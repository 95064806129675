import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Rent } from "../Rent";

export class RentForm {

  static getFormGroups(formBuilder: FormBuilder, objects?: Rent[]): FormGroup[] {
    let forms = [];
    for(let obj of objects){
      forms.push(this.getFormGroup(formBuilder, obj));
    }
    return forms;
  }

  static getFormGroup(formBuilder: FormBuilder, rent?: Rent): FormGroup {
    let form = formBuilder.group({
      rent_price_id: [this.getFormValue(rent, 'rent_price_id')],
      vehicle_id: [this.getFormValue(rent, 'vehicle_id'), Validators.required],
      rent_price: [this.getFormValue(rent, 'rent_price'), Validators.required],
      duration: [this.getFormValue(rent, 'duration'), Validators.required],
      mileage: [this.getFormValue(rent, 'mileage'), Validators.required],
    });
    return form;
  }

  static getFormValue(obj: Rent, key: string, defaultValue?: any) {
    if(obj && key){
      if(obj[key]) return obj[key];
    }
    return defaultValue != undefined ? defaultValue : null;
  }

  static getObjects(forms: FormGroup[]): Rent[] {
    let objs = [];
    for(let form of forms){
      objs.push(this.getObject(form));
    }
    return objs;
  }

  static getObject(form: FormGroup): Rent {
    return new Rent(
      form.get('rent_price_id').value,
      form.get('vehicle_id').value,
      form.get('rent_price').value,
      form.get('duration').value,
      form.get('mileage').value,
    )
  }
}
