<div class="header-base-style">
    <div class="header-inner-wrapper">
        <button class="button-icon-start" (click)="selectionCancel()">
        <img class="button-icon-start-img" src="./assets/cancle.svg"/>
        <span class="button-icon-start-span">Abbrechen</span>
        </button>
        <h2>Neue Miete</h2>
    </div>
    <div class="header-button-wrapper">
        <button *ngIf="!obj" class="btn_submit" (click)="selectionCreateRent()">Erstellen</button>
        <button *ngIf="obj" class="btn_submit" (click)="selectionUpdateRent()">Speichern</button>
    </div>
</div>


<form [formGroup]="form" id="rent-create-body" class="body-base-style" *ngIf="form">

    <div class="column">
      <div class="overview_data_box">
        <label class="overview_data_box_lbl_title">Miet-Typ</label>
        <!-- <app-dropdown-enum
            [name]="'Miet-Typ'"
            [placeholder]="'Miet-Typ'"
            [control]="form.get('rent_typ')"
            [enumName]="'rent_typ'"
            [submitted]="submitted"
        ></app-dropdown-enum> -->
        <app-dropdown-string-selection
          [headerText]="'Miet-Typ*'"
          [values]="rentTypValues"
          [selectedValue]="rentTypValue"
          [formcontrol]="form.controls['rent_typ']"
          (submitValueEmitter)="selectionRentTyp($event)"
        ></app-dropdown-string-selection>
      </div>

      <div class="overview_data_box">
        <label class="overview_data_box_lbl_title">Fahrzeug Übergabe</label>
        <app-dropdown-objects-search
          [title]="'Fahrzeug'"
          [placeholder]="'Fahrzeug'"
          [headervalue]="form.get('vehicle').value ? form.get('vehicle').value.getVehicleName() : ''"
          [formcontrol]="form.get('vehicle')"
          [values]="vehicleValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
        ></app-dropdown-objects-search>

        <!-- AB HIER DATEN VON VEHICLE -->
        <div *ngIf="form.get('vehicle').value" id="form-vehicle-wrapper">
        <form *ngIf="false" id="form-vehicle-wrapper" [formGroup]="formVehicle">
          <app-dropdown-objects-search
            [title]="'Marke'"
            [placeholder]="'Marke'"
            [headervalue]="formVehicle.get('brand').value ? formVehicle.get('brand').value.makeName : ''"
            [formcontrol]="formVehicle.get('brand')"
            [values]="brandValues"
            [submitted]="submitted"
            (submitValueEmitter)="setValueFromDropdown('brand', $event)"
          ></app-dropdown-objects-search>
          <app-dropdown-objects-search
            [title]="'Model'"
            [placeholder]="'Model'"
            [headervalue]="formVehicle.get('model').value ? formVehicle.get('model').value.name : ''"
            [formcontrol]="formVehicle.get('model')"
            [values]="modelValues"
            [submitted]="submitted"
            [disabled]="formVehicle.get('brand').value == null"
            (submitValueEmitter)="setValueFromDropdown('model', $event)"
          ></app-dropdown-objects-search>
          <div class="input_wrapper">
            <label class="overview_record_lbl">FIN</label>
            <input type="text" placeholder="---" formControlName="fin_number" [class.input_invalid]="submitted && formVehicle.get('fin_number').errors">
          </div>
          <div class="input_wrapper">
            <label class="overview_record_lbl">Kennzeichen</label>
            <input type="text" placeholder="---" formControlName="license_plate" [class.input_invalid]="submitted && formVehicle.get('license_plate').errors">
          </div>
          <app-dropdown-objects-search
              [title]="'Treibstoff'"
              [placeholder]="'Treibstoff'"
              [headervalue]="formVehicle.get('fuel').value ? formVehicle.get('fuel').value.name : ''"
              [formcontrol]="formVehicle.get('fuel')"
              [values]="fuelsValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
            ></app-dropdown-objects-search>
          <div class="input_wrapper">
            <label class="overview_record_lbl">Vorschäden</label>
            <input type="text" placeholder="---" formControlName="damages" [class.input_invalid]="submitted && formVehicle.get('damages').errors">
          </div>

        </form>

        
          <!-- AB HIER DATEN VON RENT -->

          <div class="input_wrapper">
            <label class="overview_record_lbl">Tankinhalt</label>
            <input type="text" placeholder="---" formControlName="handover_fuel_content" [class.input_invalid]="submitted && form.get('handover_fuel_content').errors">
          </div>
  
          <div class="input_wrapper">
            <label class="overview_record_lbl">Kilometerstand</label>
            <input type="text" placeholder="---" formControlName="handover_mileage" [class.input_invalid]="submitted && form.get('handover_mileage').errors">
          </div>
  
          <div class="input_wrapper">
            <label class="overview_record_lbl">Freigegeben am</label>
            <input type="date" placeholder="date" formControlName="start_release_date"  [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.get('start_release_date').errors">
          </div>
        </div>
      </div>

      
      <div class="overview_data_box">
        <label class="overview_data_box_lbl_title">Fahrzeug Rückgabe</label>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Kilometerstand</label>
          <input type="text" placeholder="---" formControlName="return_mileage" [class.input_invalid]="submitted && form.get('return_mileage').errors">
        </div>

        <!-- <div class="input_wrapper">
          <label class="overview_record_lbl">Gefahrene Kilometer</label>
          <input type="text" placeholder="---" formControlName="driven_km" [class.input_invalid]="submitted && form.get('driven_km').errors">
        </div> -->

        <div class="input_wrapper">
          <label class="overview_record_lbl">Schäden</label>
          <input type="text" placeholder="---" formControlName="return_damage" [class.input_invalid]="submitted && form.get('return_damage').errors">
        </div>

        <div class="input_wrapper">
          <label class="overview_record_lbl">Tankinhalt</label>
          <input type="text" placeholder="---" formControlName="return_fuel_content" [class.input_invalid]="submitted && form.get('return_fuel_content').errors">
        </div>

        <div class="input_wrapper">
          <label class="overview_record_lbl">Freigegeben am</label>
          <input type="date" placeholder="date" formControlName="end_release_date"  [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.get('end_release_date').errors">
        </div>
      </div>

    </div>

    <div class="column">
      <div class="overview_data_box">
        <h3>Mietumfang</h3>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Beginn</label>          
          <div class="input_wrapper_multiple">
            <input #inputDate1 type="date" placeholder="date" formControlName="rental_start_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.get('rental_start_date').errors">
            <input #inputDate2 type="time" placeholder="time" formControlName="rental_start_time" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.get('rental_start_time').errors">
          </div>
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Ende</label>
          <div class="input_wrapper_multiple">
            <input #inputDate3 type="date" placeholder="date" formControlName="rental_end_date" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.get('rental_end_date').errors">
            <input #inputDate4 type="time" placeholder="time" formControlName="rental_end_time" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && form.get('rental_end_time').errors">
          </div>
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">KM-Begrenzung</label>
          <input type="number" placeholder="---" formControlName="km_limit" [class.input_invalid]="submitted && form.get('km_limit').errors">
        </div>
      </div>

      <div class="overview_data_box">
        <h3>Miete</h3>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Preis je</label>
          <label class="overview_record_lbl" [appFormatValue]="'string'" [value]="'Tag'"></label>
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Miete (brutto)</label>
          <label class="overview_record_lbl" [appFormatValue]="'currency'" [value]="formVehicle?.get('rent_price_pro_tag').value"></label>
          <!-- <input type="text" placeholder="---" formControlName="rent_price_eur" [class.input_invalid]="submitted && form.get('rent_price_eur').errors"> -->
        </div>
        <!-- div class="input_wrapper">
          <label class="overview_record_lbl">Beisatz Miete</label>
          <input type="text" placeholder="---" formControlName="rent_add_on" [class.input_invalid]="submitted && form.get('rent_add_on').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Kosten je Mehrkilometer in €</label>
          <input type="text" placeholder="---" formControlName="additional_km_price" [class.input_invalid]="submitted && form.get('additional_km_price').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Versicherungspauschale</label>
          <input type="text" placeholder="---" formControlName="insurance_flat_rate" [class.input_invalid]="submitted && form.get('insurance_flat_rate').errors">
        </div-->
      </div>

      <!-- div class="overview_data_box">
        <h3>Kaution</h3>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Kaution in €</label>
          <input type="text" placeholder="---" formControlName="deposit_eur" [class.input_invalid]="submitted && form.get('deposit_eur').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Kaution</label>
          <input type="text" placeholder="---" formControlName="deposit_time_unit" [class.input_invalid]="submitted && form.get('deposit_time_unit').errors">
        </div>
      </div -->

      <div class="overview_data_box" *ngIf="false">
        <h3>Sonderkonditionen</h3>
          <input class="special_conditions" type="text" placeholder="---">
      </div>
    </div>

    <div class="column">
      <div class="overview_data_box">
      <label class="overview_data_box_lbl_title">Hauptfahrer</label>
      <app-dropdown-objects-search
        [title]="'Hauptfahrer'"
        [placeholder]="'Hauptfahrer'"
        [headervalue]="form.get('main_driver').value ? form.get('main_driver').value.getFullName() : ''"
        [formcontrol]="form.get('main_driver')"
        [values]="mainDriverValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('main_driver', $event)"
      ></app-dropdown-objects-search>
      <form *ngIf="form.get('main_driver').value" id="form-vehicle-wrapper" [formGroup]="formMainDriver">
        <div class="input_wrapper">
          <div class="overview_record_lbl">Vorname</div>
          <input type="text" placeholder="---" formControlName="firstName" [class.input_invalid]="submitted && formMainDriver.get('firstName').errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Nachname</div>
          <input type="text" placeholder="---" formControlName="lastName" [class.input_invalid]="submitted && formMainDriver.get('lastName').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Starße, NR.</label>
          <input type="text" placeholder="---" formControlName="street" [class.input_invalid]="submitted && formMainDriver.get('street').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">PLZ</label>
          <input type="text" placeholder="---" formControlName="postalCode" [class.input_invalid]="submitted && formMainDriver.get('postalCode').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Ort</label>
          <input type="text" placeholder="---" formControlName="city" [class.input_invalid]="submitted && formMainDriver.get('city').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Telefon</label>
          <input type="text" placeholder="---" formControlName="phone" [class.input_invalid]="submitted && formMainDriver.get('phone').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">E-Mail</label>
          <input type="text" placeholder="---" formControlName="email" [class.input_invalid]="submitted && formMainDriver.get('email').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Führerscheinnummer</label>
          <input type="text" placeholder="---" formControlName="authorization_number" [class.input_invalid]="submitted && formMainDriver.get('authorization_number').errors">
        </div>
        <div class="input_wrapper" *ngIf="false">
          <label class="overview_record_lbl">Kopie Führerschein</label>
          <input type="text" placeholder="keine daten vorhanden">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Geburtsdatum</label>
          <input #inputDate1 type="date" placeholder="date" formControlName="birthdate"  [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formMainDriver.get('birthdate').errors">
        </div>
      </form>
      </div>

      <!--form class="overview_data_box" [formGroup]="formSecondDriver" *ngIf="false">
        <label class="overview_data_box_lbl_title">Zweitfahrer</label>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Vorname</div>
          <input type="text" placeholder="---" formControlName="firstName" [class.input_invalid]="submitted && formSecondDriver.get('firstName').errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Nachname</div>
          <input type="text" placeholder="---" formControlName="lastName" [class.input_invalid]="submitted && formSecondDriver.get('lastName').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Starße, NR.</label>
          <input type="text" placeholder="---" formControlName="street" [class.input_invalid]="submitted && formSecondDriver.get('street').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">PLZ</label>
          <input type="text" placeholder="---" formControlName="postalCode" [class.input_invalid]="submitted && formSecondDriver.get('postalCode').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Ort</label>
          <input type="text" placeholder="---" formControlName="city" [class.input_invalid]="submitted && formSecondDriver.get('city').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Telefon</label>
          <input type="text" placeholder="---" formControlName="phone" [class.input_invalid]="submitted && formSecondDriver.get('phone').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">E-Mail</label>
          <input type="text" placeholder="---" formControlName="email" [class.input_invalid]="submitted && formSecondDriver.get('email').errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Führerscheinnummer</label>
          <input type="text" placeholder="---" formControlName="authorization_number" [class.input_invalid]="submitted && formSecondDriver.get('authorization_number').errors">
        </div>
        <div class="input_wrapper" *ngIf="false">
          <label class="overview_record_lbl">Kopie Führerschein</label>
          <input type="text" placeholder="keine daten vorhanden">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Geburtsdatum</label>
          <input #inputDate1 type="date" placeholder="date" formControlName="birthdate"  [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formSecondDriver.get('birthdate').errors">
        </div>
       
      </form-->
    </div>
</form>
