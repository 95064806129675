import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-customer-container',
  templateUrl: './list-customer-container.component.html',
  styleUrls: ['./list-customer-container.component.css']
})
export class ListCustomerContainerComponent implements OnInit {
  @Input() customers: Customer[];
  @Input() pageInfo: string;
  @Input() pageHandler: PageHandler;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() filter: any;
  @Input() sortObject: any;
  @Input() amount: number;
  @Input() accessEdit: boolean;
  @Output() openDetailsEmitter = new EventEmitter<undefined>();
  @Output() openEditEmitter = new EventEmitter<Customer>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() deleteEmitter = new EventEmitter<Customer>();
  @Output() moveEmitter = new EventEmitter<Customer>();
  @Output() selectionCreateDokumentEmitter = new EventEmitter<Customer>();
  @Output() selectionMoveEmitter = new EventEmitter<Customer>();
  @Output() createCustomerCarEmitter = new EventEmitter<Customer>();
  @Output() removeFilterEmitter = new EventEmitter<string>();
  @Output() applySortEmitter = new EventEmitter<undefined>();
  public maxAmountListPage = environment.max_amount_list_page;

  constructor() { }

  ngOnInit(): void {
  }
}
