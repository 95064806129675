import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.css'],
  providers: [NumberFormatPipe],
})
export class ListElementComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() objectTyp: string;
  @Input() object: any;
  @Input() activeSubMenu: SubMenu;
  @Input() index: number;
  @Input() maxIndex: number;
  @Input() activeSubMenu$: BehaviorSubject<number>;
  @Output() openDetailsEmitter = new EventEmitter<any>();
  @Output() deleteEmitter = new EventEmitter<any>();
  @Output() openEditEmitter = new EventEmitter<any>();
  @Output() moveEmitter = new EventEmitter<any>();
  @Output() printPdfEmitter = new EventEmitter<any>();
  @Output() printEtikettEmitter = new EventEmitter<any>();
  @Output() sellEmitter = new EventEmitter<any>();
  @Output() selectionCreateDokumentEmitter = new EventEmitter<any>();
  @Output() selectionCopyEmitter = new EventEmitter<any>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionCreateKaufvertragEmitter = new EventEmitter<any>();
  @Output() calculatePricesheetEmitter = new EventEmitter<any>();
  @Output() selectionArchivEmitter = new EventEmitter<any>();
  @Output() selectionPDFCreateEmitter = new EventEmitter<any>();
  @Output() selectionPDFOpenEmitter = new EventEmitter<any>();
  @Output() selectionTestDrivePDFSignEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionRestoreEmitter = new EventEmitter<any>();
  @Output() selectionChangeStatusEmitter = new EventEmitter<any>();
  @Output() selectionChangePaymentStatusEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();
  public reduzeForScreenSize = GlobalVariables.screen_size == 2;
  public mainMenuSubscription: Subscription;
  public currentMenuId: number = 0;
  public scrollLeft = 0;
  public viewLoaded = false;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    if(this.authService.isWaymarkUser()){
      if(this.activeSubMenu.actions.findIndex((action) => action == 'delete') == -1 && this.activeSubMenu.actions.length > 0){
        this.activeSubMenu.actions.push('delete');
      }
    }
    this.activeSubMenu$.subscribe((id) => {
      if(this.currentMenuId != id){
        this.currentMenuId = id;
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewLoaded = true;
  }

  handleRouting(event) {
    const tag = event.target as HTMLElement;
    if(tag.tagName != 'IMG' && !tag.classList.contains('submenu-column-action-btn') && !tag.classList.contains('column-action-box-btn')){
      this.goTo();
    }
  }

  goTo() {
    if(this.object instanceof Vehicle){
      var url = this.router.createUrlTree([], { relativeTo: this.activateRoute, queryParams: { subMenuId: this.activeSubMenu.sub_menu_id, carId: this.object.id }}).toString();
      this.router.navigateByUrl(url);
    } else if(this.object instanceof Kaufvertrag){
      this.openDetailsEmitter.emit(this.object);
    } else if(this.object instanceof Tire){
      this.openDetailsEmitter.emit(this.object);
    }
  }
}
