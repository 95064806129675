<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionBack()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>Kaufvertrag #{{document.purchase_contract_id}}</h2>
  </div>
  <div class="header-button-wrapper">
    <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button *ngIf="document.vertrag_status == kaufvertragStatus.OFFEN" (click)="selectionEdit(); shwoActions = false">Bearbeiten</button>
        <button *ngIf="(authService.isHoedl() || authService.isWaymarkUser() || authService.isReiter()) && document.vertrag_status == kaufvertragStatus.OFFEN" (click)="selectionUploadDocumentEmitter.emit(document); shwoActions = false">Dokument hochladen</button>
        <button *ngIf="(authService.isHoedl() || authService.isWaymarkUser() || authService.isReiter()) && document.vertrag_status == kaufvertragStatus.OFFEN" (click)="selectionPseudoSignatureEmitter.emit(document); shwoActions = false">Dokument signieren</button>
        <button *ngIf="(authService.isHoedl() || authService.isWaymarkUser()) && document.vertrag_status == kaufvertragStatus.UNTERZEICHNET && document.showAnzahlungKassieren()" (click)="selectionAnzahlungKassierenEmitter.emit(document); shwoActions = false">Anzahlung kassieren</button>
        <button *ngIf="(authService.isHoedl() || authService.isWaymarkUser()) && document.vertrag_status == kaufvertragStatus.UNTERZEICHNET && document.showEndzahlungKassieren()" (click)="selectionEndzahlungKassierenEmitter.emit(document); shwoActions = false">Endzahlung kassieren</button>
        <button *ngIf="document.vertrag_status == kaufvertragStatus.UNTERZEICHNET" (click)="selectionContractStornoEmitter.emit(document); shwoActions3 = false">Vertrag stornieren</button>
        <button *ngIf="document.vertrag_status == kaufvertragStatus.UNTERZEICHNET" (click)="selectionContractRenewEmitter.emit(document); shwoActions3 = false">Neu ausstellen</button>
        <button *ngIf="(authService.isHoedl() || authService.isWaymarkUser()) && document.vertrag_status == kaufvertragStatus.OFFEN" (click)="selectionContractPDFUnsigned(); shwoActions = false">Unsigniertes drucken</button>
        <button *ngIf="(authService.isSinhuber() || authService.isHoedl() || authService.isWaymarkUser()) && document.vertrag_status == kaufvertragStatus.OFFEN" (click)="selectionAppSignatureEmitter.emit(document); shwoActions3 = false">App Signatur</button>
      </div>
    </button>
    <button *ngIf="authService.isHoedl() && document.vertrag_status == kaufvertragStatus.UNTERZEICHNET" class="button_type_2" (click)="shwoActions2 = !shwoActions2" appDropdown [show]="shwoActions2" (changedValueEmitter)="shwoActions2 = $event">
      Dokumente
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions2">
        <button *ngIf="hasGarantie()" (click)="openGarantiePDF()">Garantievertrag</button>
        <button (click)="openUebergabeFormularPDF()">Übergabeformular</button>
        <button (click)="openWerkstattPDF()">Werkstattauftrag</button>
      </div>
    </button>
    <button class="btn_submit" (click)="openPDF()">PDF drucken</button>
    <button *ngIf="false" class="btn_submit" (click)="null">App-Signature</button>
  </div>
</div>

<div class="body-base-style">
  <div class="btn-tab-wrapper">
    <button class="btn-tab btn-tab-first" [class.btn-tab-active]="showTab == tabView.Details" (click)="null">Details</button>
    <button *ngIf="false" class="btn-tab btn-last" [class.btn-tab-active]="showTab == tabView.Tickets" (click)="null">Tickets</button>
  </div>
  <div id="kaufvertrag-details-body" *ngIf="showTab == tabView.Details">
    <div id="header-column" class="overview_data_box_vertical">
      <div class="overview_record_vertical" *ngIf="document.vehicle">
        <h3>Fahrzeug</h3>
        <div>
          <label class="overview_record_lbl">{{document.vehicle.brand.makeName}} {{document.vehicle.model.name}}</label>
          <label class="lbl_italic">Fahrzeug-ID: {{document.vehicle.externalId}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Kunde</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValue(document.customer?.getFullName())}}</label>
          <label class="lbl_italic">Kunden-ID: {{getInputValue(document.customer?.id)}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Verkäufer (Ersteller)</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValue(document.creator_employee?.getFullName())}}</label>
          <label class="lbl_italic">Mitarbeiter-ID: {{getInputValue(document.creator_employee_id)}}</label>
        </div>
      </div>
      <div class="overview_record_vertical" *ngIf="document.vertrag_status == kaufvertragStatus.UNTERZEICHNET">
        <h3>Verkäufer (Unterzeichner)</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValue(document.signer_employee?.getFullName())}}</label>
          <label class="lbl_italic">Mitarbeiter-ID: {{getInputValue(document.employee_id)}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Status</h3>
        <div>
          <label id="label-contract-status" class="overview_record_lbl">{{document.getSignedStatus()}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Gesamtsumme</h3>
        <div>
          <label class="overview_record_lbl">{{getInputValueCurrency(document.getVetragssumme())}}</label>
          <label class="lbl_italic">inkl. MwSt.</label>
        </div>
      </div>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] *ngIf="document.vehicle">
        <h3 collapsible-header>Fahrzeug</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Marke</label>
            <label class="overview_record_value">{{document.vehicle.brand.makeName}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Model</label>
            <label class="overview_record_value">{{document.vehicle.model.name}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl" style="align-self: flex-start;">Version</label>
            <label class="overview_record_value" style="max-width: 270px; text-align: end;">{{document.vehicle.model.version}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">KFZ-Typ</label>
            <label class="overview_record_value">{{document.vehicle.bodyTyp.name}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Hauptfarbe</label>
            <label class="overview_record_value">{{getInputValue(document.vehicle.bodyColor.name)}}</label>
         </div>
         <div class="overview_record" *ngIf="document.vehicle.category.id != 1">
            <label class="overview_record_lbl">{{document.vehicle.vehicle_imported ? 'Zulassung nach Import' : 'Erstregistrierung im Ausland'}}</label>
            <label class="overview_record_value">{{getInputValueDate(document.vehicle.firstRegistrationDate)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{document.vehicle.mileage_internal ? getInputValueKM(document.vehicle.mileage_internal) : getInputValueKM(document.vehicle.mileageKm)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">FZG-Identifizierungsnr.</label>
            <label class="overview_record_value">{{getInputValue(document.vehicle.fin_number)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="!authService.isHoedl() && !authService.isSinhuber()">
        <h3 collapsible-header>Zulassung</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Erstregistrierung im Ausland</label>
            <label class="overview_record_value">{{getInputValueDate(document.foreign_registration_date)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Dokument Typ</label>
            <label class="overview_record_value">{{getInputValue(registrationDocumentValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Dokument Zustand</label>
            <label class="overview_record_value">{{document.is_original_document ? 'Original' : 'Kopie'}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Fahrzeugzustand</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Mechanischer Zustand</label>
            <label class="overview_record_value">{{getInputValue(mechanical_conditionValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Karosserie</label>
            <label class="overview_record_value">{{getInputValue(body_conditionValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Lack</label>
            <label class="overview_record_value">{{getInputValue(paint_conditionValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Innenraum/Sonstiges</label>
            <label class="overview_record_value">{{getInputValue(interior_conditionValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Elektrik Elektronik</label>
            <label class="overview_record_value">{{getInputValue(electronic_conditionValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Gesamtzustand</label>
            <label [ngClass]="getConditionColor(total_condition)" style="justify-self: flex-end;">{{total_condition}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ankaufsüberprüfung</label>
            <label class="overview_record_value">{{(document.purchase_test_date || document.purchase_tester) ? 'Ja' : 'Nein'}}</label>
          </div>
          <div class="overview_record" *ngIf="(document.purchase_test_date || document.purchase_tester)">
            <label class="overview_record_lbl">Datum</label>
            <label class="overview_record_value">{{getInputValueDate(document.purchase_test_date)}}</label>
          </div>
          <div class="overview_record" *ngIf="(document.purchase_test_date || document.purchase_tester)">
            <label class="overview_record_lbl">Prüfer</label>
            <label class="overview_record_value">{{getInputValue(document.purchase_tester)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="authService.isHoedl()">
        <h3 collapsible-header>Zusätzlich vorhanden</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Probefahrt durchgeführt</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.test_drive)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Reserverad vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.spare_wheel)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Wagenheber vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.honey_comb_lifter)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Felgenschloss vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.rim_lock)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Fernbedienung vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.remote_control)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kartenmaterial vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.card_material)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Anhängerkupplung vorhanden</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.trailer_hitch)}}</label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zahlung und Zahlungsbedingungen</h3>
        <div collapsible-content>
          <div class="overview_record" *ngIf="authService.isHoedl() || authService.isWaymarkUser()">
            <label class="overview_record_lbl">Anzahlung</label>
            <label class="overview_record_value">{{getInputValueCurrency(document.down_payment)}}</label>
          </div>
          <!-- <div class="overview_record" *ngIf="document.down_payment != null && (authService.isHoedl() || authService.isWaymarkUser())">
            <label class="overview_record_lbl">Anzahlungs-Zahlungsart</label>
            <label class="overview_record_value">{{getInputValueCurrency(downPaymentConditionValue)}}</label>
          </div> -->
          <!--<div class="overview_record" *ngIf="authService.isHoedl() || authService.isWaymarkUser()">
            <label class="overview_record_lbl">Rabatt</label>
            <label class="overview_record_value">{{getInputValueCurrency(document.down_payment)}}</label>
          </div>-->
          <div class="overview_record"  *ngIf="!authService.isHoedl()">
            <label class="overview_record_lbl">Gewährleistungsfirst (in Monaten)</label>
            <label class="overview_record_value">{{getInputValue(document.warranty_period)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaufpreis</label>
            <label class="overview_record_value">{{getInputValueCurrency(document.price)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaution</label>
            <label class="overview_record_value">{{getInputValueCurrency(document.deposit)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Übergabe</label>
            <label class="overview_record_value">{{getInputValueDate(document.handing_over_date)}}</label>
          </div>
          <div class="overview_record" *ngIf="!authService.isHoedl()">
            <label class="overview_record_lbl">Zahlungsmethode</label>
            <label class="overview_record_value">{{getInputValue(paymentConditionValue)}}</label>
          </div>
          <div class="overview_record" *ngIf="authService.isHoedl() || authService.isSinhuber() || authService.isWaymarkUser()">
            <label class="overview_record_lbl">Finanzierungsart</label>
            <label class="overview_record_value">{{getInputValue(finanzierungsartenValue)}}</label>
          </div>
          <div class="overview_record" *ngIf="authService.isSinhuber()">
            <label class="overview_record_lbl">Finanzierungsgesellschaft</label>
            <label class="overview_record_value">{{getInputValue(finanzierungsgesellschaftValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Sonderkonditionen</label>
            <label class="overview_record_value">{{getInputValue(document.special_conditions)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Gebrauchtwagentausch</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.trade_in_vehicle?.brand?.makeName != null ? true : false)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="document.trade_in_vehicle?.brand?.makeName != null">
        <h3 collapsible-header>Gebrauchtwagentausch</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Marke</label>
            <label class="overview_record_value">{{document.trade_in_vehicle.brand.makeName}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Model</label>
            <label class="overview_record_value">{{document.trade_in_vehicle.model.name}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Erstzulassung</label>
            <label class="overview_record_value">{{getInputValueDate(document.trade_in_vehicle.registration_date)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Anzahl Vorbesitzer</label>
            <label class="overview_record_value">{{getInputValueNumberNoDicis(document.trade_in_vehicle.previous_owner)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">VIN-Nummer</label>
            <label class="overview_record_value">{{getInputValue(document.trade_in_vehicle.vin_number)}}</label>
          </div>
          <div class="overview_record" *ngIf="authService.isHoedl()">
            <label class="overview_record_lbl">NAT-Code</label>
            <label class="overview_record_value">{{getInputValue(document.trade_in_vehicle.national_code)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{getInputValueKM(document.trade_in_vehicle.mileage)}}</label>
          </div>
          <div class="overview_record" *ngIf="!authService.isHoedl()">
            <label class="overview_record_lbl">Zulassung für Fahrzeugwert</label>
            <label class="overview_record_value">{{getInputValue(tradeInVehicleRegistrationDocumentValue)}}</label>
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Wert</label>
            <label class="overview_record_value">{{getInputValueCurrency(document.trade_in_vehicle.price)}}</label>
          </div>
          <div class="overview_record" *ngIf="authService.isHoedl()">
            <label class="overview_record_lbl">Mit MwSt.</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.trade_in_vehicle.includes_tax)}}</label>
          </div>
          <div class="overview_record" *ngIf="authService.isHoedl()">
            <label class="overview_record_lbl">Vom Kaufpreis abziehen</label>
            <label class="overview_record_value">{{getBooleanStringValue(document.trade_in_vehicle.reduce_price)}}</label>
          </div>
          <div class="overview_record"  *ngIf="!authService.isHoedl()">
            <label class="overview_record_lbl">Benutzung bis Übergabe (km)</label>
            <label class="overview_record_value">{{getInputValueKM(document.trade_in_vehicle.mileage_to_drive)}}</label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="authService.isHoedl() && document.selektierteZusatzprodukte.length > 0">
        <h3 collapsible-header>Zusatzverkäufe</h3>
        <div collapsible-content>
          <div class="overview_record" *ngFor="let zusatzprodukt of document.selektierteZusatzprodukte">
            <label class="overview_record_lbl">{{zusatzprodukt.name_selektiert ? zusatzprodukt.name_selektiert : zusatzprodukt.kategorie}}</label>
            <label class="overview_record_value">{{getInputValueCurrency(zusatzprodukt.preis_selektiert)}}</label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
        <div collapsible-content>
          <div [attr.id]="'file_'+bill.bild_id" class="file" *ngFor="let bill of document.bills">
            <div class="file-inner" (click)="openRe(bill.bill_id)">
              <div class="file-img-teal">
                <img src="./assets/document-grey.svg">
              </div>
              <div class="file-info-wrapper">
                <label>{{bill.getFileName()}}</label>
                <label class="italic-small">{{getInputValueDate(bill.bill_date)}}</label>
              </div>
            </div>
            <app-list-action-container
              [actions]="['download']"
              [index]="index"
              [maxIndex]="100"
              [object]="bill"
              [listItemId]="'attachment-wrapper_'+bill.bill_id"
              (selectionDownloadEmitter)="openRe(bill.bill_id)"
            ></app-list-action-container>
          </div>
          <app-files
            [obj]="document"
          ></app-files>
        </div>
      </app-collapsible>
    </div>
  </div>
  <div *ngIf="showTab == tabView.Tickets">
    <div>

    </div>
  </div>
</div>
