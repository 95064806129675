<div [ngClass]="getListElement()" class="list_hover_element">
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">{{drive.timestamp | date: 'dd. MMM yyyy'}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">{{drive.timestamp | date: 'HH:mm'}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">{{drive.getDuration()}} min</div>
  <div class="list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">
    <div [ngClass]="drive.getStatusColor()">{{drive.getStatus()}}</div>
  </div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">{{drive.vehicle.getDescription(55)}}</div>
  <div class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">{{drive.getDriver()}}</div>
  <div [class.tag-violet]="drive.customer" [class.tag-teal]="!drive.customer"  (click)="openDetailsEmitter.emit(drive)">{{drive.customer ? 'Kunde' : 'Intern'}}</div>
  <div *ngIf="!authService.isHoedl()" class="list_item_pointer_event" (click)="openDetailsEmitter.emit(drive)">
    <div [ngClass]="drive.getStatusSignatureColor()">{{drive.getSignatureStatus()}}</div>
  </div>
  <div class="list_element_action_container">
    <div #action_container class="btn_action_wrapper">
        <img class="btn_actions" src="../../../../../assets/navdots.svg" (click)="showActions = !showActions"  [ngClass]="showActions ? 'btn_actions_active' : ''">
        <div class="dropdown_actions_container" *ngIf="showActions">
            <div *ngIf="!authService.isHoedl()" class="option" (click)="openDetailsEmitter.emit(drive)">
              <div class="list_action_value_lbl">Details</div>
              <img class="list_action_value_icon" src="../../../../../assets/eye-white.svg" >
            </div>
            <div *ngIf="drive.status_signiert == 0" class="option" (click)="openCreatePdfEmitter.emit(drive)">
                <div class="list_action_value_lbl" >PDF {{drive.customer_id != null ? 'erstellen' : 'öffnen'}}</div>
                <img class="list_action_value_icon" src="../../../../../assets/document.svg">
            </div>
            <div *ngIf="drive.status_signiert == 1" class="option" (click)="selectionPDFEmitter.emit(drive)">
              <div class="list_action_value_lbl" >PDF öffnen</div>
              <img class="list_action_value_icon" src="../../../../../assets/document.svg">
            </div>
            <div *ngIf="!authService.isHoedl() && (drive.customer_id != null && drive.status_signiert == 0 && drive.driveDataExits())" class="option" (click)="selectionPseudoSignatureEmitter.emit(drive)">
              <div class="list_action_value_lbl" >PDF signieren</div>
              <img class="list_action_value_icon" src="../../../../../assets/document.svg">
            </div>
            <div class="option" (click)="deleteEmitter.emit(drive)" *ngIf="authService.ACCESS_ALL() && (drive.customer_id == null || (drive.customer_id != null && drive.status_signiert != 1))">
                <div class="list_action_value_lbl" >Löschen</div>
                <img class="list_action_value_icon" src="../../../../../assets/delete.svg">
            </div>
        </div>
    </div>
  </div>
</div>
