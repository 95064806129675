import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { formatDate } from '@angular/common';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;

  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDateMonth = formatDate(new Date(),'yyyy-MM','de');

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  getEnumDataOnChange() {

  }

  get f() {
    return this.form.controls;
  }
}
