import { HttpEvent, HttpResponse } from '@angular/common/http';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { DragAndDrop } from 'src/app/helpers/DragAndDrop';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TireService } from 'src/app/service/tire/tire.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';


@Component({
  selector: 'app-images-web',
  templateUrl: './images-web.component.html',
  styleUrl: './images-web.component.css'
})
export class ImagesWebComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterViewChecked, OnChanges, OnDestroy {
  @Input() obj: any;
  @Input() form: FormGroup;
  @Input() showEdit: boolean;
  @Input() design: number = 1;
  @Input() updateSuccess: BehaviorSubject<boolean>;
  public swiperPictures: Picture[] = [];
  public editPictures: Picture[] = [];
  public pictures: Picture[] = [];
  public position = 0;
  public uploadProgress = 0;
  public filesAmount = 0;
  public currentFile = 0;
  public onloadSrcs: string[] = [];
  public imagesLoading = false;
  public imageRequestSubs: Subscription[] = [];
  public showAllImages = false;
  public showTitleImageLoading = true;

  public showSlider = false;
  public sliderSliceFrom = 0;
  public sliderSliceTo = 0;

  public picturesChangeSubscription: Subscription;
  public isFirstInit = true;

  public maxImages = 50;

  constructor(
    private vehicleService: VehicleService,
    private tireService: TireService,
    private dService: DialogService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if(this.design == 2) this.showAllImages = true;
    this.setUp();
    if(this.updateSuccess){
      this.updateSuccess.subscribe((value) => {
        if(value == true){
          this.setUp();
          this.updateSuccess.next(false);
        } else {
          this.setUp();
        }
      })
    }
    if(this.showEdit){
      this.getAllImageSources();
    }
    this.isFirstInit = false;
  }

  ngAfterViewInit(): void {
    if(this.obj){
      let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
      if(wrapperBox){
        wrapperBox.setAttribute('style', 'padding-right: 10px;')
      }
    }
  }

  ngAfterContentChecked(): void {
    if(this.design == 1){
      DragAndDrop.registerHandler('.items-grid-design-1-edit .drag_item');
    } else if(this.design == 2){
      DragAndDrop.registerHandler('.items-grid-design-2-edit .drag_item');
    }
  }

  ngAfterViewChecked(): void {
    if(this.obj && GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop){
      let draggedItem = DragAndDrop.getDragItem(this.editPictures);
      let toReplaceItem = DragAndDrop.getDropItem(this.editPictures);
      if(draggedItem && toReplaceItem){
        let draggedItemCopy = CloneObject.deepCopy(draggedItem);
        draggedItemCopy.position = toReplaceItem.position;
        GlobalVariables.drag_item_drag = undefined;
        GlobalVariables.drag_item_drop = undefined;
        this.updatePicturePosition(draggedItemCopy);
        this.cdr.detectChanges();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.isFirstInit) return;
    if(changes['obj'] && changes['obj'].currentValue){
      let currentObj = changes['obj'].currentValue;
      let previousObj = changes['obj'].previousValue;
      this.obj = currentObj;
      let currentObjId;
      let previousObjId;
      if(this.obj instanceof Vehicle){
        currentObjId = currentObj.id;
        previousObjId = previousObj.id;
      } else if(this.obj instanceof Tire){
        currentObjId = currentObj.tire_id;
        previousObjId = previousObj.tire_id;
      }
      if(currentObjId != previousObjId){
        this.setUp();
      }
    }
    if(changes['obj']){
      if(this.obj){
        let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 10px;')
      } else {
        let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 0px;')
      }
    }
    if(changes['showEdit'] && changes['showEdit'].currentValue == true){
      this.getAllImageSources();
    }
  }

  ngOnDestroy(): void {
    for(let imgSub of this.imageRequestSubs){
      if(imgSub) imgSub.unsubscribe();
    }
    if(this.picturesChangeSubscription) this.picturesChangeSubscription.unsubscribe();
  }

  setUp() {
    this.pictures = CloneObject.deepCopy(this.obj.pictures);
    this.pictures.sort((a, b) => a.position - b.position);
    if(this.pictures.length > 0) {
      this.swiperPictures = [];
      this.editPictures = [];
      this.swiperPictures.push(this.pictures[0])
    }
    this.getTitleImage();
  }

  async getAllImageSources() {
    this.editPictures = [];
    for(let p of this.pictures){
      if(!p.src){
        if(this.obj instanceof Vehicle){
          p.src = await this.vehicleService.getVehiclePicture(p.picture_id).toPromise();
        } else if(this.obj instanceof Tire){
          p.src = await this.tireService.getTirePicture(p.picture_id).toPromise();
        }
      }
    }
    this.editPictures = CloneObject.deepCopy(this.pictures);
  }

  async getTitleImage() {
    if(!this.pictures[0] || this.pictures[0].src != null){
      this.showTitleImageLoading = false;
      return;
    }
    if(this.obj instanceof Vehicle) {
      this.pictures[0].src = await this.getVehiclePictureServerside(this.pictures[0]).toPromise();
      this.swiperPictures[0].src = this.pictures[0].src;
      this.showTitleImageLoading = false;
    } else if(this.obj instanceof Tire) {
      this.pictures[0].src = await this.getTirePictureServerside(this.pictures[0]).toPromise();
      this.swiperPictures[0].src = this.pictures[0].src;
      this.showTitleImageLoading = false;
    }
  }

  getVehiclePictureServerside(picture: Picture): Observable<string|null> {
    return this.vehicleService.getVehiclePicture(picture.picture_id).pipe(
      map((result) => result || null),
      catchError((error) => {
        return of(null);
      })
    );
  }

  getTirePictureServerside(picture: Picture): Observable<string|null> {
    return this.tireService.getTirePicture(picture.picture_id).pipe(
      map((result) => result || null),
      catchError((error) => {
        return of(null);
      })
    );
  }

  addPictureToSlider(pic) {
    this.swiperPictures.push(CloneObject.deepCopy(pic));
    if(this.swiperPictures.length == this.pictures.length){
      this.swiperPictures.sort((a,b) => a.position - b.position);
    }
  }

  updatePicturePosition(draggedPic: Picture) {
    const currentDragged = this.editPictures.find(p => p.picture_id === draggedPic.picture_id);
    if(!currentDragged) return;
    const newPosition = draggedPic.position;
    const oldPosition = currentDragged.position;
    currentDragged.position = newPosition;
    this.editPictures.forEach(picture => {
      if (picture.picture_id !== currentDragged.picture_id) {
        if(oldPosition > newPosition) {
          if(picture.position >= newPosition && picture.position < oldPosition) {
            picture.position += 1;
          }
        }
        else if(oldPosition < newPosition) {
          if(picture.position <= newPosition && picture.position > oldPosition) {
            picture.position -= 1;
          }
        }
      }
    });
    this.editPictures.sort((a,b) => a.position - b.position);
    this.swiperPictures = this.editPictures;
    this.form.controls['pictures'].setValue(this.editPictures);
    this.form.controls['pictures_changed'].setValue(true);
  }

  async deletePicture(pic: Picture){
    let index = this.editPictures.findIndex((p) => p.position == pic.position);
    if(index > -1){
      this.editPictures.splice(index, 1);
      // Update positions
      this.editPictures.forEach((picture, i) => {
        picture.position = i + 1;
      });
      this.form.controls['pictures'].setValue(this.editPictures);
      this.form.controls['pictures_changed'].setValue(true);
    }
  }

  async uploadFile(event: any): Promise<any> {
    this.currentFile = 0;
    this.onloadSrcs = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        let src = await this.readFile(event.target.files[i]);
        if(src && this.editPictures.length < this.maxImages){
          this.editPictures.push(
            new Picture(
              null,
              this.editPictures.length+1,
              this.getObjectId(),
              src
            )
          )
        }
      }
      this.swiperPictures = this.editPictures;
      this.form.controls['pictures'].setValue(this.editPictures);
      this.form.controls['pictures_changed'].setValue(true);
    }
  }

  getObjectId(): number {
    if(this.obj instanceof Vehicle){
      return this.obj.id;
    } else if(this.obj instanceof Tire){
      return this.obj.tire_id;
    }
    return null;
  }

  getTempId(): number {
    if(this.editPictures.length == 0) return 1;
    const highestId = this.editPictures.reduce((max, current) =>
      current.position > max.position ? current : max
    ).position;
    return highestId + 1;
  }

  async readFile(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let src = event.target.result as string;
        resolve(src);
      }
      reader.onerror = (event: any) => {
        reject(false);
      }
      reader.readAsDataURL(files);
    })
  }

  async onFileDropped(data: any) {
    if(data){
      for(let d of data.data){
        if(this.editPictures.length < this.maxImages){
          this.editPictures.push(
            new Picture(
              null,
              this.editPictures.length+1,
              this.getObjectId(),
              d.content
            )
          )
        }
      }
      this.swiperPictures = this.editPictures;
      this.form.controls['pictures'].setValue(this.editPictures);
      this.form.controls['pictures_changed'].setValue(true);
    }
  }

  setAsTitle(pic: Picture) {
    let oldTitlePictureIndex = this.editPictures.findIndex((p) => p.position == 1);
    let newTitlePictureIndex = this.editPictures.findIndex((p) => p.position == pic.position);
    if(oldTitlePictureIndex > -1 && newTitlePictureIndex > -1){
      let temp = this.editPictures[oldTitlePictureIndex];
      temp.position = this.editPictures[newTitlePictureIndex].position;
      this.editPictures[oldTitlePictureIndex] = this.editPictures[newTitlePictureIndex];
      this.editPictures[oldTitlePictureIndex].position = 1;
      this.editPictures[newTitlePictureIndex] = temp;
      this.swiperPictures = this.editPictures;
      this.form.controls['pictures'].setValue(this.editPictures);
      this.form.controls['pictures_changed'].setValue(true);
    }
  }

  openSlider(pic: Picture) {
    let index = this.swiperPictures.findIndex(p => p.picture_id == pic.picture_id);
    let base64Array = [];
    this.swiperPictures.forEach(element => {
      base64Array.push(element.src);
    });
    this.dService.OpenImageSlider({base64Array: base64Array, startIndex: index});
  }

  closeSlider() {
    this.showSlider = false;
  }

  nextPicture() {
    if(this.sliderSliceTo != this.swiperPictures.length){
      this.sliderSliceFrom++;
      this.sliderSliceTo++;
    }
  }

  previousPicture() {
    if(this.sliderSliceFrom != 0){
      this.sliderSliceFrom--;
      this.sliderSliceTo--;
    }
  }

  openSingleImage(pic: Picture) {
    this.dService.OpenImageSlider({base64Array: [pic.src], startIndex: 0});
  }
}

