import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, AfterViewChecked, AfterContentInit, AfterContentChecked, AfterRenderRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';


@Component({
  selector: 'app-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.css']
})
export class ListContainerComponent implements OnInit {
  @Input() objectTyp: string;
  @Input() objects: any[];
  @Input() pageInfo: string;
  @Input() accessEdit: boolean;
  @Input() showInformation: number;
  @Input() pageHandler: PageHandler;
  @Input() filter: number;
  @Input() sortObject: any;
  @Input() filterForm: FormGroup;
  @Input() mainMenus: MainMenu[];
  @Input() activeSortingColumn: SubMenuColumn;
  @Input() activeSubMenu$: BehaviorSubject<number>;
  @Input() activeFilter: any[];
  @Input() employees: Employee[] = [];
  @Input() isListLoading: boolean = false;
  @Output() customFilterEmitter = new EventEmitter<any>();
  @Output() customFilterOutsideEmitter = new EventEmitter<any>();
  @Output() searchEmitter = new EventEmitter<any>();
  @Output() openDetailsEmitter = new EventEmitter<any>();
  @Output() openEditEmitter = new EventEmitter<any>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() deleteEmitter = new EventEmitter<any>();
  @Output() moveEmitter = new EventEmitter<any>();
  @Output() printPdfEmitter = new EventEmitter<any>();
  @Output() printEtikettEmitter = new EventEmitter<any>();
  @Output() sellEmitter = new EventEmitter<any>();
  @Output() sortEmitter = new EventEmitter<any>();
  @Output() changeListTabEmitter = new EventEmitter<number>();
  @Output() removeFilterValueEmitter = new EventEmitter<string>();
  @Output() selectionCreateDokumentEmitter = new EventEmitter<any>();
  @Output() selectionCopyEmitter = new EventEmitter<any>();
  @Output() applySortingEmitter = new EventEmitter<SubMenuColumn>();
  @Output() listLoadingEmitter = new EventEmitter<any>();
  @Output() applySpecialFilter = new EventEmitter<any>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionCreateKaufvertragEmitter = new EventEmitter<any>();
  @Output() calculatePricesheetEmitter = new EventEmitter<any>();
  @Output() selectionArchivEmitter = new EventEmitter<any>();
  @Output() selectionPDFCreateEmitter = new EventEmitter<any>();
  @Output() selectionPDFOpenEmitter = new EventEmitter<any>();
  @Output() selectionTestDrivePDFSignEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionRestoreEmitter = new EventEmitter<any>();
  @Output() selectionChangeStatusEmitter = new EventEmitter<any>();
  @Output() selectionChangePaymentStatusEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();

  public showActions = false;
  public currentMenuId: number = 0;
  public activeSubMenu: SubMenu;
  public listLoading = false;
  public emptyListText;

  constructor() { }

  ngOnInit(): void {
    this.setEmptyText();
    for(let menu of this.mainMenus){
      if(menu.getActive()){
        this.activeSubMenu = menu.sub_menus.find((subMenu) => subMenu.getActive());
        break;
      }
    }
    this.activeSubMenu$.subscribe((id) => {
      if(this.currentMenuId != id){
        this.setUpRow();
        this.currentMenuId = id;
      }
    });
  }

  setUpRow() {
    const list = document.getElementById('ab-item-list');
    if(list != null){
      list.scrollLeft = 0;
    }
    for(let menu of this.mainMenus){
      if(menu.getActive()){
        this.activeSubMenu = menu.sub_menus.find((subMenu) => subMenu.getActive());
        break;
      }
    }
  }

  public getSum(column: SubMenuColumn): any {
    let sum = 0;
    if(column.sum_typ == "SUM"){
      for(let i = 0; i < this.objects.length; i++){
        let data;
        if(typeof this.objects[i][column.data_key] === 'function'){
          data = this.objects[i][column.data_key]();
        } else if(column.data_key.includes('.')){
          let keys = column.data_key.split('.');
          let value = this.objects[i];
          for(let j = 0; j < keys.length; j++){
            value = value ? value[keys[j]] : null;
            if(value == undefined || value == null){
              break;
            }
          }
          data = value;
        } else {
          data = this.objects[i] ? this.objects[i][column.data_key] : null;
        }
        if(data != null && !isNaN(data)){
          sum = sum + Number(data);
        }
      }
    } else if(column.sum_typ == "COUNT"){
      for(let i = 0; i < this.objects.length; i++){
        let data;
        if(column.data_key.includes('.')){
          let keys = column.data_key.split('.');
          let value = this.objects[i];
          for(let j = 0; j < keys.length; j++){
            value = value ? value[keys[j]] : null;
            if(value == undefined || value == null){
              break;
            }
          }
          data = value;
        } else {
          data = this.objects[i] ? this.objects[i][column.data_key] : null;
        }
        if(data != null && data != ''){
          sum++;
        }
      }
    } else if(column.sum_typ == "LAST"){
      if(this.objects.length > 0){
        let lastObj = this.objects[this.objects.length-1];
        sum = lastObj[column.data_key];
      } else {
        sum = 0;
      }
    }
    return this.getFormat(column, sum?.toString());
  }

  public getFormat(column: SubMenuColumn, value: string): string {
    if(column.data_typ === 'STRING'){
      return value;
    }
    if(column.data_typ === 'DATE'){
      if(value == null || value == '') return '---';
      return formatDate(value, column.format, 'de');
    }
    if(column.data_typ === 'NUMBER'){
      if(value == null || value == '') return '---';
      let num;
      switch(column.format){
        case '0.00': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(2)); break;
        case '0.000': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(3)); break;
        default: num = new NumberFormatPipe().transform(parseInt(value)); break;
      }
      return (column.prefix ? column.prefix : "") + num + (column.suffix ? column.suffix : "");
    }
    if(column.data_typ === 'BOOLEAN'){
      return value ? 'Ja' : 'Nein';
    }
    return (value != null && value != '') ? value : '---';
  }

  setEmptyText() {
    switch (this.objectTyp) {
      case 'vehicle': this.emptyListText = 'Keine Fahrzeuge gefunden.'; break;
      case 'kaufvertrag': this.emptyListText = 'Keine Kaufverträge gefunden.'; break;
      case 'ledger': this.emptyListText = 'Keine Bucheinträge gefunden.'; break;
      case 'test_drive': this.emptyListText = 'Keine Fahrten gefunden.'; break;
      case 'employee': this.emptyListText = 'Keine Mitarbeiter gefunden.'; break;
      case 'customer': this.emptyListText = 'Keine Kunde gefunden.'; break;
      case 'tire': this.emptyListText = 'Keine Reifen gefunden.'; break;
      default: break;
    }
  }
}
