import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { TransactionHelper } from 'src/app/helpers/TransactionHelper';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.css'
})
export class UserDetailsComponent extends BaseComponent implements OnInit {
  @Input() obj: Employee;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  public shwoActions = false;
  public transactions: Transaction[] = [];
  public TransactionHelper = TransactionHelper;
  public salutation;
  public title;

  constructor(
    private authService: AuthenticationService,
    enumService: EnumService,
    private transactionSerive: TransactionService,
  ){
    super(authService, enumService);
  }

  ngOnInit(): void {
    this.requestDataServerside();
    this.salutation = this.enumService.getEnumValue('salutation', this.obj.salutation);
  }

  requestDataServerside(){
    this.transactionSerive.getTransactions('employee', this.obj.id).subscribe((transactions) => {
      if(transactions){
        this.transactions = transactions;
      }
    });
  }
}
