import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dropdown-strings',
  templateUrl: './dropdown-strings.component.html',
  styleUrls: ['./dropdown-strings.component.css']
})
export class DropdownStringsComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() placeholder: string = '---';
  @Input() headerText: string;
  @Input() submitted: boolean;
  @Input() formcontrol: FormControl;
  @Input() values: string[];
  @Input() isOptional: boolean = false;
  @Output() submitValueEmitter = new EventEmitter<string>();
  public show = false;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }
}
