import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateHelper } from "src/app/utils/DateHelper";
import { Customer } from "../Customer";
import { validBirthdate } from "src/app/validators/CustomValidators";
import { ContactPerson } from "../ContactPerson";
import { Address } from "../Adress";

export class CustomerForm {

  public static getCustomerForm(formBuilder: FormBuilder, customer?: Customer): FormGroup {
    let form = formBuilder.group({
      customer_id: [customer ? customer.id : null],
      contact_person_id: [customer ? customer.contactPerson.id : null],
      firstName: [customer ? customer.contactPerson.firstName : null, Validators.required],
      lastName: [customer ? customer.contactPerson.lastName : null, Validators.required],
      title: [customer ? customer.contactPerson.title : null],
      salutation: [customer ? customer.contactPerson.salutation : null, Validators.required],
      birthdate: [customer ? DateHelper.getConvertedDateForInputFull(customer.contactPerson.birthdate) : null],
      is_pep: [customer ? customer.contactPerson.is_pep : null],
      email: [customer ? customer.contactPerson.email : null, [Validators.required,Validators.email]],
      phone: [customer ? customer.contactPerson.phone : null, Validators.required],
      address_id: [customer && customer.address ? customer.address.addressId : null],
      street: [customer ? customer.address.street : null, Validators.required],
      postalCode: [customer ? customer.address.postalCode : null, [Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}'), Validators.min(1)]],
      city: [customer ? customer.address.city : null, [Validators.required]],
      country: [customer ? customer.address.country : null],
      dataprivacyAccepted: [customer ? customer.dataprivacyAccepted : false, [Validators.required, Validators.requiredTrue]],
      advertismentAccepted: [customer ? customer.advertismentAccepted : false, Validators.required],
      typ: [customer ? customer.typ : null, Validators.required],
      status: [customer ? customer.status : null, Validators.required],
      employee: [customer ? customer.employee : null],
      atuNum: [customer ? customer.atuNum : null],
      companyName: [customer ? customer.companyName : null],
      authorization_number: [customer ? customer.authorization_number : null],
      authorization_authority: [customer ? customer.authorization_authority : null],
      authorization_date: [customer ? DateHelper.getConvertedDateForInputFull(customer.authorization_date) : null],
      authorization_groups: [customer ? customer.authorization_groups : null],
    });
    return form;
  }

  public static createObject(form: FormGroup): Customer {
    let contactPerson = new ContactPerson(
      form.controls['contact_person_id'].value,
      form.controls['firstName'].value,
      form.controls['lastName'].value,
      form.controls['email'].value,
      form.controls['phone'].value,
      form.controls['title'].value,
      form.controls['salutation'].value,
      DateHelper.convertDateStringToTimestamp(form.controls['birthdate'].value),
      form.controls['is_pep'].value
    );
    let adress = new Address(
      form.controls['address_id'].value,
      form.controls['street'].value,
      form.controls['postalCode'].value,
      form.controls['city'].value,
      form.controls['country'].value,
    );
    let customer = new Customer(
      form.controls['customer_id'].value,
      Number(localStorage.getItem('location_id')),
      contactPerson,
      adress,
      form.controls['dataprivacyAccepted'].value,
      form.controls['advertismentAccepted'].value,
      form.controls['typ'].value,
      form.controls['status'].value,
      form.controls['atuNum'].value,
      form.controls['companyName'].value,
      new Date().getTime(),
      [],
      form.controls['employee'].value?.employee_id,
      form.controls['authorization_number'].value,
      form.controls['authorization_authority'].value,
      DateHelper.convertDateStringToTimestamp(form.controls['authorization_date'].value),
      form.controls['authorization_groups'].value,
    );
    return customer;
  }
}
