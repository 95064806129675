import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-enums',
  templateUrl: './dialog-enums.component.html',
  styleUrl: './dialog-enums.component.css'
})
export class DialogEnumsComponent implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnCancelTxt: string;
  public btnSubmitTxt: string;
  public obj: any;
  public typ: string;
  public enmusData: any[] = [];
  public enums: string[] = [];
  public colors: string[] = [];
  public subscription: Subscription;
  public selectedIndex;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogEnum$.subscribe((data: any) => {
      this.show = true;
      this.title = data.title;
      this.message = data.message;
      this.btnCancelTxt = data.btn_cancel_txt;
      this.btnSubmitTxt = data.btn_submit_txt;
      this.typ = data.typ;
      this.enums = data.enums;
      this.colors = data.colors;
      this.selectedIndex = data.selected_value;
      this.obj = data.obj;
      this.enmusData = [];
      for(let i = 0; i < this.enums.length; i++){
        this.enmusData.push({
          name: this.enums[i],
          color: this.colors ? this.colors[i] : null
        })
      }
      setTimeout(() => {
        this.setSelectionStyle(this.selectedIndex);
      }, 50);
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectionEnum(index: number) {
    this.selectedIndex = index;
    this.setSelectionStyle(index);
  }

  setSelectionStyle(index: number) {
    const dialogBody = document.getElementById('dialog-body-enums-wrapper');
    if(dialogBody){
      for(let i = 0; i < dialogBody.children.length; i++){
        const button = dialogBody.children[i] as HTMLButtonElement;
        if(i == index){
          button.style.color = this.enmusData[index].color;
          button.style.backgroundColor = this.enmusData[index].color+'50';
        } else {
          button.style.color = '#2C2C2C';
          button.style.backgroundColor = '#F2F2F2';
        }
      }
    }
  }

  onSubmit(){
    this.show = false;
    this.dialogService.closeEnum(
      {
        obj: this.obj,
        submit_value: this.selectedIndex,
        typ: this.typ
      }
    );
  }
}
