import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { EmployeeForm } from 'src/app/buisness-object/employee/form/EmployeeForm';
import { Location } from 'src/app/buisness-object/location/Location';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrl: './user-create.component.css'
})
export class UserCreateComponent implements OnInit, OnDestroy {
  @Input() obj: Employee;
  @Input() locations: Location[] = [];
  @Output() selectionCancelEmitter = new EventEmitter<any>();
  @Output() createSuccessEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public submitted = false;
  public formValuesChanged = false;
  public locationValues = [];
  public roles = [];
  public salutationValue;
  public salutationValues;
  public titleValues;
  public dialogQuerySubsription: Subscription;
  public isUpdatingAddressValidity = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private employeeService: EmployeeService,
    private dialogService: DialogService,
    private enumService: EnumService
  ){}

  ngOnInit(): void {
    this.setup();
    this.setDropdownValues();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionCancelEmitter.emit();
      }
    });
  }

  setDropdownValues() {
    for(let v of this.locations){
      this.locationValues.push({
        id: v.locationId,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'location'){
      let index = this.locationValues.findIndex(v => v.id == id);
      if(index > -1) this.form.controls["location"].setValue(this.locations[index]);
    }
  }

  setup() {
    this.form = EmployeeForm.getForm(this.formBuilder, this.obj);
    let t = this.enumService.getEnums('title');
    if(this.obj){
      for(let i = 0 ; i < t.length; i++){
        if(t[i].value == this.obj.title){
          this.form.get('title').setValue(t[i].raw_value)
        }
      }
    }
    if(!this.obj){
      this.form.get('password').addValidators(Validators.required);
      this.form.get('password').updateValueAndValidity();
    }
    this.roles = this.authService.getRoles();
    this.salutationValues = this.enumService.getEnumValues('salutation');
    this.form.valueChanges.subscribe((changes) => {
      if(changes){
        this.formValuesChanged = true;
      }
    });
    if(this.form.get('address_id').value == null){
      combineLatest([
        this.form.get('street').valueChanges.pipe(startWith(this.form.get('street').value)),
        this.form.get('postal_code').valueChanges.pipe(startWith(this.form.get('postal_code').value)),
        this.form.get('city').valueChanges.pipe(startWith(this.form.get('city').value))
      ]).subscribe(([street, postalCode, city]) => {
        if(!this.isUpdatingAddressValidity){
          if (street || postalCode || city) {
            this.addOrRemoveAddressRequirement(true);
          } else {
            this.addOrRemoveAddressRequirement(false);
          }
        }
      });
    }
  }

  addOrRemoveAddressRequirement(required: boolean) {
    this.isUpdatingAddressValidity = true;
    const street = this.form.get('street');
    const postalCode = this.form.get('postal_code');
    const city = this.form.get('city');
    if(required) {
      street.setValidators([Validators.required]);
      postalCode.setValidators([Validators.required]);
      city.setValidators([Validators.required]);
    } else {
      street.clearValidators();
      postalCode.clearValidators();
      city.clearValidators();
    }
    street.updateValueAndValidity({ emitEvent: false });
    postalCode.updateValueAndValidity({ emitEvent: false });
    city.updateValueAndValidity({ emitEvent: false });
    this.isUpdatingAddressValidity = false;
  }

  selectionCancel() {
    if(this.formValuesChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  async selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let obj = EmployeeForm.getObject(this.form);
    obj.role = this.form.controls.role.value;
    obj.roles = this.authService.getAuthenticationRoles(this.form.controls.role.value);
    obj.location = this.locations.find((l) => l.locationId == this.form.get('location_id').value);
    const newObj = await this.updateUser(obj).toPromise();
    if(this.form.get('create_password').value == true){
      await this.updateUserPassword(newObj.id, this.form.get('password').value).toPromise();
    }
    newObj.role = obj.role;
    newObj.roles = obj.roles;
    newObj.location = obj.location;
    this.dialogService.showNotification({
      title: 'Erfolgreich',
      message: 'Mitarbeiter aktualisiert.',
      success: true
    });
    this.updateSuccessEmitter.emit(newObj);
  }

  updateUser(user: Employee): Observable<Employee|null> {
    return this.employeeService.updateEmployee(user).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }),catchError((error) => {
        console.log(error);
        return of(null);
      })
    )
  }

  updateUserPassword(userId: number, password: string): Observable<boolean> {
    return this.employeeService.updateEmployeePassword(userId, password).pipe(
      map((success) => {
        if(success){
          return success;
        }
      }),catchError((error) => {
        console.log(error);
        return of(false);
      })
    )
  }

  selectionCreate() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let obj = EmployeeForm.getObject(this.form);
    obj.role = this.form.controls.role.value;
    obj.roles = this.authService.getAuthenticationRoles(this.form.controls.role.value);
    obj.location = this.locations.find((l) => l.locationId == this.form.get('location_id').value);
    this.employeeService.createEmployee(obj).subscribe((result) => {
      if(result){
        result.role = obj.role;
        result.roles = obj.roles;
        result.location = obj.location;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Mitarbeiter angelegt.',
          success: true
        });
        this.createSuccessEmitter.emit(result);
      }
    });
  }

  setTitleString(enumNum: number) {
    this.form.get('title').setValue(this.enumService.getEnumValue('title', enumNum))
  }
}
