<div *ngIf="showView == view.LIST">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <button class="button_type_1" (click)="showDropdownMainButton = !showDropdownMainButton" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
          <div *ngIf="showDropdownMainButton">
            <button (click)="showView = view.CREATE; showDropdownMainButton = false">Kurzzeitmiete</button>
          </div>
        </button>


        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [objectTyp]="'rent'"
        [objects]="_objectsFiltered.getValue()"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        (pageEmitter)="pageHandling($event)"
        (applySortingEmitter)="applySorting($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (openEditEmitter)="selectionEdit($event)"
        (deleteEmitter)="selectionDelete($event)"
    ></app-list-container>
  </div>

  <app-miete-create *ngIf="showView == view.CREATE"
    [obj]="selectedObject"
    [customers]="customers"
    [vehicles]="vehicles"
    (selectionCancelEmitter)="selectionCancelCreate()"
    (updateSuccessEmitter)="updateSuccess($event)"
    (createSuccessEmitter)="createSuccess($event)"
  ></app-miete-create>

  <app-miete-details *ngIf="showView == view.DETAILS"
    [obj]="selectedObject"
    (selectionCloseEmitter)="selectionCloseDetails()"
    (selectionEditEmitter)="selectionEdit($event)"
    (selectionReturnEmitter)="selectionReturn($event)"
    (slectionDocumentEmitter)="selectionDocument()"
    (selectionCancelRentEmitter)="selectionCancelRent()">
  </app-miete-details>


  <app-dialog-miete-return *ngIf="showDialogReturn"
  [obj]="selectedObject"
  (selectionCloseEmitter)="selectionCloseReturn()">
  </app-dialog-miete-return>

  <app-dialog-miete-document *ngIf="showDialogDocument"
  [obj]="selectedObject"
  (selectionCloseEmitter)="selectionCloseDocument()">
  </app-dialog-miete-document>

  <app-dialog-miete-cancel *ngIf="showDialogCancel"
    (selectionCloseEmitter)="selectionCloseCancelRent()">
  </app-dialog-miete-cancel>