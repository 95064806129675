import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthGuard } from 'src/app/helpers/AuthGuard';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-topbar-customer',
  templateUrl: './topbar-customer.component.html',
  styleUrls: ['./topbar-customer.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class TopbarCustomerComponent implements OnInit, OnDestroy {
  @Input() surface: number;
  @Input() showSurfaceOverview: number;
  @Input() filter: any;
  @Input() searchValue: string;
  @Input() selectedCustomer: Customer;
  @Input() accessEdit: boolean;
  @Input() sortObject: any;
  @Output() changeSurfaceEmitter = new EventEmitter<number>();
  @Output() saveCustomerEmitter = new EventEmitter<number>();
  @Output() openCreateEmitter = new EventEmitter<boolean>();
  @Output() editCustomerEmitter = new EventEmitter<undefined>();
  @Output() cancelEditEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<any>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  @Output() applySortEmitter = new EventEmitter<undefined>();
  @Output() resetSortEmitter = new EventEmitter<undefined>();
  @Output() openCreateCustomerCar = new EventEmitter<undefined>();
  @Output() openCreateDokument = new EventEmitter<number>();
  @Output() cancelCreateCustomerCar = new EventEmitter<undefined>();
  @Output() createCustomerCarEmitter = new EventEmitter<undefined>();
  @Output() selectionCancelPdfShowEmitter = new EventEmitter<undefined>();
  @Output() selectionChangeSurfaceOverviewEmitter = new EventEmitter<number>();
  @Output() signPDFEmitter = new EventEmitter<undefined>();
  @Output() createDataprivacyEmitter = new EventEmitter<undefined>();
  public showFilter = false;
  public showSort = false;
  public inputTimeout: any;
  public showDropdownOne = false;
  public showDropdownTwo = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
  }

  changeTab(num: number) {
    this.filter.status = num;
    this.applyFilterEmitter.emit();
  }

  searchAction(value: string) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.searchEmitter.emit(value);
    }, 300);
  }


  goToCreateDocument(typ) {
    this.router.navigate(['documents/create'], { queryParams: { typ: typ, customerId: this.selectedCustomer.id } });
  }

}
