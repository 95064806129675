import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  //Dialog-Confirm
  private openConfirm = new Subject<any>();
  openConfirm$ = this.openConfirm.asObservable();
  private closeConfirm = new Subject<any>();
  closeConfirm$ = this.closeConfirm.asObservable();

  private notification = new Subject<any>();
  notification$ = this.notification.asObservable();

  private openDialogQuery = new Subject<any>();
  openDialogQuery$ = this.openDialogQuery.asObservable();
  private closeDialogQuery = new Subject<any>();
  closeDialogQuery$ = this.closeDialogQuery.asObservable();

  private openDialogQueryCarOnline = new Subject<any>();
  openDialogQueryCarOnline$ = this.openDialogQueryCarOnline.asObservable();
  private closeDialogQueryCarOnline = new Subject<any>();
  closeDialogQueryCarOnline$ = this.closeDialogQueryCarOnline.asObservable();

  private openDialogPassword = new Subject<any>();
  openDialogPassword$ = this.openDialogPassword.asObservable();
  private closeDialogPassword = new Subject<any>();
  closeDialogPassword$ = this.closeDialogPassword.asObservable();

  private openDialogContractCarCondition = new Subject<any>();
  openDialogContractCarCondition$ = this.openDialogContractCarCondition.asObservable();
  private closeDialogContractCarCondition = new Subject<any>();
  closeDialogContractCarCondition$ = this.closeDialogContractCarCondition.asObservable();

  private openDialogCreateDoc = new Subject<any>();
  openDialogCreateDoc$ = this.openDialogCreateDoc.asObservable();
  private closeDialogCreateDoc = new Subject<any>();
  closeDialogCreateDoc$ = this.closeDialogCreateDoc.asObservable();

  private openDialogMove = new Subject<any>();
  openDialogMove$ = this.openDialogMove.asObservable();
  private closeDialogMove = new Subject<any>();
  closeDialogMove$ = this.closeDialogMove.asObservable();

  private openDialogValue = new Subject<any>();
  openDialogValue$ = this.openDialogValue.asObservable();
  private closeDialogValue = new Subject<any>();
  closeDialogValue$ = this.closeDialogValue.asObservable();

  private openDialogReparatur = new Subject<any>();
  openDialogReparatur$ = this.openDialogReparatur.asObservable();
  private closeDialogReparatur = new Subject<any>();
  closeDialogReparatur$ = this.closeDialogReparatur.asObservable();

  private openDialogLoaderSignature = new Subject<any>();
  openDialogLoaderSignature$ = this.openDialogLoaderSignature.asObservable();
  private closeDialogLoaderSignature = new Subject<any>();
  closeDialogLoaderSignature$ = this.closeDialogLoaderSignature.asObservable();

  private openDialogCreateContract = new Subject<any>();
  openDialogCreateContract$ = this.openDialogCreateContract.asObservable();
  private closeDialogCreateContract = new Subject<any>();
  closeDialogCreateContract$ = this.closeDialogCreateContract.asObservable();

  private openDialogChoosePdf = new Subject<any>();
  openDialogChoosePdf$ = this.openDialogChoosePdf.asObservable();
  private closeDialogChoosePdf = new Subject<any>();
  closeDialogChoosePdf$ = this.closeDialogChoosePdf.asObservable();

  private openImageSlider_ = new Subject<any>();
  openImageSlider_$ = this.openImageSlider_.asObservable();

  private openAttachmentViewer_ = new Subject<any>();
  openAttachmentViewer_$ = this.openAttachmentViewer_.asObservable();
  private closeAttachmentViewer_ = new Subject<any>();
  closeAttachmentViewer_$ = this.closeAttachmentViewer_.asObservable();

  private openDialogEnum = new Subject<any>();
  openDialogEnum$ = this.openDialogEnum.asObservable();
  private closeDialogEnum = new Subject<any>();
  closeDialogEnum$ = this.closeDialogEnum.asObservable();

  constructor() { }

  openConfirmDialog(data: any) {
    this.openConfirm.next(data);
  }
  closeConfirmDialog(data: any) {
    this.closeConfirm.next(data);
  }


  showNotification(data: any) {
    this.notification.next(data);
  }

  openQuery(data: any) {
    this.openDialogQuery.next(data);
  }
  closeQuery(data: any) {
    this.closeDialogQuery.next(data);
  }

  openQueryCarOnline(data: any) {
    this.openDialogQueryCarOnline.next(data);
  }
  closeQueryCarOnline(data: any) {
    this.closeDialogQueryCarOnline.next(data);
  }

  openPasswordDialog(data: any) {
    this.openDialogPassword.next(data);
  }
  closePasswordDialog(data: any) {
    this.closeDialogPassword.next(data);
  }

  openContractCarCondition(data: any) {
    this.openDialogContractCarCondition.next(data);
  }
  closeContractCarCondition(data: any) {
    this.closeDialogContractCarCondition.next(data);
  }

  openCreateDoc(data: any) {
    this.openDialogCreateDoc.next(data);
  }
  closeCreateDoc(data: any) {
    this.closeDialogCreateDoc.next(data);
  }

  openMove(data: any) {
    this.openDialogMove.next(data);
  }
  closeMove(data: any) {
    this.closeDialogMove.next(data);
  }

  openValue(data: any) {
    this.openDialogValue.next(data);
  }
  closeValue(data: any) {
    this.closeDialogValue.next(data);
  }

  openReparatur(data: any) {
    this.openDialogReparatur.next(data);
  }
  closeReparatur(data: any) {
    this.closeDialogReparatur.next(data);
  }

  openChoosePdf(data: any) {
    this.openDialogChoosePdf.next(data);
  }
  closeChoosePdf(data: any) {
    this.closeDialogChoosePdf.next(data);
  }

  openLoaderSignature(data: any) {
    this.openDialogLoaderSignature.next(data);
  }
  closeLoaderSignature(data: any) {
    this.closeDialogLoaderSignature.next(data);
  }

  openCreateContract(data: any) {
    this.openDialogCreateContract.next(data);
  }
  closeCreateContract(data: any) {
    this.closeDialogCreateContract.next(data);
  }

  OpenImageSlider(data: any) {
    this.openImageSlider_.next(data);
  }

  openAttachementViewer(data: any) {
    this.openAttachmentViewer_.next(data);
  }
  closeAttachementViewer(data: any) {
    this.closeAttachmentViewer_.next(data);
  }

  openEnum(data: any) {
    this.openDialogEnum.next(data);
  }
  closeEnum(data: any) {
    this.closeDialogEnum.next(data);
  }
}
