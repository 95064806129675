import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { Offer } from 'src/app/buisness-object/document/Offer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { OutputHelper } from 'src/app/helpers/OutputHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

export enum TransactionDataTyp {
  VEHICLE = 2,
  CUSTOMER = 1,
  DOCUMENT = 3,
  PURCHASE_CONTRACT = 4,
  BILL = 7,
  EMPLOYEE = 6,
  TIRE = 5,
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit, OnChanges {
  @Input() obj: any;

  public icon;
  public color;
  public transactions: Transaction[] = [];

  constructor(
    private transactionService: TransactionService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    let objectTyp;
    let objectId;
    if(this.obj instanceof Vehicle){
      objectTyp = 'vehicle';
      objectId = this.obj.id;
    } else if(this.obj instanceof Employee) {
      objectTyp = 'employee';
      objectId = this.obj.id;
    } else if(this.obj instanceof Customer) {
      objectTyp = 'customer';
      objectId = this.obj.id;
    } else if(this.obj instanceof Kaufvertrag) {
      objectTyp = 'document';
      objectId = this.obj.purchase_contract_id;
    } else if(this.obj instanceof LedgerEntry) {
      objectTyp = 'ledgerentry';
      objectId = this.obj.ledger_entry_id;
    } else if(this.obj instanceof Tire) {
      objectTyp = 'tire';
      objectId = this.obj.tire_id;
    } else if(this.obj instanceof Bill) {
      objectTyp = 'bill';
      objectId = this.obj.bill_id;
    }
    if(objectTyp != null && objectTyp != null){
      this.transactionService.getTransactions(objectTyp, objectId).subscribe((trans) => {
        if(trans){
          this.transactions = trans;
          for(let t of this.transactions){
            switch (t.dataTyp) {
              case TransactionDataTyp.VEHICLE:
                t.dataTypStr = 'vehicle';
                t.icon = './assets/car-transaction.svg';
                t.color = '#597CF1';
                break;
                case TransactionDataTyp.EMPLOYEE:
                t.dataTypStr = 'employee';
                t.icon = './assets/user-transaction.svg';
                t.color = '#6A9E18';
                break;
                case TransactionDataTyp.CUSTOMER:
                t.dataTypStr = 'customer';
                t.icon = './assets/user-transaction.svg';
                t.color = '#6A9E18';
                break;
                case TransactionDataTyp.PURCHASE_CONTRACT:
                t.dataTypStr = 'document';
                t.icon = './assets/car-transaction.svg';
                t.color = '#D2B836';
                break;
                case TransactionDataTyp.BILL:
                t.dataTypStr = 'document';
                t.icon = './assets/car-transaction.svg';
                t.color = '#D2B836';
                break;
                case TransactionDataTyp.TIRE:
                  t.dataTypStr = 'tire';
                  t.icon = './assets/tire-transaction.svg';
                  t.color = '#6A9E18';
                  break;
                case TransactionDataTyp.BILL:
                  t.dataTypStr = 'tire';
                  t.icon = './assets/car-transaction.svg';
                  t.color = '#6A9E18';
                  break;
              default: break;
            }
          }
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["obj"].previousValue != undefined && changes["obj"].currentValue?.tire_id != changes["obj"].previousValue?.tire_id){
      this.ngOnInit();
    }
  }

  showTransactionChanges(transaction: Transaction) {
    const changesList = document.getElementById('transaction-changes_'+transaction.transactionId);
    if(changesList){
      if(changesList.style.display === 'none' || changesList.style.display === '') {
        changesList.style.display = 'block';
      } else {
        changesList.style.display = 'none';
      }
    }
  }
}
