<div id="outer_container">
    <div [ngClass]="!showMenu(outlet) ? '' : 'main_container'" class="main">
        <router-outlet #outlet="outlet" class="main-inner"></router-outlet>
    </div>
    <app-dialog-query></app-dialog-query>
    <app-dialog-car-online></app-dialog-car-online>
    <app-dialog-notification></app-dialog-notification>
    <app-dialog-move></app-dialog-move>
    <app-dialog-create-document></app-dialog-create-document>
    <app-contract-vehicle-condition></app-contract-vehicle-condition>
    <app-dialog-number></app-dialog-number>
    <app-reparatur-dialog></app-reparatur-dialog>
    <app-dialog-create-contract></app-dialog-create-contract>
    <app-image-slider></app-image-slider>
    <app-attachment-viewer></app-attachment-viewer>
    <app-dialog-pdf-download></app-dialog-pdf-download>
    <app-dialog-enums></app-dialog-enums>
    <app-loading [isLogin]="!showMenu(outlet)" [isProgressbar]="isProgressbar()"></app-loading>
    <app-sidebar *ngIf="showMenu(outlet)"></app-sidebar>
</div>
<div *ngIf="isTest" id="test_identifier">
  <h3>Test</h3>
  <label>{{version}}</label>
</div>
<app-open-signature-loader></app-open-signature-loader>
