<form id="price_sheets_create_container" [formGroup]="form">
    <div class="price_sheets_create_page_column">
        <div class="data_box" [ngClass]="(form.controls['preis_anschreiben'].value == 0 || form.controls['preis_anschreiben'].value == undefined) ? 'data_box_highlighted' : ''">
          <h3>Preiskalkulation</h3>
          <div class="input_wrapper" *ngIf="isHoedl">
            <label>Min. Verkaufspreis (brutto)</label>
            <label  class="visible_column_text">{{getInputValueCurrency(form.controls['verkaufspreis_break_even'].value)}}</label>
        </div>
          <div class="input_wrapper">
              <label>Verkaufspreis (brutto)</label>
              <input type="number" placeholder="---" formControlName="preis_anschreiben">
          </div>
          <div class="input_wrapper" *ngIf="!isHoedl">
              <label>Händlerpreis (brutto)</label>
              <input type="number" placeholder="---" formControlName="minimaler_verkaufpreis">
          </div>
          <div class="input_wrapper slider_grid" *ngIf="isHoedl">
            <div class="overview_record_lbl">Export freischalten?</div>
            <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                <input type="checkbox" [checked]="form.controls['export_price_enabled'].value" (click)="form.controls['export_price_enabled'].setValue(!form.controls['export_price_enabled'].value)">
                <span class="slider round"></span>
            </label>
          </div>
          <div class="input_wrapper" *ngIf="isHoedl && form.controls['export_price_enabled'].value">
            <label>Exportpreis kalkuliert (brutto)</label>
            <label  class="visible_column_text">{{getInputValueCurrency(form.controls['export_price_calculated'].value)}}</label>
          </div>
          <div class="input_wrapper slider_grid" *ngIf="isHoedl && form.controls['export_price_enabled'].value">
            <div class="overview_record_lbl">Exportpreis manuell festlegen</div>
            <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                <input type="checkbox" [checked]="form.controls['export_price_checked'].value" (click)="changeExportPriceState()">
                <span class="slider round"></span>
            </label>
          </div>
          <div class="input_wrapper" *ngIf="isHoedl && form.controls['export_price_checked'].value && form.controls['export_price_enabled'].value">
            <label>Exportpreis</label>
            <input type="number" placeholder="---" formControlName="export_price">
          </div>
          <div class="input_wrapper" *ngIf="isHoedl && !form.controls['export_price_checked'].value && form.controls['export_price_enabled'].value">
            <label>Exportpreis</label>
            <input [class.input_disabled]="true" type="number" placeholder="---" formControlName="export_price_calculated">
          </div>
      </div>
      <div class="data_box">
          <div class="inner_grid">
            <h3>Kalkulationsgrundlage</h3>
            <div *ngIf="vehicle.platform_online && vehicle.as_id" class="overview_record_value">
              <a href='https://autoscout24.at/angebote/{{vehicle.as_id}}' class="overview_record_link" target="_blank">
                Fahrzeug auf AS24
                <img src="./assets/linksymbol.svg" class="link-icon">
              </a>
            </div>
          </div>
          <div class="input_wrapper">
              <label class="overview_record_lbl">Einkaufspreis (brutto)</label>
              <input id="inputEinkaufspreis" type="number" placeholder="---" formControlName="einkaufspreis_brutto" [class.input_invalid]="submitted && form.controls['einkaufspreis_brutto'].errors">
          </div>
          <app-dropdown-multiple *ngIf="form.controls['steuersatz'].value == null"
          [title]="'Steuersatz'"
          [formcontrol]="form.controls['steuersatz']"
          [headerText]="getSteuersatz(form.controls['steuersatz'].value)"
          [values]="steuerseatze"
          [submitted]="submitted"
          (submitValueEmitter)="setSteuersatz($event)"
        ></app-dropdown-multiple>
        <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value">
          <label>Steuersatz</label>
          <label class="visible_column_text">{{getSteuersatz(form.controls['steuersatz'].value)}} ({{getSteueratzValue(form.controls['steuersatz'].value)}}%) <img (click)="editSteuersatz()" src="assets/editdetailview.svg"></label>
        </div>
        <div class="input_wrapper">
          <label>Einkaufspreis (netto)</label>
          <label  class="visible_column_text">{{getInputValueCurrency(form.controls['einkaufspreis_netto'].value)}}</label>
        </div>
        <div class="input_wrapper" *ngIf="form.controls['vehicle_imported'].value == 1">
          <label>Summe NoVA</label>
          <label  class="visible_column_text">{{getInputValueCurrency(form.controls['summe_nova_vor'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <label>Umsatzsteuer</label>
          <label  class="visible_column_text">{{getInputValueCurrency(form.controls['ust_vor'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <label>Beschaffungskosten gesamt</label>
          <label  class="visible_column_text">{{getInputValueCurrency(form.controls['beschaffungskosten_gesamt'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <label>Deckungsbeitrag 1</label>
          <label  [ngClass]="(form.controls['guv_vorkalkulation'].value + form.controls['zinsen_vor'].value+form.controls['garantie_vorkalkulation'].value+form.controls['standzeitkosten_vor'].value) >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'" class="visible_column_text_tag">{{getInputValueCurrency(form.controls['guv_vorkalkulation'].value + form.controls['zinsen_vor'].value+form.controls['garantie_vorkalkulation'].value+form.controls['standzeitkosten_vor'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <label>Zusatzkosten gesamt</label>
          <label  class="visible_column_text">{{getInputValueCurrency(form.controls['zinsen_vor'].value+form.controls['garantie_vorkalkulation'].value+form.controls['standzeitkosten_vor'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <label>Deckungsbeitrag 2</label>
          <label [ngClass]="(form.controls['guv_vorkalkulation'].value) >= 0 ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'" class="visible_column_text_tag">{{getInputValueCurrency(form.controls['guv_vorkalkulation'].value)}}</label>
        </div>
      </div>

    </div>
    <div class="price_sheets_create_page_column">
        <div class="data_box">
            <h3>Details zum Einkauf</h3>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Einkaufsdatum</div>
              <input #inputDate1 type="date" [max]="maxDate" formControlName="einkaufsdatum" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['einkaufsdatum'].errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Zahldatum</div>
              <input #inputDate3 type="date" [max]="maxDate" formControlName="zahldatum" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.controls['zahldatum'].errors">
            </div>
            <app-dropdown-simple *ngIf="showValuePricesheet('vehicle_imported')"
            [title]="'Import'"
            [formcontrol]="form.controls['vehicle_imported']"
            [isOptional]="false"
            (submitValueEmitter)="form.controls['vehicle_imported'].setValue($event)"
          ></app-dropdown-simple>
          <div class="input_wrapper" *ngIf="isHoedl">
            <div class="overview_record_lbl">Abwertung</div>
            <input type="number" min="0" placeholder="---" formControlName="abwertung">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Standzeit Plan-Tage</div>
            <input type="number" step="1" placeholder="---" formControlName="standzeit_plan_tage">
          </div>
        </div>
        <div class="data_box">
          <h3>Beschaffungskosten</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">{{changeValueName('sprit')}}</div>
            <input type="number" placeholder="---" formControlName="sprit">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">{{changeValueName('lieferant_extern')}}</div>
            <input type="number" placeholder="---" formControlName="lieferant_extern">
          </div>
          <div class="input_wrapper" *ngIf="isHoedl">
            <div class="overview_record_lbl">Zubau</div>
            <input type="number" placeholder="---" formControlName="zubau">
          </div>
        </div>
        <div class="data_box">
          <h3>{{form.controls['vehicle_imported'].value == 1 ? 'NoVA' : 'NoVA Rückvergütung' }}</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">CO&#8322; Emissionen g/km</div>
            <input type="number" [class.input_disabled]="form.controls['co2_checked'].value == true" placeholder="---" formControlName="co2_emission" [class.input_invalid]="submitted && form.controls['co2_emission'].errors">
          </div>
          <div class="input_wrapper slider_grid">
            <div class="overview_record_lbl">CO&#8322; Emissionen geprüft</div>
            <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                <input type="checkbox" [checked]="form.controls['co2_checked'].value" (click)="form.controls['co2_checked'].setValue(!form.controls['co2_checked'].value)">
                <span class="slider round"></span>
            </label>
          </div>
          <div class="input_wrapper" *ngIf="isNoVA()">
            <div class="overview_record_lbl">NoVA-Satz</div>
            <label class="visible_column_text">{{form.controls['nova_satz'].value ? getInputValueNumber(form.controls['nova_satz'].value) + "%" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isNoVA()">
            <div class="overview_record_lbl">NoVA-Satz</div>
            <label class="visible_column_text">{{form.controls['nova_verguetung_satz'].value ? getInputValueNumber(form.controls['nova_verguetung_satz'].value) + "%" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="isNoVA()">
            <label class="overview_record_lbl">NoVA Grundbetrag</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['nova_grundbetrag'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isNoVA()">
            <label class="overview_record_lbl">NoVA Grundbetrag</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['nova_verguetung_grundbetrag'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="isNoVA()">
            <label class="overview_record_lbl">anteiliger Abzugsposten</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['abzugsposten'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isNoVA()">
            <label class="overview_record_lbl">anteiliger Abzugsposten</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['nova_verguetung_abzugsposten'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="isNoVA()">
            <label class="overview_record_lbl">anteiliger CO2-Malus</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['malus'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isNoVA()">
            <label class="overview_record_lbl">anteiliger CO2-Malus</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['nova_verguetung_malus'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="isNoVA()">
            <label class="overview_record_lbl">Summe NoVA</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['summe_nova_vor'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!isNoVA()">
            <label class="overview_record_lbl">Summe NoVA Rückvergütung</label>
            <label class="visible_column_text">{{getInputValueCurrency(form.controls['nova_verguetung'].value)}}</label>
          </div>
        </div>
    </div>
    <div class="price_sheets_create_page_column">
        <div class="data_box">
            <h3>Reparatur und Fahrzeugkosten</h3>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Aufbereitung</div>
              <input type="number" placeholder="---" formControlName="aufbereitung">
            </div>
        </div>
        <div class="data_box">
          <h3>Zusatzkosten</h3>
          <div class="input_four_column_wrapper">
              <label>Zinsen</label>
              <label class="small_label">Zinssatz in %</label>
              <label class="inner_flex">{{form.controls['zinssatz_vor'].value}}
              <img (click)="editValueWithDialog(form.controls['zinssatz_vor'].value, 'zinssatz_vor')" src="assets/editdetailview.svg"></label>
              <label>{{getInputValueNumber(form.controls['zinsen_vor'].value)}}</label>
          </div>
          <div class="input_four_column_wrapper">
            <label>⌀ Gewährleistung</label>
            <label class="small_label">% vom Verkaufspreis</label>
            <label class="inner_flex">{{form.controls['garantie_von_einkaufspreis'].value}}
            <img (click)="editValueWithDialog(form.controls['garantie_von_einkaufspreis'].value, 'garantie_von_einkaufspreis')" src="assets/editdetailview.svg"></label>
            <label>{{getInputValueNumber(form.controls['garantie_vorkalkulation'].value)}}</label>
        </div>
        <div class="input_four_column_wrapper">
          <label>Standzeitkosten</label>
          <label class="small_label">Kosten je Tag</label>
          <label class="inner_flex">{{form.controls['standzeitkosten_je_tag_vor'].value}}
          <img (click)="editValueWithDialog(form.controls['standzeitkosten_je_tag_vor'].value, 'standzeitkosten_je_tag_vor')" src="assets/editdetailview.svg"></label>
          <label>{{getInputValueNumber(form.controls['standzeitkosten_vor'].value)}}</label>
        </div>
        </div>
    </div>
</form>
