<div class="header-base-style">
  <div class="header-inner-wrapper">
      <button class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
      </button>
      <h2>Mitarbeiter {{obj ? 'bearbeiten' : 'anlegen'}}</h2>
  </div>
  <div class="header-button-wrapper">
    <button *ngIf="obj" class="btn_submit" (click)="selectionSave()">Speichern</button>
    <button *ngIf="!obj" class="btn_submit" (click)="selectionCreate()">Anlegen</button>
  </div>
</div>
<form id="create-body" class="body-base-style" [formGroup]="form" *ngIf="form">
  <div class="create-body-column">
    <div class="body_column_block">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Stammdaten</h3>
        <div collapsible-content>
          <app-dropdown-enum
              [name]="'Anrede *'"
              [placeholder]="'---'"
              [control]="form.get('salutation')"
              [enumName]="'salutation'"
              [submitted]="submitted"
          ></app-dropdown-enum>
          <app-dropdown-enum
              [name]="'Titel'"
              [placeholder]="'---'"
              [control]="form.get('title')"
              [enumName]="'title'"
              [submitted]="submitted"
              (submitValueEmitter)="setTitleString($event)"
          ></app-dropdown-enum>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Vorname *</div>
            <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && form.controls.firstName.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Nachname *</div>
            <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && form.controls.lastName.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Geburtsdatum</div>
            <input #inputDate1 type="date" placeholder="date" [max]="maxDate" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.birthdate.errors">
          </div>
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Kontakt und Anschrift</h3>
        <div collapsible-content>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Straße, Nr.</div>
            <input type="text" placeholder="Straße, Nr." formControlName="street" [class.input_invalid]="submitted && form.get('street').invalid">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">PLZ</div>
            <input type="text" placeholder="PLZ" formControlName="postal_code" [class.input_invalid]="submitted && form.controls.postal_code.invalid">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Ort</div>
            <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && form.controls.city.invalid">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Land</div>
            <input type="text" placeholder="Land" formControlName="country" [class.input_invalid]="submitted && form.controls.country.invalid">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">E-Mail *</div>
            <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && form.controls.email.invalid">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Telefon</div>
            <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && form.controls.phone.invalid">
          </div>
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Führerschein-Daten</h3>
        <div collapsible-content>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Führerschein-Nummer</div>
            <input type="text" placeholder="Führerschein-Nummer" formControlName="authorization_number" [class.input_invalid]="submitted && form.controls.authorization_number.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Führerschein-Behörde</div>
            <input type="text" placeholder="Führerschein-Behörde" formControlName="authorization_authority" [class.input_invalid]="submitted && form.controls.authorization_authority.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Führerschein-Datum</div>
            <input #inputDate1 type="date" placeholder="Führerschein-Datum" [max]="maxDate" formControlName="authorization_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.authorization_date.errors">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Führerschein-Gruppen</div>
            <input type="text" placeholder="Führerschein-Gruppen" formControlName="authorization_groups" [class.input_invalid]="submitted && form.controls.authorization_groups.errors">
          </div>
        </div>
      </app-collapsible>
    </div>
  </div>
  <div class="create-body-column">
    <div class="body_column_block">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Systemrelevante Informationen</h3>
        <div collapsible-content>
            <app-dropdown-strings
              [title]="'Berechtigung'"
              [placeholder]="'---'"
              [formcontrol]="form.get('role')"
              [headerText]="form.get('role').value"
              [values]="roles"
              [submitted]="submitted"
              (submitValueEmitter)="form.get('role').setValue($event);"
            ></app-dropdown-strings>
            <app-dropdown-simple
              [title]="'Aktiv'"
              [formcontrol]="form.get('active')"
              (submitValueEmitter)="form.get('active').setValue($event)"
            ></app-dropdown-simple>
          <!-- <div class="input_wrapper">
            <div class="overview_record_lbl">Planungsfarbe</div>
            <input class="planning_color_input" type="color" placeholder="Planungsfarbe" formControlName="planning_color" [class.input_invalid]="submitted && form.controls.planning_color.errors">
          </div> -->
          <div class="input_wrapper">
            <div class="overview_record_lbl">Username*</div>
            <input type="text" placeholder="Username" formControlName="username" [class.input_invalid]="submitted && form.controls.username.errors">
          </div>
          <button class="btn_label_blue"
            type="button"
            *ngIf="obj" (click)="form.get('create_password').setValue(!form.get('create_password').value)">
            {{form.get('create_password').value?'Kein neues Passwort festlegen':'Neues Passwort festlegen'}}
          </button>
          <div class="input_wrapper" *ngIf="form.get('create_password').value">
            <div class="overview_record_lbl">{{obj != null ? 'Neues' : ''}} Passwort festlegen*</div>
            <input type="text"
              placeholder="Passwort"
              formControlName="password"
              [class.input_invalid]="submitted && form.get('password').invalid">
          </div>
          <label class="overview_record_lbl lbl-info" *ngIf="form.get('create_password').value">Anforderungen für das Passwort:
            <ul>
              <li>Das Passwort muss mindestens 8 Zeichen lang sein.</li>
              <li>Sonderzeichen sind erlaubt, aber nicht zwingend erforderlich.</li>
            </ul>
          </label>
        </div>
      </app-collapsible>
    </div>
  </div>
</form>


<!-- <form id="create_employee_container" [formGroup]="form">
  <div class="lbl_bold_light_color">{{obj ? 'Mitarbeiter bearbeiten':'Mitarbeiter anlegen'}}</div>
  <div id="create_employee_body">
    <div class="body_column">
      <div class="body_column_block">
        <div class="overview_data_box_lbl_title">Userdaten</div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Vorname *</div>
          <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && form.controls.firstName.errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Nachname *</div>
          <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && form.controls.lastName.errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">E-Mail *</div>
          <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && form.controls.email.errors">
        </div>
      </div>
      <div class="body_column_block">
        <div class="overview_data_box_lbl_title">Zugangsdaten</div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Username *</div>
          <input type="text" placeholder="Username" formControlName="username" [class.input_invalid]="submitted && form.controls.username.errors">
        </div>
        <div class="input_wrapper" *ngIf="!obj">
          <div class="overview_record_lbl">Passwort *</div>
          <input type="text" placeholder="Passwort" formControlName="password" [class.input_invalid]="submitted && form.controls['password'].errors">
        </div>
        <label class="overview_record_lbl lbl-info" *ngIf="!obj">Anforderungen für das Passwort:
          <ul>
            <li>Das Passwort muss mindestens 8 Zeichen lang sein.</li>
            <li>Sonderzeichen sind erlaubt, aber nicht zwingend erforderlich.</li>
          </ul>
        </label>
        <button class="btn_label_blue" *ngIf="obj" (click)="form.controls['create_new_password'].setValue(!form.controls['create_new_password'].value)">Neues Passwort festlegen</button>
        <div class="input_wrapper" *ngIf="obj && form.controls['create_new_password'].value">
          <div class="overview_record_lbl">Neues Passwort festlegen</div>
          <input type="text" placeholder="Passwort" formControlName="password_new" [class.input_invalid]="submitted && (form.controls['password_new'].errors || form.controls['password_new'].value == null)">
        </div>
        <label class="overview_record_lbl lbl-info" *ngIf="obj && form.controls['create_new_password'].value">Anforderungen für das Passwort:
          <ul>
            <li>Das Passwort muss mindestens 8 Zeichen lang sein.</li>
            <li>Sonderzeichen sind erlaubt, aber nicht zwingend erforderlich.</li>
          </ul>
        </label>
      </div>
    </div>
    <div class="body_column">
      <div class="body_column_block">
        <div class="overview_data_box_lbl_title">Beaufauftragung</div>
        <app-dropdown-objects class="do"
          [title]="'Standort'"
          [placeholder]="'Auswählen...'"
          [headervalue]="form.controls['location'].value?.name"
          [formcontrol]="form.controls.location"
          [values]="locationValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('location', $event)"
        ></app-dropdown-objects>
      </div>
      <div class="body_column_block">
        <div class="inner_title_wrapper">
          <div class="overview_data_box_lbl_title" >Berechtigungen</div>
          <label class="lbl_error" *ngIf="submitted && !form.controls.role.value">Wählen Sie eine Berechtigung aus</label>
        </div>
        <div class="radio_container" *ngFor="let role of roles" (click)="form.controls.role.setValue(role)">
          <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="form.controls.role.value == role  ? 'radio_icon_inner_active' : ''"></div></div>
          <div class="lbl_info">{{role}}</div>
        </div>
      </div>
      <div class="body_column_block">
        <div class="status_wrapper">
          <div class="overview_data_box_lbl_title">Status</div>
          <div class="state-container" [ngClass]="form.controls.active.value ? 'state_active' : 'state_inactive'" (click)="form.controls.active.setValue(!form.controls.active.value)">{{form.controls.active.value ? 'Aktiv' : 'Inaktiv'}}</div>
        </div>
      </div>
    </div>
  </div>
</form> -->

