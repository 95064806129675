import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Kaufvertrag, TradeInVehicle, VehicleStatus } from "../Kaufvertrag";
import { DateHelper } from "src/app/utils/DateHelper";
import { CustomerForm } from "../../customer/form/CustomerForm";
import { validDatePast } from "src/app/validators/CustomValidators";
import { Customer } from "../../customer/Customer";
import { SelektiertesZusatzprodukt } from "../../zusatzprodukt/SelektiertesZusatzprodukt";
import { selektierteZusatzproduktForm } from "../../zusatzprodukt/SelektiertesZusatzproduktForm";
import { Zusatzprodukt } from "../../zusatzprodukt/Zusatzprodukt";

export class KaufvertragForm {

  public static getKaufVertragForm(formBuilder: FormBuilder, addons: Zusatzprodukt[], isStandardDefault: boolean, kaufvertrag?: Kaufvertrag,  customer?: Customer): FormGroup {
    let selektierte_zusatzprodukte: FormGroup<any>[] = [];
    let verfuegbare_zusatzprodukte: FormGroup<any>[] = [];
    if(kaufvertrag) {
      for(let zusatzprodukt of kaufvertrag?.selektierteZusatzprodukte){
        selektierte_zusatzprodukte.push(selektierteZusatzproduktForm.getSelektiertesZusatproduktForm(formBuilder, zusatzprodukt))
      }
      selektierte_zusatzprodukte = selektierte_zusatzprodukte.sort((v1: FormGroup, v2: FormGroup) => v1.controls["name"].value > v2.controls["name"].value ? 1 : -1)
      for(let zusatzprodukt of kaufvertrag?.verfuegbareZusatzprodukte){
        verfuegbare_zusatzprodukte.push(selektierteZusatzproduktForm.getZusatproduktForm(formBuilder, zusatzprodukt))
      }
      verfuegbare_zusatzprodukte = verfuegbare_zusatzprodukte.sort((v1: FormGroup, v2: FormGroup) => v1.controls["name"].value > v2.controls["name"].value ? 1 : -1)
    } else {
      for(let zusatzprodukt of addons){
        verfuegbare_zusatzprodukte.push(selektierteZusatzproduktForm.getZusatproduktForm(formBuilder, zusatzprodukt))
      }
      verfuegbare_zusatzprodukte = verfuegbare_zusatzprodukte.sort((v1: FormGroup, v2: FormGroup) => v1.controls["name"].value > v2.controls["name"].value ? 1 : -1)
    }
    let form = formBuilder.group({
      purchase_contract_id: [kaufvertrag ? kaufvertrag.purchase_contract_id : 0],

      customer: [kaufvertrag ? kaufvertrag.customer : (customer ? customer : null), Validators.required],
      customer_form: [kaufvertrag && kaufvertrag?.customer ? CustomerForm.getCustomerForm(formBuilder, kaufvertrag.customer) : (customer ? CustomerForm.getCustomerForm(formBuilder, customer) : null), [Validators.required]],
      selektierte_zusatzprodukte: [selektierte_zusatzprodukte],
      verfuegbare_zusatzprodukte: [verfuegbare_zusatzprodukte],
      vehicle: [kaufvertrag ? kaufvertrag.vehicle : null, Validators.required],

      purchase_date: [kaufvertrag ? kaufvertrag.purchase_date : new Date().getTime(), Validators.required],
      handing_over_date: [kaufvertrag ?  DateHelper.getConvertedDateForInputFull(kaufvertrag.handing_over_date) : null, [Validators.required]],
      price: [kaufvertrag ? kaufvertrag.price : null , Validators.required],
      registration_document: [kaufvertrag ? kaufvertrag.registration_document : 1, Validators.required],
      is_original_document: [kaufvertrag ? kaufvertrag.is_original_document : true, Validators.required],
      mechanical_condition: [kaufvertrag ? kaufvertrag.vehicle_status.mechanical_condition : (isStandardDefault ? 3 : 1), Validators.required],
      body_condition: [kaufvertrag ? kaufvertrag.vehicle_status.body_condition : (isStandardDefault ? 3 : 1), Validators.required],
      paint_condition: [kaufvertrag ? kaufvertrag.vehicle_status.paint_condition : (isStandardDefault ? 3 : 1), Validators.required],
      interior_condition: [kaufvertrag ? kaufvertrag.vehicle_status.interior_condition : (isStandardDefault ? 3 : 1), Validators.required],
      electronic_condition: [kaufvertrag ? kaufvertrag.vehicle_status.electronic_condition : (isStandardDefault ? 3 : 1), Validators.required],
      payment_condition: [kaufvertrag ? kaufvertrag.payment_condition : (isStandardDefault ? 3 : 1), Validators.required],
      total_condition: [isStandardDefault ? 'Klasse 3' : 'Klasse 1'],

      foreign_registration_date: [kaufvertrag ? DateHelper.getConvertedDateForInputFull(kaufvertrag.foreign_registration_date) : null, [validDatePast]],
      purchase_test_wish: [kaufvertrag ? (kaufvertrag.purchase_test_date || kaufvertrag.purchase_tester ? true : false) : false],
      purchase_test_date: [kaufvertrag ? DateHelper.getConvertedDateForInputFull(kaufvertrag.purchase_test_date) : null],
      purchase_tester: [kaufvertrag ? kaufvertrag.purchase_tester : null],
      warranty_period: [kaufvertrag ? kaufvertrag.warranty_period : null],
      deposit: [kaufvertrag ? kaufvertrag.deposit : null],

      trade_in_vehicle: [kaufvertrag && kaufvertrag.trade_in_vehicle?.brand?.makeName != null ? true : false],
      trade_in_vehicle_brand: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.brand : null],
      trade_in_vehicle_model: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.model : null],
      trade_in_vehicle_registration_document: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.registration_document : 1],
      trade_in_vehicle_mileage: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.mileage : null],
      trade_in_vehicle_price: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.price : null],
      trade_in_vehicle_registration_date: [kaufvertrag && kaufvertrag.trade_in_vehicle ? DateHelper.getConvertedDateForInputFull(kaufvertrag.trade_in_vehicle.registration_date) : null],
      trade_in_vehicle_previous_owner: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.previous_owner : null],
      trade_in_vehicle_vin_number: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.vin_number : null],
      trade_in_vehicle_mileage_to_drive: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.mileage_to_drive : null],
      trade_in_vehicle_color: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.color : null],
      trade_in_vehicle_fuel: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.fuel : null],
      trade_in_vehicle_national_code: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.national_code : null],
      trade_in_vehicle_includes_tax: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.includes_tax : null],
      trade_in_vehicle_reduce_price: [kaufvertrag && kaufvertrag.trade_in_vehicle ? kaufvertrag.trade_in_vehicle.reduce_price : null],
      special_conditions: [kaufvertrag ? kaufvertrag.special_conditions : null],

      finanzierungsgesellschaft: [kaufvertrag ? kaufvertrag.finanzierungsgesellschaft : null],
      finanzierungsart: [kaufvertrag ? kaufvertrag.finanzierungsart : null, null],
      finanzierungsart_anzahlung: [kaufvertrag ? kaufvertrag.finanzierungsart_anzahlung : null, null],

      ready_for_operation: [kaufvertrag ? kaufvertrag.ready_for_operation : true],
      test_drive: [kaufvertrag ? kaufvertrag.test_drive : true],
      spare_wheel: [kaufvertrag ? kaufvertrag.spare_wheel : false],
      honey_comb_lifter: [kaufvertrag ? kaufvertrag.honey_comb_lifter : false],
      card_material: [kaufvertrag ? kaufvertrag.card_material : false],
      trailer_hitch: [kaufvertrag ? kaufvertrag.trailer_hitch : false],
      remote_control: [kaufvertrag ? kaufvertrag.remote_control : false],
      rim_lock: [kaufvertrag ? kaufvertrag.rim_lock : false],
      down_payment: [kaufvertrag ? kaufvertrag.down_payment : null],
      down_payment_condition : [kaufvertrag ? kaufvertrag.down_payment_condition : null],
    });
    form.get('trade_in_vehicle').valueChanges.subscribe((change) => {
      if(form.get('trade_in_vehicle').value == true){
        form.get('trade_in_vehicle_brand').addValidators(Validators.required);
        form.get('trade_in_vehicle_brand').updateValueAndValidity();
        form.get('trade_in_vehicle_model').addValidators(Validators.required);
        form.get('trade_in_vehicle_model').updateValueAndValidity();
        form.get('trade_in_vehicle_registration_document').addValidators(Validators.required);
        form.get('trade_in_vehicle_registration_document').updateValueAndValidity();
        form.get('trade_in_vehicle_mileage').addValidators(Validators.required);
        form.get('trade_in_vehicle_mileage').updateValueAndValidity();
        form.get('trade_in_vehicle_price').addValidators(Validators.required);
        form.get('trade_in_vehicle_price').updateValueAndValidity();
        form.get('trade_in_vehicle_registration_date').addValidators(Validators.required);
        form.get('trade_in_vehicle_registration_date').updateValueAndValidity();
        form.get('trade_in_vehicle_vin_number').addValidators(Validators.required);
        form.get('trade_in_vehicle_vin_number').updateValueAndValidity();
        // form.get('trade_in_vehicle_mileage_to_drive').addValidators(Validators.required);
        // form.get('trade_in_vehicle_mileage_to_drive').updateValueAndValidity();
        // form.get('trade_in_vehicle_color').addValidators(Validators.required);
        // form.get('trade_in_vehicle_color').updateValueAndValidity();
        // form.get('trade_in_vehicle_fuel').addValidators(Validators.required);
        // form.get('trade_in_vehicle_fuel').updateValueAndValidity();
        // form.get('trade_in_vehicle_includes_tax').addValidators(Validators.required);
        // form.get('trade_in_vehicle_includes_tax').updateValueAndValidity();
        // form.get('trade_in_vehicle_reduce_price').addValidators(Validators.required);
        // form.get('trade_in_vehicle_reduce_price').updateValueAndValidity();
      } else {
        form.get('trade_in_vehicle_brand').removeValidators(Validators.required);
        form.get('trade_in_vehicle_brand').updateValueAndValidity();
        form.get('trade_in_vehicle_model').removeValidators(Validators.required);
        form.get('trade_in_vehicle_model').updateValueAndValidity();
        form.get('trade_in_vehicle_registration_document').removeValidators(Validators.required);
        form.get('trade_in_vehicle_registration_document').updateValueAndValidity();
        form.get('trade_in_vehicle_mileage').removeValidators(Validators.required);
        form.get('trade_in_vehicle_mileage').updateValueAndValidity();
        form.get('trade_in_vehicle_price').removeValidators(Validators.required);
        form.get('trade_in_vehicle_price').updateValueAndValidity();
        form.get('trade_in_vehicle_registration_date').removeValidators(Validators.required);
        form.get('trade_in_vehicle_registration_date').updateValueAndValidity();
        form.get('trade_in_vehicle_vin_number').removeValidators(Validators.required);
        form.get('trade_in_vehicle_vin_number').updateValueAndValidity();
        form.get('trade_in_vehicle_mileage_to_drive').removeValidators(Validators.required);
        form.get('trade_in_vehicle_mileage_to_drive').updateValueAndValidity();
        form.get('trade_in_vehicle_color').removeValidators(Validators.required);
        form.get('trade_in_vehicle_color').updateValueAndValidity();
        form.get('trade_in_vehicle_fuel').removeValidators(Validators.required);
        form.get('trade_in_vehicle_fuel').updateValueAndValidity();
        form.get('trade_in_vehicle_includes_tax').removeValidators(Validators.required);
        form.get('trade_in_vehicle_includes_tax').updateValueAndValidity();
        form.get('trade_in_vehicle_reduce_price').removeValidators(Validators.required);
        form.get('trade_in_vehicle_reduce_price').updateValueAndValidity();
      }
    });
    return form;
  }

  public static createObject(form: FormGroup): Kaufvertrag {
    let customer = CustomerForm.createObject(form.controls['customer_form'].value);
    let vehicleStatus = new VehicleStatus(
      form.controls['mechanical_condition'].value,
      form.controls['body_condition'].value,
      form.controls['paint_condition'].value,
      form.controls['interior_condition'].value,
      form.controls['electronic_condition'].value,
    );
    let oldVehicle = form.controls['trade_in_vehicle'].value == true ? new TradeInVehicle(
      form.controls['trade_in_vehicle_brand'].value,
      form.controls['trade_in_vehicle_model'].value,
      form.controls['trade_in_vehicle_registration_document'].value,
      form.controls['trade_in_vehicle_mileage'].value,
      form.controls['trade_in_vehicle_price'].value,
      DateHelper.convertDateStringToTimestamp(form.controls['trade_in_vehicle_registration_date'].value),
      form.controls['trade_in_vehicle_previous_owner'].value,
      form.controls['trade_in_vehicle_vin_number'].value,
      form.controls['trade_in_vehicle_mileage_to_drive'].value,
      form.controls['trade_in_vehicle_color'].value,
      form.controls['trade_in_vehicle_fuel'].value,
      form.controls['trade_in_vehicle_national_code'].value,
      form.controls['trade_in_vehicle_includes_tax'].value,
      form.controls['trade_in_vehicle_reduce_price'].value
    ) : null;
    let contract = new Kaufvertrag(
      form.controls['purchase_contract_id'].value,
      customer.id,
      form.controls['vehicle'].value.id,
      form.controls['purchase_date'].value,
      DateHelper.convertDateStringToTimestamp(form.controls['handing_over_date'].value),
      form.controls['price'].value,
      form.controls['registration_document'].value,
      form.controls['is_original_document'].value,
      vehicleStatus,
      form.controls['payment_condition'].value,
      0,
      DateHelper.convertDateStringToTimestamp(form.controls['foreign_registration_date'].value),
      form.controls['purchase_test_wish'].value ? DateHelper.convertDateStringToTimestamp(form.controls['purchase_test_date'].value) : null,
      form.controls['purchase_test_wish'].value ? form.controls['purchase_tester'].value : null,
      form.controls['warranty_period'].value,
      form.controls['deposit'].value,
      oldVehicle,
      form.controls['special_conditions'].value,
      form.controls['finanzierungsgesellschaft'].value,
      form.controls['finanzierungsart'].value,
      form.controls['ready_for_operation'].value,
      form.controls['test_drive'].value,
      form.controls['spare_wheel'].value,
      form.controls['honey_comb_lifter'].value,
      form.controls['remote_control'].value,
      form.controls['card_material'].value,
      form.controls['trailer_hitch'].value,
      form.controls['rim_lock'].value,
      form.controls['down_payment'].value
    );
    contract.customer = customer;
    contract.vehicle = form.controls['vehicle'].value;
    if(form.controls['selektierte_zusatzprodukte']) {
      contract.selektierteZusatzprodukte = [];
      for(let selektiertesZusatzprodukt of form.controls['selektierte_zusatzprodukte'].value){
        contract.selektierteZusatzprodukte.push(new SelektiertesZusatzprodukt(
          selektiertesZusatzprodukt.controls['zusatzprodukt_id'].value,
          selektiertesZusatzprodukt.controls['kategorie'].value,
          selektiertesZusatzprodukt.controls['preis'].value,
          selektiertesZusatzprodukt.controls['name_editierbar'].value,
          selektiertesZusatzprodukt.controls['preis_selektiert'].value,
          selektiertesZusatzprodukt.controls['name_selektiert'].value)
        );
      }
    }
    return contract;
  }
}
