import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { BaseComponent } from '../../BaseComponent';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RentService } from 'src/app/service/rent/rent.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';


@Component({
  selector: 'app-dialog-miete-return',
  templateUrl: './dialog-miete-return.component.html',
  styleUrl: './dialog-miete-return.component.css'
})
export class DialogMieteReturnComponent implements OnInit {

  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter<any>();

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private rentService: RentService,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
     this.form = RentForm.getForm(this.formBuilder, this.obj);
  }

}
