import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { formatDate } from '@angular/common';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-garantie',
  templateUrl: './garantie.component.html',
  styleUrl: './garantie.component.css'
})
export class GarantieComponent extends BaseComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;

  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public damagesValues = [];
  public damageValue;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.damagesValues = this.getEnumValues('damages');
    this.damageValue = this.getEnumValue('damages', this.vehicle.damages);
  }

  getEnumDataOnChange() {
    this.damageValue = this.getEnumValue('damages', this.form.controls['damages'].value);
  }

  get f() {
    return this.form.controls;
  }
}

