<div id="dialog_window">
    <div id="dialog_container">
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
        <h1>Dokument hochladen</h1>
        <div id="dialog_conainter_body" class="body">
          <app-files
            [obj]="obj">
          </app-files>
        </div>

        <div class="dialog_btn_container">
          <button class="btn_label">Speichern</button>
          <button class="btn_submit_dark">Hochladen</button>
        </div>

    </div>
</div>