export enum LedgerEntryStatus {
  SYSTEMEINTRAG = 1,
  OFFEN = 2,
  GEPRUEFT = 3,
}

export class LedgerEntry {
  public saldo_before: number = 0;
  public saldo: number;
  constructor(
    public ledger_entry_id: number,
    public location_id: number,
    public date: number,
    public subject: string,
    public amount: number,
    public ledger_entry_status: number,
    public purchase_contract_id: number,
  ){}

  getEinnahmen() {
    if(this.amount < 0) return 0;
    return this.amount;
  }

  getAusgaben() {
    if(this.amount > 0) return 0;
    return this.amount*-1;
  }

  getJSON(): any {
    return {
      "ledger_entry_id": this.ledger_entry_id,
      "location_id": this.location_id,
      "date": this.date,
      "subject": this.subject,
      "amount": this.amount,
      "status": this.ledger_entry_status,
      "purchase_contract_id": this.purchase_contract_id,
    }
  }
}
