import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { DriveForm } from 'src/app/buisness-object/drives/form/DriveForm';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { DateHelper } from 'src/app/utils/DateHelper';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DrivesService } from 'src/app/service/drives/drives.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-test-drive-create',
  templateUrl: './test-drive-create.component.html',
  styleUrl: './test-drive-create.component.css'
})
export class TestDriveCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() object: Drive;
  @Input() typ: string;
  @Input() vehicles: Vehicle[];
  @Input() employees: Employee[];
  @Input() drives: Drive[];
  @Input() customers: Customer[];

  public form: FormGroup;
  public vehicleValues = [];
  public platesValues = [];
  public selectedPlateValue = [];
  public selectedVehicle: Vehicle;
  public customerValues = [];
  public employeeValues = [];
  public submitted = false;
  public formValuesChanged = false;
  public dialogQuerySubsription: Subscription;

  @Output() selectionCancelEmitter = new EventEmitter<undefined>();
  @Output() createSuccessEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private eService: EnumService,
    private driveService: DrivesService,
    private dialogService: DialogService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.form = DriveForm.getFormGroup(this.formBuilder);
    if(this.form.get('drive_typ').value == null){
      this.form.get('drive_typ').setValue(this.typ == 'customer' ? 1 : 2);
      DriveForm.updateTypValidity(this.form);
    }
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.form.controls['start_date'].setValue(DateHelper.getConvertedDateForInputFull(new Date().getTime()));
    this.form.controls['start_time'].setValue(DateHelper.getConvertedTimeForInputFull(new Date().getTime()));
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionCancelEmitter.emit();
      }
    });
  }

  selectionCancel() {
    if(this.formValuesChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setPlate(value) {
    this.form.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['vehicle'].setValue(this.vehicles[index]);
        this.setVehicle(this.vehicles[index]);
      }
    } else if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    }
    else if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['employee'].setValue(this.employees[index]);
      }
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.form.controls.vehicle.setValue(vehicle);
  }

  selectionCreate() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let drive = DriveForm.getObject(this.form);
    this.driveService.createDrive(drive).subscribe((result) => {
      if(result){
        result.status_signiert = 0;
        result.customer_id = drive.customer?.id;
        result.customer = drive.customer;
        result.employee_id = drive.employee?.id;
        result.employee = drive.employee;
        result.vehicle_id = drive.vehicle.id;
        result.vehicle = drive.vehicle;
        this.createSuccessEmitter.emit(result);
      }
    });
  }
}
