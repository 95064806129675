  <div class="header-base-style">
    <div class="header-inner-wrapper">
      <button class="button-icon-start" (click)="selectionCloseEmitter.emit()">
        <img class="button-icon-start-img" src="./assets/back.svg"/>
        <span class="button-icon-start-span">Zurück</span>
      </button>
      <h2>Reifen #{{obj?.tire_id}}</h2>
    </div>
    <div class="header-button-wrapper">
      <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
        Aktionen
        <img src="./assets/dropdown-arrow-blue.svg">
        <div *ngIf="shwoActions">
          <button
            (click)="selectionEditEmitter.emit(obj); shwoActions = false">
            Bearbeiten
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageEmitter.emit(obj)"
            >Lagerplatz korrigieren
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageAutoEmitter.emit(obj)">
            Lagerplatz automatisch umlagern
          </button>
          <button *ngIf="(obj && obj.vehicle_id != undefined)"
            (click)="selectionChangeStorageEmitter.emit(obj)">
            Einlagern
          </button>
          <button *ngIf="false"
            (click)="selectionSellEmitter.emit(obj)">
            Verkaufen
          </button>
        </div>
      </button>
      <!-- <button class="btn_white" (click)="selectionChangeStorageEmitter.emit(obj)">{{(obj && obj.vehicle_id) ? 'Einlagern' : 'Lagerplatz korrigieren' }}</button> -->
      <button *ngIf="obj && obj.vehicle_id == null" class="button_type_1 button_store" (click)="selectionInstallationEmitter.emit(obj)">Montieren</button>
    </div>
  </div>
  <div class="body-base-style">
    <div class="btn-tab-wrapper">
      <button id="btn-previous" class="btn-tab btn-tab-first" [disabled]="!isPreviousObjAvailable" (click)="selectionPrevious()">Vorherige Reifen</button>
      <button id="btn-next" class="btn-tab btn-tab-last" [disabled]="!isNextObjAvailable" (click)="selectionNext()">Nächste Reifen</button>
    </div>
    <div id="drive-details-body">
      <div id="header-column" class="overview_data_box_vertical">
        <div class="overview_record_vertical">
          <h3>Bezeichnung Reifen</h3>
          <div>
            <label class="overview_record_lbl">{{obj && obj.designation_rim.length >0 ? obj.designation_rim : '---'}}</label>
          </div>
        </div>
        <div class="overview_record_vertical">
          <h3>Marke Modell</h3>
          <div>
            <label class="overview_record_lbl">{{obj && obj.designation_tire.length >0 ? obj.designation_tire : '---'}}</label>
          </div>
        </div>
        <div class="overview_record_vertical">
          <h3>Ausfühung</h3>
          <div>
            <label class="overview_record_lbl">{{obj ? obj.execution : '---'}}</label>
          </div>
        </div>
        <div class="overview_record_vertical">
          <h3>Status</h3>
          <div>
            <label id="label-drive-status" class="overview_record_lbl">---</label>
          </div>
        </div>
        <div class="overview_record_vertical">
          <h3>Lagerort</h3>
          <div>
            <label class="overview_record_lbl">{{obj ? obj.storage_place?.place_name : '---'}}</label>
          </div>
        </div>
        <div class="overview_record_vertical">
          <h3>Lagerdauer</h3>
          <div>
            <label id="label-drive-status" class="overview_record_lbl">{{obj?.storage_time > 0 ? obj?.storage_time : 0}} {{obj?.storage_time == 1 ? 'Tag' : 'Tage'}}</label>
          </div>
        </div>
      </div>
      <div class="column">
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Reifen</h3>
          <div collapsible-content>
            <div class="overview_record">
              <label class="overview_record_lbl">Profil</label>
              <label class="overview_record_value">{{tireTypValue}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Marke</label>
              <label class="overview_record_value">{{obj.tire_brand_name ? obj.tire_brand_name : "---"}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Modell</label>
              <label class="overview_record_value">{{getInputValue(obj.rim_model)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Breite</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.tire_width_1)}} / {{getInputValueNumberNoDicis(obj.tire_width_2)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Höhe</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.tire_height_1)}} / {{getInputValueNumberNoDicis(obj.tire_height_2)}}</label>
           </div>
           <div class="overview_record">
              <label class="overview_record_lbl">Zoll</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.tire_inches_1)}} / {{getInputValueNumberNoDicis(obj.tire_inches_2)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Index</label>
              <label class="overview_record_value">{{getInputValue(obj.tire_index_1)}} / {{getInputValue(obj.tire_index_2)}}</label>
            </div>
          </div>
        </app-collapsible>
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Reifen Details</h3>
          <div collapsible-content>
            <div class="overview_record" *ngFor="let detail of obj.details; let i = index">
              <label class="overview_record_lbl">{{getFormDetailsTitle(detail, i)}}</label>
              <label class="overview_record_value">{{detail.depth}} / {{detail.year}}</label>
            </div>
          </div>
        </app-collapsible>
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Felgen</h3>
          <div collapsible-content>
            <div class="overview_record">
              <label class="overview_record_lbl">Typ</label>
              <label class="overview_record_value">{{rimTypValue}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Breite</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.rim_width_1)}} / {{getInputValueNumberNoDicis(obj.rim_width_2)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Zoll</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.rim_inches_1)}} / {{getInputValueNumberNoDicis(obj.rim_inches_2)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">ET</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.rim_et_1)}} / {{getInputValueNumberNoDicis(obj.rim_et_2)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Schrauben</label>
              <label class="overview_record_value">{{getInputValue(obj.rim_screws)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Lochkreis</label>
              <label class="overview_record_value">{{getInputValueNumberNoDicis(obj.rim_bolt_circle)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Herst. Art Nr.</label>
              <label class="overview_record_value">{{getInputValue(obj.rim_external_id)}}</label>
            </div>
          </div>
        </app-collapsible>
      </div>
      <div class="column">
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Lager</h3>
          <div collapsible-content>
            <div class="overview_record">
              <label class="overview_record_lbl">Lagerort</label>
              <label class="overview_record_value">{{getInputValue(obj.storage_place?.place_name)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Anzahl Reifen</label>
              <label class="overview_record_value">{{obj.details.length}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Eingelagert am</label>
              <label class="overview_record_value">{{getInputValueDate(obj.created)}}</label>
            </div>
          </div>
        </app-collapsible>
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Verkauf</h3>
          <div collapsible-content>
            <div class="overview_record">
              <label class="overview_record_lbl">Für den Verkauf gesperrt</label>
              <label class="overview_record_value" [class.lbl_color_wrapper_red]="obj.not_for_sale == true">{{getBooleanStringValue(obj.not_for_sale)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Preis (brutto)</label>
              <label class="overview_record_value">{{getInputValueCurrency(obj.price)}}</label>
            </div>
            <div class="overview_record">
              <label class="overview_record_lbl">Export Plattformen</label>
              <label class="overview_record_value">{{getBooleanStringValue(obj.platform_online)}}</label>
            </div>
            <div class="overview_record" *ngIf="false">
              <label class="overview_record_lbl">Inserat kontrolliert</label>
              <label class="overview_record_value">{{getBooleanStringValue(obj.freigeschaltet_am_plattform)}}</label>
            </div>
          </div>
        </app-collapsible>
        <app-tyres-images
          [tire]="obj"
          [editMode]="false"
        ></app-tyres-images>
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Beschreibung</h3>
          <div collapsible-content>
            <p class="overview_description">{{obj.description}}</p>
          </div>
        </app-collapsible>
      </div>
      <div class="column">
        <app-transactions
          [obj]="obj"
        ></app-transactions>
        <app-collapsible [isCollapsible]>
          <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
          <div collapsible-content>
            <app-files
              [obj]="obj"
            ></app-files>
          </div>
        </app-collapsible>
      </div>
    </div>
  </div>
