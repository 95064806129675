import { Component, EventEmitter, Output, Input } from '@angular/core';


@Component({
  selector: 'app-dialog-miete-cancel',
  templateUrl: './dialog-miete-cancel.component.html',
  styleUrl: './dialog-miete-cancel.component.css'
})
export class DialogMieteCancelComponent {

  @Output() selectionCloseEmitter = new EventEmitter();
}
