import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Thread } from 'src/app/buisness-object/message/Thread';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { OutputHelper } from 'src/app/helpers/OutputHelper';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { TransactionService } from 'src/app/service/transaction/transaction.service';

@Component({
  selector: 'app-overview-customer-container',
  templateUrl: './overview-customer-container.component.html',
  styleUrls: ['./overview-customer-container.component.css']
})
export class OverviewCustomerContainerComponent implements OnInit, OnDestroy {
  @Input() customer: Customer;
  @Input() showSurfaceOverview: number;
  @Output() removeCustomerCarEmitter = new EventEmitter<Vehicle>();
  public transactions: Transaction[];
  public threads: Thread[] = [];
  public employees: Employee[] = [];
  public onloadSrc: any[] = [];
  public uploadProgress = 0;
  public currentFile = 0;
  public filesAmount = 0;
  public sources: {
    attachment_id: number,
    name: string,
    format: string,
    data: string
  }[] = [];
  public showFileDrop = false;
  public dialogQuerySubsription: Subscription;
  public getTransactionIcon = OutputHelper.getTransactionIcon;
  public getTransactionColor = OutputHelper.getTransactionColor;

  constructor(
    private tService: TransactionService,
    private threadService: ThreadService,
    private eService: EmployeeService,
    private aService: AttachmentService,
    private dService: DialogService,
    private router: Router,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getDateServerside();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getDateServerside() {
    forkJoin([
      this.tService.getTransactions('customer', this.customer.id),
      this.threadService.getOpenThreadsByTyp('customer', this.customer.id),
      this.eService.getEmployees()
    ]).subscribe(([transactions, threads, employees]:[Transaction[], Thread[], Employee[]]) => {
      if(transactions && threads && employees){
        this.transactions = transactions;
        this.threads = threads;
        this.employees = employees;
        this.mergeDate();
      }
    })
    for(let attachment of this.customer.attachments){
      this.sources.push({
        attachment_id: attachment.attachment_id,
        name: attachment.filename,
        format: attachment.mimetyp,
        data: attachment.data
      })
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'remove_customer_car') this.removeCustomerCarEmitter.emit(value.submit_value);
        if(value.typ == 'remove_customer_attachment') this.deleteAttachment(value.submit_value);
      }
    });
  }

  mergeDate() {
    for(let thread of this.threads){
      for(let e of this.employees){
        if(thread.creator == e.id){
          thread.employee = e;
          break;
        }
      }
    }
  }

  getInitials(): string {
    return this.customer.contactPerson.firstName.substring(0,1) + this.customer.contactPerson.lastName.substring(0,1);
  }

  resolveThread(thread: Thread) {
    this.threadService.resolveThread(thread.threadId).subscribe((t) => {
      if(t){
        let index = this.threads.findIndex(t => t.threadId == thread.threadId);
        if(index >= 0){
          this.threads.splice(index,1);
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Ticket aufgelöst.',
            success: true
          });
        }
      }
    })
  }

  // getTransactionIcon(typ: number): string {
  //   switch (typ) {
  //     case 1: return '../../../../assets/usergray.svg'; break;
  //     case 2: return '../../../../assets/activities-cars.png'; break;
  //     case 3: return '../../../../assets/mail.svg'; break;
  //     case 4: return '../../../../assets/mail.svg'; break;
  //     default: break;
  //   }
  // }

  getDataTyp(typ: number): string {
   return 'test';
  }

  deleteAttachment(source: any) {
    this.aService.deleteAttachment(source.attachment_id).subscribe((success) => {
      if(success){
        let index = this.sources.findIndex(s => s.attachment_id == source.attachment_id)
        if(index > -1) this.sources.splice(index, 1);
        let indexCustomer = this.customer.attachments.findIndex(a => a.attachment_id == source.attachment_id);
        if(indexCustomer > -1) this.customer.attachments.splice(indexCustomer,1);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument entfernt.',
          success: true
        });
      }
    })
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i])
      }
      event.target.value = '';
      this.showFileDrop = false;
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let source = {
          attachment_id: undefined,
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        }
        this.sources.push(source);
        let a = new Attachment(
          0,
          2,
          this.customer.id,
          source.format,
          source.name,
          source.data,
        )
        this.aService.createAttachment(a).subscribe((result) => {
          if(result){
            this.customer.attachments.push(a);
            resolve(true);
          } else {
            resolve(null);
          }
        })
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async onFileDropped(data: any) {
    if(data && data.data && data.data[0]){
      let source = {
        attachment_id: undefined,
        name: data.data[0].name.split('.')[0],
        format: data.data[0].name.split('.')[1],
        data: data.data[0].content.split(',')[1]
      }
      this.sources.push(source);
      let a = new Attachment(
        0,
        2,
        this.customer.id,
        source.format,
        source.name,
        source.data,
      )
      this.aService.createAttachment(a).subscribe((result) => {
        if(result){
          this.customer.attachments.push(a);
        }
      })
    }
  }

  openCarDetails(vehicle: Vehicle) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['cars/'], { queryParams: { carId: vehicle.id } }));
  }

  removeCustomerAttachmentAction(source: any) {
    this.dService.openQuery(
      {
        title: 'Anhang vom Kunden entfernen?',
        message: 'Sind sie sicher, dass Sie diesen Anhang vom Kunden enfernen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Entfernen',
        typ: 'remove_customer_attachment',
        submit_value: source,
      }
    );
  }

  removeCustomerCarAction(vehicle: Vehicle) {
    this.dService.openQuery(
      {
        title: 'Fahrzeug vom Kunden entfernen?',
        message: 'Sind sie sicher, dass Sie dieses Fahrzeug vom Kunden entfernen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Entfernen',
        typ: 'remove_customer_car',
        submit_value: vehicle,
      }
    );
  }

  getAddress(customer: Customer): string {
    const address = `${customer.address.street}, ${customer.address.postalCode} ${customer.address.city}`;
    const country = customer.address.country && customer.address.country.length > 0 ? `<br>${customer.address.country}` : '';
    return `${address}${country}`;
  }
}
