<div id="dialog_window" *ngIf="show">
  <form id="dialog_container" [formGroup]="form">
      <div class="lbl_bold">Passwort ändern</div>
      <div class="body">
        <div *ngIf="!hasAccess">
          <div class="lbl_inputfield_container">
            <div class="lbl_regular_small message">Geben Sie Ihr altes Passwort ein.</div>
          </div>
          <div class="password_container">
            <input type="{{showPassword_1 ? 'text' : 'password'}}" class="inputfield" [ngClass]="submitted && form.controls.current_password.invalid ? 'inputfield_invalid' : ''"  placeholder="Passwort" formControlName="current_password" (ngModelChange)="accessDenied = false">
            <img (click)="showPassword_1 = !showPassword_1" class='icon_eye' [src]="showPassword_1 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
          </div>
          <div  *ngIf="accessDenied" style="margin-top: 15px;" class="lbl_inputfield_container">
            <div class="lbl_regular_small message lbl_invalid">Falsches Passwort</div>
          </div>
        </div>
        <div *ngIf="hasAccess">
          <div class="lbl_inputfield_container">
            <div class="lbl_regular_small message">Geben Sie Ihr neues Passwort ein.</div>
          </div>
          <div class="password_container" style="margin-bottom: 10px;">
            <input type="{{showPassword_1 ? 'text' : 'password'}}" class="inputfield" [ngClass]="submitted && form.controls.new_password.invalid ? 'inputfield_invalid' : ''"  placeholder="Passwort" formControlName="new_password" (ngModelChange)="submitted = false">
            <img (click)="showPassword_1 = !showPassword_1" class='icon_eye' [src]="showPassword_1 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
          </div>
          <div class="password_container">
            <input type="{{showPassword_2 ? 'text' : 'password'}}" class="inputfield" [ngClass]="submitted && form.controls.new_password_confirm.invalid ? 'inputfield_invalid' : ''"  placeholder="Passwort wiederholen" formControlName="new_password_confirm" (ngModelChange)="submitted = false">
            <img (click)="showPassword_2 = !showPassword_2" class='icon_eye' [src]="showPassword_2 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
          </div>
          <div *ngIf="submitted" class="lbl_invalid">{{notIdent ? 'Passwörter stimmen nicht überein.' : 'Passwort muss min. aus 8 Zeichen bestehen.'}}</div>
        </div>
      </div>
      <div class="dialog_btn_container">
        <div *ngIf="hasAccess" class="btn_submit_dark" (click)="updatePassword()">Speichern</div>
        <div *ngIf="!hasAccess" class="btn_submit_dark" (click)="next()">Weiter</div>
        <div class="btn_label" (click)="cancel()">Abbrechen</div>
      </div>
  </form>
</div>
