import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Bill, BillStatus } from "../Bill";
import { BillItem } from "../BillItem";
import { CustomerForm } from "../../customer/form/CustomerForm";

export class BillForm {

  public static getForm(formBuilder: FormBuilder, obj?: Bill): FormGroup {
    let customerForm = this.getFormValue(obj, 'customer');
    if(obj && obj.bill_recipient != null) {
      if(obj.bill_recipient.typ == undefined) {
        obj.bill_recipient.typ = obj.bill_recipient.companyName ? 1 : 2; //Firma / Private
      }
      customerForm = this.getFormValue(obj, 'bill_recipient');
    }
    let form = formBuilder.group({
      bill_id: [this.getFormValue(obj, 'bill_id')],
      customer: [customerForm, Validators.required],
      customer_form: [null, Validators.required],
      subject: [this.getFormValue(obj, 'subject'), Validators.required],
      payment_method: [this.getFormValue(obj, 'payment_method'), Validators.required],
      positions: [this.getPositionForms(formBuilder, obj?.bill_items)],
      bill_date: [this.getFormValue(obj, 'bill_date')],
      location_id: [this.getFormValue(obj, 'bill_date', Number(localStorage.getItem('location_id')))],
      status: [this.getFormValue(obj, 'status', BillStatus.DRAFT)],
      in_accounting: [this.getFormValue(obj, 'in_accounting', false)],
      type: [this.getFormValue(obj, 'type', 2)],
      custom_text: [this.getFormValue(obj, 'custom_text')],
    });

    return form;
  }

  static getPositionForms(formBuilder: FormBuilder, positions: BillItem[]): FormGroup[] {
    let forms = [];
    if(positions && positions.length > 0){
      for(let pos of positions){
        forms.push(this.getPositionForm(formBuilder, pos));
      }
    } else {
      forms.push(this.getPositionForm(formBuilder));
    }
    return forms;
  }

  static getPositionForm(formBuilder: FormBuilder, pos?: BillItem): FormGroup {
    return formBuilder.group({
      item_id: [this.getFormValue(pos, 'item_id')],
      title: [this.getFormValue(pos, 'title'), Validators.required],
      quantity: [this.getFormValue(pos, 'quantity', 1), Validators.required],
      price: [this.getFormValue(pos, 'price'), Validators.required],
      tax_rate: [this.getFormValue(pos, 'tax_rate', 20), Validators.required],
      discount: [this.getFormValue(pos, 'discount')],
      base_value: [this.getFormValue(pos, 'base_value')],
    });
  }

  static getFormValue(obj: any, key: string, defaultValue?: any) {
    if(obj && key){
      let value = obj[key];
      if(value !== undefined && value !== null) {
        if(!isNaN(value) && !Number.isInteger(value)) return parseFloat(value).toFixed(2);
        return value;
      }
    }
    return defaultValue != undefined ? defaultValue : null;
  }

  static getObjectPosition(form: FormGroup): BillItem {
    return new BillItem(
      form.get('item_id').value,
      form.get('title').value,
      form.get('quantity').value,
      form.get('price').value,
      form.get('tax_rate').value,
      form.get('discount').value,
      form.get('base_value').value,
    );
  }

  public static getObject(form: FormGroup): Bill {
    let positions = [];
    for(let posForm of form.get('positions').value){
      positions.push(this.getObjectPosition(posForm));
    }
    const customer = CustomerForm.createObject(form.get('customer_form').value);
    let obj = new Bill(
      form.get('bill_id').value,
      null, //custom_bill_id
      form.get('type').value,
      form.get('bill_date').value ? form.get('bill_date').value : new Date().getTime(),
      form.get('location_id').value,
      form.get('customer_form').value.get('customer_id').value,
      form.get('payment_method').value,
      form.get('status').value,
      form.get('in_accounting').value,
      positions,
      null, //purchase_contract_id
      form.get('subject').value,
      null,  //saved_bill_recipient_id
      null,  //reference_bill_id
      null,  //is_storno_bill
      form.get('custom_text').value,
    );
    obj.customer = customer;
    return obj;
  }
}
