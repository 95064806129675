<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCloseEmitter.emit()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>{{obj.getTypeAndNameAndVehicle()}}</h2>
  </div>

  <div class="header-button-wrapper">
      <button class="button_type_2" (click)="shwoActions = !shwoActions">
        Aktionen
        <img src="./assets/dropdown-arrow-blue.svg">
        <div *ngIf="shwoActions">
          <button (click)="selectionEditEmitter.emit(obj); shwoActions = false">Bearbeiten</button>
          <button (click)="selectionReturnEmitter.emit(obj); shwoActions = false">Rücknahme</button>
          <button (click)="slectionDocumentEmitter.emit(); shwoActions = false">Dokument hochladen</button>
          <button (click)="selectionCancelRentEmitter.emit(); shwoActions = false">Miete stornieren</button>
        </div>
        <!--div *ngIf="shwoActions">
          <button
            (click)="selectionEditEmitter.emit(obj); shwoActions = false">
            Bearbeiten
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageEmitter.emit(obj)"
            >Lagerplatz korrigieren
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageAutoEmitter.emit(obj)">
            Lagerplatz automatisch umlagern
          </button>
          <button *ngIf="(obj && obj.vehicle_id != undefined)"
            (click)="selectionChangeStorageEmitter.emit(obj)">
            Einlagern
          </button-->
      </button>
      <button class="button_type_2">
        E-Mail senden
        <img src="./assets/dropdown-arrow-blue.svg">
      </button>
  </div>
</div>

<div class="body-base-style">
  <div id="drive-details-body">

    <div class="column">

      <app-collapsible [isCollapsible]>
        <div collapsible-content>
          <div class="overview_record">
            <h3>Miet-Typ</h3>
            <label class="overview_record_value" 
            [class.lbl_color_wrapper_pink]="this.obj.rent_typ == 1" 
            [class.lbl_color_wrapper_violet]="this.obj.rent_typ == 2"
            [class.lbl_color_wrapper_cyan]="this.obj.rent_typ == 3"
            >
              {{getInputValue(rent_typ)}}
            </label> 
            <h3>Status</h3>
            <label class="overview_record_value" 
            [class.lbl_color_wrapper_gray]="this.obj.rent_status == 0"
            [class.lbl_color_wrapper_brown]="this.obj.rent_status == 1"
            [class.lbl_color_wrapper_orange]="this.obj.rent_status == 2"
            [class.lbl_color_wrapper_yellow]="this.obj.rent_status == 3"
            [class.lbl_color_wrapper_cyan]="this.obj.rent_status == 4"
            [class.lbl_color_wrapper_violet]="this.obj.rent_status == 10">
              {{getInputValue(rent_status)}}
            </label> 
          </div>
        </div>
      </app-collapsible>


      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Dokumente & Verträge</h3>
        <div collapsible-content>
          <div class="file">
            <div class="file-inner" (click)="open(obj.rent_id)">
              <div class="file-img-teal">
                <img src="./assets/document-grey.svg">
              </div>
              <div class="file-info-wrapper">
                <label class="overview_record_lbl">{{filename}}</label>
              </div>
            </div>
          </div>
        </div>
      </app-collapsible>

      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Übergabe an den Kunden</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_mileage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorschäden</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_damage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Tankinhalt</label>
            <label class="overview_record_value">{{getInputValue(obj.handover_fuel_content)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Freigegeben am</label>
            <label class="overview_record_value">{{getInputValueDate(obj.start_release_date)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Bilddokumentation Übergabe</h3>
        <div collapsible-content>
          <!--app-docu-images
          ></app-docu-images-->
      </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Rückgabe vom Kunden</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kilometerstand</label>
            <label class="overview_record_value">{{getInputValue(obj.return_mileage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Gefahrene Kilometer</label>
            <label class="overview_record_value">{{getInputValue(obj.driven_km)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Schäden</label>
            <label class="overview_record_value">{{getInputValue(obj.return_damage)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Tankinhalt</label>
            <label class="overview_record_value">{{getInputValue(obj.return_fuel_content)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Freigegeben am</label>
            <label class="overview_record_value">{{getInputValueDate(obj.end_release_date)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Bilddokumentation Rückgabe</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Subtitel</label>
            <label class="overview_record_value">1</label> 
          </div>
        </div>
      </app-collapsible>
    </div>

    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Hauptfahrer</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorname</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.firstName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Nachname</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.lastName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Straße, NR.</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.street)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">PLZ</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.postalCode)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ort</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.address.city)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Telefon</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.phone)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">E-Mail</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.contactPerson.email)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerscheinnummer</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.authorization_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein Behörde</label>
            <label class="overview_record_value">{{getInputValue(obj.main_driver?.authorization_authority)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kopie Führerschein</label>
            <label class="overview_record_value">---</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Geburtsdatum</label>
            <label class="overview_record_value">{{getInputValueDate(obj.main_driver?.contactPerson.birthdate)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Fahrzeug</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Marke</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.brand.makeName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Modell</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.model.name)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">FIN</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.fin_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kennzeichen</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.license_plate)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Treibstoff</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.fuel.name)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorschäden</label>
            <label class="overview_record_value">{{getInputValue(obj.vehicle?.damages)}}</label> 
          </div>

        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Zweitfahrer</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Vorname</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.firstName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Nachname</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.lastName)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Straße, NR.</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.street)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">PLZ</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.postalCode)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ort</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.address.city)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Telefon</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.phone)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">E-Mail</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.email)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerscheinnummer</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.authorization_number)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Führerschein Behörde</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.authorization_authority)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kopie Führerschein</label>
            <label class="overview_record_value">---</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Geburtsdatum</label>
            <label class="overview_record_value">{{getInputValue(obj.second_driver?.contactPerson.birthdate)}}</label> 
          </div>
        </div>
      </app-collapsible>
    </div>

    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Mietumfang</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Beginn</label>
            <label class="overview_record_value">{{getInputValueDate(obj.rental_start_date)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Ende</label>
            <label class="overview_record_value">{{getInputValueDate(obj.rental_end_date)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Dauer</label>
            <label class="overview_record_value">{{obj.rent_duration}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">KM-Begrenzung</label>
            <label class="overview_record_value">{{getInputValue(obj.km_limit)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Miete</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Preis je</label>
            <label class="overview_record_value">{{getInputValue(obj.price_time_unit)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Miete in € (brutto)</label>
            <label class="overview_record_value">{{getInputValue(obj.rent_price_eur)}}</label> 
          </div>
          <div class="overview_record" *ngIf="false">
            <label class="overview_record_lbl">Beisatz Miete</label>
            <label class="overview_record_value">{{getInputValue(obj.rent_add_on)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kosten je Mehrkilometer in €</label>
            <label class="overview_record_value">{{getInputValue(obj.additional_km_price)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Versicherungspauschale</label>
            <label class="overview_record_value">{{getInputValue(obj.insurance_flat_rate)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Kaution</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaution in €</label>
            <label class="overview_record_value">{{getInputValue(obj.deposit_eur)}}</label> 
          </div>
          <div class="overview_record">
            <label class="overview_record_lbl">Kaution</label>
            <label class="overview_record_value">{{getInputValue(obj.deposit_time_unit)}}</label> 
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Sonderkonditionen</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label class="overview_record_lbl">---</label>
          </div>
        </div>
      </app-collapsible>
    </div>

  </div>
</div>

