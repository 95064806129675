<div id="overview_container">
    <form id="grid" [formGroup]="customerFormGroup" autocomplete="off">
        <div class="title">{{selectedCustomer ? 'Kunde' : 'Neuer Kunde'}}</div>
        <div id="base_container" class="column">
            <div class="data_package">
              <div class="overview_data_box_lbl_title">Stammdaten</div>
              <app-dropdown-objects
                [title]="'Anrede *'"
                [placeholder]="'Anrede'"
                [headervalue]="f.salutation.value?.salu"
                [formcontrol]="f.salutation"
                [values]="salutationValues"
                [submitted]="submitted"
                (submitValueEmitter)="setValueFromDropdown('salutation', $event)"
              ></app-dropdown-objects>
              <app-dropdown-objects
                [title]="'Titel'"
                [placeholder]="'Titel'"
                [headervalue]="f.title.value?.name"
                [formcontrol]="f.title"
                [values]="titleValues"
                [submitted]="submitted"
                (submitValueEmitter)="setValueFromDropdown('title', $event)"
              ></app-dropdown-objects>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Vorname *</div>
                <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && f.firstName.errors">
              </div>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Nachname *</div>
                <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && f.lastName.errors">
              </div>
              <div class="input_wrapper">
                <div class="overview_record_lbl">Geburtsdatum</div>
                <input #inputDate1 type="date" placeholder="date" [max]="maxDate" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.birthdate.errors">
              </div>
              <app-dropdown-simple *ngIf="authService.isHoedl()"
                [title]="'Politisch Exponierte Person*'"
                [formcontrol]="f['is_pep']"
                (submitValueEmitter)="f['is_pep'].setValue($event)"
              ></app-dropdown-simple>
            </div>

            <div class="data_package">
              <div class="overview_data_box_lbl_title">Allgemeines</div>
              <app-dropdown-multiple
                  [title]="'Typ'"
                  [formcontrol]="f.typ"
                  [headerText]="typValues[f.typ.value-1]"
                  [values]="typValues"
                  (submitValueEmitter)="f.typ.setValue($event)"
                ></app-dropdown-multiple>
                <app-dropdown-multiple
                  [title]="'Status'"
                  [formcontrol]="f.status"
                  [headerText]="statusValues[f.status.value-1]"
                  [values]="statusValues"
                  (submitValueEmitter)="f.status.setValue($event)"
                ></app-dropdown-multiple>
                <form [formGroup]="isCompanyFormGroup" class="data_package" *ngIf="f.typ.value == 1">
                  <div class="input_wrapper">
                    <div class="overview_record_lbl">Firmenname *</div>
                    <input type="text" placeholder="Firmenname" formControlName="companyName" [class.input_invalid]="submitted && f2.companyName.errors">
                  </div>
                  <div class="input_wrapper">
                    <div class="overview_record_lbl">ATU</div>
                    <input type="text" placeholder="ATU" formControlName="atuNum" [class.input_invalid]="submitted && f2.atuNum.errors">
                  </div>
                </form>
            </div>

        </div>
        <div class="column">
          <div class="data_package">
            <div class="overview_data_box_lbl_title">Kontaktdaten</div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Straße, Nr *</div>
              <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && f.street.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">PLZ *</div>
              <input type="number" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && f.postalCode.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Ort *</div>
              <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && f.city.errors">
            </div>
            <app-dropdown-strings
              class="dropdown_wrapper"
              [title]="'Land'"
              [placeholder]="'Land'"
              [formcontrol]="customerFormGroup.controls['country']"
              [headerText]="customerFormGroup.controls['country'].value"
              [values]="countries"
              [isOptional]="true"
              (submitValueEmitter)="customerFormGroup.controls['country'].setValue($event);"
            ></app-dropdown-strings>
            <div class="input_wrapper">
              <div class="overview_record_lbl">E-Mail *</div>
              <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && f.email.errors">
            </div>
            <div class="input_wrapper">
              <div class="overview_record_lbl">Telefon *</div>
              <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && f.phone.errors">
            </div>
            <app-dropdown-objects
              [title]="'Mitarbeiter zuordnen'"
              [placeholder]="'Auswählen...'"
              [headervalue]="f.employee.value?.firstName + ' ' + f.employee.value?.lastName"
              [formcontrol]="f.employee"
              [values]="employeeValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('employee', $event)"
            ></app-dropdown-objects>
          </div>
          <div class="data_package">
            <div class="overview_data_box_lbl_title">Datenschutz</div>
            <div class="slider_grid" [ngClass]="submitted && f.dataprivacyAccepted.errors ? 'slider_grid_invalid' : ''">
                <div>Kunde <strong>stimmt dem Datenschutz zu</strong>.</div>
                <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                    <input type="checkbox" [checked]="f.dataprivacyAccepted.value" (click)="setPrivacy()">
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="slider_grid" [ngStyle]="{'margin-top': '0%'}">
                <div [ngStyle]="{'color':'#f54e42'}">Kunde <strong>möchte Werbematerial erhalten</strong>.</div>
                <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                    <input type="checkbox" [checked]="f.advertismentAccepted.value" (click)="setAdvertising()">
                    <span class="slider_red round"></span>
                </label>
            </div>
          </div>
        </div>
    </form>
</div>

            <!-- <div #dropdown1 class="dropdown_wrapper" >
              <div class="lbl_regular_small" [ngStyle]="{'opacity': selectedSalutation ? '1' : '0'}">Anrede</div>
              <div class="dropdown_header_data" [ngClass]="submitted && f.salutation.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdown1 = !showDropdown1">
                  <div class="dropdown_header_data_lbl" [ngClass]="!selectedSalutation ? 'dropdown_header_lbl_placeholder' : ''">{{selectedSalutation ? selectedSalutation.salu : 'Anrede *'}}</div>
                  <img class="dropdown_header_icon" [ngClass]="showDropdown1 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="showDropdown1 ? 'rotated' : 'default'">
              </div>
              <div class="dropdown_data_body" *ngIf="showDropdown1">
                  <div *ngFor="let salu of salutations" class="dropdown_data_body_element" (click)="setSalutation(salu); showDropdown1 = false">{{salu.salu}}</div>
              </div>
            </div> -->
            <!-- <div #dropdown2 class="dropdown_wrapper" >
              <div class="lbl_regular_small" [ngStyle]="{'opacity': selectedTitle ? '1' : '0'}">Titel</div>
              <div class="dropdown_header_data" (click)="showDropdown2 = !showDropdown2">
                  <div class="dropdown_header_data_lbl" [ngClass]="!selectedSalutation ? 'dropdown_header_lbl_placeholder' : ''">{{selectedTitle ? selectedTitle.name : 'Titel'}}</div>
                  <img class="dropdown_header_icon" [ngClass]="showDropdown2 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="showDropdown2 ? 'rotated' : 'default'">
              </div>
              <div class="dropdown_data_body" *ngIf="showDropdown2">
                  <div *ngFor="let title of titles" class="dropdown_data_body_element" (click)="setTitle(title); showDropdown2 = false">{{title.name}}</div>
              </div>
            </div> -->
            <!-- <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.firstName.value ? '1' : '0'}">Vorname</div>
                <input type="text" autocomplete="new_firstname" class="inputfield" [ngClass]="submitted && f.firstName.errors ? 'inputfield_invalid' : ''" placeholder="Vorname *" formControlName="firstName" (ngModel)="f.firstName.value">
            </div>
            <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.lastName.value ? '1' : '0'}">Nachname</div>
                <input type="text" autocomplete="new_lastname" class="inputfield" [ngClass]="submitted && f.lastName.errors ? 'inputfield_invalid' : ''" placeholder="Nachname *" formControlName="lastName" (ngModel)="f.lastName.value">
            </div> -->
            <!-- <div>
              <div class="date_picker">
                <div class="lbl_regular" [ngStyle]="{'opacity': f.birthdate.value ? '1' : '0'}">Geburtsdatum (TT.MM.JJJJ) *</div>
                <div appCustomDateFormat2>
                    <input #dateInput2 class="inputfield input_datepicker" [ngClass]="(submitted && (f.birthdate.errors?.validateDateInput?.valid == false || f.birthdate.value == null)) ? 'inputfield_invalid' : ''" placeholder="Geburtsdatum (TT.MM.JJJJ) *" type="text" maxlength="10" [max]="maxDate" [matDatepicker]="picker1" [formControl]="f.birthdate" formControlName="birthdate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1
                    ></mat-datepicker>
                </div>
              </div>
            </div> -->


            <!-- <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.street.value ? '1' : '0'}">Strasse, Nr *</div>
                <input type="text" autocomplete="new_street" class="inputfield" [ngClass]="submitted && f.street.errors ? 'inputfield_invalid' : ''" placeholder="Strasse, Nr. *" formControlName="street" (ngModel)="f.street.value">
            </div> -->
            <!-- <div class="inputfield_grid">
                <div>
                    <div class="lbl_regular" [ngStyle]="{'opacity': f.postalCode.value ? '1' : '0'}">PLZ *</div>
                    <input type="number" autocomplete="new_postalCode" class="inputfield inputfield_number" [ngStyle]="{'width': '80%'}" [ngClass]="submitted && f.postalCode.errors ? 'inputfield_invalid' : ''" min="1000" placeholder="Plz *" formControlName="postalCode" (ngModel)="f.postalCode.value">
                </div>
                <div>
                    <div class="lbl_regular" [ngStyle]="{'opacity': f.city.value ? '1' : '0'}">Ort *</div>
                    <input type="text" autocomplete="new_city" class="inputfield" [ngClass]="submitted && f.city.errors ? 'inputfield_invalid' : ''" placeholder="Ort *" formControlName="city" (ngModel)="f.city.value">
                </div>
            </div> -->
            <!-- <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.email.value ? '1' : '0'}">E-Mail *</div>
                <input type="text" class="inputfield" autocomplete="new_mail" [ngClass]="submitted && f.email.errors ? 'inputfield_invalid' : ''" placeholder="E-Mail *" formControlName="email" (ngModel)="f.email.value">
            </div>
            <div>
                <div class="lbl_regular" [ngStyle]="{'opacity': f.phone.value ? '1' : '0'}">Telefon *</div>
                <input type="text" class="inputfield" autocomplete="new_phone" [ngClass]="submitted && f.phone.errors ? 'inputfield_invalid' : ''" placeholder="Telefon *" formControlName="phone" (ngModel)="f.phone.value">
            </div> -->
            <!-- <div #dropdown5 class="dropdown_input_wrapper">
              <div class="lbl_regular_small">Mitarbeiter zuordnen</div>
              <div class="dropdown_input_header" (click)="showDropdown5 = !showDropdown5">
                  <input #input_employee class="dropdown_input" type="text" placeholder="Mitarbeiter auswählen..." (input)="filterEmployees(input_employee.value)">
                  <img class="dropdown_input_header_icon" [ngClass]="showDropdown5 ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div class="dropdown_input_body" *ngIf="showDropdown5">
                  <div *ngFor="let employee of employeesFiltered" class="dropdown_input_body_element" (click)="setEmployee(employee); showDropdown5 = false">
                    {{employee.firstName + ' ' + employee.lastName}}
                  </div>
              </div>
            </div> -->


            <!-- <div class="inputfield_grid" style="grid-template-columns: 25% 70%;">
                <div class="innerTitle">Typ</div>
                <div #dropdown3 class="dropdown_wrapper" style="height: 40px;">
                  <div class="dropdown_header_data" (click)="showDropdown3 = !showDropdown3">
                      <div class="dropdown_header_data_lbl" [ngClass]="!f.typ.value ? 'dropdown_header_lbl_placeholder' : ''">{{f.typ.value == 1 ? 'FIRMA' : 'PRIVAT'}}</div>
                      <img class="dropdown_header_icon" [ngClass]="showDropdown3 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="showDropdown3 ? 'rotated' : 'default'">
                  </div>
                  <div class="dropdown_data_body" *ngIf="showDropdown3">
                    <div class="dropdown_data_body_element" (click)="setTyp(1); showDropdown3 = false">FIRMA</div>
                    <div class="dropdown_data_body_element" (click)="setTyp(2); showDropdown3 = false">PRIVAT</div>
                  </div>
                </div>
            </div> -->
            <!-- <div class="inputfield_grid" style="grid-template-columns: 25% 70%;">
                <div class="innerTitle">Status</div>
                <div #dropdown4 class="dropdown_wrapper" style="height: 40px;">
                  <div class="dropdown_header_data" [ngClass]="submitted && f.status.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdown4 = !showDropdown4">
                      <div class="dropdown_header_data_lbl" [ngClass]="!f.status.value ? 'dropdown_header_lbl_placeholder' : ''">{{f.status.value == 1 ? 'KUNDE' : ( f.status.value == 2 ? 'INTERESSENT' : 'Bitte wählen')}}</div>
                      <img class="dropdown_header_icon" [ngClass]="showDropdown4 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="showDropdown4 ? 'rotated' : 'default'">
                  </div>
                  <div class="dropdown_data_body" *ngIf="showDropdown4">
                    <div class="dropdown_data_body_element" (click)="setStatus(1); showDropdown4 = false">KUNDE</div>
                    <div class="dropdown_data_body_element" (click)="setStatus(2); showDropdown4 = false">INTERESSENT</div>
                  </div>
                </div>
            </div> -->
            <!-- <form [formGroup]="isCompanyFormGroup" autocomplete="off">
                <div class="input_container_company" [ngStyle]="{'display': isCompany ? 'block' : 'none'}" [@open]="isCompany ? 'opened' : 'closed'">
                  <div>
                    <div class="lbl_regular" [ngStyle]="{'opacity': f2.companyName.value ? '1' : '0'}">Firmenname *</div>
                    <input type="text" class="inputfield input_company" [ngClass]="submitted && f2.companyName.errors ? 'inputfield_invalid' : ''" [ngStyle]="{'display': isCompany ? 'block' : 'none'}" placeholder="Firmenname *" formControlName="companyName" (ngModel)="f2.companyName.value">
                  </div>
                  <div>
                    <div class="lbl_regular" [ngStyle]="{'opacity': f2.atuNum.value ? '1' : '0'}">ATU</div>
                    <input type="text" class="inputfield input_company" [ngStyle]="{'display': isCompany ? 'block' : 'none'}" placeholder="ATU" formControlName="atuNum" (ngModel)="f2.atuNum.value">
                  </div>
                </div>
            </form> -->
