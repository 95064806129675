import { Component, OnInit, Input } from '@angular/core';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { BaseComponent } from '../../BaseComponent';
import { Authentication } from 'src/app/buisness-object/auth/Authentication';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-drive-details',
  templateUrl: './drive-details.component.html',
  styleUrls: ['./drive-details.component.css']
})
export class DriveDetailsComponent extends BaseComponent implements OnInit {
  @Input() drive: Drive;
  public showFileDrop = false;
  public onloadSrc: any[] = [];
  public uploadProgress = 0;
  public currentFile = 0;
  public filesAmount = 0;
  public sources: {
    attachment_id: number,
    name: string,
    format: string,
    data: string
  }[] = [];

  constructor(
    private aService: AttachmentService,
    private dService: DialogService,
    private authService: AuthenticationService,
    private eService: EnumService,
    private documentService: DocumentService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    for(let attachment of this.drive.attachments){
      this.sources.push({
        attachment_id: attachment.attachment_id,
        name: attachment.filename,
        format: attachment.mimetyp,
        data: attachment.data
      })
    }
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i])
      }
      event.target.value = '';
      this.showFileDrop = false;
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let source = {
          attachment_id: undefined,
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        }
        this.sources.push(source);
        let a = new Attachment(
          0,
          3,
          this.drive.test_drive_id,
          source.format,
          source.name,
          source.data,
        )
        this.aService.createAttachment(a).subscribe((result) => {
          if(result){
            this.drive.attachments.push(a);
            resolve(true);
          } else {
            resolve(null);
          }
        })
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async onFileDropped(data: any) {
    if(data && data.data && data.data[0]){
      let source = {
        attachment_id: undefined,
        name: data.data[0].name.split('.')[0],
        format: data.data[0].name.split('.')[1],
        data: data.data[0].content.split(',')[1]
      }
      this.sources.push(source);
      let a = new Attachment(
        0,
        3,
        this.drive.test_drive_id,
        source.format,
        source.name,
        source.data,
      )
      this.aService.createAttachment(a).subscribe((result) => {
        if(result){
          this.drive.attachments.push(a);
          this.showFileDrop = false;
        }
      })
    }
  }

  deleteAttachment(source: any) {
    this.aService.deleteAttachment(source.attachment_id).subscribe((success) => {
      if(success){
        let index = this.sources.findIndex(s => s.attachment_id == source.attachment_id)
        if(index > -1) this.sources.splice(index, 1);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument entfernt.',
          success: true
        });
      }
    })
  }

  getSelectedPlate(): string {
    switch (this.drive.license_plate_id) {
      case 1: return 'KR-ESJ4'; break;
      case 2: return 'KR-13WR'; break;
      case 3: return 'BEISPIELKENNZEICHEN'; break;
      default: return '---'; break;
    };
  }

  signPDF(contract: any){
    // this.documentService.createSignatureDocument(contract).subscribe((result) => {
    //   if(result){
    //     this.dService.showNotification({
    //       title: 'Erfolgreich',
    //       message: 'Vertrag wurde zu Signature freigegeben.',
    //       success: true
    //     });
    //   }
    // })
  }
}
