import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { Setting } from 'src/app/buisness-object/settings/Setting';
import { SettingFormHelper } from 'src/app/buisness-object/settings/SettingFormHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { SettingsService } from 'src/app/service/settings/settings.service';

export enum SettingMainMenu {
  VEHICLE = 'Fahrzeuge',
  DOCUMENTS = 'Dokumente'
}

export enum SettingSubMenu {
  PREISKALKULATION = 'Preiskalkulation',
  PURCHASECONTRACT = 'Kaufvertrag'
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnInit {
  public SettingMainMenu = SettingMainMenu;
  public SettingSubMenu = SettingSubMenu;
  public mainMenus = [
    {
      menu: SettingMainMenu.VEHICLE,
      sub_menus: []
    },
    // {
    //   menu: SettingMainMenu.DOCUMENTS,
    //   sub_menus: [
    //     {
    //       sub_menu: SettingSubMenu.PURCHASECONTRACT,
    //       form_category: 'purchase_contract'
    //     }
    //   ]
    // }
  ];
  public selectedMainMenu;
  public selectedSubMenu;
  public settings: Setting[];
  public selectedSettings: Setting[] = [];
  public form: FormGroup;
  public formValueChanged = false;
  public submitted = false;
  public dialogQuerySubsription: Subscription;
  public settingTitle;

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    if(this.authService.accessPricecalculation()){
      this.mainMenus[0].sub_menus.push(
        {
          sub_menu: SettingSubMenu.PREISKALKULATION,
          form_category: 'preisblatt'
        }
      )
    }
    this.selectedMainMenu = this.mainMenus[0];
    if(this.selectedMainMenu.sub_menus.length > 0){
      this.selectedSubMenu = this.selectedMainMenu.sub_menus[0];
    }
    this.settingsService.getSettings().subscribe((result) => {
      if(result){
        this.settings = result;
        if(this.selectedSubMenu){
          this.getSettings();
          this.setForm();
          this.getSettingTitle();
        }
      }
    });
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'settings_verwerfen') this.resetForm();
      }
    });
  }

  selectionMainMenu(mainMenu: any) {
    this.selectedMainMenu = mainMenu;
    if(this.selectedMainMenu.sub_menus.length > 0){
      this.selectedSubMenu = this.selectedMainMenu.sub_menus[0];
      this.form = null;
      this.getSettings();
      this.setForm();
      this.getSettingTitle();
    }
  }

  selectionSubMenu(subMenu: any) {
    this.selectedSubMenu = subMenu;
    this.getSettings();
    this.setForm();
    this.getSettingTitle();
  }

  getSettings() {
    this.selectedSettings = [];
    if(this.selectedSubMenu){
      for(let setting of this.settings){
        if(setting.category == this.selectedSubMenu.form_category){
          this.selectedSettings.push(setting);
        }
      }
    }
  }

  setForm(): void {
    this.form = null;
    this.form = this.formBuilder.group({});
    this.selectedSettings.forEach(setting => {
      this.form.addControl(setting.name, this.formBuilder.control(setting.value));
    });
    this.formValueChanged = false;
    this.form.valueChanges.subscribe(() => {
      this.formValueChanged = true;
    });
  }

  resetForm(): void {
    for(let setting of this.selectedSettings){
      this.form.controls[setting.name].setValue(setting.value);
    }
    this.formValueChanged = false;
  }

  getSettingTitle() {
    switch(this.selectedSubMenu.sub_menu){
      case SettingSubMenu.PREISKALKULATION: this.settingTitle = 'Standardwerte für die Preiskalkulation'; break;
      case SettingSubMenu.PURCHASECONTRACT: this.settingTitle = 'Standardwerte für den Kaufvertrag'; break;
      default: this.settingTitle = '---';
    }
  }

  settingsSave() {
    this.submitted = true;
    if(this.form.invalid) return;
    let newSettings = SettingFormHelper.createObject(this.form, this.settings);
    this.settingsService.updateSettings(newSettings).subscribe((result) => {
      if(result){
        this.selectedSettings = result;
        this.setForm();
        this.submitted = false;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Einstellungen aktualisiert.',
          success: true
        });
        this.submitted = false;
      }
    })
  }

  settingsVerwerfenAction() {
    this.dialogService.openQuery(
      {
        title: 'Verwerfen',
        message: 'Sind Sie sicher, dass Sie die Einstellung verwerfen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Ja',
        typ: 'settings_verwerfen',
      }
    );
  }
}
