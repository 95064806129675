import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dropdown-objects',
  templateUrl: './dropdown-objects.component.html',
  styleUrls: ['./dropdown-objects.component.css']
})
export class DropdownObjectsComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() headervalue: string;
  @Input() formcontrol: FormControl;
  @Input() values: any[];
  @Input() submitted: boolean;
  @Input() disabled: boolean;
  @Output() submitValueEmitter = new EventEmitter<boolean>();
  public show = false;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  submit(value) {
    this.submitValueEmitter.emit(value.id);
  }

  isValidObjectId(): boolean {
    const id = Object.values(this.formcontrol.value)[0];
    if(id && !isNaN(Number(id))) return true
    else return false
  }
}
