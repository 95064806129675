<div class="overview_container">
    <app-start-container *ngIf="currentPageCreate == 1"
        [form]="formGroupVehicle"
        (vinEmitter)="vinEmitter.emit($event)"
        (createChoiseEmitter)="changePage($event)"
    ></app-start-container>
    <app-vin-missing-data-container *ngIf="currentPageCreate == 10"
        [form]="formGroupVehicle"
        [submitted]="submitted"
        [brands]="brands"
        [drives]="drives"
        [bodyColors]="bodyColors"
        [fuels]="fuels"
        [bodyTyps]="bodyTyps"
        [transmissions]="transmissions"
        [categories]="categories"
        [vehicle]="newVehicle"
        (saveAsDraftEmitter)="saveAsDraftEmitter.emit()"
    ></app-vin-missing-data-container>
    <div class="data_container" *ngIf="currentPageCreate > 1 && currentPageCreate < 10">
        <app-basic-data-container *ngIf="currentPageCreate == 2"
            [currentPageCreate]="currentPageCreate"
            [formGroup]="formGroupVehicle"
            [submitted]="submitted"
            [brands]="brands"
            [drives]="drives"
            [bodyColors]="bodyColors"
            [fuels]="fuels"
            [bodyTyps]="bodyTyps"
            [transmissions]="transmissions"
            [categories]="categories"
            (updateVehicleTitle)="updateVehicleTitle.emit($event)"
        ></app-basic-data-container>
        <app-basic-data-container-zwei *ngIf="currentPageCreate == 3"
            [formGroup]="formGroupVehicle"
            [submitted]="submitted"
            [currentPageCreate]="currentPageCreate"
        ></app-basic-data-container-zwei>
        <app-identification-container *ngIf="currentPageCreate == 4"
            [newVehicle]="newVehicle"
            [formGroup]="formGroupVehicle"
        ></app-identification-container>
        <app-additional-container *ngIf="currentPageCreate == 5"
            [formGroup]="formGroupVehicle"
            [submitted]="submitted"
        ></app-additional-container>
        <app-equipment-container *ngIf="currentPageCreate == 6"
            [formGroup]="formGroupVehicle"
            [vehicleTitle]="vehicleTitle"
        ></app-equipment-container>
        <app-discription-container *ngIf="currentPageCreate == 7"
            [formGroup]="formGroupVehicle"
            [vehicleTitle]="vehicleTitle"
        ></app-discription-container>
        <app-imageupload-container *ngIf="currentPageCreate == 8"
             [vehicle]="newVehicle"
             [formGroupVehicle]="formGroupVehicle"
        ></app-imageupload-container>
        <app-attachments-container *ngIf="currentPageCreate == 9"
             [vehicle]="newVehicle"
             [form]="formGroupVehicle"
        ></app-attachments-container>
        <!-- <app-insert-container *ngIf="currentPageCreate == 9"

        ></app-insert-container> -->

        <!-- <app-drive-container *ngIf="currentPageCreate == 4" [formGroup]="formGroupDrive"></app-drive-container>
        <app-vehicle-container *ngIf="currentPageCreate == 5"></app-vehicle-container>
        <app-insert-container *ngIf="currentPageCreate == 9"></app-insert-container> -->
        <app-footer-container
            [currentPageCreate]="currentPageCreate"
            [pagesTotalAry]="pagesTotalAry"
            (changePageEmitter)="changePage($event)"
        ></app-footer-container>
    </div>
</div>
