import { BillItem } from "../BillItem";

export class BillItemFactory {
  static jsonFactory(rawBody: any): BillItem[] {
    const data: BillItem[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): BillItem {
    const obj = new BillItem(
      rawPart.item_id,
      rawPart.title,
      rawPart.quantity,
      rawPart.price,
      rawPart.total_discount,
      rawPart.price_based,
      rawPart.base_value,
    )
    return obj;
  }
}
