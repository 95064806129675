import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { DokumentTyp } from '../dokumente/dokumente.component';

@Component({
  selector: 'app-open-signature-loader',
  templateUrl: './open-signature-loader.component.html',
  styleUrls: ['./open-signature-loader.component.css']
})
export class OpenSignatureLoaderComponent implements OnInit {

  show = false;
  sucess = false;
  loadingSubscription: Subscription;
  handler: any;
  documentTyp: string;
  running_signature_id: number;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.loadingSubscription = this.dialogService.openDialogLoaderSignature$.subscribe((data: any) => {
      this.show = true;
      this.documentTyp = data.document_typ;
      if(!this.documentTyp){
        this.documentTyp = localStorage.getItem("has_signature_document_typ");
      }
      localStorage.setItem("has_signature_document_typ",this.documentTyp);
      this.handler = setInterval((): void => {
        this.hasOpenSignatureInterval();
      }, 2000);
    })
  }

  ngOnDestroy(){
    this.loadingSubscription.unsubscribe();
  }

  hasOpenSignatureInterval() {
    this.documentService.getHasOpenSignature().subscribe((result) => {
      if(result){
        if(result.has_signatures == false){
          clearInterval(this.handler);
          localStorage.removeItem("has_signature_document_typ");
          this.show = false;
          this.closeLoader();
        } else {
          this.running_signature_id = result.signature_document_id;
        }
      }
    })
  }

  closeLoader() {
    this.dialogService.closeLoaderSignature(
      {
        typ: this.documentTyp
      }
    );
  }

  abort() {
    this.documentService.abortOpenSignature(this.running_signature_id).subscribe((result) => {
      if(result){
        clearInterval(this.handler);
        localStorage.removeItem("has_signature_document_typ");
        this.show = false;
      }
    })
  }

  getSuccessText(): string {
    switch(this.documentTyp){
      case DokumentTyp.Datenschutz: return "Datenschutz erfolgreich signiert"; break; // 1
      case DokumentTyp.Angebot: return "Angebot erfolgreich signiert"; break; // 2
      case DokumentTyp.Kaufvertrag: return "Vertrag erfolgreich signiert"; break; // 3
      case DokumentTyp.Probefahrt: return "Probefahrt erfolgreich signiert"; break; // 4
      default: return "Dokument erfolgreich signiert"; break;
    }
  }
}
