import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { AppComponent } from './app.component';
import { ViewComponent } from './view/view.component';
import { LoginComponent } from './view/login/login.component';
import { LoginFormComponent } from './view/login/login-form/login-form.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { SidebarComponent } from './view/sidebar/sidebar.component';
import { CarsComponent } from './view/cars/cars.component';
import { CustomerComponent } from './view/customer/customer.component';
import { InterfacesComponent } from './view/interfaces/interfaces.component';
import { AdvertisingComponent } from './view/advertising/advertising.component';
import { AdminComponent } from './view/admin/admin.component';
import { MaterialComponent } from './view/material/material.component';
import { AppRoutingModule } from './app-routing.module';
import { TopbarComponent } from './view/cars/topbar/topbar.component';
import { ListContainerComponent } from './view/cars/list-container/list-container.component';
import { ListTitleComponent } from './view/cars/list-container/list-title/list-title.component';
import { ListElementComponent } from './view/cars/list-container/list-element/list-element.component';
import { CreateContainerComponent } from './view/cars/create-container/create-container.component';
import { StartContainerComponent } from './view/cars/create-container/start-container/start-container.component';
import { BasicDataContainerComponent } from './view/cars/create-container/basic-data-container/basic-data-container.component';
import { DriveContainerComponent } from './view/cars/create-container/drive-container/drive-container.component';
import { VehicleContainerComponent } from './view/cars/create-container/vehicle-container/vehicle-container.component';
import { EquipmentContainerComponent } from './view/cars/create-container/equipment-container/equipment-container.component';
import { IdentificationContainerComponent } from './view/cars/create-container/identification-container/identification-container.component';
import { ImageuploadContainerComponent } from './view/cars/create-container/imageupload-container/imageupload-container.component';
import { InsertContainerComponent } from './view/cars/create-container/insert-container/insert-container.component';
import { OverviewContainerComponent } from './view/cars/overview-container/overview-container.component';
import { FilterComponent } from './view/cars/filter/filter.component';
import { FooterContainerComponent } from './view/cars/create-container/footer-container/footer-container.component';
import { DragNDropComponent } from './view/cars/create-container/imageupload-container/drag-n-drop/drag-n-drop.component';
import { OverviewComponent } from './view/cars/create-container/imageupload-container/overview/overview.component';
import { ImagesComponent } from './view/cars/overview-container/images/images.component';
import { BasicDataComponent } from './view/cars/overview-container/basic-data/basic-data.component';
import { VehicleComponent } from './view/cars/overview-container/vehicle/vehicle.component';
import { DriveComponent } from './view/cars/overview-container/drive/drive.component';
import { InsertComponent } from './view/cars/overview-container/insert/insert.component';
import { IdentificationComponent } from './view/cars/overview-container/identification/identification.component';
import { EquipmentComponent } from './view/cars/overview-container/equipment/equipment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListCustomerContainerComponent } from './view/customer/list-customer-container/list-customer-container.component';
import { ListCustomerElementComponent } from './view/customer/list-customer-container/list-customer-element/list-customer-element.component';
import { TopbarCustomerComponent } from './view/customer/topbar-customer/topbar-customer.component';
import { OverviewCustomerContainerComponent } from './view/customer/overview-customer-container/overview-customer-container.component';
import { CreateCustomerContainerComponent } from './view/customer/create-customer-container/create-customer-container.component';
import { ListAdminContainerComponent } from './view/admin/list-admin-container/list-admin-container.component';
import { ListAdminElementComponent } from './view/admin/list-admin-container/list-admin-element/list-admin-element.component';
import { ListAdminTitleComponent } from './view/admin/list-admin-container/list-admin-title/list-admin-title.component';
import { TopbarAdminComponent } from './view/admin/topbar-admin/topbar-admin.component';
import { OverviewUserContainerComponent } from './view/admin/overview-user-container/overview-user-container.component';
import { EditUserContainerComponent } from './view/admin/edit-user-container/edit-user-container.component';
import { ListLocationsContainerComponent } from './view/admin/list-locations-container/list-locations-container.component';
import { ListLocationsElementComponent } from './view/admin/list-locations-container/list-locations-element/list-locations-element.component';
import { ListLocationsTitleComponent } from './view/admin/list-locations-container/list-locations-title/list-locations-title.component';
import { LoadingComponent } from './view/loading/loading.component';
import { LoadingScreenInterceptor } from './service/loading/LoadingInterceptor';
import { DropdownContainerComponent } from './view/design-container/dropdown/dropdown-container/dropdown-container.component';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DropdownSelectContainerComponent } from './view/design-container/dropdown/dropdown-select-container/dropdown-select-container.component';
import { DropdownDateContainerComponent } from './view/design-container/dropdown/dropdown-date-container/dropdown-date-container.component';
import { MessagesComponent } from './view/messages/messages.component';
import { MessagesTopbarComponent } from './view/messages/messages-topbar/messages-topbar.component';
import { MessagesListComponent } from './view/messages/messages-list/messages-list.component';
import { MessagesListElementComponent } from './view/messages/messages-list/messages-list-element/messages-list-element.component';
import { MessagesCreateComponent } from './view/messages/messages-create/messages-create.component';
import { MessagesDetailComponent } from './view/messages/messages-detail/messages-detail.component';
import { SafeHtmlTxt } from './helpers/pipes/SafeHtmlTxt.pipe';
import { OverviewTicketsComponent } from './view/customer/overview-customer-container/overview-tickets/overview-tickets.component';
import { BasicDataContainerZweiComponent } from './view/cars/create-container/basic-data-container-zwei/basic-data-container-zwei.component';
import { NumberFormatPipe } from './helpers/NumberFormatPipe.pipe';
import { ElementDropdownComponent } from './view/cars/overview-container/overview-record-elements/element-dropdown/element-dropdown.component';
import { ElementInputComponent } from './view/cars/overview-container/overview-record-elements/element-input/element-input.component';
import { OverviewRecordElementsContainerComponent } from './view/cars/overview-container/overview-record-elements-container/overview-record-elements-container.component';
import { PriceComponent } from './view/cars/overview-container/price/price.component';
import { StandTimeComponent } from './view/cars/overview-container/stand-time/stand-time.component';
import { EmployeeComponent } from './view/cars/overview-container/employee/employee.component';
import { AttachmentComponent } from './view/cars/overview-container/attachment/attachment.component';
import { DndDirective } from './directive/dnd/dnd.directive';
import { OverviewMessagesComponent } from './view/cars/overview-messages/overview-messages.component';
import { DialogQueryComponent } from './view/dialog/dialog-query/dialog-query.component';
import { DialogNotificationComponent } from './view/dialog/dialog-notification/dialog-notification.component';
import { AdditionalContainerComponent } from './view/cars/create-container/additional-container/additional-container.component';
import { DiscriptionContainerComponent } from './view/cars/create-container/discription-container/discription-container.component';
import { DescriptionComponent } from './view/cars/overview-container/description/description.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TopbarInterfaceComponent } from './view/interfaces/topbar-interface/topbar-interface.component';
import { ElementInterfaceComponent } from './view/interfaces/element-interface/element-interface.component';
import { ChatInterfaceComponent } from './view/interfaces/chat-interface/chat-interface.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { TopbarDocumentsComponent } from './view/documents/topbar-documents/topbar-documents.component';
import { ListDocumentsComponent } from './view/documents/list-documents/list-documents.component';
import { ListDocumentsElementComponent } from './view/documents/list-documents/list-documents-element/list-documents-element.component';
import { TextfieldAutoresizeDirective } from './directive/textfield-autoresize/textfield-autoresize.directive';
import { CreateContractDocumentComponent } from './view/documents/create-contract-document/create-contract-document.component';
import { ContractNavigationComponent } from './view/documents/create-contract-document/contract-navigation/contract-navigation.component';
import { ContractPageOneComponent } from './view/documents/create-contract-document/contract-page-one/contract-page-one.component';
import { ContractPageTwoComponent } from './view/documents/create-contract-document/contract-page-two/contract-page-two.component';
import { ContractPageThreeComponent } from './view/documents/create-contract-document/contract-page-three/contract-page-three.component';
import { ContractPageFourComponent } from './view/documents/create-contract-document/contract-page-four/contract-page-four.component';
import { ContractPageFiveComponent } from './view/documents/create-contract-document/contract-page-five/contract-page-five.component';
import { DrivesComponent } from './view/drives/drives.component';
import { ListDrivesComponent } from './view/drives/list-drives/list-drives.component';
import { ListDrivesElementComponent } from './view/drives/list-drives/list-drives-element/list-drives-element.component';
import { TopbarDrivesComponent } from './view/drives/topbar-drives/topbar-drives.component';
import { CalenderDrivesComponent } from './view/drives/calender-drives/calender-drives.component';
import { CreateDriveComponent } from './view/drives/create-drive/create-drive.component';
import { DriveNavigationComponent } from './view/drives/create-drive/drive-navigation/drive-navigation.component';
import { DrivePageOneComponent } from './view/drives/create-drive/drive-page-one/drive-page-one.component';
import { DrivePageTwoComponent } from './view/drives/create-drive/drive-page-two/drive-page-two.component';
import { DrivePageThreeComponent } from './view/drives/create-drive/drive-page-three/drive-page-three.component';
import { CalendarComponent } from './view/drives/calendar/calendar.component';
import { CalendarTimePickerComponent } from './view/drives/calendar/calendar-time-picker/calendar-time-picker.component';
import '@angular/common/locales/global/de';
import { ServiceComponent } from './view/cars/overview-container/service/service.component';
import { AdditionalComponent } from './view/cars/overview-container/additional/additional.component';
import { DetailsContractDocumentComponent } from './view/documents/details-contract-document/details-contract-document.component';
import { TermsComponent } from './view/documents/create-contract-document/terms/terms.component';
import { UploadDocumentComponent } from './view/documents/upload-document/upload-document.component';
import { DetailsOfferDocumentComponent } from './view/documents/details-offer-document/details-offer-document.component';
import { DetailsBillDocumentComponent } from './view/documents/details-bill-document/details-bill-document.component';
import { CreateBillDocumentComponent } from './view/documents/create-bill-document/create-bill-document.component';
import { CreateBillDocumentElementComponent } from './view/documents/create-bill-document/create-bill-document-element/create-bill-document-element.component';
import { CreateBillPageOneComponent } from './view/documents/create-bill-document/create-bill-page-one/create-bill-page-one.component';
import { CreateBillPageTwoComponent } from './view/documents/create-bill-document/create-bill-page-two/create-bill-page-two.component';
import { CreateBillNavigationComponent } from './view/documents/create-bill-document/create-bill-navigation/create-bill-navigation.component';
import { CreateOfferDocumentComponent } from './view/documents/create-offer-document/create-offer-document.component';
import { CreateOfferNavigationComponent } from './view/documents/create-offer-document/create-offer-navigation/create-offer-navigation.component';
import { CreateOfferPageOneComponent } from './view/documents/create-offer-document/create-offer-page-one/create-offer-page-one.component';
import { CreateOfferPageTwoComponent } from './view/documents/create-offer-document/create-offer-page-two/create-offer-page-two.component';
import { CreateOfferPageTwoItemElementComponent } from './view/documents/create-offer-document/create-offer-page-two/create-offer-page-two-item-element/create-offer-page-two-item-element.component';
import { ViewDocumentComponent } from './view/documents/view-document/view-document.component';
import { TextinputAddFunctionComponent } from './view/textinput-add-function/textinput-add-function.component';
import { DialogChangePasswordComponent } from './view/dialog/dialog-change-password/dialog-change-password.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './view/reset-password/reset-password-form/reset-password-form.component';
import { TransactionsComponent } from './view/cars/overview-container/transactions/transactions.component';
import { DragAndDropComponent } from './view/drag-and-drop/drag-and-drop.component';
import { CustomerFilterComponent } from './view/customer/customer-filter/customer-filter.component';
import { DocumentsFilterComponent } from './view/documents/documents-filter/documents-filter.component';
import { SortComponent } from './view/cars/topbar/sort/sort.component';
import { CustomerCreateCarComponent } from './view/customer/customer-create-car/customer-create-car.component';
import { CreateEmployeeComponent } from './view/admin/create-employee/create-employee.component';
import { ContractVehicleConditionComponent } from './view/documents/contract-vehicle-condition/contract-vehicle-condition.component';
import { ListDocumentElementContractComponent } from './view/documents/list-documents/list-document-element-contract/list-document-element-contract.component';
import { DriveDetailsComponent } from './view/drives/drive-details/drive-details.component';
import { DialogPdfDriveComponent } from './view/drives/dialog-pdf-drive/dialog-pdf-drive.component';
import { AttachmentsContainerComponent } from './view/cars/create-container/attachments-container/attachments-container.component';
import { AttachmentElementComponent } from './view/cars/overview-container/attachment/attachment-element/attachment-element.component';
import { InterfaceOverviewComponent } from './view/cars/overview-container/interface-overview/interface-overview.component';
import { CustomercarComponent } from './view/cars/overview-container/customercar/customercar.component';
import { SortCustomerComponent } from './view/customer/topbar-customer/sort-customer/sort-customer.component';
import { MessagesFilterComponent } from './view/messages/messages-topbar/messages-filter/messages-filter.component';
import { DropdownDirective } from './directive/dropdown/dropdown.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DocumentsSortComponent } from './view/documents/topbar-documents/documents-sort/documents-sort.component';
import { SeasonalComponent } from './view/cars/overview-container/seasonal/seasonal.component';
import { ConditionComponent } from './view/cars/overview-container/condition/condition.component';
import { DialogCreateDocumentComponent } from './view/dialog/dialog-create-document/dialog-create-document.component';
import { PdfViewComponent } from './view/pdf-view/pdf-view.component';
import { DialogMoveComponent } from './view/dialog/dialog-move/dialog-move.component';
import { CustomMarkInputComponent } from './view/custom-mark-input/custom-mark-input.component';
import { WerkstattComponent } from './view/cars/overview-container/werkstatt/werkstatt.component';
import { DropdownSimpleComponent } from './view/dropdown-simple/dropdown-simple.component';
import { DropdownMultipleComponent } from './view/dropdown-multiple/dropdown-multiple.component';
import { DropdownObjectsComponent } from './view/dropdown-objects/dropdown-objects.component';
import { DialogCarOnlineComponent } from './view/dialog/dialog-car-online/dialog-car-online.component';
import { PriceSheetComponent } from './view/price-sheet/price-sheet.component';
import { PriceSheetsCreateComponent } from './view/price-sheet/price-sheets-create/price-sheets-create.component';
import { PriceSheetsDetailsComponent } from './view/price-sheet/price-sheets-details/price-sheets-details.component';
import { DialogNumberComponent } from './view/dialog/dialog-number/dialog-number.component';
import { PriceSheetsCreateNackalkulationComponent } from './view/price-sheet/price-sheets-create-nackalkulation/price-sheets-create-nackalkulation.component';
import { EditDescriptionComponent } from './view/cars/edit-description/edit-description.component';
import { ReparaturDialogComponent } from './view/price-sheet/reparatur-dialog/reparatur-dialog.component';
import { OpenSignatureLoaderComponent } from './view/open-signature-loader/open-signature-loader.component';
import { DialogCreateContractComponent } from './view/dialog/dialog-create-contract/dialog-create-contract.component';
import { ImageSliderComponent } from './view/image-slider/image-slider.component';
import { RichtextEditorComponent } from './view/richtext-editor/richtext-editor.component';
import { AttachmentViewerComponent } from './view/attachment-viewer/attachment-viewer.component';
import { TimestampFormatPipe } from './helpers/pipes/timestamp-format/timestamp-format.pipe';
import { DialogPdfDownloadComponent } from './view/dialog/dialog-pdf-download/dialog-pdf-download.component';
import { DropdownObjectsSearchComponent } from './view/dropdown-objects-search/dropdown-objects-search.component';
import { VinMissingDataContainerComponent } from './view/cars/create-container/vin-missing-data-container/vin-missing-data-container.component';
import { DropdownStringsComponent } from './view/dropdown-strings/dropdown-strings.component';
import { AdminSettingsComponent } from './view/admin/admin-settings/admin-settings.component';
import { BrowserModule } from '@angular/platform-browser';
import { SubMenuColumnDataComponent } from './view/sub-menu-column-data/sub-menu-column-data.component';
import { BtnActionComponent } from './view/list-action-container/btn-action/btn-action.component';
import { ListActionContainerComponent } from './view/list-action-container/list-action-container.component';
import { NgForCallbackDirectiveDirective } from './directive/NgForCallbackDirective/ng-for-callback-directive.directive';
import { CustomFilterComponent } from './view/cars/custom-filter/custom-filter.component';
import { CustomOutsideFilterComponent } from './view/cars/custom-outside-filter/custom-outside-filter.component';
import { ConflictDialogComponent } from './view/cars/conflict-dialog/conflict-dialog.component';
import { GarantieComponent } from './view/cars/overview-container/garantie/garantie.component';
import { TimestampFormatMonthPipe } from './helpers/pipes/timestamp-format-month/timestamp-format-month.pipe';
import { DokumenteComponent } from './view/dokumente/dokumente.component';
import { UpdateService } from './service/udpate/update.service';
import { DokumenteKaufvertragErstellenComponent } from './view/dokumente/dokumente-kaufvertrag-erstellen/dokumente-kaufvertrag-erstellen.component';
import { DokumenteKaufvertragDetailsComponent } from './view/dokumente/dokumente-kaufvertrag-details/dokumente-kaufvertrag-details.component';
import { ZusatzprodukteComponent } from './view/dokumente/dokumente-kaufvertrag-erstellen/zusatzprodukte/zusatzprodukte.component';
import { RentComponent } from './view/cars/overview-container/rent/rent.component';
import { FileComponent } from './view/files/file/file.component';
import { DropzoneComponent } from "./view/files/dropzone/dropzone.component";
import { AccountingComponent } from './view/accounting/accounting.component';
import { CreateDriveNewComponent } from './view/drives/create-drive-new/create-drive-new.component';
import { DialogKassabuchCreateComponent } from './view/accounting/dialog-kassabuch-create/dialog-kassabuch-create.component';
import { TestDrivesComponent, TestDriveView } from './view/test-drives/test-drives.component';
import { TestDriveCreateComponent } from './view/test-drives/test-drive-create/test-drive-create.component';
import { TestDriveDetailsComponent } from './view/test-drives/test-drive-details/test-drive-details.component';
import { TyresComponent } from './view/tyres/tyres.component';
import { TyresCreateComponent } from './view/tyres/tyres-create/tyres-create.component';
import { TyresDetailsComponent } from './view/tyres/tyres-details/tyres-details.component';
import { DialogTyresStoreOutsourceComponent } from './view/tyres/dialog-tyres-store-outsource/dialog-tyres-store-outsource.component';
import { DialogTyresStorageSpaceComponent } from './view/tyres/dialog-tyres-storage-space/dialog-tyres-storage-space.component';
import { CollapsibleComponent } from './view/design-container/collapsible/collapsible.component';
import { PriceSheetsCreateVorkalkulationNewComponent } from './view/price-sheet/price-sheets-create-vorkalkulation-new/price-sheets-create-vorkalkulation-new.component';
import { DialogTyresStorageSpaceInfoComponent } from './view/tyres/dialog-tyres-storage-space-info/dialog-tyres-storage-space-info.component';
import { TyresImagesComponent } from './view/tyres/tyres-images/tyres-images.component';
import { SettingsComponent } from './view/settings/settings.component';
import { UsersComponent } from './view/users/users.component';
import { DropdownEnumComponent } from './view/design-container/dropdown/dropdown-enum/dropdown-enum.component';
import { DialogTyresVehicleInstallationComponent } from './view/tyres/dialog-tyres-vehicle-installation/dialog-tyres-vehicle-installation.component';
import { UserDetailsComponent } from './view/users/user-details/user-details.component';
import { UserCreateComponent } from './view/users/user-create/user-create.component';
import { LocationsComponent } from './view/locations/locations.component';
import { ImagesWebComponent } from './view/images-web/images-web.component';
import { DialogEnumsComponent } from './view/dialog/dialog-enums/dialog-enums.component';
import { FilesComponent } from './view/files/files.component';
import { DialogTyresChooseLocationComponent } from './view/tyres/dialog-tyres-choose-location/dialog-tyres-choose-location.component';
import { KundenComponent } from './view/kunden/kunden.component';
import { KundeCreateComponent } from './view/kunden/kunde-create/kunde-create.component';
import { KundeDetailsComponent } from './view/kunden/kunde-details/kunde-details.component';
import { DialogTyresSellComponent } from './view/tyres/dialog-tyres-sell/dialog-tyres-sell.component';
import { BillDetailsComponent } from "./view/accounting/bill-details/bill-details.component";
import { FormatValueDirective } from './directive/format-value/format-value.directive';
import { BillDetailsPurchaseContractComponent } from './view/accounting/bill-details-purchase-contract/bill-details-purchase-contract.component';
import { BillEditPurchaseContractComponent } from './view/accounting/bill-edit-purchase-contract/bill-edit-purchase-contract.component';
import { BillCreateComponent } from './view/accounting/bill-create/bill-create.component';
import { DropdownStandardComponent } from './view/design-container/dropdown/dropdown-standard/dropdown-standard.component';
import { BillCreatePositionElementComponent } from './view/accounting/bill-create/bill-create-position-element/bill-create-position-element.component';

@NgModule({
    declarations: [
        AppComponent,
        ViewComponent,
        LoginComponent,
        LoginFormComponent,
        DashboardComponent,
        SidebarComponent,
        CarsComponent,
        CustomerComponent,
        InterfacesComponent,
        AdvertisingComponent,
        AdminComponent,
        MaterialComponent,
        TopbarComponent,
        ListContainerComponent,
        ListTitleComponent,
        ListElementComponent,
        CreateContainerComponent,
        StartContainerComponent,
        BasicDataContainerComponent,
        DriveContainerComponent,
        VehicleContainerComponent,
        EquipmentContainerComponent,
        IdentificationContainerComponent,
        ImageuploadContainerComponent,
        InsertContainerComponent,
        OverviewContainerComponent,
        FilterComponent,
        FooterContainerComponent,
        DragNDropComponent,
        OverviewComponent,
        ImagesComponent,
        BasicDataComponent,
        VehicleComponent,
        DriveComponent,
        InsertComponent,
        IdentificationComponent,
        EquipmentComponent,
        ListCustomerContainerComponent,
        ListCustomerElementComponent,
        TopbarCustomerComponent,
        OverviewCustomerContainerComponent,
        CreateCustomerContainerComponent,
        ListAdminContainerComponent,
        ListAdminElementComponent,
        ListAdminTitleComponent,
        TopbarAdminComponent,
        OverviewUserContainerComponent,
        EditUserContainerComponent,
        ListLocationsContainerComponent,
        ListLocationsElementComponent,
        ListLocationsTitleComponent,
        LoadingComponent,
        DropdownContainerComponent,
        DropdownSelectContainerComponent,
        DropdownDateContainerComponent,
        MessagesComponent,
        MessagesTopbarComponent,
        MessagesListComponent,
        MessagesListElementComponent,
        MessagesCreateComponent,
        MessagesDetailComponent,
        SafeHtmlTxt,
        OverviewTicketsComponent,
        BasicDataContainerZweiComponent,
        NumberFormatPipe,
        ElementDropdownComponent,
        ElementInputComponent,
        OverviewRecordElementsContainerComponent,
        PriceComponent,
        StandTimeComponent,
        EmployeeComponent,
        AttachmentComponent,
        DndDirective,
        OverviewMessagesComponent,
        DialogQueryComponent,
        DialogNotificationComponent,
        AdditionalContainerComponent,
        DiscriptionContainerComponent,
        DescriptionComponent,
        TopbarInterfaceComponent,
        ElementInterfaceComponent,
        ChatInterfaceComponent,
        DocumentsComponent,
        TopbarDocumentsComponent,
        ListDocumentsComponent,
        ListDocumentsElementComponent,
        TextfieldAutoresizeDirective,
        CreateContractDocumentComponent,
        ContractNavigationComponent,
        ContractPageOneComponent,
        ContractPageTwoComponent,
        ContractPageThreeComponent,
        ContractPageFourComponent,
        ContractPageFiveComponent,
        DrivesComponent,
        ListDrivesComponent,
        ListDrivesElementComponent,
        TopbarDrivesComponent,
        CalenderDrivesComponent,
        CreateDriveComponent,
        CreateDriveNewComponent,
        DriveNavigationComponent,
        DrivePageOneComponent,
        DrivePageTwoComponent,
        DrivePageThreeComponent,
        CalendarComponent,
        CalendarTimePickerComponent,
        ServiceComponent,
        AdditionalComponent,
        DetailsContractDocumentComponent,
        TermsComponent,
        UploadDocumentComponent,
        DetailsOfferDocumentComponent,
        DetailsBillDocumentComponent,
        CreateBillDocumentComponent,
        CreateBillDocumentElementComponent,
        CreateBillPageOneComponent,
        CreateBillPageTwoComponent,
        CreateBillNavigationComponent,
        CreateOfferDocumentComponent,
        CreateOfferNavigationComponent,
        CreateOfferPageOneComponent,
        CreateOfferPageTwoComponent,
        CreateOfferPageTwoItemElementComponent,
        ViewDocumentComponent,
        TextinputAddFunctionComponent,
        DialogChangePasswordComponent,
        ResetPasswordComponent,
        ResetPasswordFormComponent,
        TransactionsComponent,
        DragAndDropComponent,
        CustomerFilterComponent,
        DocumentsFilterComponent,
        SortComponent,
        CustomerCreateCarComponent,
        CreateEmployeeComponent,
        ContractVehicleConditionComponent,
        ListDocumentElementContractComponent,
        DriveDetailsComponent,
        DialogPdfDriveComponent,
        AttachmentsContainerComponent,
        AttachmentElementComponent,
        InterfaceOverviewComponent,
        CustomercarComponent,
        SortCustomerComponent,
        MessagesFilterComponent,
        DropdownDirective,
        DocumentsSortComponent,
        SeasonalComponent,
        ConditionComponent,
        DialogCreateDocumentComponent,
        PdfViewComponent,
        DialogMoveComponent,
        CustomMarkInputComponent,
        WerkstattComponent,
        DropdownSimpleComponent,
        DropdownMultipleComponent,
        DropdownObjectsComponent,
        DialogCarOnlineComponent,
        PriceSheetComponent,
        PriceSheetsCreateComponent,
        PriceSheetsDetailsComponent,
        DialogNumberComponent,
        PriceSheetsCreateNackalkulationComponent,
        EditDescriptionComponent,
        ReparaturDialogComponent,
        OpenSignatureLoaderComponent,
        DialogCreateContractComponent,
        ImageSliderComponent,
        RichtextEditorComponent,
        AttachmentViewerComponent,
        TimestampFormatPipe,
        DialogPdfDownloadComponent,
        DropdownObjectsSearchComponent,
        VinMissingDataContainerComponent,
        DropdownStringsComponent,
        AdminSettingsComponent,
        ListActionContainerComponent,
        CustomFilterComponent,
        CustomOutsideFilterComponent,
        ConflictDialogComponent,
        NgForCallbackDirectiveDirective,
        GarantieComponent,
        DokumenteComponent,
        DokumenteKaufvertragErstellenComponent,
        DokumenteKaufvertragDetailsComponent,
        ZusatzprodukteComponent,
        RentComponent,
        AttachmentElementComponent,
        AccountingComponent,
        DialogKassabuchCreateComponent,
        TestDrivesComponent,
        TestDriveCreateComponent,
        TestDriveDetailsComponent,
        TyresComponent,
        TyresCreateComponent,
        TyresDetailsComponent,
        DialogTyresStoreOutsourceComponent,
        DialogTyresStorageSpaceComponent,
        CollapsibleComponent,
        PriceSheetsCreateVorkalkulationNewComponent,
        DialogTyresStorageSpaceInfoComponent,
        TyresImagesComponent,
        SettingsComponent,
        UsersComponent,
        DropdownEnumComponent,
        DialogTyresVehicleInstallationComponent,
        UserDetailsComponent,
        UserCreateComponent,
        LocationsComponent,
        ImagesWebComponent,
        DialogEnumsComponent,
        FilesComponent,
        FileComponent,
        DropzoneComponent,
        DialogTyresChooseLocationComponent,
        KundenComponent,
        KundeCreateComponent,
        KundeDetailsComponent,
        DialogTyresSellComponent,
        BillDetailsComponent,
        BillDetailsPurchaseContractComponent,
        FormatValueDirective,
        BillEditPurchaseContractComponent,
        BillCreateComponent,
        DropdownStandardComponent,
        BillCreatePositionElementComponent
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      DragDropModule,
      CommonModule,
      SubMenuColumnDataComponent,
      BtnActionComponent,
      TimestampFormatMonthPipe,
      ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          registrationStrategy: 'registerWhenStable:10000'
      }),
  ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de'
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        NumberFormatPipe,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
