import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { AuthGuard } from 'src/app/helpers/AuthGuard';

@Component({
  selector: 'app-list-customer-element',
  templateUrl: './list-customer-element.component.html',
  styleUrls: ['./list-customer-element.component.css']
})
export class ListCustomerElementComponent implements OnInit, AfterViewInit, OnDestroy {
  public showDropdown = false;
  public showActionsMoveDeep = false;
  public showActionsMoveDeepDocuments = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  public listener: () => void;

  @Input() customer: Customer;
  @Input() accessEdit: boolean;
  @Output() openDetailsEmitter = new EventEmitter<Customer>();
  @Output() openEditEmitter = new EventEmitter<Customer>();
  @Output() deleteEmitter = new EventEmitter<Customer>();
  @Output() selectionCreateDokumentEmitter = new EventEmitter<Customer>();
  @Output() selectionMoveEmitter = new EventEmitter<Customer>();
  @Output() createCustomerCarEmitter = new EventEmitter<Customer>();
  @Output() moveEmitter = new EventEmitter<{customer: Customer, status: number}>();

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown){
        let isInside = this.dropdown.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdown = false;
          this.showActionsMoveDeep = false;
          this.showActionsMoveDeepDocuments = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  setIcon():string {
    if(this.showDropdown){
      return '../../../../../assets/icon-more-white.png';
    }
    return '../../../../../assets/icon-more.png'
  }

  // createDocument(customer: Customer, docTyp: number) {
  //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['documents/create'], { queryParams: { typ: docTyp, customerId: customer.id } }));
  // }
}
