<div id="dialog_window">
  <div id="dialog_container" appDropdown>
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
      <h1>Standort auswählen</h1>
      <div id="dialog_conainter_body" class="body">
        <label *ngIf="showNoLocationText">Keine Standorte definiert</label>
        <div class="btn-tab-wrapper">
            <button *ngFor="let location of locations; let i = index"
              class="btn-tab"
              [class.btn-tab-first]="i == 0"
              [class.btn-tab-last]="i == locations.length-1"
              [class.btn-tab-active]="form.get('location').value?.storage_location_id == location.storage_location_id"
              (click)="selectionLocation(location)">
                {{location.location_name}}
            </button>
        </div>
      </div>
      <div class="dialog_btn_container">
          <button class="btn_label" (click)="selectionCloseEmitter.emit()">Abbrechen</button>
          <button class="btn_submit_dark" [disabled]="form.invalid" (click)="selectionSubmit()">Speichern</button>
      </div>
  </div>
</div>
