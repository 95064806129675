<div *ngIf="showView == view.LIST">
  <div class="header-base-style">
    <div class="header-inner-wrapper">
      <button class="button_type_1" (click)="showView = view.CREATE" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
        Neu
      </button>
      <div id="main-menu-wrapper">
        <button *ngFor="let mainMenu of mainMenus"
          [class.main-menu-btn-selected]="mainMenu.getActive()"
          (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
          {{mainMenu.name}}
        </button>
      </div>
    </div>
    <div>
    </div>
  </div>
  <app-list-container *ngIf="_activeSubMenu.getValue()"
      [objectTyp]="'tire'"
      [objects]="_objectsFiltered.getValue()"
      [pageHandler]="pageHandler"
      [filter]="filter"
      [mainMenus]="mainMenus"
      [activeSubMenu$]="activeSubMenu$"
      [activeSortingColumn]="activeSortingColumn"
      (pageEmitter)="pageHandling($event)"
      (applySortingEmitter)="applySorting($event)"
      (openDetailsEmitter)="selectionDetails($event)"
      (openEditEmitter)="selectionEdit($event)"
      (deleteEmitter)="selectionDelete($event)"
      (selectionArchivEmitter)="selectionArchiv($event)"
      (selectionRestoreEmitter)="selectionRestore($event)"
  ></app-list-container>
</div>

<app-tyres-create *ngIf="showView == view.CREATE"
  [obj]="selectedObject"
  [freeVehicles]="freeVehicles"
  [vehicles]="vehicles"
  [locations]="possibleLocations"
  (selectionBackEmitter)="selectionCancelCreate()"
  (createSuccessEmitter)="createSuccess($event)"
  (createInstalationSuccessEmitter)="createInstalationSuccess($event)"
  (updateSuccessEmitter)="updateSuccess($event)"
></app-tyres-create>

<app-tyres-details *ngIf="showView == view.DETAILS"
  [obj]="selectedObject"
  [isPreviousObjAvailable]="isPreviousObjAvailable"
  [isNextObjAvailable]="isNextObjAvailable"
  (selectionCloseEmitter)="selectionCloseDetails()"
  (selectionEditEmitter)="selectionEdit($event)"
  (selectionChangeStorageEmitter)="selectionChangeStorage($event)"
  (selectionInstallationEmitter)="selectionInstallation($event)"
  (selectionChangeStorageAutoEmitter)="selectionChangeStorageAuto($event)"
  (selectionSellEmitter)="openSellDialog($event)"
  (selectionPreviousObjEmitter)="selectionPreviousObj($event)"
  (selectionNextObjEmitter)="selectionNextObj($event)"
></app-tyres-details>

<app-dialog-tyres-store-outsource *ngIf="showDialogInstallation"
  [obj]="selectedObject"
  [vehicles]="freeVehicles"
  (selectionCloseEmitter)="selectionCloseInstallation()"
  (successInstallationEmitter)="successInstallation($event)"
></app-dialog-tyres-store-outsource>

<app-dialog-tyres-storage-space-info *ngIf="showDialogStorageInfo"
  [obj]="selectedObject"
  (selectionCloseEmitter)="closeDialogStorageSpaceInfo()"
  (submitEmitter)="closeDialogStorageSpaceInfo()"
></app-dialog-tyres-storage-space-info>

<app-dialog-tyres-storage-space *ngIf="showDialogStorage"
  [locations]="possibleLocations"
  [obj]="selectedObject"
  (selectionCloseEmitter)="selectionCancelChangeStorage()"
  (selectionCancelEmitter)="selectionCancelChangeStorage()"
  (successChangeStorageEmitter)="successChangeStorage($event)"
></app-dialog-tyres-storage-space>

<app-dialog-tyres-choose-location *ngIf="showDialogChangeStorageAuto"
  [locations]="locationsChoose"
  (selectionCloseEmitter)="selectionCloseChangeStorageAuto($event)"
  (submitLocationEmitter)="submitChangeStorageAuto($event)"
></app-dialog-tyres-choose-location>

<app-dialog-tyres-sell *ngIf="showDialogSell"
  (selectionCloseEmitter)="selectionCloseSellDialog($event)"
  (selectionSubmitEmitter)="selectionSell($event)"
></app-dialog-tyres-sell>


