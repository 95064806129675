<app-collapsible [isCollapsible]="!editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Preis</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Preis kontrolliert</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.price_checked)}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Verkaufspreis</div>
          <div class="overview_record_value">{{vehicle.price ? (vehicle.price | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl() && vehicle.priceSheet && vehicle.priceSheet.steuersatz==1">
        <div class="overview_record_lbl">Verkaufspreis (ohne Ust.)</div>
        <div class="overview_record_value" *ngIf="vehicle.priceSheet && vehicle.priceSheet.steuersatz == 1">{{(vehicle.priceSheet && vehicle.price) ? (vehicle.price - vehicle.priceSheet.ust_vor | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
        <div class="overview_record_value" *ngIf="vehicle.priceSheet && vehicle.priceSheet.steuersatz == 2">{{(vehicle.priceSheet && vehicle.price) ? (vehicle.price | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Händlerpreis</div>
        <div class="overview_record_value">{{vehicle.dealerPrice ? (vehicle.dealerPrice | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Export Preis</div>
        <div class="overview_record_value">{{vehicle.export_price ? (vehicle.export_price | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl() && vehicle.priceSheet && vehicle.priceSheet.steuersatz==1">
        <div class="overview_record_lbl">Export Preis (ohne Ust.)</div>
        <div class="overview_record_value">{{vehicle.export_price ? ((vehicle.export_price /1.2) | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Steuersatz</label>
        <label class="overview_record_value">{{getSteuersatz(vehicle.priceSheet?.steuersatz)}}</label>
      </div>
      <div class="overview_record" *ngIf="!authService.isHofbauer() && !authService.isHoedl()">
        <div class="overview_record_lbl">Mietpreis pro Monat</div>
        <div class="overview_record_value">{{vehicle.rent_price ? (vehicle.rent_price | currency: 'EUR': '€': '1.0-0') : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Gewährleistungen</div>
        <div class="overview_record_value">{{vehicle.guarantee == true ? 'Ja' : (vehicle.guarantee == false ? 'Nein' : '---')}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">MwSt. ausweisbar</div>
        <div class="overview_record_value">{{vehicle.vat_reportable == true ? 'Ja' : (vehicle.vat_reportable == false ? 'Nein' : '---')}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Leasingfähig</div>
        <div class="overview_record_value">{{vehicle.leasable == true ? 'Ja' : (vehicle.leasable == false ? 'Nein' : '---')}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Aufpreis Felgen</div>
        <div class="overview_record_value">{{getInputValueCurrency(vehicle.felgen_aufpreis)}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Aufpreis Fahrwerk</div>
        <div class="overview_record_value">{{getInputValueCurrency(vehicle.fahrwerk_aufpreis)}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Transportkosten</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.sprit ? (vehicle.priceSheet.sprit | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Handlingkosten</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.lieferant_extern ? (vehicle.priceSheet.lieferant_extern | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <label class="overview_record_lbl">Zubau</label>
        <div class="overview_record_value">{{getInputValueCurrency(vehicle.priceSheet?.zubau)}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">EK Preis</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.einkaufspreis_brutto ? (vehicle.priceSheet?.einkaufspreis_brutto | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Gesamtkosten</div>
        <div class="overview_record_value">{{getInputValueCurrency(vehicle.getGesamtkosten())}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">Vermittlung</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.is_vermittlung)}}</div>
      </div>
      <div class="overview_data_box_lbl_inner_title" style="margin-top: 12px;">Neupreis</div>
      <div class="overview_record">
        <div class="overview_record_lbl">Fahrzeugpreis</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.neu_preis ? (vehicle.priceSheet.neu_preis | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Sonderausstattung</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.sonderaustattung ? (vehicle.priceSheet.sonderaustattung | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Gesamt Neupreis</div>
        <div class="overview_record_value">{{vehicle.priceSheet?.neu_preis || vehicle.priceSheet?.sonderaustattung ? (vehicle.priceSheet?.neu_preis + vehicle.priceSheet?.sonderaustattung  | currency: 'EUR': '€': '1.2-2') : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="formGroupVehicle" class="overview_data_form">
        <div class="input_wrapper slider_grid" *ngIf="authService.isHoedl()">
          <div class="checkbox_lbl">Preis kontrolliert</div>
          <label class="switch" [ngStyle]="{'cursor': 'unset'}">
              <input type="checkbox" [checked]="formGroupVehicle.controls['price_checked'].value" (click)="formGroupVehicle.controls['price_checked'].setValue(!formGroupVehicle.controls['price_checked'].value)">
              <span class="slider round"></span>
          </label>
        </div>
        <div class="input_wrapper" *ngIf="!resctricted">
          <label class="overview_record_lbl">Verkaufspreis in €</label>
          <input type="number" placeholder="Verkaufspreis in €" formControlName="price" [class.input_invalid]="submitted && formGroupVehicle.controls['price'].errors">
        </div>
        <div class="input_wrapper" *ngIf="!resctricted">
          <label class="overview_record_lbl">Händlerpreis in €</label>
          <input type="number" class="overview_input" placeholder="Händlerpreis in €" formControlName="dealerPrice">
        </div>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <label class="overview_record_lbl">Export Preis in €</label>
          <input type="number" class="overview_input" placeholder="Export Preis in €" formControlName="export_price">
        </div>
        <app-dropdown-multiple *ngIf="authService.isHoedl()"
          [title]="'Steuersatz'"
          [formcontrol]="f['steuersatz_pricesheet']"
          [headerText]="getSteuersatz(f['steuersatz_pricesheet'].value)"
          [values]="steuerseatze.slice(0,2)"
          [submitted]="submitted"
          (submitValueEmitter)="f['steuersatz_pricesheet'].setValue($event)"
        ></app-dropdown-multiple>
        <div class="input_wrapper" *ngIf="!authService.isHofbauer() && !authService.isHoedl()">
          <label class="overview_record_lbl">Mietpreis pro Monat in €</label>
          <input type="number" class="overview_input" placeholder="Preis pro Monat in €" formControlName="rent_price">
        </div>
        <div *ngIf="authService.isHoedl()" class="input_wrapper">
          <div class="overview_record_lbl">Aufpreis Felgen</div>
          <input type="number" placeholder="Aufpreis Felgen" formControlName="felgen_aufpreis"  [class.input_invalid]="submitted && f['felgen_aufpreis'].errors">
        </div>
        <div *ngIf="authService.isHoedl()" class="input_wrapper">
          <div class="overview_record_lbl">Aufpreis Fahrwerk</div>
          <input type="number" placeholder="Aufpreis Fahrwerk" formControlName="fahrwerk_aufpreis"  [class.input_invalid]="submitted && f['fahrwerk_aufpreis'].errors">
        </div>
        <div *ngIf="authService.isHoedl()" class="input_wrapper">
          <label class="overview_record_lbl">Transportkosten in € *</label>
          <input type="number" placeholder="Transportkosten in €" formControlName="sprit" [class.input_invalid]="submitted && f['sprit'].errors">
        </div>
        <div *ngIf="authService.isHoedl()" class="input_wrapper">
          <label class="overview_record_lbl">Handlingkosten in € *</label>
          <input type="number" placeholder="Handlingkosten in €" formControlName="lieferant_extern" [class.input_invalid]="submitted && f['lieferant_extern'].errors">
        </div>
        <div *ngIf="authService.isHoedl()" class="input_wrapper">
          <label class="overview_record_lbl">EK Preis in € *</label>
          <input type="number" placeholder="EK Preis in €" formControlName="einkaufspreis_pricesheet" [class.input_invalid]="submitted && f['einkaufspreis_pricesheet'].errors">
        </div>
        <app-dropdown-simple *ngIf="authService.isHoedl()"
          [title]="'Vermittlung'"
          [formcontrol]="f.is_vermittlung"
          (submitValueEmitter)="f.is_vermittlung.setValue($event)"
        ></app-dropdown-simple>
        <app-dropdown-simple
          [title]="'Gewährleistungen'"
          [formcontrol]="f.guarantee"
          (submitValueEmitter)="f.guarantee.setValue($event)"
        ></app-dropdown-simple>
          <app-dropdown-simple
          [title]="'MwSt. ausweisbar'"
          [formcontrol]="f.vat_reportable"
          (submitValueEmitter)="f.vat_reportable.setValue($event)"
        ></app-dropdown-simple>
        <app-dropdown-simple
          [title]="'Leasingfähig'"
          [formcontrol]="f.leasable"
          (submitValueEmitter)="f.leasable.setValue($event)"
        ></app-dropdown-simple>
    </form>
  </div>
</app-collapsible>
