import { VehicleEquipment } from 'src/app/buisness-object/vehicle/optional/VehicleEquipment';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { FormGroup } from '@angular/forms';
import { AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() editMode: boolean;

  public inseratDisable = false;
  public equipments: VehicleEquipment[] = [];
  public categoriesVehicle: any[] = [];
  public categories: any[] = [];

  constructor(
    private vService: VehicleService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if(this.vehicle.equipments) this.getEquipmentThisVehicle();
    if(this.form){
      this.inseratDisable = this.authService.isHoedlAdmin() ? false : this.form.get('inserat_checked').value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode || changes.form){
      if(this.editMode && this.form != null){
        this.form.get('inserat_checked').valueChanges.subscribe(value => {
          this.inseratDisable = this.authService.isHoedlAdmin() ? false : value;
        });
      }
    }
    if(this.editMode){
      if(this.vehicle.equipments) this.fillForm();
      this.getEquiptment();
    } else {
      if(this.vehicle.equipments) this.getEquipmentThisVehicle();
    }
  }

  ngAfterViewChecked(): void {
    //this.resizeAllGridItems();
  }

  fillForm() {
    this.form.controls.equipments.setValue([]);
    for(let eq of this.vehicle.equipments){
      this.form.controls.equipments.value.push({
        eq_id: eq.eq_id,
        eq_name: eq.eq_name,
        is_selected: true
      })
    }
  }

  getEquipmentThisVehicle() {
    this.categoriesVehicle = [];
    for(let eq of this.vehicle.equipments){
      let index = this.categoriesVehicle.findIndex(cat => cat.category_id == eq.category_id);
      if(index == -1){
        this.categoriesVehicle.push({
          category_id: eq.category_id,
          category_name: eq.category_name,
          equipments: [
            {
              eq_id: eq.eq_id,
              eq_name: eq.eq_name,
              is_selected: false
            }
          ]
        });
      } else {
        this.categoriesVehicle[index].equipments.push({
          eq_id: eq.eq_id,
          eq_name: eq.eq_name,
          is_selected: false
        })
      }
    }
  }

  getEquiptment() {
    this.categories = [];
    this.vService.getEquipment().subscribe((equipments) => {
      if(equipments && this.form){
        this.equipments = equipments;
        for(let eq of this.equipments){
          let index = this.categories.findIndex(cat => cat.category_id == eq.category_id);
          let is_selected = false;
          for(let x of this.form.controls?.equipments.value){
            if(x.eq_id == eq.eq_id){
              is_selected = true;
              break;
            }
          }
          if(index == -1){
            this.categories.push({
              category_id: eq.category_id,
              category_name: eq.category_name,
              equipments: [
                {
                  eq_id: eq.eq_id,
                  eq_name: eq.eq_name,
                  is_selected: is_selected
                }
              ]
            });
          } else {
            this.categories[index].equipments.push({
              eq_id: eq.eq_id,
              eq_name: eq.eq_name,
              is_selected: is_selected
            })
          }
        }
      }
    })
  }

  isSelected(equipment: any): boolean {
    for(let e of this.form.controls.equipments.value){
      if(equipment.eq_id == e.eq_id){
        return true;
      }
    }
    return false;
  }

  addEquipment(equipment: any) {
    equipment.is_selected = !equipment.is_selected;
    if(equipment.is_selected){
      this.form.controls.equipments.value.push(equipment);
    } else {
      let index = this.form.controls.equipments.value.findIndex(e => e.eq_id == equipment.eq_id);
      if(index >= 0){
        this.form.controls.equipments.value.splice(index, 1);
      }
    }
  }

  resizeGridItem(item){
    //let grid = document.getElementById(this.editMode ? 'grid_2' : 'grid_1');
    let grid = document.getElementById('grid');
    let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    let innerItems = item.querySelectorAll('.checkbox_container');
    let rowSpan = 0;
    for(let i of innerItems){
      rowSpan += Math.ceil((i.getBoundingClientRect().height+rowGap)/(rowHeight+rowGap))
    }
    item.style.gridRowEnd = "span "+(rowSpan == 1 ? 2 : rowSpan);
  }

  resizeAllGridItems(){
    const allItems = document.querySelectorAll(".category_container");
    for (var i = 0; i < allItems.length; i++){
      this.resizeGridItem(allItems[i]);
    }
  }
}
