import { HttpEvent, HttpResponse } from '@angular/common/http';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { DragAndDrop } from 'src/app/helpers/DragAndDrop';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TireService } from 'src/app/service/tire/tire.service';


@Component({
  selector: 'app-images-web',
  templateUrl: './images-web.component.html',
  styleUrl: './images-web.component.css'
})
export class ImagesWebComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterViewChecked, OnChanges, OnDestroy {
  @Input() obj: any;
  @Input() form: FormGroup;
  public gallerySwiper: Picture[] = [];
  public gallery: Picture[] = [];
  public titlePicture: Picture;
  public position = 0;
  public uploadProgress = 0;
  public filesAmount = 0;
  public currentFile = 0;
  public onloadSrcs: string[] = [];
  public imagesLoading = false;
  public imageRequestSubs: Subscription[] = [];

  public showSlider = false;
  public sliderSliceFrom = 0;
  public sliderSliceTo = 0;

  constructor(
    public tService: TireService,
    public dService: DialogService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.obj){
      let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
      if(wrapperBox){
        wrapperBox.setAttribute('style', 'padding-right: 10px;')
      }
    }
  }

  ngAfterContentChecked(): void {
    DragAndDrop.registerHandler('.drag_item_container .drag_item');
  }

  ngAfterViewChecked(): void {
    if(this.obj && GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop){
      let dragItem = DragAndDrop.getDragItem(this.gallery);
      let dropItem = DragAndDrop.getDropItem(this.gallery);
      if(dragItem && dropItem){
        dragItem.position = dropItem.position;
        GlobalVariables.drag_item_drag = undefined;
        GlobalVariables.drag_item_drop = undefined;
        this.updateImagePostition(dragItem);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['obj'] && changes['obj'].currentValue){
      this.obj = changes['obj'].currentValue;
      this.titlePicture = null;
      this.gallery = [];
      this.gallerySwiper = [];
      setTimeout(() => {
        this.getDataServerSide();
      }, 400);
    }
    if(changes['obj']){
      if(this.obj){
        let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 10px;')
      } else {
        let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 0px;')
      }
    }
  }

  ngOnDestroy(): void {
    for(let imgSub of this.imageRequestSubs){
      if(imgSub) imgSub.unsubscribe();
    }
  }

  updateImagePostition(pic: Picture) {
    this.tService.updateTirePicture(pic).subscribe((response) => {
      if(response){
        for(let data of response.pictures){
          for(let pic of this.gallery){
            if(data.picture_id == pic.picture_id){
              pic.position = data.position;
            }
          }
        }
        this.sortGalleryAfterPosition();
        this.form.controls['pictures'].setValue(this.gallerySwiper);
       }
    })
  }

  sortGalleryAfterPosition(){
    this.gallery.sort((a,b) => {
      if(a.position > b.position) return 1;
      if(a.position < b.position) return -1;
      return 0
    })
    this.gallerySwiper.sort((a,b) => {
      if(a.position > b.position) return 1;
      if(a.position < b.position) return -1;
      return 0
    });
  }

  async getDataServerSide(){
    this.titlePicture = null;
    this.gallery = [];
    this.gallerySwiper = [];
    for(let i = 0; i < this.obj.pictures.length; i++){
      this.imagesLoading = true;
      this.imageRequestSubs.push(
        this.tService.getTirePicture(this.obj.pictures[i].picture_id).subscribe((data) => {
          if(data && this.obj?.pictures[i] != null){
            this.obj.pictures[i].vehicle_id = this.obj.tire_id;
            let src = 'data:image/png;base64,'+data.trim();
            let picture = new Picture(this.obj.pictures[i].picture_id, this.obj.pictures[i].position, this.obj.pictures[i].vehicle_id, src);
            if(this.obj.pictures[i].position == 1){
              this.titlePicture = picture;
            } else {
              this.gallery.push(picture);
            }
            this.gallerySwiper.push(picture);
            this.sortGalleryAfterPosition();
            if(i == this.obj.pictures.length -1) this.imagesLoading = false;
          }
        })
      )
    }
  }

  async uploadFile(event: any): Promise<any> {
    this.currentFile = 0;
    this.onloadSrcs = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFiles(event.target.files[i]);
      }
      await this.uploadImgToServer();
      if(!this.obj.thumbnail){
        this.tService.getTireThumbnail(this.titlePicture.picture_id).subscribe((result) => {
          if(result){
            this.obj.thumbnail = 'data:image/png;base64,'+result.trim();
          }
        })
      }
    }
  }

  async readFiles(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.onloadSrcs.push(event.target.result as string);
        resolve(true)
      }
      reader.onerror = (event: any) => {
        reject(false);
      }
      reader.readAsDataURL(files);
    })
  }

  async uploadImgToServer(): Promise<any> {
    let i = 1;
    for(let src of this.onloadSrcs){
      if(this.obj == null){
        this.addPictureToForm(src, i);
      } else {
        await this.createTirePicture(src);
      }
      i++;
    }
    if(this.obj != null){
      this.obj.pictures = this.gallerySwiper;
      this.form.get('pictures').setValue(this.gallerySwiper);
    }
  }

  addPictureToForm(src: string, position: number) {
    let picture = new Picture(0, position, 0, src);
    this.form.get('pictures').value.push(picture);
    this.gallerySwiper.push(picture);
    if(position == 0) this.titlePicture = picture;
  }

  createTirePicture(src: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.tService.createTirePicture(this.obj.tire_id, src.split(',')[1]).subscribe((event: HttpEvent<any>) => {
        let response = event as HttpResponse<any>;
        if(response.body){
          this.currentFile += 1;
          let picture = new Picture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,this.obj.tire_id, src);
          if(response.body.pictures[response.body.pictures.length - 1].position != 1) {
            this.gallery.push(picture);
          } else {
            this.titlePicture = picture;
          }
          this.gallerySwiper.push(picture);
          resolve(true);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  async onFileDropped(data: any) {
    // this.filesAmount += 1;
    if(data){
      for(let d of data.data){
        await this.createTirePicture(d.content);
      }
    }
    this.obj.pictures = this.gallerySwiper;
    this.form.controls['pictures'].setValue(this.gallerySwiper);
  }

  setAsTitle(pic: Picture) {
    this.updateImage(pic);
  }

  updateImage(pic: Picture) {
    let imgToUpdate = CloneObject.deepCopy(pic);
    let currentTitleImg = CloneObject.deepCopy(this.titlePicture);
    imgToUpdate.position = 1;
    this.tService.updateTirePicture(imgToUpdate).subscribe((response) => {
      if(response){
        for(let i = 0; i < this.gallery.length; i++){
          if(this.gallery[i].picture_id == imgToUpdate.picture_id){
            let temp = CloneObject.deepCopy(this.gallery[i]);

            this.gallery[i] = currentTitleImg;
            this.gallery[i].position = temp.position;

            this.titlePicture = imgToUpdate;
            break;
          }
        }
        this.gallerySwiper = [];
        for(let i of this.gallery){
          this.gallerySwiper.push(CloneObject.deepCopy(i));
        }
        this.gallerySwiper.unshift(CloneObject.deepCopy(this.titlePicture));
        this.tService.getTireThumbnail(this.titlePicture.picture_id).subscribe((response) => {
          if(response){
            this.obj.thumbnail = 'data:image/png;base64,'+response.trim();
            this.form.controls['thumbnail'].setValue(this.obj.thumbnail);
            DragAndDrop.registerHandler('.drag_item_container .drag_item');
          }
        });
        this.obj.pictures = this.gallerySwiper;
        this.form.controls['pictures'].setValue(this.gallerySwiper);
      }
    });
  }

  removeImage(pic: Picture) {
    this.tService.deletePicture(pic.vehicle_id, pic.picture_id).subscribe((success) => {
      if(success){
        let indexGallery = this.gallery.findIndex(p => p.picture_id == pic.picture_id);
        if(indexGallery > -1) this.gallery.splice(indexGallery, 1);
        let index = this.obj.pictures.findIndex(p => p.picture_id == pic.picture_id);
        if(index > -1) this.obj.pictures.splice(index, 1);
        if(this.titlePicture?.picture_id == pic.picture_id){
          if(this.gallery.length > 0){
            this.titlePicture = this.gallery[0];
            this.gallery.splice(0, 1);
            this.setAsTitle(this.titlePicture);
          } else {
            this.titlePicture = null;
          }
        };
        if(this.obj.pictures.length == 0){
          this.obj.thumbnail = null;
          this.form.controls['thumbnail'].setValue(null);
        }
        for(let i = 0; i < this.gallerySwiper.length; i++){
          if(pic.picture_id == this.gallerySwiper[i].picture_id){
            this.gallerySwiper.splice(i, 1);
            break;
          }
        }
        this.form.controls['pictures'].setValue(this.gallerySwiper);
      }
    })
  }

  getProgress(): string {
    return this.uploadProgress + '%';
  }

  openSlider(pic: Picture) {
    if(this.obj) {
      return;
    }
    let index = this.gallerySwiper.findIndex(p => p.picture_id == pic.picture_id);
    let base64Array = [];
    this.gallerySwiper.forEach(element => {
      base64Array.push(element.src);
    });
    this.dService.OpenImageSlider({base64Array: base64Array, startIndex: index});
  }

  closeSlider() {
    this.showSlider = false;
  }

  nextPicture() {
    if(this.sliderSliceTo != this.gallerySwiper.length){
      this.sliderSliceFrom++;
      this.sliderSliceTo++;
    }
  }

  previousPicture() {
    if(this.sliderSliceFrom != 0){
      this.sliderSliceFrom--;
      this.sliderSliceTo--;
    }
  }

  openSingleImage(pic: Picture) {
    this.dService.OpenImageSlider({base64Array: [pic.src], startIndex: 0});
  }
}

