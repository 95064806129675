import { Employee } from "../buisness-object/employee/Employee";
import { RoleFactory } from "../buisness-object/employee/factory/RoleFactory";
import { Role } from "../buisness-object/employee/Role";
import { GlobalVariables } from "./GlobalVars";

export class AuthGuard {
  public roles = [
    new Role(1,'admin_view'),
    new Role(2,'admin_edit'),
    new Role(3,'customer_view'),
    new Role(4,'customer_edit'),
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
    new Role(8,'document_view'),
    new Role(9,'document_edit'),
    new Role(10,'test_drive_view'),
    new Role(11,'test_drive_edit')
  ];
  public waymark = [
    new Role(1,'admin_view'),
    new Role(2,'admin_edit'),
    new Role(3,'customer_view'),
    new Role(4,'customer_edit'),
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
    new Role(8,'document_view'),
    new Role(9,'document_edit'),
    new Role(10,'test_drive_view'),
    new Role(11,'test_drive_edit')
  ];
  public admin = [
    new Role(1,'admin_view'),
    new Role(2,'admin_edit'),
    new Role(3,'customer_view'),
    new Role(4,'customer_edit'),
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
    new Role(8,'document_view'),
    new Role(9,'document_edit'),
    new Role(10,'test_drive_view'),
    new Role(11,'test_drive_edit')
  ];
  public accounting = [
    new Role(3,'customer_view'),
    new Role(4,'customer_edit'),
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
  ];
  public employeeWerkstatt = [
    new Role(3,'customer_view'),
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
  ];
  public employeeOnlyView= [
    new Role(3,'customer_view'),
    new Role(5,'vehicle_view'),
    new Role(7,'ticketing'),
  ];
  public employeeMechaniker = [
    new Role(10,'test_drive_view'),
    new Role(11,'test_drive_edit')
  ]
  public employeeProbefahrt = [
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(10,'test_drive_view'),
    new Role(11,'test_drive_edit')
  ]
  public employeeEmpfang = [
    new Role(5,'vehicle_view'),
    new Role(6,'vehicle_edit'),
    new Role(7,'ticketing'),
    new Role(15,'empfang_view'),
  ];

  public roleGroups: Role[] = [
    new Role(1,'Administration'),
    new Role(2,'Administration'),
    new Role(3,'Buchhaltung'),
    new Role(4,'Verkauf'),
    new Role(5,'Werkstatt'),
    new Role(6,'Ersatzteile'),
    new Role(7,'Probefahrt'),
  ]

  private authOne = 'Administration';
  private authTwo = 'Administration';
  private authThree = 'Buchhaltung';
  private authFour = 'Verkauf';
  private authFive = 'Werkstatt';
  private authSix = 'Ersatzteile';
  private authSeven = 'Probefahrt';
  private authEight = 'Empfang';

  constructor(){}

  hasAccess(roleId: number): boolean {
    let auth = Number(localStorage.getItem('auth'));
    let currentUserRoles: Role[];
    switch (auth) {
      case 1: currentUserRoles = this.waymark; break;
      case 2: currentUserRoles = this.admin; break;
      case 3: currentUserRoles = this.accounting; break;
      case 4: currentUserRoles = this.employeeOnlyView; break;
      case 5: currentUserRoles = this.employeeWerkstatt; break;
      case 6: currentUserRoles = this.employeeMechaniker; break;
      case 7: currentUserRoles = this.employeeProbefahrt; break;
      case 8: currentUserRoles = this.employeeEmpfang; break;
      case 11: currentUserRoles = this.admin; break;
      default: currentUserRoles = []; break;
    }
    if(currentUserRoles.find(e => e.roleId == roleId)){
      return true;
    }
    return false;
  }

  get waymarkRoles() {
    return this.waymark;
  }
  get adminRoles() {
    return this.admin;
  }
  get employeeRoles() {
    return this.accounting;
  }
  get employeeOnlyViewRoles() {
    return this.employeeOnlyView;
  }
  get employeeWerkstattRoles() {
    return this.employeeWerkstatt;
  }
  get employeeMechanikerRoles() {
    return this.employeeMechaniker;
  }
  get probefahrRoles() {
    return this.employeeProbefahrt;
  }
  get empfangRoles() {
    return this.employeeEmpfang;
  }

  getAuthRoles(id: number): Role[] {
    switch (id) {
      case 1: return this.roles; break;
      case 2: return this.roles; break;
      case 3: return this.accounting; break;
      case 4: return this.employeeOnlyView; break;
      case 5: return this.employeeWerkstatt; break;
      case 6: return this.employeeMechaniker; break;
      case 7: return this.probefahrRoles; break;
      default: return []; break;
    }
  }

  getLoginAuthentication(data: any): number {
    let roles: Role[] = RoleFactory.jsonFactory(data);
    if(this.isWaymark(roles)){
      return 1;
    } else if(this.isAdmin(roles)){
      return 2;
    } else if(this.isAccounting(roles)){
      return 3;
    } else if(this.isEmployeeOnlyView(roles)){
      return 4;
    } else if(this.isEmployeeWerkstatt(roles)){
      return 5;
    } else if(this.isEmployeeMechaniker(roles)){
      return 6;
    } else if(this.isEmployeeProbefahrt(roles)){
      return 7;
    } else if(this.isEmployeeEmpfang(roles)){
      return 8;
    }
    return 0;
  }

  getAuthentication(employee: Employee) {
    if(this.isWaymark(employee.roles)){
      return 1;
    }
    if(this.isAdmin(employee.roles)){
      return 2;
    }
    if(this.isAccounting(employee.roles)){
      return 3;
    }
    if(this.isEmployeeWerkstatt(employee.roles)){
      return 5;
    }
    if(this.isEmployeeOnlyView(employee.roles)){
      return 4;
    }
    if(this.isEmployeeMechaniker(employee.roles)){
      return 6;
    }
    if(this.isEmployeeProbefahrt(employee.roles)){
      return 7;
    }
    if(this.isEmployeeEmpfang(employee.roles)){
      return 8;
    }
    return 0;
  }

  getRoleName(employee: Employee): string {
    let auth =  this.getAuthentication(employee);
    switch (auth) {
      case 1: return this.authOne; break;
      case 2: return this.authTwo; break;
      case 3: return this.authThree; break;
      case 4: return this.authFour; break;
      case 5: return this.authFive; break;
      case 6: return this.authSix; break;
      case 7: return this.authSeven; break;
      case 8: return this.authEight; break;
      default: return '---'; break;
    }
  }

  isWaymark(roles: Role[]): boolean {
    for(let r of this.waymark){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isAdmin(roles: Role[]): boolean {
      for(let r of this.admin){
          let isIncluded = false;
          for(let role of roles){
              if(role.roleId == r.roleId){
                  isIncluded = true;
                  break;
              }
          }
          if(!isIncluded){
              return false;
          }
      }
      return true;
  }

  isAccounting(roles: Role[]): boolean {
      for(let r of this.accounting){
          let isIncluded = false;
          for(let role of roles){
              if(role.roleId == r.roleId){
                  isIncluded = true;
                  break;
              }
          }
          if(!isIncluded){
              return false;
          }
      }
      return true;
  }

  isEmployeeOnlyView(roles: Role[]): boolean {
    for(let r of this.employeeOnlyView){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isEmployeeWerkstatt(roles: Role[]): boolean {
    for(let r of this.employeeWerkstatt){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isEmployeeMechaniker(roles: Role[]): boolean {
    for(let r of this.employeeMechaniker){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isEmployeeProbefahrt(roles: Role[]): boolean {
    for(let r of this.employeeProbefahrt){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isEmployeeEmpfang(roles: Role[]): boolean {
    for(let r of this.employeeEmpfang){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  isInterface(): boolean {
    return Number(localStorage.getItem('employee_id')) == 42; //Holzweber
  }
}
