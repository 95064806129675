import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { LoginService } from 'src/app/service/login/login.service';
import { DrivesService } from 'src/app/service/drives/drives.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import {
  SubMenuFilter,
  SubMenuOperator,
} from 'src/app/buisness-object/menu/SubMenuFilter';
import { MainMenuFactory } from 'src/app/buisness-object/menu/MainMenuFactory';
import { MasterMenu } from 'src/app/buisness-object/menu/EnumMasterMenu';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { FormGroup } from '@angular/forms';
import { DateHelper } from 'src/app/utils/DateHelper';
import { DocumentService } from 'src/app/service/document/document.service';
import { DokumentTyp } from '../dokumente/dokumente.component';
import { EnumService } from 'src/app/service/enum/enum.service';
import { SearchHelper } from 'src/app/utils/SearchHelper';
import { DynamicListHelper } from 'src/app/utils/DynamicListHelper';
import { LoadingService } from 'src/app/service/loading/loading.service';

export enum TestDriveView {
  LIST = 1,
  CREATE = 2,
  DETAILS = 3,
}

@Component({
  selector: 'app-test-drives',
  templateUrl: './test-drives.component.html',
  styleUrl: './test-drives.component.css',
})
export class TestDrivesComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  //dynamic menu
  public mainMenus: MainMenu[] = [];
  public _activeSubMenu: BehaviorSubject<SubMenu> = new BehaviorSubject<SubMenu>(null);
  public activeSubMenu$ = this._activeSubMenu.asObservable();
  public activeSubMenu: SubMenu;
  public _activeSortingColumn: BehaviorSubject<SubMenuColumn> = new BehaviorSubject<SubMenuColumn>(null);
  public activeSortingColumn$ = this._activeSortingColumn.asObservable();
  public activeSortingColumn: SubMenuColumn;
  //dynamic list
  public objects: any[] = [];
  public _objectsFiltered: BehaviorSubject<Drive[]> = new BehaviorSubject<any[]>([]);
  public _objectsFiltered$ = this._objectsFiltered.asObservable();
  public activeFilter: any[] = [];
  public searchValue;
  public searchTimeout: any;
  public pageHandler: PageHandler;
  public dynamicListLastPosition;

  public view = TestDriveView;
  public showView: TestDriveView = TestDriveView.LIST;
  public showDropdownMainButton = false;

  public vehicles: Vehicle[] = [];
  public customers: Customer[] = [];
  public employees: Employee[] = [];

  public createTyp;
  public selectedObject;
  public dialogQuerySubsription: Subscription;
  public showDialogCreatePDF = false;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private loginService: LoginService,
    private driveService: DrivesService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private employeeService: EmployeeService,
    private dialogService: DialogService,
    private attachmentService: AttachmentService,
    private documentService: DocumentService,
    private loadingService: LoadingService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.getData();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getData() {
    this.loadingService.startLoading();
    forkJoin({
      drives: this.driveService.getTestDrives(),
      customers: this.customerService.getCustomer(),
      vehicles: this.vehicleService.getVehicle(),
      employees: this.employeeService.getEmployees(),
    }).subscribe((result) => {
      if (result) {
        this.objects = result.drives;
        this.vehicles = result.vehicles;
        this.customers = result.customers;
        this.employees = result.employees;
        this.mergeData();
        this.setUpMenu();
        this.activeSubMenu$.subscribe((active) => {
          if(active) {
            this.activeSubMenu = active;
            this.activeFilter = [];
            this.activeSortingColumn = null;
            this.rootFilter();
            this.setUpSearch();
          }
        });
        this.loadingService.stopLoading();
      }
    });
  }

  //Standard dynamic menu

  setUpMenu() {
    let raw: any = JSON.parse(localStorage.getItem('menus'));
    if (raw == undefined || raw == null) {
      this.loginService.logout();
      return;
    }
    this.mainMenus = MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.TESTDRIVES);
    for (let i = 0; i < this.mainMenus.length; i++) {
      if (i == 0) this.mainMenus[i].setActive(true);
      this.mainMenus[i].sub_menus[0]?.setActive(true);
    }
    for (let mainMenu of this.mainMenus) {
      mainMenu.active$.subscribe((active) => {
        if (active) {
          for (let otherMainMenu of this.mainMenus) {
            if (otherMainMenu.main_menu_id != mainMenu.main_menu_id) {
              otherMainMenu.setActive(false);
            }
          }
          if (mainMenu.sub_menus.length > 0) {
            for (let otherSubMenu of mainMenu.sub_menus) {
              if (otherSubMenu.getActive()) {
                this._activeSubMenu.next(otherSubMenu);
              }
            }
          }
        }
      });
      for (let subMenu of mainMenu.sub_menus) {
        subMenu.active$.subscribe((active) => {
          if (active) {
            for (let otherSubMenu of mainMenu.sub_menus) {
              if (otherSubMenu != subMenu) {
                otherSubMenu.setActive(false);
              }
            }
            this._activeSubMenu.next(subMenu);
          }
        });
      }
    }
    this._activeSubMenu.next(this.mainMenus[0]?.sub_menus[0]);
  }
  rootFilter() {
    let activeSubMenu = this.mainMenus
      .filter((m) => m.getActive())[0]
      .sub_menus.filter((s) => s.getActive())[0];
    if (activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
      let sumFilter: any[] = activeSubMenu.filter;
      if (this.activeFilter.length > 0) {
        sumFilter = sumFilter
          .concat([new SubMenuOperator('&&')])
          .concat(this.activeFilter);
      }
      this._objectsFiltered.next(
        this.objects.filter((obj) => this.solveFilter(sumFilter, obj))
      );
    } else if (this.activeFilter.length > 0) {
      this._objectsFiltered.next(
        this.objects.filter((obj) => this.solveFilter(this.activeFilter, obj))
      );
    } else {
      this._objectsFiltered.next(this.objects.filter((obj) => true));
    }
    this.applySearch(this._objectsFiltered.getValue());
    this.rootSorting();
    this.setPages();
  }
  solveFilter(originalFilters: any[], object: any): boolean {
    let filters = JSON.parse(JSON.stringify(originalFilters));
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].data_key) {
        filters[i] = this.objectIsValidForFilter(filters[i], object);
      } else if (Array.isArray(filters[i])) {
        filters[i] = this.solveFilter(filters[i], object);
      }
    }
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].operator && filters[i].operator == '&&') {
        filters[i + 1] = filters[i - 1] && filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].operator && filters[i].operator == '||') {
        filters[i + 1] = filters[i - 1] || filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    return filters[0];
  }
  objectIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
    let value: any = this.getObjectValueFromDataKey(filter.data_key, object);
    if (filter.data_typ == 'NUMBER' || filter.data_typ == 'DATE') {
      return this.compareNumber(value, filter);
    } else if (
      filter.data_typ == 'BOOLEAN' ||
      filter.data_typ == 'BOOLEAN_NULL'
    ) {
      return this.compareBoolean(value, filter);
    } else if (filter.data_typ == 'ENUM' || filter.data_typ == 'ENUM_TAG') {
      return this.compareEnum(value, filter);
    } else {
      return this.compareString(value, filter);
    }
  }
  compareNumber(value: any, filter: SubMenuFilter): boolean {
    if (filter.raw_value == 'NULL') {
      if (filter.operator == '<>') {
        return value != undefined && value != null;
      } else if (filter.operator == '=') {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = Number(value);
    let rawValue;
    //Special date condition
    if (filter.raw_value && String(filter.raw_value).includes('NOW-')) {
      let miliseconds = Number(filter.raw_value.split('-')[1]);
      if (!isNaN(miliseconds)) {
        rawValue = new Date().getTime() - miliseconds;
      }
    }
    if (rawValue == undefined || rawValue == null)
      rawValue = Number(filter.raw_value);
    if (Number.isNaN(numberValue)) {
      return false;
    }
    if (filter.operator == '<>') {
      return numberValue != rawValue;
    } else if (filter.operator == '=') {
      return numberValue == rawValue;
    } else if (filter.operator == '>') {
      return numberValue > rawValue;
    } else if (filter.operator == '>=') {
      return numberValue >= rawValue;
    } else if (filter.operator == '<') {
      return numberValue < rawValue;
    } else if (filter.operator == '<=') {
      return numberValue <= rawValue;
    } else {
      return false;
    }
  }
  compareBoolean(value: any, filter: SubMenuFilter): boolean {
    if (filter.raw_value == 'NULL') {
      if (filter.operator == '<>') {
        return value != undefined && value != null;
      } else if (filter.operator == '=') {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue =
      value == null
        ? null
        : value == 'true' || value == '1' || value == true || value == 1;
    let rawValue =
      filter.raw_value == null
        ? null
        : filter.raw_value == 'true' || filter.raw_value == '1';
    if (filter.operator == '<>') {
      return numberValue != rawValue;
    } else if (filter.operator == '=') {
      return numberValue == rawValue;
    } else {
      return false;
    }
  }
  compareEnum(value: any, filter: SubMenuFilter): boolean {
    if (filter.raw_value == 'NULL') {
      if (filter.operator == '<>') {
        return value != undefined && value != null;
      } else if (filter.operator == '=') {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let id: any = null;
    if (typeof value === 'object') {
      id = value == null ? null : value.id;
    } else {
      id = value;
    }
    let rawValue = filter.raw_value;
    if (filter.operator == '<>') {
      return id != rawValue;
    } else if (filter.operator == '=') {
      return id == rawValue;
    } else {
      return false;
    }
  }
  compareString(value: any, filter: SubMenuFilter): boolean {
    if (filter.operator == '<>') {
      if (filter.raw_value == 'NULL') {
        return value != undefined && value != null;
      } else {
        return String(value) != filter.raw_value;
      }
    } else if (filter.operator == '=') {
      if (filter.raw_value == 'NULL') {
        return value == undefined || value == null;
      } else {
        return String(value)
          .toLowerCase()
          .includes(filter.raw_value.toLowerCase());
      }
    } else {
      console.log('Warning: Wrong operator in filter');
      return false;
    }
  }
  getObjectValueFromDataKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (value == undefined || value == null) {
        break;
      }
    }
    return value;
  }
  setUpSearch() {
    setTimeout(() => {
      const searchInput = document.getElementById(
        'ab-search-list-input'
      ) as HTMLInputElement;
      if (searchInput != null) {
        if (this.searchValue != null) {
          searchInput.value = this.searchValue;
          this.rootFilter();
        }
        searchInput.addEventListener('input', (event: InputEvent) => {
          if (this.searchTimeout) clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchValue = searchInput.value;
            this.rootFilter();
          }, 500);
        });
      }
      const searchInputReset = document.getElementById(
        'ab-search-list-input-reset'
      ) as HTMLInputElement;
      if (searchInputReset) {
        searchInputReset.addEventListener('click', (event: InputEvent) => {
          if (this.searchValue != null) {
            const searchInput = document.getElementById(
              'ab-search-list-input'
            ) as HTMLInputElement;
            if (searchInput) {
              searchInput.value = null;
              this.searchValue = null;
              this.rootFilter();
            }
          }
        });
      }
    }, 200);
  }
  applySearch(objects: any[]) {
    if (this.searchValue && this.searchValue.length > 0) {
      let temp = [];
      temp = objects.filter((obj) => {
        return SearchHelper.isMatch(obj.subject, this.searchValue) ||
               SearchHelper.isMatch(obj.customer?.getName(), this.searchValue) ||
               SearchHelper.isMatch(obj.employee?.getFullName(), this.searchValue) ||
               SearchHelper.isMatch(obj.plate_name, this.searchValue) ||
               SearchHelper.isMatch(obj.vehicle?.externalId, this.searchValue);
      });
      this._objectsFiltered.next(temp);
    }
  }
  rootSorting() {
    let activeSub = this._activeSubMenu.getValue();
    if (this.activeSortingColumn == null && activeSub != null) {
      for (let c of this._activeSubMenu.getValue().columns) {
        if (c.sub_menu_column_id == activeSub.default_sort_column_id) {
          this.activeSortingColumn = c;
          this.activeSortingColumn.sortingActive = true;
          this.activeSortingColumn.ascend =
            activeSub.default_sort_direction == 'ASC' ? true : false;
          break;
        }
      }
      if (this.activeSortingColumn == null) {
        let index = this.activeSubMenu.columns.findIndex(
          (c) => c.data_key == 'externalId'
        );
        if (index > -1)
          this.activeSortingColumn = this.activeSubMenu.columns[index];
        else {
          this.activeSortingColumn = new SubMenuColumn(
            0,
            'id',
            0,
            'document',
            0,
            'id',
            false,
            false,
            1,
            null,
            null,
            null,
            null,
            'NUMBER',
            null
          );
        }
        this.activeSortingColumn.sortingActive = true;
        this.activeSortingColumn.ascend = false;
      }
    }
    if (
      this.activeSortingColumn != null &&
      this.activeSortingColumn.sortingActive
    ) {
      this._objectsFiltered.getValue().sort((a, b) => {
        if (this.activeSortingColumn.sort_function) {
          if(this.activeSortingColumn.ascend) {
            return a[this.activeSortingColumn.sort_function](b) * -1;
          } else {
            return a[this.activeSortingColumn.sort_function](b);
          }
        }
        let value1;
        let value2;
        if (typeof a[this.activeSortingColumn.data_key] == 'function') {
          value1 = a[this.activeSortingColumn.data_key]();
          value2 = b[this.activeSortingColumn.data_key]();
        } else if (this.activeSortingColumn.data_key.includes('.')) {
          let deepObjKey = this.activeSortingColumn.data_key.split('.')[0];
          let deepFunctionORPara =
            this.activeSortingColumn.data_key.split('.')[1];
          let deepValueA = a[deepObjKey];
          let deepValueB = b[deepObjKey];
          if (
            deepValueA &&
            typeof deepValueA[deepFunctionORPara] == 'function'
          ) {
            value1 = deepValueA[deepFunctionORPara]();
            value2 = deepValueB[deepFunctionORPara]();
          } else {
            value1 = deepValueA[deepFunctionORPara];
            value2 = deepValueB[deepFunctionORPara];
          }
        } else {
          value1 = this.getValueFromObjKey(
            this.activeSortingColumn.data_key,
            a
          );
          value2 = this.getValueFromObjKey(
            this.activeSortingColumn.data_key,
            b
          );
        }
        if (value1 == null) return this.activeSortingColumn.ascend ? -1 : 1;
        if (value2 == null) return this.activeSortingColumn.ascend ? 1 : -1;
        if (this.activeSortingColumn.data_typ == 'STRING') {
          if (
            value1 != null &&
            value2 != null &&
            isNaN(value1) &&
            isNaN(value2)
          ) {
            return this.activeSortingColumn.ascend
              ? value1?.localeCompare(value2 ? value2 : '')
              : value2?.localeCompare(value1 ? value1 : '');
          }
        }
        return this.activeSortingColumn.ascend
          ? value1 - value2
          : value2 - value1;
      });
    }
  }
  getValueFromObjKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
      if (value == undefined || value == null) {
        break;
      }
    }
    return value;
  }
  applySorting(column: SubMenuColumn) {
    //for reference
    if (
      this.activeSortingColumn.sub_menu_column_id != column.sub_menu_column_id
    ) {
      this.activeSortingColumn.sortingActive = false;
      this.activeSortingColumn.ascend = false;
    }
    this.activeSortingColumn = column;
    this.activeSortingColumn.setSortActive();
    this.rootSorting();
  }
  setPages() {
    this.pageHandler = new PageHandler(this._objectsFiltered.getValue().length);
  }
  pageHandling(next: boolean) {
    this.pageHandler.action(next);
  }

  //Component specifix

  mergeData() {
    for (let drive of this.objects) {
      for (let customer of this.customers) {
        if (drive.customer_id == customer.id) {
          drive.customer = customer;
          break;
        }
      }
      for (let vehicle of this.vehicles) {
        if (vehicle.id == drive.vehicle_id) {
          drive.vehicle = vehicle;
          drive.subject = vehicle.brand.makeName + ' ' + vehicle.model.name;
          break;
        }
      }
      for (let employee of this.employees) {
        if (employee.id == drive.employee_id) {
          drive.employee = employee;
          break;
        }
      }
      drive.plate_name = this.getEnumValue('license_plate_id', drive.license_plate_id);
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_drive') this.deleteObject(value.submit_value);
      }
    });
  }

  selectionEdit(obj) {
    this.selectedObject = obj;
    this.showView = this.view.CREATE;
  }

  selectionCreate(typ: string) {
    this.createTyp = typ;
    this.showView = this.view.CREATE;
  }

  selectionDetails(obj: any) {
    this.dynamicListLastPosition = DynamicListHelper.getScrollPosition();
    this.selectedObject = obj;
    this.showView = this.view.DETAILS;
  }

  selectionDelete(obj: any){
    this.selectedObject = obj;
    this.openDialogDelete();
  }

  openDialogDelete() {
    this.dialogService.openQuery(
      {
        title: 'Fahrt löschen',
        message: 'Sind sie sicher, dass Sie diese Fahrt unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_drive',
        submit_value: this.selectedObject,
      }
    );
  }

  createSuccess(obj: Drive) {
    this.updateObjectLocal(obj, 'create');
    this.dialogService.showNotification({
      title: 'Erfolgreich',
      message: 'Fahrt wurde erstellt.',
      success: true
    });
    // if(obj.customer_id == null){
    //   this.getDrivePdf(obj);
    // } else {
    //   this.selectedObject = obj;
    //   this.showDialogCreatePDF = true;
    // }
  }

  cancelPdfCreate() {
    this.selectedObject = null;
    this.showDialogCreatePDF = false;
  }

  createPdfCreate(data: any) {
    let isSign = data.sign;
    let form: FormGroup = data.form;
    this.selectedObject.authorization_number = form.get('authorization_number').value;
    this.selectedObject.authorization_authority = form.get('authorization_authority').value;
    this.selectedObject.authorization_date = DateHelper.convertDateStringToTimestamp(form.get('authorization_date').value);
    this.selectedObject.authorization_groups = form.get('authorization_groups').value;
    this.selectedObject.trip_description = form.get('trip_description').value;
    this.driveService.updateDrive(this.selectedObject).subscribe((result) => {
      if(result){
        result.customer = this.selectedObject.customer
        result.authorization_number = this.selectedObject.authorization_number
        result.authorization_authority = this.selectedObject.authorization_authority
        result.authorization_date = this.selectedObject.authorization_date
        result.authorization_groups = this.selectedObject.authorization_groups
        result.trip_description = this.selectedObject.trip_description
        this.selectedObject = result;
        if(isSign){
          this.signPDF(this.selectedObject);
        } else {
          this.getDrivePdf(this.selectedObject);
        }
      }
    });
  }

  signPDF(drive: Drive){
    this.documentService.createSignatureDocument(drive).subscribe((result) => {
      if(result){
        this.dialogService.openLoaderSignature({document_typ: DokumentTyp.Probefahrt});
        this.showDialogCreatePDF = false;
      }
    })
  }

  getDrivePdf(drive: Drive) {
    this.driveService.getDrivePDF(drive.test_drive_id).subscribe((result) => {
      if(result){
        this.showDialogCreatePDF = false;
        if(drive.status_signiert == 0 || drive.customer_id == null) {
          //this.addAttachement(drive, result);
        }
      }
    });
  }

  addAttachement(drive: Drive, result: any) {
    let attachment = new Attachment(
      0,
      3,
      drive.test_drive_id,
      'pdf',
      'Zusatzblatt_zum_Probefahrtschein',
      result
    );
    this.attachmentService.createAttachment(attachment).subscribe((successDrive) => {
      if(successDrive){
        drive.attachments.push(attachment);
        if(drive.customer_id != null){
          attachment.typ = 2;
          attachment.typ_id = drive.customer_id;
          this.attachmentService.createAttachment(attachment).subscribe((successKunde) => {
            if(successKunde){
              this.updateObjectLocal(drive, this.selectedObject ? 'update' : 'create');
              this.showDialogCreatePDF = false;
            }
          })
        } else {

          this.updateObjectLocal(drive, this.selectedObject ? 'update' : 'create');
          this.showDialogCreatePDF = false;
        }
      }
    })
  }

  updateSuccess(ledgerEntry: Drive) {
    this.updateObjectLocal(ledgerEntry, 'update');
  }

  deleteObject(object: any) {
    this.driveService.deleteDrive(object.test_drive_id).subscribe((result) => {
      if(result){
        this.updateObjectLocal(object, 'delete');
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Fahrt wurde gelöscht.',
          success: true
        });
      }
    })
  }

  selectionCloseDetails() {
    this.selectedObject = null;
    this.showView = this.view.LIST;
    DynamicListHelper.scrollToPosition(this.dynamicListLastPosition);
  }

  openCreatePdf(obj: Drive) {
    if(obj.customer_id == null){
      this.getDrivePdf(obj);
    } else {
      this.selectedObject = obj;
      this.showDialogCreatePDF = true;
    }
  }

  updateObjectLocal(obj: any, updateTyp: string) {
    if(updateTyp == 'create'){
      this.objects.push(obj);
      this.showView = TestDriveView.LIST;
      this.selectedObject = null;
    } else if(updateTyp == 'update'){
      let index = this.objects.findIndex((v) => v.test_drive_id == obj.test_drive_id);
      if(index > -1){
        this.objects[index] = obj;
      }
      this.showView = TestDriveView.LIST;
      this.selectedObject = null;
    } else if(updateTyp == 'delete'){
      let index = this.objects.findIndex((v) => v.test_drive_id == obj.test_drive_id);
      if(index > -1){
        this.objects.splice(index,1);
      }
      this.showView = TestDriveView.LIST;
      this.selectedObject = null;
    }
    this.rootFilter();
  }
}
