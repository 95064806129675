<div *ngIf="showView == view.LIST">
  <div class="header-base-style">
    <div class="header-inner-wrapper">
      <button class="button_type_1" *ngIf="authService.accessVia('user_edit')"
      (click)="showView = view.CREATE"
      appDropdown
      [show]="showDropdownMainButton"
      (changedValueEmitter)="showDropdownMainButton = $event">
        Neu
      </button>
      <div id="main-menu-wrapper">
        <button *ngFor="let mainMenu of mainMenus"
          [class.main-menu-btn-selected]="mainMenu.getActive()"
          (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
          {{mainMenu.name}}
        </button>
      </div>
    </div>
    <div>
    </div>
  </div>
  <app-list-container *ngIf="_activeSubMenu.getValue()"
      [objectTyp]="'employee'"
      [objects]="_objectsFiltered.getValue()"
      [pageHandler]="pageHandler"
      [filter]="filter"
      [mainMenus]="mainMenus"
      [activeSubMenu$]="activeSubMenu$"
      [activeSortingColumn]="activeSortingColumn"
      (pageEmitter)="pageHandling($event)"
      (applySortingEmitter)="applySorting($event)"
      (openDetailsEmitter)="selectionDetails($event)"
      (openEditEmitter)="selectionEdit($event)"
      (deleteEmitter)="selectionDelete($event)"
  ></app-list-container>
</div>

<app-user-details *ngIf="showView == view.DETAILS"
  [obj]="selectedObject"
  (selectionCloseEmitter)="selectionCloseDetails()"
  (selectionEditEmitter)="selectionEdit($event)"
></app-user-details>

<app-user-create *ngIf="showView == view.CREATE"
  [obj]="selectedObject"
  [locations]="locations"
  (selectionCancelEmitter)="selectionCancelCreate()"
  (createSuccessEmitter)="createSuccess($event)"
  (updateSuccessEmitter)="updateSuccess($event)"
></app-user-create>
