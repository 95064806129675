<div id="start-container">
    <div class="choise_container" [class.choise_container_disabled]="false">
        <div class="lbl_regular_thick">Automatisch</div>
        <form [formGroup]="form">
            <div class="input_wrapper">
              <label class="overview_record_lbl">VIN *</label>
              <input #vin_input type="text" placeholder="VIN" (input)="submitted = false" [class.input_invalid]="submitted && vin_input.value.length < 8">
            </div>
            <div class="info">Durch Ausfüllen dieses Feldes wird Ihr Fahrzeug automatisch angelegt.</div>
        </form>
        <button [disabled]="false" class="btn_submit_dark" (click)="vinRequest(vin_input.value)">Erstellen</button>
    </div>
    <div id="text">Oder</div>
    <div class="choise_container">
        <div class="lbl_regular_thick">Manuell</div>
        <div class="info manualinfo">Wenn Sie die den Eurotax Code/die VIN Nummer Ihres Fahrzeugs nicht kennen, können Sie hier die Daten manuell eingeben.</div>
        <div class="btn_submit_dark" (click)="createManuell()">Manuell anlegen</div>
    </div>
</div>
