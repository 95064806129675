import { GlobalVariables } from "./GlobalVars";

export class DragAndDrop {
  static registerHandler(className: string) {
    let items = document.querySelectorAll(className);
    if(items){
      items.forEach(item => {
        item.addEventListener('dragstart', this.handleDragStart, false);
        item.addEventListener('dragover', this.handleDragOver, false);
        item.addEventListener('dragenter', this.handleDragEnter, false);
        item.addEventListener('dragleave', this.handleDragLeave, false);
        item.addEventListener('dragend', this.handleDragEnd, false);
        item.addEventListener('drop', this.handleDrop, false);
      });
    }
  }

  static handleDragStart(e: DragEvent) {
    GlobalVariables.drag_active = true;
    GlobalVariables.drag_done = false;
    let item = e.target as HTMLElement;
    let img = item.firstChild as HTMLElement;
    let itemIndex = img.id;
    GlobalVariables.drag_item_drag = itemIndex;
    GlobalVariables.drag_item_drop = undefined;
    e.dataTransfer.effectAllowed = 'move';
    item.style.backgroundColor = 'blue';
  }
  static handleDragOver(e: DragEvent) {
    if(e.preventDefault) e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    return false;
  }
  static handleDragEnter(e: DragEvent) {
    let item = e.target as HTMLElement;
    item.classList.add('drag_over_item')
  }
  static handleDragLeave(e: DragEvent) {
    let item = e.target as HTMLElement;
    item.classList.remove('drag_over_item')
  }
  static handleDrop(e: DragEvent) {
    if(e.stopPropagation) e.stopPropagation();
    let item = e.target as HTMLElement;
    let dropItemIndex = item.id;
    item.style.backgroundColor = 'lightblue';
    if(dropItemIndex && (GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drag !== dropItemIndex)){
      GlobalVariables.drag_item_drop = dropItemIndex;
    }
    return false;
  }
  static handleDragEnd(e: DragEvent) {
    let items = document.querySelectorAll('.drag_item_container .drag_item');
    if(items){
      items.forEach(item => {
        item.classList.remove('drag_over_item')
      });
    }
  }

  static changeDropItem(items: any[]): any[] {
    if(GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop && items){
      let temp = items[GlobalVariables.drag_item_drag];
      items[GlobalVariables.drag_item_drag] = items[GlobalVariables.drag_item_drop];
      items[GlobalVariables.drag_item_drop] = temp;
    }
    return items;
  }

  static getDragItem(items: any[]): any {
    if(GlobalVariables.drag_item_drag){
      let item = items[GlobalVariables.drag_item_drag];
      if(item){
        return item;
      }
    }
    return null;
  }
  static getDropItem(items: any[]): any {
    if(GlobalVariables.drag_item_drop){
      let item = items[GlobalVariables.drag_item_drop];
      if(item){
        return item;
      }
    }
    return null;
  }
}
