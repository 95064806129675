import { MasterMenu } from "./EnumMasterMenu";
import { MainMenu } from "./MainMenu";
import { SubMenu } from "./SubMenu";
import { SubMenuColumn } from "./SubMenuColumn";
import { SubMenuColumnTag, SubMenuColumnTagPossibleRange, SubMenuColumnTagPossibleValues } from "./SubMenuColumnTag";
import { SubMenuFilter, SubMenuOperator } from "./SubMenuFilter";

export class MainMenuFactory {
  static jsonFactoryMainMenus(raws: any, masterMenu?: MasterMenu): MainMenu[] {
      const objects: MainMenu[] = [];
      for(let raw of raws){
        if(masterMenu != null && raw?.sub_menus[0]?.columns[0]?.data_object == masterMenu){
          objects.push(this.jsonFactoryMainMenu(raw));
        }
      }
      objects.sort((a,b) => {return a.sort_value - b.sort_value});
      if(objects.length > 0) objects[0].setActive(true);
      return objects;
  }

  static jsonFactoryMainMenu(raw: any): MainMenu {
      let menu = new MainMenu(
          raw.main_menu_id,
          raw.name,
          raw.sort_value,
          this.jsonFactorySubMenus(raw.sub_menus),
          raw.master_menu
      );
      menu.sub_menus.sort((a,b) => {return a.sort_value - b.sort_value});
      return menu;
  }

  static jsonFactorySubMenus(raws: any): SubMenu[] {
    const objects: SubMenu[] = [];
    for(let raw of raws){
      objects.push(this.jsonFactorySubMenu(raw));
    }
    return objects;
  }

  static jsonFactorySubMenu(raw: any): SubMenu {
      return new SubMenu(
          raw.sub_menu_id,
          raw.name,
          raw.sort_value,
          this.jsonFactorySubMenuFilters(raw.filter),
          this.jsonFactorySubMenuColumns(raw.columns),
          raw.actions,
          raw.filter_id,
          raw.is_hidden,
          raw.pagination,
          raw.filterable,
          raw.default_sort_column_id,
          raw.default_sort_direction,
          raw.disabled_elements,
      );
  }

  static jsonFactorySubMenuFilters(raws: any): any[] {
    const objects: any[] = [];
    for(let raw of raws){
      if(raw.filter_operator) {
        objects.push(new SubMenuOperator(raw.filter_operator));
      } else if(raw.filter) {
        objects.push(this.jsonFactorySubMenuFilters(raw.filter));
      } else {
        objects.push(this.jsonFactorySubMenuFilter(raw));
      }

    }
    return objects;
  }

  static jsonFactorySubMenuFilter(raw: any): SubMenuFilter {
      return new SubMenuFilter(
          raw.data_object,
          raw.calculated,
          raw.data_key,
          raw.operator,
          raw.data_typ,
          raw.raw_value,
      );
  }

  static jsonFactorySubMenuColumnTag(raw: any): SubMenuColumnTag {
    return new SubMenuColumnTag(
        raw.tag_typ,
        raw.tag_data_typ,
        raw.possible_values ? this.jsonFactorySubMenuColumnTagPossibleValues(raw.possible_values) : [],
        raw.possible_ranges ? this.jsonFactorySubMenuColumnTagPossibleRange(raw.possible_ranges) : [],
    );
  }

  static jsonFactorySubMenuColumnTagPossibleValues(raws: any[]): SubMenuColumnTagPossibleValues[] {
    const objects: SubMenuColumnTagPossibleValues[] = [];
    for(let raw of raws){
      objects.push(
        new SubMenuColumnTagPossibleValues(
          raw.value,
          raw.color,
        )
      );
    }
    return objects;
  }

  static jsonFactorySubMenuColumnTagPossibleRange(raws: any[]): SubMenuColumnTagPossibleRange[] {
    const objects: SubMenuColumnTagPossibleRange[] = [];
    for(let raw of raws){
      objects.push(
        new SubMenuColumnTagPossibleRange(
          raw.smaller_than,
          raw.bigger_than,
          raw.color,
        )
      );
    }
    return objects;
  }

  static jsonFactorySubMenuColumns(raws: any): SubMenuColumn[] {
    const objects: SubMenuColumn[] = [];
    for(let raw of raws){
      objects.push(this.jsonFactorySubMenuColumn(raw));
    }
    return objects;
  }

  static jsonFactorySubMenuColumn(raw: any): SubMenuColumn {
      return new SubMenuColumn(
          raw.sub_menu_column_id,
          raw.title,
          raw.sort_value,
          raw.data_object,
          raw.calculated,
          raw.data_key,
          raw.is_sortable,
          raw.is_fixed,
          raw.width,
          raw.sum_typ,
          raw.prefix,
          raw.suffix,
          raw.format,
          raw.data_typ,
          raw.column_tag ? this.jsonFactorySubMenuColumnTag(raw.column_tag) : null,
          raw.sort_function ? raw.sort_function : null,
      );
  }
}
