<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false" [class.data_box_disabled]="editMode && inseratDisable">
  <h3 collapsible-header class="overview_data_box_lbl_title">Antrieb</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record">
          <div class="overview_record_lbl">Treibstoff</div>
          <div class="overview_record_value">{{vehicle.fuel.name}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Antriebsart</div>
          <div class="overview_record_value">{{vehicle.drive.name}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Gänge</div>
          <div class="overview_record_value">{{vehicle.numOfGears ? vehicle.numOfGears : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Getriebeart</div>
          <div class="overview_record_value">{{vehicle.transmission.name}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Leistung PS/KW</div>
          <div class="overview_record_value">{{getInputValueNumberNoDicis(vehicle.powerPs)}} / {{getInputValueNumberNoDicis(vehicle.powerKw)}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Hubraum</div>
          <div class="overview_record_value">{{vehicle.ccm ? vehicle.ccm + ' ccm' : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Zylinder</div>
          <div class="overview_record_value">{{vehicle.numOfCylinders  ? vehicle.numOfCylinders : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Verbrauch kombiniert (l/100km)</div>
          <div class="overview_record_value">{{vehicle.consumption.combined ? vehicle.consumption.combined : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">{{getUrbanText()}}</div>
          <div class="overview_record_value">{{vehicle.consumption.urban ? vehicle.consumption.urban : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">{{getLandText()}}</div>
          <div class="overview_record_value">{{vehicle.consumption.extraUrban ? vehicle.consumption.extraUrban : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">CO&#8322; Emissionen g/km</div>
          <div class="overview_record_value">{{vehicle.co2Emission ? (vehicle.co2Emission + ' g') : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">CO&#8322; Emissionen geprüft</div>
          <div class="overview_record_value">{{vehicle.co2_checked ? 'Ja' : 'Nein'}}</div>
      </div>
      <div class="overview_record" *ngIf="!authService.isHoedl()">
        <div class="overview_record_lbl">NoVA-Satz</div>
        <div class="overview_record_value">{{vehicle.priceSheet && vehicle.priceSheet.nova_satz ? (getInputValueNumberNoDicis(vehicle.priceSheet?.nova_satz) + '%') : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">NoVA-Satz</div>
        <div class="overview_record_value">{{this.formGroupPriceSheet?.controls['nova_satz']?.value ? (getInputValueNumberNoDicis(this.formGroupPriceSheet?.controls['nova_satz']?.value) + '%') : '---'}}</div>
      </div>
      <!-- <div class="overview_record" *ngIf="authService.isHoedl()">
        <div class="overview_record_lbl">NoVa</div>
        <div class="overview_record_value">{{novaValue ? (getInputValueNumberNoDicis(novaValue) + '€') : '---'}}</div>
      </div> -->
      <div class="overview_record" *ngIf="authService.isHoedl()">
          <div class="overview_record_lbl">Schadstoffklasse</div>
          <div class="overview_record_value">{{vehicle.enviromentLbl ? vehicle.enviromentLbl?.name : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Energie Effizienzklasse</div>
        <div class="overview_record_value">{{vehicle.envkv_class ? vehicle.envkv_class : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Anhängelast gebremst</div>
        <div class="overview_record_value">{{getInputValueKG(vehicle.trailer_load_braked)}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="formGroupVehicle" class="overview_data_form">
      <app-dropdown-objects
        [title]="'Treibstoff'"
        [placeholder]="'Treibstoff'"
        [headervalue]="f.fuel.value?.name"
        [formcontrol]="f.fuel"
        [values]="fuelsValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
      ></app-dropdown-objects>
      <app-dropdown-objects
        [title]="'Antriebsart'"
        [placeholder]="'Antriebsart'"
        [headervalue]="f.drive.value?.name"
        [formcontrol]="f.drive"
        [values]="drivesValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('drive', $event)"
      ></app-dropdown-objects>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Gänge</div>
        <input type="number" class="overview_input" placeholder="Gänge" formControlName="gears" (ngModel)="f.gears.value" [ngClass]="submitted && f.gears.errors ? 'inputfield_invalid_overview' : ''">
      </div>
      <app-dropdown-objects
        [title]="'Getriebeart'"
        [placeholder]="'Getriebeart'"
        [headervalue]="f.transmission.value?.name"
        [formcontrol]="f.transmission"
        [values]="transmissionsValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
      ></app-dropdown-objects>
      <div *ngIf="f.powerTyp.value == 1" class="input_wrapper">
        <div class="overview_record_lbl">Leistung (PS)</div>
        <input type="number" class="overview_input" placeholder="Leistung" formControlName="power" (ngModel)="f.power.value" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid_overview' : ''">
      </div>
        <div *ngIf="f.powerTyp.value == 2" class="input_wrapper">
            <div class="overview_record_lbl">Leistung (KW)</div>
            <input type="number" class="overview_input" placeholder="Leistung" formControlName="power" (ngModel)="f.power.value" [ngClass]="submitted && f.power.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
            <div class="overview_record_lbl">Hubraum (ccm)</div>
            <input type="number" class="overview_input" placeholder="Hubraum (ccm)" formControlName="ccm" (ngModel)="f.ccm.value" [ngClass]="submitted && f.ccm.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
            <div class="overview_record_lbl">Zylinder</div>
            <input type="number" class="overview_input" placeholder="Zylinder" formControlName="cylinder" (ngModel)="f.cylinder.value" [ngClass]="submitted && f.cylinder.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
            <div class="overview_record_lbl">Verbrauch kombiniert (l/100km)</div>
            <input type="number" class="overview_input" placeholder="Verbrauch kombiniert (l/100km)" formControlName="consumption" (ngModel)="f.consumption.value" [ngClass]="submitted && f.consumption.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
            <div class="overview_record_lbl">{{getUrbanText()}}</div>
            <input type="number" class="overview_input" [placeholder]="getUrbanText()" formControlName="consumptionUrban" (ngModel)="f.consumptionUrban.value" [ngClass]="submitted && f.consumptionUrban.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
            <div class="overview_record_lbl">{{getLandText()}}</div>
            <input type="number" class="overview_input" [placeholder]="getLandText()" formControlName="consumptionExtraUrban" (ngModel)="f.consumptionExtraUrban.value" [ngClass]="submitted && f.consumptionExtraUrban.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper" *ngIf="!authService.isSinhuber()">
            <div class="overview_record_lbl">CO&#8322; Emissionen g/km</div>
            <input type="number" class="overview_input" placeholder="CO&#8322; Emissionen g/km" formControlName="co2" (ngModel)="f.co2.value" [ngClass]="submitted && f.co2.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <!-- <app-dropdown-simple
          [title]="'CO&#8322; Emissionen geprüft'"
          [formcontrol]="f.co2_checked"
          (submitValueEmitter)="f.co2_checked.setValue($event)"
        ></app-dropdown-simple> -->
        <!-- <div class="input_wrapper">
          <div class="overview_record_lbl">NoVa in %</div>
          <input type="number" class="overview_input" placeholder="NoVa in %" formControlName="nova" (ngModel)="f.nova.value" [ngClass]="submitted && f.nova.errors ? 'inputfield_invalid_overview' : ''">
        </div> -->
        <app-dropdown-objects
          [title]="'Schadstoffklasse'"
          [placeholder]="'Schadstoffklasse'"
          [headervalue]="f.enviromentLbl.value?.name"
          [formcontrol]="f.enviromentLbl"
          [values]="environmentLabelValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('environmentLabels', $event)"
        ></app-dropdown-objects>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <div class="overview_record_lbl">Energie Effizienzklasse</div>
          <input type="text" class="overview_input" placeholder="Energie Effizienzklasse" formControlName="envkv_class" (ngModel)="f.envkv_class.value" [ngClass]="submitted && f.envkv_class.errors ? 'inputfield_invalid_overview' : ''">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Anhängelast gebremst</label>
          <input type="number" min="0" class="overview_input" placeholder="kg" formControlName="trailer_load_braked">
        </div>
      </form>
  </div>
</app-collapsible>

