import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { OfferItem } from 'src/app/buisness-object/document/Offer';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

declare function CheckNumeric(): any;

@Component({
  selector: 'app-create-offer-page-two-item-element',
  templateUrl: './create-offer-page-two-item-element.component.html',
  styleUrls: ['./create-offer-page-two-item-element.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class CreateOfferPageTwoItemElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Input() index: number;
  @Input() amount: number;
  @Input() item: OfferItem;
  @Input() form: FormGroup;
  @Input() vehicles: Vehicle[];

  public vehiclesFiltered: Vehicle[] = [];
  public showDropdownOne = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  public showCars = false;

  @Output() removeEmitter = new EventEmitter<number>();
  @Output() submitEmitter = new EventEmitter<boolean>();

  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.vehiclesFiltered = this.vehicles;
    if(this.isEdit){
      for(let v of this.vehicles){
        if(v.getDescription().includes(this.form.controls.offer_descriptions.value[0].controls.title.value)){
          this.form.controls['vehicle_selected'].setValue(true);
          this.form.controls['vehicle'].setValue(v);
          // setTimeout(() => {
          //   this.input_vehicle.nativeElement.value = v.getDescription(50);
          // }, 200);
          break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click', (e:Event) => {
      if(this.form.controls.vehicle_selected.value && this.showDropdownOne && !this.dropdownOne?.nativeElement.contains(e.target)) this.showDropdownOne = false;
    });
    if(this.isEdit && this.form.controls['vehicle'].value){
      this.input_vehicle.nativeElement.value = this.form.controls['vehicle'].value.getDescription(50);
    }
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  get f() {
    return this.form.controls;
  }
  get descriptionFrom() {
    return this.form.controls.offer_descriptions.value[0].controls;
  }

  showCar() {
    this.form.controls.vehicle_selected.setValue(!this.form.controls.vehicle_selected.value);
    if(!this.isEdit){
      this.form.controls.offer_descriptions.value[0].controls.title.setValue(null);
      if(this.input_vehicle) this.input_vehicle.nativeElement.value = '';
      if(!this.form.controls.vehicle_selected.value){
        this.form.controls.price.setValue(null);
        this.form.controls.base_value.setValue(null);
      }
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.form.controls.vehicle.setValue(vehicle);
    if(vehicle){
      this.input_vehicle.nativeElement.value = vehicle.getDescription(50);
      this.form.controls.offer_descriptions.value[0].controls.title.setValue(vehicle.getDescription());
    } else {
      this.form.controls.offer_descriptions.value[0].controls.title.setValue(null);
    }
    if(vehicle && vehicle.price){
      this.form.controls.price.setValue(vehicle.price);
      this.form.controls.base_value.setValue(1);
    }
  }

  filterVehicles(value: string) {
    if(value && value.length > 0){
      this.setVehicle(null);
      this.vehiclesFiltered = [];
      this.vehiclesFiltered = this.vehicles.filter((vehicle) => {
        return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
        vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
        vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
        vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
        //this.authService.isSinhuber() ? vehicle.vehicle_serialnumber?.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase()) ||
        (this.authService.ACCESS_ALL() && vehicle.externalId ? vehicle.externalId.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase())) ||
        (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.vehiclesFiltered = this.vehicles;
    }
  }
}

