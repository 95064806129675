
<div id="price_sheet_page">
  <app-price-sheets-create *ngIf="surface == 1 && form && !authService.isHoedl()"
    [vehicle]="vehicle"
    [form]="form"
    [vehicleForm]="vehicleForm"
    [submitted]="submitted"
    (onPriceSheetChangesEmitter)="onPriceSheetChangesEmitter.emit()"
    (selectionEditSteuersatzAcceptedEmitter)="selectionEditSteuersatzAcceptedVorEmitter.emit()"
    (addReparaturEmitter)="addReparaturVorEmitter.emit()"
    (removeReparaturEmitter)="removeReparaturVorEmitter.emit($event)"
  ></app-price-sheets-create>
  <app-price-sheets-create-vorkalkulation-new *ngIf="surface == 1 && form && authService.isHoedl()"
    [vehicle]="vehicle"
    [form]="form"
    [vehicleForm]="vehicleForm"
    [submitted]="submitted"
    (onPriceSheetChangesEmitter)="onPriceSheetChangesEmitter.emit()"
    (selectionEditSteuersatzAcceptedEmitter)="selectionEditSteuersatzAcceptedVorEmitter.emit()"
    (addReparaturEmitter)="addReparaturVorEmitter.emit()"
    (removeReparaturEmitter)="removeReparaturVorEmitter.emit($event)"
  ></app-price-sheets-create-vorkalkulation-new>
  <app-price-sheets-create-nackalkulation *ngIf="surface == 2 && form"
    [form]="form"
    [submitted]="submitted"
    [priceSheet]="vehicle.priceSheet"
    [customers]="customers"
    [employees]="employees"
    (onPriceSheetChangesEmitter)="onPriceSheetChangesEmitter.emit()"
    (selectionEditSteuersatzAcceptedEmitter)="selectionEditSteuersatzAcceptedNachEmitter.emit()"
    (addReparaturEmitter)="addReparaturNachEmitter.emit()"
    (removeReparaturEmitter)="removeReparaturNachEmitter.emit($event)"
  ></app-price-sheets-create-nackalkulation>
  <app-price-sheets-details *ngIf="vehicle.priceSheet && surface == 3"
    [priceSheet]="vehicle.priceSheet"
    [vehicle]="vehicle"
    [form]="form"
    [customers]="customers"
  ></app-price-sheets-details>
</div>
