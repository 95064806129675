import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import * as _moment from 'moment';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { VehicleEnviromentLabel } from 'src/app/buisness-object/vehicle/optional/VehicleEnviromentLabel';
import { DropdownHighlighter } from 'src/app/buisness-object/dropdown/DropdownHighlighter';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
const moment = _moment;

@Component({
  selector: 'app-basic-data-container-zwei',
  templateUrl: './basic-data-container-zwei.component.html',
  styleUrls: ['./basic-data-container-zwei.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})

//https://stackblitz.com/angular/ekjvrbglvnb?file=src%2Findex.html


export class BasicDataContainerZweiComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentPageCreate: number;
  @Input() formGroup: FormGroup;
  @Input() submitted: boolean;

  public showDropdownOne = false;
  public showDropdownTwo = false;
  public maxDate = new Date();
  public isInvalidDate = false;
  public inValidPrice = false;
  public environmentLabelValues = [];
  public environmentLabels: VehicleEnviromentLabel[] = [];

  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public listener: () => void;

  @Output() changePageEmitter = new EventEmitter<number>();

  constructor(
    private vService: VehicleService,
    private renderer: Renderer2,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.getDataServerside()
    if(this.authService.isHoedl()){
      this.formGroup.controls["sprit"].clearValidators();
      this.formGroup.controls['sprit'].updateValueAndValidity();
      this.formGroup.controls['sprit'].setValidators(Validators.required);
      this.formGroup.controls["lieferant_extern"].clearValidators();
      this.formGroup.controls['lieferant_extern'].updateValueAndValidity();
      this.formGroup.controls['lieferant_extern'].setValidators(Validators.required);
      this.formGroup.controls["einkaufspreis_pricesheet"].clearValidators();
      this.formGroup.controls['einkaufspreis_pricesheet'].updateValueAndValidity();
      this.formGroup.controls['einkaufspreis_pricesheet'].setValidators(Validators.required);
      this.formGroup.controls["einkaufsdatum"].clearValidators();
      this.formGroup.controls['einkaufsdatum'].updateValueAndValidity();
      this.formGroup.controls['einkaufsdatum'].setValidators(Validators.required);
      this.formGroup.controls["vehicle_supplier"].clearValidators();
      this.formGroup.controls['vehicle_supplier'].updateValueAndValidity();
      this.formGroup.controls['vehicle_supplier'].setValidators(Validators.required);
      this.formGroup.controls["vehicle_imported"].clearValidators();
      this.formGroup.controls['vehicle_imported'].updateValueAndValidity();
      this.formGroup.controls['vehicle_imported'].setValidators(Validators.required);
      this.formGroup.controls["vehicle_imported"].setValue(false);
      this.formGroup.controls["steuersatz_pricesheet"].clearValidators();
      this.formGroup.controls['steuersatz_pricesheet'].updateValueAndValidity();
      this.formGroup.controls['steuersatz_pricesheet'].setValidators(Validators.required);
    } else if(this.authService.isStandard()){
      this.formGroup.controls["steuersatz_pricesheet"].clearValidators();
      this.formGroup.controls['steuersatz_pricesheet'].updateValueAndValidity();
      this.formGroup.controls['steuersatz_pricesheet'].setValidators(Validators.required);
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window', 'click', (e: Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  getDataServerside() {
    this.vService.getEnvironmentLabels().subscribe((ressource) => {
      if(ressource){
        this.environmentLabels = this.sortArray(ressource);
        this.setDropdownValues();
      }
    })
  }

  setDropdownValues() {
    this.environmentLabelValues = [];
    for(let v of this.environmentLabels){
      this.environmentLabelValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  sortArray(data: any[]): any[] {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    for(let i = 0; i < data.length; i++){
      if(data[i].name == 'Sonstige'){
        data.push(data[i]);
        data.splice((i),1);
      }
    }
    return data;
  }


  setValueFromDropdown(value: string, id: number) {
    if(value === 'environmentLabels'){
      let index = this.environmentLabels.findIndex(v => v.id == id);
      if(index > -1) this.f['enviromentLbl'].setValue(this.environmentLabels[index]);
    }
  }

  getDotIcon(page: number): string {
    if(page == (this.currentPageCreate - 1)){
      return '../../../../assets/dot_full.png';
    }
    return '../../../../assets/dot_empty.png';
  }

  nextPage() {
    this.changePageEmitter.emit(this.currentPageCreate += 1);
  }

  fillFormGroup() {
    this.f.brandId.setValue(1);
  }

  get f() {
    return this.formGroup.controls;
  }

  closeDatePicker1(eventData: any, dp?:any) {
    this.f.manufactureDate.setValue(eventData);
    dp.close();
  }
  closeDatePicker2(eventData: any, dp?:any) {
    this.f.firstRegistrationDate.setValue(eventData);
    dp.close();
  }

  setManufacturDate(date: number) {
    this.f.manufactureDate.setValue(date);
  }

  setRegistrationDate(date: number) {
    this.f.firstRegistrationDate.setValue(date);
  }

  changePowervalue() {
    if(this.f.powerTyp.value == 1) this.f.powerTyp.setValue(2);
    else if(this.f.powerTyp.value == 2) this.f.powerTyp.setValue(1);
  }

  checkFirstRegistration() {
    if(this.f.firstRegistrationDate.value){
      if(this.f.firstRegistrationDate.value < this.f.manufactureDate.value){
        this.f.firstRegistrationDate.setValue(null);
      }
    }
  }

  validDate(value: string) {
    const date = new Date(value)
    this.isInvalidDate = true;
  }

  getUrbanText() {
    if(this.authService.isHoedl()){
      return 'Verbrauch innerorts (l/100km)';
    }
    return 'Verbrauch Urban (l/100km)';
  }

  getLandText() {
    if(this.authService.isHoedl()){
      return 'Verbrauch außerorts (l/100km)';
    }
    return 'Verbrauch Land (l/100km)';
  }

  // vaildNumber(value: number) {
  //   if(value % 1 != 0) this.inValidPrice = true;
  //   else this.inValidPrice = false;
  // }
}
