import { VehicleEquipmentFactory } from './../../buisness-object/vehicle/optional/factory/VehicleEquipmentFactory';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VehicleBodyColorFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleBodyColorFactory';
import { VehicleBodyTypFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleBodyTypFactory';
import { VehicleBrandFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleBrandFactory';
import { VehicleCategoryFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleCategoryFactory';
import { VehicleDriveFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleDriveFactory';
import { VehicleFuelFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleFuelFactory';
import { VehicleTransmissionFactory } from 'src/app/buisness-object/vehicle/basic/factory/VehicleTransmissionFactory';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { VehicleFactory } from 'src/app/buisness-object/vehicle/factory/VehicleFactory';
import { VehicleEquipment } from 'src/app/buisness-object/vehicle/optional/VehicleEquipment';
import { VehiclePicture } from 'src/app/buisness-object/vehicle/optional/VehiclePicture';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { VehicleUpholsteryColor } from 'src/app/buisness-object/vehicle/optional/VehicleUpholsteryColor';
import { VehicleUpholstery } from 'src/app/buisness-object/vehicle/optional/VehicleUpholstery';
import { VehicleEnvironmentLabelFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehicleEnvironmentLabelFactory';
import { VehicleEnviromentLabel } from 'src/app/buisness-object/vehicle/optional/VehicleEnviromentLabel';
import { VehicleUpholsteryColorFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehicleUpholsteryColorFactory';
import { VehicleUpholsteryFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehicleUpholsteryFactory';
import { DialogService } from '../dialog/dialog.service';
import { PriceSheet } from 'src/app/buisness-object/pricesheet/PriceSheet';
import { PriceSheetFactory } from 'src/app/buisness-object/pricesheet/factory/PriceSheetFactory';
import { AuthenticationService } from '../authentication/authentication.service';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { Picture } from 'src/app/buisness-object/picture/Picture';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  public vehicles$  = new BehaviorSubject<any>(0);
  public reload$  = new BehaviorSubject<any>(false);
  private static instance: VehicleService;
  private reloadLoop: any;

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService,
    private authService: AuthenticationService
  ) {
  }

  static getInstance(): VehicleService {
    if(!VehicleService.instance){
      VehicleService.instance = new VehicleService(null, null, null, null);
    }
    return VehicleService.instance;
  }

  public startReloadLoop(loopTime) {
    if(this.reloadLoop != null) clearInterval(this.reloadLoop);
    console.log("start reload vehicles");
    this.reloadLoop = setInterval(() => {
      this.getVehicle(true).subscribe((t) => {
        this.reload$.next(true);
        console.log("reload vehicles: "+ new Date());
      });
    }, loopTime);
  }

  public stopReloadLoop() {
    if(this.reloadLoop != null){
      clearInterval(this.reloadLoop);
      console.log("stopped reload vehicles");
    }
  }

  public createVehicle(vehicle: Vehicle): Observable<Vehicle> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let equipments: any[] = [];
    if(vehicle.equipments){
      vehicle.equipments.forEach(element => {
        equipments.push({
          eq_id: element.eq_id
        })
      });
    }
   let body = vehicle.getJSON(this.authService);
    let observable = this.http.post(environment.api + '/back/vehicle',
    body, { headers });
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, this.authService.mandantId$.getValue() != 2);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateSavedVehicle(vehicle: Vehicle, saved_id: number): Observable<Vehicle> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = vehicle.getJSON(this.authService);
    body.saved_id = saved_id;
    let observable = this.http.put(environment.api + '/back/vehicle/saved', body, { headers });
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, this.authService.mandantId$.getValue() != 2);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = vehicle.getJSON(this.authService);
    let observable = this.http.put(environment.api + '/back/vehicle', body, { headers });
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, this.authService.mandantId$.getValue() != 2);
        data.priceSheet = vehicle.priceSheet;
        let backup = this.vehicles$.getValue();
        if(backup){
          let index = backup.findIndex(v => v.id == data.id);
          if(index > -1){
            backup[index] = data;
            this.vehicles$.next(backup);
          }
        }
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateVehicleSafe(vehicle: Vehicle, force: boolean = false): Observable<Vehicle|any> {
    let oldVehicle: Vehicle = undefined;
    let backup = this.vehicles$.getValue();
    if(backup){
      let index = backup.findIndex(v => v.id == vehicle.id);
      if(index > -1){
        oldVehicle = backup[index] as Vehicle;
      }
    }
    if(oldVehicle) {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token')
      });
      let newVehicleObject = vehicle.getJSON(this.authService);
      let oldVehicleObject = oldVehicle.getJSON(this.authService);
      let observable = this.http.put(environment.api + '/back/vehicle/new/'+(force?1:0), {vehicle: newVehicleObject, old_vehicle: oldVehicleObject}, { headers });
      return observable.pipe(
        map((rawData: any) => {
          let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, false);
          let backup = this.vehicles$.getValue();
          if(backup){
            let index = backup.findIndex(v => v.id == data.id);
            if(index > -1){
              backup[index] = data;
              this.vehicles$.next(backup);
            }
          }
          return data;
        }),catchError((error) => {
          if(error.status == 403){
            this.lService.logout();
          } else if(error.status == 409){
            console.log(error)
            return [error.error];
          } else {
            console.log(error);
            this.dService.showNotification({
              title: 'Fehler',
              message: 'Fehlermeldung: ' + error.error.error_message,
              success: false
            });
          }
          return [];
        })
      );
    } else {
      this.dService.showNotification({
        title: 'Fehler',
        message: 'Fehlermeldung: Lokale Kopie des Ausgangszustands nicht vorhanden.',
        success: false
      });
    }
  }

  public getVehicle(reload?: boolean, skipLoadingAnimation?: boolean): Observable<Vehicle[]> {
    if(!reload && this.vehicles$.getValue()?.length > 0){
      return of(this.vehicles$.getValue());
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token'),
        'skip-loading-animation': skipLoadingAnimation != undefined ? (skipLoadingAnimation ? "true": "false") : "false"
      });
      let observable = this.http.get(environment.api + '/back/vehicle', {headers});
      return observable.pipe(
        map((rawData: any) => {
          let data = VehicleFactory.jsonFactory(rawData.vehicles, this.authService.mandantId$.getValue() != 2);
          // data.sort((a,b) => a.id - b.id);
          this.vehicles$.next(data);
          return data;
        }),catchError((error) => {
          if(error.status == 403){
            this.stopReloadLoop();
            this.lService.logout();
          } else {
            console.log(error);
            this.dService.showNotification({
              title: 'Fehler',
              message: 'Fehlermeldung: ' + error.error.error_message,
              success: false
            });
          }
          return [];
        })
      );
    }
  }

  public getSingleVehicle(vehicleId: number): Observable<Vehicle> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/vehicle/'+vehicleId, {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactoryOne(rawData.vehicle, this.authService.mandantId$.getValue() != 2);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getVehicleByCustomer(id: number): Observable<Vehicle[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/vehicle/customer/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFactory.jsonFactory(rawData.vehicles, this.authService.mandantId$.getValue() != 2);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          /*this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });*/
        }
        return [];
      })
    );
  }

  public deleteVehicle(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/vehicle/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  public getBrands(): Observable<VehicleBrand[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/brand', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleBrandFactory.jsonFactory(rawData.brands);
        data.sort((a,b) => {
          if(a.makeName > b.makeName) return 1;
          if(a.makeName < b.makeName) return -1;
          return 0;
        })
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getVehiclePicture(id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    let observable = this.http.get(environment.api + '/back/vehicle/picture/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return 'data:image/png;base64,'+rawData.data.trim();
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getVehicleThumbnail(id: number): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    let observable = this.http.get(environment.api + '/back/vehicle/thumbnail/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        let vehicles = this.vehicles$.getValue();
        let index = vehicles.findIndex(v => v.pictures[0]?.id == id);
        if(index > -1){
          vehicles[index].thumbnail = 'data:image/png;base64,'+rawData;
          this.vehicles$.next(vehicles);
        }
        return rawData.data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createVehiclePicture(picture: Picture): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const body = {
      vehicle_id: picture.obj_id,
      picture: picture.src.split(',')[1],
    }
    let observable = this.http.post(environment.api + '/back/vehicle/picture', body, {headers, reportProgress: true, observe: 'events'});
    return observable.pipe(
      map((rawData: any) => {
        return rawData;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateVehiclePicture(pic: Picture): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.put(environment.api + '/back/vehicle/picture',
    {
      vehicle_id: pic.obj_id,
      picture_id: pic.picture_id,
      position: pic.position
    }, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return rawData;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deletePicture(picture: Picture): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/vehicle/picture/' + picture.obj_id + '/' + picture.picture_id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }


  /*****  Get Vehicle Resources ******/

  public getTransmissions(): Observable<VehicleTransmission[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/transmission', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleTransmissionFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getFuels(): Observable<VehicleFuel[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/fuel', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleFuelFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getDrives(): Observable<VehicleDrive[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/drive', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleDriveFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getBodyTyps(): Observable<VehicleBodyTyp[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/body_typ', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleBodyTypFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

   public getBodyColor(): Observable<VehicleBodyColor[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/body_color', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleBodyColorFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getCategories(): Observable<VehicleCategory[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/category', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleCategoryFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getEquipment(): Observable<VehicleEquipment[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/equipment', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleEquipmentFactory.jsonFactory(rawData.equipments);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getUpholsteryColors(): Observable<VehicleUpholsteryColor[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/upholstery_color', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleUpholsteryColorFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getUpholsteries(): Observable<VehicleUpholstery[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/upholstery', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleUpholsteryFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getEnvironmentLabels(): Observable<VehicleEnviromentLabel[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/environment_label', {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleEnvironmentLabelFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  getEtikettenDruck(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/vehicle/etikett/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getPDFPreisblattWeb(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/vehicle/pdf/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getPDFPreisblatt(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
       Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.get(environment.api + '/back/vehicle/pdf/raw/'+id, { headers, responseType: 'arraybuffer' });
    return observable.pipe(
      map((result: any) => {
        return result;
      }),
      catchError(error => {
        console.log(error)
        return of([]);
      })
    );
  }

  getPaperStatuse(): string[] {
    return [
      'Typenschein nicht eingegangen',
      'Sandanter',
      'Easyleasing',
      'Erste Bank Leasing',
      'Lagernd',
      'Versendet'
    ]
  }

  getRepairStatuse(): string[] {
    return [
      'Bereit zum Verkauf',
      'Ersatzteile bestellt',
      'In Reparatur',
      'Vorlauf'
    ]
  }

  getTypeCertificateBanks(): string[] {
    return [
      'Ja',
      'Nein',
      'Nicht notwendig',
    ]
  }

  /*
  public getRessource(ressource: string): Observable<VehicleBodyColor[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.get(environment.api + '/back/ressource/'+ressource, {headers});
    return observable.pipe(
      map((rawData: any) => {
        let data = VehicleBodyColorFactory.jsonFactory(rawData.ressources);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
  */

  createPriceSheet(priceSheet: PriceSheet): Observable<PriceSheet> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = priceSheet.getJSON();
    let observable = this.http.post(environment.api + '/back/preisblatt', body, { headers });
    return observable.pipe(
      map((raw: any) => {
        let data = PriceSheetFactory.jsonFactoryOne(raw.preisblatt);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  updatePriceSheet(priceSheet: PriceSheet): Observable<PriceSheet> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = priceSheet.getJSON();
    let observable = this.http.put(environment.api + '/back/preisblatt', body, { headers });
    return observable.pipe(
      map((raw: any) => {
        let data = PriceSheetFactory.jsonFactoryOne(raw.preisblatt);
        return data;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  downloadCsv(filename: String, sub_menu: SubMenu, filter: any[]) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      "columns": sub_menu.columns,
      "filter": filter
    }
    let observable = this.http.post(environment.api + '/back/data/csv', body, { headers, responseType: 'arraybuffer'  });
    return observable.pipe(
      map((raw: any) => {
        this.download(raw, filename, "text/csv");
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  download(data: any, filename: String, datatyp: string) {
    const blob = new Blob([data], { type: datatyp });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename+'.csv');
    a.click();
  }
}
