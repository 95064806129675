<div id="list-element" class="list_hover_element">
    <!-- <div class="check_container" class="flex">
        <input type="checkbox" class="checkbox_one">
    </div> -->
    <div id="id-container" class="flex" (click)="openDetailsEmitter.emit(customer)">
        <div class="lbl_list_data list_item_pointer_event">{{customer.id}}</div>
    </div>
    <div id="description-container" class="flex list_item_pointer_event" (click)="openDetailsEmitter.emit(customer)">
        <div *ngIf="customer.typ == 1" class="lbl_list_data">{{customer.cutString(customer.companyName, 200)}}</div>
        <div *ngIf="customer.typ == 2" class="lbl_list_data">{{customer.cutString(customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName, 200)}}</div>
    </div>
    <div [ngClass]="customer.typ == 1 ? 'lbl_color_wrapper_yellow' : 'lbl_color_wrapper_violet'">{{customer.typ == 1 ? 'Firma' : 'Privat'}}</div>
    <div [ngClass]="customer.status == 1 ? 'lbl_color_wrapper_brown' : (customer.status == 2 ? 'lbl_color_wrapper_cyan' : 'lbl_color_wrapper_gray')">{{customer.status == 1 ? 'Kunde' : (customer.status == 2 ? 'Intressent' : 'Archiv')}}</div>
    <div id="id-container" class="flex">
        <div class="lbl_list_data">{{customer.cutString(customer.contactPerson.phone, 14)}}</div>
    </div>
    <div id="date-container" class="flex">
        <div class="lbl_list_data visible-on-desktop">{{customer.lastUpdated | date:'dd. MMM yyyy'}}</div>
    </div>
    <div class="list_element_action_container">
      <!-- <button class="btn_list_details" (click)="openDetailsEmitter.emit(customer)">
        <img class="btn_list_details_icon" src="../../../../../assets/eye.svg">
        <span>Details</span>
      </button> -->
      <div #dropdown class="btn_action_wrapper" [ngStyle]="{'visibility': accessEdit ? 'visible' : 'hidden'}">
        <img #dropdownImg class="btn_actions" src="../../../../../assets/navdots.svg" (click)="this.showDropdown = !this.showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event"  [ngClass]="showDropdown ? 'btn_actions_active' : ''">
          <div class="dropdown_actions_container" *ngIf="showDropdown">
              <div *ngIf="accessEdit" class="option" (click)="openDetailsEmitter.emit(customer)">
                <div class="dropdown_txt">Details</div>
                <img class="option_icon" src="../../../../../assets/eye-white.svg" >
              </div>
              <div *ngIf="accessEdit" class="option" (click)="openEditEmitter.emit(customer)">
                  <div class="dropdown_txt">Bearbeiten</div>
                  <img class="option_icon" src="../../../../../assets/edit.svg" >
              </div>
              <div *ngIf="accessEdit && customer.status == 1 || customer.status == 2 || customer.status == 3" class="option" (click)="selectionMoveEmitter.emit(customer)">
                  <div class="dropdown_txt">Verschieben</div>
                  <img class="option_icon" src="../../../../../assets/direction.svg" >
              </div>
              <div *ngIf="accessEdit" class="option" (click)="selectionCreateDokumentEmitter.emit(customer)">
                <div class="dropdown_txt">Dokument erstellen</div>
                <img class="option_icon" src="../../../../../assets/document.svg" >
              </div>
              <div *ngIf="accessEdit" class="option" (click)="createCustomerCarEmitter.emit(customer)">
                <div class="dropdown_txt">Fahrzeug hinzufügen</div>
                <img class="option_icon" src="../../../../../assets/carmenu-white.svg" >
              </div>
              <div *ngIf="accessEdit" class="option" (click)="deleteEmitter.emit(customer)">
                  <div class="dropdown_txt">Löschen</div>
                  <img class="option_icon" src="../../../../../assets/delete.svg" >
              </div>
              <div *ngIf="!accessEdit" class="option_none">
                  <div class="dropdown_txt">Keine Optionen</div>
              </div>
              <!-- <div class="list_action_value_container_deep" *ngIf="showActionsMoveDeep">
                <div *ngIf="customer.status != 0 && customer.status != 1" class="list_action_option" (click)="moveEmitter.emit({customer: customer, status: 1})">
                    <div class="list_action_value_lbl">Kunden</div>
                    <img class="list_action_value_icon" src="../../../../../assets/direction.svg" >
                </div>
                <div *ngIf="customer.status != 0 && customer.status != 2" class="list_action_option" (click)="moveEmitter.emit({customer: customer, status: 2})">
                  <div class="list_action_value_lbl">Interessenten</div>
                  <img class="list_action_value_icon" src="../../../../../assets/direction.svg" >
                </div>
                <div *ngIf="customer.status != 0 && customer.status != 3" class="list_action_option" (click)="moveEmitter.emit({customer: customer, status: 3})">
                  <div class="list_action_value_lbl">Archiv</div>
                  <img class="list_action_value_icon" src="../../../../../assets/direction.svg" >
                </div>
              </div> -->
              <!-- <div class="list_action_value_container_deep" *ngIf="showActionsMoveDeepDocuments">
                <div class="list_action_option" (click)="createDocument(customer, 2)">
                    <div class="list_action_value_lbl">Angebot</div>
                    <img class="list_action_value_icon" src="../../../../../assets/document.svg" >
                </div>
                <div class="list_action_option" (click)="createDocument(customer, 4)">
                  <div class="list_action_value_lbl">Vertrag</div>
                  <img class="list_action_value_icon" src="../../../../../assets/document.svg" >
                </div>
              </div> -->
          </div>
        </div>
    </div>
</div>
