<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
    </button>
    <h2>Kaufvertrag {{document!=null ? 'bearbeiten' : 'erstellen'}}</h2>
  </div>
  <div class="header-button-wrapper" *ngIf="!showCreateChoise">
    <button class="btn_submit" [disabled]="form == null || form.controls['vehicle'].value == null" (click)="selectionSave()">Speichern</button>
    <!-- <button class="btn_submit" [disabled]="true" (click)="selectionSign()">Signieren</button>
    <button class="btn_submit" [disabled]="true" (click)="selectionSignWithApp()">Mit App Signieren</button> -->
  </div>
</div>

<div class="body-base-style choise-wrapper" *ngIf="form && showCreateChoise">
  <div class="choise-container" [class.choise_container_disabled]="false">
    <h1>Kunden wählen</h1>
    <form [formGroup]="form" class="form">
      <app-dropdown-objects-search
        [title]="'Kunde*'"
        [placeholder]="'Kunde'"
        [headervalue]="form.controls['customer']?.value?.getName()"
        [formcontrol]="form.controls['customer']"
        [values]="customerValues"
        [submitted]="submitted"
        [showLabel]="false"
        (submitValueEmitter)="setValueFromDropdown('customer', $event)"
      ></app-dropdown-objects-search>
    </form>
    <label>Wählen Sie zuerst einen Kunden, um den <br>Kaufvertrag zu erstellen.</label>
    <button [disabled]="form.controls['customer'].value == null" class="btn_submit_dark" (click)="submit()">Weiter</button>
  </div>
  <label id="choise-split-label">ODER</label>
  <div class="choise-container">
      <h1>Neuer Kunde</h1>
      <label>Legen Sie einen neuen Kunden an, um den <br>Kaufvertrag zu erstellen.</label>
      <button class="btn_submit_dark" (click)="goToCustomer()">Weiter</button>
  </div>
</div>

<form [formGroup]="form" id="contract-create-body" class="body-base-style" *ngIf="form && !showCreateChoise">
  <div class="column">
    <!--Fahrzeug-->
    <div class="overview_data_box">
      <label class="overview_data_box_lbl_title">Fahrzeug</label>
      <app-dropdown-objects-search
        [title]="'Fahrzeug*'"
        [placeholder]="'Fahrzeug'"
        [headervalue]="form.controls['vehicle'].value ? form.controls.vehicle.value.getVehicleName() : ''"
        [formcontrol]="form.controls['vehicle']"
        [values]="vehicleValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
      ></app-dropdown-objects-search>
    </div>
    <!--Kunde-->
    <form class="overview_data_box" [formGroup]="formCustomer">
      <label class="overview_data_box_lbl_title">Kunde</label>
      <app-dropdown-multiple
        [title]="'Anrede*'"
        [formcontrol]="formCustomer.controls['salutation']"
        [headerText]="salutationValues[formCustomer.controls['salutation']?.value-1]"
        [values]="salutationValues"
        (submitValueEmitter)="formCustomer.controls['salutation'].setValue($event)"
      ></app-dropdown-multiple>
      <app-dropdown-strings
        [title]="'Titel'"
        [placeholder]="'---'"
        [formcontrol]="formCustomer.controls['title']"
        [headerText]="formCustomer.controls['title'].value"
        [values]="titleValues"
        (submitValueEmitter)="formCustomer.controls['title'].setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Vorname*</div>
        <input type="text" placeholder="Vorname" formControlName="firstName" [class.input_invalid]="submitted && formCustomer.controls['firstName'].errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Nachname*</div>
        <input type="text" placeholder="Nachname" formControlName="lastName" [class.input_invalid]="submitted && formCustomer.controls['lastName'].errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Geburtsdatum</div>
        <input #inputDate1 type="date" placeholder="date" [max]="today" formControlName="birthdate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formCustomer.controls['birthdate'].errors">
      </div>
      <!-- <app-dropdown-multiple
        [title]="'Typ'"
        [formcontrol]="formCustomer.controls['typ']"
        [headerText]="typValues[formCustomer.controls['typ'].value-1]"
        [values]="typValues"
        (submitValueEmitter)="formCustomer.controls['typ'].setValue($event-1)"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Status'"
        [formcontrol]="formCustomer.controls['status']"
        [headerText]="statusValues[formCustomer.controls['status'].value-1]"
        [values]="statusValues"
        (submitValueEmitter)="formCustomer.controls['status'].setValue($event-1)"
      ></app-dropdown-multiple> -->
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">Firmenname *</label>
        <input type="text" placeholder="Firmenname" formControlName="companyName" [class.input_invalid]="submitted && formCustomer.controls['companyName'].errors">
      </div>
      <div class="input_wrapper" *ngIf="formCustomer.controls['typ'].value == 1">
        <label class="overview_record_lbl">ATU</label>
        <input type="text" placeholder="ATU" formControlName="atuNum" [class.input_invalid]="submitted && formCustomer.controls['atuNum'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Straße, Nr*</label>
        <input type="text" placeholder="Straße" formControlName="street" [class.input_invalid]="submitted && formCustomer.controls['street'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">PLZ*</label>
        <input type="number" placeholder="PLZ" formControlName="postalCode" [class.input_invalid]="submitted && formCustomer.controls['postalCode'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Ort*</label>
        <input type="text" placeholder="Ort" formControlName="city" [class.input_invalid]="submitted && formCustomer.controls['city'].errors">
      </div>
      <app-dropdown-strings
        [title]="'Land'"
        [placeholder]="'Land'"
        [formcontrol]="formCustomer.controls['country']"
        [headerText]="formCustomer.controls['country'].value"
        [values]="countries"
        [isOptional]="true"
        (submitValueEmitter)="formCustomer.controls['country'].setValue($event);"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label class="overview_record_lbl">E-Mail*</label>
        <input type="text" placeholder="E-Mail" formControlName="email" [class.input_invalid]="submitted && formCustomer.controls['email'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Telefon*</label>
        <input type="text" placeholder="Telefon" formControlName="phone" [class.input_invalid]="submitted && formCustomer.controls['phone'].errors">
      </div>
      <app-dropdown-simple *ngIf="authService.isHoedl()"
        [title]="'Politisch Exponierte Person*'"
        [formcontrol]="formCustomer.controls['is_pep']"
        (submitValueEmitter)="formCustomer.controls['is_pep'].setValue($event)"
      ></app-dropdown-simple>
    </form>
     <!--Zusätzlicher Käufer-->
     <div class="overview_data_box" *ngIf="false">
      <label class="overview_data_box_lbl_title">Zusätzlicher Käufer</label>
      <button class="button-square button-square-add" (click)="addCustomer()"><span>&#43;</span> Weiteren Kunden hinzufügen</button>
    </div>
  </div>

  <div class="column">
    <!--Zulassung-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value" *ngIf="showKaufvertragValue('box-zulassung')">
      <label class="overview_data_box_lbl_title">Zulassung</label>
      <div class="input_wrapper" *ngIf="form.controls['vehicle'].value">
        <label class="overview_record_lbl">{{form.controls['vehicle'].value ? 'Zulassung nach Import' : 'Erstregistrierung im Ausland'}}</label>
        <input #inputDate type="date" placeholder="date" [max]="today" formControlName="foreign_registration_date" [class.date-input--has-value]="inputDate.value != null" [class.input_invalid]="submitted && form.controls.foreign_registration_date.errors">
      </div>
      <app-dropdown-multiple
        [title]="'Dokumenttyp'"
        [formcontrol]="form.controls['registration_document']"
        [headerText]="registrationDocumentValue"
        [values]="registrationDocumentValues"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['registration_document'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <app-dropdown-simple *ngIf="authService.isStandard() || authService.isHoedl()"
        [title]="'Dokument original'"
        [formcontrol]="form.controls['is_original_document']"
        (submitValueEmitter)="form.controls['is_original_document'].setValue($event)"
      ></app-dropdown-simple>
    </div>
    <!--Fahrzeugzustand-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
      <div class="overview-header-wrapper">
        <label class="overview_data_box_lbl_title">Fahrzeugzustand</label>
        <button type="button" class="btn_label_blue" (click)="openInfoCondition()">Info-Blatt öffnen</button>
      </div>
      <div class="input_wrapper input-wrapper-special-2">
        <label class="overview_record_lbl">Betriebs- und Zulassungsbereit</label>
        <div class="slider_container" (click)="form.controls['ready_for_operation'].setValue(!form.controls['ready_for_operation'].value)">
          <div class="slider_still" [ngClass]="form.controls['ready_for_operation'].value ? 'slider_still_on' : ''"></div>
          <div class="slider_move" [ngClass]="form.controls['ready_for_operation'].value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <app-dropdown-multiple
        [title]="'Mechanischer Zustand*'"
        [formcontrol]="form.controls['mechanical_condition']"
        [headerText]="mechanical_conditionValue"
        [values]="conditionValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['mechanical_condition'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Karosserie*'"
        [formcontrol]="form.controls['body_condition']"
        [headerText]="body_conditionValue"
        [values]="conditionValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['body_condition'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Lack*'"
        [formcontrol]="form.controls['paint_condition']"
        [headerText]="paint_conditionValue"
        [values]="conditionValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['paint_condition'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Innenraum/Sonstiges*'"
        [formcontrol]="form.controls['interior_condition']"
        [headerText]="interior_conditionValue"
        [values]="conditionValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['interior_condition'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Elektrik/Elektronik*'"
        [formcontrol]="form.controls['electronic_condition']"
        [headerText]="electronic_conditionValue"
        [values]="conditionValues"
        [submitted]="submitted"
        (submitValueEmitter)="form.controls['electronic_condition'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <div class="input_wrapper input-wrapper-special" [class.input-wrapper-special-2]="form.controls['total_condition'].value">
        <label class="overview_record_lbl">Gesamtzustand</label>
        <label *ngIf="form.controls['total_condition'].value == null" class="overview_record_lbl">Die Fahrzeugklasse wird angzeigt, sobald sie den bekannt gegeben haben.</label>
        <div *ngIf="form.controls['total_condition'].value" [ngClass]="getColor()" style="justify-self: flex-end;">{{form.controls['total_condition'].value}}</div>
      </div>
      <div class="input_wrapper input-wrapper-special-2" *ngIf="showKaufvertragValue('Ankaufsüberprüfung')">
        <label class="overview_record_lbl">Ankaufsüberprüfung</label>
        <div class="slider_container" (click)="form.controls['purchase_test_wish'].setValue(!form.controls['purchase_test_wish'].value)">
          <div class="slider_still" [ngClass]="form.controls['purchase_test_wish'].value ? 'slider_still_on' : ''"></div>
          <div class="slider_move" [ngClass]="form.controls['purchase_test_wish'].value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="input_wrapper" *ngIf="form.controls['purchase_test_wish'].value">
        <div class="overview_record_lbl">Datum</div>
        <input #inputDate2 type="date" placeholder="date" [min]="today" formControlName="purchase_test_date" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.controls['purchase_test_date'].errors">
      </div>
      <div class="input_wrapper" *ngIf="form.controls['purchase_test_wish'].value">
        <label class="overview_record_lbl">Prüfer</label>
        <input type="text" placeholder="Prüfer" formControlName="purchase_tester" [class.input_invalid]="submitted && form.controls['purchase_tester'].errors">
      </div>
    </div>
    <!--Zusätzlich vorhanden-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value" *ngIf="authService.isHoedl()">
      <label class="overview_data_box_lbl_title">Zusätzlich vorhanden</label>
      <app-dropdown-simple
        [title]="'Probefahrt durchgeführt'"
        [formcontrol]="form.controls['test_drive']"
        (submitValueEmitter)="form.controls['test_drive'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Reserverad vorhanden'"
        [formcontrol]="form.controls['spare_wheel']"
        (submitValueEmitter)="form.controls['spare_wheel'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Wagenheber vorhanden'"
        [formcontrol]="form.controls['honey_comb_lifter']"
        (submitValueEmitter)="form.controls['honey_comb_lifter'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Felgenschloss vorhanden'"
        [formcontrol]="form.controls['rim_lock']"
        (submitValueEmitter)="form.controls['rim_lock'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Fernbedienung vorhanden'"
        [formcontrol]="form.controls['remote_control']"
        (submitValueEmitter)="form.controls['remote_control'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Kartenmaterial vorhanden'"
        [formcontrol]="form.controls['card_material']"
        (submitValueEmitter)="form.controls['card_material'].setValue($event)"
      ></app-dropdown-simple>
      <app-dropdown-simple
        [title]="'Anhängerkupplung vorhanden'"
        [formcontrol]="form.controls['trailer_hitch']"
        (submitValueEmitter)="form.controls['trailer_hitch'].setValue($event)"
      ></app-dropdown-simple>
    </div>
  </div>

  <div class="column">
    <!--Zahlung-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
      <label class="overview_data_box_lbl_title">Zahlung</label>
      <app-dropdown-strings *ngIf="showKaufvertragValue('warranty_period')"
        [title]="'Gewährleistung (in Monaten)'"
        [placeholder]="'---'"
        [formcontrol]="form.controls['warranty_period']"
        [headerText]="form.controls['warranty_period'].value"
        [values]="periods"
        (submitValueEmitter)="form.controls['warranty_period'].setValue($event)"
      ></app-dropdown-strings>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Kaufpreis in €*</label>
        <input type="number" placeholder="---" formControlName="price" [class.input_invalid]="submitted && form.controls['price'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Kaution in €</label>
        <input type="number" placeholder="---" formControlName="deposit" [class.input_invalid]="submitted && form.controls['deposit'].errors">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Übergabe</div>
        <input #inputDate1 type="date" placeholder="date" [min]="today" formControlName="handing_over_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls.handing_over_date.errors">
      </div>
      <app-dropdown-multiple *ngIf="showKaufvertragValue('Zahlungsmethode')"
        [title]="'Zahlungsmethode'"
        [formcontrol]="form.controls['payment_condition']"
        [headerText]="paymentConditionValue"
        [values]="paymentConditionValues"
        [isOptional]="false"
        (submitValueEmitter)="form.controls['payment_condition'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <app-dropdown-multiple *ngIf="showKaufvertragValue('Finanzierungsart')"
        [title]="'Finanzierungsart'"
        [formcontrol]="form.controls['finanzierungsart']"
        [placeholder]="'---'"
        [headerText]="finanzierungsartenValue"
        [values]="finanzierungsartenValues"
        (submitValueEmitter)="form.controls['finanzierungsart'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <app-dropdown-multiple *ngIf="false"
        [title]="'Finanzierungsart-Anzahlung'"
        [formcontrol]="form.controls['finanzierungsart_anzahlung']"
        [placeholder]="'---'"
        [headerText]="finanzierungsartenAnzahlungValue"
        [values]="finanzierungsartenAnzahlungValues"
        (submitValueEmitter)="form.controls['finanzierungsart_anzahlung'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="showKaufvertragValue('Anzahlung')">
        <label class="overview_record_lbl">Anzahlung in €</label>
        <input type="number" placeholder="---" formControlName="down_payment" [class.input_invalid]="submitted && form.controls['down_payment'].errors">
      </div>
      <!-- <app-dropdown-strings *ngIf="showKaufvertragValue('down_payment_condition') && form.controls['down_payment'].value > 0"
        [title]="'Anzahlungs-Zahlungsart'"
        [placeholder]="'---'"
        [headerText]="downPaymentConditionValue"
        [formcontrol]="form.controls['down_payment_condition']"
        [values]="paymentConditionValues"
        (submitValueEmitter)="form.controls['down_payment_condition'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-strings> -->
      <app-dropdown-strings *ngIf="showKaufvertragValue('Finanzierungsgesellschaft')"
        [title]="'Finanzierungsgesellschaft'"
        [placeholder]="'---'"
        [headerText]="finanzierungsgesellschaftValue"
        [formcontrol]="form.controls['finanzierungsgesellschaft']"
        [values]="finanzierungsgesellschaftValues"
        (submitValueEmitter)="form.controls['finanzierungsgesellschaft'].setValue($event); getEnumDataOnChange();"
      ></app-dropdown-strings>
    </div>
    <!--Zahlungsbedingungen-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
      <label class="overview_data_box_lbl_title">Sonderkonditionen</label>
      <input
        type="text"
        maxlength="400"
        placeholder="---"
        formControlName="special_conditions"
        [class.input_invalid]="submitted && form.controls['special_conditions'].errors">
      <label class="overview_record_lbl">({{400 - (form.controls['special_conditions'].value ? form.controls['special_conditions'].value.length : 0)}} Zeichen übrig)</label>
    </div>
    <!--Inzahlungsnahme-->
    <div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
      <label class="overview_data_box_lbl_title">Inzahlungsnahme</label>
      <div *ngIf="form.controls['trade_in_vehicle'].value == true" class="overview_data_box_inner">
        <app-dropdown-objects-search
          [title]="'Marke *'"
          [placeholder]="'Marke'"
          [headervalue]="form.controls['trade_in_vehicle_brand'].value ? form.controls['trade_in_vehicle_brand'].value?.makeName : ''"
          [formcontrol]="form.controls['trade_in_vehicle_brand']"
          [values]="brandValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('brand', $event)"
        ></app-dropdown-objects-search>
        <app-dropdown-objects-search
            [title]="'Model *'"
            [placeholder]="'Model'"
            [headervalue]="form.controls['trade_in_vehicle_model'].value ? form.controls['trade_in_vehicle_model'].value?.name : ''"
            [formcontrol]="form.controls['trade_in_vehicle_model']"
            [values]="modelValues"
            [submitted]="submitted"
            (submitValueEmitter)="setValueFromDropdown('model', $event)"
        ></app-dropdown-objects-search>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Erstzulassung *</div>
          <input #inputDate1 type="date" placeholder="date" [max]="today" formControlName="trade_in_vehicle_registration_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_registration_date'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Anzahl Vorbesitzer</div>
          <input type="number" placeholder="---" formControlName="trade_in_vehicle_previous_owner" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_previous_owner'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">VIN-Nummer *</div>
          <input type="text" placeholder="---" formControlName="trade_in_vehicle_vin_number" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_vin_number'].errors">
        </div>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <div class="overview_record_lbl">NAT-Code</div>
          <input type="text" placeholder="---" formControlName="trade_in_vehicle_national_code" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_national_code'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Kilometerstand *</div>
          <input type="number" placeholder="---" formControlName="trade_in_vehicle_mileage" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_mileage'].errors">
        </div>
        <app-dropdown-multiple *ngIf="showKaufvertragValue('trade_in_vehicle_registration_document')"
          [title]="'Genehmigungs-Dokument *'"
          [formcontrol]="form.controls['trade_in_vehicle_registration_document']"
          [headerText]="tradeInVehicleRegistrationDocumentValue"
          [values]="registrationDocumentValues"
          [isOptional]="true"
          (submitValueEmitter)="form.controls['trade_in_vehicle_registration_document'].setValue($event); getEnumDataOnChange();"
        ></app-dropdown-multiple>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Wert in € *</label>
          <input type="number" placeholder="---" formControlName="trade_in_vehicle_price" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_price'].errors">
        </div>
        <app-dropdown-simple *ngIf="showKaufvertragValue('trade_in_vehicle_includes_tax')"
        [title]="'Mit MwSt.'"
        [formcontrol]="form.controls['trade_in_vehicle_includes_tax']"
        (submitValueEmitter)="form.controls['trade_in_vehicle_includes_tax'].setValue($event)"
        ></app-dropdown-simple>
        <app-dropdown-simple *ngIf="showKaufvertragValue('trade_in_vehicle_reduce_price')"
        [title]="'vom Kaufpreis abziehen'"
        [formcontrol]="form.controls['trade_in_vehicle_reduce_price']"
        (submitValueEmitter)="form.controls['trade_in_vehicle_reduce_price'].setValue($event)"
        ></app-dropdown-simple>
        <div class="input_wrapper"  *ngIf="!authService.isHoedl()">
          <label class="overview_record_lbl">Benutzung bis Übergabe (km)</label>
          <input type="number" placeholder="---" formControlName="trade_in_vehicle_mileage_to_drive" [class.input_invalid]="submitted && form.controls['trade_in_vehicle_mileage_to_drive'].errors">
        </div>
      </div>
      <button class="button-square button-square-add" (click)="this.form.controls['trade_in_vehicle'].setValue(!this.form.controls['trade_in_vehicle'].value)">
        <span>{{this.form.controls['trade_in_vehicle'].value == true ? '&#8722;' : '&#43;'}}</span>
        Fahrzeug {{this.form.controls['trade_in_vehicle'].value == true ? 'entfernen' : 'hinzufügen'}}
      </button>
    </div>
    <!--Zusatzverkäufe-->
    <app-zusatzprodukte *ngIf="authService.isHoedl()"
      [form]="form"
      [zusatzprodukte]="zusatzprodukte"
      [submitted]="submitted"
    ></app-zusatzprodukte>
    <!--<div class="overview_data_box" [class.data_box_disabled]="!form.controls['vehicle'].value">
      <label class="overview_data_box_lbl_title">Zusatzverkäufe</label>
      <div class="remove-wrapper" *ngFor="let selektierteZusatzprodukt of form.controls['selektierte_zusatzprodukte'].value">
        <app-dropdown-multiple style="width: 100%;"
          [formcontrol]="selektierteZusatzprodukt.controls['registration_document'].value"
          [headerText]="registrationDocumentValue"
          [values]="registrationDocumentValues"
          [isOptional]="true"
          (submitValueEmitter)=""
        ></app-dropdown-multiple>
        <button class="button-square" style="margin-bottom: 4px;" (click)="removeAdditionalService(selektierteZusatzprodukt)"><span>&#8722;</span></button>
      </div>
      <button class="button-square button-square-add" (click)="addAdditionalService()"><span>&#43;</span> Zusatzleistungen bzw. -produkt hinzufügen</button>
    </div>-->
  </div>
</form>
