<div class="header-base-style">
    <div class="header-inner-wrapper">
      <button class="button-icon-start" (click)="selectionCloseEmitter.emit()">
        <img class="button-icon-start-img" src="./assets/back.svg"/>
        <span class="button-icon-start-span">Zurück</span>
      </button>
      <h2>{{obj.getFullName()}}</h2>
    </div>
    <div class="header-button-wrapper">
      <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event" *ngIf="false">
        Aktionen
        <img src="./assets/dropdown-arrow-blue.svg">
        <div *ngIf="shwoActions">
          <button
            (click)="selectionEditEmitter.emit(obj); shwoActions = false">
            Fahrzeug hinzufügen
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageEmitter.emit(obj)"
            >Dokument hochladen
          </button>
          <button *ngIf="(obj && obj.vehicle_id == undefined)"
            (click)="selectionChangeStorageAutoEmitter.emit(obj)">
            Ticket erstellen
          </button>
        </div>
      </button>
      <!-- <button class="btn_white" (click)="selectionChangeStorageEmitter.emit(obj)">{{(obj && obj.vehicle_id) ? 'Einlagern' : 'Lagerplatz korrigieren' }}</button> -->
      <!--button *ngIf="obj == null" class="button_type_1 button_store" (click)="selectionInstallationEmitter.emit(obj)">Montieren</button-->
    
      <button class="button_type_2" *ngIf="false">
        Dokumente erstellen
        <img src="./assets/dropdown-arrow-blue.svg">
      </button>

      <button class="btn_submit" (click)="selectionEditEmitter.emit(obj)">Bearbeiten</button>
    
    </div>
    
  </div>
  
  <div class="body-base-style">
    <div class="btn-tab-wrapper">
      <!-- <button class="btn-tab btn-tab-first" (click)="null">Vorherige Reifen</button>
      <button class="btn-tab btn-tab-last" (click)="null">Nächste Reifen</button> -->
    </div>
    
    <div id="drive-details-body">
      <div id="header-column" class="overview_data_box_vertical">
        <div class="overview_record_vertical">
          <h3>Hinweis</h3>
          <div>
            <!--label class="overview_record_lbl">{{obj ? obj.designation_tire : '---'}}</label-->
          </div>
        </div>
      </div>

      <div id="customer-details-body">
        <div class="column">
          <app-collapsible [isCollapsible]>
            <h3 collapsible-header>Kunde</h3>
            <div collapsible-content>
              <div class="overview_record">
                <label class="overview_record_lbl">Kunden-ID</label>
                <label class="overview_record_value">{{getInputValue(obj.id)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Anrede</label>
                <label class="overview_record_value">{{getInputValue(salutation)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Titel</label>
                <label class="overview_record_value">{{getInputValue(obj.contactPerson.title)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Vorname</label>
                <label class="overview_record_value">{{getInputValue(obj.getName())}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Nachname</label>
                <label class="overview_record_value">{{getInputValue(obj.getLastName())}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Geburtsdatum</label>
                <label class="overview_record_value">{{getInputValueDate(obj.contactPerson.birthdate)}}</label>
              </div>
            </div>
          </app-collapsible>
          <app-collapsible [isCollapsible]>
            <h3 collapsible-header>Kontakt und Anschrift</h3>
            <div collapsible-content>
              <div class="overview_record">
                <label class="overview_record_lbl">Straße, Nr.</label>
                <label class="overview_record_value">{{getInputValue(obj.address.street)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">PLZ</label>
                <label class="overview_record_value">{{getInputValue(obj.address.postalCode)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Ort</label>
                <label class="overview_record_value">{{getInputValue(obj.address.city)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Land</label>
                <label class="overview_record_value">{{getInputValue(obj.address.country)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">E-Mail</label>
                <label class="overview_record_value">{{getInputValue(obj.contactPerson.email)}}</label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Telefon</label>
                <label class="overview_record_value">{{getInputValue(obj.contactPerson.phone)}}</label>
              </div>
            </div>
          </app-collapsible>
          <app-collapsible [isCollapsible]>
            <h3 collapsible-header>Weitere Informationen</h3>
            <div collapsible-content>
              <div class="overview_record">
                <label class="overview_record_lbl">Kundentyp</label>
                <label class="overview_record_value" 
                  [class.lbl_color_wrapper_orange]="obj.typ == 1" 
                  [class.lbl_color_wrapper_violet]="obj.typ == 2">
                  {{getInputValue(typName)}}
                </label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Kundenstatus</label>
                <label class="overview_record_value" 
                  [class.lbl_color_wrapper_brown]="obj.status == 1" 
                  [class.lbl_color_wrapper_cyan]="obj.status == 2">
                  {{getInputValue(statusName)}}
                </label>
              </div>
              <div class="overview_record">
                <label class="overview_record_lbl">Kundenbetreuer</label>
              </div>
            </div>
          </app-collapsible>
        </div>

        <div class="column">
            <app-collapsible [isCollapsible] *ngIf="false">
              <h3 collapsible-header>Letzte Dokumente</h3>
            </app-collapsible>
            <app-collapsible [isCollapsible] *ngIf="false">
              <h3 collapsible-header>Letzte Rechnung</h3>
            </app-collapsible>
            <app-collapsible [isCollapsible]>
              <h3 collapsible-header>Anhänge</h3>
              <div collapsible-content>
                <app-files
                  [obj]="obj"
                ></app-files>
              </div>
            </app-collapsible>

            <app-collapsible [isCollapsible]>
                <h3 collapsible-header>Informationen zum Führerschein</h3>
                <div collapsible-content>
                  <div class="overview_record">
                    <label class="overview_record_lbl">Führerschein-Nummer</label>
                    <label class="overview_record_value">{{getInputValue(obj.authorization_number)}}</label>
                  </div>
                  <div class="overview_record">
                    <label class="overview_record_lbl">Führerschein-Behörde</label>
                    <label class="overview_record_value">{{getInputValue(obj.authorization_authority)}}</label>
                  </div>
                  <div class="overview_record">
                    <label class="overview_record_lbl">Führerschein-Datum</label>
                    <label class="overview_record_value">{{getInputValueDate(obj.authorization_date)}}</label>
                  </div>
                  <div class="overview_record">
                    <label class="overview_record_lbl">Führerschein-Gruppen</label>
                    <label class="overview_record_value">{{getInputValue(obj.authorization_groups)}}</label>
                  </div>
                </div>
            </app-collapsible>
        </div>

        <div class="column">
            <app-collapsible [isCollapsible] *ngIf="false">
              <h3 collapsible-header>Kommende Termine</h3>
            </app-collapsible>
            <app-transactions
                [obj]="obj"
            ></app-transactions>




            <!-- <app-collapsible [isCollapsible]>
                <h3 collapsible-header>Historie</h3>
                <div collapsible-content>
                  <div class="overview_files">
                    <div class="file" *ngFor="let trans of transactions">
                      <div class="file-inner" (click)="null">
                        <div class="file-img" [ngStyle]="{'background-color': TransactionHelper.getTransactionColor(trans.dataTyp)}">
                          <img [src]="TransactionHelper.getTransactionIcon(trans.dataTyp)">
                        </div>
                        <div class="file-info-wrapper">
                          <label>{{getInputValue(trans.text)}}</label>
                          <label class="italic-small">{{getInputValue(trans.username)}}</label>
                        </div>
                      </div>
                      <div class="file-date-wrapper">
                        <label>{{getInputValueDateTime(trans.timestamp)}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-collapsible> -->
              
        </div>
    </div>
      


    </div>
  </div>

