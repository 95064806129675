
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { Attachment, AttachmentFactory } from 'src/app/buisness-object/attachment/Attachment';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getAttachment(id: number): Observable<Attachment> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    const observable = this.http.get(environment.api + '/back/attachment/' + id, { headers });
    return observable.pipe(
      map((result: any) => {
        return AttachmentFactory.jsonFactoryOne(result.attachment);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getAttachmentsByTypId(typ: number, typ_id: number): Observable<Attachment[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    const observable = this.http.get(environment.api + '/back/attachment/' + typ + '/' + typ_id, { headers });
    return observable.pipe(
      map((result: any) => {
        return AttachmentFactory.jsonFactory(result.attachments);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createAttachment(attachment: Attachment): Observable<Attachment> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = {
      "typ": attachment.typ,
      "typ_id": attachment.typ_id,
      "mimetype" : attachment.mimetyp,
      "filename": attachment.filename,
      "data" : attachment.data
    }
    const observable = this.http.post(environment.api + '/back/attachment', body, { headers });
    return observable.pipe(
      map((result: any) => {
        attachment.attachment_id = result.attachment_id;
        return attachment;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteAttachment(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let observable = this.http.delete(environment.api + '/back/attachment/' + id, {headers});
    return observable.pipe(
      map((rawData: any) => {
        return true;
      }),catchError((error) => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  base64ToArrayBuffer(base64) {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  print(pdf: string) {
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const objectUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objectUrl;
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  public downloadAttachment(attachment: Attachment) {
    const byteCharacters = atob(attachment.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/' + attachment.mimetyp });
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', attachment.filename +'.' + attachment.mimetyp);
    a.click();
  }
}
