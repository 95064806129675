import { Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { AuthGuard } from 'src/app/helpers/AuthGuard';
import { OutputHelper } from 'src/app/helpers/OutputHelper';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { TransactionService } from 'src/app/service/transaction/transaction.service';

@Component({
  selector: 'app-overview-user-container',
  templateUrl: './overview-user-container.component.html',
  styleUrls: ['./overview-user-container.component.css']
})
export class OverviewUserContainerComponent implements OnInit {
  public transactions: Transaction[] = [];
  public authGuard = new AuthGuard();
  @Input() employee: Employee;
  public getTransactionIcon = OutputHelper.getTransactionIcon;
  public getTransactionColor = OutputHelper.getTransactionColor;

  constructor(private tService: TransactionService) { }

  ngOnInit(): void {
    this.tService.getTransactions('employee', this.employee.id).subscribe((transactions) => {
      if(transactions){
        this.transactions = transactions;
      }
    });
  }

  getInitials(): string {
    return this.employee.firstName.substring(0,1) + this.employee.lastName.substring(0,1);
  }

  getDataTyp(typ: number): string {
   return 'test';
  }
}
