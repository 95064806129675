import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bill, BillStatus, getBillStatusLabel, getBillStatusLabelStyle } from 'src/app/buisness-object/document/Bill';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';

export enum BillContractDetailsTab {
  Details = 1,
  Tickets = 1,
}

@Component({
  selector: 'app-bill-details-purchase-contract',
  templateUrl: './bill-details-purchase-contract.component.html',
  styleUrl: './bill-details-purchase-contract.component.css'
})
export class BillDetailsPurchaseContractComponent extends BaseComponent implements OnInit {
  @Input() obj: Bill;
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  public tabView = BillContractDetailsTab;
  public showTab: BillContractDetailsTab = BillContractDetailsTab.Details;
  public shwoActions = false;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public registrationDocumentValue;
  public billStatus;
  public billStatusStyle;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.obj.purchase_contract.payment_condition);
    this.downPaymentConditionValue = this.getEnumValue('payment_condition', this.obj.purchase_contract.down_payment_condition);
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.obj.purchase_contract.finanzierungsart);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.obj.purchase_contract.registration_document);
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.mechanical_condition);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.body_condition);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.paint_condition);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.interior_condition);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.obj.purchase_contract.vehicle_status.electronic_condition);
    this.total_condition = this.obj.purchase_contract.vehicle_status.getOverallCondition();
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.obj.purchase_contract.registration_document);
    this.billStatus = getBillStatusLabel(this.obj.status);
    this.billStatusStyle = getBillStatusLabelStyle(this.obj.status);
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }
}
