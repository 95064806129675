import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css'],
  animations: [DropdownIconArrowAnimation],
  providers: [NumberFormatPipe]
})
export class BasicDataComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  public brands: VehicleBrand[] = [];
  public models: VehicleModel[] = [];
  public drives: VehicleDrive[] = [];
  public categories: VehicleCategory[] = [];
  public bodyTyps: VehicleBodyTyp[] = [];
  public titleArray: string[] = [];
  public minDate: string;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public counterVersion = 80;

  public brandValues = [];
  public modelValues = [];
  public bodytypValues = [];
  public categorieValues = [];

  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public showDropdownFive = false;
  public inseratDisable = false;

  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;

  @Output() updateVehicleTitle = new EventEmitter<string>();

  // @ViewChild('picker1') datePicker_1 = MatDatepicker;
  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  @ViewChild('dropdown_4') dropdown_4: ElementRef;
  @ViewChild('dropdown_5') dropdown_5: ElementRef;
  public listener: () => void;

  public hailDamageValues = [];
  public hailDamageValue;

  constructor(
    private renderer: Renderer2,
    private vService: VehicleService,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.hailDamageValues = this.getEnumValues('hail_damage');
    this.hailDamageValue = this.getEnumValue('hail_damage', this.vehicle.hail_damage);
    if(this.form){
      this.inseratDisable = this.authService.isHoedlAdmin() ? false : this.form.get('inserat_checked').value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode || changes.formGroupVehicle){
      if(this.editMode && this.form != null){
        this.form.get('inserat_checked').valueChanges.subscribe(value => {
          this.inseratDisable = this.authService.isHoedlAdmin() ? false : value;
        });
      }
    }
    if(this.editMode){
      this.requestDataServerside();
      this.titleArray = [this.vehicle.brand.makeName + ', ', this.vehicle.model.name  + ', ', this.vehicle.model.version];
      this.counterVersion = 80 - this.f.version?.value.length;
      this.calcRegistrationDate();
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownOne = false;
        }
      }
      if(this.showDropdownTwo){
        let isInside = this.dropdown_2.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownTwo = false;
        }
      }
      if(this.showDropdownThree){
        let isInside = this.dropdown_3.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownThree = false;
        }
      }
      if(this.showDropdownFour){
        let isInside = this.dropdown_4.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownFour = false;
        }
      }
      if(this.showDropdownFive){
        let isInside = this.dropdown_5.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdownFive = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  requestDataServerside() {
    forkJoin([
      this.vService.getBrands(),
      this.vService.getDrives(),
      this.vService.getCategories(),
      this.vService.getBodyTyps()
    ]).subscribe(([brands, drives, categories, bodyTypes]:[VehicleBrand[], VehicleDrive[], VehicleCategory[], VehicleBodyTyp[]]) => {
      if(brands && drives && categories &&  bodyTypes){
        this.brands = brands;
        this.drives = drives;
        this.categories = categories;
        this.bodyTyps = bodyTypes;
        if(this.form){
          for(let brand of brands){
            if(brand?.id == this.f.brand.value?.id){
              this.models = brand.models;
            }
          }
        }
        this.sortValues();
        this.setDropdownValues();
      }
    });
  }

  getEnumDataOnChange() {
    this.hailDamageValue = this.getEnumValue('hail_damage', this.form.controls['hail_damage'].value);
  }

  sortValues() {
    this.brands.sort((a,b) => {
      if(a.makeName.toLowerCase() < b.makeName.toLowerCase()) return -1;
      if(a.makeName.toLowerCase() > b.makeName.toLowerCase()) return 1;
      return 0;
    })
    let iBrand = this.brands.findIndex(m => m.makeName.toLowerCase() == "sonstige");
    if(iBrand){
      let temp = this.brands[iBrand];
      this.brands.splice(iBrand,1);
      this.brands.push(temp);
    }
    this.models.sort((a,b) => {
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })
    let iModel = this.models.findIndex(m => m.name.toLowerCase() == "sonstige");
    if(iModel){
      let temp = this.models[iModel];
      this.models.splice(iModel,1);
      this.models.push(temp);
    }
  }

  setDropdownValues() {
    this.brandValues = [];
    this.modelValues = [];
    this.bodytypValues = [];
    this.categorieValues = [];
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyTyps){
      this.bodytypValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.categories){
      this.categorieValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
    } else if(value === 'model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
    } else if(value === 'bodytyp'){
      let index = this.bodyTyps.findIndex(v => v.id == id);
      if(index > -1) this.f.bodyTyp.setValue(this.bodyTyps[index]);
    } else if(value === 'category'){
      let index = this.categories.findIndex(v => v.id == id);
      if(index > -1){
        this.f.category.setValue(this.categories[index]);
        this.f.firstRegistrationDate.setValue(null);
        this.calcRegistrationDate();
      }
    }
  }

  get f() {
    return this.form?.controls;
  }

  setBrand(brand: VehicleBrand) {
    this.f.brand.setValue(brand);
    this.models = brand.models;
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.f.model.setValue(null);
    this.titleArray[0] = brand.makeName + ', ';
    this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
  }

  setModel(model: VehicleModel) {
    this.f.model.setValue(model);
    this.titleArray[1] = model.name + ', ';
    this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
  }

  setVersionTitle() {
    let count = 80 - this.f.version?.value.length;
    this.counterVersion = count >= 0 ? count : 0;
    if(this.counterVersion >= 0){
      this.counterVersion = 80 - this.f.version?.value.length;
      this.titleArray[2] = this.f.version?.value;
      this.updateVehicleTitle.emit(this.titleArray[0] + this.titleArray[1] + this.titleArray[2]);
    }
  }

  // setBodyTyp(typ: VehicleBodyTyp) {
  //   this.f.bodyTyp.setValue(typ);
  // }

  setDrive(drive: VehicleDrive) {
    this.f.drive.setValue(drive);
  }

  setCategory(cat: VehicleCategory) {
    this.f.category.setValue(cat);
    this.f.firstRegistrationDate.setValue(null);
    this.calcRegistrationDate();
  }

  setAccident(name: string) {
    if(name == 'Ja'){
      this.f.unfall.setValue(true);
    } else {
      this.f.unfall.setValue(false);
    }
  }

  closeDatePicker1(eventData: any, dp?:any) {
    this.f.manufactureDate.setValue(eventData);
    dp.close();
  }
  closeDatePicker2(eventData: any, dp?:any) {
    this.f.firstRegistrationDate.setValue(eventData);
    dp.close();
  }

  checkFirstRegistration() {
    if(this.f.firstRegistrationDate.value){
      if(this.f.firstRegistrationDate.value < this.f.manufactureDate.value){
        this.f.firstRegistrationDate.setValue(null);
      }
    }
  }

  calcRegistrationDate() {
    if(this.f.category.value.id == 1){
      this.f.firstRegistrationDate.setValue(null);
    } else if(this.f.category.value.id == 6){
      let t = new Date();
      t.setMonth(t.getMonth() - 12);
      this.minDate = formatDate(t,'yyyy-MM-dd','de');
    } else if(this.f.category.value.id == 3) {
      let t = new Date();
      t.setMonth(t.getMonth() - 24);
      this.minDate = formatDate(t,'yyyy-MM-dd','de');
    } else {
      this.minDate = formatDate(new Date(0),'yyyy-MM-dd','de');
    }
  }

  // validateDateInputLong(c: FormControl): any {
  //   const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
  //   if(c.value && c.value._f == 'DD.MM.YYYY'){
  //     if(date_regex.test(c.value._i)){
  //       let today = new Date();
  //       let values = c.value._i.split('.');
  //       let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
  //       if(input.getTime() < today.getTime()){
  //         return null;
  //       }
  //     }
  //   } else if(c.value && moment.isMoment(c.value)){
  //     return null;
  //   } else if(c.value == null){
  //     return null;
  //   }
  //   return {
  //     validateDateInput: {
  //       valid: false
  //     }
  //   }
  // }

  validDate(value: any) {
    let date = value;
    if(date && !moment.isMoment(date)){
      date = moment(date);
      return null;
    }
    const date_regex = /^\d{2}\/\d{4}$/;
    if(date && date_regex.test(date._i)){
      let strArs = date._i.split('/');
      if(Number(strArs[0]) <= 12 && Number(strArs[0]) >= 1){
        date.set({'year': strArs[1], 'month': Number(strArs[0]) - 1})
        if(date.isValid()){
          return null;
        }
      }
    } else if(date == null) {
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }
}
