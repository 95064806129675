<div id="slider_container" *ngIf="show">
  <div id="slider_close_icon" (click)="closeSlider()">
    &#10005;
    <!-- <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-570.5 -1251.5)">
        <path d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
        <path d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
      </g>
    </svg> -->
  </div>
  <div id="slider_inner_container"  [ngStyle]="{'justify-content': true ? 'space-between' : 'center'}">
    <div class="slider_arrow" [ngStyle]="{'visibility': sliderSliceFrom != 0 ? 'visible' : 'hidden' }" (click)="previousPicture()">
      <!-- <img class="slider_arrow_left" src="../../../../../assets/arrowblack.svg"> -->
      <svg class="slider_arrow_left" xmlns="http://www.w3.org/2000/svg" width="8.678" height="15.637" viewBox="0 0 8.678 15.637">
        <path d="M150.859,15.637a1,1,0,0,1-.715-.3l-6.678-6.819a1,1,0,0,1,0-1.4L150.144.3a1,1,0,0,1,1.43,1.4l-5.993,6.12,5.993,6.118a1,1,0,0,1-.715,1.7" transform="translate(-143.182 0)" fill="#000000"/>
      </svg>
    </div>
    <div class="slider_image_wrapper">
      <img id="test_image" [src]="base64Array[sliderSliceFrom]">
        <!-- <img class="slider_full_image" *ngFor="let src of base64Array | slice:sliderSliceFrom:sliderSliceTo" [src]="src"> -->
    </div>
    <div class="slider_arrow" [ngStyle]="{'visibility': sliderSliceTo != base64Array.length ? 'visible' : 'hidden' }" (click)="nextPicture()">
      <svg class="slider_arrow_right" xmlns="http://www.w3.org/2000/svg" width="8.678" height="15.637" viewBox="0 0 8.678 15.637">
        <path d="M150.859,15.637a1,1,0,0,1-.715-.3l-6.678-6.819a1,1,0,0,1,0-1.4L150.144.3a1,1,0,0,1,1.43,1.4l-5.993,6.12,5.993,6.118a1,1,0,0,1-.715,1.7" transform="translate(-143.182 0)" fill="#000000"/>
      </svg>
    </div>
  </div>
</div>
