import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Attachment, AttachmentHelper, AttachmentType } from 'src/app/buisness-object/attachment/Attachment';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrl: './file.component.css'
})
export class FileComponent implements OnInit, OnDestroy {
  @Input() attachment: Attachment;
  @Input() index: number;
  @Input() isLast: boolean;
  @Output() successDeleteEmitter = new EventEmitter<any>();
  public showActions = false;
  public colorClass;
  public dialogQuerySubsription: Subscription;
  public identifier;

  constructor(
    private attachmentService: AttachmentService,
    private dialogService: DialogService
  ){}

  ngOnInit(): void {
    this.identifier = 'attachment-wrapper_'+this.attachment.attachment_id;
    this.colorClass = AttachmentHelper.getColorClass(this.attachment.typ);
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_attachment_'+this.identifier) this.deleteAttachement();
      }
    });
  }

  selectionDetails() {
    const mimeTyp = this.attachment.mimetyp.toLowerCase().trim();
    if(mimeTyp == 'jpg' ||
      mimeTyp == 'png' ||
      mimeTyp == 'jpng'
    ) {
      this.dialogService.OpenImageSlider({base64Array: ['data:image/png;base64,'+this.attachment.data.trim()], startIndex: 0});
    } else if(mimeTyp == 'pdf' || mimeTyp == 'docx') {
      this.dialogService.openAttachementViewer({ pdf: this.attachment.data, isArrayBuffer: false, mime_typ: mimeTyp });
    }
  }

  selectionDownload() {
    this.attachmentService.downloadAttachment(this.attachment);
  }

  selectionDelete() {
    this.dialogService.openQuery(
      {
        title: 'Anhang löschen',
        message: 'Sind sie sicher, dass Sie diesen Anhang unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_attachment_'+this.identifier,
      }
    );
  }

  deleteAttachement() {
    this.attachmentService.deleteAttachment(this.attachment.attachment_id).subscribe((result) => {
      if(result){
        this.successDeleteEmitter.emit(this.attachment);
      }
    })
  }
}
