import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, QueryList, Sanitizer, ViewChildren, AfterViewInit, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterContentChecked, AfterViewChecked, AfterContentInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VehiclePictureFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehiclePictureFactory';
import { VehiclePicture } from 'src/app/buisness-object/vehicle/optional/VehiclePicture';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag, CdkDragEnter, CdkDragMove, CdkDropListGroup, CdkDropList} from "@angular/cdk/drag-drop";
import {ViewportRuler} from "@angular/cdk/overlay";
import { Observable, Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { DragAndDrop } from 'src/app/helpers/DragAndDrop';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { FormGroup } from '@angular/forms';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { CloneObject } from 'src/app/helpers/CloneObject';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})

//https://medium.com/@tarekabdelkhalek/how-to-create-a-drag-and-drop-file-uploading-in-angular-78d9eba0b854

export class ImagesComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterViewChecked, OnDestroy, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() editMode: boolean;
  @Input() formGroupVehicle: FormGroup;
  @Input() selectedDetailsTab: number;
  public gallerySwiper: VehiclePicture[] = [];
  public gallery: VehiclePicture[] = [];
  public titlePicture: VehiclePicture;
  public position = 0;
  public uploadProgress = 0;
  public filesAmount = 0;
  public currentFile = 0;
  public onloadSrcs: string[] = [];
  public imagesLoading = false;
  public imageRequestSubs: Subscription[] = [];

  public showSlider = false;
  public sliderSliceFrom = 0;
  public sliderSliceTo = 0;
  //https://material.angular.io/cdk/drag-drop/examples

  constructor(
    private vService: VehicleService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.editMode){
      let wrapperBox = document.getElementById('overview-images-wrapper');
      if(wrapperBox){
        wrapperBox.setAttribute('style', 'padding-right: 10px;')
      }
    }
  }

  ngAfterContentChecked(): void {
    DragAndDrop.registerHandler('.drag_item_container .drag_item');
  }

  ngAfterViewChecked(): void {
    if(this.editMode && GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop){
      let dragItem = DragAndDrop.getDragItem(this.gallery);
      let dropItem = DragAndDrop.getDropItem(this.gallery);
      if(dragItem && dropItem){
        dragItem.position = dropItem.position;
        GlobalVariables.drag_item_drag = undefined;
        GlobalVariables.drag_item_drop = undefined;
        this.updateImagePostition(dragItem);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['vehicle'] && changes['vehicle'].currentValue && this.selectedDetailsTab == 1){
      this.vehicle = changes['vehicle'].currentValue;
      this.titlePicture = null;
      this.gallery = [];
      this.gallerySwiper = [];
      setTimeout(() => {
        this.getDataServerSide();
      }, 400);
    }
    if(changes['editMode']){
      if(this.editMode){
        let wrapperBox = document.getElementById('overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 10px;')
      } else {
        let wrapperBox = document.getElementById('overview-images-wrapper');
        if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 0px;')
      }
    }
  }

  ngOnDestroy(): void {
    for(let imgSub of this.imageRequestSubs){
      if(imgSub) imgSub.unsubscribe();
    }
  }

  async getDataServerSide(){
    this.titlePicture = null;
    this.gallery = [];
    this.gallerySwiper = [];
    for(let i = 0; i < this.vehicle.pictures.length; i++){
      this.imagesLoading = true;
      this.imageRequestSubs.push(
        this.vService.getVehiclePicture(this.vehicle.pictures[i].id).subscribe((data) => {
          if(data && this.vehicle?.pictures[i] != null){
            this.vehicle.pictures[i].vehicle_id = this.vehicle.id;
            let src = 'data:image/png;base64,'+data.trim();
            let picture = new VehiclePicture(this.vehicle.pictures[i].id, this.vehicle.pictures[i].position, this.vehicle.pictures[i].vehicle_id, src);
            if(this.vehicle.pictures[i].position == 1){
              this.titlePicture = picture;
            } else {
              this.gallery.push(picture);
            }
            this.gallerySwiper.push(picture);
            this.sortGalleryAfterPosition();
            if(i == this.vehicle.pictures.length -1) this.imagesLoading = false;
          }
        })
      )
    }
  }

  async getDataAferUploadServerside(pictures: VehiclePicture[]){
    this.gallery = [];
    this.gallerySwiper = [];
    for(let pic of pictures){
      this.vService.getVehiclePicture(pic.id).subscribe((data) => {
        if(data){
          pic.vehicle_id = this.vehicle.id;
          pic.src = 'data:image/png;base64,'+data.trim();
          if(pic.position == 1) this.titlePicture = pic;
          else this.gallery.push(pic);
          this.gallerySwiper.push(pic);
        }
      });
    }
    this.sortGalleryAfterPosition();
  }

  sortGalleryAfterPosition(){
    this.gallery.sort((a,b) => {
      if(a.position > b.position) return 1;
      if(a.position < b.position) return -1;
      return 0
    })
    this.gallerySwiper.sort((a,b) => {
      if(a.position > b.position) return 1;
      if(a.position < b.position) return -1;
      return 0
    });
  }


  async uploadFile(event: any): Promise<any> {
    this.currentFile = 0;
    this.onloadSrcs = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFiles(event.target.files[i]);
      }
      await this.uploadImgToServer();
      if(!this.vehicle.thumbnail){
        this.vService.getVehicleThumbnail(this.titlePicture.id).subscribe((result) => {
          if(result){
            this.vehicle.thumbnail = 'data:image/png;base64,'+result.trim();
          }
        })
      }
    }
  }

  async readFiles(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.onloadSrcs.push(event.target.result as string);
        resolve(true)
      }
      reader.readAsDataURL(files);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async uploadImgToServer(): Promise<any> {
    for(let src of this.onloadSrcs){
      await this.createVehiclePicture(src);
    }
    this.vehicle.pictures = this.gallerySwiper;
    this.formGroupVehicle.controls['pictures'].setValue(this.gallerySwiper);
  }

  createVehiclePicture(src: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.vService.createVehiclePicture(this.vehicle.id, src.split(',')[1]).subscribe((event: HttpEvent<any>) => {
        let response = event as HttpResponse<any>;
        if(response.body){
          this.currentFile += 1;
          let picture = new VehiclePicture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,this.vehicle.id, src);
          if(response.body.pictures[response.body.pictures.length - 1].position != 1) {
            this.gallery.push(picture);
          } else {
            this.titlePicture = picture;
          }
          this.gallerySwiper.push(picture);
          resolve(true);
        }
        // switch (event.type) {
        //   case HttpEventType.UploadProgress:
        //     let gesamtsatz = this.filesAmount * 100;
        //     let progress = Math.round((event.loaded / event.total) * 100);
        //     let lProzentSatz = Math.round(progress * gesamtsatz);
        //     let result = lProzentSatz / gesamtsatz;
        //     this.uploadProgress = this.uploadProgress > result ? this.uploadProgress : result;
        //   case HttpEventType.Response:
        //     setTimeout(() => {
        //       if(this.uploadProgress >= 100 && this.currentFile == this.filesAmount){
        //         this.uploadProgress = 0;
        //         this.currentFile = 0;
        //         this.filesAmount = 0;
        //         this.vehicle.pictures = this.gallery;
        //       }
        //     }, 200);
        //     break;
        //   default: break;
        // }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  async onFileDropped(data: any) {
    // this.filesAmount += 1;
    if(data){
      for(let d of data.data){
        await this.createVehiclePicture(d.content);
      }
    }
    this.vehicle.pictures = this.gallerySwiper;
    this.formGroupVehicle.controls['pictures'].setValue(this.gallerySwiper);
  }



  setAsTitle(pic: VehiclePicture) {
    this.updateImage(pic);
  }

  updateImage(pic: VehiclePicture) {
    let imgToUpdate = CloneObject.deepCopy(pic);
    let currentTitleImg = CloneObject.deepCopy(this.titlePicture);
    imgToUpdate.position = 1;
    this.vService.updateVehiclePicture(imgToUpdate).subscribe((response) => {
      if(response){
        for(let i = 0; i < this.gallery.length; i++){
          if(this.gallery[i].id == imgToUpdate.id){
            let temp = CloneObject.deepCopy(this.gallery[i]);

            this.gallery[i] = currentTitleImg;
            this.gallery[i].position = temp.position;

            this.titlePicture = imgToUpdate;
            break;
          }
        }
        this.gallerySwiper = [];
        for(let i of this.gallery){
          this.gallerySwiper.push(CloneObject.deepCopy(i));
        }
        this.gallerySwiper.unshift(CloneObject.deepCopy(this.titlePicture));
        this.vService.getVehicleThumbnail(this.titlePicture.id).subscribe((response) => {
          if(response){
            this.vehicle.thumbnail = 'data:image/png;base64,'+response.trim();
            this.formGroupVehicle.controls['thumbnail'].setValue(this.vehicle.thumbnail);
            DragAndDrop.registerHandler('.drag_item_container .drag_item');
          }
        });
        this.vehicle.pictures = this.gallerySwiper;
        this.formGroupVehicle.controls['pictures'].setValue(this.gallerySwiper);
      }
    });
  }

  removeImage(pic: VehiclePicture) {
    this.vService.deletePicture(pic.vehicle_id, pic.id).subscribe((success) => {
      if(success){
        let indexGallery = this.gallery.findIndex(p => p.id == pic.id);
        if(indexGallery > -1) this.gallery.splice(indexGallery, 1);
        let index = this.vehicle.pictures.findIndex(p => p.id == pic.id);
        if(index > -1) this.vehicle.pictures.splice(index, 1);
        if(this.titlePicture?.id == pic.id){
          if(this.gallery.length > 0){
            this.titlePicture = this.gallery[0];
            this.gallery.splice(0, 1);
            this.setAsTitle(this.titlePicture);
          } else {
            this.titlePicture = null;
          }
        };
        if(this.vehicle.pictures.length == 0){
          this.vehicle.thumbnail = null;
          this.formGroupVehicle.controls['thumbnail'].setValue(null);
        }
        for(let i = 0; i < this.gallerySwiper.length; i++){
          if(pic.id == this.gallerySwiper[i].id){
            this.gallerySwiper.splice(i, 1);
            break;
          }
        }
        this.formGroupVehicle.controls['pictures'].setValue(this.gallerySwiper);
      }
    })
  }

  updateImagePostition(pic: VehiclePicture) {
    this.vService.updateVehiclePicture(pic).subscribe((response) => {
      if(response){
        for(let data of response.pictures){
          for(let pic of this.gallery){
            if(data.picture_id == pic.id){
              pic.position = data.position;
            }
          }
        }
        this.sortGalleryAfterPosition();
        this.formGroupVehicle.controls['pictures'].setValue(this.gallerySwiper);
       }
    })
  }

  createArray(amount: number): Array<number> {
    return new Array(amount);
  }

  getProgress(): string {
    return this.uploadProgress + '%';
  }

  openSlider(pic: VehiclePicture) {
    if(this.editMode) {
      return;
    }
    let index = this.gallerySwiper.findIndex(p => p.id == pic.id);
    let base64Array = [];
    this.gallerySwiper.forEach(element => {
      base64Array.push(element.src);
    });
    this.dService.OpenImageSlider({base64Array: base64Array, startIndex: index});
  }

  closeSlider() {
    this.showSlider = false;
  }

  nextPicture() {
    if(this.sliderSliceTo != this.gallerySwiper.length){
      this.sliderSliceFrom++;
      this.sliderSliceTo++;
    }
  }

  previousPicture() {
    if(this.sliderSliceFrom != 0){
      this.sliderSliceFrom--;
      this.sliderSliceTo--;
    }
  }

  openSingleImage(pic: VehiclePicture) {
    this.dService.OpenImageSlider({base64Array: [pic.src], startIndex: 0});
  }
}
