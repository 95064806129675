import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from 'src/app/buisness-object/location/Location';
import { Role } from 'src/app/buisness-object/employee/Role';
import { AuthGuard } from 'src/app/helpers/AuthGuard';


@Component({
  selector: 'app-edit-user-container',
  templateUrl: './edit-user-container.component.html',
  styleUrls: ['./edit-user-container.component.css'],
  animations: [
    trigger('rotate', [
      state('default', style({
        transform: 'rotate(0deg)'
      })),
      state('rotated', style({
        transform: 'rotate(180deg)'
      })),
      transition('default => rotated', [
        animate('0.2s'),
      ]),
      transition('rotated => default', [
        animate('0.2s')
      ]),
    ]),
  ]
})

export class EditUserContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  public dropLocationSelected = false;
  public rolesInvalid = false;
  public locationTxt: string = "";
  public authentication = Number(localStorage.getItem('auth'));
  public authGuard = new AuthGuard();
  @Input() employee: Employee;
  @Input() locations: Location[];
  @Input() editFormGroup: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() roles: string[];
  @ViewChild('dropdownInputContainer') dropdownInputContainer: ElementRef;
  public listener: () => void;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.setFormGroupValue();
    this.locationTxt = this.getLocation(this.employee.locationId);
    this.employee.auth = this.authGuard.getAuthentication(this.employee);
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click', (e:Event) => {
      if(this.dropLocationSelected){
        let isInside = this.dropdownInputContainer.nativeElement.contains(e.target);
        if(!isInside){
          this.dropLocationSelected = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  setFormGroupValue() {
    this.f.firstName.setValue(this.employee.firstName);
    this.f.lastName.setValue(this.employee.lastName);
    this.f.locationId.setValue(this.employee.location.locationId);
    this.f.username.setValue(this.employee.username);
    this.f.active.setValue(this.employee.active);
    this.f.roles.setValue(this.employee.roles);
  }

  get f() {
    return this.editFormGroup.controls;
  }

  getInitials(): string {
    return this.employee.firstName.substring(0,1) + this.employee.lastName.substring(0,1);
  }

  getLocation(id: number): string {
    for(let loc of this.locations){
      if(loc.locationId == id){
        return loc.city + " " + loc.postCode;
      }
    }
  }

  setActive() {
    this.f.active.setValue(!this.f.active.value);
  }

  hasRole(id: number): boolean {
    if(id == 2 && this.employee.auth == 1){
      return true;
    }
    return id == this.employee.auth;
  }

  setRole(role: Role) {
    this.employee.auth = role.roleId;
  }

  administerRole(role: Role) {
    this.employee.auth = role.roleId;
    this.employee.roles = this.authGuard.getAuthRoles(role.roleId);
    this.f.roles.setValue(this.employee.roles);
  }

  addLocation(id: number) {
    this.locationTxt = this.getLocation(id);
    this.f.locationId.setValue(id);
  }
}
