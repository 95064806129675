import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { Address } from 'src/app/buisness-object/customer/Adress';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Salutation } from 'src/app/buisness-object/customer/Salutation';
import { Title } from 'src/app/buisness-object/customer/Title';
import { AuthGuard } from 'src/app/helpers/AuthGuard';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Location } from '@angular/common';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import * as moment from 'moment';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { environment } from 'src/environments/environment';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { validBirthdate, validDatePast } from 'src/app/validators/CustomValidators';
import { DocumentService } from 'src/app/service/document/document.service';
import { DateHelper } from 'src/app/utils/DateHelper';
import { DokumentTyp } from '../dokumente/dokumente.component';

const KW_MULTIPLICATOR = 0.73549875;
const PS_MULTIPLICATOR = 1.36;

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit, OnDestroy {
  public surface = 1;
  public authGuard = new AuthGuard();
  public customers: Customer[] = [];
  public customersFiltered: Customer[] = [];
  public titles: Title[] = [];
  public salutations: Salutation[] = [];
  public isCompany = false;
  public selectedCustomer: (Customer|undefined) = undefined;
  public customerFormGroup: UntypedFormGroup;
  public isCompanyFormGroup: UntypedFormGroup;
  public submitted = false;
  public dataprivacy = false;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public roles: any;
  public routeSubscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public dialogCreateDocSubscription: Subscription;
  public dialogMoveSubscription: Subscription;
  public dialogAttachementViewerSubscription: Subscription;
  public searchValue = '';
  public accessEdit = false;
  public customerCarForm: UntypedFormGroup;
  public submittedCreateCustomerCar = false
  public isLoaded = false;
  public openedViewFromDetails = false;
  public employees: Employee[] = [];
  public pdf: any;
  public showSurfaceOverview = 1;
  public filter = {
    status: 0,
    typ: null,
    is_set: false
  }
  public sortObject = {
    sort_after_value: 1,
    sort_asc: false,
    sorting_is_set: false
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cService: CustomerService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private locationRoute: Location,
    private dService: DialogService,
    private employeeService: EmployeeService,
    private attachmentService: AttachmentService,
    private vService: VehicleService,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.roles = JSON.parse(localStorage.getItem('auth_roles'));
    this.getDataServerside();
    this.buildFormGroups();
    this.accessEdit = this.authGuard.hasAccess(4);
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogCreateDocSubscription) this.dialogCreateDocSubscription.unsubscribe();
    if(this.dialogMoveSubscription) this.dialogMoveSubscription.unsubscribe();
    if(this.dialogAttachementViewerSubscription) this.dialogAttachementViewerSubscription.unsubscribe();
  }

  getDataServerside() {
    forkJoin({
      customers: this.cService.getCustomer(),
      titles: this.cService.getTitles(),
      salutations: this.cService.getSalutations(),
      employees: this.employeeService.getEmployees(),
    }).subscribe((result) => {
      if(result){
        this.customers = result.customers;
        this.titles = result.titles;
        this.salutations = result.salutations;
        this.employees = result.employees;
        this.customersFiltered = this.customers;
        this.mergeData();
        this.applySorting();
        this.setPages();
        this.routeListener();
        this.isLoaded = true;
      }
    })
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete'){
          this.deleteCustomer(value.submit_value);
        }
      }
    });
    this.dialogCreateDocSubscription = this.dService.closeDialogCreateDoc$.subscribe((value) => {
      if(value) this.createDocument(value.data, value.submit_value)
    })
    this.dialogMoveSubscription = this.dService.closeDialogMove$.subscribe((value) => {
      if(value) this.moveCustomer(value.data, value.submit_value)
    })
    this.dialogAttachementViewerSubscription = this.dService.closeAttachmentViewer_$.subscribe((value) => {
      if(value){
        this.signPDF();
      }
    })
  }

  mergeData() {
    for(let customer of this.customers){
      for(let employee of this.employees){
        if(customer.employee_id && customer.employee_id == employee.id){
          customer.employee = employee;
          break;
        }
      }
    }
  }

  sortCustomers() {
    this.customersFiltered.sort((a,b) => {
      if(a.id > b.id) return 1
      if(a.id < b.id) return -1;
      return 0;
    })
  }

  setPages() {
    this.pageHandler = new PageHandler(this.customersFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  routeListener() {
    // let params = this.activateRoute.snapshot.queryParams;
    // if(params['threadId']){
    //   for(let data of this.threads){
    //     if(data.threadId == Number(params['threadId'])){
    //       this.openDetails(data);
    //       break;
    //     }
    //   };
    // }
    this.routeSubscription = this.activateRoute.url.subscribe((url) => {
      let id = Number(url[1]?.path);
      if(!isNaN(id)){
        for(let data of this.customers){
          if(data.id == id){
            this.openDetails(data);
            break;
          }
        };
      } else {
        switch (url[1]?.path) {
          case 'create': this.openCreate(this.activateRoute.snapshot.queryParams.typ); break;
          case 'edit': for(let data of this.customers){
                          if(data.id == this.activateRoute.snapshot.queryParams.customerId){
                            this.openEdit(data);
                            break;
                          }
                       };
                       break;
          case undefined: if(this.activateRoute.snapshot.queryParams.customerId){
                        for(let data of this.customers){
                          if(data.id == this.activateRoute.snapshot.queryParams.customerId){
                            this.openDetails(data);
                            break;
                          }
                        };
                      };
                      break;
          default: break;
        }
      }
    });
  }

  async openDetails(customer: any) {
    this.selectedCustomer = customer;
    let a = [];
    this.vService.getVehicleByCustomer(this.selectedCustomer.id).subscribe((vehicles) => {
      if(vehicles){
        this.selectedCustomer.vehicles = vehicles;
      }
    })
    for(let attachment of customer.attachments){
      if(attachment.attachment_id && attachment.attachment_id != 0){
        a.push(await this.getAttachment(attachment.attachment_id));
      }
    }
    customer.attachments = a;
    this.surface = 3;
    this.locationRoute.replaceState('/customers?customerId='+customer.id);
  }

  getAttachment(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.attachmentService.getAttachment(id).subscribe((result) => {
        if(result){
          resolve(result);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  openCreate(isCompany: boolean) {
    this.selectedCustomer = null;
    this.buildFormGroups();
    this.isCompany = isCompany;
    this.surface = 2;
    this.locationRoute.replaceState('/customers/create?typ='+(this.isCompany ? 1 : 2));
  }

  openEdit(customer: Customer) {
    if(customer){
      this.selectedCustomer = customer;
    }
    this.locationRoute.replaceState('/customers/edit?customerId=' + (this.selectedCustomer ? this.selectedCustomer.id : customer.id));
    this.surface = 2;
  }

  buildFormGroups() {
    this.customerFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: [null],
      salutation: [null, Validators.required],
      birthdate: [null, [validBirthdate]],
      email: ['', [Validators.required,Validators.email]],
      phone: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: [null, [Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}'), Validators.min(1)]],
      city: ['', [Validators.required]],
      dataprivacyAccepted: [false, [Validators.required, Validators.requiredTrue]],
      advertismentAccepted: [false, Validators.required],
      typ: [null, Validators.required],
      status: [null, Validators.required],
      employee: [null],
      country: ["Österreich"],
      is_pep: [false],
    });

    this.isCompanyFormGroup = this.formBuilder.group({
      atuNum: [''],
      companyName: ['', Validators.required]
    });
  }

  createCustomerCarForm(customerCar?: Vehicle){
    this.customerCarForm = this.formBuilder.group({
      customer_id: [customerCar ? customerCar.customer_id : 0],
      brand: [customerCar ? customerCar.brand : null,Validators.required],
      model: [customerCar ? customerCar.brand : null,Validators.required],
      version: [customerCar ? customerCar.brand : null,[Validators.required, Validators.maxLength(80)]],
      drive: [customerCar ? customerCar.brand : null,Validators.required],
      transmission: [customerCar ? customerCar.brand : null,Validators.required],
      fuel: [customerCar ? customerCar.brand : null,Validators.required],
      power: [customerCar ? customerCar.brand : null,Validators.required],
      powerTyp: [customerCar ? (customerCar.powerPs ? 1 : 2) : 1],
      bodyTyp: [customerCar ? customerCar.bodyTyp : null,Validators.required],
      bodyColor: [customerCar ? customerCar.bodyColor : null,Validators.required],
      category: [customerCar ? customerCar.category : null,Validators.required],
      numOfDoors: [customerCar ? customerCar.numOfDoors : null,Validators.required],
      numOfSeats: [customerCar ? customerCar.numOfSeats : null,Validators.required],
      unfall: [customerCar ? customerCar.unfall : null,Validators.required],
      mileageKm: [customerCar ? customerCar.mileageKm : null,Validators.required],
      firstRegistrationDate: [customerCar ? customerCar.firstRegistrationDate : null, this.validateDateInputLong],
      vehicle: [null],
    });
  }

  get f() {
    return this.customerFormGroup.controls;
  }

  changeSurface(surface: number) {
    if(surface == 3 && !this.selectedCustomer) {
      this.surface = 1;
      this.submitted = false;
      return;
    }
    if(surface == 1){
      this.router.navigate(['/customers']);
      this.selectedCustomer = null;
      this.submitted = false;
    }
    this.surface = surface;
    this.locationRoute.replaceState('/customers');
  }

  cancelEdit() {
    this.submitted = false;
    if(this.selectedCustomer != null){
      this.surface = 3;
      this.locationRoute.replaceState('/customers?customerId='+this.selectedCustomer.id);
    } else {
      this.surface = 1;
      this.selectedCustomer = null;
      this.locationRoute.replaceState('/customers');
    }
  }

  saveCustomer() {
    this.submitted = true;
    if(this.f.typ.value == 1){
      if(this.customerFormGroup.invalid || this.isCompanyFormGroup.invalid){
        return;
      }
    } else if(this.customerFormGroup.invalid){
      return;
    }
    this.submitted = false;
   // let bDate = this.f.birthdate.value ? new Date(this.f.birthdate.value).getTime() : null;
    let customer = new Customer(
      0,
      Number(localStorage.getItem('location_id')),
      new ContactPerson(
        0,
        this.f.firstName.value,
        this.f.lastName.value,
        this.f.email.value,
        this.f.phone.value,
        this.f.title.value ? this.f.title.value.name : 'Kein Titel',
        this.f.salutation.value.id,
        DateHelper.convertDateStringToTimestamp(this.f.birthdate.value),
        this.f.is_pep.value
        ),
      new Address(
        0,
        this.f.street.value,
        String(this.f.postalCode.value),
        this.f.city.value,
        this.f.country.value
      ),
      this.f.dataprivacyAccepted.value,
      this.f.advertismentAccepted.value,
      this.f.typ.value,
      this.f.status.value,
      this.isCompanyFormGroup.controls.atuNum.value ? this.isCompanyFormGroup.controls.atuNum.value : '',
      this.isCompanyFormGroup.controls.companyName.value ? this.isCompanyFormGroup.controls.companyName.value : '',
    );
    if(this.customerFormGroup.controls.employee.value){
      customer.employee = this.customerFormGroup.controls.employee.value;
      customer.employee_id = this.customerFormGroup.controls.employee.value.id;
    } else {
      customer.employee_id = Number(localStorage.getItem('employee_id'));
    }
    if(!this.selectedCustomer){
      this.cService.createCustomer(customer).subscribe((result) => {
        if(result){
          if(customer.employee) result.employee = customer.employee;
          this.customers.push(result);
          this.selectedCustomer = result;
          this.applySorting();
          this.setPages();
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kunde wurde angelegt.',
            success: true
          });
          this.surface = 3;
        } else {
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: 'Ein unbekannter Fehler ist aufgetreten. Versuchen Sie ers erneut oder wenden Sie sich bitte an den Support.',
            success: false
          });
        }
      });
    } else {
      customer.id = this.selectedCustomer.id;
      customer.contactPerson.id = this.selectedCustomer.contactPerson.id;
      customer.address.addressId = this.selectedCustomer.address.addressId;
      this.cService.updateCustomer(customer).subscribe((result) => {
        if(result){
          customer.lastUpdated = result.lastUpdated;
          if(customer.employee) result.employee = customer.employee;
          this.selectedCustomer = customer;
          this.updateListLocal(customer);
          this.applyFilter();
          this.locationRoute.replaceState('/customers?customerId='+customer.id);
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kunde wurde aktualisiert.',
            success: true
          });
          this.surface = 3;
        } else {
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: 'Ein unbekannter Fehler ist aufgetreten. Versuchen Sie ers erneut oder wenden Sie sich bitte an den Support.',
            success: false
          });
        }
      });
    }
  }

  updateListLocal(customer: Customer) {
    for(let i = 0; i < this.customers.length; i++){
      if(this.customers[i].id == customer.id){
        this.customers[i] = customer;
      }
    }
    this.customersFiltered = this.customers;
  }

  deleteAction(customer: Customer) {
    this.dService.openQuery(
      {
        title: 'Kunde wirklich löschen?',
        message: 'Sind sie sicher, dass Sie den Kunden ' + (customer.companyName ? customer.companyName : customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName) +' unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete',
        submit_value: customer.id,
      }
    );
  }

  deleteCustomer(id: number){
    this.cService.deleteCustomer(id).subscribe((success) => {
      if(success){
        for(let i = 0; i < this.customers.length; i++){
          if(this.customers[i].id == id){
            this.customers.splice(i, 1);
            let index = this.customersFiltered.findIndex(customer => customer.id == id);
            if(index != -1){
              this.customersFiltered.splice(index, 1);
            }
            break;
          }
        }
      }
      this.setPages();
    })
  }

  moveCustomer(customer: Customer, status: number) {
    let data = CloneObject.deepCopy(customer);
    data.status = status;
    this.cService.updateCustomer(data).subscribe((result) => {
      if(result){
        this.updateListLocal(result);
        this.applyFilter();
        this.setPages();
        if(data.status == 1){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Als Kunde gesetzt.',
            success: true
          });
        } else if(data.status == 2){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Als Interessent gesetzt.',
            success: true
          });
        } else if(data.status == 3){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kunde archiviert.',
            success: true
          });
        }

      }
    })
  }

  removeFilterValue(str: string) {
    this.filter = {
      status: this.filter.status,
      typ: str === 'typ' ? null : this.filter.typ,
      is_set: false
    }
    this.applyFilter();
  }

  applyFilter() {
    this.customersFiltered = [];
    this.customersFiltered = this.customers.filter(customer => {
      let found = true;
      if(!this.includesStatus(customer)) found = false;
      if(!this.includesTyp(customer)) found = false;
      return found;
    });
    this.applySorting();
    this.setPages();
    this.scrollTo();
    this.searchValue = '';
  }

  includesStatus(customer: Customer): boolean {
    if(!this.filter.status) return true;
    else if(customer.status == this.filter.status){
      return true;
    }
    else false;
  }
  includesTyp(customer: Customer): boolean {
    if(!this.filter.typ) return true;
    else if(customer.typ == this.filter.typ) return true;
    else false;
  }

  scrollTo() {
    const elementList = document.querySelectorAll('#listcontainer');
    const element = elementList[0] as HTMLElement;
    if(element){
      element.scrollTop = 0;
    }
  }

  pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  searchAction(searchValue: string) {
    if(searchValue && searchValue.length > 0){
      let temp = [];
      temp = this.customers.filter((customer) => {
        return customer.contactPerson.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.contactPerson.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.address.street.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.address.postalCode.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.address.city.toLowerCase().includes(searchValue.toLowerCase()) ||
               customer.id.toString().toLowerCase().includes(searchValue.toLowerCase())  ||
               customer.contactPerson.phone.toString().toLowerCase().includes(searchValue.toLowerCase());
     });
     this.customersFiltered = temp;
   } else {
     this.customersFiltered = this.customers;
   }
    this.applySorting();
    this.setPages();
  }

  validateDateInput(c: UntypedFormControl): any {
    const date_regex = /^\d{2}.\d{2}.\d{4}$/;
    if(c.value && c.value._f == 'DD.MM.YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('.');
        let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
        if(input.getTime() < today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }


  // filterDetails(filter?: number) {
  //   let query = {
  //     typ: this.filterForm.controls.typ.value
  //   }
  //   this.customersFiltered = this.customers;
  //   let result = this.customersFiltered.filter((item) => {
  //     for(let key in query){
  //       if(item[key] === undefined){
  //         return false;
  //       } else {
  //         if(query[key] && key == 'typ' && item[key] != query[key]){
  //           return false;
  //         }
  //       }
  //     }
  //     return true;
  //   })
  //   this.customersFiltered = result;
  //   if(filter || filter == Number(0)) this.filter = filter;
  //   switch (filter) {
  //     case 0: this.customersFiltered = this.customersFiltered; break;
  //     case 1: this.customersFiltered = this.customersFiltered.filter((customer) => {return customer.status == 1}); break;
  //     case 2: this.customersFiltered = this.customersFiltered.filter((customer) => {return customer.status == 2}); break;
  //     case 3: this.customersFiltered = this.customersFiltered.filter((customer) => {return customer.status == 3}); break;
  //     default: break;
  //   }
  //   this.setPages();
  // }

  resetFilterEmitter() {
    this.filter = {
      typ: null,
      status: null,
      is_set: false
    }
    this.applyFilter();
  }

  openCreateCustomerCar(customer?: Customer) {
    if(customer) this.selectedCustomer = customer;
    this.createCustomerCarForm();
    this.customerCarForm.controls.customer_id.setValue(this.selectedCustomer.id);
    this.surface = 4;
  }

  cancelCreateCustomerCar() {
    this.submitted = false;
    this.surface = 3;
  }

  createCustomerCar() {
    this.submittedCreateCustomerCar = true;
    if(this.customerCarForm.invalid && !this.customerCarForm.controls.vehicle.value){
      return;
    }
    this.submittedCreateCustomerCar = false;
    if(this.customerCarForm.controls.vehicle.value){
      let vehicle = this.customerCarForm.controls.vehicle.value;
      vehicle.customer_id = this.customerCarForm.controls.customer_id.value;
      this.vService.updateVehicle(vehicle).subscribe((result) => {
        if(result){
          this.selectedCustomer.vehicles.push(result);
          this.surface = 3;
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kundenfahrzeug erstellt.',
            success: true
          });
        }
      })
    } else {
      let model = this.customerCarForm.controls.model.value;
      model.version = this.customerCarForm.controls.version.value;
      let hp = 0;
      let kw = 0;
      if(this.customerCarForm.controls.powerTyp.value == 1){
        hp = this.customerCarForm.controls.power.value;
        kw = this.customerCarForm.controls.power.value * KW_MULTIPLICATOR;
      } else {
        kw = this.customerCarForm.controls.power.value;
        hp = this.customerCarForm.controls.power.value * PS_MULTIPLICATOR;
      }
      let vehicle = new Vehicle(
        0,
        Number(localStorage.getItem('location_id')),
        this.customerCarForm.controls.brand.value,
        model,
        null,
        this.customerCarForm.controls.fuel.value,
        hp,
        kw,
        this.customerCarForm.controls.bodyColor.value,
        this.customerCarForm.controls.bodyTyp.value,
        this.customerCarForm.controls.category.value,
        this.customerCarForm.controls.mileageKm.value,
        this.customerCarForm.controls.unfall.value,
        this.customerCarForm.controls.numOfDoors.value,
        this.customerCarForm.controls.numOfSeats.value,
        this.customerCarForm.controls.transmission.value,
        this.customerCarForm.controls.drive.value,
        null, //manufature date,
        this.customerCarForm.controls.firstRegistrationDate.value ? this.customerCarForm.controls.firstRegistrationDate.value.valueOf() : null,
        null,
        4
      );
      vehicle.customer_id = this.customerCarForm.controls.customer_id.value;
      this.vService.createVehicle(vehicle).subscribe((result) => {
        if(result){
          this.selectedCustomer.vehicles.push(result);
          this.surface = 3;
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kundenfahrzeug erstellt.',
            success: true
          });
        }
      })
    }
  }

  openCreateDokument(typ: number) {
    if(this.selectedCustomer ){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['documents/create'], { queryParams: { typ: typ, customerId: this.selectedCustomer.id } }));
    }
  }

  removeCustomerCar(vehicle: Vehicle) {
    vehicle.customer_id = null;
    vehicle.status = 1;
    this.vService.updateVehicle(vehicle).subscribe((result) => {
      if(result){
        let index = this.selectedCustomer.vehicles.findIndex(v => v.id == result.id);
        if(index > -1) this.selectedCustomer.vehicles.splice(index,1);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Fahrzeug vom Kunden entfernt.',
          success: true
        });
      }
    })
  }

  validateDateInputLong(c: UntypedFormControl): any {
    const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
    if(c.value && c.value._f == 'DD.MM.YYYY'){
      if(date_regex.test(c.value._i)){
        let today = new Date();
        let values = c.value._i.split('.');
        let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
        if(input.getTime() < today.getTime()){
          return null;
        }
      }
    } else if(c.value && moment.isMoment(c.value)){
      return null;
    } else if(c.value == null){
      return null;
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }

  applySorting() {
    // this.sortObject.sorting_is_set = true;
    this.customersFiltered.sort((a,b) => -(a.lastUpdated - b.lastUpdated));
    // if(this.sortObject.sort_after_value == 1){
    //   this.customersFiltered.sort((a,b) => {
    //     if(a.getName()?.toLowerCase() > b.getName()?.toLowerCase()) return this.sortObject.sort_asc ? 1 : -1;
    //     if(a.getName()?.toLowerCase() < b.getName()?.toLowerCase()) return this.sortObject.sort_asc ? -1 : 1;
    //     return 0;
    //   })
    // } else if(this.sortObject.sort_after_value == 2){
    //   this.customersFiltered.sort((a,b) => {
    //     if(a.lastUpdated > b.lastUpdated) return this.sortObject.sort_asc ? 1 : -1;
    //     if(a.lastUpdated < b.lastUpdated) return this.sortObject.sort_asc ? -1 : 1;
    //     return 0;
    //   })
    // }
  }

  resetSortEmitter() {
    this.sortObject.sort_after_value = 1;
    this.sortObject.sort_asc = false;
    this.sortObject.sorting_is_set = false;
    this.sortCustomers();
  }

  selectionCreateDocument(customer: Customer) {
    this.dService.openCreateDoc(
      { data : customer }
    );
  }
  createDocument(customer: Customer, typ: number) {
    if(typ == 1) this.goToDocumentCreate(customer, 2);
    else if(typ == 2) this.goToDocumentCreate(customer, 4);
    else if(typ == 3) this.getDatadataprivacy(customer, false);
  }
  goToDocumentCreate(customer: Customer, docTyp: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['documents/create'], { queryParams: { typ: docTyp, customerId: customer.id } }));
  }
  getDatadataprivacy(customer: Customer, openedViewFromDetails: boolean) {
    this.selectedCustomer = customer;
    this.openedViewFromDetails = openedViewFromDetails;
    this.cService.getDataPrivacy(customer.id).subscribe((data) => {
      if(data){
        //this.pdf = data;
        this.dService.openAttachementViewer({pdf: data, isArrayBuffer: true, custome_action: "App Signature"});
        //this.surface = 5;
      }
    })
  }
  selectionCancelPdfShow() {
    this.pdf = null;
    if(this.openedViewFromDetails){
      this.surface = 3;
    } else {
      this.surface = 1;
    }
  }

  selectionMove(customer: Customer) {
    this.dService.openMove(
      { customer : customer }
    );
  }

  signPDF(){
    let signature = {
      "typ" : 1,
      "reference_id" : this.selectedCustomer.id,
      "authorization_number": null,
      "authorization_authority": null,
      "authorization_date": null,
      "authorization_groups" : null,
      "trip_description": null,
    }
    this.documentService.createSignatureDocument(signature).subscribe((result) => {
      if(result){
        this.dService.openLoaderSignature({document_typ: DokumentTyp.Datenschutz});
      }
    });
  }
}

