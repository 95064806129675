import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleUpholstery } from 'src/app/buisness-object/vehicle/optional/VehicleUpholstery';
import { VehicleUpholsteryColor } from 'src/app/buisness-object/vehicle/optional/VehicleUpholsteryColor';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css'],
  animations: [DropdownIconArrowAnimation],
  providers: [NumberFormatPipe]
})
export class VehicleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() editMode: boolean;
  @Input() submitted: boolean;
  @Input() activeSubMenu: SubMenu;
  @Input() superGroup: number;

  public bodyColorValues = [];
  public upholsteryValues = [];
  public upholsteryColorValues = [];

  public bodyColors: VehicleBodyColor[] = [];
  public upholsteries: VehicleUpholstery[] = [];
  public upholsteryColors: VehicleUpholsteryColor[] = [];

  public inseratDisable = false;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  public listener: () => void;

  constructor(
    private vService: VehicleService,
    private renderer: Renderer2,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.requestDataServerside();
    if(this.formGroupVehicle){
      this.inseratDisable = this.authService.isHoedlAdmin() ? false : this.formGroupVehicle.get('inserat_checked').value;
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownThree && !this.dropdown_3.nativeElement.contains(e.target)) this.showDropdownThree = false;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode || changes.formGroupVehicle){
      if(this.editMode && this.formGroupVehicle != null){
        this.formGroupVehicle.get('inserat_checked').valueChanges.subscribe(value => {
          this.inseratDisable = this.authService.isHoedlAdmin() ? false : value;
        });
      }
    }
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  showValue(value: string): boolean {
    return this.showValueBase(this.authService, this.activeSubMenu, this.superGroup, "vehicle", this.editMode, value);
  }

  requestDataServerside() {
    forkJoin([
      this.vService.getBodyColor(),
      this.vService.getUpholsteries(),
      this.vService.getUpholsteryColors(),
    ]).subscribe(([bodyColors, upholsteries, upholsteryColors]:[VehicleBodyColor[], VehicleUpholstery[], VehicleUpholsteryColor[]]) => {
      if(bodyColors && upholsteries && upholsteryColors){
        this.bodyColors = bodyColors;
        this.upholsteries = this.sortArray(upholsteries);
        this.upholsteryColors = this.sortArray(upholsteryColors);
        this.setDropdownValues();
      }
    });
  }

  setDropdownValues() {
    this.bodyColorValues = [];
    this.upholsteryValues = [];
    this.upholsteryColorValues = [];
    for(let v of this.bodyColors){
      this.bodyColorValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.upholsteries){
      this.upholsteryValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.upholsteryColors){
      this.upholsteryColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'bodyColor'){
      let index = this.bodyColors.findIndex(v => v.id == id);
      if(index > -1) this.f.bodyColor.setValue(this.bodyColors[index]);
    } else if(value === 'upholstery'){
      let index = this.upholsteries.findIndex(v => v.id == id);
      if(index > -1) this.f.upholstery.setValue(this.upholsteries[index]);
    } else if(value === 'upholsteryColor'){
      let index = this.upholsteryColors.findIndex(v => v.id == id);
      if(index > -1) this.f.upholsteryColor.setValue(this.upholsteryColors[index]);
    }
  }

  sortArray(data: any[]): any[] {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    for(let i = 0; i < data.length; i++){
      if(data[i].name == 'Sonstige'){
        data.push(data[i]);
        data.splice((i),1);
      }
    }
    return data;
  }

  get f() {
    return this.formGroupVehicle.controls;
  }
}
