<div class="list_container">
    <div class="list_header">
      <div class="header_list_row_1">
        <div></div>
        <div class="page_wrapper">
          <div id="count">{{pageInfo}}</div>
          <div id="arrows" *ngIf="amount > maxAmountListPage">
            <img *ngIf="sliceFrom == 0" class="icon_arrow_left" src="../../../../../assets/arrowlight.svg">
            <img *ngIf="sliceFrom != 0" class="icon_arrow_left list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
            <img *ngIf="sliceTo == amount" class="icon_arrow_right" src="../../../../../assets/arrowlight.svg">
            <img *ngIf="sliceTo != amount"  class="icon_arrow_right list_arrow_active" src="../../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
          </div>
        </div>
      </div>
      <div class="header_list_row_2">
        <div class="header_list_row_2_inner">
          <label *ngIf="filter.typ" class="lbl_filter_value"><button (click)="removeFilterEmitter.emit('typ')">&#10005;</button>{{filter.typ == 1 ? 'Firma' : 'Private'}}</label>
        </div>
        <div></div>
        <div class="header_list_row_2_inner">
          <label *ngIf="sortObject.sort_after_value == 2" class="lbl_filter_value">Bearbeitet<img (click)="sortObject.sort_asc = !sortObject.sort_asc; applySortEmitter.emit()" [src]="sortObject.sort_asc ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
          <label *ngIf="sortObject.sort_after_value == 1" class="lbl_filter_value">Alphabetisch<img (click)="sortObject.sort_asc = !sortObject.sort_asc; applySortEmitter.emit()" [src]="sortObject.sort_asc ? '../../../../../assets/sortup.svg' : '../../../../../assets/sortdown.svg'"></label>
        </div>
      </div>
    </div>
    <div *ngIf="customers?.length != 0" id="container_title">
        <div class="lbl_list_title">Kunden-ID</div>
        <div class="lbl_list_title">Name</div>
        <div class="lbl_list_title">Typ</div>
        <div class="lbl_list_title">Status</div>
        <div class="lbl_list_title">Telefon</div>
        <div class="lbl_list_title">Bearbeitet</div>
    </div>
    <div id="listcontainer" class="list_element_container" [class.list_element_conatiner_empty]="customers.length == 0">
        <div class="list_no_elements lbl_regular_italic_light_color" *ngIf="customers?.length == 0">{{filter == 2 ? 'Keine Interessenten' : 'Keine Kunden'}}</div>
        <app-list-customer-element *ngFor="let customer of customers | slice:sliceFrom:sliceTo"
            [customer]="customer"
            [accessEdit]="accessEdit"
            (openDetailsEmitter)="openDetailsEmitter.emit($event)"
            (openEditEmitter)="openEditEmitter.emit($event)"
            (deleteEmitter)="deleteEmitter.emit($event)"
            (createCustomerCarEmitter)="createCustomerCarEmitter.emit($event)"
            (selectionCreateDokumentEmitter)="selectionCreateDokumentEmitter.emit($event)"
            (selectionMoveEmitter)="selectionMoveEmitter.emit($event)"
        ></app-list-customer-element>
    </div>
    <div class="list_footer"></div>
</div>
