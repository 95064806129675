import { CloneObject } from "src/app/helpers/CloneObject";
import { LedgerEntry } from "../LedgerEntry";

export class LedgerEntryFactory {

  static jsonFactory(rawBody: any[]): LedgerEntry[] {
    const data: LedgerEntry[] = [];
    rawBody = rawBody.sort((a, b) => {
      if(a.date == b.date) return a.ledger_entry_id - b.ledger_entry_id;
      return a.date - b.date;
    });
    let saldo_before = 0;
    for(let i = 0; i < rawBody?.length; i++){
      let entry = this.jsonFactoryOne(rawBody[i]);
      if(i > 0){
        entry.saldo_before = saldo_before;
      }
      entry.saldo = entry.saldo_before + entry.amount;
      saldo_before = entry.saldo;
      data.push(entry);
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): LedgerEntry {
      const obj = new LedgerEntry(
        rawPart.ledger_entry_id,
        rawPart.location_id,
        rawPart.date,
        rawPart.subject,
        rawPart.amount,
        rawPart.status,
        rawPart.purchase_contract_id,
      )
      return obj;
  }

  public static setSaldo(ledgeEntries: LedgerEntry[]): LedgerEntry[] {
    const data: LedgerEntry[] = [];
    ledgeEntries = ledgeEntries.sort((a, b) => {
      if(a.date == b.date) return a.ledger_entry_id - b.ledger_entry_id;
      return a.date - b.date;
    });
    let saldo_before = 0;
    for(let i = 0; i < ledgeEntries?.length; i++){
      if(i > 0){
        ledgeEntries[i].saldo_before = saldo_before;
      }
      ledgeEntries[i].saldo =  ledgeEntries[i].saldo_before +  ledgeEntries[i].amount;
      saldo_before =  ledgeEntries[i].saldo;
      data.push(CloneObject.deepCopy(ledgeEntries[i]));
    }
    return data;
  }
}
