import { CustomerFactory } from "../../customer/factory/CustomerFactory";
import { Bill } from "../Bill";
import { PerformancePeriod } from "../Offer";
import { BillItemFactory } from "./BillItemFactory";
import { KaufvertragFactory } from "./KaufvertragFactory";

export class BillFactory {
    static jsonFactory(rawBody: any): Bill[] {
        const data: Bill[] = [];
        for(let raw of rawBody){
          data.push(this.jsonFactoryOne(raw));
        }
        return data;
      }

      static jsonFactoryOne(raw: any): Bill {
          const obj = new Bill(
            raw.bill_id,
            raw.custom_bill_id,
            raw.type,
            raw.bill_date,
            raw.location_id,
            raw.customer_id,
            raw.payment_method,
            raw.status,
            raw.in_accounting,
            BillItemFactory.jsonFactory(raw.bill_item),
            raw.purchase_contract_id,
            raw.subject,
            raw.saved_bill_recipient_id,
            raw.reference_bill_id,
            raw.is_storno_bill,
            raw.custom_text,
          )
          if(raw.purchase_contract) obj.purchase_contract = KaufvertragFactory.jsonFactoryOne(raw.purchase_contract);
          if(raw.customer) obj.customer = CustomerFactory.jsonFactoryOne(raw.customer);
          if(raw.bill_recipient) obj.bill_recipient = CustomerFactory.jsonFactoryOne(raw.bill_recipient)
          obj.bill_recipient = obj.purchase_contract != null ? obj.purchase_contract.customer : obj.customer;
          obj.total_brutto = obj.getTotalBrutto();
          obj.total_netto = obj.total_brutto*0.8;
          return obj;
      }

      static jsonFactoryOnePeriod(rawPart: any): PerformancePeriod {
        const obj = new PerformancePeriod(
          rawPart.start_date,
          rawPart.end_date,
        )
        return obj;
      }
}
