<div class="topbar">
    <div class="topbar_inner"  *ngIf="surface == 1 || surface == 4 || surface == 6 || surface == 7">
      <button *ngIf="authService.ACCESS_ALL() || authService.ACCESS_FOUR()" class="button_type_1" (click)="openCreateEmitter.emit()">Neu</button>
      <div class="topbar_tab_wrapper">
        <button [class.button_tap_active]="surface == 1" (click)="changeSurfaceEmitter.emit(1);">User<span *ngIf="surface == 1"></span></button>
        <button [class.button_tap_active]="surface == 6" (click)="changeSurfaceEmitter.emit(6);">Archiv<span *ngIf="surface == 6"></span></button>
        <button [class.button_tap_active]="surface == 4" (click)="changeSurfaceEmitter.emit(4);">Standorte<span *ngIf="surface == 4"></span></button>
        <button *ngIf="authService.accessPricecalculation()" [class.button_tap_active]="surface == 7" (click)="changeSurfaceEmitter.emit(7);">Einstellungen<span *ngIf="surface == 7"></span></button>
      </div>
      <div *ngIf="surface != 7" class="input_dropdown_container">
        <input #search class="input_search" type="text" placeholder="Suche" (input)="searchEmitter.emit(search.value);">
      </div>
      <div *ngIf="surface == 7" class="btn-wrapper">
        <button class="btn_white" [disabled]="!settingsChanged" (click)="settingsVerwerfenEmitter.emit()">Verwerfen</button>
        <button class="btn_submit" (click)="settingsSaveEmitter.emit()">Änderungen speichern</button>
      </div>
    </div>
    <div class="topbar_inner" *ngIf="surface == 2">
        <div class="btn_cancel_container" (click)="changeSurfaceEmitter.emit(3)">
            <img class="icon_cancel" src="../../../../assets/cancle.svg">
            <button id="btn-cancel">Abbrechen</button>
        </div>
        <button class="btn_submit" (click)="saveEmployeeEmitter.emit()">Speichern</button>
    </div>
    <div class="topbar_inner_long" *ngIf="surface == 3">
        <div class="btn_cancel_container" (click)="changeSurfaceEmitter.emit(1)">
            <img class="icon_cancel" src="../../../../assets/back.svg">
            <button id="btn-cancel">Zurück</button>
        </div>
        <button *ngIf="authGuard.hasAccess(2)" class="btn_submit" (click)="editEmployeeEmitter.emit()">Bearbeiten</button>
    </div>
    <div class="topbar_inner_long" *ngIf="surface == 5">
      <div class="btn_cancel_container" (click)="cancleCreateEmitter.emit()">
          <img class="icon_cancel" src="../../../../assets/back.svg">
          <button id="btn-cancel">Zurück</button>
      </div>
      <!-- <div id="lbl-title">Detailansicht</div> -->
      <button class="btn_submit" (click)="createEmployeeEmitter.emit()">{{isEdit ? 'Speichern' : 'Anlegen'}}</button>
  </div>
</div>
