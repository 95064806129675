import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { MainMenuFactory } from 'src/app/buisness-object/menu/MainMenuFactory';
import { MasterMenu } from 'src/app/buisness-object/menu/EnumMasterMenu';
import { LoginService } from 'src/app/service/login/login.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { SubMenuFilter, SubMenuOperator } from 'src/app/buisness-object/menu/SubMenuFilter';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LedgerEntryFactory } from 'src/app/buisness-object/document/factory/LedgerEntryFactory';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { EnumService } from 'src/app/service/enum/enum.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { SearchHelper } from 'src/app/utils/SearchHelper';
import { LoadingService } from 'src/app/service/loading/loading.service';

export enum AccountingView {
  LIST = 1,
  BILLDETAILSPURCHASECONTRACT = 2,
  BILLDETAILS = 3,
  BILLEDITPURCHASECONTRACT = 4,
  BILLCREATE = 5,
}

export enum AccountingObject {
  LEDGERENTRY = 'ledger_entry',
}

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrl: './accounting.component.css'
})
export class AccountingComponent extends BaseComponent implements OnInit, OnDestroy {
  //dynamic menu
  public mainMenus: MainMenu[] = [];
  public _activeSubMenu: BehaviorSubject<SubMenu> = new BehaviorSubject<SubMenu>(null);
  public activeSubMenu$ = this._activeSubMenu.asObservable();
  public activeSubMenu: SubMenu;
  public _activeSortingColumn: BehaviorSubject<SubMenuColumn> = new BehaviorSubject<SubMenuColumn>(null);
  public activeSortingColumn$ = this._activeSortingColumn.asObservable();
  public activeSortingColumn: SubMenuColumn;
  //dynamic
  public objects: any[] = [];
  public _objectsFiltered: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public _objectsFiltered$ = this._objectsFiltered.asObservable();
  public activeFilter: any[] = [];
  public activeFilterOutside: any[] = [];
  public activeFilterInside: any[] = [];
  public searchValue;
  public searchTimeout: any;
  public pageHandler: PageHandler;

  public view = AccountingView;
  public showView: AccountingView = AccountingView.LIST;
  public showDropdownMainButton = false;
  public showDialogCreateKassabuch = false;
  public selectedObject;
  public dialogQuerySubsription: Subscription;
  public users: Employee[] = [];
  public additionalProducts: Zusatzprodukt[] = [];
  public isPreviousObjAvailable = false;
  public isNextObjAvailable = false;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private loginService: LoginService,
    private documentService: DocumentService,
    private dialogService: DialogService,
    private userService: EmployeeService,
    private loadingScreenService: LoadingService,
    private cdr: ChangeDetectorRef
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.getData();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getData() {
    this.loadingScreenService.startLoading();
    forkJoin({
      ledgerEntries: this.documentService.getLedgerEntries(),
      bills: this.documentService.getBills(),
      users: this.userService.getEmployees(),
      additional_products: this.documentService.getAdditionalProducts(),
    }).subscribe((result) => {
      if(result){
        this.objects = result.ledgerEntries;
        this.objects.push(...result.bills);
        this.users = result.users;
        this.additionalProducts = result.additional_products;
        this.mergeData();
        this.setUpMenu();
        this.activeSubMenu$.subscribe((active) => {
          if(active){
            this.activeSubMenu = active;
            this.activeFilter = [];
            this.activeFilterOutside = [];
            this.activeFilterInside = [];
            this.activeSortingColumn = null;
            this.rootFilter();
            this.setUpSearch();
          }
        });
        this.loadingScreenService.stopLoading();
      }
    });
  }

  mergeData() {
    const userMap = new Map<number, any>();
    for(let u of this.users) {
      userMap.set(u.id, u);
    }
    for(let d of this.objects) {
      if(d.purchase_contract != null) {
        const employee = userMap.get(d.purchase_contract.creator_employee_id);
        if(employee) d.purchase_contract.creator_employee = employee;
        let zusatzProduktIds = d.purchase_contract.selektierteZusatzprodukte.map((z) => z.zusatzprodukt_id);
        d.purchase_contract.verfuegbareZusatzprodukte = this.additionalProducts.filter((z) => !zusatzProduktIds.includes(z.zusatzprodukt_id));
      }
    }
  }

  //Standard dynamic menu

  setUpMenu() {
    let raw: any = JSON.parse(localStorage.getItem('menus'));
    if(raw == undefined || raw == null ){
      this.loginService.logout();
      return;
    }
    this.mainMenus = MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.LEDGERENTRY);
    this.mainMenus.push(...MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.BILL));
    for(let i = 0; i < this.mainMenus.length; i++){
      if(i == 0) this.mainMenus[i].setActive(true);
      this.mainMenus[i].sub_menus[0]?.setActive(true);
    }
    for(let mainMenu of this.mainMenus){
      mainMenu.active$.subscribe((active) => {
        if(active){
          for(let otherMainMenu of this.mainMenus){
            if(otherMainMenu.main_menu_id != mainMenu.main_menu_id){
              otherMainMenu.setActive(false);
            }
          }
          if(mainMenu.sub_menus.length > 0){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu.getActive()){
                this._activeSubMenu.next(otherSubMenu);
              }
            }
          }
        }
      });
      for(let subMenu of mainMenu.sub_menus){
        subMenu.active$.subscribe((active) => {
          if(active){
            for(let otherSubMenu of mainMenu.sub_menus){
              if(otherSubMenu != subMenu){
                otherSubMenu.setActive(false);
              }
            }
            this._activeSubMenu.next(subMenu);
          }
        });
      }
    }
    this._activeSubMenu.next(this.mainMenus[0]?.sub_menus[0]);
  }
  rootFilter() {
    let activeSubMenu = this.mainMenus.filter((m) => m.getActive())[0].sub_menus.filter((s) => s.getActive())[0];
    if(activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
      let sumFilter: any[] = activeSubMenu.filter;
      if(this.activeFilter.length > 0) {
        sumFilter = sumFilter.concat([new SubMenuOperator("&&")]).concat(this.activeFilter);
      }
      this._objectsFiltered.next(this.objects.filter((obj) => this.solveFilter(sumFilter, obj, activeSubMenu.columns[0]?.data_object)));
    } else if(this.activeFilter.length > 0) {
      this._objectsFiltered.next(this.objects.filter((obj) => this.solveFilter(this.activeFilter, obj, activeSubMenu.columns[0]?.data_object)));
    } else {
      this._objectsFiltered.next(this.objects.filter((obj) => this.solveObject(obj, activeSubMenu.columns[0]?.data_object)));
    }
    this.applySearch(this._objectsFiltered.getValue());
    this.rootSorting();
    this.setPages();
  }
  solveObject(object: any, data_object: string): boolean {
    switch (data_object) {
      case 'vehicle': return object instanceof Vehicle; break;
      case 'ledger_entry': return object instanceof LedgerEntry; break;
      case 'purchase_contract': return object instanceof Kaufvertrag; break;
      case 'bill': return object instanceof Bill; break;
      case 'drive': return object instanceof Drive; break;
      default: return false; break;
    }
  }
  solveFilter(originalFilters: any[], object: any, data_object: string): boolean {
    let filters = JSON.parse(JSON.stringify(originalFilters));
    if(!this.solveObject(object, data_object)) return false;
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].data_key) {
        filters[i] = this.objectIsValidForFilter(filters[i], object);
      } else if(Array.isArray(filters[i])) {
        filters[i] = this.solveFilter(filters[i], object, data_object);
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '&&') {
        filters[i + 1] = filters[i -1] && filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    for(let i = 0; i < filters.length; i++) {
      if(filters[i].operator && filters[i].operator == '||') {
        filters[i + 1] = filters[i -1] || filters[i + 1];
        filters.splice(i - 1, 2);
        i = i - 1;
      }
    }
    return filters[0];
  }
  objectIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
    let value: any = this.getObjectValueFromDataKey(filter.data_key, object);
    if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
      return this.compareNumber(value, filter);
    } else if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
      return this.compareBoolean(value, filter);
    } else if(filter.data_typ == "ENUM" || filter.data_typ == "ENUM_TAG") {
      return this.compareEnum(value, filter);
    } else {
      return this.compareString(value, filter);
    }
  }
  compareNumber(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = Number(value);
    let rawValue;
    //Special date condition
    if(filter.raw_value && String(filter.raw_value).includes("NOW-")){
      let miliseconds = Number(filter.raw_value.split("-")[1]);
      if(!isNaN(miliseconds)){
        rawValue = new Date().getTime()-miliseconds;
      }
    }
    if(rawValue == undefined || rawValue == null) rawValue = Number(filter.raw_value);
    if(Number.isNaN(numberValue)) {
      return false;
    }
    if(filter.operator == "<>") {
      return numberValue != rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else if (filter.operator == ">") {
      return numberValue > rawValue;
    } else if (filter.operator == ">=") {
      return numberValue >= rawValue;
    } else if (filter.operator == "<") {
      return numberValue < rawValue;
    } else if(filter.operator == "<=") {
      return numberValue <= rawValue;
    } else {
      return false;
    }
  }
  compareBoolean(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let numberValue = value == null ? null: (value == "true" || value == "1" || value == true || value == 1);
    let rawValue = filter.raw_value == null ? null: (filter.raw_value == "true" || filter.raw_value == "1");
    if(filter.operator == "<>") {
      return numberValue != rawValue;
    } else if(filter.operator == "=") {
      return numberValue == rawValue;
    } else {
      return false;
    }
  }
  compareEnum(value: any, filter: SubMenuFilter): boolean {
    if(filter.raw_value == "NULL") {
      if(filter.operator == "<>") {
        return value != undefined && value != null;
      } else if(filter.operator == "=") {
        return value == undefined || value == null;
      } else {
        return false;
      }
    }
    let id: any = null;
    if(typeof value === 'object') {
      id = value == null ? null: value.id;
    } else {
      id = value;
    }
    let rawValue = filter.raw_value;
    if(filter.operator == "<>") {
      return id != rawValue;
    } else if(filter.operator == "=") {
      return id == rawValue;
    } else {
      return false;
    }
  }
  compareString(value: any, filter: SubMenuFilter): boolean {
    if(filter.operator == '<>') {
      if(filter.raw_value == "NULL") {
        return value != undefined && value != null;
      } else {
        return String(value) != filter.raw_value;
      }
    } else if(filter.operator == '=') {
      if(filter.raw_value == "NULL") {
        return value == undefined || value == null;
      } else {
        return String(value).toLowerCase().includes(filter.raw_value.toLowerCase());
      }
    } else {
      console.log("Warning: Wrong operator in filter");
      return false;
    }
  }
  getObjectValueFromDataKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for(let i = 0; i < keys.length; i++){
      value = value[keys[i]];
      if(value == undefined || value == null){
        break;
      }
    }
    return value;
  }
  setUpSearch() {
    setTimeout(() => {
      const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
      if(searchInput != null){
        if(this.searchValue != null){
          searchInput.value = this.searchValue;
          this.rootFilter();
        }
        searchInput.addEventListener('input', (event: InputEvent) => {
          if(this.searchTimeout) clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchValue = searchInput.value;
            this.rootFilter();
          }, 500);
        });
      }
      const searchInputReset = document.getElementById('ab-search-list-input-reset') as HTMLInputElement;
      if(searchInputReset){
        searchInputReset.addEventListener('click', (event: InputEvent) => {
          if(this.searchValue != null){
            const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
            if(searchInput){
              searchInput.value = null;
              this.searchValue = null;
              this.rootFilter();
            }
          }
        });
      }
    }, 200);
  }
  applySearch(objects: any[]) {
    if(this.searchValue && this.searchValue.length > 0) {
      let temp = [];
      temp = objects.filter((obj) => {
        if(obj instanceof LedgerEntry){
          return SearchHelper.isMatch(obj.ledger_entry_id, this.searchValue) ||
          SearchHelper.isMatch(obj.subject, this.searchValue);
        } else if(obj instanceof Bill){
          return SearchHelper.isMatch(obj.custom_bill_id, this.searchValue) ||
          SearchHelper.isMatch(obj.purchase_contract?.vehicle?.externalId, this.searchValue) ||
          SearchHelper.isMatch(obj.purchase_contract?.vehicle?.fin_number, this.searchValue) ||
          SearchHelper.isMatch(obj.subject, this.searchValue) ||
          SearchHelper.isMatch(obj.customer?.getFullName(), this.searchValue);
        }
      });
      this._objectsFiltered.next(temp);
    }
  }
  rootSorting() {
    let activeSub = this._activeSubMenu.getValue();
    if(this.activeSortingColumn == null && activeSub != null){
      for(let c of this._activeSubMenu.getValue().columns){
        if(c.sub_menu_column_id == activeSub.default_sort_column_id){
          this.activeSortingColumn = c;
          this.activeSortingColumn.sortingActive = true;
          this.activeSortingColumn.ascend = activeSub.default_sort_direction == 'ASC' ? true : false;
          break;
        }
      }
      if(this.activeSortingColumn == null){
        let index = this.activeSubMenu.columns.findIndex((c) => c.data_key == 'externalId');
        if(index > -1) this.activeSortingColumn = this.activeSubMenu.columns[index];
        else {
          this.activeSortingColumn = new SubMenuColumn(0,"id",0,"document",0,"id",false,false,1,null,null,null,null,"NUMBER",null);
        }
        this.activeSortingColumn.sortingActive = true;
        this.activeSortingColumn.ascend = false;
      }
    }
    if(this.activeSortingColumn != null && this.activeSortingColumn.sortingActive){
      this._objectsFiltered.getValue().sort((a,b) => {
        if (this.activeSortingColumn.sort_function) {
          if(this.activeSortingColumn.ascend) {
            return a[this.activeSortingColumn.sort_function](b) * -1;
          } else {
            return a[this.activeSortingColumn.sort_function](b);
          }
        }
        let value1;
        let value2;
        if(typeof a[this.activeSortingColumn.data_key] == 'function'){
          value1 = a[this.activeSortingColumn.data_key]();
          value2 = b[this.activeSortingColumn.data_key]();
        } else if(this.activeSortingColumn.data_key.includes('.')) {
          let deepObjKey = this.activeSortingColumn.data_key.split('.')[0];
          let deepFunctionORPara = this.activeSortingColumn.data_key.split('.')[1];
          let deepValueA = a[deepObjKey];
          let deepValueB = b[deepObjKey];
          if(deepValueA && typeof deepValueA[deepFunctionORPara] == 'function'){
            value1 = deepValueA[deepFunctionORPara]();
            value2 = deepValueB[deepFunctionORPara]();
          } else {
            value1 = deepValueA[deepFunctionORPara];
            value2 = deepValueB[deepFunctionORPara];
          }
        } else {
          value1 = this.getValueFromObjKey(this.activeSortingColumn.data_key, a);
          value2 = this.getValueFromObjKey(this.activeSortingColumn.data_key, b);
        }
        if(value1 == null) return this.activeSortingColumn.ascend ? -1 : 1;
        if(value2 == null) return this.activeSortingColumn.ascend ? 1 : -1;
        if(this.activeSortingColumn.data_typ == "STRING"){
          if(value1 != null && value2 != null && isNaN(value1) && isNaN(value2)){
            return this.activeSortingColumn.ascend ? value1?.localeCompare(value2 ? value2 : '') : value2?.localeCompare(value1 ? value1 : '');
          }
        }
        return this.activeSortingColumn.ascend ? (value1 - value2) : (value2 - value1);
      });
    }
  }
  getValueFromObjKey(data_key: string, obj: any): any {
    let keys = data_key.split('.');
    let value = obj;
    for(let i = 0; i < keys.length; i++){
      value = value[keys[i]];
      if(value == undefined || value == null){
        break;
      }
    }
    return value;
  }
  applySorting(column: SubMenuColumn) {
    //for reference
    if(this.activeSortingColumn.sub_menu_column_id != column.sub_menu_column_id){
      this.activeSortingColumn.sortingActive = false;
      this.activeSortingColumn.ascend = false;
    }
    this.activeSortingColumn = column;
    this.activeSortingColumn.setSortActive();
    this.rootSorting();
  }
  setPages() {
    this.pageHandler = new PageHandler(this._objectsFiltered.getValue().length);
  }
  pageHandling(next: boolean) {
    this.pageHandler.action(next);
  }

  customFilterOutside(filterValue: any) {
    this.activeFilterOutside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilter();
  }

  customFilter(filterValue: any) {
    this.activeFilterInside = filterValue;
    if(this.activeFilterInside.length > 0 && this.activeFilterOutside.length > 0){
      this.activeFilter = this.activeFilterInside.concat([new SubMenuOperator("&&")]).concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    } else {
      this.activeFilter = this.activeFilterInside.concat(this.activeFilterOutside.length > 0 ? [this.activeFilterOutside] : []);
    }
    this.rootFilter();
  }

  objIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
    let value: any = this.getValueFromObjKey(filter.data_key, object);
    if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
      return this.compareNumber(value, filter);
    } else if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
      return this.compareBoolean(value, filter);
    } else if(filter.data_typ == "ENUM" || filter.data_typ == "ENUM_TAG") {
      return this.compareEnum(value, filter);
    } else {
      return this.compareString(value, filter);
    }
  }

  //Component specific

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_ledger_entry') this.deleteObject(value.submit_value);
        else if(value.typ == 'delete_bill') this.deleteObject(value.submit_value);
      }
    });
  }

  selectionDelete(object: any) {
    if(object) this.selectedObject = object;
    if(this.selectedObject instanceof LedgerEntry){
      this.openDialogDeleteLedgerEntry();
    } else if(this.selectedObject instanceof Bill){
      this.openDialogDeleteBill();
    }
  }

  openDialogDeleteLedgerEntry() {
    this.dialogService.openQuery(
      {
        title: 'Eintrag löschen',
        message: 'Sind sie sicher, dass Sie diesen Eintrag unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_ledger_entry',
        submit_value: this.selectedObject,
      }
    );
  }

  openDialogDeleteBill() {
    this.dialogService.openQuery(
      {
        title: 'Rechnung löschen',
        message: 'Sind sie sicher, dass Sie diese Rechnung unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_bill',
        submit_value: this.selectedObject,
      }
    );
  }

  deleteObject(object: any) {
    if(object instanceof LedgerEntry){
      this.documentService.deleteLedgerEntry(object.ledger_entry_id).subscribe((result) => {
        if(result){
          this.updateObjectLocal(object, 'delete');
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Kassabuch Eintrag wurde gelöscht.',
            success: true
          });
        }
      })
    }
  }

  createLedgeEntrySuccess(ledgerEntry: LedgerEntry) {
    this.updateObjectLocal(ledgerEntry, 'create');
    this.showDialogCreateKassabuch = false;
  }

  updateLedgeEntrySuccess(ledgerEntry: LedgerEntry) {
    this.updateObjectLocal(ledgerEntry, 'update');
    this.showDialogCreateKassabuch = false;
  }

  updateObjectLocal(obj: any, updateTyp: string) {
    if(updateTyp == 'create'){
      if(obj instanceof LedgerEntry){
        this.objects.push(obj);
        let temp = LedgerEntryFactory.setSaldo(this.objects);
        this.objects = temp;
        this.showView = AccountingView.LIST;
        this.selectedObject = null;
      } else if(obj instanceof Bill){
        this.objects.push(obj);
        this.selectedObject = obj;

        this.showView = AccountingView.BILLDETAILS;
      }
    } else if(updateTyp == 'update'){
      if(obj instanceof LedgerEntry){
        let index = this.objects.findIndex((v) => v.ledger_entry_id == obj.ledger_entry_id);
        if(index > -1){
          this.objects[index] = obj;
        }
        let temp = LedgerEntryFactory.setSaldo(this.objects);
        this.objects = temp;
        this.showView = AccountingView.LIST;
        this.selectedObject = null;
      } else if(obj instanceof Bill){
        let index = this.objects.findIndex((v) => v.bill_id == obj.bill_id);
        if(index > -1){
          this.objects[index] = obj;
        }
        this.selectedObject = obj;
        if(this.showView == AccountingView.BILLEDITPURCHASECONTRACT){
          this.showView = AccountingView.BILLDETAILSPURCHASECONTRACT;
        } else {
          this.showView = AccountingView.BILLDETAILS;
        }
      }
    } else if(updateTyp == 'delete'){
      if(obj instanceof LedgerEntry){
        let index = this.objects.findIndex((v) => v.ledger_entry_id == obj.ledger_entry_id);
        if(index > -1){
          this.objects.splice(index,1);
        }
        let temp = LedgerEntryFactory.setSaldo(this.objects);
        this.objects = temp;
        this.showView = AccountingView.LIST;
        this.selectedObject = null;
      }
    }
    this.rootFilter();
  }

  selectionDetails(obj: any) {
    this.selectedObject = obj;
    this.isPreviousObjAvailable = this.getIsPreviousObjAvailable();
    this.isNextObjAvailable = this.getIsNextObjAvailable();
    if(obj instanceof Bill){
      if(obj.purchase_contract_id != null) this.showView = AccountingView.BILLDETAILSPURCHASECONTRACT;
      else this.showView = AccountingView.BILLDETAILS;
    }
  }

  selectionCloseDetails(){
    this.selectedObject = null;
    this.showView = AccountingView.LIST;
  }

  selectionEdit(obj: any) {
    if(!this.selectedObject) this.selectedObject = obj;
    if(obj instanceof LedgerEntry){
      this.showDialogCreateKassabuch = true;
    } else if(obj instanceof Bill) {
      if(obj.purchase_contract_id != null){
        this.showView = AccountingView.BILLEDITPURCHASECONTRACT;
      } else {
        this.showView = AccountingView.BILLCREATE;
      }
    }
  }

  selectionCancelEditPruchaseContract() {
    this.showView = AccountingView.BILLDETAILSPURCHASECONTRACT;
  }

  successUpdateBillPurchaseContract(obj: any) {
    this.updateObjectLocal(obj, 'update');
  }

  selectionCreateBill() {
    this.selectedObject = null;
    this.showView = AccountingView.BILLCREATE;
  }

  selectionCancelCreate() {
    if(this.selectedObject){
      this.showView = AccountingView.BILLDETAILS;
    } else {
      this.selectedObject = null;
      this.showView = AccountingView.LIST;
    }
  }

  successCreateBill(obj: Bill) {
    this.updateObjectLocal(obj, 'create');
  }

  selectionEditBill(obj: Bill) {
    this.selectedObject = obj;
    this.showView = AccountingView.BILLCREATE;
  }

  successUpdateBill(obj: Bill){
    this.updateObjectLocal(obj, 'update');
  }

  successCancelBill(obj: Bill) {
    this.updateObjectLocal(obj, 'update');
  }

  successRenewBill(data: any) {
    const cancelBill = data?.canceled;
    const createdBill = data?.created;
    if(cancelBill && createdBill){
      this.updateObjectLocal(cancelBill, 'update');
      this.updateObjectLocal(createdBill, 'create');
    }
  }

  successPaidBill(obj: Bill) {
    this.updateObjectLocal(obj, 'update');
  }

  selectionPreviousObj(obj: any) {
    const currentIndex = this._objectsFiltered.getValue().indexOf(obj);
    if(currentIndex > 0) {
      this.selectionDetails(this._objectsFiltered.getValue()[currentIndex - 1]);
    }
  }

  selectionNextObj(obj: any) {
    const currentIndex = this._objectsFiltered.getValue().indexOf(obj);
    if(currentIndex < this._objectsFiltered.getValue().length - 1) {
      this.selectionDetails(this._objectsFiltered.getValue()[currentIndex + 1]);
    }
  }

  getIsPreviousObjAvailable(): boolean {
    const currentIndex = this._objectsFiltered.getValue().indexOf(this.selectedObject);
    if((currentIndex-1) > 0) {
      return true;
    }
    return false;
  }

  getIsNextObjAvailable(): boolean {
    const currentIndex = this._objectsFiltered.getValue().indexOf(this.selectedObject);
    if((currentIndex+1) < this._objectsFiltered.getValue().length - 1) {
      return true;
    }
    return false;
  }
}
