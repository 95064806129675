import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Rent } from 'src/app/buisness-object/rent/Rent';


@Component({
  selector: 'app-dialog-miete-document',
  templateUrl: './dialog-miete-document.component.html',
  styleUrl: './dialog-miete-document.component.css'
})
export class DialogMieteDocumentComponent implements OnInit {

  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter();

  ngOnInit(): void {
    
  }
}
