<div id="list-element" class="list_hover_element">
    <label class="lbl_list_data list_item_pointer_event" (click)="openDetailsEmitter.emit(employee)">{{employee.id}}</label>
    <div id="description-container" class="flex list_item_pointer_event" (click)="openDetailsEmitter.emit(employee)">
      <div id="user_initials" class="lbl_list_data">{{getInitials()}}</div>
      <label class="lbl_list_data">{{employee.firstName}} {{employee.lastName}}</label>
    </div>
    <label class="lbl_list_data">{{employee.username}}</label>
    <label class="lbl_list_data">{{employee.role}}</label>
    <label class="lbl_list_data">{{employee.location?.street}}, {{employee.location?.postCode}} {{employee.location?.city}}</label>
    <label [ngClass]="employee.active ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{employee.active ? 'Aktiv' : 'Inaktiv'}}</label>
    <div class="list_element_action_container">
      <!-- <button class="btn_list_details" (click)="openDetailsEmitter.emit(employee)">
        <img class="btn_list_details_icon" src="../../../../../assets/eye.svg">
        <span>Details</span>
      </button> -->
      <div class="btn_action_wrapper">
        <img #dropdownImg class="btn_actions" src="../../../../../assets/navdots.svg" (click)="showDropdown = !this.showDropdown"  [ngClass]="showDropdown ? 'btn_actions_active' : ''">
          <div #dropdownOptions class="dropdown_actions_container" *ngIf="showDropdown">
              <div class="option" (click)="openDetailsEmitter.emit(employee)">
                <div class="list_action_value_lbl">Details</div>
                <img class="list_action_value_icon" src="../../../../../assets/eye-white.svg" >
              </div>
              <div *ngIf="authGuard.hasAccess(2)" class="option" (click)="openEditEmitter.emit(employee)">
                  <div class="dropdown_txt">Bearbeiten</div>
                  <img class="option_icon" src="../../../../../assets/edit.svg" >
              </div>
              <!-- <div *ngIf="authGuard.hasAccess(2)" class="option" (click)="deleteEmitter.emit(employee)">
                <div class="dropdown_txt" >Löschen</div>
                <img class="option_icon" src="../../../../../assets/delete.svg" >
              </div> -->
              <div *ngIf="!authGuard.hasAccess(2)" class="option_none">
                  <div class="dropdown_txt">Keine Optionen</div>
              </div>
          </div>
      </div>
    </div>
</div>
