import { Employee } from './../../../buisness-object/employee/Employee';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { MessageConverter } from 'src/app/helpers/MessageConverter';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-messages-create',
  templateUrl: './messages-create.component.html',
  styleUrls: ['./messages-create.component.css'],
  animations: [DropdownIconArrowAnimation]
})

export class MessagesCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  public showDropdown1 = false;
  public showDropdown2 = false;
  public selectedTyp: number;
  public selectedCustomer: Customer;
  public selectedEmployee: Employee;
  public selectedVehicle: Vehicle;
  public showDropdownUser = false;
  public showDropdownUserIfValue = false;
  public employeesFiltered: Employee[] = [];
  public customersFiltered: Customer[] = [];
  public vehiclesFiltered: Vehicle[] = [];
  // public messageUnconverted = '';
  // public messageConverted = '';
  // public addingUser = false;
  // public messageBoxHasFocus = false;
  // public countAddedEmployess = 0;
  // public firstTouch = true;
  // public removeKey = false;

  // public posTopUserBox = 445;
  // public posLeftUserBox = 150;
  // public countLineBreaks = 0;
  // public lastRowTxtWith: number[] = [];


  private boundKeyDownCallback: EventListener;

  @Input() employees: Employee[];
  @Input() currentUser: Employee;
  @Input() threadForm: UntypedFormGroup;
  @Input() customers: Customer[];
  @Input() customerId: number;
  @Input() vehicleId: number;
  @Input() submitted: boolean;
  @Input() vehicles: Vehicle[];
  @Output() submitEmitter = new EventEmitter<undefined>();

  @ViewChild('dropdown1') dropdown1: ElementRef;
  @ViewChild('dropdown2') dropdown2: ElementRef;

  @ViewChild('input_employee') input_employee: ElementRef;
  @ViewChild('input_customer') input_customer: ElementRef;
  @ViewChild('input_vehicle') input_vehicle: ElementRef;

  @ViewChild('textField') textField: ElementRef;
  @ViewChild('test') test: ElementRef;
  public listenerFn: () => void;
  public listenerDrop1: () => void;
  public listenerDrop2: () => void;


  constructor(
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.employeesFiltered = this.employees;
    this.customersFiltered = this.customers;
    this.vehiclesFiltered = this.vehicles;
    this.setDropdownCloseListener();
    if(this.customerId){
      this.setTyp(2);
      this.setCustomer(this.customerId);
    } else if(this.vehicleId){
      this.setTyp(1);
      this.setVehicle(this.vehicleId);
    }
  }

  ngAfterViewInit(): void {
    if(this.selectedCustomer) this.input_customer.nativeElement.value = this.selectedCustomer.getName();
  }

  ngOnDestroy(): void {
    if(this.listenerFn){
      this.listenerFn();
    }
    if(this.listenerDrop1){
      this.listenerDrop1();
    }
    if(this.listenerDrop2){
      this.listenerDrop2();
    }
  }

  setDropdownCloseListener() {
    this.listenerDrop1 = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown1){
        let isInside = this.dropdown1?.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdown1 = false;
        }
      }
    });
    this.listenerDrop2 = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown2){
        let isInside = this.dropdown2?.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropdown2 = false;
        }
      }
    });
  }

  removeKeyDownListener() {
    window.removeEventListener('keydown', this.boundKeyDownCallback);
  }

  getInitials() {
    return this.currentUser.firstName.substring(0,1) + this.currentUser.lastName.substring(0,1);
  }

  setTyp(typ: number) {
    this.selectedTyp = typ;
    this.selectedEmployee = null;
    this.selectedCustomer = null;
    this.selectedVehicle = null;
  }

  getTyp(typ: number): string {
    switch (typ) {
      case 0: return 'Mitarbeiter'; break;
      case 1: return 'Fahrzeug'; break;
      case 2: return 'Kunde'; break;
      default: return '---'; break;
    }
  }

  setEmployee(employee: Employee) {
    this.selectedEmployee = employee;
    this.threadForm.controls.typ_object.setValue(employee.id);
    if(this.selectedEmployee) this.input_employee.nativeElement.value = this.selectedEmployee.firstName + ' ' + this.selectedEmployee.lastName;
  }

  setCustomer(customer: Customer | Number | null){
    if(customer === null){
      this.selectedVehicle = null;
      this.threadForm.controls.typ_object.setValue(null);
    } else {
      if(customer instanceof Customer){
        this.selectedCustomer = customer;
        this.threadForm.controls.typ_object.setValue(customer.id);
      } else {
        for(let c of this.customers){
          if(customer == c.id){
            this.selectedCustomer = c;
            this.threadForm.controls.typ_object.setValue(customer);
            break;
          }
        }
      }
      if(this.selectedCustomer) this.input_customer.nativeElement.value = this.selectedCustomer.getName();
    }
  }

  setVehicle(vehicle: Vehicle | Number | null){
    if(vehicle === null){
      this.selectedVehicle = null;
      this.threadForm.controls.typ_object.setValue(null);
    } else {
      if(vehicle instanceof Vehicle){
        this.selectedVehicle = vehicle;
        this.threadForm.controls.typ_object.setValue(vehicle.id);
      } else {
        for(let v of this.vehicles){
          if(vehicle == v.id){
            this.selectedVehicle = v;
            this.threadForm.controls.typ_object.setValue(vehicle);
            break;
          }
        }
      }
      if(this.selectedVehicle) this.input_vehicle.nativeElement.value = this.selectedVehicle.getDescription(50);
    }
  }

  getSelectedValue(): string {
    switch (this.selectedTyp) {
      case 1: return this.selectedEmployee ? this.selectedEmployee.firstName + ' ' + this.selectedEmployee.lastName : 'Intern'; break;
      case 2: return this.selectedCustomer ? this.selectedCustomer.companyName : 'Kunde'; break;
      default: return '---'; break;
    }
  }

  filterEmployees(value: string) {
    if(value && value.length > 0){
      this.setEmployee(null);
      this.employeesFiltered = [];
      this.employeesFiltered = this.employees.filter((employee) => {
        return employee.firstName.toLowerCase().includes(value.toLowerCase()) ||
               employee.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (employee.firstName.toLowerCase() + ' ' + employee.lastName.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.employeesFiltered = this.employees;
    }
  }
  filterVehicles(value: string) {
    if(value && value.length > 0){
      this.setVehicle(null);
      this.vehiclesFiltered = [];
      this.vehiclesFiltered = this.vehicles.filter((vehicle) => {
        return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
          //this.authService.isSinhuber() ? vehicle.vehicle_serialnumber?.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase()) ||
          (this.authenticationService.ACCESS_ALL() && vehicle.externalId ? vehicle.externalId.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase())) ||
          (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.vehiclesFiltered = this.vehicles;
    }
  }
  filterCustomers(value: string) {
    if(value && value.length > 0){
      this.setCustomer(null);
      this.customersFiltered = [];
      this.customersFiltered = this.customers.filter((customer) => {
        return customer.contactPerson.firstName.toLowerCase().includes(value.toLowerCase()) ||
               customer.contactPerson.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (customer.contactPerson.firstName.toLowerCase() + ' ' + customer.contactPerson.lastName.toLowerCase()).includes(value.toLowerCase()) ||
               customer.companyName.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.customersFiltered = this.customers;
    }
  }

  submit(msg) {
    this.threadForm.controls.typ.setValue(this.selectedTyp);
    this.threadForm.controls.message.setValue(msg);
    this.submitEmitter.emit();
  }

  inputAction(txt: string) {
    this.threadForm.controls.message.setValue(txt);
  }
}

