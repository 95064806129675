
  <div *ngIf="showView == view.LIST">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <button *ngIf="authService.accessVia('custom_bill_edit') || authService.accessVia('ledger_edit')" class="button_type_1" (click)="showDropdownMainButton = !showDropdownMainButton" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
          <div *ngIf="showDropdownMainButton">
            <button *ngIf="authService.accessVia('custom_bill_edit')" (click)="selectionCreateBill(); showDropdownMainButton = false;">Individuelle Rechnung</button>
            <button *ngIf="authService.accessVia('ledger_edit')" (click)="showDialogCreateKassabuch = true; showDropdownMainButton = false;">Kassabuch Eintrag</button>
          </div>
        </button>
        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [objectTyp]="'ledger'"
        [objects]="_objectsFiltered.getValue()"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [activeFilter]="activeFilter"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        (pageEmitter)="pageHandling($event)"
        (applySortingEmitter)="applySorting($event)"
        (customFilterEmitter)="customFilter($event)"
        (customFilterOutsideEmitter)="customFilterOutside($event)"
        (openEditEmitter)="selectionEdit($event)"
        (deleteEmitter)="selectionDelete($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (customFilterOutsideEmitter)="customFilterOutside($event)"
    ></app-list-container>
</div>

<app-dialog-kassabuch-create *ngIf="showDialogCreateKassabuch"
  [ledgeEntry]="selectedObject"
  (cancelEmitter)="showDialogCreateKassabuch = false"
  (createSuccessEmitter)="createLedgeEntrySuccess($event)"
  (updateSuccessEmitter)="updateLedgeEntrySuccess($event)"
></app-dialog-kassabuch-create>

<app-bill-details-purchase-contract *ngIf="showView == view.BILLDETAILSPURCHASECONTRACT"
  [obj]="selectedObject"
  [isPreviousObjAvailable]="isPreviousObjAvailable"
  [isNextObjAvailable]="isNextObjAvailable"
  (selectionBackEmitter)="selectionCloseDetails()"
  (selectionEditEmitter)="selectionEdit($event)"
  (successPaidEmitter)="successPaidBill($event)"
  (selectionPreviousObjEmitter)="selectionPreviousObj($event)"
  (selectionNextObjEmitter)="selectionNextObj($event)"
></app-bill-details-purchase-contract>

<app-bill-edit-purchase-contract *ngIf="showView == view.BILLEDITPURCHASECONTRACT"
  [obj]="selectedObject"
  (selectionCancelEmitter)="selectionCancelEditPruchaseContract()"
  (successUpdateEmitter)="successUpdateBillPurchaseContract($event)"
></app-bill-edit-purchase-contract>

<app-bill-details *ngIf="showView == view.BILLDETAILS"
  [obj]="selectedObject"
  [isPreviousObjAvailable]="isPreviousObjAvailable"
  [isNextObjAvailable]="isNextObjAvailable"
  (selectionBackEmitter)="selectionCloseDetails()"
  (selectionEditEmitter)="selectionEditBill($event)"
  (successCancelEmitter)="successCancelBill($event)"
  (successRenewEmitter)="successRenewBill($event)"
  (successPaidEmitter)="successPaidBill($event)"
  (selectionPreviousObjEmitter)="selectionPreviousObj($event)"
  (selectionNextObjEmitter)="selectionNextObj($event)"
></app-bill-details>

<app-bill-create *ngIf="showView == view.BILLCREATE"
  [obj]="selectedObject"
  (cancelCreateEmitter)="selectionCancelCreate()"
  (successCreateEmitter)="successCreateBill($event)"
  (successUpdateEmitter)="successUpdateBill($event)"
></app-bill-create>
