import { Rent } from "../Rent";

export class RentFactory{

    static jsonFactory(rawBody: any): Rent[] {
        const transactions: Rent[] = [];
        for(let rent of rawBody){
            transactions.push(this.jsonFactoryOne(rent));
        }
        return transactions;
    }

    static jsonFactoryOne(rawRent: any): Rent {
        let object = new Rent(
            rawRent.rent_order_id,
            rawRent.type,
            rawRent.price,
            rawRent.status,
            rawRent.start_mileage,
            rawRent.end_mileage,
            rawRent.start_damages,
            rawRent.end_damages,
            rawRent.start_fuel,
            rawRent.end_fuel,
            rawRent.start_release,
            rawRent.end_release,
            rawRent.start_date,
            rawRent.end_date,
            rawRent.mileage_limit,
            rawRent.price_per,
            rawRent.price_info,
            rawRent.price_exceed,
            rawRent.price_insurance,
            rawRent.deposit,
            rawRent.deposit_info,
            rawRent.customer_id,
            rawRent.location_id,
            rawRent.vehicle_id,
            rawRent.customer2_id
        )
        return object;
    }
}