import { HttpEvent, HttpResponse } from '@angular/common/http';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Picture } from 'src/app/buisness-object/picture/Picture';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { CloneObject } from 'src/app/helpers/CloneObject';
import { DragAndDrop } from 'src/app/helpers/DragAndDrop';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TireService } from 'src/app/service/tire/tire.service';

@Component({
  selector: 'app-tyres-images',
  templateUrl: './tyres-images.component.html',
  styleUrl: './tyres-images.component.css'
})
export class TyresImagesComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterViewChecked, OnChanges, OnDestroy {
    @Input() tire: Tire;
    @Input() editMode: boolean;
    @Input() formGroupTire: FormGroup;
    public gallerySwiper: Picture[] = [];
    public gallery: Picture[] = [];
    public titlePicture: Picture;
    public position = 0;
    public uploadProgress = 0;
    public filesAmount = 0;
    public currentFile = 0;
    public onloadSrcs: string[] = [];
    public imagesLoading = false;
    public imageRequestSubs: Subscription[] = [];

    public showSlider = false;
    public sliderSliceFrom = 0;
    public sliderSliceTo = 0;

    constructor(
      public tService: TireService,
      public dService: DialogService
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
      if(this.editMode){
        let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
        if(wrapperBox){
          wrapperBox.setAttribute('style', 'padding-right: 10px;')
        }
      }
    }

    ngAfterContentChecked(): void {
      DragAndDrop.registerHandler('.drag_item_container .drag_item');
    }

    ngAfterViewChecked(): void {
      if(this.editMode && GlobalVariables.drag_item_drag && GlobalVariables.drag_item_drop){
        let dragItem = DragAndDrop.getDragItem(this.gallery);
        let dropItem = DragAndDrop.getDropItem(this.gallery);
        if(dragItem && dropItem){
          dragItem.position = dropItem.position;
          GlobalVariables.drag_item_drag = undefined;
          GlobalVariables.drag_item_drop = undefined;
          this.updateImagePostition(dragItem);
        }
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes['tire'] && changes['tire'].currentValue){
        this.tire = changes['tire'].currentValue;
        this.titlePicture = null;
        this.gallery = [];
        this.gallerySwiper = [];
        setTimeout(() => {
          this.getDataServerSide();
        }, 400);
      }
      if(changes['editMode']){
        if(this.editMode){
          let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
          if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 10px;')
        } else {
          let wrapperBox = document.getElementById('tyres-overview-images-wrapper');
          if(wrapperBox) wrapperBox.setAttribute('style', 'padding-right: 0px;')
        }
      }
    }

    ngOnDestroy(): void {
      for(let imgSub of this.imageRequestSubs){
        if(imgSub) imgSub.unsubscribe();
      }
    }

    updateImagePostition(pic: Picture) {
      this.tService.updateTirePicture(pic).subscribe((response) => {
        if(response){
          for(let data of response.pictures){
            for(let pic of this.gallery){
              if(data.picture_id == pic.picture_id){
                pic.position = data.position;
              }
            }
          }
          this.sortGalleryAfterPosition();
          this.formGroupTire.controls['pictures'].setValue(this.gallerySwiper);
         }
      })
    }

    sortGalleryAfterPosition(){
      this.gallery.sort((a,b) => {
        if(a.position > b.position) return 1;
        if(a.position < b.position) return -1;
        return 0
      })
      this.gallerySwiper.sort((a,b) => {
        if(a.position > b.position) return 1;
        if(a.position < b.position) return -1;
        return 0
      });
    }

    async getDataServerSide(){
      this.titlePicture = null;
      this.gallery = [];
      this.gallerySwiper = [];
      for(let i = 0; i < this.tire.pictures.length; i++){
        this.imagesLoading = true;
        this.imageRequestSubs.push(
          this.tService.getTirePicture(this.tire.pictures[i].picture_id).subscribe((data) => {
            if(data && this.tire?.pictures[i] != null){
              this.tire.pictures[i].obj_id = this.tire.tire_id;
              let src = 'data:image/png;base64,'+data.trim();
              let picture = new Picture(this.tire.pictures[i].picture_id, this.tire.pictures[i].position, this.tire.pictures[i].obj_id, src);
              if(this.tire.pictures[i].position == 1){
                this.titlePicture = picture;
              } else {
                this.gallery.push(picture);
              }
              this.gallerySwiper.push(picture);
              this.sortGalleryAfterPosition();
              if(i == this.tire.pictures.length -1) this.imagesLoading = false;
            }
          })
        )
      }
    }

    async uploadFile(event: any): Promise<any> {
      this.currentFile = 0;
      this.onloadSrcs = [];
      if(event.target.files && event.target.files.length != 0){
        this.filesAmount = event.target.files.length;
        for(let i = 0; i < this.filesAmount; i++){
          await this.readFiles(event.target.files[i]);
        }
        await this.uploadImgToServer();
        if(!this.tire.thumbnail){
          this.tService.getTireThumbnail(this.titlePicture.picture_id).subscribe((result) => {
            if(result){
              this.tire.thumbnail = 'data:image/png;base64,'+result.trim();
            }
          })
        }
      }
    }

    async readFiles(files: any): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.onloadSrcs.push(event.target.result as string);
          resolve(true)
        }
        reader.onerror = (event: any) => {
          reject(false);
        }
        reader.readAsDataURL(files);
      })
    }

    async uploadImgToServer(): Promise<any> {
      for(let src of this.onloadSrcs){
        await this.createTirePicture(src);
      }
      this.tire.pictures = this.gallerySwiper;
      this.formGroupTire.controls['pictures'].setValue(this.gallerySwiper);
    }

    createTirePicture(src: string): Promise<boolean> {
      return new Promise<any>((resolve, reject) => {
        this.tService.createTirePicture(null).subscribe((event: HttpEvent<any>) => {
          let response = event as HttpResponse<any>;
          if(response.body){
            this.currentFile += 1;
            let picture = new Picture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,this.tire.tire_id, src);
            if(response.body.pictures[response.body.pictures.length - 1].position != 1) {
              this.gallery.push(picture);
            } else {
              this.titlePicture = picture;
            }
            this.gallerySwiper.push(picture);
            resolve(true);
          }
        });
      }).catch((error) => {
        console.log(error);
      });
    }

    async onFileDropped(data: any) {
      // this.filesAmount += 1;
      if(data){
        for(let d of data.data){
          await this.createTirePicture(d.content);
        }
      }
      this.tire.pictures = this.gallerySwiper;
      this.formGroupTire.controls['pictures'].setValue(this.gallerySwiper);
    }

    setAsTitle(pic: Picture) {
      this.updateImage(pic);
    }

    updateImage(pic: Picture) {
      let imgToUpdate = CloneObject.deepCopy(pic);
      let currentTitleImg = CloneObject.deepCopy(this.titlePicture);
      imgToUpdate.position = 1;
      this.tService.updateTirePicture(imgToUpdate).subscribe((response) => {
        if(response){
          for(let i = 0; i < this.gallery.length; i++){
            if(this.gallery[i].picture_id == imgToUpdate.picture_id){
              let temp = CloneObject.deepCopy(this.gallery[i]);

              this.gallery[i] = currentTitleImg;
              this.gallery[i].position = temp.position;

              this.titlePicture = imgToUpdate;
              break;
            }
          }
          this.gallerySwiper = [];
          for(let i of this.gallery){
            this.gallerySwiper.push(CloneObject.deepCopy(i));
          }
          this.gallerySwiper.unshift(CloneObject.deepCopy(this.titlePicture));
          this.tService.getTireThumbnail(this.titlePicture.picture_id).subscribe((response) => {
            if(response){
              this.tire.thumbnail = 'data:image/png;base64,'+response.trim();
              this.formGroupTire.controls['thumbnail'].setValue(this.tire.thumbnail);
              DragAndDrop.registerHandler('.drag_item_container .drag_item');
            }
          });
          this.tire.pictures = this.gallerySwiper;
          this.formGroupTire.controls['pictures'].setValue(this.gallerySwiper);
        }
      });
    }

    removeImage(pic: Picture) {
      this.tService.deletePicture(pic).subscribe((success) => {
        if(success){
          let indexGallery = this.gallery.findIndex(p => p.picture_id == pic.picture_id);
          if(indexGallery > -1) this.gallery.splice(indexGallery, 1);
          let index = this.tire.pictures.findIndex(p => p.picture_id == pic.picture_id);
          if(index > -1) this.tire.pictures.splice(index, 1);
          if(this.titlePicture?.picture_id == pic.picture_id){
            if(this.gallery.length > 0){
              this.titlePicture = this.gallery[0];
              this.gallery.splice(0, 1);
              this.setAsTitle(this.titlePicture);
            } else {
              this.titlePicture = null;
            }
          };
          if(this.tire.pictures.length == 0){
            this.tire.thumbnail = null;
            this.formGroupTire.controls['thumbnail'].setValue(null);
          }
          for(let i = 0; i < this.gallerySwiper.length; i++){
            if(pic.picture_id == this.gallerySwiper[i].picture_id){
              this.gallerySwiper.splice(i, 1);
              break;
            }
          }
          this.formGroupTire.controls['pictures'].setValue(this.gallerySwiper);
        }
      })
    }

    getProgress(): string {
      return this.uploadProgress + '%';
    }

    openSlider(pic: Picture) {
      if(this.editMode) {
        return;
      }
      let index = this.gallerySwiper.findIndex(p => p.picture_id == pic.picture_id);
      let base64Array = [];
      this.gallerySwiper.forEach(element => {
        base64Array.push(element.src);
      });
      this.dService.OpenImageSlider({base64Array: base64Array, startIndex: index});
    }

    closeSlider() {
      this.showSlider = false;
    }

    nextPicture() {
      if(this.sliderSliceTo != this.gallerySwiper.length){
        this.sliderSliceFrom++;
        this.sliderSliceTo++;
      }
    }

    previousPicture() {
      if(this.sliderSliceFrom != 0){
        this.sliderSliceFrom--;
        this.sliderSliceTo--;
      }
    }

    openSingleImage(pic: Picture) {
      this.dService.OpenImageSlider({base64Array: [pic.src], startIndex: 0});
    }
}
