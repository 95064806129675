import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-customercar',
  templateUrl: './customercar.component.html',
  styleUrls: ['./customercar.component.css']
})
export class CustomercarComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;
  public minDate = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
