<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false" *ngIf="!editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Miete</h3>
  <div collapsible-content>
    <div class="overview_record">
      <label class="overview_record_lbl">Art der Miete</label>
      <label class="overview_record_value">{{rentValue}}</label>
    </div>
    <div class="overview_record" *ngIf="rentValue == 'Kurzmiete' || rentValue == 'Spezial'">
      <label class="overview_record_lbl">Mietpreis pro Tag</label>
      <label class="overview_record_value">{{getInputValueCurrency(vehicle.rent_price_pro_tag)}}</label>
    </div>
    <div *ngIf="showSubscriptions" class="overview-data-inner-box">
      <div *ngFor="let rent of vehicle.rents; let i = index">
        <h3 *ngIf="i % 4 === 0" style="margin-top: 14px; padding-bottom: 8px;">Mietpreise für {{rent.duration}} Monate (abhängig von km)</h3>
        <div class="overview_record">
          <label class="overview_record_lbl">bis {{getInputValueKM(rent.mileage)}} / Monat</label>
          <label class="overview_record_value">{{getInputValueCurrency(rent.rent_price)}}</label>
        </div>
      </div>
    </div>
  </div>
</app-collapsible>

<form *ngIf="editMode" [formGroup]="form" class="overview_data_box">
  <h3>Miete</h3>
  <app-dropdown-multiple
    [title]="'Art der Miete'"
    [formcontrol]="form.controls['rent_typ']"
    [headerText]="rentValue"
    [values]="rentValues"
    [isOptional]="true"
    (submitValueEmitter)="form.controls['rent_typ'].setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <div class="input_wrapper" *ngIf="rentValue == 'Kurzmiete' || rentValue == 'Spezial'">
    <label class="overview_record_lbl">Mietpreis pro Tag in €</label>
    <input type="number" class="overview_input" placeholder="Preis pro Tag in €" formControlName="rent_price_pro_tag">
  </div>
  <div *ngIf="showSubscriptions" class="overview-data-inner-box">
    <form *ngFor="let rentForm of form.get('rent_forms').value; let i = index" [formGroup]="rentForm">
      <h3 *ngIf="i % 4 === 0" style="margin-top: 14px; padding-bottom: 8px;">Mietpreise für {{rentForm.controls['duration'].value}} Monate (abhängig von km)</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">bis {{getInputValueKM(rentForm.controls['mileage'].value)}} / Monat</label>
        <input type="number" placeholder="---" formControlName="rent_price" [class.input_invalid]="submitted && rentForm.controls.rent_price.errors">
      </div>
    </form>
  </div>
</form>

