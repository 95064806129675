<div id="sidebar">
    <div>
      <img id="logo" src="../../../assets/logomenu.svg" (click)="openWebsite()">
      <div id="menu_item_wrapper">
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 1" (click)="reload('/dashboard')"  *ngIf="authService.accessViaMandant('dashboard')">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.918" height="20.976" viewBox="0 0 25.918 20.976">
            <path class="menu_item_svg" d="M186.706,497.191h-.134a6.69,6.69,0,0,0-.206-.962c-1.015-3.437-1.666-6.339-9.381-6.339h-2.638c-7.715,0-8.366,2.9-9.38,6.339a7.776,7.776,0,0,0-.218.962h-.161a1.9,1.9,0,0,0-1.9,1.9.523.523,0,0,0,.522.522h1.123a1.965,1.965,0,0,0-.365.953v9.778a.522.522,0,0,0,.522.522h4.284a.523.523,0,0,0,.522-.522V508.55h12.74v1.794a.523.523,0,0,0,.522.522h4.283a.523.523,0,0,0,.523-.522v-9.778a1.712,1.712,0,0,0-.432-.953h1.151a.523.523,0,0,0,.522-.522A1.9,1.9,0,0,0,186.706,497.191Zm-20.181-.5.077-.263c.929-3.166,1.442-4.91,7.744-4.91h2.638c6.3,0,6.814,1.744,7.744,4.91l.077.263a1.011,1.011,0,0,1-.97,1.3H167.5a1.011,1.011,0,0,1-.97-1.3Zm1.845,8.109h-.312c-2.343,0-2.648-.415-2.648-.776a2.407,2.407,0,0,1,.018-.3c.238-1.879,1.947-2.544,3.539-1.517,0,0,2.506,1.424,2.362,2.268C171.33,504.585,170.958,504.755,168.37,504.8Zm11.523-.326c-.143-.844,2.363-2.268,2.363-2.268,1.592-1.027,3.3-.363,3.538,1.517a2.4,2.4,0,0,1,.019.3c0,.361-.306.776-2.648.776h-.312C180.266,504.755,179.893,504.585,179.893,504.472Z" transform="translate(-162.687 -489.89)" fill="#2c2c2c"/>
          </svg>
          <label>Dashboard</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 2" (click)="reload('/cars')"  *ngIf="authService.accessViaRoleCars() && authService.accessViaMandant('cars')">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.918" height="20.976" viewBox="0 0 25.918 20.976">
            <path class="menu_item_svg" d="M186.706,497.191h-.134a6.69,6.69,0,0,0-.206-.962c-1.015-3.437-1.666-6.339-9.381-6.339h-2.638c-7.715,0-8.366,2.9-9.38,6.339a7.776,7.776,0,0,0-.218.962h-.161a1.9,1.9,0,0,0-1.9,1.9.523.523,0,0,0,.522.522h1.123a1.965,1.965,0,0,0-.365.953v9.778a.522.522,0,0,0,.522.522h4.284a.523.523,0,0,0,.522-.522V508.55h12.74v1.794a.523.523,0,0,0,.522.522h4.283a.523.523,0,0,0,.523-.522v-9.778a1.712,1.712,0,0,0-.432-.953h1.151a.523.523,0,0,0,.522-.522A1.9,1.9,0,0,0,186.706,497.191Zm-20.181-.5.077-.263c.929-3.166,1.442-4.91,7.744-4.91h2.638c6.3,0,6.814,1.744,7.744,4.91l.077.263a1.011,1.011,0,0,1-.97,1.3H167.5a1.011,1.011,0,0,1-.97-1.3Zm1.845,8.109h-.312c-2.343,0-2.648-.415-2.648-.776a2.407,2.407,0,0,1,.018-.3c.238-1.879,1.947-2.544,3.539-1.517,0,0,2.506,1.424,2.362,2.268C171.33,504.585,170.958,504.755,168.37,504.8Zm11.523-.326c-.143-.844,2.363-2.268,2.363-2.268,1.592-1.027,3.3-.363,3.538,1.517a2.4,2.4,0,0,1,.019.3c0,.361-.306.776-2.648.776h-.312C180.266,504.755,179.893,504.585,179.893,504.472Z" transform="translate(-162.687 -489.89)" fill="#2c2c2c"/>
          </svg>
          <label>Fahrzeuge</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 3" (click)="reload('/customers')"  *ngIf="authService.accessViaRoleCustomers() && authService.accessViaMandant('customers')">
          <svg  xmlns="http://www.w3.org/2000/svg" width="23.085" height="23.477" viewBox="0 0 23.085 23.477">
            <g id="Gruppe_601" data-name="Gruppe 601" transform="translate(5.861)">
              <circle class="menu_item_svg" id="Ellipse_14" data-name="Ellipse 14" cx="5.681" cy="5.681" r="5.681" fill="#2c2c2c"/>
            </g>
            <g id="Gruppe_602" data-name="Gruppe 602" transform="translate(0 14.757)">
              <path class="menu_item_svg" data-name="Pfad 544" d="M230.739,577.916a.572.572,0,0,0,.565-.628c-.4-4.783-5.417-8.092-11.54-8.092s-11.136,3.308-11.54,8.092a.572.572,0,0,0,.564.628Z" transform="translate(-208.221 -569.197)" fill="#2c2c2c"/>
            </g>
          </svg>
          <label>Kunden</label>
        </div>
        <!-- <div class="menu_item" [class.menu_item_active]="selectedMenue == 4" (click)="reload('/interfaces')" *ngIf="authService.accessViaRoleInterfaces() && authService.accessViaConfigInterfaces()">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.476" height="23.477" viewBox="0 0 23.476 23.477">
            <path class="menu_item_svg" d="M225.083,430.417a11.738,11.738,0,0,0-11.738,11.739h0a11.738,11.738,0,1,0,23.476,0h0A11.739,11.739,0,0,0,225.083,430.417Zm1.277,14.707-2.214,2.214a2.927,2.927,0,0,1-3.832.32,2.863,2.863,0,0,1-.283-4.293l2.521-2.521a2.862,2.862,0,0,1,4.044,0,.712.712,0,0,1-.112,1.1.752.752,0,0,1-.926-.123,1.439,1.439,0,0,0-2,.031l-2.481,2.481a1.476,1.476,0,0,0-.223,1.85,1.44,1.44,0,0,0,2.216.222l1.6-1.6a.117.117,0,0,1,.133-.024,2.273,2.273,0,0,0,1.454.138A.12.12,0,0,1,226.36,445.124Zm4.177-4.177-2.521,2.521a2.862,2.862,0,0,1-4.044,0,.711.711,0,0,1,.112-1.1.751.751,0,0,1,.926.123,1.44,1.44,0,0,0,2-.031l2.481-2.481a1.476,1.476,0,0,0,.223-1.85,1.44,1.44,0,0,0-2.216-.223l-1.592,1.592a.117.117,0,0,1-.14.019,1.9,1.9,0,0,0-1.432-.144.121.121,0,0,1-.111-.2l2.2-2.2a2.926,2.926,0,0,1,3.832-.32A2.862,2.862,0,0,1,230.536,440.946Z" transform="translate(-213.345 -430.417)" fill="#2c2c2c"/>
          </svg>
          <label>API</label>
        </div> -->
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 5" (click)="reload('/documents')"  *ngIf="authService.accessViaRoleDocuments() && authService.accessViaMandant('documents')">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.323" height="14.487" viewBox="0 0 13.323 14.487">
            <g id="document" transform="translate(-23.52 -129.848)">
              <path class="menu_item_svg" data-name="Pfad 545" d="M216.581,756.985V744.627a1.491,1.491,0,0,1,.444-1.064h-.678a1.326,1.326,0,0,0-1.233,1.4v11.682a1.326,1.326,0,0,0,1.233,1.4h.678A1.492,1.492,0,0,1,216.581,756.985Z" transform="translate(-191.594 -613.715)" fill="#f2f2f2"/>
              <path class="menu_item_svg" data-name="Pfad 546" d="M232.779,749.168l-4.812-5.473a.375.375,0,0,0-.281-.132h-4.371a1.326,1.326,0,0,0-1.233,1.4v11.682a1.326,1.326,0,0,0,1.233,1.4h8.348a1.326,1.326,0,0,0,1.233-1.4v-7.16A.484.484,0,0,0,232.779,749.168Zm-2.218,5.9h-6.146a.485.485,0,0,1,0-.969h6.146a.485.485,0,1,1,0,.969Zm0-2.931h-6.146a.485.485,0,0,1,0-.969h6.146a.485.485,0,1,1,0,.969Z" transform="translate(-196.053 -613.715)" fill="#2C2C2C"/>
            </g>
          </svg>
          <label>Dokumente</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 11" (click)="reload('/accounting')"  *ngIf="true && authService.accessViaConfigAdmin() && authService.accessViaMandant('accounting')">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.528" height="21.273" viewBox="0 0 25.528 21.273">
            <path class="menu_item_svg" d="M22.337,6.255H5.318A3.2,3.2,0,0,1,2.941,5.192,3.186,3.186,0,0,1,5.318,4.127H24.464a1.064,1.064,0,0,0,0-2.127H5.318A5.319,5.319,0,0,0,0,7.318V17.955a5.319,5.319,0,0,0,5.318,5.318H22.337a3.191,3.191,0,0,0,3.191-3.191V9.446A3.191,3.191,0,0,0,22.337,6.255Zm-1.064,9.573a1.064,1.064,0,0,1,0-2.127A1.064,1.064,0,0,1,21.273,15.828Z" transform="translate(0 -2)" fill="#2c2c2c"/>
          </svg>
          <label>Buchhaltung</label>
        </div>
        <!-- <div class="menu_item" [class.menu_item_active]="selectedMenue == 7" (click)="reload('/admin')"  *ngIf="authService.accessViaRoleAdmin() && authService.accessViaMandant('admin')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.884" height="24.667" viewBox="0 0 21.884 24.667">
            <path class="menu_item_svg" d="M229.76,962.026a.8.8,0,0,0-.8-.777c-.063,0-6.387.076-9.513-4.064a.776.776,0,0,0-1.246,0c-3.129,4.141-9.458,4.06-9.514,4.064a.805.805,0,0,0-.8.777c-.005.138-.367,13.76,10.536,19.425a.772.772,0,0,0,.356.087h.1a.772.772,0,0,0,.356-.087C230.127,975.786,229.765,962.163,229.76,962.026Zm-2.325,5.9h-8.614v11.918h0c-5.322-2.907-7.621-7.948-8.615-11.919h8.615v-9.01c2.979,3.223,7.511,3.818,9.354,3.924A25.372,25.372,0,0,1,227.435,967.927Z" transform="translate(-207.879 -956.871)" fill="#2c2c2c"/>
          </svg>
          <label>ADMIN</label>
        </div> -->
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 10" (click)="reload('/drives')" *ngIf="authService.accessViaRoleDrives() && authService.accessViaMandant('drives')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.309" height="24.508" viewBox="0 0 21.309 24.508">
            <g id="planning" transform="translate(-128.846 -497.522)">
              <path class="menu_item_svg" id="Pfad_993" data-name="Pfad 993" d="M7.11,5.264A1.192,1.192,0,0,1,5.919,4.073V1.191a1.191,1.191,0,1,1,2.383,0V4.073A1.192,1.192,0,0,1,7.11,5.264" transform="translate(126.452 497.522)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_994" data-name="Pfad 994" d="M27.046,5.264a1.192,1.192,0,0,1-1.191-1.191V1.191a1.191,1.191,0,1,1,2.383,0V4.073a1.192,1.192,0,0,1-1.191,1.191" transform="translate(118.391 497.522)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_995" data-name="Pfad 995" d="M27.046,7.018a1.192,1.192,0,0,0,1.191-1.191V4.338H25.855V5.827a1.192,1.192,0,0,0,1.191,1.191" transform="translate(118.391 495.768)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_996" data-name="Pfad 996" d="M7.11,7.018A1.192,1.192,0,0,0,8.3,5.827V4.338H5.919V5.827A1.192,1.192,0,0,0,7.11,7.018" transform="translate(126.452 495.768)" fill="#2c2c2c"/>
              <path class="menu_item_svg" id="Pfad_997" data-name="Pfad 997" d="M19.3,4.338h-.923V5.827a1.787,1.787,0,1,1-3.574,0V4.338H6.5V5.827a1.787,1.787,0,1,1-3.574,0V4.338H2.008A2.011,2.011,0,0,0,0,6.347V24.254a2.01,2.01,0,0,0,2.008,2.008H19.3a2.01,2.01,0,0,0,2.008-2.008V6.347A2.011,2.011,0,0,0,19.3,4.338m.221,19.916a.224.224,0,0,1-.221.221H2.008a.224.224,0,0,1-.221-.221v-13.8H19.522Z" transform="translate(128.846 495.768)" fill="#2c2c2c"/>
            </g>
          </svg>
          <label>PROBEFAHRT</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 12" (click)="reload('/tyres')" *ngIf="authService.accessViaRoleDrives() && authService.accessViaMandant('tyres')">
          <svg xmlns="http://www.w3.org/2000/svg" width="21.848" height="21.777" viewBox="0 0 21.848 21.777">
            <path class="menu_item_svg" d="M10.924,0A10.936,10.936,0,0,0,0,10.924c.546,14.474,21.3,14.468,21.848,0A10.936,10.936,0,0,0,10.924,0Zm0,11.834a3.717,3.717,0,0,0-.769.091,3.345,3.345,0,0,0-.406-1.449,3.277,3.277,0,0,0,.646-.33,3.294,3.294,0,0,0,.529-.464,2.913,2.913,0,0,0,1.175.791,3.345,3.345,0,0,0-.406,1.449,3.717,3.717,0,0,0-.769-.088ZM16.412,7.7,13.828,8.781a1.457,1.457,0,0,1-1.994-1.35V4.624A6.378,6.378,0,0,1,16.412,7.7Zm-6.4-3.078V7.431A1.457,1.457,0,0,1,8.024,8.784l-2.6-1.06a6.372,6.372,0,0,1,4.592-3.1ZM4.74,9.412l2.394.977a1.937,1.937,0,0,1,.841,2.949l-1.59,2.047A6.372,6.372,0,0,1,4.74,9.412Zm3.1,7.08,1.632-2.109a1.869,1.869,0,0,1,2.92.01l1.62,2.1a6.4,6.4,0,0,1-6.175,0Zm7.625-1.1-1.578-2.047a1.936,1.936,0,0,1,.847-2.964L17.1,9.39a6.372,6.372,0,0,1-1.645,6Z" fill="#2c2c2c"/>
          </svg>
          <label>LAGER</label>
        </div>
        <div class="menu_item" [class.menu_item_active]="selectedMenue == 9" (click)="reload('/messages')"  *ngIf="authService.accessViaRoleTickets() && authService.accessViaMandant('messages')">
          <div *ngIf="getNotify() != 0" id="menu_notify">{{getNotify()}}</div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22.695" height="22.695" viewBox="0 0 22.695 22.695">
              <path class="menu_item_svg" data-name="Pfad 1021" d="M11.347,0h0A11.345,11.345,0,0,0,3.059,19.095L.121,21.989a.405.405,0,0,0,.284.693H10.635c.024,0,.046,0,.069-.007.213.012.427.019.643.019A11.347,11.347,0,0,0,11.347,0M7.485,14.106a.91.91,0,0,1-.2.3.907.907,0,0,1-1.483-.3.92.92,0,0,1,.2-.992.919.919,0,0,1,.3-.2,1.138,1.138,0,0,1,.173-.055.908.908,0,0,1,.819.251.923.923,0,0,1,.269.641.906.906,0,0,1-.074.35m8.562.562H10.526a.91.91,0,0,1,0-1.82h5.521a.91.91,0,1,1,0,1.82m0-5.277h-9.4a.91.91,0,0,1,0-1.82h9.4a.91.91,0,1,1,0,1.82" transform="translate(0 0)" fill="#2c2c2c"/>
          </svg>
          <label>NACHRICHTEN</label>
        </div>
      </div>
    </div>

    <!-- <div id="menu_footer">
      <button class="eva_style" (click)="showActions = !showActions" appDropdown [show]="showActions" (changedValueEmitter)="showActions = $event">{{authService.isHoedl() ? (user.f[0] + user.l[0]) : 'MEIN KONTO'}}</button>
      <div class="dropdown_actions_container" *ngIf="showActions">
        <div class="user_info_wrapper">
          <div class="initials">{{user.f[0]}}{{user.l[0]}}</div>
          <div class="flex">
            <label>{{user.f}} {{user.l}}</label>
            <label style="font-size: 10px; font-weight: 200;">{{location?.name}}</label>
          </div>
        </div>
        <label (click)="changePasswortAction()">Passwort ändern</label>
        <label (click)="logoutAction()">Abmelden</label>
      </div>
      <label class="lbl_regular_italic_light_color">V.{{versionsNr}}</label>
    </div> -->

    <div id="menu_footer">
      <button class="btn-gradient" *ngIf="authService.accessViaRoleAdmin()"
        (click)="showActions2 = !showActions2"
        appDropdown
        [show]="showActions2"
        (changedValueEmitter)="showActions2 = $event">
        OPTIONEN
        <div class="action-container-1" *ngIf="showActions2">
          <button class="button-label-white" *ngIf="authService.accessViaRoleAdmin() && authService.accessViaMandant('settings')" (click)="reload('/settings')">Einstellungen</button>
          <button class="button-label-white" *ngIf="authService.accessViaRoleAdmin() && authService.accessViaMandant('users')" (click)="reload('/users')">User-Verwaltung</button>
          <button class="button-label-white" *ngIf="authService.accessViaRoleAdmin() && authService.accessViaMandant('locations')" (click)="reload('/locations')">Standorte</button>
          <button class="button-label-white" *ngIf="false" (click)="null">Support kontaktieren</button>
        </div>
      </button>
      <button class="btn-black"
        (click)="showActions = !showActions"
        appDropdown
        [show]="showActions"
        (changedValueEmitter)="showActions = $event">
        {{authService.isHoedl() ? (user.f[0] + user.l[0]) : 'MEIN KONTO'}}
        <div class="action-container-2" *ngIf="showActions">
          <div class="user_info_wrapper">
            <label class="initials">{{user.f[0]}}{{user.l[0]}}</label>
            <div class="flex">
              <label>{{user.f}} {{user.l}}</label>
              <label style="font-size: 10px; font-weight: 200;">{{location?.name}}</label>
            </div>
          </div>
          <button class="button-label-white" (click)="changePasswortAction()">Passwort ändern</button>
          <button class="button-label-white" (click)="logoutAction()">Abmelden</button>
        </div>
      </button>
      <label class="lbl_regular_italic_light_color">V.{{versionsNr}}</label>
    </div>

</div>
<app-dialog-change-password></app-dialog-change-password>
