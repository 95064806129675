import { AuthenticationService } from "src/app/service/authentication/authentication.service";
import { Authentication } from "../../auth/Authentication";
import { Employee } from "../Employee";
import { RoleFactory } from "./RoleFactory";
import { AddressFactory } from "../../customer/factory/AddressFactory";

export class EmployeeFactory {
    static jsonFactory(rawBody: any): Employee[] {
        const employees: Employee[] = [];
        for(let employee of rawBody){
            employees.push(this.jsonFactoryOne(employee));
        }
        return employees;
    }

    static jsonFactoryOne(rawEmployee: any): Employee {
        const employee = new Employee(
            rawEmployee.employee_id,
            rawEmployee.username,
            rawEmployee.first_name,
            rawEmployee.last_name,
            rawEmployee.email,
            rawEmployee.location_id,
            rawEmployee.mandant_id,
            rawEmployee.active
        )
        employee.roles = rawEmployee.roles;
        employee.planning_color = "#D2B836";
        employee.birthdate = rawEmployee.birthdate;
        employee.phone = rawEmployee.phone;
        employee.authorization_number = rawEmployee.authorization_number;
        employee.authorization_authority = rawEmployee.authorization_authority;
        employee.authorization_date = rawEmployee.authorization_date;
        employee.authorization_groups = rawEmployee.authorization_groups;
        employee.salutation = rawEmployee.salutation;
        employee.title = rawEmployee.title;
        employee.address = rawEmployee.address ? AddressFactory.jsonFactoryOne(rawEmployee.address) : null;
        employee.auth = Authentication.getAuthentication(employee.roles);
        return employee;
    }
}
