import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RouterAnimation } from './helpers/Animations';
import { GlobalVariables } from './helpers/GlobalVars';
import { UpdateService } from './service/udpate/update.service';
import { DocumentService } from './service/document/document.service';
import { DialogService } from './service/dialog/dialog.service';
import { AuthenticationService } from './service/authentication/authentication.service';
import { ReloadDataHandler } from './utils/ReloadDataHandler';
import { ReloadServiceService } from './service/reloadService/reload-service.service';
import { VehicleService } from './service/vehicle/vehicle.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [RouterAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'autobase-backend';
  isTest = false;
  version = environment.VERSION;

  constructor(
    private documentService: DocumentService,
    private dialogService: DialogService,
    private authService: AuthenticationService,
    private vService: VehicleService,
    private reloadService: ReloadServiceService,
    private updateService: UpdateService
  ){}

  ngOnInit() {
    if(environment.api.includes('test')) this.isTest = true;
    GlobalVariables.screen_size = window.innerWidth > 1420 ? 1 : 2;
    if(localStorage.getItem("token")){
      this.documentService.getHasOpenSignature().subscribe((result) => {
        if(result){
          if(result.has_signatures == true){
            this.dialogService.openLoaderSignature({});
          }
        }
      })
    }
    document.documentElement.style.setProperty('--heigth-scroll-thumb', (this.authService.isSinhuber() ? 16 : 8) + 'px');
    if(localStorage.getItem('token') != null) new ReloadDataHandler().activateReloadLoop([this.vService]);
    this.calculateViewDimension();
    window.addEventListener('resize', this.calculateViewDimension);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateViewDimension);
  }

  calculateViewDimension() {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--calc-vh', vh + 'px');
    var vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--calc-vw', vw + 'px');
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.animation;
  }

  showMenu(outlet: RouterOutlet): boolean {
    if(!outlet.isActivated){
      return false;
    } else if(outlet?.activatedRouteData.isLogin === true) {
      return false;
    } else return true;
  }

  isProgressbar(): boolean {
    return GlobalVariables.progressbarActive;
  }
}
