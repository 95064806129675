<div id="main_container">
    <app-topbar-customer
        [surface]="surface"
        [showSurfaceOverview]="showSurfaceOverview"
        [filter]="filter"
        [searchValue]="searchValue"
        [selectedCustomer]="selectedCustomer"
        [accessEdit]="accessEdit"
        [sortObject]="sortObject"
        (changeSurfaceEmitter)="changeSurface($event)"
        (openCreateEmitter)="openCreate($event)"
        (saveCustomerEmitter)="saveCustomer()"
        (editCustomerEmitter)="openEdit(undefined)"
        (cancelEditEmitter)="cancelEdit()"
        (applyFilterEmitter)="applyFilter()"
        (searchEmitter)="searchAction($event)"
        (resetFilterEmitter)="resetFilterEmitter()"
        (applySortEmitter)="applySorting()"
        (resetSortEmitter)="resetSortEmitter()"
        (openCreateCustomerCar)="openCreateCustomerCar()"
        (cancelCreateCustomerCar)="cancelCreateCustomerCar()"
        (createCustomerCarEmitter)="createCustomerCar()"
        (openCreateDokument)="openCreateDokument($event)"
        (selectionCancelPdfShowEmitter)="selectionCancelPdfShow()"
        (signPDFEmitter)="signPDF()"
        (createDataprivacyEmitter)="getDatadataprivacy(selectedCustomer, true)"
        (selectionChangeSurfaceOverviewEmitter)="showSurfaceOverview = $event"
    ></app-topbar-customer>
    <app-list-customer-container *ngIf="surface == 1 && isLoaded"
        [customers]="customersFiltered"
        [sliceTo]="sliceTo"
        [sliceFrom]="sliceFrom"
        [pageInfo]="pageInfo"
        [amount]="customersFiltered.length"
        [filter]="filter"
        [sortObject]="sortObject"
        [accessEdit]="accessEdit"
        (pageEmitter)="pageHandling($event)"
        (openDetailsEmitter)="openDetails($event)"
        (openEditEmitter)="openEdit($event)"
        (deleteEmitter)="deleteAction($event)"
        (createCustomerCarEmitter)="openCreateCustomerCar($event)"
        (removeFilterEmitter)="removeFilterValue($event)"
        (applySortEmitter)="applySorting()"
        (selectionCreateDokumentEmitter)="selectionCreateDocument($event)"
        (selectionMoveEmitter)="selectionMove($event)"
    ></app-list-customer-container>
    <app-create-customer-container *ngIf="surface == 2 && accessEdit"
        [isCompany]="isCompany"
        [titles]="titles"
        [salutations]="salutations"
        [customerFormGroup]="customerFormGroup"
        [isCompanyFormGroup]="isCompanyFormGroup"
        [submitted]="submitted"
        [selectedCustomer]="selectedCustomer"
        [employees]="employees"
    ></app-create-customer-container>
    <app-overview-customer-container *ngIf="surface == 3"
        [customer]="selectedCustomer"
        [showSurfaceOverview]="showSurfaceOverview"
        (removeCustomerCarEmitter)="removeCustomerCar($event)"
    ></app-overview-customer-container>
    <app-customer-create-car *ngIf="surface == 4"
        [customer]="selectedCustomer"
        [form]="customerCarForm"
        [submitted]="submittedCreateCustomerCar"
    ></app-customer-create-car>
    <!-- <app-pdf-view *ngIf="surface == 5 && pdf"
        [pdf]="pdf"
    ></app-pdf-view> -->
</div>
