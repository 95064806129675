import { Attachment } from "../attachment/Attachment";
import { ContactPerson } from "../customer/ContactPerson";
import { Customer } from "../customer/Customer";
import { Vehicle } from "../vehicle/Vehicle";
import { Location } from "../location/Location";

export enum RentOrderStatus {
  OPEN = 0,
  RECEIVE = 1,
  RECEIVED = 2,
  RETURN = 3,
  RETURNED = 4,
  ARCHIVED = 10
}

export enum RentTyp {
  MIETABO = 1,
  KURZMIETE = 2,
  SPEZIAL = 3,
}

export const RentTypLabels: { [key in RentTyp]: string } = {
  [RentTyp.MIETABO]: 'Miet-Abo',
  [RentTyp.KURZMIETE]: 'Kurzzeit',
  [RentTyp.SPEZIAL]: 'Spezial',
};

export const RentOrderStatusLabels: { [key in RentOrderStatus]: string } = {
  [RentOrderStatus.OPEN]: 'Offen',
  [RentOrderStatus.RECEIVE]: 'Rücknahme',
  [RentOrderStatus.RECEIVED]: 'Erhalten',
  [RentOrderStatus.RETURN]: 'Übergabe',
  [RentOrderStatus.RETURNED]: 'Abgeschlossen',
  [RentOrderStatus.ARCHIVED]: 'Archiviert',
}


export class Rent {
  public location: Location;
  public vehicle: Vehicle;
  public main_driver: Customer;
  public second_driver?: Customer;
  public rent_duration: string;
  public driven_km: number;
  //public attachments: Attachment[] = [];
  //public handover_pictures = [];
  //public return_pictures = [];
  constructor(
    public rent_id: number,
    public rent_typ: number,
    public rent_price_eur: number,
    //public contract_deadline: number,
    public rent_status: number,
    public handover_mileage: number,
    public return_mileage: number,
    //public driven_km: number,
    public handover_damage: number,
    public return_damage: number, 
    public handover_fuel_content: string,
    public return_fuel_content: string,
    public start_release_date: number, 
    public end_release_date: number,
    //public drivers_license_copy: string,
    public rental_start_date: number,
    public rental_end_date: number,
    public km_limit: number,
    public price_time_unit: string,
    public rent_add_on: string,
    public additional_km_price: number,
    public insurance_flat_rate: number,
    public deposit_eur: number,
    public deposit_time_unit: string,
    public main_driver_id: number,
    public location_id: number,
    public vehicle_id: number,
    public second_driver_id?: number
  ) {
      this.rent_duration = this.getRentDuration();
      this.driven_km = this.getDrivenKm();
  }

  public getDrivenKm(): number {
    return this.return_mileage - this.handover_mileage;
  }

  public getRentDuration(): string {

    const oneDay = 24 * 60 * 60 * 1000;
    const oneMonth = 30 * oneDay;
    const oneYear = 12 * oneMonth;
  
    const duration = this.rental_end_date - this.rental_start_date;
  
    if(duration >= oneYear) {
      const years = Math.floor(duration / oneYear);
      return years === 1 ? `${years} Jahr` : `${years} Jahre`;
    }
  
    if(duration >= oneMonth) {
      const months = Math.floor(duration / oneMonth);
      return months === 1 ? `${months} Monat` : `${months} Monate`;
    }
  
    const days = Math.ceil(duration / oneDay);

    if(days === 0){
      return '1 Tag';
    }
    
    return days === 1 ? `${days} Tag` : `${days} Tage`;
  }

  public getPriceAndTimeUnit(): string {
    return this.rent_price_eur + ' / ' + 'Tag';
  }
  
  public getTypName(number: Number): string {
    if(number == 0){
      return 'Miet-Abo';
    }
    else if(number == 1){
      return 'Kurzmiete'
    }
    else if(number == 2){
      return 'Spezial'
    }
  }
  public getTypeAndNameAndVehicle(): string {
    return this.getTypName(this.rent_typ) + ': ' + this.main_driver?.getFullName() + '- ' + this.vehicle.getVehicleName();
  }

  getJSON(): any {
    return {
      rent_order_id: this.rent_id,
      type: this.rent_typ,
      status: this.rent_status,
      location_id: this.location_id,
      customer_id: this.main_driver_id,
      customer2_id: this.second_driver_id,
      vehicle_id: this.vehicle_id,
      start_date: this.rental_start_date,
      end_date: this.rental_end_date,
      mileage_limit: this.km_limit,
      price: this.rent_price_eur,
      price_per: this.price_time_unit,
      price_info: this.rent_add_on,
      price_exceed: this.additional_km_price,
      price_insurance: this.insurance_flat_rate,
      start_mileage: this.handover_mileage,
      start_damages: this.handover_damage,
      start_fuel: this.handover_fuel_content,
      start_release: this.start_release_date,
      end_mileage: this.return_mileage,
      end_damages: this.return_damage,
      end_fuel: this.return_fuel_content,
      end_release: this.end_release_date,
      deposit: this.deposit_eur,
      deposit_info: this.deposit_time_unit
    }
  }

}
