<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false" *ngIf="!editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Werkstatt</h3>
  <div collapsible-content>
    <div class="overview_record" *ngIf="showValue('ankaufstest')">
      <label class="overview_record_lbl">Ankaufstest</label>
      <label class="overview_record_value">{{ankaufstestValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('ankaufstest_info_description')">
      <div class="overview_record_lbl">Ankaufstest Info</div>
      <div class="overview_record_value">{{vehicle.ankaufstest_info_description ? vehicle.ankaufstest_info_description : '---'}}</div>
    </div>
    <div class="overview_record" *ngIf="showValue('werkstatt_info_description')">
      <div class="overview_record_lbl">Werkstatt Info</div>
      <div class="overview_record_value">{{vehicle.werkstatt_info_description ? vehicle.werkstatt_info_description : '---'}}</div>
    </div>
    <div class="overview_record" *ngIf="showValue('aufbereitung')">
      <label class="overview_record_lbl">Aufbereitung</label>
      <label class="overview_record_value">{{aufbereitungValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('aufbereitung_info')">
      <label class="overview_record_lbl">Aufbereitung-Info</label>
      <label class="overview_record_value">{{getInputValue(vehicle.aufbereitung_info)}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('aufbereitung_zustaendigkeit')">
      <label class="overview_record_lbl">Aufbereitung Zuständigkeit</label>
      <label class="overview_record_value">{{aufbereitungZustValue}}</label>
    </div>
    <div class="overview_record">
      <div class="overview_record_lbl">Dellen</div>
      <div class="overview_record_value">{{damageValue}}</div>
    </div>
    <div class="overview_record">
      <div class="overview_record_lbl">Dellen-Info</div>
      <div class="overview_record_value">{{getInputValue(vehicle.dellen_info)}}</div>
    </div>
    <div class="overview_record" *ngIf="authService.ACCESS_ALL()">
      <label class="overview_record_lbl">Letzte Bewegung</label>
      <div class="overview_record_value">{{vehicle.last_movement | timestampFormat}}</div>
    </div>
    <div class="overview_record" *ngIf="showValue('repair_status')">
      <label class="overview_record_lbl">Reparatur</label>
      <label class="overview_record_value">{{repairStatenValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('ersatzteile')">
      <label class="overview_record_lbl">Ersatzteile</label>
      <label class="overview_record_value">{{ersatzTeileValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('lackierung_notwendig')">
      <label class="overview_record_lbl">Lackierung</label>
      <label class="overview_record_value">{{lackierungValue}}</label>
    </div>
    <div class="overview_record" *ngIf="showValue('pickerl_valid')">
      <label class="overview_record_lbl">§57a fällig am</label>
      <div class="overview_record_value">{{getInputValueDateMonth(vehicle.pickerl_valid)}}</div>
    </div>
  </div>
</app-collapsible>

<form *ngIf="editMode" [formGroup]="form" class="overview_data_box">
  <h3>Werkstatt</h3>
  <app-dropdown-multiple *ngIf="showValue('ankaufstest')"
    [title]="'Ankaufstest'"
    [formcontrol]="f.ankaufstest"
    [headerText]="ankaufstestValue"
    [values]="ankaufstestValues"
    [isOptional]="true"
    (submitValueEmitter)="f.ankaufstest.setValue($event); getEnumDataOnChange();">
  </app-dropdown-multiple>
  <div class="input_wrapper" *ngIf="showValue('ankaufstest_info_description')">
    <label class="overview_record_lbl">Ankaufstest Info</label>
    <input type="text" class="overview_input" placeholder="Ankaufstest Info" formControlName="ankaufstest_info_description">
  </div>
  <div class="input_wrapper" *ngIf="showValue('werkstatt_info_description')">
    <label class="overview_record_lbl">Werkstatt Info</label>
    <input type="text" class="overview_input" placeholder="Werkstatt Info" formControlName="werkstatt_info_description">
  </div>
  <app-dropdown-multiple *ngIf="showValue('aufbereitung')"
    [title]="'Aufbereitung'"
    [formcontrol]="f.aufbereitung"
    [headerText]="aufbereitungValue"
    [values]="aufbereitungValues"
    [isOptional]="true"
    (submitValueEmitter)="f.aufbereitung.setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <app-dropdown-strings *ngIf="showValue('aufbereitung_info')"
    [title]="'Aufbereitung-Info'"
    [placeholder]="'---'"
    [formcontrol]="f.aufbereitung_info"
    [headerText]="aufbereitungInfoValue"
    [values]="aufbereitungInfoValues"
    [isOptional]="true"
    (submitValueEmitter)="f.aufbereitung_info.setValue($event); getEnumDataOnChange();"
  ></app-dropdown-strings>
  <app-dropdown-multiple *ngIf="showValue('aufbereitung_zustaendigkeit')"
      [title]="'Aufbereitung Zuständigkeit'"
      [formcontrol]="f.aufbereitung_zustaendigkeit"
      [headerText]="aufbereitungZustValue"
      [values]="aufbereitungZustValues"
      [isOptional]="true"
      (submitValueEmitter)="f.aufbereitung_zustaendigkeit.setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <app-dropdown-multiple
    [title]="'Dellen'"
    [formcontrol]="f.damages"
    [headerText]="damageValue"
    [values]="damagesValues"
    [isOptional]="true"
    (submitValueEmitter)="f.damages.setValue($event); getEnumDataOnChange()"
  ></app-dropdown-multiple>
  <div class="input_wrapper">
    <label class="overview_record_lbl">Dellen-Info</label>
    <input type="text" class="overview_input" placeholder="Dellen-Info" formControlName="dellen_info">
  </div>
  <div class="input_wrapper">
    <div class="overview_record_lbl">Letzte Bewegung</div>
    <input #inputDate5 type="date" formControlName="last_movement" [class.date-input--has-value]="inputDate5.value != null" [class.input_invalid]="submitted && f.last_movement.errors">
  </div>
  <app-dropdown-multiple *ngIf="showValue('repair_status')"
      [title]="'Reparatur'"
      [formcontrol]="f.repair_status"
      [headerText]="repairStatenValue"
      [values]="repairStatenValues"
      [isOptional]="true"
      (submitValueEmitter)="f.repair_status.setValue($event); getEnumDataOnChange();"
    ></app-dropdown-multiple>
    <app-dropdown-multiple *ngIf="showValue('ersatzteile')"
    [title]="'Ersatzteile'"
    [formcontrol]="f.ersatzteile"
    [headerText]="ersatzTeileValue"
    [values]="ersatzTeileValues"
    [isOptional]="true"
    (submitValueEmitter)="f.ersatzteile.setValue($event); getEnumDataOnChange();"
  ></app-dropdown-multiple>
  <app-dropdown-multiple *ngIf="showValue('lackierung_notwendig')"
      [title]="'Lackierung'"
      [formcontrol]="f.lackierung_notwendig"
      [headerText]="lackierungValue"
      [values]="lackierungValues"
      [isOptional]="true"
      (submitValueEmitter)="f.lackierung_notwendig.setValue($event); getEnumDataOnChange();"
    ></app-dropdown-multiple>
  <div class="input_wrapper" *ngIf="showValue('pickerl_valid')">
    <label class="overview_record_lbl">§57a fällig am</label>
    <input #inputDate7 type="month" formControlName="pickerl_valid" [class.date-input--has-value]="inputDate7.value != null" [class.input_invalid]="submitted && f.pickerl_valid.errors">
  </div>
</form>
