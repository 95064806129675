import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import * as moment from 'moment';
import { getTimestampFromString } from "../helpers/HelperFunctions";

export function validDatePast(c: FormControl) {
  if(c.value == null || c.value?.length == 0) return null;
  else {
    return c.value.length == 10 && getTimestampFromString(c.value) <= new Date().getTime() ? null : {
      validDatePast: {
        valid: false
      }
    };
  }
}

export function validDateFuture(c: FormControl) {
  if(c.value == null || c.value?.length == 0) return null;
  else {
    return c.value.length == 10 && getTimestampFromString(c.value) >= new Date().getTime() ? null : {
      validDateFuture: {
        valid: false
      }
    };
  }
}

export function validDateFutureMonth(c: FormControl) {
  if(c.value == null || c.value?.length == 0) return null;
  else {
    return c.value.length == 7 && getTimestampFromString(c.value) >= new Date().getTime() ? null : {
      validDateFuture: {
        valid: false
      }
    };
  }
}

export function validBirthdate(c: FormControl) {
  if(c.value == null || c.value?.length == 0) return null;
  else {
    let now = new Date();
    if(c.value.length == 10 && getTimestampFromString(c.value) > new Date(now.getFullYear() - 18, now.getMonth(), now.getDate()).getTime()){
      return {
          validDatePast: {
          valid: false
        }
      }
    }
    return c.value.length == 10 && getTimestampFromString(c.value) <= new Date().getTime() ? null : {
      validDatePast: {
        valid: false
      }
    };
  }
}

export function validateDateInputAndTime(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if(control.value){
      const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
      if(control.value && control.value._f == 'DD.MM.YYYY'){
        if(date_regex.test(control.value._i)){
          let today = new Date();
          let values = control.value._i.split('.');
          let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
          if(input.getTime() < today.getTime()){
            return null;
          }
        }
      } else if(control.value && moment.isMoment(control.value)){
        return null;
      } else if(control.value == null){
        return null;
      }
    }
    return {
      validateDateInput: {
        valid: false
      }
    }
  }
}

export function IsValidDateInput(value: string): boolean {
  if(value && value.length > 0){
    const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
    if(date_regex.test(value)) return true;
    else return false;
  } else return true;
}

export function validateNumberOnlyInteger(c: FormControl) {
  return c.value % 1 == 0 ? null : {
    validateNumberOnlyInteger: {
      valid: false
    }
  };
}
export function validateDateInput(c: FormControl): any {
  const date_regex = /^\d{2}\/\d{4}$/;
  if(c.value && c.value._f == 'MM/YYYY'){
    if(date_regex.test(c.value._i)){
      let today = new Date();
      let values = c.value._i.split('/');
      let input = new Date(values[1],values[0]);
      if(input.getTime() < today.getTime()){
        return null;
      }
    }
  } else if(c.value && moment.isMoment(c.value)){
    return null;
  } else if(c.value == null){
    return null;
  }
  return {
    validateDateInput: {
      valid: false
    }
  }
}
export function validateDateInputNotPast(c: FormControl): any {
  const date_regex = /^\d{2}\/\d{4}$/;
  if(c.value && c.value._f == 'MM/YYYY'){
    if(date_regex.test(c.value._i)){
      let today = new Date();
      let values = c.value._i.split('/');
      let input = new Date(values[1],values[0]);
      if(input.getTime() > today.getTime()){
        return null;
      }
    }
  } else if(c.value && moment.isMoment(c.value)){
    return null;
  } else if(c.value == null){
    return null;
  }
  return {
    validateDateInput: {
      valid: false
    }
  }
}
export function validateDateInputLong(c: FormControl): any {
  const pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}/;
  // const date_regex = /^\d{2}\.\d{2}\.\d{4}$/;
  if(c.value && c.value._f == 'DD.MM.YYYY'){
    if(pattern.test(c.value._i)){
      let today = new Date();
      let values = c.value._i.split('.');
      let input = new Date(values[2],(Number(values[1])-1),Number(values[0]));
      if(input.getTime() < today.getTime()){
        return null;
      }
    } else {
      return {
        validateDateInput: {
          valid: false
        }
      }
    }
  } else if(c.value && moment.isMoment(c.value)){
    return null;
  } else if(c.value == null){
    return null;
  }
  return {
    validateDateInput: {
      valid: false
    }
  }
}


