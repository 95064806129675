import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-start-container',
  templateUrl: './start-container.component.html',
  styleUrls: ['./start-container.component.css']
})
export class StartContainerComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() createChoiseEmitter = new EventEmitter<number>();
  @Output() vinEmitter = new EventEmitter<string>();

  public submitted = false;
  public vinEnabled = false;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');

  constructor(
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  vinRequest(vin: string) {
    this.submitted = true;
    if(vin.length > 8){
      this.form.controls["category"].setValue(new VehicleCategory(2,"Gebrauchtwagen"));
      // this.form.controls["firstRegistrationDate"].setErrors(null);
      // this.form.updateValueAndValidity();
      this.vinEmitter.emit(vin);
    } else {
      // this.form.controls["firstRegistrationDate"].setErrors({"incorrect": true});
      // this.form.updateValueAndValidity();
    }
  }

  createManuell() {
    //this.form.controls["category"].setValue(new VehicleCategory(1,"Neuwagen"));
    //this.form.controls["firstRegistrationDate"].setValue(null);
    this.createChoiseEmitter.emit(1);
  }
}
