import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { ErrorHelper } from 'src/app/error/ErrorHelper';

@Component({
  selector: 'app-kunde-create',
  templateUrl: './kunde-create.component.html',
  styleUrl: './kunde-create.component.css'
})
export class KundeCreateComponent extends BaseComponent implements OnInit{
  @Input() obj: Customer;
  @Input() employees: Employee[];
  @Output() updateSuccessEmitter = new EventEmitter<any>();
  @Output() selectionCancelEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public customers: Customer[] = [];
  public submitted = false;
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];
  public titleValues = [];

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private dialogService: DialogService,
  ){
    super(authService, eService);
  }


  ngOnInit(): void {
    this.setUp();
  }

  setUp() {
    this.titleValues = this.enumService.getEnumValues('title');
    this.form = CustomerForm.getCustomerForm(this.formBuilder, this.obj);
  }

  selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    let customer = CustomerForm.createObject(this.form);
    this.customerService.updateCustomer(customer).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    });
  }

  selectionCancel() {
    this.selectionCancelEmitter.emit();
  }

}
