import { Customer } from "../customer/Customer";
import { Picture } from "../picture/Picture";
import { VehicleBrand } from "../vehicle/basic/VehicleBrand";
import { VehicleModel } from "../vehicle/basic/VehicleModel";
import { Vehicle } from "../vehicle/Vehicle";
import { StoragePlace } from "./StoragePlace";

export enum Execution {
  REIFEN = 'Reifen',
  FELGEN = 'Felgen',
  REIFENUNDFELGEN = 'Reifen und Felgen',
}

export enum TireStatus {
  DRAFT = 0,
  STORED = 1,
  PUBLISHED = 2,
  ARCHIVED = 10,
}

export class Tire {
  public customer: Customer;
  public storage_place: StoragePlace;
  public designation_tire: string;
  public designation_rim: string;
  public execution: string;
  public storage_time: number;
  public thumbnail: string;
  public vehicle: Vehicle;
  public purchased_with_vehicle: Vehicle;
  public storage_location_id: number;
  public dimensions_b: string;
  public dimensions_t: string;
  public dimensions_z: string;
  constructor(
    public tire_id: number,
    public location_id: number,
    public vehicle_id: number,
    public storage_place_id: number,
    public amount: number,
    public price: number,
    public platform_online: boolean,
    public freigeschaltet_am_plattform: boolean,
    public description: string,
    public status: number,
    public created: number,
    public last_updated: number,
    public tire_type: number,
    public tire_brand: number,
    public tire_model: string,
    public tire_width_1: number,
    public tire_width_2: number,
    public tire_height_1: number,
    public tire_height_2: number,
    public tire_inches_1: number,
    public tire_inches_2: number,
    public tire_index_1: string,
    public tire_index_2: string,
    public rim_type: number,
    public rim_brand: number,
    public rim_model: number,
    public rim_width_1: number,
    public rim_width_2: number,
    public rim_inches_1: number,
    public rim_inches_2: number,
    public rim_et_1: number,
    public rim_et_2: number,
    public rim_screws: number,
    public rim_bolt_circle: number,
    public rim_external_id: string,
    public details: TireDetail[],
    public pictures: Picture[],
    public purchased_with_vehicle_id: number,
    public not_for_sale: boolean,
    public tire_pressure_sensor: boolean,
    public vehicle_make: VehicleBrand,
    public tire_brand_name: string
    //public vehicle_model: VehicleModel
  ){
    this.dimensions_b = (this.tire_width_1 ? this.tire_width_1 : '-') + '/' + (this.tire_width_2 ? this.tire_width_2 : '-');
    this.dimensions_t = (this.tire_height_1 ? this.tire_height_1 : '-') + '/' + (this.tire_height_2 ? this.tire_height_2 : '-');
    this.dimensions_z = (this.tire_inches_1 ? this.tire_inches_1 : '-') + '/' + (this.tire_inches_2 ? this.tire_inches_2 : '-');
  }

  getStoragePlaceSortValue(compareTire: Tire): number {
    if(this.storage_place && compareTire.storage_place) {
      let storagePlaceName = this.splitString(this.storage_place.place_name);
      let compareStoragePlaceName = this.splitString(compareTire.storage_place.place_name);
      if(storagePlaceName[0] === compareStoragePlaceName[0]) {
        if(storagePlaceName[1] === compareStoragePlaceName[1]) {
          return 0;
        } else if(Number(storagePlaceName[1]) < Number(compareStoragePlaceName[1])) {
          return -1;
        } else {
          return 1;
        }
      } else if(storagePlaceName[0] < compareStoragePlaceName[0]) {
        return -1;
      } else {
        return 1;
      }
    } else if(this.storage_place) {
      return -1;
    } else {
      return 1;
    }
  }

  splitString(s: string): [string, string] {
    const match = s.match(/^([a-zA-Z]+)(\d+)$/);
    if (match) {
        return [match[1], match[2]];
    } else {
        return [s, "0"];
    }
}

  getExecution(): string {
    if(this.tire_type != null && this.rim_type != null) return Execution.REIFENUNDFELGEN;
    else if(this.tire_type != null) return Execution.REIFEN;
    else if(this.rim_type != null) return Execution.FELGEN;
    return "";
  }

  getJSON() {
    let detailsJson = [];
    for(let details of this.details){
      detailsJson.push({
        "tire_detail_id": details.tire_detail_id,
        "tire_id": details.tire_id,
        "depth": details.depth,
        "year": details.year,
        "tyres_equal": details.tyres_equal,
      })
    }
    let picturesJson = [];
    for(let pictures of this.pictures){
      picturesJson.push({
        "picture_id": pictures.picture_id,
        "position": pictures.position,
      })
    }
    return {
      "tire_id": this.tire_id,
      "location_id": this.location_id,
      "vehicle_id": this.vehicle_id,
      "storage_place_id": this.storage_place?.storage_place_id,
      "amount": this.amount,
      "price": this.price,
      "platform_online": this.platform_online,
      "description": this.description,
      "status": this.status,
      "created": this.created,
      "last_updated": this.last_updated,
      "tire_type": this.tire_type,
      "tire_brand": this.tire_brand,
      "tire_model": this.tire_model,
      "tire_width_1": this.tire_width_1,
      "tire_width_2": this.tire_width_2,
      "tire_height_1": this.tire_height_1,
      "tire_height_2": this.tire_height_2,
      "tire_inches_1": this.tire_inches_1,
      "tire_inches_2": this.tire_inches_2,
      "tire_index_1": this.tire_index_1,
      "tire_index_2": this.tire_index_2,
      "rim_type": this.rim_type,
      "rim_brand": this.rim_brand,
      "rim_model": this.rim_model,
      "rim_width_1": this.rim_width_1,
      "rim_width_2": this.rim_width_2,
      "rim_inches_1": this.rim_inches_1,
      "rim_inches_2": this.rim_inches_2,
      "rim_et_1": this.rim_et_1,
      "rim_et_2": this.rim_et_2,
      "rim_screws": this.rim_screws,
      "rim_bolt_circle": this.rim_bolt_circle,
      "rim_external_id": this.rim_external_id,
      "pictures": picturesJson,
      "details": detailsJson,
      "purchased_with_vehicle_id": this.purchased_with_vehicle_id,
      "not_for_sale": this.not_for_sale,
      "storage_location_id": this.storage_location_id,
      "tire_pressure_sensor": this.tire_pressure_sensor,
      "vehicle_make" : {
        "make_id": this.vehicle_make ? this.vehicle_make.id : null
      },
      "tire_brand_name" : this.tire_brand_name
    }
  }
}

export class TireDetail {
  constructor(
    public tire_detail_id: number,
    public tire_id: number,
    public depth: number,
    public year: string,
    public tyres_equal: boolean
  ){}
}
