<div class="dropdown_component_wrapper">
  <div class="overview_record_lbl">{{title}}</div>
  <div class="dropdown_button">
      <div class="dropdown_button_header" [class.dropdown_button_invalid]="submitted && formcontrol.invalid" (click)="show = !show" appDropdown [show]="show" (changedValueEmitter)="show = $event">
          <input type="text" class="dropdown_header_data_lbl_overview" [ngClass]="formcontrol.value == null ? 'dropdown_header_lbl_placeholder_overview' : ''" [formControl]="formcontrol" placeholder="{{placeholder}}">
          <img class="dropdown_button_header_img" [ngClass]="show ? 'dropdown_button_header_img_active' : ''" src="./assets/arrow-dark.svg">
      </div>
      <div #dropdownBox class="dropdown_button_box" *ngIf="show">
        <label *ngIf="isOptional" (click)="submitValueEmitter.emit(null); show = false">--</label>
          <label *ngFor="let value of values; let i = index" (click)="submitValueEmitter.emit(value); show = false">{{value}}</label>
      </div>
  </div>
</div>
