<div class="dropdown_component_wrapper" [class.dropdown_component_wrapper_standalone]="!name">
  <div class="overview_record_lbl" *ngIf="name">{{name}}</div>
  <div class="dropdown_button">
    <div class="dropdown_button_header"
      [class.dropdown_button_highlighted]="isHighlighted && !submitted && selectedValue == null"
      [class.dropdown_button_invalid]="submitted && formControl.invalid"
      (click)="toggleDropdown()"
      appDropdown [show]="show"
      (changedValueEmitter)="show = $event">
        <div class="dropdown_header_data_lbl_overview"
          [class.dropdown_header_lbl_placeholder_overview]="!selectedValue">
          {{!selectedValue ? placeholder : selectedValue}}
        </div>
        <img class="dropdown_button_header_img"
          [ngClass]="show ? 'dropdown_button_header_img_active' : ''"
          src="./assets/arrow-dark.svg">
    </div>
    <div #dropdownBox class="dropdown_button_box" *ngIf="show">
      <label *ngIf="isOptional" (click)="selectionValue(null)">---</label>
      <label *ngFor="let value of values;" (click)="selectionValue(value)">{{value}}</label>
    </div>
  </div>
</div>
