import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Address } from "../../customer/Adress";
import { DateHelper } from "src/app/utils/DateHelper";
import { Bill } from "../Bill";
import { CustomerForm } from "../../customer/form/CustomerForm";


export class BillPurchaseContractForm {

  public static getForm(formBuilder: FormBuilder, obj?: Bill): FormGroup {
    let customer = obj.purchase_contract.customer;
    if(obj.bill_recipient != null) {
      if(obj.bill_recipient.typ == undefined) {
        obj.bill_recipient.typ = obj.bill_recipient.companyName ? 1 : 2;
      }
      customer = obj.bill_recipient;
    }
    let form = formBuilder.group({
      vehicle_co2: [obj.purchase_contract.vehicle.co2Emission, Validators.required],
      steuersatz: [obj.purchase_contract.vehicle.priceSheet.steuersatz, Validators.required],
      vehicle_imported: [obj.purchase_contract.vehicle.priceSheet.vehicle_imported, Validators.required],
      customer_form: [CustomerForm.getCustomerForm(formBuilder,customer), Validators.required],
      custom_text: [obj?.custom_text??null],
    });
    return form;
  }

  public static getObject(form: FormGroup, obj: Bill): Bill {
    obj.purchase_contract.vehicle.co2Emission = form.get('vehicle_co2').value;
    obj.purchase_contract.vehicle.priceSheet.steuersatz = form.get('steuersatz').value;
    obj.purchase_contract.vehicle.priceSheet.vehicle_imported = form.get('vehicle_imported').value;
    obj.purchase_contract.customer = CustomerForm.createObject(form.get('customer_form').value);
    obj.custom_text = form.get('custom_text').value;
    return obj;
  }
}
