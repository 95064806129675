<div class="page">
  <div *ngIf="showView == DokumentenView.DokumentListe">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <button class="button_type_1" (click)="showDropdownMain = !showDropdownMain" appDropdown [show]="showDropdownMain" (changedValueEmitter)="showDropdownMain = $event">
          Neu
          <div *ngIf="showDropdownMain">
            <button (click)="selectionCreateContract(); showDropdownMain = false">Kaufvertrag</button>
          </div>
        </button>
        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [accessEdit]="accessEdit"
        [objectTyp]="'kaufvertrag'"
        [objects]="_objectsFiltered.getValue()"
        [showInformation]="showInformation"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [sortObject]="sortObject"
        [filterForm]="filterForm"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        [activeFilter]="activeFilter"
        [employees]="employees"
        (pageEmitter)="pageHandling($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (deleteEmitter)="selectionDelete($event)"
        (selectionUploadDocumentEmitter)="selectionUploadDocumentAction($event, true)"
        (selectionPseudoSignatureEmitter)="selectionUploadDocumentAction($event, false)"
        (applySortingEmitter)="applySorting($event)"
        (customFilterEmitter)="customFilter($event)"
        (customFilterOutsideEmitter)="customFilterOutside($event)"
        (selectionContractPDFUnsigendSignedEmitter)="getContractPDFUnsignedSigned($event)"
        (moveEmitter)="selectionMoveContract($event)"
        (selectionRestoreEmitter)="selectionRestore($event)"
        (selectionChangePaymentStatusEmitter)="selectionChangePaymentStatus($event)"
        (selectionAppSignatureEmitter)="selectionAppSignature($event)"
      ></app-list-container>
  </div>

  <app-dokumente-kaufvertrag-erstellen *ngIf="showView == DokumentenView.DokumentKaufVertragErstellen"
    [document]="selectedDocument"
    [zusatzprodukte]="zusatzprodukte"
    (selectionCancelEmitter)="selectionClose()"
    (createSuccessEmitter)="createKaufvertragSuccess($event)"
    (updateSuccessEmitter)="updateKaufvertragSuccess($event)"
  ></app-dokumente-kaufvertrag-erstellen>
  <app-dokumente-kaufvertrag-details *ngIf="showView == DokumentenView.DokumentKaufvertragDetails"
    [document]="selectedDocument"
    [zusatzprodukte]="zusatzprodukte"
    (selectionBackEmitter)="selectionClose()"
    (selectionEditEmitter)="selectionEdit()"
    (selectionUploadDocumentEmitter)="selectionUploadDocumentAction($event, true)"
    (selectionPseudoSignatureEmitter)="selectionUploadDocumentAction($event, false)"
    (selectionContractStornoEmitter)="openDialogStorno($event)"
    (selectionAnzahlungKassierenEmitter)="selectionAnzahlungKassieren($event)"
    (selectionEndzahlungKassierenEmitter)="selectionEndzahlungKassieren($event)"
    (selectionContractPDFUnsigendSignedEmitter)="getContractPDFUnsignedSigned($event)"
    (selectionContractRenewEmitter)="openDialogRenew($event)"
    (selectionAppSignatureEmitter)="selectionAppSignature($event)"
  ></app-dokumente-kaufvertrag-details>
</div>

<input id="input-file" type="file" accept="application/pdf" (change)="uploadFile($event)" multiple="" style="display: none;">
