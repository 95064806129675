import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bill-create-position-element',
  templateUrl: './bill-create-position-element.component.html',
  styleUrl: './bill-create-position-element.component.css'
})
export class BillCreatePositionElementComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() index: number;
  @Input() submitted: boolean;
  @Output() removeElementEmitter = new EventEmitter<number>();
  public taxes = ['20%','10%','0'];
  public taxesValues = [
    {
      name: '20%',
      value: 20
    },
    {
      name: '10%',
      value: 10
    },
    {
      name: '0%',
      value: 0
    }
  ]
  public selectedTax;

  constructor(){}

  ngOnInit(): void {
    if(this.form.get('tax_rate').value){
      let i = this.taxesValues.findIndex((v) => v.value == this.form.get('tax_rate').value);
      if(i > 0) this.selectedTax = this.taxesValues[i].name;
    } else {
      this.selectedTax = this.taxes[0];
      this.form.get('tax_rate').setValue(20);
    }
  }

  selectionTax(value) {
    this.selectedTax = value;
    let i = this.taxesValues.findIndex((v) => v.name == value);
    if(i > 0) this.form.get('tax_rate').setValue(this.taxesValues[i].value);
  }
}
