<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionCancel()">
      <img class="button-icon-start-img" src="./assets/cancle.svg"/>
      <span class="button-icon-start-span">Abbrechen</span>
    </button>
    <h2>Probefahrten-Formular {{object!=null ? 'bearbeiten' : 'erstellen'}} ({{typ == 'customer' ? 'Kunde' : 'Mitarbeiter'}})</h2>
  </div>
  <div class="header-button-wrapper">
    <button class="btn_submit" (click)="selectionCreate()">Einplanen</button>
  </div>
</div>

<form [formGroup]="form" id="drive-create-body" class="body-base-style">
  <div class="column">
    <div class="overview_data_box">
      <h3>Kennzeichen</h3>
      <app-dropdown-multiple
        [title]="'Kennzeichen *'"
        [formcontrol]="form.controls['license_plate_id']"
        [headerText]="selectedPlateValue"
        [values]="platesValues"
        [submitted]="submitted"
        (submitValueEmitter)="setPlate($event);"
      ></app-dropdown-multiple>
    </div>
    <div class="overview_data_box">
      <h3>Details zur Probefahrt</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Datum *</label>
        <input #inputDate1 type="date" placeholder="date"  formControlName="start_date" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['start_date'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Uhrzeit *</label>
          <input #inputDate2 type="time" placeholder="time"  formControlName="start_time" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.controls['start_time'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Dauer in Min. *</label>
          <input #inputDate3 type="number" placeholder="Dauer"  formControlName="duration" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && form.controls['duration'].errors">
      </div>
      <div class="input_wrapper">
          <label class="overview_record_lbl">Ziel der Probefahrt *</label>
          <input #inputDate4 type="text" placeholder="Von - Nach"  formControlName="trip_description" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && form.controls['trip_description'].errors">
      </div>
    </div>
  </div>

  <div class="column">
    <div class="overview_data_box">
      <h3>Fahrzeug</h3>
      <app-dropdown-objects-search
        [title]="'Fahrzeug *'"
        [placeholder]="'Fahrzeug'"
        [headervalue]="form.controls['vehicle'].value ? form.controls.vehicle.value.getVehicleName() : ''"
        [formcontrol]="form.controls['vehicle']"
        [values]="vehicleValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('vehicle', $event)"
      ></app-dropdown-objects-search>
    </div>
    <form [formGroup]="formVehicle" class="overview_data_box" *ngIf="form.get('vehicle_to_create').value == true">
      <label class="overview_data_box_lbl_title">Fahrzeug anlegen</label>
      <div class="overview_data_box_inner">
        <app-dropdown-objects-search
          [title]="'Marke *'"
          [placeholder]="'Marke'"
          [headervalue]="formVehicle.controls['brand'].value ? formVehicle.controls['brand'].value?.makeName : ''"
          [formcontrol]="formVehicle.controls['brand']"
          [values]="brandValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('brand', $event)"
        ></app-dropdown-objects-search>
        <app-dropdown-objects-search
            [title]="'Model *'"
            [placeholder]="'Model'"
            [headervalue]="formVehicle.controls['model'].value ? formVehicle.controls['model'].value?.name : ''"
            [formcontrol]="formVehicle.controls['model']"
            [values]="modelValues"
            [submitted]="submitted"
            (submitValueEmitter)="setValueFromDropdown('model', $event)"
        ></app-dropdown-objects-search>
        <div class="input_wrapper">
          <label class="overview_record_lbl" style="align-self: flex-start;">Version *</label>
          <div class="version_container">
              <input type="text" maxlength="80" placeholder="Version" formControlName="version" [class.input_invalid]="submitted && formVehicle['version'].errors">
              <span class="counter_lbl lbl_regular_italic_light_color">({{counterVersion}} Zeichen übrig)</span>
          </div>
        </div>
        <app-dropdown-objects
          [title]="'Treibstoff *'"
          [placeholder]="'Treibstoff'"
          [headervalue]="formVehicle.controls.fuel.value?.name"
          [formcontrol]="formVehicle.controls.fuel"
          [values]="fuelsValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
        ></app-dropdown-objects>
        <app-dropdown-objects
          [title]="'Antriebsart *'"
          [placeholder]="'Antriebsart'"
          [headervalue]="formVehicle.controls.drive.value?.name"
          [formcontrol]="formVehicle.controls.drive"
          [values]="drivesValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('drive', $event)"
        ></app-dropdown-objects>
        <app-dropdown-objects
          [title]="'Getriebeart *'"
          [placeholder]="'Getriebeart'"
          [headervalue]="formVehicle.controls.transmission.value?.name"
          [formcontrol]="formVehicle.controls.transmission"
          [values]="transmissionsValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
        ></app-dropdown-objects>
        <app-dropdown-objects
          [title]="'KFZ-Typ *'"
          [placeholder]="'KFZ-Typ'"
          [headervalue]="formVehicle.controls.bodyTyp.value?.name"
          [formcontrol]="formVehicle.controls.bodyTyp"
          [values]="bodytypValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
        ></app-dropdown-objects>
        <app-dropdown-objects
          [title]="'Fahrzeugart *'"
          [placeholder]="'Fahrzeugart'"
          [headervalue]="formVehicle.controls.category.value?.name"
          [formcontrol]="formVehicle.controls.category"
          [values]="categorieValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('category', $event)"
        ></app-dropdown-objects>
        <div class="input_wrapper" *ngIf="formVehicle.controls.category.value && formVehicle.controls.category.value.id != 1">
          <div class="overview_record_lbl">Erstzulassung *</div>
          <input #inputDate1 type="date" placeholder="date" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formVehicle.controls.firstRegistrationDate.errors">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Türen *</label>
          <input type="number" placeholder="Türen" formControlName="numOfDoors" [class.input_invalid]="submitted && f['numOfDoors'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Sitze *</div>
          <input type="number" placeholder="Sitze" formControlName="numOfSeats" [class.input_invalid]="submitted && f['numOfSeats'].errors">
        </div>
        <app-dropdown-objects
          [title]="'Hauptfarbe *'"
          [placeholder]="'Hauptfarbe'"
          [headervalue]="formVehicle.controls.bodyColor.value?.name"
          [formcontrol]="formVehicle.controls.bodyColor"
          [values]="bodyColorValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
        ></app-dropdown-objects>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Kilometerstand *</div>
          <input type="number" placeholder="---" formControlName="mileageKm" [class.input_invalid]="submitted && formVehicle.controls['mileageKm'].errors">
        </div>
        <div class="input_container">
          <div class="power_header">
              <div class="slider_grid">
                  <div class="switch_lbl">
                      <div *ngIf="formVehicle.controls.powerTyp.value == 1" [ngStyle]="{'display': 'flex'}"><div [ngStyle]="{'color': '#2C2C2C'}">PS</div>&nbsp;/ KW</div>
                      <div *ngIf="formVehicle.controls.powerTyp.value == 2" [ngStyle]="{'display': 'flex'}">PS /&nbsp;<div [ngStyle]="{'color': '#2C2C2C'}">KW</div></div>
                  </div>
                  <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                      <input type="checkbox" [checked]="formVehicle.controls.powerTyp.value == 2" (click)="changePowervalue()">
                      <span class="slider round"></span>
                  </label>
              </div>
          </div>
          <div *ngIf="formVehicle.controls.powerTyp.value == 1" class="input_wrapper">
            <label class="overview_record_lbl">Leistung (PS) *</label>
            <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && f['power'].errors">
          </div>
          <div *ngIf="formVehicle.controls.powerTyp.value == 2" class="input_wrapper">
            <label class="overview_record_lbl">Leistung (KW) *</label>
            <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && f['power'].errors">
          </div>
       </div>
      </div>
    </form>
  </div>

  <div class="column">
    <div class="overview_data_box" *ngIf="typ == 'customer'">
      <h3>Kunde</h3>
      <app-dropdown-objects-search
        [title]="'Kunde*'"
        [placeholder]="'Kunde'"
        [headervalue]="form.controls['customer']?.value?.getName()"
        [formcontrol]="form.controls['customer']"
        [values]="customerValues"
        [submitted]="submitted"
        [showLabel]="false"
        (submitValueEmitter)="setValueFromDropdown('customer', $event)"
      ></app-dropdown-objects-search>
    </div>
    <form [formGroup]="formCustomer" class="column" *ngIf="typ == 'customer' && form.get('customer_form').value">
      <div class="overview_data_box">
        <h3>Informationen zum Führerschein</h3>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Nummer</label>
            <input
              type="text"
              placeholder="Führerschein-Nummer"
              formControlName="authorization_number"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_number.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Behörde</label>
            <input
              type="text"
              placeholder="Führerschein-Nummer"
              formControlName="authorization_authority"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_authority.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Datum</label>
            <input
              #inputDate1
              type="date"
              placeholder="Führerschein-Datum"
              formControlName="authorization_date"
              [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && formCustomer.controls.authorization_date.errors">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Führerschein-Gruppen</label>
            <input
              type="text"
              placeholder="Führerschein-Gruppen"
              formControlName="authorization_groups"
              [class.input_invalid]="submitted && formCustomer.controls.authorization_groups.errors">
        </div>
      </div>
    </form>
    <div class="overview_data_box" *ngIf="typ == 'intern'">
      <h3>Mitarbeiter</h3>
      <app-dropdown-objects-search
        [title]="'Mitarbeiter *'"
        [placeholder]="'Mitarbeiter'"
        [headervalue]="form.controls['employee'].value ? form.controls['employee'].value.getFullName() : ''"
        [formcontrol]="form.controls['employee']"
        [values]="employeeValues"
        [submitted]="submitted"
        (submitValueEmitter)="setValueFromDropdown('employee', $event)"
    ></app-dropdown-objects-search>
    </div>
  </div>
</form>
