import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Tire, TireDetail } from "../Tire";

export class TireForm {

  public static getForm(formBuilder: FormBuilder, obj?: Tire): FormGroup {
    let detailsForms = [];
    if(obj){
      if(obj.details.length == 4 &&
          obj.details[0].depth == obj.details[1].depth &&
          obj.details[0].year == obj.details[1].year &&
          obj.details[2].depth == obj.details[3].depth &&
          obj.details[2].year == obj.details[3].year){
        for(let details of obj.details){
          let f = this.createDetailsForm(formBuilder, details);
          f.get('tyres_equal').setValue(true);
          detailsForms.push(f);
        }
      } else {
        for(let details of obj.details){
          detailsForms.push(this.createDetailsForm(formBuilder, details));
        }
      }
    } else {
      detailsForms.push(this.createDetailsForm(formBuilder));
      detailsForms.push(this.createDetailsForm(formBuilder));
      detailsForms.push(this.createDetailsForm(formBuilder));
      detailsForms.push(this.createDetailsForm(formBuilder));
      detailsForms[0].get('tyres_equal').setValue(true);
      detailsForms[1].get('tyres_equal').setValue(true);
      detailsForms[2].get('tyres_equal').setValue(true);
      detailsForms[3].get('tyres_equal').setValue(true);
    }
    let form = formBuilder.group({
      tire_id: [this.getFormValue(obj, 'tire_id')],
      location_id: [this.getFormValue(obj, 'location_id')],
      vehicle_id: [this.getFormValue(obj, 'vehicle_id')],
      storage_place_id: [obj && obj?.storage_place ? obj.storage_place.storage_place_id : null],
      storage_place: [this.getFormValue(obj, 'storage_place')],
      amount: [this.getFormValue(obj, 'amount', 4)],
      tire_price: [this.getFormValue(obj, 'price')],
      platform_online: [obj ? obj.platform_online : false],
      freigeschaltet_am_plattform: [this.getFormValue(obj, 'freigeschaltet_am_plattform')],
      description: [this.getFormValue(obj, 'description')],
      status: [obj ? obj.status : 0],
      created: [this.getFormValue(obj, 'created')],
      last_updated: [this.getFormValue(obj, 'last_updated')],
      tire_type: [this.getFormValue(obj, 'tire_type')],
      tire_brand: [this.getFormValue(obj, 'tire_brand')],
      tire_model: [this.getFormValue(obj, 'tire_model')],
      tire_width_1: [this.getFormValue(obj, 'tire_width_1')],
      tire_width_2: [this.getFormValue(obj, 'tire_width_2')],
      tire_height_1: [this.getFormValue(obj, 'tire_height_1')],
      tire_height_2: [this.getFormValue(obj, 'tire_height_2')],
      tire_inches_1: [this.getFormValue(obj, 'tire_inches_1')],
      tire_inches_2: [this.getFormValue(obj, 'tire_inches_2')],
      tire_index_1: [this.getFormValue(obj, 'tire_index_1')],
      tire_index_2: [this.getFormValue(obj, 'tire_index_2')],
      rim_type: [this.getFormValue(obj, 'rim_type')],
      rim_brand: [this.getFormValue(obj, 'rim_brand')],
      rim_model: [this.getFormValue(obj, 'rim_model')],
      rim_width_1: [this.getFormValue(obj, 'rim_width_1')],
      rim_width_2: [this.getFormValue(obj, 'rim_width_2')],
      rim_inches_1: [this.getFormValue(obj, 'rim_inches_1')],
      rim_inches_2: [this.getFormValue(obj, 'rim_inches_2')],
      rim_et_1: [this.getFormValue(obj, 'rim_et_1')],
      rim_et_2: [this.getFormValue(obj, 'rim_et_2')],
      rim_screws: [this.getFormValue(obj, 'rim_screws')],
      rim_bolt_circle: [this.getFormValue(obj, 'rim_bolt_circle')],
      rim_external_id: [this.getFormValue(obj, 'rim_external_id')],
      details: [detailsForms],
      pictures: [[]],
      customer: [this.getFormValue(obj, 'customer')],
      vehicle: [this.getFormValue(obj, 'vehicle')],
      purchased_with_vehicle: [this.getFormValue(obj, 'purchased_with_vehicle')],
      execution: [this.getFormValue(obj, 'execution'), Validators.required],
      thumbnail: [this.getFormValue(obj, 'thumbnail')],
      purchased_with_vehicle_id: [this.getFormValue(obj, 'purchased_with_vehicle_id')],
      not_for_sale: [this.getFormValue(obj, 'not_for_sale', false)],
      storage_location_id: [this.getFormValue(obj, 'storage_location_id', false)],
      tire_pressure_sensor: [this.getFormValue(obj, 'tire_pressure_sensor', false)],
      vehicle_make: [this.getFormValue(obj, 'vehicle_make', false)],
      tire_brand_name: [this.getFormValue(obj, 'tire_brand_name')],
    });
    return form;
  }

  public static createDetailsForm(formBuilder: FormBuilder, obj?: TireDetail): FormGroup {
    return formBuilder.group({
      tire_detail_id: [this.getFormValue(obj, 'tire_detail_id')],
      tire_id: [this.getFormValue(obj, 'tire_id')],
      depth: [this.getFormValue(obj, 'depth'), Validators.required],
      year: [this.getFormValue(obj, 'year'), [Validators.required]],
      tyres_equal: [this.getFormValue(obj, 'tyres_equal', false)],
    });
  }

  static getFormValue(obj: any, key: string, defaultValue?: any) {
    if(obj && key){
      if(obj[key] && obj[key] != '---') return obj[key];
    }
    return defaultValue != undefined ? defaultValue : null;
  }

  public static getObject(form: FormGroup): Tire {
    let details = [];
    for(let detailsForm of form.get('details').value){
      details.push(this.getDetailsObject(detailsForm));
    }
    if(details.length == 4 && details[0].tyres_equal && details[2].tyres_equal){
      details[1].depth = details[0].depth;
      details[1].year = details[0].year;
      details[3].depth = details[2].depth;
      details[3].year = details[2].year;
    }
    let obj = new Tire(
      form.get('tire_id').value,
      form.get('location_id').value,
      form.get('vehicle_id').value,
      form.get('storage_place_id').value,
      form.get('amount').value,
      form.get('tire_price').value,
      form.get('platform_online').value,
      form.get('freigeschaltet_am_plattform').value,
      form.get('description').value,
      form.get('status').value,
      form.get('created').value,
      form.get('last_updated').value,
      form.get('tire_type').value,
      form.get('tire_brand').value,
      form.get('tire_model').value,
      form.get('tire_width_1').value,
      form.get('tire_width_2').value,
      form.get('tire_height_1').value,
      form.get('tire_height_2').value,
      form.get('tire_inches_1').value,
      form.get('tire_inches_2').value,
      form.get('tire_index_1').value,
      form.get('tire_index_2').value,
      form.get('rim_type').value,
      form.get('rim_brand').value,
      form.get('rim_model').value,
      form.get('rim_width_1').value,
      form.get('rim_width_2').value,
      form.get('rim_inches_1').value,
      form.get('rim_inches_2').value,
      form.get('rim_et_1').value,
      form.get('rim_et_2').value,
      form.get('rim_screws').value,
      form.get('rim_bolt_circle').value,
      form.get('rim_external_id').value,
      details,
      [],
      form.get('purchased_with_vehicle_id').value,
      form.get('not_for_sale').value,
      form.get('tire_pressure_sensor').value,
      form.get('vehicle_make').value,
      form.get('tire_brand_name').value
    );
    obj.storage_place = form.get('storage_place').value;
    obj.vehicle = form.get('vehicle').value;
    obj.purchased_with_vehicle = form.get('purchased_with_vehicle').value;
    obj.storage_location_id = form.get('storage_location_id').value;
    return obj;
  }

  static getDetailsObject(form: FormGroup): TireDetail {
    return new TireDetail(
      form.get('tire_detail_id').value,
      form.get('tire_id').value,
      form.get('depth').value,
      form.get('year').value,
      form.get('tyres_equal').value
    );
  }
}
