<div id="overview_container" *ngIf="screenSize == 1">
    <div class="overview_grid" *ngIf="selectedDetailsTab == 1">
        <div class="overview_column" *ngIf="allowToSeeColumn(0, 0)">
            <app-basic-data *ngIf="allowToSeeDeep(0, 'basic-data')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-basic-data>
            <app-price *ngIf="allowToSeeDeep(0, 'price')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-price>
            <app-identification *ngIf="allowToSeeDeep(0, 'identification')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-identification>
            <app-vehicle *ngIf="allowToSeeDeep(0, 'vehicle')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                [activeSubMenu]="activeSubMenu"
                [superGroup]="0"
            ></app-vehicle>
            <app-drive *ngIf="allowToSeeDeep(0, 'drive')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [formGroupPriceSheet]="formGroupPriceSheet"
                [editMode]="editMode"
                [submitted]="submitted"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-drive>
        </div>
        <div class="overview_column" *ngIf="allowToSeeColumn(0, 1)">
            <app-condition *ngIf="(authService.ACCESS_ALL() || authService.isHoedl()) && allowToSeeDeep(0, 'condition')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                [activeSubMenu]="activeSubMenu"
                [superGroup]="0"
            ></app-condition>

            <app-interface-overview *ngIf="allowToSeeDeep(0, 'interface-overview')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-interface-overview>
            <app-additional *ngIf="allowToSeeDeep(0, 'additional')"
            [vehicle]="selectedVehicle"
            [formGroupVehicle]="formGroupVehicle"
            [editMode]="editMode"
            [submitted]="submitted"
            [activeSubMenu]="activeSubMenu"
            [superGroup]="0"
            ></app-additional>
            <app-service *ngIf="allowToSeeDeep(0, 'service')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-service>
            <app-garantie *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'service')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
            ></app-garantie>
            <app-werkstatt *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'werkstatt')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                [activeSubMenu]="activeSubMenu"
                [superGroup]="0"
            ></app-werkstatt>
            <app-seasonal *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'seasonal')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                [activeSubMenu]="activeSubMenu"
                [superGroup]="0"
            ></app-seasonal>
            <app-description *ngIf="!editMode && allowToSeeDeep(0, 'description')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-description>
            <app-rent *ngIf="authService.isHofbauer() || authService.isWaymark()"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-rent>
            <app-customercar *ngIf="selectedVehicle.customer_id && allowToSeeDeep(0,  'customercar')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
            ></app-customercar>
        </div>
        <div class="overview_column" *ngIf="allowToSeeColumn(0, 2)">
            <app-images *ngIf="allowToSeeDeep(0, 'images')"
                [vehicle]="selectedVehicle"
                [editMode]="editMode"
                [formGroupVehicle]="formGroupVehicle"
                [selectedDetailsTab]="selectedDetailsTab"
            ></app-images>
            <app-equipment *ngIf="allowToSeeDeep(0, 'equipment')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
            ></app-equipment>
            <app-attachment *ngIf="allowToSeeDeep(0, 'attachment')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
            ></app-attachment>
            <app-transactions *ngIf="!editMode && allowToSeeDeep(0, 'transactions') && !editMode"
              [obj]="selectedVehicle"
            ></app-transactions>
        </div>
    </div>
    <div class="overview_grid" *ngIf="selectedDetailsTab == 2">
        <div class="overview_column" *ngIf="allowToSeeColumn(1, 0)">
            <app-price *ngIf="allowToSeeDeep(1, 'price')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-price>
            <app-images *ngIf="allowToSeeDeep(1, 'images')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [selectedDetailsTab]="selectedDetailsTab"
            ></app-images>
        </div>
        <div class="overview_column" *ngIf="allowToSeeColumn(1, 1)">
            <app-basic-data *ngIf="allowToSeeDeep(1, 'basic-data')"
                [vehicle]="selectedVehicle"
                [form]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-basic-data>
            <app-drive *ngIf="allowToSeeDeep(1, 'drive')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-drive>
            <app-vehicle *ngIf="allowToSeeDeep(1, 'vehicle')"
                [vehicle]="selectedVehicle"
                [formGroupVehicle]="formGroupVehicle"
                [editMode]="editMode"
                [submitted]="submitted"
                [activeSubMenu]="activeSubMenu"
                [superGroup]="0"
                (updateVehicleTitle)="updateVehicleTitle.emit($event)"
            ></app-vehicle>
        </div>
        <div class="overview_column" *ngIf="allowToSeeColumn(1, 2)">
            <app-equipment *ngIf="allowToSeeDeep(1, 'equipment')"
              [vehicle]="selectedVehicle"
              [editMode]="editMode"
              [form]="formGroupVehicle"
            ></app-equipment>
            <app-transactions *ngIf="allowToSeeDeep(1, 'transactions')"
                [vehicle]="selectedVehicle"
            ></app-transactions>
        </div>
    </div>
    <div id="footer"></div>
</div>

<div id="overview_container" *ngIf="screenSize == 2">
  <div class="overview_grid" *ngIf="selectedDetailsTab == 1">
      <!--<div *ngIf="allowToEdit()">
        <div class="overview_data_box">
          <div class="overview_data_box_lbl_title">Werkstatt</div>
          <app-dropdown-multiple
            [title]="'Ersatzteile'"
            [formcontrol]="formGroupVehicle.controls['ersatzteile']"
            [headerText]="ersatzTeileValue"
            [values]="ersatzTeileValues"
            [isOptional]="true"
            (submitValueEmitter)="formGroupVehicle.controls['ersatzteile'].setValue($event); getEnumDataOnChange();"
          ></app-dropdown-multiple>
        </div>
      </div>-->
      <app-werkstatt *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'werkstatt')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-werkstatt>
      <div class="overview_column" *ngIf="allowToSeeColumn(0, 0)">
          <app-images *ngIf="allowToSeeDeep(0, 'images')"
              [vehicle]="selectedVehicle"
              [editMode]="editMode"
              [formGroupVehicle]="formGroupVehicle"
              [selectedDetailsTab]="selectedDetailsTab"
          ></app-images>
          <app-price *ngIf="allowToSeeDeep(0, 'price')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-price>
          <app-service *ngIf="allowToSeeDeep(0, 'service')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-service>
          <app-werkstatt *ngIf="(authService.ACCESS_ALL()) && allowToSeeDeep(0, 'werkstatt')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-werkstatt>
          <app-condition *ngIf="(authService.ACCESS_ALL() || authService.isHoedl()) && allowToSeeDeep(0, 'condition')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-condition>
          <app-garantie *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'service')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-garantie>
          <app-customercar *ngIf="selectedVehicle.customer_id && allowToSeeDeep(0,  'customercar')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-customercar>
          <app-interface-overview *ngIf="allowToSeeDeep( 0, 'interface-overview')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-interface-overview>
          <app-additional *ngIf="allowToSeeDeep( 0, 'additional')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-additional>
          <app-attachment *ngIf="allowToSeeDeep(0, 'attachment')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
          ></app-attachment>
          <app-transactions *ngIf="!editMode && allowToSeeDeep(0, 'transactions')"
              [vehicle]="selectedVehicle"
          ></app-transactions>
      </div>
      <div class="overview_column" *ngIf="allowToSeeColumn(0, 1)">
          <app-basic-data *ngIf="allowToSeeDeep(0, 'basic-data')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              (updateVehicleTitle)="updateVehicleTitle.emit($event)"
          ></app-basic-data>
          <app-drive *ngIf="allowToSeeDeep(0, 'drive')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              (updateVehicleTitle)="updateVehicleTitle.emit($event)"
          ></app-drive>
          <app-vehicle *ngIf="allowToSeeDeep(0, 'vehicle')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-vehicle>
          <app-equipment *ngIf="allowToSeeDeep(0, 'equipment')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
          ></app-equipment>
          <app-description *ngIf="!editMode && allowToSeeDeep(0, 'description')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-description>
          <app-identification *ngIf="allowToSeeDeep(0, 'identification')"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-identification>
          <app-seasonal *ngIf="authService.ACCESS_ALL() && allowToSeeDeep(0, 'seasonal')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-seasonal>
      </div>
  </div>
  <div class="overview_grid" *ngIf="selectedDetailsTab == 2">
      <div class="overview_column" *ngIf="allowToSeeColumn()">
          <app-price *ngIf="allowToSeeDeep()"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              (updateVehicleTitle)="updateVehicleTitle.emit($event)"
          ></app-price>
          <app-service *ngIf="allowToSeeDeep()"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-service>
          <app-condition *ngIf="(authService.ACCESS_ALL() || authService.isHoedl()) && allowToSeeDeep(0, 'condition')"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
              [activeSubMenu]="activeSubMenu"
              [superGroup]="0"
          ></app-condition>
          <app-garantie *ngIf="authService.ACCESS_ALL() && allowToSeeDeep()"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
              [submitted]="submitted"
          ></app-garantie>
          <app-attachment *ngIf="allowToSeeDeep()"
              [vehicle]="selectedVehicle"
              [form]="formGroupVehicle"
              [editMode]="editMode"
          ></app-attachment>
      </div>
      <div class="overview_column" *ngIf="allowToSeeColumn()">
          <app-images *ngIf="allowToSeeDeep()"
              [vehicle]="selectedVehicle"
              [formGroupVehicle]="formGroupVehicle"
              [selectedDetailsTab]="selectedDetailsTab"
          ></app-images>
          <app-basic-data *ngIf="allowToSeeDeep()"
            [vehicle]="selectedVehicle"
            [form]="formGroupVehicle"
            [editMode]="editMode"
            [submitted]="submitted"
            (updateVehicleTitle)="updateVehicleTitle.emit($event)"
        ></app-basic-data>
      </div>
  </div>
  <div id="footer"></div>
</div>
