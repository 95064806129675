
import { EnumHelper } from "src/app/helpers/EnumHelper";
import { STEUERSATZVALUES, SteuersatzValue } from "src/app/helpers/Enums";
import { PriceSheet, Reparatur } from "./PriceSheet";
import { VehicleFuel } from "../vehicle/basic/VehicleFuel";
import { NovaCalculator, NovaFahrzeugtyp } from "./NovaCalculator";

/*
  PKW:
  Der CO2-Abzugsbetrag wird jährlich um den Wert 5 gesenkt.
  Der Malus-Grenzwert wird jährlich um den Wert 15 abgesenkt.
  Der Malus-Betrag wird jährlich um den Wert 10 erhöht.
  Der Höchststeuersatz wird jährlich um 10 Prozentpunkte erhöht.
*/
const NOVABERECHNUNG_2024 = {
  date: "1.1.2024",
  co2_abzugsbetrag: 97, // g/km
  divider: 5,
  g_grenzwert: 155, // g/km
  zusatz_pro_g: 80, // euro
  bonus_betraege: { // einmaliger Abzug euro
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 80, // prozent
}
const NOVABERECHNUNG_2023 = {
  date: "1.1.2023",
  co2_abzugsbetrag: 102,
  divider: 5,
  g_grenzwert: 170,
  zusatz_pro_g: 70,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 70,
}
const NOVABERECHNUNG_2022 = {
  date: "1.1.2022",
  co2_abzugsbetrag: 107,
  divider: 5,
  g_grenzwert: 185,
  zusatz_pro_g: 60,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 60,
}
const NOVABERECHNUNG_2021 = {
  date: "1.1.2021",
  co2_abzugsbetrag: 112,
  divider: 5,
  g_grenzwert: 200,
  zusatz_pro_g: 50,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 50,
}

const NOVABERECHNUNG_2020 = {
  date: "1.1.2020",
  co2_abzugsbetrag: 115,
  divider: 5,
  g_grenzwert: 275,
  zusatz_pro_g: 40,
  bonus_betraege: {
    benzin: 350,
    diesel: 350,
  },
  hoeststeuersatz: 32,
}
const NOVABERECHNUNG_2016 = {
  date: "1.1.2016",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 300,
    diesel: 300,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_2015 = {
  date: "1.1.2015",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 400,
    diesel: 400,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_2014 = {
  date: "1.3.2014",
  co2_abzugsbetrag: 90,
  divider: 5,
  g_grenzwert: 250,
  zusatz_pro_g: 20,
  bonus_betraege: {
    benzin: 450,
    diesel: 350,
  },
  hoeststeuersatz: 50,
}
const NOVABERECHNUNG_BIS_2013 = {
  date: "1.1.2013",
  co2_abzugsbetrag: 95,
  divider: 5,
  g_grenzwert: 150,
  g_grenzwert_2: 170,
  g_grenzwert_3: 210,
  zusatz_pro_g: 25,
  bonus_betraege: {
    benzin: 300,
    diesel: 300,
  },
  hoeststeuersatz: 50,
}

const GEBRAUCHT = {
  monate: 6,
  km: 6000,
}

//ab NOVABERECHNUNG_2014 ohne 20% ust  bzw ohne Novazuschlag


export class PriceSheetCalculaterNew {

  static calculateNova(co2_emission: number, vk_netto: number, ez_year: number, fuel_typ: VehicleFuel, pricesheet): number {
    const novaSatz = pricesheet.nova_satz;
    if(ez_year){
      let NOVABerechnung = this.getNOVABerechnung(Number(ez_year));
      if(NOVABerechnung && co2_emission){
        let nova = (vk_netto * (novaSatz/100));
        if(co2_emission > NOVABerechnung.g_grenzwert){
          for(let i = co2_emission; i <= NOVABerechnung.g_grenzwert; i++){
            nova += NOVABerechnung.zusatz_pro_g;
          }
        }
        return Number(nova.toFixed(2));
      } else {
        return null;
      }
    }
  }
  static getNovaSatz(co2_emission: number, NOVABerechnung: any): number {
    let satz = Math.round((co2_emission - NOVABerechnung.co2_abzugsbetrag) / NOVABerechnung.divider);
    if(satz > NOVABerechnung.hoeststeuersatz) {
      satz = NOVABerechnung.hoeststeuersatz;
    }
    return satz < 0 ? 0 : satz;
  }
  static getNovaSatzValue(pricesheet, isStandard: boolean): number {
    const co2_emission = pricesheet.co2_emission;
    let datum = null;
    if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4) {
      if(pricesheet.fahrzeug_typ.id == 1) { //neuwagen
        datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
      } else {
        datum = new Date(pricesheet.ez).getFullYear() + "";
      }
    } else {
      if(isStandard) {
        if(pricesheet.vehicle_imported == 1 && pricesheet.fahrzeug_typ.id == 1) { //import
          datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        } else if(pricesheet.vehicle_imported == 1) { //gebraucht
          datum = new Date(pricesheet.ez).getFullYear() + "";
        }
      } else {
        if(pricesheet.fahrzeug_typ.id == 1) { //neuwagen
          datum = new Date().getFullYear() + "";
        }
      }

    }
    const ez_year = datum ? Number(datum.slice(0,4)) : null;
    if(ez_year){
      let NOVABerechnung = this.getNOVABerechnung(ez_year);
     if(NOVABerechnung){
      const novaSatz = this.getNovaSatz(co2_emission, NOVABerechnung);
      return novaSatz;
     }
    }
    return null;
  }

  static getNOVABerechnung(ez_year: number): any {
    switch (ez_year) {
      case 2024: return NOVABERECHNUNG_2024;  break;
      case 2023: return NOVABERECHNUNG_2023;  break;
      case 2022: return NOVABERECHNUNG_2022;  break;
      case 2021: return NOVABERECHNUNG_2021;  break;
      case 2020: return NOVABERECHNUNG_2020;  break;
      case 2019:
      case 2018:
      case 2017:
      case 2016: return NOVABERECHNUNG_2016;  break;
      case 2015: return NOVABERECHNUNG_2015;  break;
      case 2014: return NOVABERECHNUNG_2014;  break;
      default: break;
    }
    if(ez_year < 2014){
      return NOVABERECHNUNG_BIS_2013;
    }
    return null;
  }

  static setSteuersatzNachkalulation(pricesheet) {
    let steuersatz = pricesheet.steuersatz;
    let steuerSatzNach;
    if(steuersatz == 1) steuerSatzNach = 1;
    else if(steuersatz == 2) steuerSatzNach = 2;
    else if(steuersatz == 3 || steuersatz == 4) steuerSatzNach = 1;
    return steuerSatzNach;
  }

  static getNovaVor(pricesheet, isStandard: boolean): number {
    if(isStandard) {
      const fahrzeug_typ = pricesheet.fahrzeug_typ;
      if(fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto_vor;
        const fuel_typ = pricesheet.fuel_typ;
        const datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear() + "" : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        } else return null;
      } else if(pricesheet.vehicle_imported == 0 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(pricesheet.vehicle_imported == 1) {
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto_vor;
        const fuel_typ = pricesheet.fuel_typ;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear() + "" : (new Date().getFullYear() + "");
        } else {
          datum = new Date(pricesheet.ez).getFullYear() + "";
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        }
        return null;
      }
      return 0;
    } else {
      const fahrzeug_typ = pricesheet.fahrzeug_typ;
      if(pricesheet.steuersatz == 1 && fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto_vor;
        const fuel_typ = pricesheet.fuel_typ;
        const datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        } else return null;
      } else if(pricesheet.steuersatz == 1 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(pricesheet.steuersatz == 2 && fahrzeug_typ?.id != 1) { //Diff Steuersatz 0% & nicht Neuwagen
        return 0;
      } else if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto_vor;
        const fuel_typ = pricesheet.fuel_typ;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        } else {
          datum = new Date(pricesheet.ez).getFullYear()+""
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        }
        return null;
      }
      return 0;
    }
  }

  static getNovaNach(pricesheet, isStandard: boolean): number {
    if(isStandard) {
      const fahrzeug_typ = pricesheet.fahrzeug_typ;
      if(fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto;
        const fuel_typ = pricesheet.fuel_typ;
        const datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        } else return null;
      } else if(pricesheet.vehicle_imported == 0 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      }else if(pricesheet.vehicle_imported == 1) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto;
        const fuel_typ = pricesheet.fuel_typ;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        } else {
          datum = new Date(pricesheet.ez).getFullYear()+"";
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        }
        return null;
      }
      return 0;
    } else {
      const fahrzeug_typ = pricesheet.fahrzeug_typ;
      if(pricesheet.steuersatz == 1 && fahrzeug_typ?.id == 1){ //Wenn Steuersatz 20% & Neuwagen
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto;
        const fuel_typ = pricesheet.fuel_typ;
        const datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        } else return null;
      } else if(pricesheet.steuersatz == 1 && fahrzeug_typ?.id != 1) { //Wenn Steuersatz 20% & nicht Neuwagen
        return 0;
      } else if(pricesheet.steuersatz == 2 && fahrzeug_typ?.id != 1) { //Diff Steuersatz 0% & nicht Neuwagen
        return 0;
      } else if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4) { //Wenn vom Ausland BRD (Steuersatz 19%)
        const co2_emission = pricesheet.co2_emission;
        const vk_netto = pricesheet.verkaufspreis_netto;
        const fuel_typ = pricesheet.fuel_typ;
        let datum;
        if(fahrzeug_typ?.id == 1){ //neuwagen
          datum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
        } else {
          datum = new Date(pricesheet.ez).getFullYear()+"";
        }
        if(datum){
          let year = datum.slice(0,4);
          const nova = this.calculateNova(co2_emission, vk_netto, Number(year), fuel_typ, pricesheet);
          return nova;
        }
        return null;
      }
      return 0;
    }
  }

  static getEKNetto(pricsheet: PriceSheet): number {
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,pricsheet.steuersatz-1)];
    const ek_brutto = pricsheet.einkaufspreis_brutto;
    const ek_netto = (ek_brutto / ((100+steuer)/100));
    return Number(ek_netto.toFixed(2));
  }
  static getEKButto(pricsheet: PriceSheet): number {
    const steuer = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,pricsheet.steuersatz-1)];
    const ek_netto = pricsheet.einkaufspreis_netto;
    const ek_brutto = ek_netto * ((steuer/100)+1);
    return Number(ek_brutto.toFixed(2));
  }
  static getVKBruttoVor(pricesheet): number {
    return pricesheet.minimaler_verkaufpreis;
  }
  static getVKBrutto(pricesheet): number {
    return pricesheet.verkaufspreis_brutto;
  }
  static getVKBruttoForeignLand(pricesheet): number {
    const summe_nova = pricesheet.summe_nova;
    const ohne_nova_inkl_ust = pricesheet.ohne_nova_inkl_ust;
    let vk_brutto = summe_nova + ohne_nova_inkl_ust;
    return Number(vk_brutto.toFixed(2));
  }
  static getVKNetto(pricesheet): number {
    if(pricesheet.steuersatz == 1){
      const steuerSatzValue = 20;
      let vk_brutto = this.getVKBrutto(pricesheet)
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      } else {
        return undefined;
      }
    } else if(pricesheet.steuersatz == 2){
      return null;
    } else if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4){
      const steuerSatzValue = 19;
      let vk_brutto = this.getVKBrutto(pricesheet);
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      }
    }
    return null;
  }

  static getVKNettoVorStandard(pricesheet): number {
    let vk_brutto = pricesheet.preis_anschreiben;
    let nova_total = pricesheet.summe_nova_vor;
    let ust = pricesheet.ust_vor;
    let vk_netto = vk_brutto - nova_total - ust;
    return Number(vk_netto.toFixed(2));
  }

  static getVKNettoVor(pricesheet, isStandard: boolean): number {
    if(isStandard) {
      if(pricesheet.steuersatz == 1) {
        const steuerSatzValue = 20;
        let vk_brutto = this.getVKBruttoVor(pricesheet);
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        }
      } else {
        return null;
      }
    } else {
      if(pricesheet.steuersatz == 1){
        const steuerSatzValue = 20;
        let vk_brutto = this.getVKBruttoVor(pricesheet)
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        } else {
          return 0;
        }
      } else if(pricesheet.steuersatz == 2){
        return null;
      } else if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4){
        const steuerSatzValue = 19;
        let vk_brutto = this.getVKBruttoVor(pricesheet);
        if(vk_brutto) {
          const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
          return Number(vk_netto.toFixed(2));
        }
      }
      return null;
    }

  }

  static getVKNettoNachStandard(pricesheet): number {
    let vk_brutto = pricesheet.verkaufspreis_brutto;
    let nova_total = pricesheet.summe_nova;
    let ust = pricesheet.ust;
    let vk_netto = vk_brutto - nova_total - ust;
    return Number(vk_netto.toFixed(2));
  }

  static getVKNettoNach(pricesheet): number {
    if(pricesheet.steuersatz == 1){
      const steuerSatzValue = 20;
      let vk_brutto = this.getVKBrutto(pricesheet)
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      } else {
        return 0;
      }
    } else if(pricesheet.steuersatz == 2){
      return null;
    } else if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4){
      const steuerSatzValue = 19;
      let vk_brutto = this.getVKBrutto(pricesheet);
      if(vk_brutto) {
        const vk_netto = (Number(vk_brutto) / (steuerSatzValue+100)) * 100;
        return Number(vk_netto.toFixed(2));
      }
    }
    return null;
  }

  static getUSTVorStandard(pricesheet): number {
    let vk_brutto = pricesheet.preis_anschreiben;
    let ek_netto = pricesheet.einkaufspreis_netto;
    let nova_total = pricesheet.summe_nova_vor;
    if(pricesheet.steuersatz == 1){
      const ust = (vk_brutto - nova_total) / 6;
      return Number(ust.toFixed(2));
    } else {
      const ust = (vk_brutto - nova_total - ek_netto) / 6;
      if(ust < 0) return 0;
      return Number(ust.toFixed(2));
    }
  }

  static getPredictedVkBrutto(pricesheet): number {
    let ek_netto = pricesheet.einkaufspreis_netto;

    return 0;
  }

  static getGuVExportVorUst(pricesheet): number {
    let export_price = pricesheet.export_price;
    if(pricesheet.steuersatz == 1){
        return Number((export_price/1.2*0.2).toFixed(2));
    } else if(pricesheet.steuersatz == 2){
        let ek_netto = pricesheet.einkaufspreis_netto;
        if(export_price && ek_netto){
          if(ek_netto > export_price) return 0;
          const ust = (export_price - ek_netto)/6;
          return Number(ust.toFixed(2));
        }
    }
    return null;
  }

  static getUSTVor(pricesheet, isStandard: boolean): number {
    let vk_brutto = pricesheet.minimaler_verkaufpreis;
    if(pricesheet.steuersatz == 1){
      if(isStandard) {
        let vk_netto = pricesheet.verkaufspreis_netto_vor;
        if(pricesheet.vehicle_imported == 1) {
          let malusBonus = pricesheet.malus_bonus;
          const ust = vk_brutto - vk_netto + malusBonus - pricesheet.nova_vor;
          return Number(ust.toFixed(2));
        } else {
          const ust = vk_brutto - vk_netto;
          return Number(ust.toFixed(2));
        }
      } else {
        let vk_netto = pricesheet.verkaufspreis_netto_vor;
        const ust = vk_brutto - vk_netto;
        return Number(ust.toFixed(2));
      }
    } else if(pricesheet.steuersatz == 2){
      if(isStandard) {
        let ek_netto = pricesheet.einkaufspreis_netto;
        if(pricesheet.vehicle_imported == 1) {
          let malusBonus = pricesheet.malus_bonus;
          if(vk_brutto && ek_netto){
            const ust = (vk_brutto - ek_netto)/6  + malusBonus - pricesheet.nova_vor;
            return Number(ust.toFixed(2));
          }
        } else {
          if(vk_brutto && ek_netto){
            const ust = (vk_brutto - ek_netto)/6;
            return Number(ust.toFixed(2));
          }
        }

      } else {
        let ek_netto = pricesheet.einkaufspreis_netto;
        if(vk_brutto && ek_netto){
          const ust = (vk_brutto - ek_netto)/6;
          return Number(ust.toFixed(2));
        }
      }

    }
    return null;
  }

  static getUSTNach(pricesheet): number {
    let vk_brutto = pricesheet.verkaufspreis_brutto;
    if(pricesheet.steuersatz == 1){
      let vk_netto = pricesheet.verkaufspreis_netto;
      const ust = vk_brutto - vk_netto;
      return Number(ust.toFixed(2));
    } else if(pricesheet.steuersatz == 2){
      let ek_netto = pricesheet.einkaufspreis_netto;
      if(vk_brutto && ek_netto){
        const ust = (vk_brutto - ek_netto)/6;
        return Number(ust.toFixed(2));
      }
    }
    return null;
  }

  static getUSTNachStandard(pricesheet): number {
    let vk_brutto = pricesheet.verkaufspreis_brutto;
    let ek_netto = pricesheet.einkaufspreis_netto;
    let nova_total = pricesheet.summe_nova;
    if(pricesheet.steuersatz == 1){
      const ust = (vk_brutto - nova_total) / 6;
      return Number(ust.toFixed(2));
    } else {
      const ust = (vk_brutto -nova_total - ek_netto) / 6;
      if(ust < 0) return 0;
      return Number(ust.toFixed(2));
    }
  }

  static getVorSteuerDifferenz(pricesheet) {
    const ek_brutto = pricesheet.einkaufspreis_brutto;
    const ek_netto = pricesheet.einkaufspreis_netto;
    const vorsteuer = ek_brutto - ek_netto;
    return Number(vorsteuer.toFixed(2));
  }
  static getVorSteuerDifferenzNach(pricesheet) {
    const ek_brutto = pricesheet.minimaler_verkaufpreis;
    const ek_netto = pricesheet.verkaufspreis_netto;
    const vorsteuer = ek_brutto - ek_netto;
    return Number(vorsteuer.toFixed(2));
  }
  static getAufschlag(pricesheet): number {
    const entgelt_ohne_nova = pricesheet.entgelt_ohne_nova;
    const ek_netto = pricesheet.einkaufspreis_netto;
    const aufschlag = entgelt_ohne_nova - ek_netto;
    return aufschlag;
  }
  static getMalusBonus(pricesheet, isStandard: boolean): number {
    const fahrzeug_typ = pricesheet.fahrzeug_typ;
    const fuel_typ = pricesheet.fuel_typ;
    if(!this.isVomAusland(pricesheet, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return 0;
    } else {
      let ez = new Date(pricesheet.ez).getFullYear()+"";
      const hereinnahmedatum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
      if(!this.isVomAusland(pricesheet, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung == null) { return null;}
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return null;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return null;
          } else return null;
        }
      } else {
        if(ez) {
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return null;
          } else return null;
        }
      }
    }
    return 0;
  }

  static malusBonusIsCalculatable(pricesheet, isStandard: boolean): boolean {
    if(pricesheet.malus_bonus_nach != null) {
      return false;
    }
    if(pricesheet.malus_bonus_changed == true) {
      return false;
    }
    const fahrzeug_typ = pricesheet.fahrzeug_typ;
    const fuel_typ = pricesheet.fuel_typ;
    if(!this.isVomAusland(pricesheet, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return true;
    } else {
      const ez = pricesheet.ez;
      const hereinnahmedatum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear+"" : (new Date().getFullYear() + "");
      if(!this.isVomAusland(pricesheet, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return true;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return true;
          } else return true;
        }
      } else {
        if(ez) {
          let date = new Date(ez).getFullYear()+"";
          const year = date.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return true;
          } else return true;
        }
      }
    }
    return false;
  }

  static getMalusBonusNach(pricesheet, isStandard: boolean): number {
    if(pricesheet.malus_bonus_nach != null) {
      return pricesheet.malus_bonus_nach;
    }
    const fahrzeug_typ = pricesheet.fahrzeug_typ;
    const fuel_typ = pricesheet.fuel_typ;
    if(!this.isVomAusland(pricesheet, isStandard) && fahrzeug_typ?.id == 2){ // 2 = gebraucht
      return 0;
    } else {
      const ez = pricesheet.ez;
      const hereinnahmedatum = pricesheet.hereinnahmedatum ? new Date(pricesheet.hereinnahmedatum).getFullYear()+"" : (new Date().getFullYear() + "");
      if(!this.isVomAusland(pricesheet, isStandard)){
        if(ez){
          const year = ez.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
          else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
          else return 0;
        } else if(hereinnahmedatum) {
          const year = hereinnahmedatum.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ?.id == 1 || fuel_typ?.id == 8) return NOVABerechnung.bonus_betraege.benzin; //1 = benzin / 8 = benzin hybrid
            else if(fuel_typ?.id == 2 || fuel_typ?.id == 9) return NOVABerechnung.bonus_betraege.diesel; //2 = diesel / 8 = diesel hybrid
            else return 0;
          } else return 0;
        }
      } else {
        if(ez) {
          let date = new Date(ez).getFullYear()+"";
          const year = date.slice(0,4);
          let NOVABerechnung = this.getNOVABerechnung(Number(year));
          if(NOVABerechnung){
            if(fuel_typ.id == 1 || fuel_typ.id == 8) return NOVABerechnung.bonus_betraege.benzin;
            else if(fuel_typ.id == 2 || fuel_typ.id == 9) return NOVABerechnung.bonus_betraege.diesel;
            else return 0;
          } else return 0;
        }
      }
    }
    if(pricesheet.malus_bonus_changed == true) {
      return pricesheet.malus_bonus_nach;
    }
    return null;
  }

  static getVKNettoSpecificumAuslandVor(pricesheet): number {
    let vkBrutto = this.getVKBruttoVor(pricesheet);
    const malus_bonus = pricesheet.malus_bonus;
    const steuersatz = 0.2;
    const novaSatz = pricesheet.nova_satz / 100;
    return (vkBrutto + malus_bonus) / (1 + steuersatz + novaSatz);
  }

  static getVKNettoSpecificumAuslandNach(pricesheet): number {
    let vkBrutto = this.getVKBrutto(pricesheet);
    const malus_bonus = pricesheet.malus_bonus_nach;
    const steuersatz = 0.2;
    const novaSatz = pricesheet.nova_satz / 100;
    return (vkBrutto + malus_bonus) / (1 + steuersatz + novaSatz);
  }

  static getSummeNovaVor(pricesheet): number {
    const nova = pricesheet.nova_vor;
    const malus_bonus = pricesheet.malus_bonus;
    if(nova - malus_bonus > 0) {
      return nova - malus_bonus;
    }
    return 0;
  }
  static getSummeNovaNach(pricesheet): number {
    const nova = pricesheet.nova;
    const malus_bonus = pricesheet.malus_bonus_nach;
    if(nova - malus_bonus > 0) {
      return nova - malus_bonus;
    }
    return 0;
    //return nova - malus_bonus;
  }

  static getGuVExportVor(pricesheet, isStandard: boolean): number {
    let entgelt_ohne_nova = 0;
    entgelt_ohne_nova =  pricesheet.export_price;
    let ust = pricesheet.guv_export_ust_vor;
    if(pricesheet.vehicle_imported != 1 && pricesheet.nova_verguetung > 0) {
      entgelt_ohne_nova = entgelt_ohne_nova - ust + pricesheet.nova_verguetung;
    } else {
      entgelt_ohne_nova = entgelt_ohne_nova - ust;
    }

    let ek_netto = pricesheet.einkaufspreis_netto;
    const beschaffungskosten_gesamt = pricesheet.beschaffungskosten_gesamt;
    const aufbereitung = pricesheet.aufbereitung;
    const reparatur_summe_gesamt = this.getReparaturGesamt(pricesheet.reparaturen);
    const zinsen_vor = pricesheet.zinsen_vor;
    const garantie = pricesheet.garantie_vorkalkulation;
    const standzeitkosten_vor = pricesheet.standzeitkosten_vor;
    const fixkosten_pro_fahrzeug_bei_70 = pricesheet.fixkosten_pro_fahrzeug_bei_70;
    const gw_aufschlag = pricesheet.gw_aufschlag;
    const fahrzeug_provision = pricesheet.fahrzeug_provision;
    const finanzierung = pricesheet.finanzierung;
    const garantie_vor = pricesheet.garantie_vor;
    const abzug_fahrzeug_provision = pricesheet.abzug_fahrzeug_provision;
    const abzug_finanzierung = pricesheet.abzug_finanzierung;

    let guv = 0;
    guv = (entgelt_ohne_nova -
      ek_netto -
      beschaffungskosten_gesamt -
      aufbereitung -
      reparatur_summe_gesamt -
      zinsen_vor -
      garantie -
      standzeitkosten_vor -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      garantie_vor) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getGuVVor(pricesheet, isStandard: boolean): number {
    //let entgelt_ohne_nova = !isNaN(pricesheet.entgelt_ohne_nova) ? pricesheet.entgelt_ohne_nova : null;
    let entgelt_ohne_nova = 0;
    if(isStandard) {
      entgelt_ohne_nova =  pricesheet.verkaufspreis_netto_vor;
    } else {
      entgelt_ohne_nova =  pricesheet.entgelt_ohne_nova_vor;
    }

    let ek_netto = pricesheet.einkaufspreis_netto;
    const beschaffungskosten_gesamt = pricesheet.beschaffungskosten_gesamt;
    const aufbereitung = pricesheet.aufbereitung;
    const reparatur_summe_gesamt = this.getReparaturGesamt(pricesheet.reparaturen);
    const zinsen_vor = pricesheet.zinsen_vor;
    const garantie = pricesheet.garantie_vorkalkulation;
    const standzeitkosten_vor = pricesheet.standzeitkosten_vor;
    const fixkosten_pro_fahrzeug_bei_70 = pricesheet.fixkosten_pro_fahrzeug_bei_70;
    const gw_aufschlag = pricesheet.gw_aufschlag;
    const fahrzeug_provision = pricesheet.fahrzeug_provision;
    const finanzierung = pricesheet.finanzierung;
    const garantie_vor = pricesheet.garantie_vor;
    const abzug_fahrzeug_provision = pricesheet.abzug_fahrzeug_provision;
    const abzug_finanzierung = pricesheet.abzug_finanzierung;

    let guv = 0;
    guv = (entgelt_ohne_nova -
      ek_netto -
      beschaffungskosten_gesamt -
      aufbereitung -
      reparatur_summe_gesamt -
      zinsen_vor -
      garantie -
      standzeitkosten_vor -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      garantie_vor) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getGuVNach(pricesheet, isStandard: boolean): number {
    let entgelt_ohne_nova = pricesheet.entgelt_ohne_nova;
    let ek_netto = pricesheet.einkaufspreis_netto;
    const beschaffungskosten_gesamt = pricesheet.beschaffungskosten_gesamt;
    const aufbereitung = pricesheet.aufbereitung;
    const aufbereitung_nachkalkulation = pricesheet.aufbereitung_nachkalkulation;
    const reparatur_summe_gesamt = this.getReparaturGesamt(pricesheet.reparaturen);
    const garantie = pricesheet.garantie;
    const fixkosten_pro_fahrzeug_bei_70 = pricesheet.fixkosten_pro_fahrzeug_bei_70;
    const gw_aufschlag = pricesheet.gw_aufschlag;

    let zinsen_nach = pricesheet.zinsen_nach;
    let standzeitkosten_nach = pricesheet.standzeitkosten_nach;
    const reparatur_summe_gesamt_vorjahr = this.getReparaturGesamt(pricesheet.reparaturen_vorjahr);
    const garantie_intern = pricesheet.garantie_intern;
    const garantie_extern = pricesheet.garantie_extern;
    const fahrzeug_provision = pricesheet.fahrzeug_provision;
    const finanzierung = pricesheet.finanzierung;
    const abzug_fahrzeug_provision = pricesheet.abzug_fahrzeug_provision;
    const abzug_finanzierung = pricesheet.abzug_finanzierung;
    const garantie_vor = pricesheet.garantie_vor;

    entgelt_ohne_nova =  pricesheet.entgelt_ohne_nova;

    let ek_butto = pricesheet.einkaufspreis_brutto;
    let steuer_nach = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,pricesheet.steuersatz_nach-1)];
    ek_netto = (ek_butto/(100+steuer_nach))*100;

    let standzeit_geld = pricesheet.standzeit_geld;
    let zinssatz_nach = pricesheet.zinssatz_nach;
    zinsen_nach = (ek_netto * standzeit_geld * zinssatz_nach)/36000;

    let standzeitkosten_je_tag_nach =  pricesheet.standzeitkosten_je_tag_nach;
    let N46 = standzeitkosten_nach*standzeitkosten_je_tag_nach;

    let guv = 0;
    if(isStandard) {
      entgelt_ohne_nova = pricesheet.verkaufspreis_netto;
    }
    guv = (entgelt_ohne_nova -
      ek_netto -
      aufbereitung_nachkalkulation -
      beschaffungskosten_gesamt -
      //reparatur_summe_gesamt -
      garantie -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      zinsen_nach -
      standzeitkosten_nach -
      reparatur_summe_gesamt_vorjahr -
      garantie_intern -
      garantie_extern -
      garantie_vor -
      abzug_fahrzeug_provision -
      abzug_finanzierung) +
      fahrzeug_provision +
      finanzierung;
    if(entgelt_ohne_nova != null && entgelt_ohne_nova != 0) {
      return Number(guv.toFixed(2));
    } else {
      return null;
    }

  }
  static getGUVNachAusland(pricesheet, isStandard: boolean): number {
    let entgelt_ohne_nova = pricesheet.entgelt_ohne_nova;
    let ek_netto = pricesheet.einkaufspreis_netto;
    const beschaffungskosten_gesamt = pricesheet.beschaffungskosten_gesamt;
    const aufbereitung = pricesheet.aufbereitung;
    const aufbereitung_nachkalkulation = pricesheet.aufbereitung_nachkalkulation;
    const garantie = pricesheet.garantie;
    const fixkosten_pro_fahrzeug_bei_70 = pricesheet.fixkosten_pro_fahrzeug_bei_70;
    const gw_aufschlag = pricesheet.gw_aufschlag;

    let zinsen_nach = pricesheet.zinsen_nach;
    let standzeitkosten_nach = pricesheet.standzeitkosten_nach;
    const reparatur_summe_gesamt_vorjahr = this.getReparaturGesamt(pricesheet.reparaturen_vorjahr);
    const garantie_intern = pricesheet.garantie_intern;
    const garantie_extern = pricesheet.garantie_extern;
    const fahrzeug_provision = pricesheet.fahrzeug_provision;
    const finanzierung = pricesheet.finanzierung;
    const abzug_fahrzeug_provision = pricesheet.abzug_fahrzeug_provision;
    const abzug_finanzierung = pricesheet.abzug_finanzierung;
    let ek_brutto = pricesheet.einkaufspreis_brutto;
    let steuer_vor = STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,pricesheet.steuersatz-1)];
    ek_netto = (ek_brutto/(100+steuer_vor))*100;

    let standzeit_geld = pricesheet.standzeit_geld;
    let zinssatz_nach = pricesheet.zinssatz_nach;
    zinsen_nach = (ek_netto * standzeit_geld * zinssatz_nach)/36000;

    let standzeitkosten_je_tag_nach =  pricesheet.standzeitkosten_je_tag_nach;
    let N46 = standzeitkosten_nach*standzeitkosten_je_tag_nach;

    let guv = 0;
    if(isStandard) {
      entgelt_ohne_nova = pricesheet.verkaufspreis_netto;
    }
    guv = (entgelt_ohne_nova -
      ek_netto -
      aufbereitung_nachkalkulation -
      beschaffungskosten_gesamt -
      //reparatur_summe_gesamt -
      garantie -
      fixkosten_pro_fahrzeug_bei_70 -
      gw_aufschlag -
      zinsen_nach -
      standzeitkosten_nach -
      reparatur_summe_gesamt_vorjahr -
      garantie_intern -
      garantie_extern) +
      fahrzeug_provision +
      finanzierung -
      abzug_fahrzeug_provision -
      abzug_finanzierung;
    return Number(guv.toFixed(2));
  }

  static getOhneNovaUSTVor(pricesheet): number {
    const ust = 20;
    const entgelt_ohne_nova = pricesheet.entgelt_ohne_nova_vor;
    let value = entgelt_ohne_nova * ((ust/100)+1);
    return Number(value.toFixed(2));
  }
  static getOhneNovaUSTNach(pricesheet): number {
    const ust = 20;
    const entgelt_ohne_nova = pricesheet.entgelt_ohne_nova;
    let value = entgelt_ohne_nova * ((ust/100)+1);
    return Number(value.toFixed(2));
  }
  static getBeschaffungskostenGesamt(pricesheet): number {
    const sprit = pricesheet.sprit;
    const flug_bahn = pricesheet.flug_bahn;
    const gebuehren = pricesheet.gebuehren;
    const lieferant_extern = pricesheet.lieferant_extern;
    const uebernachtung = pricesheet.uebernachtung;
    let gesamt =
      sprit +
      flug_bahn +
      gebuehren +
      lieferant_extern +
      uebernachtung;
    return gesamt;
  }
  static getGesamtNeupreis(pricesheet): number {
    const neu_preis = pricesheet.neu_preis;
    const sonderaustattung = pricesheet.sonderaustattung;
    if(!neu_preis && !sonderaustattung) return null;
    let gesamt =
      neu_preis +
      sonderaustattung;
    return gesamt;
  }
  static getReparaturColumnGesamt(pricesheets: Reparatur[]): any {
    let az = 0;
    let mat = 0;
    let reifen = 0;
    let lack = 0;
    let typ = 0;
    for(let pricesheet of pricesheets){
      az += pricesheet.az;
      mat += pricesheet.mat;
      reifen += pricesheet.reifen;
      lack += pricesheet.lackierung;
      typ += pricesheet.typisierung;
    }
    return {
      az: az,
      mat: mat,
      reifen: reifen,
      lack: lack,
      typ: typ,
    }
  }
  static getReparaturGesamt(reparaturen: Reparatur[]): number {
    let az = 0;
    let mat = 0;
    let reifen = 0;
    let lack = 0;
    let typ = 0;
    for(let r of reparaturen){
      az += r.az;
      mat += r.mat;
      reifen += r.reifen;
      lack += r.lackierung;
      typ += r.typisierung;
    }
    return az + mat + reifen + lack + typ;
  }

  static getZinsenVor(pricesheet): number {
    const einkaufspreis_netto = pricesheet.einkaufspreis_netto;
    const standzeit_plan_tage = pricesheet.standzeit_plan_tage;
    const zinssatz_vor = pricesheet.zinssatz_vor;
    if(einkaufspreis_netto && standzeit_plan_tage && zinssatz_vor){
      let zinsen = (einkaufspreis_netto * standzeit_plan_tage * zinssatz_vor) / 36000;
      return Number(zinsen.toFixed(2));
    } else {
      return null;
    }
  }
  static getZinsenNach(pricesheet): number {
    const einkaufspreis_netto = pricesheet.einkaufspreis_netto;
    const standzeit_geld = pricesheet.standzeit_geld;
    const zinssatz_nach = pricesheet.zinssatz_nach;
    if(einkaufspreis_netto && standzeit_geld && zinssatz_nach){
      let zinsen = (einkaufspreis_netto * standzeit_geld * zinssatz_nach) / 36000;
      return Number(zinsen.toFixed(2));
    } else {
      return null;
    }
  }
  static getGarantieVor(pricesheet, isStandard: boolean): number {
    let verkaufspreis_brutto = 0;
    if(isStandard) {
      verkaufspreis_brutto = pricesheet.preis_anschreiben;
    } else {
      verkaufspreis_brutto = this.getVKBruttoVor(pricesheet);
    }
    const garantie_von_einkaufspreis = pricesheet.garantie_von_einkaufspreis;
    if(verkaufspreis_brutto && garantie_von_einkaufspreis){
      let garantie = (verkaufspreis_brutto * garantie_von_einkaufspreis)-verkaufspreis_brutto;
      return Number(garantie.toFixed(3));
    } else {
      return null;
    }
  }

  static getGarantieNach(pricesheet): number {
    const verkaufspreis_brutto = this.getVKBrutto(pricesheet);
    const garantie_von_einkaufspreis = pricesheet.garantie_von_einkaufspreis;
    if(verkaufspreis_brutto && garantie_von_einkaufspreis){
      let garantie = (verkaufspreis_brutto * garantie_von_einkaufspreis)-verkaufspreis_brutto;
      return Number(garantie.toFixed(3));
    } else {
      return null;
    }
  }

  static getStandzeitkostenVor(pricesheet): number {
    const standzeit_plan_tage = pricesheet.standzeit_plan_tage;
    const standzeitkosten_je_tag_vor = pricesheet.standzeitkosten_je_tag_vor;
    let kosten = standzeit_plan_tage * standzeitkosten_je_tag_vor;
    return Number(kosten.toFixed(2));
  }
  static getStandzeitPlatz(pricesheet): number {
    const hereinnahmedatum = pricesheet.hereinnahmedatum;
    const verkaufsdatum = pricesheet.verkaufsdatum;
    if(hereinnahmedatum && verkaufsdatum){
      const days = Math.ceil((verkaufsdatum - hereinnahmedatum) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }
  static getStandzeitPlatzStandard(pricesheet): number {
    const hereinnahmedatum = pricesheet.einkaufsdatum;
    const verkaufsdatum = pricesheet.verkaufsdatum;
    if(hereinnahmedatum && verkaufsdatum){
      const days = Math.ceil((verkaufsdatum - hereinnahmedatum) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }

  static getStandzeitGeld(pricesheet): number {
    const zahldatum = pricesheet.zahldatum;
    const geldeingang = pricesheet.geldeingang;
    if(zahldatum && geldeingang){
      const days = Math.ceil((geldeingang - zahldatum) / (1000 * 3600 * 24));
      return days;
    }
    return null;
  }
  static getStandzeitkostenNach(pricesheet): number {
    const standzeit_platz = pricesheet.standzeit_platz;
    const standzeitkosten_je_tag_nach = pricesheet.standzeitkosten_je_tag_nach;
    let kosten = standzeit_platz * standzeitkosten_je_tag_nach;
    return Number(kosten.toFixed(2));
  }
  static getAufschlageVerkaufVor(pricesheet): number {
    const entgelt_ohne_nova = pricesheet.entgelt_ohne_nova_vor;
    let ek_netto = pricesheet.einkaufspreis_netto;
    return entgelt_ohne_nova - ek_netto;
  }

  static getAufschlageVerkaufNach(pricesheet): number {
    const entgelt_ohne_nova = pricesheet.entgelt_ohne_nova;
    if(entgelt_ohne_nova != 0 && entgelt_ohne_nova != null){
      let ek_netto = pricesheet.einkaufspreis_netto;
      return entgelt_ohne_nova - ek_netto;
    } else {
      return null;
    }

  }

  static getEntGeltOhneNovaVor(pricesheet, isStandard: boolean): number {
    if(pricesheet.steuersatz == 1){
      let vk_netto = pricesheet.verkaufspreis_netto_vor;
      const malus_bonus = pricesheet.malus_bonus;
      const nova = pricesheet.nova_vor;
      if(isStandard && pricesheet.vehicle_imported == 1) {
        return vk_netto;
      } else {
        let summeNova = nova - malus_bonus;
        if(summeNova > 0) {
          return vk_netto + malus_bonus - nova;
        } else {
          return vk_netto;
        }
      }
    } else if(pricesheet.steuersatz == 2) {
      let vk_brutto = pricesheet.minimaler_verkaufpreis;
      const ust = pricesheet.ust_vor;
      if(isStandard && pricesheet.vehicle_imported == 1) {
        const malu_bonus = pricesheet.malus_bonus;
        const entgelt = (vk_brutto - ust) / 1;
        return Number(entgelt.toFixed(2));
      } else {
        const malu_bonus = pricesheet.malus_bonus;
        const entgelt = (vk_brutto - ust - malu_bonus) / 1;
        return Number(entgelt.toFixed(2));
      }

    } else {
      //TODO: vk inkorrekt
      const vk_netto = pricesheet.verkaufspreis_netto_vor;
      return vk_netto;
    }
  }

  static getEntGeltOhneNovaNach(pricesheet, isStandard: boolean): number {
    const malus_bonus = pricesheet.malus_bonus_nach;
    if(pricesheet.steuersatz == 1){
      let vk_netto = pricesheet.verkaufspreis_netto;
      const nova = pricesheet.nova;
      if(isStandard && pricesheet.vehicle_imported == 1) {
        return vk_netto;
      } else {
        return vk_netto - malus_bonus - nova;
      }
    } else if(pricesheet.steuersatz == 2) {
      let vk_brutto = pricesheet.verkaufspreis_brutto;
      const ust = pricesheet.ust;
      const entgelt = (vk_brutto - ust - malus_bonus) / 1;
      return Number(entgelt.toFixed(2));
    } else {
      //TODO: vk inkorrekt
      const vk_netto = pricesheet.verkaufspreis_netto;
      return vk_netto;
    }
  }

  static isVomAusland(pricesheet, isStandard: boolean): boolean {
    if(isStandard) {
      if(pricesheet.vehicle_imported == 1) return true;
      else return false;
    } else {
      if(pricesheet.steuersatz == 3 || pricesheet.steuersatz == 4) return true;
      else return false;
    }
  }

  static getNovaCalculatorVor(pricesheet: PriceSheet): NovaCalculator {
    let novaTatbestand = pricesheet.verkaufsdatum ? pricesheet.verkaufsdatum : new Date().getTime();
    return new NovaCalculator(
      pricesheet.preis_anschreiben,
      20,
      novaTatbestand,

      pricesheet.bodyTypId == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
      pricesheet.co2_emission,
      new VehicleFuel(pricesheet.fuel_typ ? pricesheet.fuel_typ.id : 0, ""),
      pricesheet.consumptionCombined,
      pricesheet.powerKw,
      undefined,
      undefined,
      pricesheet.ez
    );
  }

  static getNovaCalculatorNach(pricesheet: PriceSheet): NovaCalculator {
    let novaTatbestand = pricesheet.verkaufsdatum ? pricesheet.verkaufsdatum : new Date().getTime();
    return new NovaCalculator(
      pricesheet.verkaufspreis_brutto,
      20,
      novaTatbestand,
      pricesheet.bodyTypId == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
      pricesheet.co2_emission,
      new VehicleFuel(pricesheet.fuel_typ ? pricesheet.fuel_typ.id : 0, ""),
      pricesheet.consumptionCombined,
      pricesheet.powerKw,
      undefined,
      undefined,
      pricesheet.ez
    );
  }

  static getNovaCalculatorVerguetung(pricesheet: PriceSheet): NovaCalculator|undefined {
    let novaTatbestand = pricesheet.verkaufsdatum ? pricesheet.verkaufsdatum : new Date().getTime();
    if(pricesheet.einkaufspreis_brutto != undefined && pricesheet.einkaufspreis_brutto > 0) {
      return new NovaCalculator(
        pricesheet.steuersatz == 1 ? pricesheet.einkaufspreis_netto : pricesheet.einkaufspreis_brutto,
        pricesheet.steuersatz == 1 ? 0 : 20,
        novaTatbestand,
        pricesheet.bodyTypId == 7 ? NovaFahrzeugtyp.N1 : NovaFahrzeugtyp.PKW,
        pricesheet.co2_emission,
        new VehicleFuel(pricesheet.fuel_typ ? pricesheet.fuel_typ.id : 0, ""),
        pricesheet.consumptionCombined,
        pricesheet.powerKw,
        undefined,
        undefined,
        pricesheet.ez
      );
    } else {
      return undefined;
    }
  }

  static getBreakEvenVK(pricesheet: PriceSheet, calculator: NovaCalculator): number|undefined {
    if(pricesheet.einkaufspreis_brutto != undefined && pricesheet.einkaufspreis_brutto > 0) {
      let novaRelevant = pricesheet.vehicle_imported == 1 || (pricesheet.ez == undefined);
        let vkBruttoBreakEven = pricesheet.einkaufspreis_brutto;
        if(novaRelevant) {
          let transportCosts = 0;
          if(pricesheet.sprit != undefined && pricesheet.sprit  > 0) {
            transportCosts = transportCosts + pricesheet.sprit;
          }
          if(pricesheet.lieferant_extern != undefined && pricesheet.lieferant_extern > 0) {
            transportCosts = transportCosts + pricesheet.lieferant_extern;
          }
          if(pricesheet.zubau != undefined && pricesheet.zubau > 0) {
            transportCosts = transportCosts + pricesheet.zubau;
          }
          transportCosts = transportCosts * 1.2;
          vkBruttoBreakEven = calculator.calcBreakEvenVK(pricesheet.einkaufspreis_brutto + transportCosts)
        } else {
          if(pricesheet.sprit != undefined && pricesheet.sprit  > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + pricesheet.sprit;
          }
          if(pricesheet.lieferant_extern != undefined && pricesheet.lieferant_extern > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + pricesheet.lieferant_extern;
          }
          if(pricesheet.zubau != undefined && pricesheet.zubau > 0) {
            vkBruttoBreakEven = vkBruttoBreakEven + pricesheet.zubau;
          }
        }
        return vkBruttoBreakEven;
      } else {
        return undefined;
      }
  }

  static getNovaVerguetung(pricesheet: PriceSheet): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(pricesheet);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(pricesheet.vehicle_imported == 0) {
        return calculator.calcNovaTotal();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungGrundbetrag(pricesheet: PriceSheet): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(pricesheet);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(pricesheet.vehicle_imported == 0) {
        return calculator.calcNovaGrundbetrag();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungMalus(pricesheet: PriceSheet): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(pricesheet);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(pricesheet.vehicle_imported == 0) {
        return calculator.calcMalus();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getNovaVerguetungAbzugsposten(pricesheet: PriceSheet): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(pricesheet);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(pricesheet.vehicle_imported == 0) {
        return calculator.calcAbzugsposten();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }


  static getNovaVerguetungSatz(pricesheet: PriceSheet): number|undefined {
    let calculator = this.getNovaCalculatorVerguetung(pricesheet);
    if(calculator) {
      //TODO neuer Datensatz NOVA freigegeben
      if(pricesheet.vehicle_imported == 0) {
        return calculator.calcNovaSatz();
      } else {
        return 0;
      }
    } else {
      return undefined;
    }
  }

  static getExportPriceCalculated(pricesheet: PriceSheet): number|undefined {
    if(pricesheet.preis_anschreiben == undefined || pricesheet.preis_anschreiben == 0) {
      return 0;
    }
    if(pricesheet.vehicle_imported == 0) {
      let price = pricesheet.preis_anschreiben - pricesheet.nova_verguetung;
      if(pricesheet.nova_verguetung == 0) {
        return price;
      }
      if(pricesheet.steuersatz == 2 && pricesheet.preis_anschreiben < pricesheet.einkaufspreis_netto) {
        let value = pricesheet.preis_anschreiben - pricesheet.nova_verguetung;
        price = value;
      } else if(pricesheet.steuersatz == 2) {
        let value = pricesheet.preis_anschreiben - (pricesheet.preis_anschreiben - pricesheet.einkaufspreis_netto)/6 - pricesheet.nova_verguetung;
        if(6*(value - pricesheet.einkaufspreis_netto/ 6) / 5 > pricesheet.einkaufspreis_netto) {
          price = 6*(value - pricesheet.einkaufspreis_netto/ 6) / 5;
        } else {
          price = value;
        }
      } else {
        price = pricesheet.preis_anschreiben - 1.2 * pricesheet.nova_verguetung;
      }
      if(price % 100 < 880) {
        price = price - price % 100 + 80;
      } else {
        price = price - price % 100 + 180;
      }
      return price;
    } else {
      let price =  pricesheet.preis_anschreiben - pricesheet.summe_nova_vor;
      if(pricesheet.summe_nova_vor == 0) {
        return price;
      }
      if(price % 100 < 880) {
        price = price - price % 100 + 80;
      } else {
        price = price - price % 100 + 180;
      }
      return price;
    }
  }
}
