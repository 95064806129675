
<div id="overview_container">
    <div class="data_container" *ngIf="showSurfaceOverview == 1">
        <div class="lbl_bold_light_color">Detailansicht</div>
        <div class="details_header">
          <div class="details_header_inner_flex">
            <div id="shorthand">{{getInitials()}}</div>
            <div class="details_header_inner">
              <div class="name-wrapper">
                <h1>{{customer.contactPerson.title != 'Kein Titel' ? customer.contactPerson.title : ''}} {{customer.getName()}}</h1>
                <label *ngIf="customer.typ == 1 && customer.atuNum != null " class="overview_record_lbl" style="margin-bottom: 4px;">(ATU: {{customer.atuNum}})</label>
              </div>
              <label class="lbl_regular_italic_light_color">Kunden-ID: {{customer.id}}</label>
              <label *ngIf="customer.employee" class="lbl_italic">betreut von {{customer.employee.firstName + ' ' + customer.employee.lastName}}</label>
            </div>
          </div>
          <div id="additional_info">
            <div [ngClass]="customer.typ == 1 ? 'lbl_color_wrapper_yellow' : 'lbl_color_wrapper_violet'" style="margin-right: 15px;">{{customer.typ == 1 ? 'Firma' : 'Privat'}}</div>
            <div [ngClass]="customer.status == 1 ? 'lbl_color_wrapper_brown' : 'lbl_color_wrapper_cyan'">{{customer.status == 1 ? 'Kunde' : 'Intressent'}}</div>
          </div>
        </div>
        <div id="details_body">
            <div id="information_container" class="column">
                <label class="overview_data_box_lbl_title">Daten</label>
                <div class="info_container" *ngIf="customer.typ == 1">
                  <img class="info_icon" src="../../../../assets/user-small.svg">
                  <div class="info_inner_container">
                      <label class="overview_record_lbl">Kontaktperson</label>
                      <label>{{customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName}}</label>
                  </div>
                </div>
                <div class="info_container">
                    <img class="info_icon" src="../../../../assets/mail.svg">
                    <div class="info_inner_container">
                        <label class="overview_record_lbl">E-Mail</label>
                        <label>{{customer.contactPerson.email}}</label>
                    </div>
                </div>
                <div class="info_container">
                    <img class="info_icon" src="../../../../assets/phone.svg">
                    <div class="info_inner_container">
                        <label class="overview_record_lbl">Telefon</label>
                        <label>{{customer.contactPerson.phone}}</label>
                    </div>
                </div>
                <div class="info_container">
                    <img class="info_icon" src="../../../../assets/pin.svg">
                    <div class="info_inner_container">
                        <label class="overview_record_lbl">Adresse</label>
                        <label [innerHTML]="getAddress(customer)"></label>
                    </div>
                </div>
                <div class="info_container" *ngIf="customer.contactPerson.birthdate">
                    <img class="info_icon" src="../../../../assets/calendergray.svg">
                    <div class="info_inner_container">
                        <label class="overview_record_lbl">Geburtsdatum</label>
                        <label>{{customer.contactPerson.birthdate | date:'dd.MM.yyyy'}}</label>
                    </div>
                </div>
                <div class="overview_data_box_lbl_title" style="margin-top: 40px;">
                  Anhänge
                  <button *ngIf="!showFileDrop" class="btn_list_details" (click)="showFileDrop = true">hinzufügen</button>
                </div>
                <label *ngIf="sources.length == 0" class="lbl_regular_italic_light_color">Keine Dateien hinterlegt</label>
                <div class="attachment-list">
                  <app-attachment-element *ngFor="let source of sources"
                    [source]="source"
                    (deleteEmitter)="removeCustomerAttachmentAction($event)"
                  ></app-attachment-element>
                </div>
                <div *ngIf="showFileDrop">
                  <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)" (click)="selectFile_dokumente.click()">
                    <div class="upload_box_info">
                        <img src="../../../../../assets/upload.svg">
                        <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen.</label>
                        <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
                    </div>
                  </div>
                </div>
                <div class="footer">
                    <div id="lbl_advertising">Will Werbesendung erhalten?</div>
                    <div [ngClass]="customer.advertismentAccepted ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_red'">{{customer.advertismentAccepted ? 'Ja' : 'Nein'}}</div>
                </div>
            </div>
            <div class="column">
              <label class="overview_data_box_lbl_title">Historie</label>
              <div id="list_transactions">
                <div class="list_transactions_element" *ngFor="let trans of transactions; let last = last" [ngStyle]="{'border': last ? 'none' : ''}">
                  <div class="history_icon" [ngStyle]="{'background-color': getTransactionColor(trans.dataTyp)}"><img [src]="'../../../../assets/'+getTransactionIcon(trans.dataTyp)"></div>
                  <div class="info_inner_container">
                      <div class="list_transactions_element_inner">
                        <label class="lbl_regular_thick">{{trans.text}}</label>
                        <label>{{trans.timestamp |  date:'dd MMM yyyy, HH:mm'}}</label>
                      </div>
                      <div class="lbl_italic">von {{trans.username}}</div>
                  </div>
                </div>
              </div>
              <div class="title_column" *ngIf="customer.vehicles.length > 0">Fahrzeuge</div>
              <div id="list_cars">
                <div class="list_cars_element" *ngFor="let car of customer.vehicles">
                  <div class="list_transactions_element_inner">
                    <img class="info_icon" src="../../../../assets/carmenu-light-color.svg">
                    <label class="lbl_regular_thick">{{car.getShortDescription(50)}}</label>
                  </div>
                  <div class="list_transactions_element_inner">
                    <button class="btn_list_details" (click)="openCarDetails(car)">
                      <img class="btn_list_details_icon" src="../../../../../assets/eye.svg">
                      <span>Details</span>
                    </button>
                    <button class="btn_single_icon" (click)="removeCustomerCarAction(car)">
                      <img class="btn_single_icon_icon" src="../../../../../assets/deletedark.svg">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div id="administration_container" class="column">
                <div class="title_column">Dokumente</div>
                <div class="admin_car">Keine Dokumente vorhanden.</div>
            </div> -->
        </div>
    </div>
    <app-overview-tickets *ngIf="showSurfaceOverview == 2"
      [customer]="customer"
      [threads]="threads"
      [employees]="employees"
      (resolveEmitter)="resolveThread($event)"
    ></app-overview-tickets>
</div>
