import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { MenuLoader } from 'src/app/buisness-object/menu/MenuLoader';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { OpenCloseDropDown } from 'src/app/helpers/Animations';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-title',
  templateUrl: './list-title.component.html',
  styleUrls: ['./list-title.component.css'],
  animations: [ OpenCloseDropDown ]
})
export class ListTitleComponent implements OnInit, OnChanges, OnDestroy {
  public showDropdown = false;
  @Input() isTitle: boolean;
  @Input() pageInfo: string;
  @Input() showInformation: number;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() amount: number;
  @Input() sortObject: any;
  @Input() filterForm: FormGroup;
  @Input() mainMenus: MainMenu[];
  @Input() objects: any[];
  @Input() activeSubMenu: SubMenu;
  @Input() activeSubMenu$: Observable<SubMenu>;
  @Input() activeFilter: any[];
  @Input() objectTyp: string;
  @Input() employees: Employee[] = [];
  @Output() customFilterEmitter = new EventEmitter<any>();
  @Output() customFilterOutsideEmitter = new EventEmitter<any>();
  @Output() searchEmitter = new EventEmitter<any>();
  @Output() filterEmitter = new EventEmitter<number>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() changeShownDataEmitter = new EventEmitter<number>();
  @Output() sortEmitter = new EventEmitter<undefined>();
  @Output() removeFilterValueEmitter = new EventEmitter<string>();
  @Output() applySortEmitter = new EventEmitter<undefined>();
  @Output() applySpecialFilter = new EventEmitter<any>();
  public maxAmountListPage = environment.max_amount_list_page;
  public reduzeForScreenSize = GlobalVariables.screen_size == 2;
  public outsideFilterVisible = false;
  public menus = [
    MenuLoader.carMenu1,
    MenuLoader.carMenu2,
    MenuLoader.carMenu3,
    MenuLoader.carMenu4,
    MenuLoader.carMenu5
  ]
  public specialFilterValues = [];
  public showSpecialFilter = false;
  public showDrop = false;
  public selectedMonthFilter;
  public searchPlaceholder;

  public showFilter = false;
  public showCustomFilter = false;
  public customFilterVisible = false;
  private searchTimeout: any;

  constructor(
    public authService: AuthenticationService,
    public service: VehicleService,
    private enumService: EnumService
  ) { }

  ngOnInit(): void {
    this.setSearchPlaceholer();
    this.activeSubMenu$.subscribe((active) => {
      if(active) {
        this.customFilterVisible = active.filterable && active.filterable.filter((a) => a.position == "INSIDE").length > 0;
        this.outsideFilterVisible = active.filterable.find((f) => f.position == "OUTSIDE") != undefined;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['activeSubMenu']){
      if(this.activeSubMenu?.name == "Verkauf" && this.objectTyp == 'vehicle'){
          this.specialFilterValues = [];
          for(let v of this.objects){
            if(v.priceSheet && v.priceSheet.al_re_datum != null){
              let date = new Date(v.priceSheet.al_re_datum);

              let index = this.specialFilterValues.findIndex((value) => new Date(value).getMonth() == date.getMonth() && new Date(value).getFullYear() == date.getFullYear());
              if(index == -1) this.specialFilterValues.push(v.priceSheet.al_re_datum);
            }
          }
          this.specialFilterValues.sort((a,b) => {
            return b - a;
          })
          this.showSpecialFilter = true;
      } else {
        this.showSpecialFilter = false;
      }
    }
  }

  ngOnDestroy(): void {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
  }

  selectionMonth(value: any) {
    this.selectedMonthFilter = value;
    this.applySpecialFilter.emit(value);
  }

  removeFilterValue(value: string) {
    this.removeFilterValueEmitter.emit(value)
  }
  removeSortValue() {

  }

  changeSort(menu) {
    if(menu.is_sort) {
      if(this.sortObject.sort_after_value == menu.sort_value) {
        this.sortObject.sort_asc = !this.sortObject.sort_asc;
      } else {
        this.sortObject.sort_after_value = menu.sort_value;
      }
      this.applySortEmitter.emit();
    }
  }

  getSortIcon(menu) {
    if(menu.is_sort) {
      return this.sortObject.sort_after_value==menu.sort_value?(this.sortObject.sort_asc?"../../../../../assets/sortup.svg":"../../../../../assets/sortdown.svg"):"../../../../../assets/sortnone.svg";
    }
  }

  setSearchPlaceholer()  {
    switch (this.objectTyp) {
      case 'vehicle': this.searchPlaceholder = 'Fahrzeuge durchsuchen'; break;
      case 'kaufvertrag': this.searchPlaceholder = 'Kaufverträge durchsuchen'; break;
      default: this.searchPlaceholder = 'Durchsuchen'; break;
    }
  }

  downloadCsv() {
    let columns = this.activeSubMenu.columns.filter(c => c.data_key != "thumbnail");
    let csv = "\uFEFF" + columns.map(c => c.title).join(";") + "\n";
    for (let o of this.objects) {
      csv += columns.map(c => this.getMenuData(c, o)).join(";") + "\n";
    }
    this.service.download(csv, this.activeSubMenu.name, "text/csv");
  }

  getMenuData(column: SubMenuColumn, object: any) {
    if(column.data_key.includes('.') && !column.calculated){
      let keys = column.data_key.split('.');
      let value = object;
      for(let i = 0; i < keys.length; i++){
        value = value[keys[i]];
        if(value == undefined || value == null){
          break;
        }
      }
      return value != undefined && value != null ? this.getLabel(value.toString(), column, object) : '---';
    }
    return this.getLabel(object[column.data_key], column, object);
  }

  getLabel(value: string, column: SubMenuColumn, object: any) {
    if(column.calculated === 1){
      return this.getCalcluatedValue(column, object);
    }
    if(column.data_typ === 'STRING'){
      return value && value.length > 0 ? value : '---';
    }
    if(column.data_typ === 'DATE'){
      if(value == null || value == '') return '---';
      return this.getDateValue(value, column);
    }
    if(column.data_typ === 'NUMBER'){
      if(value == null || value == '') return '---';
      let num = this.getNumberValue(value, column);
      return num;
    }
    if(column.data_typ === 'BOOLEAN'){
      return this.getBooleanValue(value);
    }
    if(column.data_typ === 'ENUM'){
      return this.getEnumValue(column.data_key, object[column.data_key]);
    }
    return (value != null && value != '') ? value : '---';
  }

  getCalcluatedValue(column: SubMenuColumn, object: any) {
    //special functions
    if(column.data_key == "getCustomerName"){
      if (object.customer != null){
        return object.customer.getFullName();
      }
    }
    //functions
    if(typeof object[column.data_key] === 'function'){
      let value = object[column.data_key]();
      return this.getDataTypValue(value, column);
    } else if(column.data_key.includes('.')){
      //Ween tieferes Object und funktion
      let deepObjKey = column.data_key.split('.')[0];
      let deepFunction = column.data_key.split('.')[1];
      let deepObj = object[deepObjKey];
      if(deepObj && typeof deepObj[deepFunction] == 'function'){
        let value = deepObj[deepFunction]();
        return this.getDataTypValue(value, column);
      }
    } else {
      //Wenn keine funktion aber spezieller Datensatz
      if(column.data_key === "getUstVor") {
        let value = object[column.data_key]();
        if(value == undefined) return '---';
        return this.getNumberValue(value, column);
      }
      if(column.data_key === 'make_and_model'){
        return object.brand.makeName + ' ' + object.model.name;
      }
      if(column.data_key === 'make_and_version'){
        return object.brand.makeName + ' ' + object.model.version;
      }
      if(column.data_key === 'model_and_version'){
        let vehicle;
        if(object instanceof Vehicle){
          vehicle = object
        } else {
          vehicle = object.vehicle;
        }
        if(vehicle){
          if(vehicle.model.version.startsWith(vehicle.model.name)) {
            return vehicle.model.version;
          }
          return vehicle.model.name + ' ' + vehicle.model.version;
        }
        return '---';
      }
      if(column.data_key === "getOnlineSeit") {
          let value = object[column.data_key]();
          if(value == undefined) return '---';
          return value + (column.suffix ? column.suffix : "");
      }
      if(column.data_key === 'priceSheet.steuersatz'){
        switch(object.priceSheet?.steuersatz){
          case 1: return '20%';
          case 2: return '0%';
          case 3: return '19%';
          default: return '---';
        }
      }
      // if(column.data_key == 'vertrag_status'){
      //   return this.getEnumValue('vertrag_status', object.vertrag_status+1);
      // }
      if(column.data_key == 'ledger_entry_status'){
        return this.getEnumValue('ledger_entry_status', object.ledger_entry_status);
      }
      return (object[column.data_key] != null && object[column.data_key] != '') ? object[column.data_key] : '---';
    }
  }

  getDataTypValue(value, column: SubMenuColumn): any {
    if(value != null){
      switch (column.data_typ) {
        case 'STRING':
          return value;
        case 'NUMBER':
          return this.getNumberValue(value, column);
        case 'DATE':
          return this.getDateValue(value, column);
        case 'BOOLEAN':
          return this.getBooleanValue(value);
        default: return value;
      }
    } else {
      return '---';
    }
  }

  getNumberValue(value: any, column: SubMenuColumn): any {
    let num;
    switch(column.format){
      case '0.00':
        let truncatedValue = Math.floor(parseFloat(value) * 100) / 100;
        num = new NumberFormatPipe().transform(truncatedValue.toFixed(2));
        break;
      case '0.000': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(3)); break;
      default: num = new NumberFormatPipe().transform(parseFloat(value).toFixed()); break;
    }
    let suffix = column.suffix ? column.suffix.trim() : "";
    return num + (suffix == "%" || suffix == "€" ? suffix : "");
  }

  getDateValue(value: any, column: SubMenuColumn): any {
    try {
      return formatDate(value, column.format, 'de');
    } catch (error) {
      console.log("Error date format");
      return '---';
    }
  }

  getBooleanValue(value : any): any  {
    return (value && value != "0") ? 'Ja' : 'Nein';
  }

  public getEnumValue(enumName: string, value: number): any {
    return this.enumService.getEnumValue(enumName, value);
  }
}
