import { Attachment } from "../attachment/Attachment";
import { Customer } from "../customer/Customer";
import { Employee } from "../employee/Employee";
import { Vehicle } from "../vehicle/Vehicle";
import { VehicleBrand } from "../vehicle/basic/VehicleBrand";
import { VehicleModel } from "../vehicle/basic/VehicleModel";
import { SelektiertesZusatzprodukt } from "../zusatzprodukt/SelektiertesZusatzprodukt";
import { Zusatzprodukt } from "../zusatzprodukt/Zusatzprodukt";
import { Bill } from "./Bill";

export enum KaufvertragStatus {
  OPEN = 0,
  SIGNED = 1,
  CANCELED = 2,
  ARCHIVED = 10
}

export const KaufvertragStatusLabels: { [key in KaufvertragStatus]: string } = {
  [KaufvertragStatus.OPEN]: 'Offen',
  [KaufvertragStatus.SIGNED]: 'Unterzeichnet',
  [KaufvertragStatus.CANCELED]: 'Storniert',
  [KaufvertragStatus.ARCHIVED]: 'Archiviert',
};

export function getKaufvertragStatusClass(status: KaufvertragStatus): string {
  switch (status) {
    case KaufvertragStatus.OPEN: return 'lbl_color_wrapper_orange'; break;
    case KaufvertragStatus.SIGNED: return 'lbl_color_wrapper_green'; break;
    case KaufvertragStatus.CANCELED: return 'lbl_color_wrapper_red'; break;
    case KaufvertragStatus.ARCHIVED: return 'lbl_color_wrapper_gray'; break;
    default: return ''; break;
  }
}

export enum PaymentStatus {
  OFFEN = 0,
  EINZAHLUNG = 1,
  BEZAHLT = 2,
}

export class Kaufvertrag {
  public customer: Customer;
  public bill_recipient: Customer;
  public vehicle: Vehicle;
  public signer_employee: Employee;
  public creator_employee: Employee;
  public selektierteZusatzprodukte: SelektiertesZusatzprodukt[] = [];
  public verfuegbareZusatzprodukte: Zusatzprodukt[] = [];
  public isReady = true;
  public attachments: Attachment[] = [];
  public bills: Bill[] = [];
  public custom_bill_ids: string[] = [];
  public saved_vehicle_id: number;
  constructor(
    public purchase_contract_id: number,
    public customer_id: number,
    public vehicle_id: number,
    public purchase_date: number,
    public handing_over_date: number,
    public price: number,
    public registration_document: number,
    public is_original_document: boolean,
    public vehicle_status: VehicleStatus,
    public payment_condition: number,
    public vertrag_status: number,
    public foreign_registration_date?: number,
    public purchase_test_date?:number,
    public purchase_tester?: string,
    public warranty_period?: number,
    public deposit?: number,
    public trade_in_vehicle?: TradeInVehicle,
    public special_conditions?: string,
    public finanzierungsgesellschaft?: number,
    public finanzierungsart?: number,
    public ready_for_operation?: boolean,
    public test_drive?: boolean,
    public spare_wheel?: boolean,
    public honey_comb_lifter?: boolean,
    public remote_control?: boolean,
    public card_material?: boolean,
    public trailer_hitch?: boolean,
    public rim_lock?: boolean,
    public down_payment?: number,
    public valid_date?: number,
    public employee_id?: number,
    public finanzierungsart_anzahlung?: number,
    public down_payment_condition?: number,
    public payment_status?: number,
    public handed_over_date?: number,
    public sign_date?: number,
    public creator_employee_id?: number,
    public kv_nr_month?: number,
  ){}

  getCustomKvName(): string {
    let date = new Date(this.purchase_date);
    let monthString = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
    return (this.purchase_contract_id + "/" + monthString);
  }

  getReferenz(): string {
    return this.vehicle ? 'Kaufvertrag für '+this.vehicle?.getBrandName() : '---';
  }

  getCustomerName(customers: Customer[]): string {
    if(this.customer_id == null) return '---';
    for(let customer of customers){
      if(customer.id == this.customer_id){
        return customer.getFullName();
      }
    }
    return '---'
  }

  getVetragssumme(): number {
    let sum = this.price;
    for(let service of this.selektierteZusatzprodukte){
      if(service && service.preis_selektiert) {
        sum += service.preis_selektiert;
      }
    }
    /*if(this.trade_in_vehicle?.brand != null){
      sum -= this.trade_in_vehicle.price;
    }*/
    return sum;
  }

  getStandtage(): number {
    if(this.vehicle?.freigeschaltet_am_plattform == null) return undefined;
    const date = new Date(this.vehicle.freigeschaltet_am_plattform);
    let today = new Date();
    if(this.purchase_date) {
      today = new Date(this.purchase_date);
    }

    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const differenceInMilliseconds = today.getTime() - date.getTime();
    const daysDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;
    return daysDifference;
    if(this.vehicle.freigeschaltet_am_plattform == null) return 0;
    return 1;
  }
  getGV(): string {
    let provision = this.getProvision([2]);
    if(provision == 0) return "---";
    return ""+this.getProvision([2]);
  }

  getProvision(ids: number[]): number {
    let services = this.selektierteZusatzprodukte.filter((z) => ids.includes(z.zusatzprodukt_id));
    let sum = 0;
    for(let service of services) {
      if(service && service.preis && service.preis_selektiert) {
        if([5, 4, 1].includes(service.zusatzprodukt_id)) {
          if(service.preis_selektiert >= service.preis) {
            sum += 1;
          }
        } else {
          if(service.preis_selektiert > 0 && service.preis_selektiert >= service.preis) {
            sum += 1 + ((service.preis_selektiert - service.preis) - (service.preis_selektiert - service.preis) % 100) / 100;
          }
        }
      }
    }
    return sum;
  }

  getServ(): string {
    let provision = this.getProvision([3]);
    if(provision == 0) return "---";
    return ""+provision;
  }
  getReifen(): string {
    let provision = this.getProvision([1]);
    if(provision == 0) return "---";
    return ""+provision;
  }
  getLack(): string {
    let provision = this.getProvision([6,7,8,9,10,11,12,13,14]);
    if(provision == 0) return "---";
    return "" + provision;
  }
  getAZProv(): string {
    let provision = this.getProvision([4]);
    if(provision == 0) return "---";
    return "" + provision;
  }

  showAnzahlungKassieren(): boolean {
    if(this.payment_status == PaymentStatus.OFFEN && (this.down_payment != null && this.down_payment > 0)) return true;
    return false;
  }

  showEndzahlungKassieren(): boolean {
    if(this.payment_status != PaymentStatus.BEZAHLT && !this.showAnzahlungKassieren()) return true;
    return false;
  }

  gePurchaseContractIdValue(compareObj: Kaufvertrag): number {
    return this.purchase_contract_id - compareObj.purchase_contract_id;
  }

  public getCreateSignatureJSON(): any {
    return {
      "signature_document" : {
        "typ" : 3,
        "reference_id" : this.purchase_contract_id,
        "authorization_number": "---",
        "authorization_authority": "---",
        "authorization_date": new Date().getTime(),
        "authorization_groups" : "---",
        "trip_description": "Bezirk Krems & Umgebung"
     }
    }
  }

  getJSON(): any {
    let contract = {
      "purchase_contract_id" : this.purchase_contract_id == 0 ? null : this.purchase_contract_id,
      "customer_id": this.customer_id,
      "vehicle_id" : this.vehicle_id,
      "purchase_date" : this.purchase_date,
      "handing_over_date" : this.handing_over_date,
      "price" : this.price,
      "registration_document" : this.registration_document,
      "is_original_document" : this.is_original_document,
      "vehicle_status" : {
          "mechanical_condition" : this.vehicle_status.mechanical_condition,
          "body_condition" : this.vehicle_status.body_condition,
          "paint_condition": this.vehicle_status.paint_condition,
          "interior_condition" : this.vehicle_status.interior_condition,
          "electronic_condition": this.vehicle_status.electronic_condition
      },
      "payment_condition" : this.payment_condition,
      "foreign_registration_date": this.foreign_registration_date,
      "purchase_test_date": this.purchase_test_date,
      "purchase_tester": this.purchase_tester,
      "warranty_period": this.warranty_period,
      "deposit": this.deposit, //kaution
      "old_vehicle": this.trade_in_vehicle ? this.trade_in_vehicle.getJSON() : null,
      "special_conditions": this.special_conditions,
      "finanzierungsgesellschaft": this.finanzierungsgesellschaft,
      "finanzierungsart": this.finanzierungsart,
      "finanzierungsart_anzahlung": this.finanzierungsart_anzahlung,
      "ready_for_operation": this.ready_for_operation,
      "test_drive": this.test_drive,
      "spare_wheel": this.spare_wheel,
      "honey_comb_lifter": this.honey_comb_lifter,
      "trailer_hitch": this.trailer_hitch,
      "remote_control": this.remote_control,
      "card_material": this.card_material,
      "rim_lock": this.rim_lock,
      "down_payment": this.down_payment, //anzahlung
      "down_payment_condition": this.down_payment != null ? this.down_payment_condition : null,
      "valid_date": this.valid_date,
      "handed_over_date": this.handed_over_date,
      "sign_date": this.sign_date,
      "vehicle": this.vehicle.getJSON(null),
    };
    let services = [];
    for(let zusatzprodukt of this.selektierteZusatzprodukte){
      services.push({
        "service_id": zusatzprodukt.zusatzprodukt_id,
        "custom_price": zusatzprodukt.preis_selektiert,
        "custom_description": zusatzprodukt.name_selektiert
      });
    }
    contract['services'] = services;
    return contract;
  }
}

export class VehicleStatus {
  constructor(
    public mechanical_condition: number,
    public body_condition: number,
    public paint_condition: number,
    public interior_condition: number,
    public electronic_condition: number
  ){}

  getOverallCondition(): string {
    let conditions: number[] = [
      this.mechanical_condition,
      this.body_condition,
      this.paint_condition,
      this.interior_condition,
      this.electronic_condition
    ];
    let min = Math.min(...conditions);
    let max = Math.max(...conditions);
    if(min != max) {
      return 'Klasse ' + min + '-' + max;
    } else {
      return 'Klasse ' + min;
    }
  }

  public static getConditionColor(value: string): string {
    if(value == 'Klasse 1') return 'lbl_color_wrapper_green';
    if(value == 'Klasse 1-2') return 'lbl_color_wrapper_green';
    if(value == 'Klasse 2') return 'lbl_color_wrapper_green';
    if(value.includes('5')) return 'lbl_color_wrapper_red';
    if(value.includes('4')) return 'lbl_color_wrapper_orange';
    return 'lbl_color_wrapper_yellow';
  }
}

export class TradeInVehicle {
  constructor(
    public brand: VehicleBrand,
    public model: VehicleModel,
    public registration_document: number,
    public mileage: number,
    public price: number,
    public registration_date: number,
    public previous_owner: number,
    public vin_number: string,
    public mileage_to_drive: number,
    public color: string,
    public fuel: string,
    public national_code: string,
    public includes_tax: boolean,
    public reduce_price: boolean
  ){}

  getJSON(): any {
    return {
      "brand" :  {
          "make" : {
              "make_id" : this.brand?.id,
              "make_name" : this.brand?.makeName
          },
          "model" : {
              "model_id" : this.model?.id,
              "model_name" : this.model?.name
          }
      },
      "registration_document" : this.registration_document,
      "mileage" : this.mileage,
      "price" : this.price,
      "registration_date": this.registration_date,
      "previous_owner" : this.previous_owner,
      "fin_number": this.vin_number,
      "mileage_to_drive" : this.mileage_to_drive,
      "color" : this.color,
      "fuel" : this.fuel,
      "nat" : this.national_code,
      "includes_tax" : this.includes_tax,
      "reduce_price" : this.reduce_price
    };
  }
}
