import { Picture } from "../Picture";

export class PictureFactory {

  static jsonFactory(rawBody: any): Picture[] {
    const data: Picture[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): Picture {
      const obj = new Picture(
        rawPart.picture_id,
        rawPart.position,
        rawPart.object_id,
        rawPart.src,
      )
      return obj;
  }
}
