// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //api: 'http://localhost:8797/api/v1', //sinhuber
  //api: 'http://localhost:8877/api/v1', //hoedl
  //api: 'https://hofbauer-api.auto-base.at/api/v1',
  api: 'https://hoedl-api.auto-base.at/api/v1',
  //api: 'https://sinhuber-api.auto-base.at/api/v1',
  //api: 'https://test-api.auto-base.at/api/v1',
  //api: 'https://api.auto-base.at/api/v1', //Standard
  developer: false,
  VERSION: require('../../package.json').version,
  max_amount_list_page: 50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
