<div id="dialog_window" *ngIf="show">
  <div id="dialog_container" appDropdown [show]="show" (changedValueEmitter)="show = $event">
    <div id="dialog_close_icon" (click)="show = false">&#10005;</div>
    <h1>{{title}}</h1>
      <div id="dialog_conainter_body">
        <label *ngIf="message">{{message}}</label>
        <div id="dialog-body-enums-wrapper">
          <button *ngFor="let enum of enmusData; let i = index"
            type="button"
            [attr.id]="'dialog-enum-value_'+i"
            class="dialog-button-enum"
            (click)="selectionEnum(i)">
            {{enum.name}}
          </button>
        </div>
      </div>
      <div class="dialog_btn_container">
        <button class="btn_label" (click)="show=false">{{btnCancelTxt}}</button>
        <button class="btn_submit_dark" (click)="onSubmit()">{{btnSubmitTxt}}</button>
      </div>
  </div>
</div>
