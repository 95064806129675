import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TransactionFactory } from 'src/app/buisness-object/transaction/factory/TransactionFactory';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) {}

  public getTransactions(objectTyp: string, id: number): Observable<Transaction[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let url = environment.api + '/back/'+objectTyp+'/transaction/' + id;
    if(objectTyp == 'employee') url = environment.api + '/back/transaction/' + id
    const observable = this.http.get(url, { headers });
    return observable.pipe(
      map((rawTransactions: any) => {
        const transactions = TransactionFactory.jsonFactory(rawTransactions.transactions);
        return transactions;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
