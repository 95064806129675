import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, concat, interval } from 'rxjs';
import { first, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(
    public updates: SwUpdate,
    public appRef: ApplicationRef,
    private loginService: LoginService
  ) {
    if(environment.production){
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const time$ = interval(1000*60*5);
      const intervalOnceAppIsStable$ = concat(appIsStable$, time$);

      intervalOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.updates.checkForUpdate();
          console.log("Checking update. Found: "+updateFound)
          if(updateFound) {
            this.promptUser();
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
      this.updates.checkForUpdate().then((result) => {
        console.log(result)
      })
    }
    this.updates.versionUpdates.subscribe(event => {
      if(event.type === 'VERSION_READY') {
        this.promptUser();
      } else if(event.type === 'VERSION_INSTALLATION_FAILED'){
        const hash = event.version?.hash;
        console.log("Installation check")
        if(localStorage.getItem('version_hash') != hash) this.promptUser(hash);
      }
    });
  }

  private promptUser(hash?: string): void {
    if(confirm('Neue version verfügbar. Seite wird neu geladen. Bitte beachten Sie, dass Sie sich erneut anmelden müssen')) {
      if(hash) localStorage.setItem('version_hash', hash);
      this.loginService.logout();
      setTimeout(() => {
        this.updates.activateUpdate().then(() => window.location.reload());
      }, 300);
    }
  }
}
