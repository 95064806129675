import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';
import { TransactionHelper } from 'src/app/helpers/TransactionHelper';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { Rent, RentOrderStatusLabels, RentTyp, RentTypLabels } from 'src/app/buisness-object/rent/Rent';
import { RentService } from 'src/app/service/rent/rent.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RentForm } from 'src/app/buisness-object/rent/form/RentForm';
import { getLabelFromEnumValue, } from 'src/app/utils/EnumHandler';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-miete-details',
  templateUrl: './miete-details.component.html',
  styleUrl: './miete-details.component.css'
})
export class MieteDetailsComponent extends BaseComponent implements OnInit {

  @Input() obj: Rent;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionEditEmitter = new EventEmitter<any>();
  @Output() selectionReturnEmitter = new EventEmitter<any>();
  @Output() slectionDocumentEmitter = new EventEmitter();
  @Output() selectionCancelRentEmitter = new EventEmitter();

  public rent_typ;
  public rent_status;
  public shwoActions = false;
  public form: FormGroup;
  public rentTypValue;
  public rentOrderStatusValue;

  rents: Rent[] = [];
  filename: string = 'Vertrag.pdf';

  ngOnInit(): void {
    this.form = RentForm.getForm(this.formBuilder, this.obj);

    this.rentTypValue = getLabelFromEnumValue(this.form.get('rent_typ').value, RentTypLabels);
    this.rent_typ = this.rentTypValue;

    this.rentOrderStatusValue = getLabelFromEnumValue(this.form.get('rent_status').value, RentOrderStatusLabels);
    this.rent_status = this.rentOrderStatusValue;
    //this.rent_status = this.enumService.getEnumValue('rent_status', this.obj.rent_status);
    //this.form = RentForm.getForm(this.formBuilder, this.obj);
  }

  public transactions: Transaction[] = [];
  public TransactionHelper = TransactionHelper;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private transactionSerive: TransactionService,
    private rentService: RentService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
  ){
    super(authService, eService);
  }

  open(id: number) {
    this.rentService.getRentPdf(id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true, filename: this.filename });
      }
    });
  }

}
