import { DropdownIconArrowAnimation } from './../../../../helpers/Animations';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { formatDate } from '@angular/common';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})
export class AdditionalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;
  @Input() activeSubMenu: SubMenu;
  @Input() superGroup: number;
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public keyValues = [];

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.getEnumDataOnChange();
  }

  showValue(value: string): boolean {
    return this.showValueBase(this.authService, this.activeSubMenu, this.superGroup, "additional", this.editMode, value);
  }

  isCustomView(): boolean {
    let auths = this.authService.getViewAuth().filter((auth) => auth.id == this.activeSubMenu.sub_menu_id);
    return auths.length > 0
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  get f() {
    return this.formGroupVehicle.controls;
  }

  getEnumDataOnChange() {
    //this.keyValues = this.getEnumValueNames('keys_available');
  }
}
