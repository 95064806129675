import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Address } from "../../customer/Adress";
import { DateHelper } from "src/app/utils/DateHelper";
import { Bill } from "../Bill";
import { CustomerForm } from "../../customer/form/CustomerForm";


export class BillPurchaseContractForm {

  public static getForm(formBuilder: FormBuilder, obj?: Bill): FormGroup {
    let form = formBuilder.group({
      vehicle_co2: [obj.purchase_contract.vehicle.co2Emission, Validators.required],
      steuersatz: [obj.purchase_contract.vehicle.priceSheet.steuersatz, Validators.required],
      vehicle_imported: [obj.purchase_contract.vehicle.priceSheet.vehicle_imported, Validators.required],
      customer_form: [CustomerForm.getCustomerForm(formBuilder, obj.purchase_contract.customer), Validators.required],
    });
    return form;
  }

  public static getObject(form: FormGroup, obj: Bill): Bill {
    obj.purchase_contract.vehicle.co2Emission = form.get('vehicle_co2').value;
    obj.purchase_contract.vehicle.priceSheet.steuersatz = form.get('steuersatz').value;
    obj.purchase_contract.vehicle.priceSheet.vehicle_imported = form.get('vehicle_imported').value;
    obj.purchase_contract.customer = CustomerForm.createObject(form.get('customer_form').value);
    return obj;
  }
}
