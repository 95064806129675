<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionBack()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>Rechnung #{{obj.bill_id}}</h2>
  </div>
  <div class="header-button-wrapper">
    <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Aktionen
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button (click)="selectionEditEmitter.emit(obj)">Bearbeiten</button>
      </div>
    </button>
  </div>
</div>

<div class="body-base-style">
  <!-- <div class="btn-tab-wrapper">
    <button class="btn-tab btn-tab-first" [class.btn-tab-active]="showTab == tabView.Details" (click)="null">Details</button>
    <button *ngIf="false" class="btn-tab btn-last" [class.btn-tab-active]="showTab == tabView.Tickets" (click)="null">Tickets</button>
  </div> -->
  <div id="kaufvertrag-details-body" *ngIf="showTab == tabView.Details">
    <div id="header-column" class="overview_data_box_vertical">
      <div class="overview_record_vertical">
        <h3>Betreff</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.subject"></label>
          <label class="lbl_italic">RE-Nr. {{obj.bill_id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Kunde</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.customer?.getFullName()"></label>
          <label class="lbl_italic">Kunden-ID: {{obj.customer.id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Austellungsdatum</h3>
        <div>
          <label [appFormatValue]="'date'" [value]="obj.bill_date"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Bezahl-Status</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="billStatus" [ngClass]="billStatusStyle"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Buchhaltung</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.in_accounting ? 'Übermittelt' : 'Nicht übermittelt'" [ngClass]="obj.in_accounting ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_gray'"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Gesamtsumme</h3>
        <div>
          <label [appFormatValue]="'currency'" [value]="obj.purchase_contract.getVetragssumme()"></label>
          <label class="lbl_italic">inkl. MwSt.</label>
        </div>
      </div>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zahlung und Zahlungsbedingungen</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Anzahlung</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Rabatt</label>
            <label [appFormatValue]="'currency'" [value]="obj.discount"></label>
          </div>
          <div class="overview_record">
            <label>Gewährleistungsfirst (in Monaten)</label>
            <label [appFormatValue]="'number'" [value]="obj.purchase_contract.warranty_period"></label>
          </div>
          <div class="overview_record">
            <label>Kaufpreis</label>
            <label [appFormatValue]="'currency'" [value]="obj.purchase_contract.price"></label>
          </div>
          <div class="overview_record">
            <label>Übergabe</label>
            <label [appFormatValue]="'date'" [value]="obj.purchase_contract.handing_over_date"></label>
         </div>
         <div class="overview_record">
            <label>Kaution</label>
            <label [appFormatValue]="'currency'" [value]="obj.purchase_contract.deposit"></label>
          </div>
          <div class="overview_record" *ngIf="!authService.isHoedl()">
            <label>Zahlungsmethode</label>
            <label [appFormatValue]="'string'" [value]="paymentConditionValue"></label>
          </div>
          <div class="overview_record" *ngIf="authService.isHoedl() || authService.isSinhuber() || authService.isWaymarkUser()">
            <label>Finanzierungsart</label>
            <label [appFormatValue]="'string'" [value]="finanzierungsartenValue"></label>
          </div>
          <div class="overview_record" *ngIf="authService.isSinhuber()">
            <label>Finanzierungsgesellschaft</label>
            <label [appFormatValue]="'string'" [value]="finanzierungsgesellschaftValue"></label>
          </div>
          <div class="overview_record">
            <label>Sonderkonditionen</label>
            <label [appFormatValue]="'string'" [value]="finanzierungsgesellschaftValue"></label>
          </div>
          <div class="overview_record">
            <label>Gebrauchtwagentausch</label>
            <label [appFormatValue]="'boolean'" [value]="obj.purchase_contract.trade_in_vehicle?.brand?.makeName"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="obj.purchase_contract.trade_in_vehicle">
        <h3 collapsible-header>Gebrauchtwagentausch</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Marke</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.trade_in_vehicle.brand.makeName"></label>
          </div>
          <div class="overview_record">
            <label>Model</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.trade_in_vehicle.model.name"></label>
          </div>
          <div class="overview_record">
            <label>Erstzulassung</label>
            <label [appFormatValue]="'date'" [value]="obj.purchase_contract.trade_in_vehicle"></label>
          </div>
          <div class="overview_record">
            <label>Anzahl Vorbesitzer</label>
            <label [appFormatValue]="'number'" [value]="obj.purchase_contract.trade_in_vehicle.registration_date"></label>
          </div>
          <div class="overview_record">
            <label>VIN-Nummer</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.trade_in_vehicle.previous_owner"></label>
          </div>
          <div class="overview_record">
            <label>NAT-Code</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.trade_in_vehicle.vin_number"></label>
          </div>
          <div class="overview_record">
            <label>Kilometerstand</label>
            <label [appFormatValue]="'number'" [value]="obj.purchase_contract.trade_in_vehicle.national_code"></label>
          </div>
          <div class="overview_record">
            <label>Zulassung für Fahrzeugwert</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.trade_in_vehicle.mileage"></label>
          </div>
          <div class="overview_record">
            <label>Wert</label>
            <label [appFormatValue]="'currency'" [value]="obj.purchase_contract.trade_in_vehicle.price"></label>
          </div>
          <div class="overview_record">
            <label>Mit MwSt.</label>
            <label [appFormatValue]="'boolean'" [value]="obj.purchase_contract.trade_in_vehicle.includes_tax"></label>
          </div>
          <div class="overview_record">
            <label>Vom Kaufpreis abziehen</label>
            <label [appFormatValue]="'currency'" [value]="obj.purchase_contract.trade_in_vehicle.reduce_price"></label>
          </div>
          <div class="overview_record">
            <label>Benutzung bis Übergabe (km)</label>
            <label [appFormatValue]="'number'" [value]="obj.purchase_contract.trade_in_vehicle.mileage_to_drive"></label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] *ngIf="obj.bill_items.length > 0">
        <h3 collapsible-header>Zusatzverkäufe</h3>
        <div collapsible-content>
          <div class="overview_record" *ngFor="let item of obj.bill_items">
            <label>{{item.title}}</label>
            <label [appFormatValue]="'currency'" [value]="item.price"></label>
            <!-- <label [appFormatValue]="'string'" [value]="zusatzprodukt.name_selektiert ? zusatzprodukt.name_selektiert : zusatzprodukt.kategorie"></label>
            <label [appFormatValue]="'string'" [value]="zusatzprodukt.preis_selektiert"></label> -->
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="obj.purchase_contract.creator_employee">
        <h3 collapsible-header>Mitarbeiter</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Mitarbeiter-ID</label>
            <label [appFormatValue]="'string'" [value]="'#'+obj.purchase_contract.creator_employee.id"></label>
          </div>
          <div class="overview_record">
            <label>Name</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.creator_employee.firstName + '' + obj.purchase_contract.creator_employee.lastName"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="obj.purchase_contract.vehicle">
        <h3 collapsible-header>Fahrzeug</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Marke</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.brand.makeName"></label>
          </div>
          <div class="overview_record">
            <label>Model</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.model.name"></label>
          </div>
          <div class="overview_record">
            <label>Version</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.model.version"></label>
          </div>
          <div class="overview_record">
            <label>KFZ-Typ</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.bodyTyp.name"></label>
          </div>
          <div class="overview_record">
            <label>Hauptfarbe</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.bodyColor.name"></label>
          </div>
          <div class="overview_record">
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.vehicle_imported ? 'Zulassung nach Import' : 'Erstregistrierung im Ausland'"></label>
            <label [appFormatValue]="'date'" [value]="obj.purchase_contract.vehicle.firstRegistrationDate"></label>
          </div>
          <div class="overview_record">
            <label>Kilometerstand</label>
            <label [appFormatValue]="'km'" [value]="obj.purchase_contract.vehicle.mileage_internal ? obj.purchase_contract.vehicle.mileage_internal : obj.purchase_contract.vehicle.mileageKm"></label>
          </div>
          <div class="overview_record">
            <label>FZG-Identifizierungsnr.</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.vehicle.fin_number"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="!authService.isHoedl() && !authService.isSinhuber()">
        <h3 collapsible-header>Zulassung</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Erstregistrierung im Ausland</label>
            <label [appFormatValue]="'date'" [value]="obj.purchase_contract.foreign_registration_date"></label>
          </div>
          <div class="overview_record">
            <label>Dokument Typ</label>
            <label [appFormatValue]="'string'" [value]="registrationDocumentValue"></label>
          </div>
          <div class="overview_record">
            <label>Dokument Zustand</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.is_original_document ? 'Original' : 'Kopie'"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Fahrzeugzustand</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Mechanischer Zustand</label>
            <label [appFormatValue]="'string'" [value]="mechanical_conditionValue"></label>
          </div>
          <div class="overview_record">
            <label>Karosserie</label>
            <label [appFormatValue]="'string'" [value]="body_conditionValue"></label>
          </div>
          <div class="overview_record">
            <label>Lack</label>
            <label [appFormatValue]="'string'" [value]="paint_conditionValue"></label>
          </div>
          <div class="overview_record">
            <label>Innenraum/Sonstiges</label>
            <label [appFormatValue]="'string'" [value]="interior_conditionValue"></label>
          </div>
          <div class="overview_record">
            <label>Elektrik Elektronik</label>
            <label [appFormatValue]="'string'" [value]="electronic_conditionValue"></label>
          </div>
          <div class="overview_record">
            <label>Gesamtzustand</label>
            <label [appFormatValue]="'string'" [value]="total_condition" [ngClass]="getConditionColor(total_condition)"></label>
          </div>
          <div class="overview_record">
            <label>Ankaufsüberprüfung</label>
            <label [appFormatValue]="'boolean'" [value]="obj.purchase_contract.purchase_test_date || obj.purchase_contract.purchase_tester"></label>
          </div>
          <div class="overview_record" *ngIf="(obj.purchase_contract.purchase_test_date || obj.purchase_contract.purchase_tester)">
            <label>Datum</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.purchase_test_date"></label>
          </div>
          <div class="overview_record" *ngIf="(obj.purchase_contract.purchase_test_date || obj.purchase_contract.purchase_tester)">
            <label>Prüfer</label>
            <label [appFormatValue]="'string'" [value]="obj.purchase_contract.purchase_tester"></label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
        <div collapsible-content>
          <app-files
            [obj]="document"
          ></app-files>
        </div>
      </app-collapsible>
      <app-transactions
        [obj]="obj"
      ></app-transactions>
    </div>
  </div>
</div>
