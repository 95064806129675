<app-collapsible [isCollapsible]="!editMode && !authService.isSinhuber()" [isContentVisible]="false" [class.data_box_disabled]="editMode && inseratDisable">
  <h3 collapsible-header class="overview_data_box_lbl_title">Identifikation</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record">
          <div class="overview_record_lbl">ID-Nummer</div>
          <div class="overview_record_value">{{vehicle.externalId ? vehicle.externalId : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="!authService.isHoedl()">
          <div class="overview_record_lbl">Motor-Nummer</div>
          <div class="overview_record_value">{{vehicle.engineNumber ? vehicle.engineNumber : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">FIN Nummer</div>
        <div class="overview_record_value">{{vehicle.fin_number ? vehicle.fin_number : '---'}}</div>
      </div>
      <div *ngIf="authService.isHoedl()" class="overview_record">
        <div class="overview_record_lbl">Verkäufer</div>
        <div class="overview_record_value">{{vehicle.vehicle_supplier ? vehicle.vehicle_supplier : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Nationaler Code</div>
        <div class="overview_record_value">{{vehicle.eurotax_code ? vehicle.eurotax_code : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Ursprungsland</div>
        <div class="overview_record_value">{{vehicle.originalMarket ? vehicle.originalMarket : '---'}}</div>
      </div>
      <div class="overview_record">
        <div class="overview_record_lbl">Eigengewicht</div>
        <div class="overview_record_value">{{vehicle.emptyWeight ? ((vehicle.emptyWeight | numberFormat) +'kg') : '---'}}</div>
      </div>
      <div class="overview_record">
          <div class="overview_record_lbl">Gesamtgewicht</div>
          <div class="overview_record_value">{{vehicle.fullWeight ? ((vehicle.fullWeight | numberFormat) +'kg') : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="formGroupVehicle" class="overview_data_form">
        <div class="input_wrapper">
            <label class="overview_record_lbl">ID-Nummer</label>
            <input type="text" class="overview_input" placeholder="ID-Nummer" formControlName="idNummer">
        </div>
        <div class="input_wrapper" *ngIf="!authService.isHoedl()">
            <label class="overview_record_lbl">Motor-Nummer</label>
            <input type="text" class="overview_input" placeholder="Motor-Nummer" formControlName="engineNummer">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Fin Nummer</label>
          <input type="text" class="overview_input" placeholder="Fin Nummer" formControlName="fin_number">
        </div>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <label class="overview_record_lbl">Verkäufer</label>
          <input type="text" class="overview_input" placeholder="Verkäufer" formControlName="vehicle_supplier">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Nationaler Code</label>
          <input type="text" class="overview_input" placeholder="Nationaler Code" formControlName="eurotax_code">
        </div>
        <div class="input_wrapper">
          <label class="overview_record_lbl">Ursprungsland</label>
          <input type="text" class="overview_input" placeholder="Ursprungsland" formControlName="originalMarket">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Eigengewicht in kg</label>
            <input type="number" class="overview_input" placeholder="Eigengewicht" formControlName="ownWeight">
        </div>
        <div class="input_wrapper">
            <label class="overview_record_lbl">Gesamtgewicht in kg</label>
            <input type="number" class="overview_input" placeholder="Gesamtgewicht" formControlName="totalWeight">
        </div>
    </form>
  </div>
</app-collapsible>
