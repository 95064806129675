
import { formatNumber, formatDate } from "@angular/common";
import { EnumHelper } from "../helpers/EnumHelper";
import { PaperStatus, STEUERSATZVALUES, Steuersatz, SteuersatzStandard, SteuersatzValue, TypenscheinBank } from "../helpers/Enums";
import { Customer } from "../buisness-object/customer/Customer";
import { Vehicle } from "../buisness-object/vehicle/Vehicle";
import { EnumData, EnumFactory, EnumValue } from "../buisness-object/menu/EnumData";
import { AuthenticationService } from "../service/authentication/authentication.service";
import { SubMenu } from "../buisness-object/menu/SubMenu";
import { FormGroup } from "@angular/forms";
import { EnumService } from "../service/enum/enum.service";
import { Injector } from "@angular/core";

export const countries: string[] = [
  "Belgien",
  "Bulgarien",
  "Dänemark",
  "Deutschland",
  "Estland",
  "Finnland",
  "Frankreich",
  "Griechenland",
  "Irland",
  "Italien",
  "Kroatien",
  "Lettland",
  "Litauen",
  "Luxemburg",
  "Malta",
  "Niederlande",
  "Österreich",
  "Polen",
  "Portugal",
  "Rumänien",
  "Schweden",
  "Slowakei",
  "Slowenien",
  "Spanien",
  "Tschechien",
  "Ungarn",
  "Zypern"
];

export class BaseComponent {
    public enumHelper = new EnumHelper();
    public typeCertificateBankValues = this.enumHelper.getEnumValueList(TypenscheinBank);
    public steuerseatze = [];
    public periods = [6,9,12,18,24];
    public screenSize = window.innerWidth > 1420 ? 1 : 2;
    public enums: EnumData[] = [];

    constructor(
      private authSerivceBase: AuthenticationService,
      public enumService: EnumService
    ){
      this.enums = this.enumService.enums;
      this.steuerseatze = this.enumService.steuerseatze;
    }

    showValueBase(authService: AuthenticationService, activeSubMenu: SubMenu, superGroup: number, group: string, editMode: boolean, value: string): boolean {
      let auths = authService.getViewAuth().filter((auth) => auth.id == activeSubMenu.sub_menu_id);
      if(auths.length > 0){
        let auth = auths[0];
        if(auth.screens.length > superGroup) {
          let views = editMode? auth.screens[superGroup].edit : auth.screens[superGroup].view;
          if(views.filter((v) => v.name == group).length > 0) {
            let rows = views.filter((v) => v.name == group)[0].rows;
            if(rows.length == 0) {
              return true;
            } else {
              return rows.filter((r) => r == value).length > 0;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
  }

  showValuePricesheet(value: string): boolean {
    if(this.authSerivceBase.isStandard() || this.authSerivceBase.isHoedl()){
      switch (value) {
        case 'aufschlag': return false;
        case 'kosten_lt_vorkalkulation': return false;
        case 'vk_nachkalkulation': return false;
        case 'vk_nachkalkulation_haendler': return false;
        case 'rechnungsdatum_vor': return false;
        case 'eurotax': return false;
        case 'vehicle_serialnumber': return false;
        case 'anpreisungsdatum': return false;
        case 'vehicle_supplier': return false;
        case 'einstockung_bei': return false;
        case 'invoice_checked': return false;
        case 'reparatur_dauer_tage': return false;
        case 'garantie_vor': return false;
        case 'al_re_datum': return false;
        case 'finanzierungszusageValue': return false;
        case 'finanzierungszusage': return false;
        case 'sent_to_bank': return false;
        case 'eingang_anzahlung': return false;
        case 'eingang_anzahlung_description': return false;
        case 'fahrzeuginzahlungnahme': return false;
        case 'export_value': return false;
        case 'nova_satz': return false;
        case 'ohne_nova_inkl_ust': return false;
        case 'garantie_extern': return false;
        case 'gewaehrleistung_garantie_reparaturen': return false;
        case 'finanzierungsgesellschaft': return false;
        case 'finanzierungsart': return false;
        case 'vk_vor_checked': return false;
        case 'flug_bahn': return false;
        case 'gebuehren': return false;
        default: break;
      }
    } else if(this.authSerivceBase.isSinhuber()){
      switch (value) {
        case 'vehicle_imported': return false;
        default: break;
      }
    }
    return true;
  }

  showBoxPricesheet(value: string): boolean {
    if(this.authSerivceBase.isStandard() || this.authSerivceBase.isHoedl() || this.authSerivceBase.isWaymark()){
      switch (value) {
        case 'Typenschein': return false;
        case 'Rubrik Transport': return false;
        case 'Neupreis': return false;
        case 'Notizfeld Geschäftsführung intern': return false;
        case 'NoVA vor': return false;
        case 'Provision-Eingang': return false;
        case 'Provision-Ausgang': return false;
        case 'Vorkalkulation': return false;
        case 'Richtlinien Verkauf': return false;
        default: break;
      }
    } else if(this.authSerivceBase.isSinhuber()){
      switch (value) {
        case 'Provision-Mitarbeiter': return false;
        default: break;
      }
    }
    return true;
  }

  changeValueName(value: string): string {
    if(this.authSerivceBase.isStandard() || this.authSerivceBase.isHoedl() || this.authSerivceBase.isWaymark()){
      switch (value) {
        case 'hereinnahmedatum': return 'Hereinnahmedatum';
        case 'aufschlag_nach': return 'Aufschlag bei Verkauf';
        case 'preis_anschreiben': return 'Verkaufspreis (brutto)';
        case 'minimaler_verkaufpreis': return 'Händlerpreis (brutto)';
        case 'sprit': return 'Transportkosten';
        case 'lieferant_extern': return 'Handlingkosten';
        case 'planed_repair_date': return 'Geplante Reparatur';
        case 'paper_status': return 'Eingestockt bei';
        default: break;
      }
    } else if(this.authSerivceBase.isSinhuber()){
      switch (value) {
        case 'hereinnahmedatum': return 'Fahrzeug-Eingangsdatum';
        case 'aufschlag_nach': return 'Aufschlag bei Verkauf (Nachkalkulation)';
        case 'preis_anschreiben': return 'Verkaufspreis (Vorkalkulation)';
        case 'minimaler_verkaufpreis': return 'Händlerpreis (Vorkalkulation)';
        case 'sprit': return 'Sprit';
        case 'lieferant_extern': return 'Lieferant extern';
        case 'planed_repair_date': return 'AL-Reparatur';
        case 'paper_status': return 'Standort Typenschein';
        default: break;
      }
    }
    return value;
  }

  showKaufvertragValue(value: string): boolean {
    if(this.authSerivceBase.isSinhuber()){
      switch (value) {
        case 'box-zulassung': return false;
        case 'Ankaufsüberprüfung': return true;
        case 'Zahlungsmethode': return true;
        case 'Finanzierungsart': return true;
        case 'Finanzierungsgesellschaft': return true;
        case 'warranty_period': return true;
        case 'trade_in_vehicle_registration_document': return true;
        case 'trade_in_vehicle_includes_tax' : return false;
        case 'trade_in_vehicle_reduce_price' : return false;
        case 'Finanzierungsart-Anzahlung' : return false;
        case 'Anzahlung' : return false;
        case 'Anzahlungs-Zahlungsart' : return false;
        default: break;
      }
    }
    if(this.authSerivceBase.isHoedl()){
      switch (value) {
        case 'box-zulassung': return false;
        case 'Ankaufsüberprüfung': return false;
        case 'Zahlungsmethode': return false;
        case 'Finanzierungsart': return true;
        case 'Finanzierungsgesellschaft': return false;
        case 'warranty_period': return false;
        case 'trade_in_vehicle_registration_document': return false;
        case 'trade_in_vehicle_includes_tax' : return true;
        case 'trade_in_vehicle_reduce_price' : return true;
        case 'Finanzierungsart-Anzahlung' : return true;
        case 'Anzahlung' : return true;
        case 'Anzahlungs-Zahlungsart' : return true;
        default: break;
      }
    }
    if(this.authSerivceBase.isStandard()){
      switch (value) {
        case 'box-zulassung': return true;
        case 'Ankaufsüberprüfung': return true;
        case 'Zahlungsmethode': return true;
        case 'Finanzierungsart': return false;
        case 'Finanzierungsgesellschaft': return false;
        case 'warranty_period': return true;
        case 'trade_in_vehicle_registration_document': return true;
        case 'trade_in_vehicle_includes_tax' : return false;
        case 'trade_in_vehicle_reduce_price' : return false;
        case 'Finanzierungsart-Anzahlung' : return false;
        case 'Anzahlung' : return false;
        default: break;
      }
    }
    return true;
  }

    public getEnumValues(enumName: string): any[] {
      return this.enumService.getEnumValues(enumName);
    }

    public getEnumValueNames(enumName: string): any[] {
      return this.enumService.getEnumValueNames(enumName);
    }

    public getEnumValue(enumName: string, rawValue: number): any {
      return this.enumService.getEnumValue(enumName, rawValue);
    }

    public get paperStatus(): typeof PaperStatus {
        return PaperStatus;
    }

    getDropdownValuesCustomer(customers: Customer[]): any[] {
      let values = [];
      for(let value of customers){
        values.push({
          id: value.id,
          name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
        })
      }
      return values;
    }

    getDropdownValuesVehicles(vehicles: Vehicle[]): any[] {
      let values = [];
      for(let value of vehicles){
        values.push({
          id: value.id,
          name: value.getVehicleName()
        })
      }
      return values;
    }

    getValueFromDropdown(value: DropdownValueType, id: number, objects: any[]) {
      if(value === DropdownValueType.CUSTOMER && Array.isArray(objects) && objects[0] instanceof Customer){
        let index = objects.findIndex(obj => obj.id == id);
        if(index > -1) return objects[index];
      } else if(value === DropdownValueType.VEHICLE && Array.isArray(objects) && objects[0] instanceof Vehicle){
        let index = objects.findIndex(obj => obj.id == id);
        if(index > -1) return objects[index];
      }
    }

    getInputValue(value) {
      return value ? value : '---';
    }

    getInputValueNumber(value): any {
      return value ? formatNumber(value,"de","1.2-2") : '---';
    }

    getInputValueNumber3Dicis(value): any {
      return value ? formatNumber(value,"de","1.3-3") : '---';
    }

    getInputValueNumberNoDicis(value): any {
      return value ? formatNumber(value,"de","1.0-0") : '---';
    }

    getInputValueDate(value): any {
      return value ? formatDate(new Date(value),'dd.MM.yyyy','de') : '---';
    }

    getInputValueTime(value): any {
      return value ? formatDate(new Date(value),'HH:mm','de') : '---';
    }

    getInputValueDateTime(value): any {
      return value ? formatDate(new Date(value),'dd.MM.yyyy, HH:mm','de') : '---';
    }

    getInputValueDateMonth(value): any {
      return value ? formatDate(new Date(value),'MM.yyyy','de') : '---';
    }

    getInputValueKG(value): any {
      return value ? (formatNumber(value,"de","1.0-0")+' kg') : '---';
    }

    getInputValueKM(value): any {
      return value ? (formatNumber(value,"de","1.0-0")+' km') : '---';
    }

    getInputValueCurrency(value): any {
      return value ? (formatNumber(value,"de","1.2-2")+' €') : '---';
    }

    getBooleanStringValue(value) {
      return value != null ? (value ? 'Ja' : 'Nein') : '---';
    }

    getSteuersatz(value): string {
      let str = new EnumHelper().getEnumValue(Steuersatz,value-1);
      return str ? str : '---';
    }

    getSteueratzValue(value: number): number {
      return STEUERSATZVALUES[new EnumHelper().getEnumValue(SteuersatzValue,value-1)]
    }

    onScreenSizeChange() {
      this.screenSize = window.innerWidth > 1420 ? 1 : 2;
    }

    setValueFromDropdownBase(form: FormGroup, value: string, id: number, ressources: any[]) {
      if(value === 'customer'){
        let index = ressources.findIndex(obj => obj.id == id);
        if(index > -1){
          form.controls['customer'].setValue(ressources[index]);
        }
      } else if(value === 'vehicle'){
        let index = ressources.findIndex(obj => obj.id == id);
        if(index > -1){
          form.controls['vehicle'].setValue(ressources[index]);
          if(form.controls['vehicle_id'] != null){
            form.controls['vehicle_id'].setValue(ressources[index].id);
          }
          if(ressources[index].price != null && form.controls['price'] != undefined) form.controls['price'].setValue(ressources[index].price);
        }
      }
    }
}

export class DropdownValueType {
  public static CUSTOMER = 'customer';
  public static VEHICLE = 'vehicle';
}
