import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Drive } from "../Drive";
import { formatDate } from "@angular/common";
import { DateHelper } from "src/app/utils/DateHelper";
import { CustomerForm } from "../../customer/form/CustomerForm";
import { VehicleForm } from "../../vehicle/form/VehicleForm";

export class DriveForm {

  static getFormGroup(formBuilder: FormBuilder, obj?: Drive): FormGroup {
    let defaultSinhuber = 'Bezirk Krems & Umgebung';
    let defaultHoedl = 'Hönigtal';
    let mandantId = Number(localStorage.getItem("mandant_id"));
    let form = formBuilder.group({
      test_drive_id: [this.getFormValue(obj, 'test_drive_id')],
      vehicle: [this.getFormValue(obj, 'vehicle'), Validators.required],
      start_date: [this.getFormValue(obj, 'start_date'), Validators.required],
      start_time: [this.getFormValue(obj, 'start_time'), Validators.required],
      end_time: [this.getFormValue(obj, 'end_time')],
      duration: [obj ? Math.round((obj.start_time - obj.end_time) / 1000 / 60) : 25, Validators.required],
      customer: [this.getFormValue(obj, 'customer')],
      customer_form: [obj && obj.customer ? CustomerForm.getCustomerForm(formBuilder, obj.customer) : null],
      employee: [this.getFormValue(obj, 'employee')],
      attachments: [this.getFormValue(obj, 'attachments')],
      license_plate_id: [this.getFormValue(obj, 'license_plate_id'), Validators.required],
      trip_description: [obj ? obj.trip_description : (mandantId == 2 ? defaultSinhuber : (mandantId == 7 ? defaultHoedl : "Test")), Validators.required],
      drive_typ: [obj ? obj.customer_id ? 1 : 2 : null, Validators.required],
      vehicle_to_create: [false],
      vehicle_form: [VehicleForm.getVehicleForm(formBuilder)]
    });
    this.updateTypValidity(form);
    return form;
  }

  static updateTypValidity(form: FormGroup) {
    if(form.get('drive_typ').value == 2){
      form.get('employee').setValidators(Validators.required);
      form.get('employee').updateValueAndValidity();
      form.get('customer').removeValidators(Validators.required);
      form.get('customer').updateValueAndValidity();
    } else {
      form.get('customer').setValidators(Validators.required);
      form.get('customer').updateValueAndValidity();
      form.get('employee').removeValidators(Validators.required);
      form.get('employee').updateValueAndValidity();
    }
  }

  static getFormValue(obj: Drive, key: string, defaultValue?: any) {
    if(obj && key){
      if(obj[key]) return obj[key];
    }
    return defaultValue ? defaultValue : null;
  }

  public static getObject(form: FormGroup): Drive {
    let startDate = DateHelper.convertDateStringToTimestamp(form.controls['start_date'].value + ' ' + form.controls['start_time'].value);
    let duration = form.controls['duration'].value * 60 * 1000;
    let endDate = startDate + duration;
    let drive = new Drive(
      0,
      Number(localStorage.getItem("location_id")),
      startDate,
      duration,
      form.controls['vehicle'].value?.id,
      form.controls['customer'].value?.id,
      form.controls['license_plate_id'].value,
      [],
      startDate,
      endDate,
      null,
      form.get('employee').value?.id
    );
    drive.trip_description = form.controls['trip_description'].value;
    drive.vehicle = form.controls['vehicle'].value;
    drive.customer = form.controls['customer'].value;
    drive.employee = form.controls['employee'].value;
    return drive;
  }

}
