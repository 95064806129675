import { DrivesComponent } from './view/drives/drives.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { CustomerComponent } from './view/customer/customer.component';
import { CarsComponent } from './view/cars/cars.component';
import { AdminComponent } from './view/admin/admin.component';
import { MessagesComponent } from './view/messages/messages.component';
import { InterfacesComponent } from './view/interfaces/interfaces.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { AuthenticationService } from './service/authentication/authentication.service';
import { DokumenteComponent } from './view/dokumente/dokumente.component';
import { environment } from 'src/environments/environment';
import { AccountingComponent } from './view/accounting/accounting.component';
import { TestDrivesComponent } from './view/test-drives/test-drives.component';
import { TyresComponent } from './view/tyres/tyres.component';
import { SettingsComponent } from './view/settings/settings.component';
import { UsersComponent } from './view/users/users.component';
import { LocationsComponent } from './view/locations/locations.component';
import { KundenComponent } from './view/kunden/kunden.component';
import { MieteComponent } from './view/miete/miete.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cars',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   component: LoginComponent,
  //   data: { isLogin: true }
  // },
  {
    path: 'login',
    component: LoginComponent,
    data: { isLogin: true }
  },
  {
    path: 'user/forgetpassword',
    component: ResetPasswordComponent,
    data: { isLogin: true }
  },
  {
    path: 'customers',
    component: KundenComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'customers/:customerId',
    component: KundenComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'customers/create/:typ',
    component: KundenComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'customers/edit/:customerId',
    component: KundenComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'cars',
    component: CarsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'cars/:carId/:subMenuId',
    component: CarsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'cars/create',
    component: CarsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'cars/:carId/:subMenuId',
    component: CarsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'cars/:vehicleId/:threadId',
    component: CarsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthenticationService],
  },
  // {
  //   path: 'messages',
  //   component: MessagesComponent,
  //   canActivate: [AuthenticationService]
  // },
  // {
  //   path: 'messages/create',
  //   component: MessagesComponent,
  //   canActivate: [AuthenticationService]
  // },
  // {
  //   path: 'messages/:threadId',
  //   component: MessagesComponent,
  //   canActivate: [AuthenticationService],
  // },
  // {
  //   path: 'messages/:threadId/:vehicleId',
  //   component: MessagesComponent,
  //   canActivate: [AuthenticationService],
  // },
  // {
  //   path: 'messages/:threadId/:customerId',
  //   component: MessagesComponent,
  //   canActivate: [LoginService],
  // },
  // {
  //   path: 'interfaces',
  //   component: InterfacesComponent,
  //   canActivate: [AuthenticationService]
  // },
  {
    path: 'documents',
    component: DokumenteComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'documents/create/:typ/:customerId',
    component: DokumenteComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'documents/create/:typ/:vehicleId',
    component: DokumenteComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'documents/edit/:documentId',
    component: DokumenteComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'documents/:documentId',
    component: DokumenteComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'drives',
    component: (environment.api.includes('sinhuber') ||  environment.api.includes('8797')) ? DrivesComponent : TestDrivesComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'drives/create',
    component: (environment.api.includes('sinhuber') ||  environment.api.includes('8797')) ? DrivesComponent : TestDrivesComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'drives/create/:vehicleId/:typ',
    component: (environment.api.includes('sinhuber') ||  environment.api.includes('8797')) ? DrivesComponent : TestDrivesComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'material',
    component: DashboardComponent,
    canActivate: [AuthenticationService]
  },
  {
    path: 'accounting',
    component: AccountingComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'tyres',
    component: TyresComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'rent',
    component: MieteComponent,
    canActivate: [AuthenticationService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
