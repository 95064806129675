import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { Bill, getBillStatusFromLabel, getBillStatusLabelArray } from 'src/app/buisness-object/document/Bill';
import { BillForm } from 'src/app/buisness-object/document/form/BillForm';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { countries } from '../../BaseComponent';
import { ErrorHelper } from 'src/app/error/ErrorHelper';

@Component({
  selector: 'app-bill-create',
  templateUrl: './bill-create.component.html',
  styleUrl: './bill-create.component.css'
})
export class BillCreateComponent implements OnInit {
  @Input() obj;
  @Output() cancelCreateEmitter = new EventEmitter<undefined>();
  public showCreateChoise = true;
  public customerChanged = true;
  public form: FormGroup;
  public customerValues = [];
  public customers: Customer[] = [];
  public salutationValues = [];
  public salutationValue;
  public titleValues = [];
  public titleValue;
  public paymentMethods = getBillStatusLabelArray();
  public selectedPaymentMethod;
  public countries = countries;
  public formValuesChanged = false;
  public dialogQuerySubsription: Subscription;
  public submitted = false;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private customerService: CustomerService,
    private enumService: EnumService,
    private authService: AuthenticationService
  ){}

  ngOnInit(): void {
    this.setUp();
  }

  setUp() {
    forkJoin({
      customers: this.customerService.getCustomer(),
    }).subscribe((result) => {
      if(result){
        this.customers = result.customers;
        this.initView();
      }
    });
  }

  initView() {
    this.form = BillForm.getForm(this.formBuilder, this.obj);
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
  }

  selectionCancel() {
    this.cancelCreateEmitter.emit();
  }

  goToCustomer() {
    this.router.navigate(['customers'])
  }

  getDropdownValuesCustomer(customers: Customer[]): any[] {
    let values = [];
    for(let value of customers){
      values.push({
        id: value.id,
        name: value.contactPerson.firstName + ' ' + value.contactPerson.lastName + (value.companyName ? ' - ' + value.companyName : '')
      })
    }
    return values;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    }
  }

  get formCustomer() {
    return this.form?.controls['customer_form'].value;
  }

  submit() {
    this.form.get('customer_form').setValue(CustomerForm.getCustomerForm(this.formBuilder, this.form.get('customer').value));
    this.formCustomer.get('dataprivacyAccepted').setValue(true);
    this.salutationValue = this.enumService.getEnumValue('salutation', this.formCustomer.controls['salutation'].value);
    this.salutationValues = this.enumService.getEnumValues('salutation');
    this.titleValues = this.enumService.getEnumValues('title');
    this.form.get('customer_form').value.valueChanges.subscribe((value) => {
      this.customerChanged = true;
    })
    this.showCreateChoise = false;
    this.customerChanged = false;
  }

  setPaymentMethod(value: string) {
    this.selectedPaymentMethod = value;
    this.form.get('payment_method').setValue(getBillStatusFromLabel(value));
  }

  selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
    }
  }

  addPosition() {
    this.form.get('positions').value.push(BillForm.getPositionForm(this.formBuilder));
  }

  removePosition(index: number) {
    this.form.get('positions').value.splice(index, 1);
  }
}
