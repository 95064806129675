import { Employee } from "../employee/Employee";
import { Role } from "../employee/Role";

export class Authentication {
  static getRolesWaymark(): Role[] {
    return [
      new Role(1,'admin_view'),
      new Role(2,'admin_edit'),
      new Role(3,'customer_view'),
      new Role(4,'customer_edit'),
      new Role(5,'vehicle_view'),
      new Role(6,'vehicle_edit'),
      new Role(7,'ticketing'),
      new Role(8,'document_view'),
      new Role(9,'document_edit'),
    ]
  }
  static getRolesAdmin(): Role[] {
    return [
      new Role(1,'admin_view'),
      new Role(2,'admin_edit'),
      new Role(3,'customer_view'),
      new Role(4,'customer_edit'),
      new Role(5,'vehicle_view'),
      new Role(6,'vehicle_edit'),
      new Role(7,'ticketing'),
      new Role(8,'document_view'),
      new Role(9,'document_edit'),
    ];
  }
  static getRolesAccounting(): Role[] {
    return [
      new Role(3,'customer_view'),
      new Role(4,'customer_edit'),
      new Role(5,'vehicle_view'),
      new Role(6,'vehicle_edit'),
      new Role(7,'ticketing'),
    ]
  }
  static getRolesEmployeeWerkstatt(): Role[] {
    return [
      new Role(3,'customer_view'),
      new Role(5,'vehicle_view'),
      new Role(6,'vehicle_edit'),
      new Role(7,'ticketing'),
      new Role(12,'werkstatt_view')
    ]
  }
  static getRolesOnlyView(): Role[] {
    return [
      new Role(3,'customer_view'),
      new Role(5,'vehicle_view'),
      new Role(7,'ticketing'),
      new Role(13,'ersatzteile_view')
    ]
  }

  static getRoleFahrzeuganlieferung(): Role[] {
    return [
      new Role(3,'customer_view'),
      new Role(5,'vehicle_view'),
      new Role(7,'ticketing'),
      new Role(14,'fahrzeuganlieferung_view')
    ]
  }

  static getRoleName(roles: Role[]): string {
    let auth =  this.getAuthentication(roles);
    switch (auth) {
      case 1: return 'Administration'; break;
      case 2: return 'Administration'; break;
      case 3: return 'Buchhaltung'; break;
      case 4: return 'Verkauf'; break;
      case 5: return 'Werkstatt'; break;
      case 6: return 'Fahrzeuganlieferung'; break;
      default: return '---'; break;
    }
  }

  static getAuthentication(roles: Role[]) {
    if(this.isWaymark(roles)){
      return 1;
    }
    if(this.isAdmin(roles)){
      return 2;
    }
    if(this.isAccounting(roles)){
      return 3;
    }
    if(this.isEmployeeWerkstatt(roles)){
      return 5;
    }
    if(this.isEmployeeOnlyView(roles)){
      return 4;
    }
    if(this.isFahrzeuganlieferung(roles)){
      return 6;
    }
    return 0;
  }

  static isWaymark(roles: Role[]): boolean {
    for(let r of this.getRolesWaymark()){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  static isAdmin(roles: Role[]): boolean {
    for(let r of this.getRolesAdmin()){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  static isAccounting(roles: Role[]): boolean {
      for(let r of this.getRolesAccounting()){
          let isIncluded = false;
          for(let role of roles){
              if(role.roleId == r.roleId){
                  isIncluded = true;
                  break;
              }
          }
          if(!isIncluded){
              return false;
          }
      }
      return true;
  }

  static isEmployeeOnlyView(roles: Role[]): boolean {
    for(let r of this.getRolesOnlyView()){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  static isFahrzeuganlieferung(roles: Role[]): boolean {
    for(let r of this.getRoleFahrzeuganlieferung()){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }

  static isEmployeeWerkstatt(roles: Role[]): boolean {
    for(let r of this.getRolesEmployeeWerkstatt()){
        let isIncluded = false;
        for(let role of roles){
            if(role.roleId == r.roleId){
                isIncluded = true;
                break;
            }
        }
        if(!isIncluded){
            return false;
        }
    }
    return true;
  }
}
