<div class="topbar">
    <div class="topbar_inner" *ngIf="surface == 1">
        <button class="button_type_1" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
          Neu
          <div *ngIf="showDropdown">
            <button (click)="openCreateEmitter.emit(true); showDropdown = false">Firma</button>
            <button (click)="openCreateEmitter.emit(false); showDropdown = false">Privat</button>
          </div>
        </button>
        <div class="topbar_tab_wrapper">
          <button [class.button_tap_active]="filter.status == 0" (click)="changeTab(0)">Alle<span *ngIf="filter.status == 0"></span></button>
          <button [class.button_tap_active]="filter.status == 1" (click)="changeTab(1)">Kunden<span *ngIf="filter.status == 1"></span></button>
          <button [class.button_tap_active]="filter.status == 2" (click)="changeTab(2)">Interessenten<span *ngIf="filter.status == 2"></span></button>
          <button [class.button_tap_active]="filter.status == 3" (click)="changeTab(3)">Archiv<span *ngIf="filter.status == 3"></span></button>
        </div>
        <div class="sort_filter_search_wrapper">
          <div class="dropdown_wrapper_header_filter">
            <div id="sort_btn" class="filter_btn_wrapper" (click)="showSort = !showSort; showFilter = false;">
              <svg xmlns="http://www.w3.org/2000/svg" width="18.557" height="17.471" viewBox="0 0 18.557 17.471">
                <g transform="translate(-38.59 -13.793)">
                  <g id="Gruppe_146" data-name="Gruppe 146" transform="translate(40.09 19.674)">
                    <g id="Gruppe_144" data-name="Gruppe 144">
                      <path class="filter_icon_path" data-name="Pfad 796" d="M3.882,8.691a1.5,1.5,0,0,1-1.019-.4l-5.07-4.7,5.07-4.7a1.5,1.5,0,0,1,2.12.081A1.5,1.5,0,0,1,4.9,1.1L2.207,3.6,4.9,6.091a1.5,1.5,0,0,1-1.02,2.6Z" transform="translate(0 9.382) rotate(-90)" fill="#2c2c2c"/>
                      <path class="filter_icon_path" data-name="Pfad 797" d="M7226.248,9167.239a1.5,1.5,0,0,1-1.5-1.5v-8.911a1.5,1.5,0,0,1,3,0v8.911A1.5,1.5,0,0,1,7226.248,9167.239Z" transform="translate(-7222.652 -9156.828)" fill="#2c2c2c"/>
                    </g>
                  </g>
                  <g id="Gruppe_147" data-name="Gruppe 147" transform="translate(55.647 25.382) rotate(180)">
                    <g id="Gruppe_144-2" data-name="Gruppe 144" transform="translate(0 0)">
                      <path class="filter_icon_path" data-name="Pfad 796" d="M3.882,8.691a1.5,1.5,0,0,1-1.019-.4l-5.07-4.7,5.07-4.7a1.5,1.5,0,0,1,2.12.081A1.5,1.5,0,0,1,4.9,1.1L2.207,3.6,4.9,6.091a1.5,1.5,0,0,1-1.02,2.6Z" transform="translate(0 9.382) rotate(-90)" fill="#2c2c2c"/>
                      <path class="filter_icon_path" data-name="Pfad 797" d="M0,10.412a1.5,1.5,0,0,1-1.5-1.5V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V8.912A1.5,1.5,0,0,1,0,10.412Z" transform="translate(3.596)" fill="#2c2c2c"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <app-sort-customer appDropdown
              [show]="showSort"
              [excludingElementIds]="['sort_btn']"
              (changedValueEmitter)="showSort = $event"
              [showSort]="showSort"
              [sortObject]="sortObject"
              (applySortEmitter)="applySortEmitter.emit(); showSort = false"
              (resetSortEmitter)="resetSortEmitter.emit(); showSort = false"
            ></app-sort-customer>
          </div>
          <div class="dropdown_wrapper_header_filter">
            <div id="filter_btn" class="filter_btn_wrapper" (click)="showFilter = !showFilter; showSort = false;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rechteck_928" data-name="Rechteck 928" width="19" height="19" fill="#2c2c2c"/>
                  </clipPath>
                </defs>
                <g id="Gruppe_1668" data-name="Gruppe 1668" clip-path="url(#clip-path)">
                  <path class="filter_icon_path" data-name="Pfad 1070" d="M12.852,16.065a.8.8,0,0,1-.482-.161l-3.213-2.41a.8.8,0,0,1-.321-.643V9.972L4.113,5.25A3.076,3.076,0,0,1,6.288,0H16.2a3.076,3.076,0,0,1,2.174,5.25L13.655,9.972v5.29a.8.8,0,0,1-.8.8ZM8.193,14.78a2.418,2.418,0,0,1-.964-1.928V10.637L2.978,6.386A4.634,4.634,0,0,1,1.656,3.577,3.06,3.06,0,0,0,.9,8.463l4.722,4.722v2.88a.8.8,0,0,0,.321.643l3.213,2.41a.8.8,0,0,0,1.285-.643V16.467Z" fill="#2c2c2c"/>
                </g>
              </svg>
            </div>
            <app-customer-filter appDropdown
              [show]="showFilter"
              [excludingElementIds]="['filter_btn']"
              (changedValueEmitter)="showFilter = $event"
              [showFilter]="showFilter"
              [filter]="filter"
              (filterDetailEmitter)="applyFilterEmitter.emit(); showFilter = false"
              (resetFilterEmitter)="resetFilterEmitter.emit(); showFilter = false"
            ></app-customer-filter>
          </div>
          <div class="input_dropdown_container">
            <input #search class="input_search" type="text" placeholder="Suche" (input)="searchAction(search.value)">
          </div>
        </div>
    </div>
    <div class="topbar_inner" *ngIf="surface == 2">
        <div class="btn_cancel_container" (click)="cancelEditEmitter.emit()">
            <img class="icon_cancel" src="../../../../assets/cancle.svg">
            <button id="btn-cancel">Abbrechen</button>
        </div>
        <!-- <div id="lbl-title">{{selectedCustomer ? 'Kunde Bearbeiten' : 'Kunde anlegen'}}</div> -->
        <button class="btn_submit" (click)="saveCustomerEmitter.emit()">{{selectedCustomer ? "Speichern":"Erstellen"}}</button>
    </div>
    <div class="topbar_inner" *ngIf="surface == 3">
        <div class="btn_cancel_container" (click)="changeSurfaceEmitter.emit(1)">
            <img class="icon_cancel" src="../../../../assets/back.svg">
            <button class="btn_cancel">Zurück</button>
        </div>
        <div class="title_inner_flex">
          <button *ngIf="accessEdit" class="btn_white" (click)="editCustomerEmitter.emit()">Bearbeiten</button>
          <div class="dropdown_container">
            <div class="dropdown_header" (click)="showDropdownOne = !showDropdownOne" appDropdown [show]="showDropdownOne" (changedValueEmitter)="showDropdownOne = $event">
                <div class="dropdown_header_lbl">{{showSurfaceOverview == 1 ? 'Details' : 'Tickets'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownOne ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-light.svg">
            </div>
            <div class="dropdown_element_container" *ngIf="showDropdownOne">
                <div class="dropdown_element" (click)="selectionChangeSurfaceOverviewEmitter.emit(1); showDropdownOne = false">Details</div>
                <div class="dropdown_element" *ngIf="authService.isSinhuber()" (click)="selectionChangeSurfaceOverviewEmitter.emit(2); showDropdownOne = false">Tickets</div>
            </div>
          </div>
          <div *ngIf="accessEdit" class="dropdown_container">
            <div class="dropdown_header" (click)="showDropdownTwo = !showDropdownTwo" appDropdown [show]="showDropdownTwo" (changedValueEmitter)="showDropdownTwo = $event">
                <div class="dropdown_header_lbl">Erstellen</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownTwo ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-light.svg">
            </div>
            <div class="dropdown_element_container" *ngIf="showDropdownTwo">
                <div class="dropdown_element" *ngIf="authService.isSinhuber()" (click)="openCreateCustomerCar.emit(); showDropdownTwo = false">Fahrzeug</div>
                <div class="dropdown_element" *ngIf="authService.isSinhuber()" (click)="goToCreateDocument(2); showDropdownTwo = false">Angebot</div>
                <div class="dropdown_element" (click)="goToCreateDocument(4); showDropdownTwo = false">Vertrag</div>
                <div class="dropdown_element" *ngIf="authService.isSinhuber()" (click)="createDataprivacyEmitter.emit(); showDropdownTwo = false">Datenschutz</div>
            </div>
          </div>
        </div>
    </div>
    <div class="topbar_inner" *ngIf="surface == 4">
      <div class="btn_cancel_container" (click)="cancelCreateCustomerCar.emit()">
          <img class="icon_cancel" src="../../../../assets/cancle.svg">
          <button id="btn-cancel">Abbrechen</button>
      </div>
      <button class="btn_submit" (click)="createCustomerCarEmitter.emit()">Erstellen</button>
    </div>
    <div class="topbar_inner" *ngIf="surface == 5">
      <div class="btn_cancel_container" (click)="selectionCancelPdfShowEmitter.emit()">
          <img class="icon_cancel" src="../../../../assets/back.svg">
          <button id="btn-cancel">Zurück</button>
      </div>
      <button class="btn_submit" (click)="signPDFEmitter.emit()">App Signature</button>
    </div>
</div>
