import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RentForm } from 'src/app/buisness-object/vehicle/form/RentForm';
import { Rent } from 'src/app/buisness-object/vehicle/Rent';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-rent',
  templateUrl: './rent.component.html',
  styleUrl: './rent.component.css'
})
export class RentComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() vehicle: Vehicle;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;
  public rentValue;
  public rentValues = [];
  public showSubscriptions = false;
  public rents: Rent[] = [];

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder
  ){
    super(authService, eService);
  }


  ngOnInit(): void {
    this.rentValues = this.getEnumValues('rent_typ');
    this.rentValue = this.getEnumValue('rent_typ', this.vehicle.rent_typ);
    if(this.rentValue == "Miet-Abo"){
      this.rents = this.vehicle.rents;
      this.showSubscriptions = true;
    } else {
      this.rents.push(new Rent(0,this.vehicle.id, null, 6, 1000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 6, 2000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 6, 3000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 6, 4000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 12, 1000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 12, 2000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 12, 3000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 12, 4000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 18, 1000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 18, 2000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 18, 3000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 18, 4000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 24, 1000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 24, 2000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 24, 3000))
      this.rents.push(new Rent(0,this.vehicle.id, null, 24, 4000))
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.editMode){
      if(this.editMode == true){
        if(this.rentValue == "Miet-Abo"){
          this.rents = this.vehicle.rents;
        }
        for(let rent of this.rents){
          this.form.get('rent_forms').value.push(RentForm.getFormGroup(this.formBuilder, rent));
        }
      }
      this.rentValue = this.getEnumValue('rent_typ', this.vehicle.rent_typ);
    }
    if(changes.vehicle){
      this.rentValue = this.getEnumValue('rent_typ', this.vehicle.rent_typ);
    }
  }

  getEnumDataOnChange() {
    this.rentValue = this.getEnumValue('rent_typ', this.form?.controls['rent_typ'].value);
    if(this.rentValue == "Miet-Abo"){
      this.showSubscriptions = true;
    } else this.showSubscriptions = false;
  }
}
