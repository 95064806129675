import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { LoginService } from 'src/app/service/login/login.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { LoadingService } from 'src/app/service/loading/loading.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { catchError, tap } from 'rxjs/operators';
import { MainMenuFactory } from 'src/app/buisness-object/menu/MainMenuFactory';
import { SubMenuFilter, SubMenuOperator } from 'src/app/buisness-object/menu/SubMenuFilter';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { MasterMenu } from 'src/app/buisness-object/menu/EnumMasterMenu';
import { Rent } from 'src/app/buisness-object/rent/Rent';
import { RentService } from 'src/app/service/rent/rent.service';
import { LocationService } from 'src/app/service/location/location.service';
import { Location } from 'src/app/buisness-object/location/Location';

export enum RentView {
  LIST = 1,
  DETAILS = 2,
  CREATE = 3,
}

@Component({
  selector: 'app-miete',
  templateUrl: './miete.component.html',
  styleUrl: './miete.component.css'
})
export class MieteComponent extends BaseComponent implements OnInit, OnDestroy {

    //dynamic menu
    public mainMenus: MainMenu[] = [];
    public _activeSubMenu: BehaviorSubject<SubMenu> = new BehaviorSubject<SubMenu>(null);
    public activeSubMenu$ = this._activeSubMenu.asObservable();
    public activeSubMenu: SubMenu;
    public _activeSortingColumn: BehaviorSubject<SubMenuColumn> = new BehaviorSubject<SubMenuColumn>(null);
    public activeSortingColumn$ = this._activeSortingColumn.asObservable();
    public activeSortingColumn: SubMenuColumn;
    //dynamic
    public objects: any[] = [];
    public _objectsFiltered: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public _objectsFiltered$ = this._objectsFiltered.asObservable();
    public activeFilter: any[] = [];
    public searchValue;
    public searchTimeout: any;
    public pageHandler: PageHandler;
    public dynamicListLastPosition;
  
    public view = RentView;
    public showView: RentView = RentView.LIST;
    public showDropdownMainButton = false;
    public selectedObject: Rent;
    public dialogQuerySubsription: Subscription;
    public customers: Customer[] = [];
    public vehicles: Vehicle[] = [];
    public locations: Location[] = [];
    public showDialogReturn = false;
    public showDialogDocument = false;
    public showDialogCancel = false;
  
  
    constructor(
      private authService: AuthenticationService,
      private eService: EnumService,
      private loginService: LoginService,
      private dialogService: DialogService,
      private vehicleService: VehicleService,
      private loadingService: LoadingService,
      private customerService: CustomerService,
      private employeeService: EmployeeService,
      private rentService: RentService,
      private locationService: LocationService
    ){
      super(authService, eService);
    }
  
    ngOnInit(): void {
      this.setUp();
      this.setDialogSubscription()
    }
  
    ngOnDestroy(): void {
      if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    }
  
    async setUp() {
      await this.requestDataServerside().toPromise();
      this.setUpMenu();
      this.activeSubMenu$.subscribe((active) => {
        if(active){
          this.activeSubMenu = active;
          this.activeFilter = [];
          this.activeSortingColumn = null;
          this.rootFilter();
        }
      });
      this.setUpSearch();
    }

    setDialogSubscription() {
      this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
        if(value){
          if(value.typ == 'delete_rent' && value.submit_value != null) this.actionDelete(value.submit_value);
        }
      });
    }

    actionDelete(obj: Rent) {
      this.rentService.deleteRent(obj.rent_id).subscribe((result) => {
        if(result){
          this.updateDataLocal(obj, 'delete');
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Mieteintrag wurde gelöscht.',
            success: true
          });
        }
      })
    }
  
    requestDataServerside(): Observable<any> {
      this.loadingService.startLoading();
      return forkJoin({
        rents: this.rentService.getRents(),
        customers: this.customerService.getCustomer(),
        vehicles: this.vehicleService.getVehicle(),
        locations: this.locationService.getLocations()
      }).pipe(
        tap((result) => {
          if(result) {
            this.objects = result.rents;
            this.customers = result.customers;
            this.vehicles = result.vehicles;
            this.locations = result.locations;
            this.loadingService.stopLoading();
          
            this.objects = this.objects.map(obj => {
              const mainDriver = this.customers.find(c => c.id === obj.main_driver_id);
              if (mainDriver) {
                obj.main_driver = mainDriver;
              }
              const secondDriver = this.customers.find(c => c.id === obj.second_driver_id);
              if (secondDriver) {
                obj.second_driver = secondDriver;
              }
              const vehicle = this.vehicles.find(v => v.id === obj.vehicle_id);
              if (vehicle) {
                obj.vehicle = vehicle;
              }
              return obj;
            });
          }
        }
      ),
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
    }
  
  
    //Standard dynamic menu
  
    setUpMenu() {
      let raw: any = JSON.parse(localStorage.getItem('menu_rent'));
      if(raw == undefined || raw == null ){
        this.loginService.logout();
        return;
      }
      this.mainMenus = MainMenuFactory.jsonFactoryMainMenus(raw, MasterMenu.RENT);
      for(let i = 0; i < this.mainMenus.length; i++){
        if(i == 0) this.mainMenus[i].setActive(true);
        this.mainMenus[i].sub_menus[0]?.setActive(true);
      }
      for(let mainMenu of this.mainMenus){
        mainMenu.active$.subscribe((active) => {
          if(active){
            for(let otherMainMenu of this.mainMenus){
              if(otherMainMenu.main_menu_id != mainMenu.main_menu_id){
                otherMainMenu.setActive(false);
              }
            }
            if(mainMenu.sub_menus.length > 0){
              for(let otherSubMenu of mainMenu.sub_menus){
                if(otherSubMenu.getActive()){
                  this._activeSubMenu.next(otherSubMenu);
                }
              }
            }
          }
        });
        for(let subMenu of mainMenu.sub_menus){
          subMenu.active$.subscribe((active) => {
            if(active){
              for(let otherSubMenu of mainMenu.sub_menus){
                if(otherSubMenu != subMenu){
                  otherSubMenu.setActive(false);
                }
              }
              this._activeSubMenu.next(subMenu);
            }
          });
        }
      }
      this._activeSubMenu.next(this.mainMenus[0]?.sub_menus[0]);
    }
    rootFilter() {
      let activeSubMenu = this.mainMenus.filter((m) => m.getActive())[0].sub_menus.filter((s) => s.getActive())[0];
      if(activeSubMenu?.filter && activeSubMenu.filter.length > 0) {
        let sumFilter: any[] = activeSubMenu.filter;
        if(this.activeFilter.length > 0) {
          sumFilter = sumFilter.concat([new SubMenuOperator("&&")]).concat(this.activeFilter);
        }
        this._objectsFiltered.next(this.objects.filter((obj) => this.solveFilter(sumFilter, obj, activeSubMenu.columns[0]?.data_object)));
      } else if(this.activeFilter.length > 0) {
        this._objectsFiltered.next(this.objects.filter((obj) => this.solveFilter(this.activeFilter, obj, activeSubMenu.columns[0]?.data_object)));
      } else {
        this._objectsFiltered.next(this.objects.filter((obj) => this.solveObject(obj, activeSubMenu.columns[0]?.data_object)));
      }
      this.applySearch(this._objectsFiltered.getValue());
      this.rootSorting();
      this.setPages();
    }
    solveObject(object: any, data_object: string): boolean {
      switch (data_object) {
        case 'vehicle': return object instanceof Vehicle; break;
        case 'ledger_entry': return object instanceof LedgerEntry; break;
        case 'purchase_contract': return object instanceof Kaufvertrag; break;
        case 'bill': return object instanceof Bill; break;
        case 'drive': return object instanceof Drive; break;
        case 'tire': return object instanceof Tire; break;
        case 'customer': return object instanceof Customer; break;
        case 'rent': return object instanceof Rent; break;
        default: return false; break;
      }
    }
    solveFilter(originalFilters: any[], object: any, data_object: string): boolean {
      let filters = JSON.parse(JSON.stringify(originalFilters));
      if(!this.solveObject(object, data_object)) return false;
      for(let i = 0; i < filters.length; i++) {
        if(filters[i].data_key) {
          filters[i] = this.objectIsValidForFilter(filters[i], object);
        } else if(Array.isArray(filters[i])) {
          filters[i] = this.solveFilter(filters[i], object, data_object);
        }
      }
      for(let i = 0; i < filters.length; i++) {
        if(filters[i].operator && filters[i].operator == '&&') {
          filters[i + 1] = filters[i -1] && filters[i + 1];
          filters.splice(i - 1, 2);
          i = i - 1;
        }
      }
      for(let i = 0; i < filters.length; i++) {
        if(filters[i].operator && filters[i].operator == '||') {
          filters[i + 1] = filters[i -1] || filters[i + 1];
          filters.splice(i - 1, 2);
          i = i - 1;
        }
      }
      return filters[0];
    }
    objectIsValidForFilter(filter: SubMenuFilter, object: any): boolean {
      let value: any = this.getObjectValueFromDataKey(filter.data_key, object);
      if(filter.data_typ == "NUMBER" || filter.data_typ == "DATE") {
        return this.compareNumber(value, filter);
      } else if(filter.data_typ == "BOOLEAN" || filter.data_typ == "BOOLEAN_NULL") {
        return this.compareBoolean(value, filter);
      } else if(filter.data_typ == "ENUM" || filter.data_typ == "ENUM_TAG") {
        return this.compareEnum(value, filter);
      } else {
        return this.compareString(value, filter);
      }
    }
    compareNumber(value: any, filter: SubMenuFilter): boolean {
      if(filter.raw_value == "NULL") {
        if(filter.operator == "<>") {
          return value != undefined && value != null;
        } else if(filter.operator == "=") {
          return value == undefined || value == null;
        } else {
          return false;
        }
      }
      let numberValue = Number(value);
      let rawValue;
      //Special date condition
      if(filter.raw_value && String(filter.raw_value).includes("NOW-")){
        let miliseconds = Number(filter.raw_value.split("-")[1]);
        if(!isNaN(miliseconds)){
          rawValue = new Date().getTime()-miliseconds;
        }
      }
      if(rawValue == undefined || rawValue == null) rawValue = Number(filter.raw_value);
      if(Number.isNaN(numberValue) && filter.operator != "<>") {
        return false;
      }
      if(filter.operator == "<>") {
        return numberValue != rawValue;
      } else if(filter.operator == "=") {
        return numberValue == rawValue;
      } else if (filter.operator == ">") {
        return numberValue > rawValue;
      } else if (filter.operator == ">=") {
        return numberValue >= rawValue;
      } else if (filter.operator == "<") {
        return numberValue < rawValue;
      } else if(filter.operator == "<=") {
        return numberValue <= rawValue;
      } else {
        return false;
      }
    }
    compareBoolean(value: any, filter: SubMenuFilter): boolean {
      if(filter.raw_value == "NULL") {
        if(filter.operator == "<>") {
          return value != undefined && value != null;
        } else if(filter.operator == "=") {
          return value == undefined || value == null;
        } else {
          return false;
        }
      }
      let numberValue = value == null ? null: (value == "true" || value == "1" || value == true || value == 1);
      let rawValue = filter.raw_value == null ? null: (filter.raw_value == "true" || filter.raw_value == "1");
      if(filter.operator == "<>") {
        return numberValue != rawValue;
      } else if(filter.operator == "=") {
        return numberValue == rawValue;
      } else {
        return false;
      }
    }
    compareEnum(value: any, filter: SubMenuFilter): boolean {
      if(filter.raw_value == "NULL") {
        if(filter.operator == "<>") {
          return value != undefined && value != null;
        } else if(filter.operator == "=") {
          return value == undefined || value == null;
        } else {
          return false;
        }
      }
      let id: any = null;
      if(typeof value === 'object') {
        id = value == null ? null: value.id;
      } else {
        id = value;
      }
      let rawValue = filter.raw_value;
      if(filter.operator == "<>") {
        return id != rawValue;
      } else if(filter.operator == "=") {
        return id == rawValue;
      } else {
        return false;
      }
    }
    compareString(value: any, filter: SubMenuFilter): boolean {
      if(filter.operator == '<>') {
        if(filter.raw_value == "NULL") {
          return value != undefined && value != null;
        } else {
          return String(value) != filter.raw_value;
        }
      } else if(filter.operator == '=') {
        if(filter.raw_value == "NULL") {
          return value == undefined || value == null;
        } else {
          return String(value).toLowerCase().includes(filter.raw_value.toLowerCase());
        }
      } else {
        console.log("Warning: Wrong operator in filter");
        return false;
      }
    }
    getObjectValueFromDataKey(data_key: string, obj: any): any {
      let keys = data_key.split('.');
      let value = obj;
      for(let i = 0; i < keys.length; i++){
        value = value[keys[i]];
        if(value == undefined || value == null){
          break;
        }
      }
      return value;
    }
    setUpSearch() {
      setTimeout(() => {
        const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
        if(searchInput != null){
          if(this.searchValue != null){
            searchInput.value = this.searchValue;
            this.rootFilter();
          }
          searchInput.addEventListener('input', (event: InputEvent) => {
            if(this.searchTimeout) clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              this.searchValue = searchInput.value;
              this.rootFilter();
            }, 500);
          });
        }
        const searchInputReset = document.getElementById('ab-search-list-input-reset') as HTMLInputElement;
        if(searchInputReset){
          searchInputReset.addEventListener('click', (event: InputEvent) => {
            if(this.searchValue != null){
              const searchInput = document.getElementById('ab-search-list-input') as HTMLInputElement;
              if(searchInput){
                searchInput.value = null;
                this.searchValue = null;
                this.rootFilter();
              }
            }
          });
        }
      }, 200);
    }
    applySearch(objects: any[]) {
      if(this.searchValue && this.searchValue.length > 0){
        let temp = [];
        temp = objects.filter((obj) => {
          let dimension: string|undefined = undefined;
          let dimension2: string|undefined = undefined;
          if(obj.tire_width_1 && obj.tire_height_1 && obj.tire_inches_1){
            dimension = obj.tire_width_1 + ' ' + obj.tire_height_1 + ' ' + obj.tire_inches_1;
          }
          if(obj.tire_width_2 && obj.tire_height_2 && obj.tire_inches_2){
            dimension = obj.tire_width_2 + ' ' + obj.tire_height_2 + ' ' + obj.tire_inches_2;
          }
          return obj.tire_id.toString().toLowerCase().includes(this.searchValue.toLowerCase()) ||
                obj.designation_tire?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                obj.designation_rim?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                dimension?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                dimension2?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                obj.storage_place?.place_name?.toLowerCase().includes(this.searchValue.toLowerCase());
        });
        this._objectsFiltered.next(temp);
      }
    }
    rootSorting() {
      let activeSub = this._activeSubMenu.getValue();
      if(this.activeSortingColumn == null && activeSub != null){
        for(let c of this._activeSubMenu.getValue().columns){
          if(c.sub_menu_column_id == activeSub.default_sort_column_id){
            this.activeSortingColumn = c;
            this.activeSortingColumn.sortingActive = true;
            this.activeSortingColumn.ascend = activeSub.default_sort_direction == 'ASC' ? true : false;
            break;
          }
        }
        if(this.activeSortingColumn == null){
          let index = this.activeSubMenu.columns.findIndex((c) => c.data_key == 'externalId');
          if(index > -1) this.activeSortingColumn = this.activeSubMenu.columns[index];
          else {
            this.activeSortingColumn = new SubMenuColumn(0,"id",0,"document",0,"id",false,false,1,null,null,null,null,"NUMBER",null);
          }
          this.activeSortingColumn.sortingActive = true;
          this.activeSortingColumn.ascend = false;
        }
      }
      if(this.activeSortingColumn != null && this.activeSortingColumn.sortingActive){
        this._objectsFiltered.getValue().sort((a,b) => {
          if (this.activeSortingColumn.sort_function) {
            if(this.activeSortingColumn.ascend) {
              return a[this.activeSortingColumn.sort_function](b) * -1;
            } else {
              return a[this.activeSortingColumn.sort_function](b);
            }
          }
          let value1;
          let value2;
          if(typeof a[this.activeSortingColumn.data_key] == 'function'){
            value1 = a[this.activeSortingColumn.data_key]();
            value2 = b[this.activeSortingColumn.data_key]();
          } else if(this.activeSortingColumn.data_key.includes('.')) {
            let deepObjKey = this.activeSortingColumn.data_key.split('.')[0];
            let deepFunctionORPara = this.activeSortingColumn.data_key.split('.')[1];
            let deepValueA = a[deepObjKey];
            let deepValueB = b[deepObjKey];
            if(deepValueA && typeof deepValueA[deepFunctionORPara] == 'function'){
              value1 = deepValueA[deepFunctionORPara]();
            } else if(deepValueA) {
              value1 = deepValueA[deepFunctionORPara];
            } else {
              value1 = null;
            }
            if(deepValueB && typeof deepValueB[deepFunctionORPara] == 'function'){
              value2 = deepValueB[deepFunctionORPara]();
            } else if(deepValueB) {
              value2 = deepValueB[deepFunctionORPara];
            } else {
              value2 = null;
            }
          } else {
            value1 = this.getValueFromObjKey(this.activeSortingColumn.data_key, a);
            value2 = this.getValueFromObjKey(this.activeSortingColumn.data_key, b);
          }
          if(value1 == null) return this.activeSortingColumn.ascend ? -1 : 1;
          if(value2 == null) return this.activeSortingColumn.ascend ? 1 : -1;
          if(this.activeSortingColumn.data_typ == "STRING"){
            if(value1 != null && value2 != null && isNaN(value1) && isNaN(value2)){
              return this.activeSortingColumn.ascend ? value1?.localeCompare(value2 ? value2 : '') : value2?.localeCompare(value1 ? value1 : '');
            }
          }
          return this.activeSortingColumn.ascend ? (value1 - value2) : (value2 - value1);
        });
      }
    }
    getValueFromObjKey(data_key: string, obj: any): any {
      let keys = data_key.split('.');
      let value = obj;
      for(let i = 0; i < keys.length; i++){
        value = value[keys[i]];
        if(value == undefined || value == null){
          break;
        }
      }
      return value;
    }
    applySorting(column: SubMenuColumn) {
      //for reference
      if(this.activeSortingColumn.sub_menu_column_id != column.sub_menu_column_id){
        this.activeSortingColumn.sortingActive = false;
        this.activeSortingColumn.ascend = false;
      }
      this.activeSortingColumn = column;
      this.activeSortingColumn.setSortActive();
      this.rootSorting();
    }
    setPages() {
      this.pageHandler = new PageHandler(this._objectsFiltered.getValue().length);
    }
    pageHandling(next: boolean) {
      this.pageHandler.action(next);
    }



    //specific part

  selectionDetails(obj: any) {
    this.selectedObject = obj;
    this.showView = RentView.DETAILS;
  }

  selectionCloseDetails() {
    this.showView = RentView.LIST;
    this.selectedObject = null;
  }

  selectionEdit(obj: Rent) {
    this.selectedObject = obj;
    this.showView = RentView.CREATE;
  }

  updateSuccess(obj: Rent) {
    this.updateDataLocal(obj, 'update');
  }

  createSuccess(obj: Rent) {
    this.updateDataLocal(obj, 'create');
  }


  updateDataLocal(obj: any, updateTyp: string) {
    if(updateTyp == 'create'){
      if(obj.vehicle_id) {
        obj.vehicle = this.vehicles.find((v) => v.id == obj.vehicle_id);
      }
      if(obj.main_driver_id) {
        obj.main_driver = this.customers.find((v) => v.id == obj.main_driver_id);
      }
      this.objects.push(obj);
      this.selectedObject = obj;
      this.showView = RentView.DETAILS;
    } else if(updateTyp == 'delete'){
      let index = this.objects.findIndex((o) => o.rent_id == obj.rent_id);
      if(index > -1){
        this.objects.splice(index,1);
      }
      this.selectedObject = null;
    } else if(updateTyp == 'update'){
      let index = this.objects.findIndex((o) => o.rent_id == obj.rent_id);
      if(index > -1){
        this.objects[index] = obj;
      }
      if(obj.vehicle_id) {
        obj.vehicle = this.vehicles.find((v) => v.id == obj.vehicle_id);
      }
      if(obj.main_driver_id) {
        obj.main_driver = this.customers.find((v) => v.id == obj.main_driver_id);
      }
      this.selectedObject = obj;
      this.showView = RentView.DETAILS;
    }
    this.rootFilter();
  }

  selectionCancelCreate() {
    if(this.selectedObject) this.showView = RentView.DETAILS;
    else this.showView = RentView.LIST;
  }

  selectionReturn(obj: Rent) {
    this.showDialogReturn = true;
  }

  selectionCloseReturn() {
    this.showDialogReturn = false;
  }

  selectionDocument() {
    this.showDialogDocument = true;
  }

  selectionCloseDocument() {
    this.showDialogDocument = false;
  }

  selectionCancelRent() {
    this.showDialogCancel = true;
  }

  selectionCloseCancelRent() {
    this.showDialogCancel = false;
  }

  selectionDelete(obj) {
    this.dialogService.openQuery(
      {
        title: 'Mieteintrag entfernen',
        message: 'Sind sie sicher, dass Sie diesen Mieteintrag löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_rent',
        submit_value: obj,
      }
    );
  }
}
