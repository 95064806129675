<div *ngIf="showView == view.LIST">
  <div class="header-base-style">
    <div class="header-inner-wrapper">
      <!-- <button class="button_type_1" *ngIf="authService.ACCESS_ALL() || authService.ACCESS_FOUR()"
      (click)="showView = view.CREATE"
      appDropdown
      [show]="showDropdownMainButton"
      (changedValueEmitter)="showDropdownMainButton = $event">
        Neu
      </button> -->
      <div id="main-menu-wrapper">
        <button *ngFor="let mainMenu of mainMenus"
          [class.main-menu-btn-selected]="mainMenu.getActive()"
          (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
          {{mainMenu.name}}
        </button>
      </div>
    </div>
    <div>
    </div>
  </div>
  <app-list-container *ngIf="_activeSubMenu.getValue()"
      [objectTyp]="'location'"
      [objects]="_objectsFiltered.getValue()"
      [pageHandler]="pageHandler"
      [filter]="filter"
      [mainMenus]="mainMenus"
      [activeSubMenu$]="activeSubMenu$"
      [activeSortingColumn]="activeSortingColumn"
      (pageEmitter)="pageHandling($event)"
      (applySortingEmitter)="applySorting($event)"
  ></app-list-container>
</div>
