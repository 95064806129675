<app-collapsible [isCollapsible] [isContentVisible]="editMode">
  <h3 collapsible-header class="overview_data_box_lbl_title">Fahrzeug-Fotos
    <div class="loading-screen-wrapper" *ngIf="imagesLoading">
      <div class="loader"></div>
    </div>
  </h3>
  <div collapsible-content>
    <div id="title_image_container">
      <img *ngIf="titlePicture" id="title_img" [src]="this.titlePicture.src" (click)="openSlider(titlePicture)">
      <div *ngIf="titlePicture && editMode" style="top: -4%; left: 90.3%;" class="image_open_icon" (click)="openSingleImage(titlePicture)">
        <label class="lbl_hover">Ansehen</label>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.854" height="8.237" viewBox="0 0 13.854 8.237">
          <path id="Pfad_545" d="M107.557,10.452a8.222,8.222,0,0,0-13.466,0,1.075,1.075,0,0,0,0,1.233,8.226,8.226,0,0,0,13.466,0,1.075,1.075,0,0,0,0-1.233M100.824,13.9a2.832,2.832,0,1,1,2.832-2.832,2.832,2.832,0,0,1-2.832,2.832" transform="translate(-93.897 -6.948)" fill="#313131"/>
        </svg>
      </div>
      <div *ngIf="titlePicture && editMode" class="image_remove_icon image_remove_icon_title" (click)="removeImage(titlePicture)">
        <label class="lbl_hover">Löschen</label>
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 11.959 15.159">
          <path id="Linie_2" d="M122.766,591.4l-4.176-.022v-.38a.9.9,0,0,0-1.808,0v.37l-4.167-.022h-.005a.9.9,0,0,0,0,1.808h.042l.465,10.954a1.114,1.114,0,0,0,1.08,1.145h6.991a1.115,1.115,0,0,0,1.08-1.145l.462-10.9h.037a.9.9,0,0,0,0-1.808Zm-6.575,11.161h-.026a.543.543,0,0,1-.542-.516l-.332-6.931a.542.542,0,1,1,1.083-.052l.332,6.931A.542.542,0,0,1,116.191,602.558Zm3.58-.56a.542.542,0,0,1-.541.516H119.2a.542.542,0,0,1-.516-.568l.332-6.931a.542.542,0,1,1,1.083.052Z" transform="translate(-111.706 -590.09)" fill="#2c2c2c"/>
        </svg>
      </div>
      <div *ngIf="!titlePicture" class="title_placeholder">Kein Titelbild ausgewählt.</div>
    </div>
    <div id="overview-images-wrapper">
      <div *ngIf="!editMode" class="drag_item_container">
        <app-images-element *ngFor="let pic of toShowImages | slice:1:gallery.length; ; let i = index"
          [picture]="pic"
          (click)="openSlider(pic)"
        ></app-images-element>
      </div>
      <div *ngIf="editMode" id="drop_shit" class="drag_item_container">
        <div *ngFor="let pic of gallery; let i = index" class="drag_item" [draggable]="editMode" [ngStyle]="{'cursor': editMode ? 'grab' : 'default'}">
          <img [attr.id]="i" class="img_car" [src]="pic.src" [draggable]="editMode" (click)="openSlider(pic)">
          <div *ngIf="editMode" class="image_open_icon" (click)="openSingleImage(pic)">
            <label class="lbl_hover">Ansehen</label>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.854" height="8.237" viewBox="0 0 13.854 8.237">
              <path id="Pfad_545" d="M107.557,10.452a8.222,8.222,0,0,0-13.466,0,1.075,1.075,0,0,0,0,1.233,8.226,8.226,0,0,0,13.466,0,1.075,1.075,0,0,0,0-1.233M100.824,13.9a2.832,2.832,0,1,1,2.832-2.832,2.832,2.832,0,0,1-2.832,2.832" transform="translate(-93.897 -6.948)" fill="#313131"/>
            </svg>
          </div>
          <div *ngIf="editMode" class="image_set_title_icon" (click)="setAsTitle(pic)">
            <label class="lbl_hover">Als Titelbild</label>
            <svg id="headerimage" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="8.733" height="8.736" viewBox="0 0 8.733 8.736">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rechteck_1000" data-name="Rechteck 1000" width="8.733" height="8.736"/>
                </clipPath>
              </defs>
              <g id="Gruppe_1875" data-name="Gruppe 1875" clip-path="url(#clip-path)">
                <path id="Pfad_545" data-name="Pfad 1147" d="M4.37,2.827a.947.947,0,0,1,1.339,0l.378.378a.222.222,0,0,0,.309,0L8.561,1.041A1.821,1.821,0,0,0,6.917,0H3.639A1.82,1.82,0,0,0,1.82,1.819V4a1.809,1.809,0,0,0,.331,1.044ZM3.457,1.091a.546.546,0,1,1-.546.546.545.545,0,0,1,.546-.546m1.03,7.574L1.333,7.789A1.821,1.821,0,0,1,.066,5.548l.586-2.1a1.809,1.809,0,0,1,.44-.771V4.006A2.545,2.545,0,0,0,3.639,6.552H6.964L6.728,7.4A1.821,1.821,0,0,1,4.487,8.67ZM8.732,1.9V4A1.82,1.82,0,0,1,6.913,5.821H3.639a1.837,1.837,0,0,1-.96-.273l2.2-2.2a.222.222,0,0,1,.309,0l.378.378a.967.967,0,0,0,1.335,0Z" transform="translate(0)"/>
              </g>
            </svg>
          </div>
          <div *ngIf="editMode" class="image_remove_icon" (click)="removeImage(pic)">
            <label class="lbl_hover">Löschen</label>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 11.959 15.159">
              <path id="Linie_2" d="M122.766,591.4l-4.176-.022v-.38a.9.9,0,0,0-1.808,0v.37l-4.167-.022h-.005a.9.9,0,0,0,0,1.808h.042l.465,10.954a1.114,1.114,0,0,0,1.08,1.145h6.991a1.115,1.115,0,0,0,1.08-1.145l.462-10.9h.037a.9.9,0,0,0,0-1.808Zm-6.575,11.161h-.026a.543.543,0,0,1-.542-.516l-.332-6.931a.542.542,0,1,1,1.083-.052l.332,6.931A.542.542,0,0,1,116.191,602.558Zm3.58-.56a.542.542,0,0,1-.541.516H119.2a.542.542,0,0,1-.516-.568l.332-6.931a.542.542,0,1,1,1.083.052Z" transform="translate(-111.706 -590.09)" fill="#2c2c2c"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="editMode" id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)" (click)="selectFile.click()">
      <div class="upload_box_info">
          <img src="../../../../../assets/upload.svg">
          <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen.</label>
          <input #selectFile type="file" accept="image/*" capture="environment" (change)="uploadFile($event)" multiple="" style="display: none;">
      </div>
    </div>
  </div>
</app-collapsible>
