import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Kaufvertrag, KaufvertragStatus, VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { DocumentService } from 'src/app/service/document/document.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { EnumService } from 'src/app/service/enum/enum.service';

export enum KaufvertragDetailsTab {
  Details = 1,
  Tickets = 2
}

@Component({
  selector: 'app-dokumente-kaufvertrag-details',
  templateUrl: './dokumente-kaufvertrag-details.component.html',
  styleUrl: './dokumente-kaufvertrag-details.component.css'
})
export class DokumenteKaufvertragDetailsComponent extends BaseComponent implements OnInit {
  @Input() document: Kaufvertrag;
  @Input() zusatzprodukte: Zusatzprodukt[];
  public tabView = KaufvertragDetailsTab;
  public showTab: KaufvertragDetailsTab = KaufvertragDetailsTab.Details;
  public registrationDocumentValue;
  public tradeInVehicleRegistrationDocumentValue;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public salutationValue;
  public titleValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public shwoActions = false;
  public shwoActions2 = false;
  public shwoActions3 = false;
  public kaufvertragStatus = KaufvertragStatus;

  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() selectionEditEmitter = new EventEmitter<undefined>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionContractStornoEmitter = new EventEmitter<any>();
  @Output() selectionAnzahlungKassierenEmitter = new EventEmitter<any>();
  @Output() selectionEndzahlungKassierenEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionContractRenewEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private documentService: DocumentService,
    private dialogService: DialogService,
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.document.registration_document);
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.document.payment_condition);
    this.downPaymentConditionValue = this.getEnumValue('payment_condition', this.document.down_payment_condition);
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.mechanical_condition);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.body_condition);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.paint_condition);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.interior_condition);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.document.vehicle_status.electronic_condition);
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.document.finanzierungsart);
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.document.registration_document);
    this.tradeInVehicleRegistrationDocumentValue = this.getEnumValue('registration_document', this.document.trade_in_vehicle?.registration_document);
    this.total_condition = this.document.vehicle_status.getOverallCondition();
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  selectionEdit() {
    this.selectionEditEmitter.emit();
  }

  openPDF() {
    this.documentService.getContractPDF(this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  openRe(id: number) {
    this.documentService.getRePdf(id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true });
      }
    });
  }

  openWerkstattPDF() {
    this.documentService.getPdf('/vehicle/werkstattauftrag/',this.document.vehicle.id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }


  openGarantiePDF() {
    this.documentService.getPdf('/contract/garantie/',this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  openUebergabeFormularPDF() {
    this.documentService.getPdf('/contract/uebergabe/',this.document.purchase_contract_id).subscribe((result) => {
      if(result){
        this.dialogService.openAttachementViewer({ pdf: result, isArrayBuffer: true })
      }
    })
  }

  signPDF(contract: any){
    this.documentService.createSignatureDocument(contract).subscribe((result) => {
      if(result){
        this.dialogService.openLoaderSignature({document_typ: 3});
      }
    });
  }

  hasGarantie() {
    return this.document.selektierteZusatzprodukte.findIndex((s) => s.zusatzprodukt_id == 2) != -1;
  }

  selectionContractPDFUnsigned(){
    this.selectionContractPDFUnsigendSignedEmitter.emit({unsigned: true, document: this.document});
  }
  selectionContractPDFSigned(){
    this.selectionContractPDFUnsigendSignedEmitter.emit({unsigned: false, document: this.document});
  }
}
