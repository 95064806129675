import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnumService } from 'src/app/service/enum/enum.service';
import { LoginService } from 'src/app/service/login/login.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public submitted = false;
  public accessDenied = false;
  public showPassword = false;
  public showForgotPasswordForm = false;
  public sendMailSuccess = false;
  public version = environment.VERSION;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(!this.showForgotPasswordForm) this.onSubmit();
    else this.sendMail();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private loginService: LoginService,
    private vService: VehicleService,
    private eService: EnumService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      username_reset: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if(!this.loginForm.controls['username'].valid && !this.loginForm.controls['password'].valid){
      return;
    }
    this.loginService.login(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value).subscribe((access) => {
      if(access){
        this.eService.updateEnums();
        new ReloadDataHandler().activateReloadLoop([this.vService]);
        if(Number(localStorage.getItem('auth')) == 6) this.router.navigate(['cars'])
        else if(Number(localStorage.getItem('auth')) == 10) this.router.navigate(['interfaces'])
        else this.router.navigate(['cars']);
        return;
      }
      this.accessDenied = true;
    });
  }

  forgotPasswordAction() {
    this.loginForm.reset();
    this.submitted = false;
    this.showForgotPasswordForm = true;
  }

  sendMail() {
    this.submitted = true;
    if(this.loginForm.controls['username_reset'].invalid){
      return;
    } else {
      this.loginService.sendResetPassword(this.loginForm.controls['username_reset'].value).subscribe((response) => {
        if(response){
          this.sendMailSuccess = true;
          this.submitted = false;
        }
      })
    }
  }

  cancel() {
    this.loginForm.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showForgotPasswordForm = false;
    this.sendMailSuccess = false;
  }

  goToLogin() {
    this.cancel();
  }
}
