<div [attr.id]="'submenu-column-wrapper_'+activeSubMenu?.sub_menu_id+'_'+index"
 [class.submenu-column-not-clickable]="activeSubMenu.disabled_elements"
 (click)="handleRouting($event)"
 [ngClass]="activeSubMenu?.getColumnWrapperStyle(index)">
  <app-sub-menu-column-data
    *ngFor="let column of activeSubMenu?.columns; let i = index;"
    style=" height: 100% !important;"
    [object]="object"
    [subMenuColumn]="column"
    [class.column-data-is-fixed]="column.is_fixed"
    [class.column-data-first-item]="i == 0 ? true : false"
    [leftWidth]="column.is_fixed ? activeSubMenu?.getColumnFixedLeftPos(i) : 0"
    (click)="openDetailsEmitter.emit(object)"
    (calculatePricesheetEmitter)="calculatePricesheetEmitter.emit(object)"
  ></app-sub-menu-column-data>
  <div *ngIf="activeSubMenu.actions.length == 0"></div>
  <app-list-action-container
    *ngIf="activeSubMenu.actions.length > 0"
    [actions]="activeSubMenu.actions"
    [index]="index"
    [maxIndex]="maxIndex"
    [object]="object"
    [listItemId]="'submenu-column-wrapper_'+activeSubMenu?.sub_menu_id+'_'+index"
    (selectionOpenDetailsEmitter)="goTo()"
    (selectionOpenDetailsEditEmitter)="openEditEmitter.emit($event)"
    (selectionDeleteEmitter)="deleteEmitter.emit($event)"
    (selectionMoveEmitter)="moveEmitter.emit($event)"
    (selectionPrintEmitter)="printPdfEmitter.emit($event)"
    (selectionEtikettEmitter)="printEtikettEmitter.emit($event)"
    (selectionSellEmitter)="sellEmitter.emit($event)"
    (selectionDokumentEmitter)="selectionCreateDokumentEmitter.emit($event)"
    (selectionCopyEmitter)="selectionCopyEmitter.emit($event)"
    (selectionUploadDocumentEmitter)="selectionUploadDocumentEmitter.emit($event)"
    (selectionPseudoSignatureEmitter)="selectionPseudoSignatureEmitter.emit($event)"
    (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertragEmitter.emit($event)"
    (selectionArchivEmitter)="selectionArchivEmitter.emit($event)"
    (selectionPDFCreateEmitter)="selectionPDFCreateEmitter.emit($event)"
    (selectionPDFOpenEmitter)="selectionPDFOpenEmitter.emit($event)"
    (selectionTestDrivePDFSignEmitter)="selectionTestDrivePDFSignEmitter.emit($event)"
    (selectionContractPDFUnsigendSignedEmitter)="selectionContractPDFUnsigendSignedEmitter.emit($event)"
    (selectionRestoreEmitter)="selectionRestoreEmitter.emit($event)"
    (selectionChangeStatusEmitter)="selectionChangeStatusEmitter.emit($event)"
    (selectionChangePaymentStatusEmitter)="selectionChangePaymentStatusEmitter.emit($event)"
    (selectionAppSignatureEmitter)="selectionAppSignatureEmitter.emit($event)"
  ></app-list-action-container>
  <!-- <div *ngIf="!object?.isReady && viewLoaded" class="submenu-column-disabled"></div> -->
</div>
