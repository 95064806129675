import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Thread } from 'src/app/buisness-object/message/Thread';

@Component({
  selector: 'app-overview-tickets',
  templateUrl: './overview-tickets.component.html',
  styleUrls: ['./overview-tickets.component.css']
})
export class OverviewTicketsComponent implements OnInit {
  public userId: number;
  @Input() threads: Thread[];
  @Input() customer: Customer;
  @Input() employees: Thread[];
  @Output() resolveEmitter = new EventEmitter<Thread>();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userId = Number(localStorage.getItem('employee_id'));
  }

  openTicket(thread: Thread) {
    this.router.navigate(['messages'], { queryParams: { ticketId: thread.threadId, customerId: this.customer.id } });
  }

  createTicket() {
    //TODO:
    this.router.navigate(['messages/create'], { queryParams: { customerId: this.customer.id } });
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['messages/create'], { queryParams: { customerId: this.customer.id } }));
  }
}
