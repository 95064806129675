<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false" [class.data_box_disabled]="editMode && inseratDisable">
  <h3 collapsible-header class="overview_data_box_lbl_title">Ausstattung
    <div class="loading-screen-wrapper" *ngIf="(editMode && !vehicle.chatgpt_flag) && this.authService.isSuperAdmin()">
      <div class="loader"></div>
    </div>
  </h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="flex_container_equi" [class.flex_container_equi_empty]="!vehicle.equipments || vehicle.equipments.length == 0">
      <label class="overview_record_lbl" *ngIf="!vehicle.equipments || vehicle.equipments.length == 0">Keine Ausstattungen angegeben.</label>
      <div *ngFor="let category of categoriesVehicle" class="category_container">
        <div class="overview_data_box_lbl_inner_title">{{category.category_name}}</div>
        <div *ngFor="let equipment of category.equipments" class="checkbox_container">
          <img class="icon_check" src="../../../../../assets/icon-listitem-blue.png">
          <div class="overview_record_value">{{equipment.eq_name}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="editMode" class="flex_container_equi">
      <div *ngFor="let category of categories" class="category_container">
        <div class="overview_data_box_lbl_inner_title">{{category.category_name}}</div>
          <div *ngFor="let equipment of category.equipments" class="checkbox_container" (click)="addEquipment(equipment)">
            <input type="checkbox" class="checkbox" name="equipment" [checked]="equipment.is_selected">
            <label for="equipment" class="checkbox_lbl">{{equipment.eq_name}}</label>
          </div>
      </div>
    </div>
  </div>
</app-collapsible>
