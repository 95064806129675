import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() editMode: boolean;
  @Input() form: FormGroup;

  public onloadSrc: any[] = [];
  public uploadProgress = 0;
  public currentFile = 0;
  public filesAmount = 0;
  public sources: {
    attachment_id: number,
    name: string,
    format: string,
    data: string
  }[] = [];

  constructor(
    private aService: AttachmentService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    for(let attachment of this.vehicle.attachments){
      this.sources.push({
        attachment_id: attachment.attachment_id,
        name: attachment.filename,
        format: attachment.mimetyp,
        data: attachment.data
      })
    }
  }


  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i])
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.sources.push({
          attachment_id: undefined,
          name: file.name.split('.')[0],
          format: file.name.split('.')[1],
          data: event.target.result.split(',')[1]
        })
        this.form.controls.attachments.setValue(this.sources)
        resolve(true)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async onFileDropped(data: any) {
    if(data?.data && data?.data[0]){
      this.sources.push({
        attachment_id: undefined,
        name: data?.data[0].name.split('.')[0],
        format: data?.data[0].name.split('.')[1],
        data: data?.data[0].content.split(',')[1]
      })
      this.form.controls.attachments.setValue(this.sources);
    }
  }

  deleteAttachment(source: any) {
    this.aService.deleteAttachment(source.attachment_id).subscribe((success) => {
      if(success){
        let index = this.sources.findIndex(s => s.attachment_id == source.attachment_id)
        if(index > -1) this.sources.splice(index, 1);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Dokument entfernt.',
          success: true
        });
      }
    })
  }
}
