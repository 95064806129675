import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageLocation } from 'src/app/buisness-object/tires/StorageLocation';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-dialog-tyres-storage-space-info',
  templateUrl: './dialog-tyres-storage-space-info.component.html',
  styleUrl: './dialog-tyres-storage-space-info.component.css'
})
export class DialogTyresStorageSpaceInfoComponent extends BaseComponent {
  @Input() obj: Tire;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  public name;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    let brand = this.getEnumValue("tire_brand", this.obj.tire_brand);
    this.name = '#'+this.obj.tire_id+' '+brand+' '+(this.obj.tire_model?this.obj.tire_model:'');
  }
}
