<form id="price_sheets_create_page" [formGroup]="form" *ngIf="form">
  <div class="price_sheets_create_page_column">
    <div class="data_box">
      <h3>Berechnungsgrundlage</h3>
      <div class="input_wrapper" *ngIf="!isVomAusland()">
        <label class="overview_record_lbl">Verkaufspreis (brutto)</label>
        <input type="number" placeholder="---" formControlName="verkaufspreis_brutto" [class.input_invalid]="submitted && false">
      </div>
      <div class="input_wrapper" *ngIf="isVomAusland()">
        <div class="overview_record_lbl">Verkaufspreis (brutto)</div>
        <input type="number" placeholder="---" formControlName="verkaufspreis_brutto" [class.input_invalid]="submitted && false">
      </div>
      <app-dropdown-multiple *ngIf="form.controls['steuersatz_nach'].value == null"
        [title]="'Steuersatz'"
        [formcontrol]="form.controls['steuersatz_nach']"
        [headerText]="getSteuersatz(form.controls['steuersatz_nach'].value)"
        [values]="steuerseatze"
        (submitValueEmitter)="form.controls['steuersatz_nach'].setValue($event)"
      ></app-dropdown-multiple>
      <div class="input_wrapper" *ngIf="form.controls['steuersatz_nach'].value">
        <label>Steuersatz</label>
        <label class="input_no_input">{{getSteuersatz(form.controls['steuersatz_nach'].value)}}  ({{getSteueratzValue(form.controls['steuersatz_nach'].value)}}%) <img (click)="selectionEditSteuersatz()" src="assets/edit_blue.svg"></label>
      </div>
    </div>
    <div class="data_box">
      <h3>Fahrzeugkosten</h3>
      <div class="input_wrapper">
        <label>{{authService.isSinhuber() ? 'GuV bei Vorkalkulation' : 'Deckungsbeitrag (Vorkalkulation)' }}</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['guv_vorkalkulation'].value)}}</label>
      </div>
      <div class="input_wrapper">
        <label>{{authService.isSinhuber() ? 'GuV bei Nachkalkulation' : 'Deckungsbeitrag (Nachkalkulation)' }}</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['guv_nachkalkulation'].value)}}</label>
      </div>
      <div class="input_wrapper" *ngIf="!authService.isStandard()">
        <label>Aufschlag bei Verkauf (Nachkalkulation)</label>
        <label class="input_no_input">{{getInputValueNumber(form.controls['aufschlag_nach'].value)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Richtlinien Verkauf')">
      <h3>Richtlinien Verkauf</h3>
      <div class="input_wrapper">
        <label>Verkaufspreis</label>
        <input type="number" placeholder="---" formControlName="vk_nachkalkulation">
      </div>
      <div class="input_wrapper">
        <label>Händlerpreis</label>
        <input type="number" placeholder="---" formControlName="vk_nachkalkulation_haendler">
      </div>
      <app-dropdown-multiple
        [title]="'Finanzierungsgesellschaft'"
        [placeholder]="'---'"
        [headerText]="finanzierungsgesellschaftenValue"
        [formcontrol]="form.controls['finanzierungsgesellschaft']"
        [values]="finanzierungsgesellschaftenValues"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['finanzierungsgesellschaft'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <app-dropdown-multiple
        [title]="'Finanzierungsart'"
        [placeholder]="'---'"
        [headerText]="finanzierungsartenValue"
        [formcontrol]="form.controls['finanzierungsart']"
        [values]="finanzierungsartenValues"
        [isOptional]="true"
        (submitValueEmitter)="form.controls['finanzierungsart'].setValue($event); getEnumDataOnChange()"
      ></app-dropdown-multiple>
      <!-- <div class="input_wrapper" *ngIf="false">
        <label>Zugaben</label>
        <input type="number" placeholder="---" formControlName="zugaben">
      </div>
      <div class="input_wrapper" *ngIf="false">
        <label>Zubehör</label>
        <input type="number" placeholder="---" formControlName="zubehoer">
      </div> -->
    </div>
    <div class="data_box" id="data_box_vorkalkulation" *ngIf="showBoxPricesheet('Vorkalkulation')">
      <h3>Vorkalkulation</h3>
      <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value != 2">
        <div class="overview_record_lbl">Einkaufspreis (netto)</div>
        <label class="input_no_input">{{getInputValueNumber(form.controls['einkaufspreis_netto'].value)}}</label>
      </div>
      <div class="input_wrapper" *ngIf="form.controls['steuersatz'].value == 2">
        <div class="overview_record_lbl">Einkaufspreis (brutto)</div>
        <label class="input_no_input">{{getInputValueNumber(form.controls['einkaufspreis_brutto'].value)}}</label>
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Vorsteuer - Umsatzsteuer (%)</div>
        <label class="input_no_input">{{getInputValueNumber(form.controls['vorsteuer'].value)}}</label>
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Standzeit Plan-Tage</div>
        <label class="input_no_input">{{getInputValueNumber(form.controls['standzeit_plan_tage'].value)}}</label>
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Standzeitkosten</div>
        <label class="input_no_input">{{getInputValueNumber(form.controls['standzeitkosten_vor'].value)}}</label>
      </div>
      <div *ngIf="showMore">
        <div class="input_wrapper">
          <div class="overview_record_lbl">Beschaffungskosten gesamt</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['beschaffungskosten_gesamt'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Aufbereitungskosten</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['aufbereitung'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Reparaturkosten gesamt</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['reparatur_summe_gesamt_vorjahr'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Zinsen</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['zinsen_vor'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Garantie</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['garantie'].value)}}</label>
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('fixkosten_pro_fahrzeug_bei_70')">
          <div class="overview_record_lbl">Fixkosten pro Fahrzeug</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['fixkosten_pro_fahrzeug_bei_70'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">GW-Aufschlag</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['gw_aufschlag'].value)}}</label>
        </div>
      </div>
      <button class="btn_label_blue" (click)="showMore = !showMore">
        <span *ngIf="!showMore">&#43; Mehr anzeigen</span>
        <span *ngIf="showMore">&#8722; Weniger anzeigen</span>
      </button>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Typenschein')">
      <h3>Typenschein</h3>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Typenschein ausgestockt am</label>
        <input #inputDate9 type="date" [max]="maxDate" formControlName="type_certificate_ausgestockt" [class.date-input--has-value]="inputDate9.value != null" [class.input_invalid]="submitted && form.controls['type_certificate_ausgestockt'].errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Typenschein versendet an Kunden</label>
        <input #inputDate5 type="date" [max]="maxDate" formControlName="type_certificate_versendet" [class.date-input--has-value]="inputDate5.value != null" [class.input_invalid]="submitted && form.controls['type_certificate_versendet'].errors">
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Neupreis')">
      <h3>Neupreis</h3>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Fahrzeugpreis</div>
        <input type="number" placeholder="---" formControlName="neu_preis">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Sonderausstattung</div>
        <input type="number" placeholder="---" formControlName="sonderaustattung">
      </div>
      <div class="input_wrapper">
        <div class="overview_record_lbl">Gesamt Neupreis</div>
        <label class="input_no_input">{{getInputValue(form.controls['gesamt_neupreis'].value)}}</label>
      </div>
    </div>
    <div class="data_box" *ngIf="showBoxPricesheet('Notizfeld Geschäftsführung intern')">
      <h3>Notizfeld Geschäftsführung intern</h3>
      <div>
        <app-richtext-editor
          [editorId]="1"
          [activateBold]="false"
          [activateItalic]="false"
          [activateStrikeThrougth]="false"
          [activateUnderline]="false"
          [control]="this.form.controls.gf_info"
        ></app-richtext-editor>
      </div>
    </div>
  </div>
  <div class="price_sheets_create_page_column" id="column_2">
    <div class="column_horizontal_flex">
      <div class="data_box">
        <h3>Verkauf</h3>
        <div class="input_wrapper" *ngIf="!isVomAusland() && form.controls['steuersatz'].value != 2">
          <div class="overview_record_lbl">Verkaufspreis (netto)</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['verkaufspreis_netto'].value)}}</label>
        </div>
        <div class="input_wrapper" *ngIf="isVomAusland()">
          <div class="overview_record_lbl">Verkaufspreis (netto)</div>
          <label class="input_no_input">{{getInputValueNumber(form.controls['verkaufspreis_netto'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Verkaufsdatum</div>
          <input #inputDate5 type="date" [max]="maxDate" formControlName="verkaufsdatum" [class.date-input--has-value]="inputDate5.value != null" [class.input_invalid]="submitted && form.controls['verkaufsdatum'].errors">
        </div>
        <div class="input_wrapper" *ngIf="authService.isSinhuber()">
          <div class="overview_record_lbl">AL/RE-Datum</div>
          <input #inputDate6 type="date" [max]="maxDate" formControlName="al_re_datum" [class.date-input--has-value]="inputDate6.value != null" [class.input_invalid]="submitted && form.controls['al_re_datum'].errors">
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Geldeingang</div>
          <input #inputDate8 type="date" [max]="maxDate" formControlName="geldeingang" [class.date-input--has-value]="inputDate8.value != null" [class.input_invalid]="submitted && form.controls['geldeingang'].errors">
        </div>
        <!-- <div class="input_wrapper">
          <div class="overview_record_lbl">Standzeit</div>
          <input #inputDate7 type="date" [max]="maxDate" formControlName="standzeit" [class.date-input--has-value]="inputDate7.value" [class.input_invalid]="submitted && form.controls['standzeit'].errors">
        </div> -->
        <div class="input_wrapper">
          <div class="overview_record_lbl">Standzeit Platz</div>
          <label class="input_no_input">{{getInputValue(form.controls['standzeit_platz'].value)}}</label>
        </div>
        <div class="input_wrapper">
          <div class="overview_record_lbl">Standzeit Geld</div>
          <label class="input_no_input">{{getInputValue(form.controls['standzeit_geld'].value)}}</label>
        </div>
        <app-dropdown-multiple *ngIf="showValuePricesheet('finanzierungszusage')"
          [title]="'Finanzierungszusage'"
          [placeholder]="'---'"
          [headerText]="finanzierungszusagenValue"
          [formcontrol]="form.controls['finanzierungszusage']"
          [values]="finanzierungszusagenValues"
          [isOptional]="true"
          (submitValueEmitter)="form.controls['finanzierungszusage'].setValue($event); getEnumDataOnChange()"
        ></app-dropdown-multiple>
        <div class="input_wrapper" *ngIf="showValuePricesheet('sent_to_bank')">
          <div class="overview_record_lbl">Unterlagen an Bank versendet</div>
          <input #inputDateBank type="date" [max]="maxDate" formControlName="sent_to_bank" [class.date-input--has-value]="inputDateBank.value != null" [class.input_invalid]="submitted && form.controls['sent_to_bank'].errors">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('eingang_anzahlung')">
          <div class="overview_record_lbl">Eingang Anzahlung</div>
          <input #inputDate9 type="date" [max]="maxDate" formControlName="eingang_anzahlung" [class.date-input--has-value]="inputDate9.value != null" [class.input_invalid]="submitted && form.controls['eingang_anzahlung'].errors">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('eingang_anzahlung_description')">
          <div class="overview_record_lbl">Eingang Anzahlung Info</div>
          <input type="text" placeholder="---" formControlName="eingang_anzahlung_description" [class.input_invalid]="submitted && form.controls['eingang_anzahlung_description'].errors">
        </div>
        <div class="data_wrapper" *ngIf="showValuePricesheet('fahrzeuginzahlungnahme')">
          <label class="checkbox_lbl">Fahrzeuginzahlungnahme</label>
          <input type="checkbox" class="checkbox" [checked]="form.controls['fahrzeuginzahlungnahme'].value" (click)="form.controls['fahrzeuginzahlungnahme'].setValue(!form.controls['fahrzeuginzahlungnahme'].value)">
        </div>
        <div class="data_wrapper" *ngIf="showValuePricesheet('export_value')">
          <label class="checkbox_lbl">Export</label>
          <input type="checkbox" class="checkbox" [checked]="form.controls['export_value'].value" (click)="form.controls['export_value'].setValue(!form.controls['export_value'].value)">
        </div>
        <app-dropdown-objects-search
          [title]="'Kunde'"
          [placeholder]="'---'"
          [headervalue]="form.controls['customer']?.value != null ? form.controls['customer']?.value?.getName() : (form.controls['customer_name'].value ? form.controls['customer_name'].value: null)"
          [formcontrol]="form.controls['customer']"
          [formcontrolTwo]="form.controls['customer_name']"
          [stringPossible]="true"
          [values]="customerValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('customer', $event)"
        ></app-dropdown-objects-search>
        <app-dropdown-objects-search
          [title]="'Mitarbeiter'"
          [placeholder]="'---'"
          [headervalue]="form.controls['employee']?.value != null ? (form.controls['employee']?.value.firstName + ' ' + form.controls['employee']?.value.lastName) : null"
          [formcontrol]="form.controls['employee']"
          [formcontrolTwo]="form.controls['employee_id']"
          [values]="employeeValues"
          [submitted]="submitted"
          (submitValueEmitter)="setValueFromDropdown('employee', $event)"
        ></app-dropdown-objects-search>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <label class="overview_record_lbl">Einkauf Vertragsnr.</label>
          <input type="text" class="overview_input" placeholder="Nummer" formControlName="einkauf_vertragsnummer">
        </div>
        <div class="input_wrapper" *ngIf="authService.isHoedl()">
          <label class="overview_record_lbl">Verkauf Vertragsnr.</label>
          <input type="text" class="overview_input" placeholder="Nummer" formControlName="verkauf_vertragsnummer">
        </div>
      </div>
      <div class="column_vertical_flex">
        <div class="data_box">
          <h3>NoVA</h3>
          <div class="input_wrapper" *ngIf="!authService.isStandard()">
            <div class="overview_record_lbl">Entgelt ohne NoVA</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['entgelt_ohne_nova'].value)}}</label>
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">NoVa-Satz</div>
            <label class="input_no_input">{{form.controls['nova_satz'].value ? getInputValueNumber(form.controls['nova_satz'].value) + "%" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="authService.isStandard()">
            <div class="overview_record_lbl">NoVA Grundbetrag</div>
            <label class="input_no_input">{{form.controls['nova_grundbetrag_nach'].value ? getInputValueNumber(form.controls['nova_grundbetrag_nach'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="authService.isStandard()">
            <div class="overview_record_lbl">anteiliger Abzugsposten</div>
            <label class="input_no_input">{{form.controls['abzugsposten'].value ? getInputValueNumber(form.controls['abzugsposten'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="authService.isStandard()">
            <div class="overview_record_lbl">anteiliger CO2-Malus</div>
            <label class="input_no_input">{{form.controls['malus'].value ? getInputValueNumber(form.controls['malus'].value) + " €" : '---'}}</label>
          </div>
          <div class="data_wrapper" *ngIf="authService.isStandard()">
            <div class="overview_record_lbl">Summe NoVA</div>
            <label class="input_no_input">{{form.controls['summe_nova'].value ? getInputValueNumber(form.controls['summe_nova'].value) + " €" : '---'}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!authService.isStandard()">
            <div class="overview_record_lbl">NoVA</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['nova'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!authService.isStandard()">
            <div class="overview_record_lbl">Malus/<b>Bonus</b></div>
            <input type="number" placeholder="---" formControlName="malus_bonus_nach" (input)="form.controls['malus_bonus_changed'].setValue(true)">
          </div>
          <div class="input_wrapper" *ngIf="!authService.isStandard()">
            <div class="overview_record_lbl">Summe NoVA</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['summe_nova'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!authService.isStandard() && !isVomAusland()">
            <div class="overview_record_lbl">UST</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['ust'].value)}}</label>
          </div>
          <div class="input_wrapper" *ngIf="!authService.isStandard() && isVomAusland()">
            <div class="overview_record_lbl">Ohne NoVA inkl. UST</div>
            <label class="input_no_input">{{getInputValueNumber(form.controls['ohne_nova_inkl_ust'].value)}}</label>
          </div>
        </div>
        <div class="data_box" *ngIf="showBoxPricesheet('Provision-Mitarbeiter')">
          <h3>Provision</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Fahrzeug</div>
            <input type="number" placeholder="---" formControlName="abzug_fahrzeug_provision">
          </div>
        </div>
        <div class="data_box" *ngIf="showBoxPricesheet('Provision-Eingang')">
          <h3>Provision-Eingang</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Fahrzeug</div>
            <input type="number" placeholder="---" formControlName="fahrzeug_provision">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Finanzierung</div>
            <input type="number" placeholder="---" formControlName="finanzierung">
          </div>
        </div>
        <div class="data_box" *ngIf="showBoxPricesheet('Provision-Ausgang')">
          <h3>Provision-Ausgang</h3>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Fahrzeug</div>
            <input type="number" placeholder="---" formControlName="abzug_fahrzeug_provision">
          </div>
          <div class="input_wrapper">
            <div class="overview_record_lbl">Finanzierung</div>
            <input type="number" placeholder="---" formControlName="abzug_finanzierung">
          </div>
        </div>
      </div>
    </div>
    <div class="data_box" *ngIf="authService.isSinhuber() || authService.isHoedl()">
      <h3>Reparatur und Fahrzeugkosten</h3>
      <div class="input_wrapper" style="grid-template-columns: 25% 75%;">
        <div class="overview_record_lbl">Aufbereitung</div>
        <input type="number" placeholder="---" formControlName="aufbereitung_nachkalkulation">
      </div>
    </div>
    <div class="column_vertical_flex" >
      <div class="data_box" id="reparatur_details_box" *ngIf="authService.isSinhuber() || authService.isHoedl()">
        <div id="reparatur_details_box_header">
          <h3>Reparaturkosten-Vorjahr</h3>
          <h3>Renr.</h3>
          <h3>AZ</h3>
          <h3>MAT</h3>
          <h3>Reifen</h3>
          <h3>Lackierung</h3>
          <div></div>
          <div></div>
        </div>
        <div id="reparatur_details_box_form_body">
          <div *ngFor="let repairForm of form.controls['reparaturen_vorjahr'].value; let i = index">
            <form id="reparatur_details_form" [formGroup]="repairForm">
              <h3>{{repairForm?.controls['name'].value}}</h3>
              <input type="string" placeholder="---" formControlName="renr">
              <input type="number" placeholder="---" formControlName="az">
              <input type="number" placeholder="---" formControlName="mat">
              <input type="number" placeholder="---" formControlName="reifen">
              <input type="number" placeholder="---" formControlName="lackierung">
              <div></div>
              <button class="btn_lbl_action" (click)="removeReparaturEmitter.emit(i)"><span>&#8722;</span></button>
            </form>
          </div>
        </div>
        <div id="reparatur_details_box_button">
          <button id="btn_add" class="btn_lbl_action" (click)="addReparaturEmitter.emit()"><span>&#43;</span> Reparatur hinzufügen</button>
        </div>
        <div id="reparatur_details_box_footer">
          <h3>Gesamt</h3>
          <div></div>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt_vorjahr'].value?.az)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt_vorjahr'].value?.mat)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt_vorjahr'].value?.reifen)}}</label>
          <label>{{getInputValueNumber(form.controls['reparaturen_column_gesamt_vorjahr'].value?.lack)}}</label>
          <div></div>
        </div>
      </div>
      <div class="data_box" id="reparatur_details_box" *ngIf="authService.isSinhuber()">
        <div id="reparatur_details_box_header">
          <h3>Gew/Gar rep</h3>
          <h3>Renr.</h3>
          <h3>AZ</h3>
          <h3>MAT</h3>
          <h3>Reifen</h3>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <form id="reparatur_details_form" [formGroup]="form.controls['gw_gar_reparatur'].value[0]">
          <div></div>
          <input type="string" placeholder="---" formControlName="renr">
          <input type="number" placeholder="---" formControlName="az">
          <input type="number" placeholder="---" formControlName="mat">
          <input type="number" placeholder="---" formControlName="reifen">
          <div></div>
          <div></div>
          <div></div>
        </form>
      </div>
      <div class="data_box" id="zusatzkosten_box">
        <h3>Zusatzkosten</h3>
        <div class="input_wrapper">
          <label>Zinsen</label>
          <label class="data_lbl_edit">
            <p>Zinssatz</p>
            {{form.controls['zinssatz_nach'].value}}
            <img (click)="selectionEditValue(form.controls['zinssatz_nach'].value, 'zinssatz_nach')" src="assets/edit_blue.svg">
            <span>{{getInputValueNumber(form.controls['zinsen_nach'].value)}}</span>
          </label>
        </div>
        <div class="input_wrapper" *ngIf="authService.isSinhuber() || authService.isHoedl()">
          <label>Garantie (intern)</label>
          <input type="number" placeholder="---" formControlName="garantie_intern">
        </div>
        <div class="input_wrapper" *ngIf="showValuePricesheet('garantie_extern')">
          <label>Garantie (extern)</label>
          <input type="number" placeholder="---" formControlName="garantie_extern">
        </div>
        <div class="input_wrapper">
          <label>Standzeitkosten</label>
          <label class="data_lbl_edit">
            <p>Kosten je Tag</p>
            {{form.controls['standzeitkosten_je_tag_nach'].value}}
            <img (click)="selectionEditValue(form.controls['standzeitkosten_je_tag_nach'].value, 'standzeitkosten_je_tag_nach')" src="assets/edit_blue.svg">
            <span>{{getInputValueNumber(form.controls['standzeitkosten_nach'].value)}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
