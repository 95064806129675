<div *ngIf="showView == view.LIST">
    <div class="header-base-style">
      <div class="header-inner-wrapper">
        <button class="button_type_1" (click)="showView = view.CREATE" appDropdown [show]="showDropdownMainButton" (changedValueEmitter)="showDropdownMainButton = $event">
          Neu
        </button>
        <div id="main-menu-wrapper">
          <button *ngFor="let mainMenu of mainMenus"
            [class.main-menu-btn-selected]="mainMenu.getActive()"
            (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
            {{mainMenu.name}}
          </button>
        </div>
      </div>
      <div>
      </div>
    </div>
    <app-list-container *ngIf="_activeSubMenu.getValue()"
        [objectTyp]="'customer'"
        [objects]="_objectsFiltered.getValue()"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [mainMenus]="mainMenus"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        (pageEmitter)="pageHandling($event)"
        (applySortingEmitter)="applySorting($event)"
        (openDetailsEmitter)="selectionDetails($event)"
        (openEditEmitter)="selectionEdit($event)"
        (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertrag($event)"
        (selectionRestoreEmitter)="selectionRestore($event)"
        (selectionArchivEmitter)="selectionArchiv($event)"
        (deleteEmitter)="selectionDelete($event)"
    ></app-list-container>
  </div>

  <app-kunde-create *ngIf="showView == view.CREATE"
    [obj]="selectedObject"
    [employees]="employees"
    (createSuccessEmitter)="createSuccess($event)"
    (updateSuccessEmitter)="updateSuccess($event)"
    (selectionCancelEmitter)="selectionCancelCreate()"
  ></app-kunde-create>

  <app-kunde-details *ngIf="showView == view.DETAILS"
    [obj]="selectedObject"
    (selectionCloseEmitter)="selectionCloseDetails()"
    (selectionEditEmitter)="selectionEdit($event)"
  ></app-kunde-details>

