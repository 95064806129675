import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EnumHelper } from 'src/app/helpers/EnumHelper';
import { environment } from 'src/environments/environment';

enum AUTHORIZATIONS {
  Admin = 1,
  Buchhaltung = 2,
  Verkauf = 3,
  Werkstatt = 4,
  Ersatzteile = 5,
  Fahrzeuganlieferung = 6,
  Probefahrt = 7,
  Empfang = 8,
  isOnlyVehicles = 9
}

enum ROLES {
  ADMIN_VIEW = 1,
  ADMIN_EDIT = 2,
  CUSTOMER_VIEW = 3,
  CUSTOMER_EDIT = 4,
  VEHICLE_VIEW = 5,
  VEHICLE_EDIT = 6,
  TICKETING = 7,
  DOCUMENT_VIEW = 8,
  DOCUMENT_EDIT = 9,
  TEST_DRIVE_VIEW = 10,
  TEST_DRIVE_EDIT = 11,
  WERKSTATT_VIEW = 12,
  ERSATZTEILE_VIEW = 13,
  FAHRZEUGANLIEFERUNG_VIEW = 14,
  EMPFANG_VIEW = 15,
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public authenticatedRoles$ = new BehaviorSubject<any>([]);
  public permissions$ = new BehaviorSubject<any>([]);
  public mandantId$ = new BehaviorSubject<any>(null);
  public configId$ = new BehaviorSubject<any>(null);

  constructor(
    private router: Router
  ) {
    this.authenticatedRoles$.next(JSON.parse(localStorage.getItem('roles')));
    try {
      this.permissions$.next(JSON.parse(localStorage.getItem('permissions')));
    } catch(e) {
      this.permissions$.next([]);
    }
    
    this.mandantId$.next(Number(localStorage.getItem('mandant_id')));
    this.configId$.next(Number(localStorage.getItem('config_id')));
  }

  setAuhtenticationRoles(data) {
    this.authenticatedRoles$.next(data);
  }

  setPermissions(data) {
    this.permissions$.next(data);
  }

  setTenantId(data) {
    this.mandantId$.next(data);
  }
  setConfigId(data) {
    this.configId$.next(data);
  }

  isWaymarkUser():  boolean {
    let mail = localStorage.getItem('email');
    return mail == 'tech@waymark.at' || mail == 'sebastian@waymark.at' || mail == 'benjamin.waymark1@gmail.com';
  }

  isHoedlAdmin():  boolean {
    let adminUsers = ['office@kfz-hoedl.at','laurastrommer@hotmail.com','tech@waymark.at']
    let mail = localStorage.getItem('email');
    return adminUsers.findIndex((m) => m == mail) > -1;
  }

  isWaymark(): boolean {
    return (this.mandantId$.getValue() == 1 ||
      this.mandantId$.getValue() == 2 ||
      this.mandantId$.getValue() == 7 ) &&
      (environment.api.includes('test'));
  }

  isAutobase(): boolean {
    return (this.mandantId$.getValue() == 1 && environment.api.includes('/api.auto-base'));
  }

  isSinhuber(): boolean {
    return (this.mandantId$.getValue() == 2 && environment.api.includes('sinhuber')) ||
        (this.mandantId$.getValue() == 2 && environment.api.includes('test-api')) ||
        environment.api.includes('localhost');
  }

  isHoedl(): boolean {
    return (this.mandantId$.getValue() == 7 && environment.api.includes('hoedl')) || environment.api.includes('localhost');
  }


  isHofbauer(): boolean {
    return (this.mandantId$.getValue() == 1 && environment.api.includes('hofbauer'));
  }

  isStandard(): boolean {
    return !this.isSinhuber();
  }


  accessPricecalculation(): boolean {
    if(this.ADMIN()) return true; //TODO Euromobile, Zweispurig, Hofbauer ausblenden
    return false;
  }

  accessViaMandant(menu: string): boolean {
    if(menu == 'rent'){
      if(this.isHofbauer()) return true;
    }
    return false;
  }

  getHoedlVerkauf(): any {
    return {
      "view" : [
        {
          "name": "images",
          "rows" : []
        },
        {
          "name" : "price",
          "rows" : ["price", "steuersatz", "guarantee", "vat_reportable", "leasable", "felgen_aufpreis", "fahrwerk_aufpreis"]
        },
        {
          "name" : "basic-data",
          "rows" : []
        },
        {
          "name" : "drive",
          "rows" : []
        },
        {
          "name" : "vehicle",
          "rows" : []
        },
        {
          "name" : "equipment",
          "collapsable": true,
          "rows" : []
        },
      ],
      "edit": []
    };
  }

  getViewAuth(): any {

    return [
      //Sinhuber
      {
        "id": 10,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : ["ankaufstest", "ankaufstest_info_description", "werkstatt_info_description", "aufbereitung", "lackierung_notwendig", "aufbereitung_info", "aufbereitung_zustaendigkeit"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
              },
              {
                "name" : "vehicle",
                "rows" : []
              }
            ],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "ankaufstest_info_description", "lackierung_notwendig", "aufbereitung_info", "aufbereitung_zustaendigkeit"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      {
        "id": 11,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ersatzteile"
                ]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ersatzteile"]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
              /*{
                "name" : "werkstatt",
                "rows" : []
              }*/
            ]
          }
        ]
      },
      {
        "id": 15,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : [
                  "ersatzteile"
                ]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ersatzteile"]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
              /*{
                "name" : "werkstatt",
                "rows" : []
              }*/
            ]
          }
        ]
      },
      {
        "id": 14,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : []
              },
              {
                "name" : "seasonal",
                "rows" : []
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : [
                "ankaufstest",
                "ankaufstest_info_description",
                "repair_status",
                "werkstatt_info_description",
                "lackierung_notwendig"
              ]
            }
          ]
          },
          {
            "view" : [
              /*{
                "name": "price",
                "rows" : []
              }, {
                "name": "images",
                "rows" : []
              }, {
                "name": "basic-data",
                "rows" : []
              },
              {
                "name": "vehicle",
                "rows" : []
              },
              {
                "name": "equipment",
                "rows" : []
              },
              {
                "name": "transactions",
                "rows" : []
              }*/
            ],
            "edit": [
            ]
          }
        ]
      },
      {
        "id": 16,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_info", "aufbereitung_zustaendigkeit", "repair_status"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : ["color_designation", "color_code", "bodyColor"]
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum",  "pickerl_valid"]
              }
            ],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_info", "aufbereitung_zustaendigkeit", "repair_status"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "vehicle",
              "rows" : ["color_designation", "color_code", "bodyColor"]
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      {
        "id": 17,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["paper_status", "finanzsperrauskunft","finanzsperrauskunft_beantragt","type_certificate_versendet","type_certificate_eingestockt"]
              },
              {
                "name" : "werkstatt",
                "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_zustaendigkeit", "repair_status"]
              },
              {
                "name" : "seasonal",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum",  "pickerl_valid"]
              }],
            "edit": [
            {
              "name" : "werkstatt",
              "rows" : ["ankaufstest", "werkstatt_info_description", "aufbereitung", "aufbereitung_zustaendigkeit","repair_status"]
            },
            {
              "name" : "seasonal",
              "rows" : []
            },
            {
              "name" : "additional",
              "rows" : ["keys_available", "keys_number", "display_keys_number", "digital_keys_number", "eingangsdatum", "pickerl_valid"]
            }
          ]
          },
          {
            "view" : [],
            "edit": []
          }
        ]
      },
      //Hödl
      {
        "id": 201,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 202,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 203,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 204,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 205,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 206,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 207,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 208,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 209,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 210,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 211,
        "screens": [
          {
            "view": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ],
            "edit": [
              {
                "name": "images",
                "rows" : []
              },
              {
                "name" : "price",
                "rows" : []
              },
              {
                "name" : "additional",
                "rows" : []
              },
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "interface-overview",
                "rows" : []
              },
              {
                "name" : "attachment",
                "rows" : []
              },
              {
                "name" : "basic-data",
                "rows" : []
              },
              {
                "name" : "identification",
                "rows" : []
              },
              {
                "name" : "vehicle",
                "rows" : []
              },
              {
                "name" : "drive",
                "rows" : []
              },
              {
                "name" : "service",
                "rows" : []
              },
              {
                "name" : "equipment",
                "rows" : []
              },
              {
                "name" : "description",
                "rows" : []
              },
              {
                "name" : "transactions",
                "rows" : []
              },
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 212,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 213,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 214,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
      {
        "id": 215,
        "screens": [
          {
            "view": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ],
            "edit": [
              {
                "name" : "condition",
                "rows" : ["type_certificate_eingestockt", "paper_status","type_certificate_is_sent"]
              },
              {
                "name" : "basic-data",
                "rows" : []
              }
            ]
          },
          this.getHoedlVerkauf()
        ]
      },
    ]
  }

  getViewRepresentations(): any {
    return [
      [
        ["images", "price", "customercar", "condition", "additional", "interface-overview", "attachment"],
        ["basic-data", "identification", "vehicle", "drive", "service", "werkstatt", "seasonal"],
        ["equipment", "description", "transactions"]
      ],
      [
        ["price", "images"],
        ["basic-data", "drive", "vehicle"],
        ["equipment", "transactions"]
      ]
    ];
  }

  getLocationID(): number {
    return Number(localStorage.getItem("location_id"));
  }

  getRoles(): any {
    let data = new EnumHelper().getEnumValueList(AUTHORIZATIONS);
    data = data.slice(0,data.length / 2);
    if(!this.isSinhuber()) {
      return data.slice(0,1);
    }
  }

  getAuthenticationRoles(auth: string): any {
    switch (auth) {
      case 'Admin': return this.getRolesAdmin(); break;
      case 'Buchhaltung': return this.getRolesBuchhaltung(); break;
      case 'Verkauf': return this.getRolesVerkauf(); break;
      case 'Werkstatt': return this.getRolesWerkstatt(); break;
      case 'Ersatzteile': return this.getRolesMechaniker(); break;
      case 'Fahrzeuganlieferung': return this.getRolesFahrzeuganlieferung(); break;
      case 'Probefahrt': return this.getRolesProbefahrt(); break;
      case 'Empfang': return this.getRolesEmpfang(); break;
      case 'Fahrzeuge': return this.getRolesOnlyVehicle(); break;
      default: return[]; break;
    }
  }

  getRolesAdmin(): any[] {
    return [
      { role_id: 1, role: 'admin_view'},
      { role_id: 2, role: 'admin_edit'},
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 8, role: 'document_view'},
      { role_id: 9, role: 'document_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
    ];
  }
  getRolesBuchhaltung(): any[] {
    if(this.isHoedl()) {
      return [
        { role_id: 3, role: 'customer_view'},
        { role_id: 4, role: 'customer_edit'},
        { role_id: 5, role: 'vehicle_view'},
        { role_id: 6, role: 'vehicle_edit'},
        { role_id: 7, role: 'ticketing'},
        { role_id: 8, role: 'document_view'},
        { role_id: 9, role: 'document_edit'},
        /*{ role_id: 18, role: 'buchhaltung_view'},*/
      ];
    } else {
      return [
        { role_id: 3, role: 'customer_view'},
        { role_id: 4, role: 'customer_edit'},
        { role_id: 5, role: 'vehicle_view'},
        { role_id: 6, role: 'vehicle_edit'},
        { role_id: 7, role: 'ticketing'},
        { role_id: 8, role: 'document_view'},
        { role_id: 9, role: 'document_edit'},
      ];
    }

  }
  getRolesVerkauf(): any[] {
    return [
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 8, role: 'document_view'},
      { role_id: 9, role: 'document_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
    ];
  }
  getRolesWerkstatt(): any[] {
    return [
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
      { role_id: 12, role: 'werkstatt_view'},
    ];
  }
  getRolesMechaniker(): any[] {
    return [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 13, role: 'ersatzteile_view'}
    ];
  }
  getRolesFahrzeuganlieferung(): any[] {
    return [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 14, role: 'fahrzeuganlieferung_view'}
    ];
  }
  getRolesProbefahrt(): any[] {
    return [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
      { role_id: 7, role: 'ticketing'},
    ];
  }
  getRolesEmpfang(): any[] {
    return [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 15, role: 'empfang_view'},
    ];
  }
  getRolesOnlyVehicle(): any[] {
    return [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'}
    ];
  }


  //roles specifications
  setRole(roles: any) : any {
    if(this.isAdmin(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 0);
    else if(this.isVerkauf(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 2);
    else if(this.isBuchhaltung(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 1);
    else if(this.isWerkstatt(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 3);
    else if(this.isMechaniker(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 4);
    else if(this.isFahrzeuganlieferung(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 5);
    else if(this.isProbefahrt(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 6);
    else if(this.isEmpfang(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 7);
    else if(this.isOnlyVehicles(roles)) return new EnumHelper().getRawValue(AUTHORIZATIONS, 7);
    else return '---';
  }
  isSuperAdmin() :boolean {
    const userId = Number(localStorage.getItem('employee_id'));
    return userId == 38 || userId == 43;
  }
  isAdmin(roles: any[]): boolean {
    let isRoles = [
      { role_id: 1, role: 'admin_view'},
      { role_id: 2, role: 'admin_edit'},
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 8, role: 'document_view'},
      { role_id: 9, role: 'document_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles?.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }
  isVerkauf(roles: any[]): boolean {
    let isRoles = [
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 8, role: 'document_view'},
      { role_id: 9, role: 'document_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }
  isBuchhaltung(roles: any[]): boolean {
    let isRoles = this.getRolesBuchhaltung();
    /*let isRoles = [
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 8, role: 'document_view'},
      { role_id: 9, role: 'document_edit'}
    ]*/
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }
  isWerkstatt(roles: any[]): boolean {
    let isRoles = [
      { role_id: 3, role: 'customer_view'},
      { role_id: 4, role: 'customer_edit'},
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
      { role_id: 12, role: 'werkstatt_view'},
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }
  isMechaniker(roles: any[]): boolean {
    let isRoles = [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 13, role: 'ersatzteile_view'}
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }

  isFahrzeuganlieferung(roles: any[]): boolean {
    let isRoles = [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 14, role: 'fahrzeuganlieferung_view'}
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }

  isProbefahrt(roles: any[]): boolean {
    let isRoles = [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 10, role: 'test_drive_view'},
      { role_id: 11, role: 'test_drive_edit'},
      { role_id: 7, role: 'ticketing'},
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }

  isEmpfang(roles: any[]): boolean {
    let isRoles = [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
      { role_id: 7, role: 'ticketing'},
      { role_id: 15, role: 'empfang_view'}
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }

  isOnlyVehicles(roles: any[]): boolean {
    let isRoles = [
      { role_id: 5, role: 'vehicle_view'},
      { role_id: 6, role: 'vehicle_edit'},
    ]
    let access = true;
    for(let role of isRoles){
      let index = roles.findIndex(r => r.role_id == role.role_id);
      if(index == -1) access = false;
    }
    return access;
  }

  //roles access
  ADMIN(): boolean {
    let admin= (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.ADMIN_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.ADMIN_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_EDIT) != undefined);
      return admin;
  }
  BUCHHALTUNG(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 7 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_EDIT)) != undefined;
  }
  VERKAUF(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 9 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.DOCUMENT_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_EDIT)) != undefined;
  }
  WERKSTATT(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 8 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.CUSTOMER_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.WERKSTATT_VIEW)) != undefined;

  }
  MECHANIKER(): boolean {
    return (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.ERSATZTEILE_VIEW)) != undefined;
  }

  FAHRZEUGANLIEFERUNG(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 4 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.FAHRZEUGANLIEFERUNG_VIEW)) != undefined
  }

  PROBEFAHRT(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 5 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TEST_DRIVE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING)) != undefined
  }

  EMPFANG(): boolean {
    let rolesLength = this.authenticatedRoles$.getValue().length;
    return rolesLength == 4 && (this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_VIEW) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.VEHICLE_EDIT) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.TICKETING) &&
           this.authenticatedRoles$.getValue().find(r => r.role_id === ROLES.EMPFANG_VIEW)) != undefined
  }

  accessVehicleMenu(): boolean {
    return this.permissions$.getValue().find((p) => p == "vehicle_menu") != undefined;
  }
  accessVehicleDetails(): boolean {
    return this.accessVehicleMenu() && this.permissions$.getValue().find((p) => p == "vehicle_view") != undefined;
  }

  accessVehicleEdit(): boolean {
    return this.accessVehicleDetails() && this.permissions$.getValue().find((p) => p == "vehicle_edit") != undefined;
  }

  accessTicketView(): boolean {
    return this.permissions$.getValue().find((p) => p == "ticket_view") != undefined;
  }

  accessTicketEdit(): boolean {
    return this.accessTicketView() && this.permissions$.getValue().find((p) => p == "ticket_edit") != undefined;
  }

  accessAccountingMenu(): boolean {
    return this.permissions$.getValue().find((p) => p == "accounting_menu") != undefined;
  }

  accessCustomBillEdit(): boolean {
    return this.permissions$.getValue().find((p) => p == "custom_bill_edit") != undefined;
  }

  accessVia(name: string): boolean { 
    return this.permissions$.getValue().find((p) => p == name) != undefined;
  }

  //access by roles
  //--> customers
  accessViaRoleCustomers(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT(); }
  accessViaRoleCustomersDetails(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT(); }
  accessViaRoleCustomersCreate(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT(); }
  accessViaRoleCustomersTickets(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT(); }
  //--> admin
  accessViaRoleAdmin(): boolean { return this.ADMIN(); }
  //--> tickets
  accessViaRoleTickets(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT() || this.MECHANIKER() || this.FAHRZEUGANLIEFERUNG() || this.PROBEFAHRT() || this.EMPFANG(); }
  accessViaRoleTicketsDetails(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT() || this.MECHANIKER() || this.FAHRZEUGANLIEFERUNG() || this.PROBEFAHRT() || this.EMPFANG();}
  accessViaRoleTicketsCreate(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF() || this.WERKSTATT() || this.MECHANIKER() || this.FAHRZEUGANLIEFERUNG() || this.PROBEFAHRT() || this.EMPFANG(); }
  //--> documents
  accessViaRoleDocuments(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF(); }
  accessViaRoleDocumentsDetails(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF(); }
  accessViaRoleDocumentsCreate(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF(); }
  accessViaRoleDocumentsTickets(): boolean { return this.ADMIN() || this.BUCHHALTUNG() || this.VERKAUF(); }
  //--> drivess
  accessViaRoleDrives(): boolean { return this.ADMIN() || this.VERKAUF() || this.WERKSTATT() || this.PROBEFAHRT(); }

  //access route path
  canActivate(next: ActivatedRouteSnapshot): boolean {
    let path = next.routeConfig.path;
    if(localStorage.getItem('token') && localStorage.getItem('roles') && localStorage.getItem('permissions')){
      if(path === 'cars') return this.accessVehicleMenu();

      else if(path === 'cars/:carId/:subMenuId') return this.accessVehicleDetails();
      else if(path === 'cars/create') return this.accessVehicleEdit();
      else if(path === 'cars/:carId/:subMenuId') return this.accessVehicleEdit();
      else if(path === 'cars/:vehicleId/:threadId') return this.accessVehicleDetails() && this.accessTicketView();

      else if(path === 'accounting') return this.accessAccountingMenu();

      else if(path === 'customers') return this.accessViaRoleCustomers() == true;
      else if(path === 'customers/:customerId') return this.accessViaRoleCustomersDetails() == true;
      else if(path === 'customers/create/:typ') return this.accessViaRoleCustomersCreate() == true;
      else if(path === 'customers/edit/:customerId') return this.accessViaRoleCustomersDetails() == true;

      else if(path === 'admin') return this.accessViaRoleAdmin() == true;

      else if(path === 'messages') return this.accessVia('ticket_menu');
      else if(path === 'messages/create') return this.accessVia('ticket_edit');
      else if(path === 'messages/:threadId') return this.accessVia('ticket_view');
      else if(path === 'messages/:threadId/:vehicleId') return this.accessVia('ticket_view') && this.accessVia('vehicle_view');

      else if(path === 'documents') return this.accessViaRoleDocuments() == true && this.accessVia('document_menu');
      else if(path === 'documents/create/:typ/:customerId') this.accessViaRoleDocuments() == true && this.accessVia('document_menu');
      else if(path === 'documents/edit/:documentId') this.accessViaRoleDocuments() == true && this.accessVia('document_menu');
      else if(path === 'documents/:documentId') this.accessViaRoleDocuments() == true && this.accessVia('document_menu');

      else if(path === 'drives' || path === 'drives/create') return this.accessViaRoleDrives() == true  && this.accessVia('test_drive_menu');

      else if(path === 'interfaces') return false;

      else if(path === 'dashboard') return false;

      else if(path === 'material') return false;

      else if(path === 'tyres') return this.accessVia('tire_menu');

      else if(path === 'settings') return this.accessViaRoleAdmin() == true && this.accessVia('user_menu');

      else if(path === 'users') return this.accessViaRoleAdmin() == true && this.accessVia('user_menu');

      else if(path === 'locations') return false;

      else if(path === 'rent') return this.accessViaRoleAdmin() && this.accessViaMandant('rent');

      else return false;
    } else {
      this.logout();
      return false;
    }
  }

  logout() {
    this.mandantId$.next(-1);
    this.authenticatedRoles$.next(null);
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    localStorage.removeItem('employee_id');
    localStorage.removeItem('location_id');
    localStorage.removeItem('mandant_id');
    localStorage.removeItem('roles');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem('menus');
    localStorage.removeItem('enums');
    this.router.navigate(['login']);
  }
}
