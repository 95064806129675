<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false">
  <h3 collapsible-header class="overview_data_box_lbl_title">Garantie</h3>
  <div collapsible-content>
    <div class="overview_data_box_vehicle" *ngIf="!editMode">
      <div class="overview_record">
        <label class="overview_record_lbl">Garantie enthalten</label>
        <div class="overview_record_value">{{vehicle.warrantyExists == true ? 'Ja' : (vehicle.warrantyExists == false ? 'Nein' : '---')}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Garantie</label>
        <div class="overview_record_value">{{vehicle.garantie_description ? (vehicle.garantie_description) : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="vehicle.warrantyExists">
        <label class="overview_record_lbl">Garantiedauer in Monaten</label>
        <div class="overview_record_value">{{vehicle.warrantyDuration ? (vehicle.warrantyDuration + ' Monate') : '---'}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Garantie bis</label>
        <div class="overview_record_value">{{vehicle.garantie_bis | timestampFormat}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Garantie Kilometer</label>
        <div class="overview_record_value">{{vehicle.garantie_km ? vehicle.garantie_km : '---'}}</div>
      </div>
    </div>
    <form [formGroup]="form" class="overview_data_form" *ngIf="editMode">
      <app-dropdown-simple
        [title]="'Garantie enthalten'"
        [formcontrol]="f.warrantyExists"
        (submitValueEmitter)="f.warrantyExists.setValue($event)"
      ></app-dropdown-simple>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Garantie</label>
        <input type="text" class="overview_input" placeholder="Garantie" formControlName="garantie_description">
      </div>
      <div class="input_wrapper" *ngIf="f.warrantyExists.value == true">
        <label class="overview_record_lbl">Garantiedauer in Monaten</label>
        <input type="number" class="overview_input" placeholder="Garantiedauer" formControlName="warrantyDuration" (ngModel)="f.warrantyDuration.value">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Garantie bis</label>
        <input #inputDate4 type="date" [min]="minDate" formControlName="garantie_bis" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && f.garantie_bis.errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Garantie Kilometer</label>
        <input type="number" class="overview_input" placeholder="KM" min="0" step="1" formControlName="garantie_km">
      </div>
    </form>
  </div>
</app-collapsible>
