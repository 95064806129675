import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/buisness-object/location/Location';
import { AuthGuard } from 'src/app/helpers/AuthGuard';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { LoginService } from 'src/app/service/login/login.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ReloadDataHandler } from 'src/app/utils/ReloadDataHandler';
import { environment } from 'src/environments/environment';

const menuMap = {
  '/dashboard': 1,
  '/cars': 2,
  '/customers': 3,
  '/interfaces': 4,
  '/documents': 5,
  '/advertising': 6,
  '/admin': 7,
  '/material': 8,
  '/messages': 9,
  '/drives': 10,
  '/accounting': 11,
  '/tyres': 12
};

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public selectedMenue = 2;
  public authGuard = new AuthGuard();
  public subRout: Subscription;
  public accessCustomers = false;
  public accessAdmin = false;
  public accessMessages = false;
  public accessCars = false;
  public accessDocuments = false;
  public accessDrives = false;
  public accessApi = false;
  public accessAccounting = false;
  public dialogQuerySubsription: Subscription;
  public dialogChangePasswordSubscription: Subscription;
  public routerSub: Subscription;
  public versionsNr: string;
  public showActions = false;
  public showActions2 = false;
  public locations: Location[] = [];
  public location: Location;
  public location_id = Number(localStorage.getItem('location_id'));
  public userAuth = Number(localStorage.getItem('auth'));
  public user = JSON.parse(localStorage.getItem('user'));

  constructor(
    private loginService: LoginService,
    private router: Router,
    private dService: DialogService,
    private tService: ThreadService,
    private locationService: LocationService,
    public authService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private vService: VehicleService
  ) { }

  ngOnInit(): void {
    this.setMenue(this.router.url);
    this.accessCustomers = this.authGuard.hasAccess(3);
    this.accessAdmin = this.authGuard.hasAccess(1);
    this.accessMessages = this.authGuard.hasAccess(7);
    this.accessCars = this.authGuard.hasAccess(2);
    this.accessDocuments = this.authGuard.hasAccess(8);
    this.accessDrives = this.authGuard.hasAccess(10);
    this.accessAccounting = this.authGuard.hasAccess(11);
    this.accessApi = this.userAuth == 1 || this.userAuth == 2 || this.userAuth == 10;
    if(this.userAuth == 10) this.selectedMenue = 4;
    this.setDialogSubscription();
    this.setRouteListener();
    this.versionsNr = environment.VERSION;

    this.tService.getOpenThreads().subscribe((result) => {});
      this.locationService.getLocations(true).subscribe((result) => {
        this.locations = result;
        let i = this.locations.findIndex(l => l.locationId == this.location_id);
        if(i > -1) this.location = this.locations[i];
      });
  }

  ngOnDestroy(): void {
    if(this.subRout) this.subRout.unsubscribe();
    if(this.routerSub) this.routerSub.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogChangePasswordSubscription) this.dialogChangePasswordSubscription.unsubscribe();
  }

  setRouteListener() {
    this.routerSub = this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        this.setMenue(event.url);
      }
    })
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value && value.typ == 'logout') this.logout();
      else if(value && value.typ == 'cancel_create'){
        this.reloadRouter(value.submit_value);
      }
    });
    this.dialogChangePasswordSubscription = this.dService.closeDialogPassword$.subscribe((value) => {
      if(value) this.changePassword(value.new_password);
    })
  }

  logout() {
    new ReloadDataHandler().deactivateReloadLoop([this.vService]);
    this.loginService.logout();
  }

  setMenue(url: string) {
    this.selectedMenue = 0;
    for(const [path, menu] of Object.entries(menuMap)) {
      if(url.includes(path)) {
        this.selectedMenue = menu;
        break;
      }
    }
    if(url.includes('/rent')){
      this.selectedMenue = 13;
    }
  }

  reload(page: string) {
    this.reloadRouter(page);
    // if(GlobalVariables.is_create_vehicle_process){
    //   this.dService.openQuery(
    //     {
    //       title: 'Vorgang abbrechen',
    //       message: 'Sind Sie sicher, dass Sie den Vorgang abbrechen möchten? Ihr Fahrzeug wird dann nicht gespeichert.',
    //       btn_cancel_txt: 'zurück',
    //       btn_submit_txt: 'Ja, abbrechen',
    //       typ: 'cancel_create',
    //       submit_value: page,
    //     }
    //   );
    // } else {
    //   this.reloadRouter(page);
    // }
  }

  reloadRouter(page: string) {
    if(this.router.url == page || this.router.url.includes(page)){
      this.router.navigateByUrl('/login', {skipLocationChange: true}).then(() => this.router.navigate([page]));
    } else {
      var url = this.router.createUrlTree([], { relativeTo: this.activateRoute }).toString();
      this.router.navigateByUrl(page);
      //this.router.navigate([page]);
    }
  }

  getIcon(menu: number): string {
    switch (menu) {
      case 1: if(menu == this.selectedMenue){ return '../../../../assets/dashboard-active.png'; } return '../../../../assets/dashboard.png'; break;
      case 2: if(menu == this.selectedMenue){ return '../../../../assets/carmenuactive.svg'; } return '../../../../assets/carmenu.svg'; break;
      case 3: if(menu == this.selectedMenue){ return '../../../../assets/user-lila.svg'; } return '../../../../assets/user.svg'; break;
      case 4: if(menu == this.selectedMenue){ return '../../../../assets/interfaces-active.png'; } return '../../../../assets/interfaces.png'; break;
      case 5: if(menu == this.selectedMenue){ return '../../../../assets/document-menu-blue.svg'; } return '../../../../assets/document-menu.svg'; break;
      case 6: if(menu == this.selectedMenue){ return '../../../../assets/advertising-active.png'; } return '../../../../assets/advertising.png'; break;
      case 7: if(menu == this.selectedMenue){ return '../../../../assets/administratives-lila.svg'; } return '../../../../assets/administratives.svg'; break;
      case 8: if(menu == this.selectedMenue){ return '../../../../assets/material.png'; } return '../../../../assets/material.png'; break;
      case 9: if(menu == this.selectedMenue){ return '../../../../assets/nachrichten-lila.svg'; } return '../../../../assets/nachrichten.svg'; break;
      case 10: if(menu == this.selectedMenue){ return '../../../../assets/calenderactive.svg'; } return '../../../../assets/calender.svg'; break;
      case 11: if(menu == this.selectedMenue){ return './assets/billingactive.svg'; } return './assets/billing.svg'; break;
      case 12: if(menu == this.selectedMenue){ return './assets/tireactive.svg'; } return './assets/tire.svg'; break;
      case 13: if(menu == this.selectedMenue){ return './assets/rentactive.svg'; } return './assets/rent.svg'; break;
      default: break;
    }
  }

  logoutAction() {
    this.dService.openQuery(
      {
        title: 'Abmelden ?',
        message: '',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Ja',
        typ: 'logout',
        submit_value: null,
      }
    );
  }

  changePasswortAction() {
    this.dService.openPasswordDialog(null)
  }

  changePassword(newPassword: string) {

  }

  openWebsite() {
    window.open('http://auto-base.at/', '_blank').focus();
  }

  getNotify(): number {
    return this.tService.notify_request$.getValue();
  }

  openMail() {
    var email = document.createElement("a");
    email.href = "mailto:support@waymark.at";
    email.click();
  }
}
