import { Role } from "../Role";

export class RoleFactory {
    static jsonFactory(rawBody: any): Role[] {
        const roles: Role[] = [];
        for(let role of rawBody){
            roles.push(this.jsonFactoryOne(role));
        }
        return roles;
    } 

    static jsonFactoryOne(rawRole: any): Role {
        const role = new Role(
            rawRole.role_id,
            rawRole.role
        )
        return role;
    }
}