<div class="header-base-style">
  <div class="header-inner-wrapper">
    <button class="button-icon-start" (click)="selectionBack()">
      <img class="button-icon-start-img" src="./assets/back.svg"/>
      <span class="button-icon-start-span">Zurück</span>
    </button>
    <h2>Rechnung #{{obj.bill_id}}</h2>
  </div>
  <div class="header-button-wrapper">
    <button class="button_type_2" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Dokumente
      <img src="./assets/dropdown-arrow-blue.svg">
      <div *ngIf="shwoActions">
        <button (click)="null">Action 1</button>
        <button (click)="null">Action 1</button>
      </div>
    </button>
  </div>
</div>

<div class="body-base-style">
  <div class="btn-tab-wrapper">
    <button class="btn-tab btn-tab-first" [class.btn-tab-active]="showTab == tabView.Details" (click)="null">Details</button>
    <button *ngIf="false" class="btn-tab btn-last" [class.btn-tab-active]="showTab == tabView.Tickets" (click)="null">Tickets</button>
  </div>
  <div id="kaufvertrag-details-body" *ngIf="showTab == tabView.Details">
    <div id="header-column" class="overview_data_box_vertical">
      <div class="overview_record_vertical">
        <h3>Betreff</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.subject"></label>
          <label class="lbl_italic">RE-Nr. {{obj.bill_id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Kunde</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.customer?.getFullName()"></label>
          <label class="lbl_italic">Kunden-ID: {{obj.customer.id}}</label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Austellungsdatum</h3>
        <div>
          <label [appFormatValue]="'date'" [value]="obj.bill_date"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Bezahl-Status</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="billStatus" [ngClass]="billStatusStyle"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Buchhaltung</h3>
        <div>
          <label [appFormatValue]="'string'" [value]="obj.in_accounting ? 'Übermittelt' : 'Nicht übermittelt'" [ngClass]="obj.in_accounting ? 'lbl_color_wrapper_green' : 'lbl_color_wrapper_gray'"></label>
        </div>
      </div>
      <div class="overview_record_vertical">
        <h3>Gesamtsumme</h3>
        <div>
          <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          <label class="lbl_italic">inkl. MwSt.</label>
        </div>
      </div>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Rechnungsübersicht</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Gesamtbetrag (Netto)</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_netto"></label>
          </div>
          <div class="overview_record">
            <label>Steuer 10%</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Steuer 20%</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Rabatte gesamt</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Gesamtbetrag</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Gesamtbetrag (Brutton)</label>
            <label [appFormatValue]="'currency'" [value]="obj.total_brutto"></label>
          </div>
          <div class="overview_record">
            <label>Zahlungsart</label>
            <label [appFormatValue]="'string'" [value]="paymentConditionValue"></label>
          </div>
        </div>
      </app-collapsible>
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Rabatte</h3>
        <div collapsible-content>
          <div class="overview_record">
            <label>Marke</label>
            <label [appFormatValue]="'string'" [value]=""></label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible] *ngIf="false">
        <h3 collapsible-header>Rechnungspositionen (Netto)</h3>
        <div collapsible-content>
          <div class="bill-position-wrapper" *ngFor="let position of obj.bill_items; let i = index;">
            <label>POS {{i+1}}</label>
            <label>{{position.quantity}}x</label>
            <label>{{position.title}}</label>
            <label [appFormatValue]="'currency'" [value]="position.price"></label>
          </div>
        </div>
      </app-collapsible>
    </div>
    <div class="column">
      <app-collapsible [isCollapsible]>
        <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
        <div collapsible-content>
          <app-files
            [obj]="document"
          ></app-files>
        </div>
      </app-collapsible>
      <app-transactions
        [obj]="obj"
      ></app-transactions>
    </div>
  </div>
</div>
