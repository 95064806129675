<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false">
  <h3 collapsible-header>Zugehörige Dokumente/Anhänge</h3>
  <div collapsible-content>
    <app-files
      [obj]="vehicle"
    ></app-files>
  </div>
  <!-- <div collapsible-content>
    <label *ngIf="sources.length == 0" class="lbl_regular_italic_light_color">Keine Dateien hinterlegt</label>
    <app-attachment-element *ngFor="let source of sources"
      [source]="source"
      (deleteEmitter)="deleteAttachment($event)"
      (signPDFEmitter)="deleteAttachment($event)"
    ></app-attachment-element>
    <div *ngIf="editMode" id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)" (click)="selectFile_dokumente.click()">
      <div class="upload_box_info">
          <img src="../../../../../assets/upload.svg">
          <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen.</label>
          <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
      </div>
    </div> -->
    <!-- <div *ngIf="editMode">
      <div id="upload_container_attachments">
        <div id="upload_box" class="hover_change" appDnd (fileDroppedEmitter)="onFileDropped($event)">
          <div class="upload_box_info">
              <img src="../../../../../assets/upload.svg">
              <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen.</label>
              <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
          </div>
        </div>
        <input #selectFile_dokumente type="file" accept="image/x-png,image/jpeg, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
        <button class="btn_grey" (click)="selectFile_dokumente.click()" style="align-self: center;margin-bottom: 20px;">Dateien auswählen</button>
      </div>
    </div> -->
  <!-- </div> -->
</app-collapsible>
