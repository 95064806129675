<div id="files-list-wrapper">
  <app-file *ngFor="let attachment of attachments; let i = index"
    [attachment]="attachment"
    [index]="i"
    [isLast]="i == attachments.length-1"
    (successDeleteEmitter)="successDelete($event)"
  ></app-file>
</div>
<app-dropzone
  [object]="obj"
  (successCreateEmitter)="successCreate($event)"
></app-dropzone>
