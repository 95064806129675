import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Attachment, AttachmentHelper } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrl: './files.component.css'
})
export class FilesComponent implements OnInit {
  @Input() obj: any;
  @Output() successDeleteEmitter = new EventEmitter<any>();
  public attachments: Attachment[] = [];

  constructor(
    private attachmentService: AttachmentService,
  ){}

  ngOnInit(): void {
    this.setUp();
  }

  ngOnChanges(changes: SimpleChange): void {
    if(changes['obj'] &&
      changes['obj'].currentValue != changes['obj'].previousValue
    ){
      this.setUp();
    }
  }

  setUp() {
    if(this.obj){
      let typ =  AttachmentHelper.getAttachmentTyp(this.obj);
      let typID =  AttachmentHelper.getAttachmentTypID(this.obj);
      if(typ && typID){
        this.attachmentService.getAttachmentsByTypId(typ, typID).subscribe((result) => {
          if(result){
            this.attachments = result;
          }
        })
      }
    }
  }

  successCreate(attachment: Attachment) {
    this.attachments.push(attachment);
  }

  successDelete(attachment: Attachment) {
    let index = this.attachments.findIndex((a) => a.attachment_id == attachment.attachment_id);
    if(index > -1){
      this.attachments.splice(index,1);
    }
  }
}
