import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Bill } from 'src/app/buisness-object/document/Bill';
import { BillPurchaseContractForm } from 'src/app/buisness-object/document/form/BillPurchaseContractForm';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { EnumHelper } from 'src/app/helpers/EnumHelper';
import { Steuersatz } from 'src/app/helpers/Enums';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DocumentService } from 'src/app/service/document/document.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { countries } from '../../BaseComponent';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-bill-edit-purchase-contract',
  templateUrl: './bill-edit-purchase-contract.component.html',
  styleUrl: './bill-edit-purchase-contract.component.css'
})
export class BillEditPurchaseContractComponent implements OnInit {
  @Input() obj: Bill;
  @Output() selectionCancelEmitter = new EventEmitter<undefined>();
  @Output() successUpdateEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public steuerseatze;
  public salutationValues;
  public titleValues;
  public submitted = false;
  public countries = countries;
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];

  constructor(
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    public authService: AuthenticationService,
    private documentService: DocumentService,
    private vehicleService: VehicleService,
    private dialogService: DialogService,
    private customerService: CustomerService
  ){}

  ngOnInit(): void {
    this.steuerseatze = this.enumService.steuerseatze;
    this.salutationValues = this.enumService.getEnumValues('salutation');
    this.titleValues = this.enumService.getEnumValues('title');
    this.form = BillPurchaseContractForm.getForm(this.formBuilder, this.obj);
  }

  get customerForm() {
    return this.form.get('customer_form').value;
  }

  getSteuersatz(value): string {
    let str = new EnumHelper().getEnumValue(Steuersatz,value-1);
    return str ? str : '---';
  }

  selectionCancel() {
    this.selectionCancelEmitter.emit();
  }

  selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    if(this.form.get('customer_form').value.invalid){
      ErrorHelper.showFormError(this.form.get('customer_form').value);
      return;
    }
    let obj = BillPurchaseContractForm.getObject(this.form, this.obj);
    const savedCustomer = CustomerForm.createObject(this.form.get('customer_form').value);
    const savedVehicle = this.obj.purchase_contract.vehicle;
    savedVehicle.co2Emission = this.form.get('vehicle_co2').value;
    savedVehicle.priceSheet.steuersatz = this.form.get('steuersatz').value;
    savedVehicle.priceSheet.vehicle_imported = this.form.get('vehicle_imported').value;
    obj.bill_recipient = savedCustomer;
    if(this.obj.saved_bill_recipient_id != null) {
      obj.saved_bill_recipient_id = this.obj.saved_bill_recipient_id;
    }
    this.documentService.updateBill(obj).subscribe((result) => {
      if(result){
        this.vehicleService.updateSavedVehicle(savedVehicle,obj.purchase_contract.saved_vehicle_id).subscribe((savedVehicle) => {
          result.purchase_contract.vehicle = savedVehicle;
          this.dialogService.showNotification({
            title: 'Erfolgreich',
            message: 'Rechnung aktualisiert.',
            success: true
          });
          this.successUpdateEmitter.emit(result);
        });

      }
    })
  }
}
