import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Component, OnInit, OnDestroy, Input, Output, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { UntypedFormGroup } from '@angular/forms';
import { DropdownHighlighter } from 'src/app/buisness-object/dropdown/DropdownHighlighter';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-customer-create-car',
  templateUrl: './customer-create-car.component.html',
  styleUrls: ['./customer-create-car.component.css']
})
export class CustomerCreateCarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  public vehicles: Vehicle[] = [];
  public vehiclesFiltered: Vehicle[] = [];
  public brands: VehicleBrand[] = [];
  public models: VehicleModel[] = [];
  public drives: VehicleDrive[] = [];
  public fuels: VehicleFuel[] = [];
  public bodyColors: VehicleBodyColor[] = [];
  public bodyTyps: VehicleBodyTyp[] = [];
  public categories: VehicleCategory[] = [];
  public transmissions: VehicleTransmission[] = [];
  public inputTimeout: any;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public showDropdownFour = false;
  public showDropdownFive = false;
  public showDropdownSix = false;
  public showDropdownSeven = false;
  public showDropdownEight = false;
  public showDropdownNine = false;
  public showDropdownTen = false;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownThree') dropdownThree: ElementRef;
  @ViewChild('dropdownFour') dropdownFour: ElementRef;
  @ViewChild('dropdownFive') dropdownFive: ElementRef;
  @ViewChild('dropdownSix') dropdownSix: ElementRef;
  @ViewChild('dropdownSeven') dropdownSeven: ElementRef;
  @ViewChild('dropdownEight') dropdownEight: ElementRef;
  @ViewChild('dropdownNine') dropdownNine: ElementRef;
  @ViewChild('dropdownTen') dropdownTen: ElementRef;
  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  public listenerOnClick: () => void;
  public listenerOnKeydown: () => void;
  public highlighter: DropdownHighlighter = new DropdownHighlighter();
  public keyDownItem: HTMLElement;
  public counterVersion = 80;
  public maxDate = new Date();
  public minDate = new Date(0);
  private dialogQuerySubsription: Subscription;

  constructor(
    private vService: VehicleService,
    private renderer: Renderer2,
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {
    this.listenerOnClick = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdownOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.dropdownTwo.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      if(this.showDropdownThree && !this.dropdownThree.nativeElement.contains(e.target)) this.showDropdownThree = false;
      if(this.showDropdownFour && !this.dropdownFour.nativeElement.contains(e.target)) this.showDropdownFour = false;
      if(this.showDropdownFive && !this.dropdownFive.nativeElement.contains(e.target)) this.showDropdownFive = false;
      if(this.showDropdownSix && !this.dropdownSix.nativeElement.contains(e.target)) this.showDropdownSix = false;
      if(this.showDropdownSeven && !this.dropdownSeven.nativeElement.contains(e.target)) this.showDropdownSeven = false;
      if(this.showDropdownEight && !this.dropdownEight.nativeElement.contains(e.target)) this.showDropdownEight = false;
      if(this.showDropdownNine && !this.dropdownNine.nativeElement.contains(e.target)) this.showDropdownNine = false;
      if(this.showDropdownTen && !this.dropdownTen.nativeElement.contains(e.target)) this.showDropdownTen = false;
    })
    this.listenerOnKeydown = this.renderer.listen('window','keydown',(e: KeyboardEvent) => {
      if(this.showDropdownOne) this.highlightElement(this.brands, e.key);
    })
  }

  ngOnDestroy(): void {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    if(this.listenerOnClick) this.listenerOnClick();
    if(this.listenerOnKeydown) this.listenerOnKeydown();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'customer_car_create_detail') this.goToCreateVehicle();
      }
    });
  }

  onMouseEnterDropdown() {
    if(this.keyDownItem){
      this.keyDownItem.className = 'dropdown_data_body_element';
      this.keyDownItem = null;
    }
  }

  highlightElement(dropdownValues: any[], key: string) {
    for(let value of dropdownValues){
      if(value.makeName[0].toLowerCase() == key){
        if(this.keyDownItem){
          this.keyDownItem.className = 'dropdown_data_body_element';
        }
        this.keyDownItem = document.getElementById('dropdown_value_' + value.id);
        if(this.keyDownItem){
          this.keyDownItem.className ='dropdown_data_body_element_hover_status';
          this.keyDownItem.scrollIntoView({block: "start"});
        }
        break;
      }
    }
  }

  getDataServerside() {
    forkJoin({
      one: this.vService.getVehicle(),
      two: this.vService.getBrands(),
      three: this.vService.getDrives(),
      four: this.vService.getBodyColor(),
      five: this.vService.getFuels(),
      six: this.vService.getCategories(),
      seven: this.vService.getTransmissions(),
      eight: this.vService.getBodyTyps(),
    }).subscribe((result) => {
      if(result){
        for(let v of result.one){
          if(!v.customer_id) this.vehicles.push(v);
        }
        this.sortVehicles();
        this.brands = result.two;
        this.sortBrands();
        this.drives = result.three;
        this.bodyColors = result.four;
        this.fuels = result.five;
        this.categories = result.six;
        this.transmissions = result.seven;
        this.bodyTyps = result.eight;
        this.vehiclesFiltered = this.vehicles;
      }
    })
  }

  sortVehicles() {
    this.vehicles.sort((a,b) => {
      if(a.brand.makeName > b.brand.makeName) return 1;
      if(a.brand.makeName < b.brand.makeName) return -1;
      return 0;
    })
  }

  sortBrands() {
    this.brands.sort((a,b) => {
      if(a.makeName > b.makeName) return 1;
      if(a.makeName < b.makeName) return -1;
      return 0;
    })
  }

  filtervehicles(value: string) {
    this.form.controls.vehicle.setValue(null);
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      if(value && value.length > 0){
        this.vehiclesFiltered = [];
        this.vehiclesFiltered = this.vehicles.filter((vehicle) => {
          return vehicle.brand.makeName.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.model.name.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.model.version.toLowerCase().includes(value.toLowerCase()) ||
          vehicle.fin_number?.toLowerCase().includes(value.toLowerCase()) ||
          //this.authService.isSinhuber() ? vehicle.vehicle_serialnumber?.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase()) ||
          (this.authService.ACCESS_ALL() && vehicle.externalId ? vehicle.externalId.toLowerCase().includes(value.toLowerCase()) : vehicle.id.toString().toLowerCase().includes(value.toLowerCase())) ||
          (vehicle.brand.makeName.toLowerCase() + ' ' + vehicle.model.name.toLowerCase() + ' ' + vehicle.model.version.toLowerCase()).includes(value.toLowerCase());
        })
      } else {
        this.vehiclesFiltered = this.vehicles;
      }
    }, 300);
  }

  changePowervalue() {
    if(this.form.controls.powerTyp.value == 1) this.form.controls.powerTyp.setValue(2);
    else if(this.form.controls.powerTyp.value == 2) this.form.controls.powerTyp.setValue(1);
  }

  setBrand(brand: VehicleBrand) {
    this.form.controls.brand.setValue(brand);
    this.models = brand.models;
    this.form.controls.model.setValue(null);
  }

  setVersionTitle() {
    let count = 80 - this.form.controls.version?.value.length;
    this.counterVersion = count >= 0 ? count : 0;
    if(this.counterVersion >= 0){
      this.counterVersion = 80 - this.form.controls.version?.value.length;
    }
  }

  setVehicle(vehicle: Vehicle) {
    this.form.controls.vehicle.setValue(vehicle);
    this.input_vehicle.nativeElement.value = vehicle.getDescription(35);
    this.submitted = false;
  }

  setCategory(cat: VehicleCategory) {
    this.form.controls.category.setValue(cat);
    this.calcRegistrationDate()
  }

  calcRegistrationDate() {
    if(this.form.controls.category.value.id == 1){
      this.form.controls.firstRegistrationDate.setValue(null);
    } else if(this.form.controls.category.value.id == 6){
      this.minDate = new Date();
      this.minDate.setMonth(this.maxDate.getMonth() - 12);
    } else if(this.form.controls.category.value.id == 3) {
      this.minDate = new Date();
      this.minDate.setMonth(this.maxDate.getMonth() - 24);
    } else {
      this.minDate = new Date(0);
    }
  }

  goToCreateVehicleAction() {
    this.dialogService.openQuery(
      {
        title: 'Zu "Fahrzeug anlegen" navigieren',
        message: 'Der Vorang wird abgebrochen und die Daten gehen verloren.',
        btn_cancel_txt: 'Nein',
        btn_submit_txt: 'Ja',
        typ: 'customer_car_create_detail',
        submit_value: null,
      }
    );
  }
  goToCreateVehicle() {
    this.router.navigate(['cars/create']);
  }
}
