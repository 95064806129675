import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { MainMenu } from 'src/app/buisness-object/menu/MainMenu';
import { SubMenu } from 'src/app/buisness-object/menu/SubMenu';
import { OverviewConfiguration } from 'src/app/buisness-object/overview/OverviewConfiguration';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { OpenVehicleFilterAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
  animations: [OpenVehicleFilterAnimation]
})
export class TopbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() surface: number;
  @Input() selectedDetailsTab: number;
  @Input() accessEdit: boolean;
  @Input() vinFlag: boolean;
  @Input() isNewPriceSheet: boolean;
  @Input() currentPageCreate: number;
  @Input() surfacePriceSheet: number;
  @Input() vehicleTitle: string;
  @Input() vehicleSubTitle: string;
  @Input() initialMenuName: string;
  @Input() filter: number;
  @Input() searchValue: string;
  @Input() configurations: OverviewConfiguration[];
  @Input() selectedConfiguration: OverviewConfiguration;
  @Input() editMode: boolean;
  @Input() vehicles: Vehicle[];
  @Input() filterForm: UntypedFormGroup;
  @Input() sortObject: any;
  @Input() selectedVehicle: Vehicle;
  @Input() editTyp: number;
  @Input() mainMenus: MainMenu[];
  @Input() employees: Employee[];
  @Input() activeFilter: any[];
  @Input() activeSubMenu$: Observable<SubMenu>;
  @Output() showCreateEmitter = new EventEmitter<number>();
  @Output() cancelCreateEmitter = new EventEmitter<undefined>();
  @Output() cancelEditEmitter = new EventEmitter<undefined>();
  @Output() updateVehicleEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<undefined>();
  @Output() filterEmitter = new EventEmitter<number>();
  @Output() changeConfigEmitter = new EventEmitter<OverviewConfiguration>();
  @Output() editEmitter = new EventEmitter<boolean>();
  @Output() saveAsDraftEmitter = new EventEmitter<boolean>();
  @Output() closeDetailsEmitter = new EventEmitter<undefined>();
  @Output() filterDetailEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  @Output() sortEmitter = new EventEmitter<undefined>();
  @Output() resetSortEmitter = new EventEmitter<undefined>();
  @Output() selectionKalkulation = new EventEmitter<number>();
  @Output() selectionPriceSheetCancel = new EventEmitter<undefined>();
  @Output() selectionPriceSheetSave = new EventEmitter<number>();
  @Output() selectionEditVehicle = new EventEmitter<boolean>();
  @Output() selectionEditDescrtiption = new EventEmitter<undefined>();
  @Output() selectionPrintPreisblatt = new EventEmitter<undefined>();
  @Output() customFilterEmitter = new EventEmitter<any>();
  @Output() selectionEtikettenDruckEmitter = new EventEmitter<undefined>();
  @Output() printPdfEmitter = new EventEmitter<any>();
  public showSort = false;
  public showFilter = false;
  public showCustomFilter = false;
  public shwoActions = false;
  public customFilterVisible = false;
  public showDropdown_1 = false;
  public showDropdown_2 = false;
  public showDropdown_3 = false;
  public showEditDescription = false;
  public showEdit = false;
  public showMainMenuAsDropdown = false;
  public menuWidthOpen;
  public resizeMenuTimeout: any;
  @ViewChild('filterDiv') filterDiv: ElementRef;
  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @HostListener('document:keydown.escape', ['$event'])onEscHandler(event: KeyboardEvent) {
    this.cancelCreateEmitter.emit();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if(this.resizeMenuTimeout) clearTimeout(this.resizeMenuTimeout);
    this.resizeMenuTimeout = setTimeout(() => {
      this.calcMenuStyle();
    }, 300);
  }

  private searchTimeout: any;

  constructor(
    private router: Router,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.activeSubMenu$.subscribe((active) => {
      if(active) {
        this.setEditVisible(active);
        this.setEditDescriptionVisible(active);
        this.customFilterVisible = active.filterable && active.filterable.filter((a) => { a.position == "INSIDE"}).length > 0;
      }
    });
    // this.initialMenuName = this.mainMenus.find((m) => m.getActive() == true)?.name;
  }

  setEditVisible(active: SubMenu) {
    let auths = this.authService.getViewAuth().filter((auth) => auth.id == active.sub_menu_id);
    if(auths.length > 0) {
      let includesDescription = false;
      for(let screen of auths[0].screens) {
        if(screen.edit.length > 0) {
          includesDescription = true;
          break;
        }
      }
      this.showEdit = includesDescription;
    } else {
      this.showEdit = true;
    }
  }

  setEditDescriptionVisible(active: SubMenu) {
    let auths = this.authService.getViewAuth().filter((auth) => auth.id == active.sub_menu_id);
    if(auths.length > 0) {
      let includesDescription = false;
      for(let screen of auths[0].screens) {
        if(screen.edit.filter((e) => e.name == 'description').length > 0 ) {
          includesDescription = true;
          break;
        }
      }
      this.showEditDescription = includesDescription;
    } else {
      this.showEditDescription = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calcMenuStyle();
    }, 600);
  }

  ngOnDestroy(): void {
    if(this.resizeMenuTimeout) clearTimeout(this.resizeMenuTimeout);
  }

  calcMenuStyle() {
    const pageMenuBar = document.getElementById('page-menu-bar');
    let gridWidth;
    let thresholdWidth;
    const thresholdPercentage = 70; //%
    if(pageMenuBar){
      gridWidth = pageMenuBar.offsetWidth;
      thresholdWidth = gridWidth * (thresholdPercentage/100);
    }
    const menusDropdown = document.getElementById('main-menu-wrapper-dropdown');
    const menus = document.getElementById('main-menu-wrapper');
    if(pageMenuBar && menus){
      const menusWidth = menus.offsetWidth;
      if(menusWidth > thresholdWidth){
        this.menuWidthOpen = menusWidth;
        this.showMainMenuAsDropdown = true;
      } else this.showMainMenuAsDropdown = false;
    } else if(pageMenuBar && menusDropdown) {
      if(this.showMainMenuAsDropdown){
        if(this.menuWidthOpen < thresholdWidth) this.showMainMenuAsDropdown = false;
      }
    }
  }

  searchChanged() {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.searchEmitter.emit();
      }, 600);
  }

  cancelEdit() {
    this.cancelEditEmitter.emit();
  }

  updateVehicle() {
    this.updateVehicleEmitter.emit();
  }

  goToCreateDocument(typ) {
    this.router.navigate(['documents/create'], { queryParams: { typ: typ, vehicleId: this.selectedVehicle.id } });
  }
}

