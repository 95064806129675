import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css'],
  animations: [DropdownIconArrowAnimation]
})
export class PriceComponent extends BaseComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() formGroupVehicle: FormGroup;
  @Input() submitted: boolean;
  @Input() editMode: boolean;
  @Input() activeSubMenu: any;
  @Input() superGroup: any;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public showDropdownThree = false;
  public resctricted = localStorage.getItem("location_id") == "13" ? true : false;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
  }

  showValue(value: string): boolean {
    let show = this.showValueBase(this.authService, this.activeSubMenu, this.superGroup, "price", this.editMode, value);
    return show;
  }

  get f() {
    return this.formGroupVehicle.controls;
  }
}
