import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment, AttachmentHelper } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrl: './dropzone.component.css'
})
export class DropzoneComponent implements OnInit {
  @Input() object: any;
  @Output() successCreateEmitter = new EventEmitter<any>();
  public onloadSrc = [];
  public sources: {
    name: string,
    format: string,
    data: string
  }[] = [];
  public filesAmount;

  constructor(
    private attachmentService: AttachmentService
  ){}

  ngOnInit(): void {

  }

  async onFileDropped(data: any) {
    if(!data) return;
    for(let file of data.data){
      let a = new Attachment(
        0,
        AttachmentHelper.getAttachmentTyp(this.object),
        AttachmentHelper.getAttachmentTypID(this.object),
        file.name.split('.')[1],
        file.name.split('.')[0],
        file.content.split(',')[1]
      )
      this.uploadAttachment(a);
    }
  }

  async uploadFile(event: any) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
         await this.readFile(event.target.files[i])
      }
      event.target.value = '';
    }
  }
  async readFile(file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        let a = new Attachment(
          0,
          AttachmentHelper.getAttachmentTyp(this.object),
          AttachmentHelper.getAttachmentTypID(this.object),
          file.name.split('.')[1],
          file.name.split('.')[0],
          event.target.result.split(',')[1]
        )
        this.uploadAttachment(a);
        resolve(true)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  uploadAttachment(attachment: Attachment) {
    this.attachmentService.createAttachment(attachment).subscribe((result) => {
      if(result){
        this.successCreateEmitter.emit(result);
      }
    })
  }
}
