import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Bill } from "../Bill";
import { BillItem } from "../BillItem";
import { group } from "console";
import { from } from "rxjs";

export class BillForm {

  public static getForm(formBuilder: FormBuilder, obj?: Bill): FormGroup {
    let form = formBuilder.group({
      bill_id: [this.getFormValue(obj, 'bill_id')],
      customer: [this.getFormValue(obj, 'customer'), Validators.required],
      customer_form: [null, Validators.required],
      subject: [this.getFormValue(obj, 'subject'), Validators.required],
      payment_method: [this.getFormValue(obj, 'payment_method'), Validators.required],
      positions: [[this.getPositionForm(formBuilder)]],
    });
    return form;
  }

  static getPositionForm(formBuilder: FormBuilder, pos?: BillItem): FormGroup {
    return formBuilder.group({
      item_id: [this.getFormValue(pos, 'item_id')],
      title: [this.getFormValue(pos, 'title'), Validators.required],
      quantity: [this.getFormValue(pos, 'quantity'), Validators.required],
      price: [this.getFormValue(pos, 'price'), Validators.required],
      tax_rate: [this.getFormValue(pos, 'tax_rate'), Validators.required],
      discount: [this.getFormValue(pos, 'discount'), Validators.required],
      base_value: [this.getFormValue(pos, 'base_value'), Validators.required],
    });
  }

  static getFormValue(obj: any, key: string, defaultValue?: any) {
    if(obj && key){
      if(obj[key]) return obj[key];
    }
    return defaultValue != undefined ? defaultValue : null;
  }

  public static getObject(form: FormGroup): Bill {
    // let obj = new Bill(
    //   form.get('employee_id').value,
    //   form.get('username').value,
    //   form.get('firstName').value,
    //   form.get('lastName').value,
    //   form.get('email').value,
    //   form.get('location').value?.locationId,
    // );
    return null;
  }
}
