<div id="overview_container">
  <form *ngIf="form" id="grid-missing-data" [formGroup]="form" autocomplete="off">
      <label class="title">Fehlende Daten</label>
      <div class="column">
          <div id="data-package" class="data_package">
            <!-- <label class="overview_data_box_lbl_title">Titel</label> -->
            <app-dropdown-objects *ngIf="valuesInvalid.bodyTyp"
              [title]="'KFZ-Typ *'"
              [placeholder]="'KFZ-Typ'"
              [headervalue]="form.controls['bodyTyp'].value?.name"
              [formcontrol]="form.controls['bodyTyp']"
              [values]="bodytypValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('bodytyp', $event)"
            ></app-dropdown-objects>
            <app-dropdown-objects
              [title]="'Fahrzeugart *'"
              [placeholder]="'Fahrzeugart'"
              [headervalue]="form.controls['category'].value?.name"
              [formcontrol]="form.controls['category']"
              [values]="categorieValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('category', $event)"
            ></app-dropdown-objects>
            <app-dropdown-objects *ngIf="valuesInvalid.bodyColor"
              [title]="'Hauptfarbe *'"
              [placeholder]="'Hauptfarbe'"
              [headervalue]="form.controls['bodyColor'].value?.name"
              [formcontrol]="form.controls['bodyColor']"
              [values]="bodyColorValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('bodyColor', $event)"
            ></app-dropdown-objects>
            <app-dropdown-objects *ngIf="valuesInvalid.fuel"
              [title]="'Treibstoff *'"
              [placeholder]="'Treibstoff'"
              [headervalue]="form.controls['fuel'].value?.name"
              [formcontrol]="form.controls['fuel']"
              [values]="fuelsValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('fuel', $event)"
            ></app-dropdown-objects>
            <app-dropdown-objects *ngIf="valuesInvalid.drive"
              [title]="'Antriebsart *'"
              [placeholder]="'Antriebsart'"
              [headervalue]="form.controls['drive'].value?.name"
              [formcontrol]="form.controls['drive']"
              [values]="drivesValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('drive', $event)"
            ></app-dropdown-objects>
            <app-dropdown-objects *ngIf="valuesInvalid.transmission"
              [title]="'Getriebeart *'"
              [placeholder]="'Getriebeart'"
              [headervalue]="form.controls['transmission'].value?.name"
              [formcontrol]="form.controls['transmission']"
              [values]="transmissionsValues"
              [submitted]="submitted"
              (submitValueEmitter)="setValueFromDropdown('transmission', $event)"
            ></app-dropdown-objects>
            <div class="input_wrapper" *ngIf="valuesInvalid.mileageKm">
                <label class="overview_record_lbl">Kilometerstand *</label>
                <input type="number" placeholder="Kilometerstand" formControlName="mileageKm" [class.input_invalid]="submitted && form.controls['mileageKm'].errors">
            </div>
            <div class="input_wrapper" *ngIf="form.controls['category'].value?.id != 1">
              <label class="overview_record_lbl">Erstzulassung *</label>
              <input #inputDate1 type="date" placeholder="date" [min]="minDate" [max]="maxDate" formControlName="firstRegistrationDate" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && form.controls['firstRegistrationDate'].errors">
            </div>
            <div class="input_wrapper" *ngIf="valuesInvalid.numOfDoors">
              <label class="overview_record_lbl">Türen *</label>
              <input type="number" placeholder="Türen" formControlName="numOfDoors" [class.input_invalid]="submitted && form.controls['numOfDoors'].errors">
            </div>
            <div class="input_wrapper" *ngIf="valuesInvalid.numOfSeats">
                <div class="overview_record_lbl">Sitze *</div>
                <input type="number" placeholder="Sitze" formControlName="numOfSeats" [class.input_invalid]="submitted && form.controls['numOfSeats'].errors">
            </div>
            <div *ngIf="valuesInvalid.power">
              <div *ngIf="form.controls['powerTyp'].value == 1" class="input_wrapper">
                <div class="overview_record_lbl">Leistung (PS) *</div>
                <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && form.controls['power'].errors">
              </div>
              <div *ngIf="form.controls['powerTyp'].value == 2" class="input_wrapper">
                <div class="overview_record_lbl">Leistung (KW) *</div>
                <input type="number" placeholder="Leistung" formControlName="power" [class.input_invalid]="submitted && form.controls['power'].errors">
              </div>
            </div>
            <div *ngIf="false" class="input_wrapper">
              <label class="overview_record_lbl">Verkaufspreis in € *</label>
              <input type="number" placeholder="Verkaufspreis in €" formControlName="price" [class.input_invalid]="submitted && form.controls['price'].errors">
            </div>
            <div class="input_wrapper">
              <label class="overview_record_lbl">Einkaufspreis in € *</label>
              <input type="number" placeholder="Einkaufspreis in €" formControlName="einkaufspreis_pricesheet" [class.input_invalid]="submitted && form.controls['einkaufspreis_pricesheet'].errors">
            </div>
            <div *ngIf="authService.isHoedl()" class="input_wrapper">
              <label class="overview_record_lbl">Einkaufsdatum *</label>
              <input #inputDate2 type="date" placeholder="date" [max]="maxDate" formControlName="einkaufsdatum" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && form.controls['einkaufsdatum'].errors">
            </div>
            <app-dropdown-multiple *ngIf="!authService.isSinhuber()"
              [title]="'Steuersatz'"
              [formcontrol]="form.controls['steuersatz_pricesheet']"
              [headerText]="getSteuersatz(form.controls['steuersatz_pricesheet'].value)"
              [values]="steuerseatze.slice(0,2)"
              [submitted]="submitted"
              (submitValueEmitter)="setSteuersatz($event)"
            ></app-dropdown-multiple>
            <div *ngIf="authService.isHoedl()" class="input_wrapper">
              <label class="overview_record_lbl">Transportkosten in € *</label>
              <input type="number" placeholder="Transportkosten in €" formControlName="sprit" [class.input_invalid]="submitted && form.controls['sprit'].errors">
            </div>
            <div *ngIf="authService.isHoedl()" class="input_wrapper">
              <label class="overview_record_lbl">Handlingkosten in € *</label>
              <input type="number" placeholder="Handlingkosten in €" formControlName="lieferant_extern" [class.input_invalid]="submitted && form.controls['lieferant_extern'].errors">
            </div>
            <div *ngIf="authService.isHoedl()" class="input_wrapper">
              <div class="overview_record_lbl">Verkäufer *</div>
              <input type="text" placeholder="Verkäufer" formControlName="vehicle_supplier"  [class.input_invalid]="submitted && form.controls['vehicle_supplier'].errors">
            </div>
            <app-dropdown-simple *ngIf="authService.isHoedl()"
              [title]="'Import'"
              [formcontrol]="form.controls['vehicle_imported']"
              [isOptional]="false"
              (submitValueEmitter)="form.controls['vehicle_imported'].setValue($event)"
            ></app-dropdown-simple>
            <div *ngIf="authService.isSuperAdmin()" class="checkbox_container" (click)="form.controls['chatgpt_flag'].setValue(!form.controls['chatgpt_flag'].value)">
              <input type="checkbox" class="checkbox" name="equipment" [checked]="!form.controls['chatgpt_flag'].value">
              <label for="equipment" class="checkbox_lbl">ChatGPT Austattung</label>
            </div>
          </div>
          <button class="btn_submit_dark" type="submit" (click)="submit()">Erstellen</button>
      </div>
  </form>
</div>
