import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';

@Component({
  selector: 'app-list-action-container',
  templateUrl: './list-action-container.component.html',
  styleUrl: './list-action-container.component.css'
})
export class ListActionContainerComponent implements OnInit, AfterViewInit {
  @Input() actions: string[] = [];
  @Input() index: number;
  @Input() maxIndex: number;
  @Input() object: any;
  @Input() listItemId: string;
  @Input() isListView: boolean = true;
  @Output() selectionOpenDetailsEmitter = new EventEmitter<any>();
  @Output() selectionOpenDetailsEditEmitter = new EventEmitter<any>();
  @Output() selectionDeleteEmitter = new EventEmitter<any>();
  @Output() selectionMoveEmitter = new EventEmitter<any>();
  @Output() selectionPrintEmitter = new EventEmitter<any>();
  @Output() selectionEtikettEmitter = new EventEmitter<any>();
  @Output() selectionSellEmitter = new EventEmitter<any>();
  @Output() selectionDokumentEmitter = new EventEmitter<any>();
  @Output() selectionCopyEmitter = new EventEmitter<any>();
  @Output() selectionUploadDocumentEmitter = new EventEmitter<any>();
  @Output() selectionPseudoSignatureEmitter = new EventEmitter<any>();
  @Output() selectionCreateKaufvertragEmitter = new EventEmitter<any>();
  @Output() selectionArchivEmitter = new EventEmitter<any>();
  @Output() selectionDownloadEmitter = new EventEmitter<any>();
  @Output() selectionPDFCreateEmitter = new EventEmitter<any>();
  @Output() selectionPDFOpenEmitter = new EventEmitter<any>();
  @Output() selectionTestDrivePDFSignEmitter = new EventEmitter<any>();
  @Output() selectionContractPDFUnsigendSignedEmitter = new EventEmitter<any>();
  @Output() selectionRestoreEmitter = new EventEmitter<any>();
  @Output() selectionChangeStatusEmitter = new EventEmitter<any>();
  @Output() selectionChangePaymentStatusEmitter = new EventEmitter<any>();
  @Output() selectionAppSignatureEmitter = new EventEmitter<any>();
  public showActions = false;
  public actionBoxBottomStyle = false;
  public columnId;
  public actionBoxId;
  public emptyActions = false;


  constructor(
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    if(!this.actions.includes('print') &&
      !(this.object instanceof LedgerEntry) &&
      !(this.object instanceof Drive) &&
      !(this.object instanceof Kaufvertrag) &&
      !(this.object instanceof Tire)){
      this.actions.push('print');
    }
    this.actionBoxBottomStyle = (this.index + 2) >= (this.maxIndex-2);
    this.columnId = this.listItemId.split('_')[0]+'_'+this.listItemId.split('_')[1];
  }

  ngAfterViewInit(): void {
  }

  selectionActions() {
    this.showActions = !this.showActions;
    const list = document.getElementById('ab-item-list');
    if(this.showActions){
      if(list){
        list.addEventListener('scroll', this.closeActions);
      }
      const listItem = document.getElementById(this.listItemId);
      if(listItem){
        const listItemNumber = Number(this.listItemId.split('_')[2]);
        if(this.maxIndex > 8 && listItemNumber && listItemNumber >= (this.maxIndex-5)){
          for(let i = 5; i >= 0; i--){
            setTimeout(() => {
              const item = document.getElementById(this.columnId+'_'+(listItemNumber-i));
              if(item){
                item.classList.add('special');
              }
            }, 50);
          }
          setTimeout(() => {
            const box = document.getElementById('submenu-column-action-box_'+this.columnId);
            if(box){
              box.style.top = '-'+(box.offsetHeight-30)+'px';
            }
          }, 50);
        } else {
          for(let i = 0; i <= 5; i++){
            setTimeout(() => {
              const item = document.getElementById(this.columnId+'_'+(listItemNumber+i));
              if(item){
                item.classList.remove('special')
                item.style.zIndex = listItemNumber.toString();
              }
            }, 30);
          }
        }
      }
    } else {
      if(list){
        list.removeEventListener('scroll', this.closeActions);
      }
    }
  }

  closeActions() {
    this.showActions = false;
  }

  checkActionAvailability() {
    if(this.actions.length == 0){
      this.emptyActions = true;
    }
  }
}
