import { Customer } from "../customer/Customer";
import { Bill } from "../document/Bill";
import { Kaufvertrag } from "../document/Kaufvertrag";
import { Drive } from "../drives/Drive";
import { Employee } from "../employee/Employee";
import { Tire } from "../tires/Tire";
import { Vehicle } from "../vehicle/Vehicle";

export enum AttachmentType {
  VEHICLE = 1,
  CUSTOMER = 2,
  DOCUMENT = 3,
  PURCHASE_CONTRACT = 4,
  BILL = 5
}

export class Attachment {
  public category: number;
  public creator_name: string;
  constructor(
    public attachment_id: number,
    public typ: number,
    public typ_id: number,
    public mimetyp: string,
    public filename: string,
    public data: string
  ){}
}

export class AttachmentFactory {
  static jsonFactory(rawBody: any): Attachment[] {
    const data: Attachment[] = [];
    for(let rawPart of rawBody){
      data.push(this.jsonFactoryOne(rawPart));
    }
    return data;
  }

  static jsonFactoryOne(rawPart: any): Attachment {
      let obj = new Attachment(
        rawPart.attachment_id,
        rawPart.typ,
        rawPart.typ_id,
        rawPart.mimetype,
        rawPart.filename,
        rawPart.data,
      )
      obj.creator_name = rawPart.creator_name;
      return obj;
  }
}

export class AttachmentHelper {
  static getAttachmentTyp(obj): number {
    if(!obj) return null;
    if(obj instanceof Vehicle) return 1;
    if(obj instanceof Customer) return 2;
    if(obj instanceof Drive) return 3;
    if(obj instanceof Kaufvertrag) return 4;
    if(obj instanceof Bill) return 5;
    if(obj instanceof Tire) return 6;
    return null;
  }

  static getAttachmentTypID(obj): number {
    if(!obj) return null;
    if(obj instanceof Vehicle) return obj.id;
    else if(obj instanceof Customer) return obj.id;
    else if(obj instanceof Drive) return obj.test_drive_id;
    else if(obj instanceof Kaufvertrag) return obj.purchase_contract_id;
    else if(obj instanceof Bill) return obj.bill_id;
    else if(obj instanceof Tire) return obj.tire_id;
    return null;
  }

  static getColorClass(typ): string {
    switch (typ) {
      case 1: return 'file-img-blue'; break;
      case 2: return 'file-img-violet'; break;
      case 3: return 'file-img-pink'; break;
      case 4: return 'file-img-yellow'; break;
      case 5: return 'file-img-teal'; break;
      case 6: return 'file-img-green'; break;
      default: return 'file-img-blue'; break;
    }
  }

  static getIcon(obj): string {
    const mimetyp = String(obj.mimetyp).toLowerCase().trim();
    switch (mimetyp) {
      case 'png': return './assets/document-grey.svg'; break;
      case 'jpg': return './assets/document-grey.svg'; break;
      case 'pdf': return './assets/document-grey.svg'; break;
      case 'docx': return './assets/document-grey.svg'; break;
      default: return ''; break;
    }
  }
}
