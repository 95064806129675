<div id="notification_container" *ngIf="show" (click)="show = false">
    <div id="notification_box">
      <div class="column_1" [ngClass]="isSuccess ? 'success' : 'fail'">
        <img class="icon" [src]="isSuccess ? '../../../../assets/success.svg' : '../../../../assets/fail.svg'">
      </div>
      <div class="column_2">
        <div class="container_txt">
          <div class="lbl_bold title">{{title}}</div>
          <div class="lbl_regular message">{{message}}</div>
        </div>
      </div>
    </div>
</div>
