import { Injectable } from '@angular/core';
import { EnumData, EnumFactory, EnumValue } from 'src/app/buisness-object/menu/EnumData';
import { AuthenticationService } from '../authentication/authentication.service';
import { EnumHelper } from 'src/app/helpers/EnumHelper';
import { Steuersatz, SteuersatzStandard } from 'src/app/helpers/Enums';

@Injectable({
  providedIn: 'root'
})
export class EnumService {
  public enumHelper = new EnumHelper();
  public enums: EnumData[] = [];
  public steuerseatze = [];
  constructor(
    private authSerivceBase: AuthenticationService
  ) {
    this.updateEnums();
   }

   public updateEnums() {
    let rawEnums: any = JSON.parse(localStorage.getItem('enums'));
    if(rawEnums != null && rawEnums.length > 0){
      this.enums = EnumFactory.jsonFactoryEnumData(rawEnums);
    }
    this.enums.push(new EnumData('spedition',[new EnumValue(1, 'Hödlmayr', 0),new EnumValue(2, 'Lagermax', 0)]))
    this.enums.push(new EnumData('aufbereitung_info',[new EnumValue(1, 'Reifen umstecken', 0)]))
    this.enums.push(new EnumData('registration_document',[new EnumValue(1, 'Typenschein', 0), new EnumValue(2, 'Genehmigung für Zulassung', 0), new EnumValue(3, 'Einzelgenehmigung des Landeshauptmannes', 0)]))
    this.enums.push(new EnumData('payment_condition',[new EnumValue(1, 'Überweisung', 0),new EnumValue(2, 'Anzahlung bei Vertragsabschluss; Überweisung Restkaufpreis vor Übergabe', 0),new EnumValue(3, 'Teilzahlung mit Bankkredit', 0)]))
    this.enums.push(new EnumData('salutation',[new EnumValue(1, 'Frau', 0),new EnumValue(2, 'Herr', 0),new EnumValue(3, 'Diverse', 0),new EnumValue(5, 'Familie', 0),new EnumValue(4, 'Keine Angaben', 0)]))
    this.enums.push(new EnumData('title',[new EnumValue(1, 'Kein Titel', 0),new EnumValue(2, 'Prof.', 0),new EnumValue(3, 'Mag.', 0),new EnumValue(4, 'Dr.', 0),new EnumValue(5, 'Ing.', 0),new EnumValue(6, 'Dipl.-Ing', 0),new EnumValue(7, 'BSc', 0),new EnumValue(8, 'BEd', 0),new EnumValue(9, 'MBA', 0), new EnumValue(10, 'MA', 0), new EnumValue(11, 'MEd', 0), new EnumValue(12, 'MEd', 0)]))
    this.enums.push(new EnumData('vertrag_status',[new EnumValue(0, 'Offen', 0), new EnumValue(1, 'Unterzeichnet', 0), new EnumValue(2, 'Storniert', 0), new EnumValue(10, 'Archiviert', 0)]));
    if(this.authSerivceBase.isSinhuber()) {
      this.enums.push(new EnumData('license_plate_id',[new EnumValue(1, 'KR-ESJ4', 0), new EnumValue(2, 'KR-13WR', 0)]));
    }
    this.enums.push(new EnumData('rent_typ',[new EnumValue(1, 'Miet-Abo', 0), new EnumValue(2, 'Kurzmiete', 0), new EnumValue(3, 'Spezial', 0)]));
    this.enums.push(new EnumData('ledger_entry_status',[new EnumValue(1, 'Systemeintrag', 0), new EnumValue(2, 'Offen', 0), new EnumValue(3, 'Geprüft', 0)]));
    this.enums.push(new EnumData('tire_type',[new EnumValue(1, 'Sommer', 0),new EnumValue(2, 'Winter', 0),new EnumValue(3, 'Ganzjahresreifen', 0),new EnumValue(4, 'Runflat-Reifen', 0),new EnumValue(5, 'Breitreifen', 0)]))
    this.enums.push(new EnumData('tire_status',[new EnumValue(1, 'Lagernd', 0), new EnumValue(2, 'Veröffentlicht', 0), new EnumValue(10, 'Archiviert', 0)]));
    this.enums.push(new EnumData('rim_type',[new EnumValue(1, 'Stahlfelgen', 0), new EnumValue(2, 'Alufelgen', 0)]));
    this.enums.push(new EnumData('payment_method',[new EnumValue(1, 'Bar', 0), new EnumValue(2, 'Überweisung', 0), new EnumValue(3, 'Finanzierung', 0)]));
    //this.enums.push(new EnumData('bill_status',[new EnumValue(1, 'Entwurf', 0), new EnumValue(2, 'Bereit', 0), new EnumValue(3, 'Gesendet', 0), new EnumValue(4, 'Warten', 0), new EnumValue(5, 'Bezahlt', 0), new EnumValue(6, 'Storniert', 0)]));
    if(this.authSerivceBase.isSinhuber()){
      this.steuerseatze = this.enumHelper.getEnumValueList(Steuersatz);
    } else {
      this.steuerseatze = this.enumHelper.getEnumValueList(SteuersatzStandard);
    }
   }

   public getEnums(enumName: string): any[] {
    let index = this.enums.findIndex((value) => value.name === enumName);
    if(index > -1){
      return this.enums[index].values;
    }
    return [];
  }

   public getEnumValues(enumName: string): any[] {
    let index = this.enums.findIndex((value) => value.name === enumName);
    if(index > -1){
      let enumData = this.enums[index];
      enumData.values.sort((a,b) => {
        return a.sort_value - b.sort_value;
      });
      let values = [];
      for(let value of enumData.values){
        values.push(value.value);
      }
      return values;
    }
    return [];
  }

  public getEnumValueNames(enumName: string): any[] {
    let index = this.enums.findIndex((value) => value.name === enumName);
    if(index > -1){
      let enumData = this.enums[index];
      enumData.values.sort((a,b) => {
        return b.sort_value - a.sort_value;
      });
      let values = [];
      for(let value of enumData.values){
        values.push({ name: value.value, id: value.raw_value});
      }
      return values;
    } else {
      if(enumName == "priceSheet.steuersatz") {
        return this.steuerseatze.map((m, index) => {
          return {name: m, id: (index + 1)};
        });
      }
    }
    return [];
  }

  public getEnumValue(enumName: string, rawValue: number): any {
    if(rawValue == null) return '---';
    let index = this.enums.findIndex((value) => value.name === enumName);
    if(index > -1){
      let enumData = this.enums[index];
      let enumValue = enumData.values.find((value) => value.raw_value == rawValue);
      return enumValue ? enumValue.value : '---';
    }
    return '---';
  }
}
