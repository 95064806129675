import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { TireForm } from 'src/app/buisness-object/tires/form/TireForm';
import { Execution, Tire } from 'src/app/buisness-object/tires/Tire';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { TireService } from 'src/app/service/tire/tire.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { StorageLocation } from 'src/app/buisness-object/tires/StorageLocation';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';

@Component({
  selector: 'app-tyres-create',
  templateUrl: './tyres-create.component.html',
  styleUrl: './tyres-create.component.css'
})
export class TyresCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() obj: Tire;
  @Input() locations: StorageLocation[] = [];
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  @Output() createSuccessEmitter = new EventEmitter<Tire>();
  @Output() updateSuccessEmitter = new EventEmitter<Tire>();
  @Output() createInstalationSuccessEmitter = new EventEmitter<Tire>();
  public form: FormGroup;
  public submitted = false;
  public submittedInstallation = false;
  public showDialogInstallation = false;
  public showCreateChoise = false;
  public customers: Customer[] = [];
  public customerValues = [];
  @Input() freeVehicles: Vehicle[];
  @Input() vehicles: Vehicle[];
  public vehicleValues = [];
  public execution = Execution;
  public executionValues = [Execution.REIFEN,Execution.FELGEN,Execution.REIFENUNDFELGEN];
  public executionValue;
  public tireTypValue;
  public tireTypValues = [];
  public tireBrandValue;
  public tireBrandValues = [];
  public rimTypValue;
  public rimTypValues = [];
  //public rimBrandValue;
  public rimBrandValues = [];
  public tireBrandEnums = [];
  public rimBrandEnums = [];
  public selectedCheckbox = true;
  public tireValueChanged = false;
  public dialogQuerySubsription: Subscription;
  public showDialogStore = false;
  public locationsChoose = [];
  public brands: VehicleBrand[];
  public models: VehicleModel[] = [];
  public brandValues = [];
  public modelValues = [];

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private tireService: TireService,
    private dialogService: DialogService,
    private vehicleService: VehicleService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setUp();
    for(let l of this.locations){
      if(l.location_name != "Sonstiges") this.locationsChoose.push(l);
    }
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  async setUp() {
    await this.getData().toPromise();
    this.initView();
    this.setDialogSubscription();
  }

  getData(): Observable<any> {
    return forkJoin({
      customers: this.customerService.getCustomer(),
      brand: this.vehicleService.getBrands()
    }).pipe(
      tap((result) => {
        if(result) {
          this.customers = result.customers;
          this.brands = result.brand;
          this.getDropdownValues();
        }
      }),
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    );
  }

  initView() {
    this.form = TireForm.getForm(this.formBuilder, this.obj);
    this.form.get('execution').valueChanges.subscribe((change) => {
      if(change == Execution.FELGEN){
        this.form.get('rim_type').setValidators(Validators.required)
        this.form.get('rim_type').updateValueAndValidity();

        this.form.get('tire_type').removeValidators(Validators.required)
        this.form.get('tire_type').updateValueAndValidity();
        this.form.get('tire_brand_name').removeValidators(Validators.required)
        this.form.get('tire_brand_name').updateValueAndValidity();
      } else if(change == Execution.REIFEN) {
        this.form.get('tire_type').setValidators(Validators.required)
        this.form.get('tire_type').updateValueAndValidity();
        this.form.get('tire_brand_name').setValidators(Validators.required)
        this.form.get('tire_brand_name').updateValueAndValidity();

        this.form.get('rim_type').removeValidators(Validators.required)
        this.form.get('rim_type').updateValueAndValidity();
      } else if(change == Execution.REIFENUNDFELGEN){
        this.form.get('rim_type').setValidators(Validators.required)
        this.form.get('rim_type').updateValueAndValidity();
        this.form.get('tire_type').setValidators(Validators.required)
        this.form.get('tire_type').updateValueAndValidity();
        this.form.get('tire_brand_name').setValidators(Validators.required)
        this.form.get('tire_brand_name').updateValueAndValidity();
      }
    });
    this.form.get('amount').valueChanges.subscribe((change) => {
      let details = [];
      if(change == 4){
        details.push(TireForm.createDetailsForm(this.formBuilder));
        details.push(TireForm.createDetailsForm(this.formBuilder));
        details.push(TireForm.createDetailsForm(this.formBuilder));
        details.push(TireForm.createDetailsForm(this.formBuilder));
        details[0].get('tyres_equal').setValue(true);
        details[1].get('tyres_equal').setValue(true);
        details[2].get('tyres_equal').setValue(true);
        details[3].get('tyres_equal').setValue(true);
        this.form.get('details').setValue(details);
      } else if(change <= 10 && change > 0){
        for(let i = 0; i < this.form.get('amount').value; i++){
          details.push(TireForm.createDetailsForm(this.formBuilder));
        }
        this.form.get('details').setValue(details);
      }
    });
    this.form.get('not_for_sale').valueChanges.subscribe((change) => {
      if(change){
        this.form.get('platform_online').setValue(false);
      }
    })
    this.form.valueChanges.subscribe((change) => {
      if(change && this.form.get('amount').value != null){
        this.tireValueChanged = true;
      }
    })
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.tireBrandEnums = this.eService.getEnums('tire_brand');
    this.rimBrandEnums = this.eService.getEnums('rim_brand');
    this.getEnumData();
    if(this.obj && this.form.get('tire_brand').value){
      this.models = this.form.get('tire_brand').value;
    }
  }

  getEnumData() {
    this.tireTypValue = this.getEnumValue('tire_type', this.form.get('tire_type').value);
    this.tireTypValues = this.getEnumValues('tire_type');
    this.tireBrandValue = this.getEnumValue('tire_brand', this.form.get('tire_brand').value);
    this.tireBrandValues = this.getEnumValues('tire_brand');
    if(this.tireBrandValues.length == 0) this.tireBrandValues.push("Waymark");
    this.rimTypValue = this.getEnumValue('rim_type', this.form.get('rim_type').value);
    this.rimTypValues = this.getEnumValues('rim_type');
    //this.rimBrandValue = this.getEnumValue('rim_brand', this.form.get('rim_brand').value);
    this.rimBrandValues = this.getEnumValues('rim_brand');
  }

  getEnumDataOnChange() {
    //this.rimBrandValue = this.getEnumValue('rim_brand', this.form.controls['rim_brand'].value);
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionBackEmitter.emit();
      }
    });
  }

  selectionCancel() {
    if(this.tireValueChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionBackEmitter.emit();
    }
  }

  selectionCreateAndInstallation() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    if(!this.validDetailsForms()) return;
    this.showDialogInstallation = true;
  }

  selectionCreateAndStore() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    if(!this.validDetailsForms()) return;
    this.showDialogStore = true
  }

  submitLocation(location: StorageLocation) {
    this.form.get('storage_location_id').setValue(location.storage_location_id);
    let tire = TireForm.getObject(this.form);
    this.tireService.createTire(tire).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Reifen erstellt.',
          success: true
        });
        this.createSuccessEmitter.emit(result);
      }
    });
  }

  selectionSave() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    if(!this.validDetailsForms()) return;
    let tire = TireForm.getObject(this.form);
    this.tireService.updateTire(tire).subscribe((result) => {
      if(result){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Reifen aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    });
  }


  validDetailsForms(): boolean {
    if(this.form.get('execution').value == Execution.FELGEN){
      return true;
    }
    if(this.form.get('details').value.length != 4){
      for(let detailsForm of this.form.get('details').value){
        if(detailsForm.invalid){
          ErrorHelper.showFormError(detailsForm);
          return false;
        }
      }
    } else {
      if(this.form.get('details').value[0].invalid){
        ErrorHelper.showFormError(this.form.get('details').value[0]);
        return false;
      }
      if(this.form.get('details').value[2].invalid){
        ErrorHelper.showFormError(this.form.get('details').value[2]);
        return false;
      }
    }
    return true;
  }

  getFormDetailsTitle(form: FormGroup, index: number): string {
    if(this.form.get('details').value.length == 4 && form.get('tyres_equal').value){
      if(index == 0) return 'Vorderreifen (Tiefe / Baujahr)';
      if(index == 2) return 'Hinterreifen (Tiefe / Baujahr)';
    } else {
      return `Reifen ${index+1} (Tiefe / Baujahr)`;
    }
    return '---';
  }

  changeDetails() {
    let newDetails = [];
    this.selectedCheckbox = !this.selectedCheckbox;
    newDetails.push(TireForm.createDetailsForm(this.formBuilder));
    newDetails.push(TireForm.createDetailsForm(this.formBuilder));
    newDetails.push(TireForm.createDetailsForm(this.formBuilder));
    newDetails.push(TireForm.createDetailsForm(this.formBuilder));
    newDetails[0].get('tyres_equal').setValue(this.selectedCheckbox);
    newDetails[1].get('tyres_equal').setValue(this.selectedCheckbox);
    newDetails[2].get('tyres_equal').setValue(this.selectedCheckbox);
    newDetails[3].get('tyres_equal').setValue(this.selectedCheckbox);
    this.form.get('details').setValue(newDetails);
  }

  setValuePurchaseVehicle(id: number) {
    let index = this.vehicles.findIndex(obj => obj.id == id);
    if(index > -1){
      this.form.get('purchased_with_vehicle_id').setValue(this.vehicles[index].id);
      this.form.get('purchased_with_vehicle').setValue(this.vehicles[index]);
    }
  }



  selectionCloseInstallation() {
    this.showDialogInstallation = false;
  }

  selectionCloseChooseLocation() {
    this.showDialogStore = false;
  }

  selectionSubmit(vehicle: Vehicle) {
    this.form.get('vehicle').setValue(vehicle);
    this.form.get('vehicle_id').setValue(vehicle.id);
    this.form.get('storage_place').setValue(null);
    this.form.get('storage_place_id').setValue(null);
    let obj = TireForm.getObject(this.form);
    this.tireService.createTire(obj).subscribe((result) => {
      if(result){
        this.showDialogInstallation = true;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Reifen erstellt.',
          success: true
        });
        this.createInstalationSuccessEmitter.emit(result);
      }
    });
  }

  getDropdownValues() {
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle_make'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
      else this.form.get('vehicle_make').setValue(null)
    } else if(value === 'tire_model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
      else this.form.get('tire_model').setValue(null)
    }
  }

  setBrand(brand: VehicleBrand) {
    this.form.get('tire_brand').setValue(brand)
    this.models = brand.models;
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.form.get('tire_model').setValue(null)
  }

  setModel(model: VehicleModel) {
    this.form.get('tire_model').setValue(model)
  }
}
