<div (window:resize)="onScreenSizeChange()">
    <app-topbar
        [surface]="surface"
        [selectedDetailsTab]="selectedDetailsTab"
        [accessEdit]="accessEdit"
        [currentPageCreate]="currentPageCreate"
        [vehicleTitle]="vehicleTitle"
        [vehicleSubTitle]="vehicleSubTitle"
        [filter]="filter"
        [configurations]="configs"
        [selectedConfiguration]="selectedConfiguration"
        [editMode]="editMode"
        [vehicles]="vehicles"
        [filterForm]="filterForm"
        [sortObject]="sortObject"
        [selectedVehicle]="selectedVehicle"
        [surfacePriceSheet]="surfacePriceSheet"
        [editTyp]="editTyp"
        [vinFlag]="vinFlag"
        [isNewPriceSheet]="isNewPriceSheet"
        [mainMenus]="mainMenus"
        [initialMenuName]="initialMenuName"

        [activeSubMenu$]="activeSubMenu$"
        [activeFilter]="activeFilter"
        [employees]="employees"

        (showCreateEmitter)="openCreate()"
        (closeDetailsEmitter)="closeDetails()"
        (cancelCreateEmitter)="cancelCreateAction()"
        (cancelEditEmitter)="selectionCancelEditVehicle()"
        (updateVehicleEmitter)="validateInputUpdate()"
        (searchEmitter)="null"
        (filterEmitter)="filterVehicle($event)"
        (changeConfigEmitter)="changeOverviewConfig($event)"
        (selectionEditVehicle)="openEdit($event)"
        (saveAsDraftEmitter)="saveAsDraft()"
        (filterDetailEmitter)="filterDetails()"
        (resetFilterEmitter)="resetFilterEmitter()"
        (sortEmitter)="sortEmitter()"
        (resetSortEmitter)="resetSortEmitter()"
        (selectionCreateDokumentEmitter)="selectionCreateDocument($event)"
        (selectionKalkulation)="selectionKalkulation($event)"
        (selectionPriceSheetCancel)="selectionPriceSheetCancel()"
        (selectionPriceSheetSave)="selectionPriceSheetSave()"
        (selectionEditDescrtiption)="selectionEditDescrtiption()"
        (selectionEtikettenDruckEmitter)="selectionEtikettenDruck()"
        (selectionPrintPreisblatt)="printPDF(selectedVehicle.id, 2)"
        (printPdfEmitter)="openPdfDialog($event)"
    ></app-topbar>
    <!-- <div *ngIf="listLoading" id="list-loader"></div> -->
    <app-list-container *ngIf="dataReady == true && surface == 1"
        [accessEdit]="accessEdit"
        [objectTyp]="'vehicle'"
        [objects]="_vehiclesFiltered.getValue()"
        [showInformation]="showInformation"
        [pageHandler]="pageHandler"
        [filter]="filter"
        [sortObject]="sortObject"
        [filterForm]="filterForm"
        [mainMenus]="mainMenus"
        [isListLoading]="isListLoading"
        [activeSubMenu$]="activeSubMenu$"
        [activeSortingColumn]="activeSortingColumn"
        [activeFilter]="activeFilter"
        [employees]="employees"
        (pageEmitter)="pageHandling($event)"
        (openEditEmitter)="openEditFromList($event)"
        (deleteEmitter)="deleteAction($event)"
        (moveEmitter)="moveVehicleAction($event)"
        (printPdfEmitter)="openPdfDialog($event)"
        (printEtikettEmitter)="printEtikett($event)"
        (sellEmitter)="openSellDialog($event)"
        (sortEmitter)="sortEmitter()"
        (removeFilterValueEmitter)="removeFilterValue($event)"
        (selectionCreateDokumentEmitter)="selectionCreateDocument($event)"
        (selectionCopyEmitter)="selectionCopyVehicle($event)"
        (applySortingEmitter)="applySorting($event)"
        (applySpecialFilter)="applySpecialFilter($event)"
        (customFilterEmitter)="customFilter($event)"
        (customFilterOutsideEmitter)="customFilterOutside($event)"
        (changeListTabEmitter)="showInformation = $event"
        (listLoadingEmitter)="listLoading = false"
        (selectionCreateKaufvertragEmitter)="selectionCreateKaufvertrag($event)"
        (calculatePricesheetEmitter)="calculatePricesheetListItem($event)"
        (selectionArchivEmitter)="selectionArchiv($event)"
    ></app-list-container>
    <app-create-container *ngIf="surface == 2"
        [currentPageCreate]="currentPageCreate"
        [formGroupVehicle]="formGroupVehicle"
        [submitted]="submitted"
        [brands]="brands"
        [drives]="drives"
        [bodyColors]="bodyColors"
        [fuels]="fuels"
        [bodyTyps]="bodyTyps"
        [transmissions]="transmissions"
        [categories]="categories"
        [bodyTypOptions]="bodyTypOptions"
        [fuelOptions]="fuelOptions"
        [colorOptions]="colorOptions"
        [driveOptions]="driveOptions"
        [brandOptions]="brandOptions"
        [categoryOptions]="categoryOptions"
        [transmissionOptions]="transmissionOptions"
        [equipments]="equipments"
        [newVehicle]="selectedVehicle"
        (changePageEmitter)="validateInput($event)"
        (updateVehicleTitle)="vehicleTitle = $event"
        (createVehicleEmitter)="createVehicle(1)"
        (vinEmitter)="getVehicleByVin($event)"
        (saveAsDraftEmitter)="saveAsDraft()"
    ></app-create-container>
    <div id="details-page" *ngIf="surface == 3 || surface == 4 || surface == 5">
      <div id="overview-header">
        <div class="overview-header-column" *ngIf="!(surfacePriceSheet != 3)">
          <button [disabled]="surfacePriceSheet != 3 || editMode" class="btn-tab btn-tab-first" [class.btn-tab-active]="selectedDetailsTab == 1" (click)="changeDetailsTab(1)">Standard</button>
          <button [disabled]="editMode" *ngIf="allowToSeeSuperGroup(1)" [disabled]="surfacePriceSheet != 3" class="btn-tab" [class.btn-tab-active]="selectedDetailsTab == 2" (click)="changeDetailsTab(2)">Verkauf</button>
          <button [disabled]="editMode" *ngIf="authenticationService.accessPricecalculation()" class="btn-tab" [class.btn-tab-active]="selectedDetailsTab == 3" [class.btn-tab-last]="(!authenticationService.ACCESS_ALL() || !authenticationService.ACCESS_FOUR())" (click)="changeDetailsTab(3)">Preiskalkulation</button>
          <button [disabled]="editMode || surfacePriceSheet != 3" *ngIf="(authenticationService.ACCESS_ALL() || authenticationService.ACCESS_FOUR())" class="btn-tab btn-tab-last" [class.btn-tab-active]="selectedDetailsTab == 4" (click)="changeDetailsTab(4)">Tickets</button>
        </div>
        <div class="overview-header-column" *ngIf="surfacePriceSheet == 3">
          <button [disabled]="editMode" class="btn-tab btn-tab-first" [class.btn-tab-disabled]="!showPreviousBtnDetails" (click)="selectionPreviousVehicle()">Vorheriges Fahrzeug</button>
          <button [disabled]="editMode" class="btn-tab btn-tab-last" [class.btn-tab-disabled]="!showNextBtnDetails" (click)="selectionNextVehicle()">Nächstes Fahrzeug</button>
        </div>
      </div>
      <app-overview-container *ngIf="surface == 3 && editTyp == 1"
          [activeSubMenu]="_activeSubMenu.getValue()"
          [configurations]="configs"
          [selectedConfiguration]="selectedConfiguration"
          [selectedDetailsTab]="selectedDetailsTab"
          [selectedVehicle]="selectedVehicle"
          [formGroupVehicle]="formGroupVehicle"
          [formGroupPriceSheet]="pricesheetForm"
          [editMode]="editMode"
          [submitted]="submitted"
          [screenSize]="screenSize"
          (updateVehicleTitle)="vehicleTitle = $event"
      ></app-overview-container>
      <app-overview-messages *ngIf="surface == 4"
          [vehicle]="selectedVehicle"
      ></app-overview-messages>
      <app-edit-description *ngIf="surface == 3 && editTyp == 2 && formGroupVehicle"
          [form]="formGroupVehicle"
      ></app-edit-description>
      <app-price-sheet *ngIf="surface == 5 && surfacePriceSheet"
          [vehicle]="selectedVehicle"
          [surface]="surfacePriceSheet"
          [form]="pricesheetForm"
          [formVehicle]="formGroupVehicle"
          [submitted]="submitted"
          [customers]="customers"
          [employees]="employees"
          (onPriceSheetChangesEmitter)="onPriceSheetChanges()"
          (selectionEditSteuersatzAcceptedVorEmitter)="selectionEditSteuersatzAcceptedVor()"
          (selectionEditSteuersatzAcceptedNachEmitter)="selectionEditSteuersatzAcceptedNach()"
          (addReparaturVorEmitter)="addReparaturVor()"
          (removeReparaturVorEmitter)="removeReparaturVor($event)"
          (addReparaturNachEmitter)="addReparaturNach()"
          (removeReparaturNachEmitter)="removeReparaturNach($event)"
      ></app-price-sheet>
    </div>
</div>
<app-conflict-dialog
  [show]="updateConflicts != null"
  [conflicts]="updateConflicts"
  (submitEventEmitter)="submitConflicts($event)"
></app-conflict-dialog>
