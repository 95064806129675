import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { rejects } from 'assert';
import { resolve } from 'dns';
import { VehiclePictureFactory } from 'src/app/buisness-object/vehicle/optional/factory/VehiclePictureFactory';
import { VehiclePicture } from 'src/app/buisness-object/vehicle/optional/VehiclePicture';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { GlobalVariables } from 'src/app/helpers/GlobalVars';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';

@Component({
  selector: 'app-imageupload-container',
  templateUrl: './imageupload-container.component.html',
  styleUrls: ['./imageupload-container.component.css']
})
export class ImageuploadContainerComponent implements OnInit {
  public gallery: VehiclePicture[] = [];
  public titlePicture: VehiclePicture;
  public position = 0;
  public uploadProgress = 0;
  public filesAmount = 0;
  public currentFile = 0;
  public respondImgs: VehiclePicture[] = [];
  public onloadSrcs: string[] = [];

  @Input() formGroupVehicle: UntypedFormGroup;
  @Input() vehicle: Vehicle;

  constructor(private vService: VehicleService) { }

  ngOnInit(): void {
    if(!this.f.pictures.value){
      this.f.pictures.setValue(this.gallery);
    } else {
      this.gallery = this.f.pictures.value;
      for(let image of this.f.pictures.value){
        if(image.position == 1){
          this.titlePicture = image;
          break;
        }
      }
    }
  }

  get f() {
    return this.formGroupVehicle.controls;
  }

  async uploadFile(event: any): Promise<any> {
    this.currentFile = 0;
    this.onloadSrcs = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i <this.filesAmount; i++){
       await this.readFiles(event.target.files[i])
      }
      await this.uploadImgToServer();
    }
  }

  async readFiles(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.onloadSrcs.push(event.target.result as string);
        resolve(true)
      }
      reader.readAsDataURL(files);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  async uploadImgToServer(): Promise<any> {
    let i = 0;
    for(let src of this.onloadSrcs){
      let response = await this.createVehiclePicture(src);
      i++;
    }
  }

  createVehiclePicture(src: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.vService.createVehiclePicture(this.vehicle.id, src.split(',')[1]).subscribe((event: HttpEvent<any>) => {
        let response = event as HttpResponse<any>;
        if(response.body){
          this.currentFile += 1;
          this.gallery.push(new VehiclePicture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,this.vehicle.id, src));
          this.titlePicture = this.gallery[0];
          resolve(true);
        }
        switch (event.type) {
          case HttpEventType.UploadProgress:
            let gesamtsatz = this.filesAmount * 100;
            let progress = Math.round((event.loaded / event.total) * 100);
            let lProzentSatz = Math.round(progress * gesamtsatz);
            let result = lProzentSatz / gesamtsatz;
            this.uploadProgress = this.uploadProgress > result ? this.uploadProgress : result;
          case HttpEventType.Response:
            setTimeout(() => {
              if(this.uploadProgress >= 100 && this.currentFile == this.filesAmount){
                this.uploadProgress = 0;
                this.currentFile = 0;
                this.filesAmount = 0;
                this.f.pictures.setValue(this.gallery);
              }
            }, 100);
            break;
          default: break;
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  // async onFileDropped(data: any) {
  //   this.filesAmount += 1;
  //   if(data?.data){
  //     await this.createVehiclePicture(data);
  //   }
  // }


  async onFileDropped(data: any) {
    // if(data?.data && data?.data[0]){
    //   await this.createVehiclePicture(data.data[0].content);
    //   // this.sources.push({
    //   //   name: data?.data[0].name.split('.')[0],
    //   //   format: data?.data[0].name.split('.')[1],
    //   //   data: data?.data[0].content.split(',')[1]
    //   // })
    // }
    if(data){
      for(let d of data.data){
        await this.createVehiclePicture(d.content);
      }
    }
  }

  setAsTitle(pic: VehiclePicture) {
    pic.position = 1;
    this.updateImage(pic);
  }

  updateImage(pic: VehiclePicture) {
    if(pic){
      this.vService.updateVehiclePicture(pic).subscribe((result) => {
        if(result){
          this.titlePicture = pic;
          for(let uploadedPic of result.pictures){
            for(let pic of this.gallery){
              if(pic.id == uploadedPic.picture_id) pic.position = uploadedPic.position;
              if(pic.position == 1) this.titlePicture = pic;
            }
          }
        }
      });
    }
  }

  removeImage(pic: VehiclePicture) {
    this.vService.deletePicture(pic.vehicle_id, pic.id).subscribe((success) => {
      if(success){
        let index = this.gallery.findIndex(p => p.id == pic.id);
        if(index > -1) this.gallery.splice(index, 1);
        if(this.titlePicture.id == pic.id){
          this.titlePicture = this.gallery[0] ? this.gallery[0] : null;
        };
      }
    })
  }

  getProgress(): string {
    return this.uploadProgress + '%';
  }
}




  // async uploadFile(event: any) {
  //   GlobalVariables.progressbarActive = true;
  //   this.selectedImg = null;
  //   this.currentFile = 1;
  //   if(event.target.files){
  //     this.filesAmount = event.target.files.length;
  //     for(let i = 0; i < this.filesAmount; i++){
  //       var reader = new FileReader();
  //       this.position += 1;
  //       let pic = new VehiclePicture(0, this.newVehicle ? this.newVehicle.id : 30 , this.position, '');
  //       reader.onload = (event: any) => {
  //         pic.src = event.target.result as string;
  //         this.f.images.value.push(pic);
  //       }
  //       reader.onloadend = () => {
  //         this.uploadImgToServer(pic);
  //         if(i == this.filesAmount - 1){
  //           setTimeout(() => {
  //             this.selectedImg = this.vehicleImgs[this.f.images.value.length - 1];
  //           }, 1500);
  //         }
  //       }
  //       reader.readAsDataURL(event.target.files[i]);
  //     }
  //   }
  //   GlobalVariables.progressbarActive = false;
  // }

  // onFileDropped(base64: string) {
  //   if(base64){
  //     let pos = this.f.images.value.length + 1;
  //     this.f.images.value.push(new VehiclePicture(0, this.vehicle ? this.vehicle.id : 0 , pos, base64));
  //     this.selectedImg = this.f.images.value[this.f.images.value.length - 1];
  //     //this.uploadImgToServer(new VehiclePicture(0, this.vehicle.id, pos, base64));

  //     // this.vehiclePictures.push(new VehiclePicture(0, this.vehicle.id, pos, base64));
  //     // this.vService.createVehiclePicture(new VehiclePicture(0, this.vehicle.id, pos, base64)).subscribe((pic) => {
  //     //   if(pic){
  //     //     this.vehiclePictures.push(pic);
  //     //   }
  //     // });
  //   }
  // }

  // async uploadImgToServer(vehicleImg: VehiclePicture) {
  //   // this.vService.createVehiclePicture(vehicleImg).subscribe((event: HttpEvent<any>) => {
  //   //   let n = event as HttpResponse<any>;
  //   //   if(n.body){
  //   //     let pictures = VehiclePictureFactory.jsonFactory(n.body.pictures);
  //   //     this.respondImgs = pictures;
  //   //   }
  //   //   switch (event.type) {
  //   //     case HttpEventType.UploadProgress:
  //   //       this.uploadProgress = Math.round(event.loaded / event.total * 100); break;
  //   //     case HttpEventType.Response:
  //   //       setTimeout(() => {
  //   //         this.currentFile += 1;
  //   //         this.uploadProgress = 0;
  //   //       }, 1500); break;
  //   //     default: break;
  //   //   }
  //   // });
  // }

  // deleteImg() {
  //   this.vService.deletePicture(this.selectedImg.id).subscribe((success) => {
  //     if(success){
  //       let index = this.f.images.value.findIndex(pic => pic.id == this.selectedImg.id);
  //       if(index != -1){
  //         this.f.images.value.splice(index, 1);
  //       }
  //     }
  //   })
  // }

  // setAsTitle() {
  //   for(let image of this.f.images.value){
  //     if(image.position == 1){
  //       image.position = this.selectedImg.position;
  //       this.selectedImg.position = 1;
  //     }
  //   }
  //   for(let image of this.f.images.value){
  //     if(image.id == this.selectedImg.id){
  //       image = this.selectedImg;
  //     }
  //   }
  // }




    // async uploadImgToServer(src: string): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.vService.createVehiclePicture(137, src.split(',')[1]).subscribe((event: HttpEvent<any>) => {
  //       let response = event as HttpResponse<any>;
  //       if(response.body){
  //         this.currentFile += 1;
  //         this.gallery.push(new VehiclePicture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,137, src));
  //         this.titlePicture = this.gallery[0];
  //       } else {
  //         resolve(false)
  //       }
  //       switch (event.type) {
  //         case HttpEventType.UploadProgress:
  //           let gesamtsatz = this.filesAmount * 100;
  //           let progress = Math.round((event.loaded / event.total) * 100);
  //           let lProzentSatz = Math.round(progress * gesamtsatz);
  //           let result = lProzentSatz / gesamtsatz;
  //           this.uploadProgress = this.uploadProgress > result ? this.uploadProgress : result;
  //         case HttpEventType.Response:
  //           setTimeout(() => {
  //             if(this.uploadProgress >= 100 && this.currentFile == this.filesAmount){
  //               this.uploadProgress = 0;
  //               this.currentFile = 0;
  //               this.filesAmount = 0;
  //               this.f.pictures.setValue(this.gallery);
  //             }
  //           }, 1500);
  //           break;
  //         default: break;
  //       }
  //     });
  //   })
  // }

  // async uploadImgToServer(src: string) {
  //   this.vService.createVehiclePicture(this.vehicle.id, src.split(',')[1]).subscribe((event: HttpEvent<any>) => {
  //     let response = event as HttpResponse<any>;
  //     if(response.body){
  //       this.currentFile += 1;
  //       this.gallery.push(new VehiclePicture(response.body.pictures[response.body.pictures.length - 1].picture_id, response.body.pictures[response.body.pictures.length - 1].position,this.vehicle.id, src));
  //       this.titlePicture = this.gallery[0];
  //     }
  //     switch (event.type) {
  //       case HttpEventType.UploadProgress:
  //         let gesamtsatz = this.filesAmount * 100;
  //         let progress = Math.round((event.loaded / event.total) * 100);
  //         let lProzentSatz = Math.round(progress * gesamtsatz);
  //         let result = lProzentSatz / gesamtsatz;
  //         this.uploadProgress = this.uploadProgress > result ? this.uploadProgress : result;
  //       case HttpEventType.Response:
  //         setTimeout(() => {
  //           if(this.uploadProgress >= 100 && this.currentFile == this.filesAmount){
  //             this.uploadProgress = 0;
  //             this.currentFile = 0;
  //             this.filesAmount = 0;
  //             this.f.pictures.setValue(this.gallery);
  //           }
  //         }, 1800); break;
  //       default: break;
  //     }
  //   });
  // }

