<app-collapsible [isCollapsible]="!editMode && !authService.isSinhuber()" [isContentVisible]="false" *ngIf="obj">
  <h3 collapsible-header class="overview_data_box_lbl_title">Historie</h3>
  <div collapsible-content>
    <div id="transaction-container" *ngIf="transactions.length == 0">
      <label>Keine Historie vorhanden.</label>
    </div>
    <div id="transaction-container" *ngIf="transactions.length > 0">
      <div class="list-transactions-element" [class.list-tranactions-element-last]="i == transactions.length-1" *ngFor="let transaction of transactions; let i = index;">
        <div class="history_icon" [ngStyle]="{'background-color': transaction.color}"><img [src]="transaction.icon"></div>
        <div class="tranaction-inner-wrapper">
          <div class="transaction-header">
            <div class="tranaction-header-inner-wrapper-1">
              <label>{{transaction.text}}</label>
              <div class="italic-small">von {{transaction.username}}</div>
            </div>
            <div class="tranaction-header-inner-wrapper-2">
              <label>{{transaction.timestamp |  date:'dd MMM yyyy, HH:mm'}}</label>
              <button *ngIf="transaction.changes.length > 0 && authService.isWaymark()" class="btn_label_blue" (click)="showTransactionChanges(transaction)">Details anzeigen</button>
            </div>
          </div>
          <div  class="tranaction-changes-wrapper" [attr.id]="'transaction-changes_'+transaction.transactionId">
            <div *ngFor="let change of transaction.changes" class="tranaction-change-wrapper">
              <label>{{change.display_name}}</label>
              <div class="tranaction-change-value-wrapper">
                <label [appFormatValue]="change.property_type" [value]="change.old_value"></label>
                <img src="./assets/direction-black.svg">
                <label [appFormatValue]="change.property_type" [value]="change.new_value"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-collapsible>
