import { Drive } from 'src/app/buisness-object/drives/Drive';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from 'src/app/view/BaseComponent';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { VehicleStatus } from 'src/app/view/list-action-container/btn-action/btn-action.component';
import { EnumService } from 'src/app/service/enum/enum.service';

@Component({
  selector: 'app-drive-page-two',
  templateUrl: './drive-page-two.component.html',
  styleUrls: ['./drive-page-two.component.css'],
  animations: [ DropdownIconArrowAnimation ]
})
export class DrivePageTwoComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() drives: Drive[];
  @Input() employees: Employee[] = [];
  @Input() submitted: boolean;
  public toShowEntries: Drive[] = [];

  @Input() vehicles: Vehicle[];
  @Input() selectedDrive: Drive;

  public showInformationTab = 2;
  public vehiclesFiltered: Vehicle[] = [];
  public selectedVehicle: Vehicle;
  public selectedPlate = 0;
  @ViewChild('input_vehicle') input_vehicle: ElementRef;
  @ViewChild('dropdownTwo') dropdownTwo: ElementRef;
  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listener: () => void;
  public tempCreated: Drive;
  public platesValues = [];
  public selectedPlateValue = [];
  public vehicleValues = [];
  public brandValues = [];
  public modelValues = [];
  public employeeValues = [];
  public brands: VehicleBrand[] = [];
  public models: VehicleModel[] = [];
  public heightCalender;

  constructor(
    private vService: VehicleService,
    public authService: AuthenticationService,
    private eService: EnumService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    if(this.form.controls.vehicle.value){
      this.tempCreated = new Drive(
        0,
        1,
        this.form.controls.start_time.value,
        this.form.controls.end_time.value -  this.form.controls.start_time.value,
        this.form.controls.vehicle.value.id,
        0,
        this.form.controls.license_plate_id.value, //plate
        [],
        this.form.controls.start_time.value,
        this.form.controls.end_time.value,
        'Neuer Termin'
      )
      this.tempCreated.is_new = true;
    }
    this.vehicles = this.filterOUt()
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.vehiclesFiltered = this.vehicles;
  }

  filterOUt(): Vehicle[] {
    let temp = [];
    if(this.authService.isSinhuber()){
      temp = this.vehicles.filter((v) => {
        if(v.externalId == "9606") return true;
        return (v.priceSheet?.al_re_datum == null || v.priceSheet?.al_re_datum > new Date().getTime()) && v.status != VehicleStatus.Archiv;
      });
    } else if(this.authService.isStandard()){
      temp = this.vehicles.filter((v) => {
        return !v.customer_id && v.status != VehicleStatus.CustomerCar && v.status != VehicleStatus.Archiv;
      });
    }
    return temp;
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setDropdownValues() {
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    if(this.form['trade_in_vehicle_brand']?.value != null){
      this.modelValues = [];
      for(let v of this.form['trade_in_vehicle_brand'].value.models){
        this.modelValues.push({
          id: v.id,
          name: v.name
        })
      }
    }
  }


  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['vehicle'].setValue(this.vehicles[index]);
        this.setVehicle(this.vehicles[index]);
      }
    } else   if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls["employee"].setValue(this.employees[index]);
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const calenderBody = document.getElementById('calender-body');
      if(calenderBody){
        this.heightCalender = calenderBody.offsetHeight;
      }
    }, 200);
  }

  addTime(time: any) {
    this.form.controls.start_time.setValue(time.start_time);
    this.form.controls.end_time.setValue(time.end_time);
    let index = this.drives.findIndex(d => d.test_drive_id == 0)
    if(index > -1) this.drives.splice(index,1)
    this.tempCreated = null;
  }

  sort() {
    this.vehicles.sort((a,b) => {
      if(a.brand.makeName > b.brand.makeName) return 1;
      if(a.brand.makeName < b.brand.makeName) return -1;
      return 0;
    })
  }

  setVehicle(vehicle: Vehicle) {
    this.selectedDrive = null;
    this.toShowEntries = [];
    this.selectedVehicle = vehicle;
    this.getThumbnail();
    this.toShowEntries = this.getDrives();
    this.form.controls.vehicle.setValue(vehicle);
    if(this.input_vehicle) this.input_vehicle.nativeElement.value = this.selectedVehicle.getDescription(25);
  }

  getThumbnail() {
    if(this.selectedVehicle?.pictures.length > 0){
      this.vService.getVehicleThumbnail(this.selectedVehicle.pictures[0].id).subscribe((result) => {
        if(result){
          this.selectedVehicle.thumbnail = 'data:image/png;base64,'+result.trim();
          this.toShowEntries = this.getDrives();
        }
      })
    }
  }

  getDrives(): Drive[] {
    let drives = [];
    for(let drive of this.drives){
      if(this.selectedVehicle){
        if(drive.license_plate_id == this.selectedPlate && drive.vehicle_id == this.selectedVehicle.id){
          drives.push(drive);
        }
      } else if(drive.license_plate_id == this.selectedPlate){
        drives.push(drive);
      }
    }
    if(this.tempCreated) drives.push(this.tempCreated);
    return drives;
  }

  filterPlate(id: number) {
    this.selectedPlate = id;
    this.tempCreated = null;
    this.form.controls.start_time.setValue(null);
    this.form.controls.end_time.setValue(null);
    let indexPlate = this.platesValues.findIndex((p) => p == this.form.controls['license_plate_id'].value-1);
    if(indexPlate > -1) {
      this.form.controls['license_plate_id'].setValue(this.platesValues[indexPlate]+1);
    }
    //this.form.controls.license_plate_id.setValue(id);
    let index = this.drives.findIndex(d => d.test_drive_id == 0)
    if(index > -1) this.drives.splice(index,1)
    this.tempCreated = null;
    this.toShowEntries = this.getDrives();
  }

  getSelectedPlate(): string {
    return this.platesValues[this.form.controls['license_plate_id'].value];
    // switch (this.form.controls['license_plate_id'].value) {
    //   case 1: return 'KR-ESJ4'; break;
    //   case 2: return 'KR-13WR'; break;
    //   case 3: return 'BEISPIELKENNZEICHEN'; break;
    //   default: return '---'; break;
    // };
  }

  setPlate(value) {
    this.form.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
  }
}
