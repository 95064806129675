<div class="dropdown_filter_container" [ngClass]="showFilter ? 'dropdown_filter_container_open' : ''">
  <div class="lbl_list_title">FILTER</div>
  <div class="lbl_color_wrapper">
      <div class="lbl_color_wrapper_violet" [ngClass]="tempFilter.typ == 2 ? 'lbl_color_wrapper_violet_active' : ''" (click)="tempFilter.typ = 2">Privat</div>
      <div class="lbl_color_wrapper_orange" [ngClass]="tempFilter.typ == 1 ? 'lbl_color_wrapper_orange_active' : ''" (click)="tempFilter.typ = 1">Firma</div>
  </div>
  <div class="footer" [ngStyle]="{'justify-content': !tempFilter.is_set ? 'flex-end' : 'space-between'}">
    <div *ngIf="tempFilter.is_set" class="btn_menu_information" (click)="resetFilter()">Zurücksetzen</div>
    <div class="btn_submit_dark" (click)="submitFilter()">Filtern</div>
  </div>
</div>

