<form [formGroup]="customFilterForm">
    <div class="custom-filter-inner-container" >
        <div *ngFor="let inputRow of inputRows; let j = index" [ngClass]="hasLabel ? 'custom-filter-with-label' : ''" class="custom-filter-row">
                <div *ngIf="hasLabel" class="custom-filter-label" [innerHTML]="labels[j]"></div>
                <div [ngClass]="{'two-grid': (inputRow.length==2) }">
                    <div [ngClass]="amount-of-filter-in-row" *ngFor="let input of inputRow">
                      <input *ngIf="input.data_typ=='STRING'" type="text" placeholder="{{input.placeholder}}" formControlName="{{input.data_key}}">
                      <app-dropdown-objects-search *ngIf="input.data_typ=='ENUM'"
                        [placeholder]="input.placeholder"
                        [enumvalue]="customFilterForm.value[input.data_key]"
                        [formcontrol]="customFilterForm.controls[input.data_key]"
                        [values]="getFilterEnumValueNames(input.data_key)"
                        [submitted]="true"
                        (submitValueEmitter)="setValueFromDropdown(input.data_key, $event)"
                      ></app-dropdown-objects-search>
                      <div *ngIf="input.data_typ=='DATE' && !input.input_filterable_value_id"
                              [ngClass]="{ 'greater-prefix' : (input.operator_name=='greater' || input.operator_name=='greaterequal'), 'less-prefix' : (input.operator_name=='less' || input.operator_name=='lessequal') }">
                          <input type="{{input.format=='MM.yyyy'?'month':'date'}}" placeholder="2024-07" formControlName="{{input.data_key}}_{{input.operator_name}}" (change)="changed()">
                      </div>
                      <div *ngIf="input.data_typ=='NUMBER'"
                              [ngClass]="{ 'greater-prefix' : (input.operator_name=='greater' || input.operator_name=='greaterequal'), 'less-prefix' : (input.operator_name=='less' || input.operator_name=='lessequal') }">
                          <input type="number"
                                  placeholder="{{input.placeholder}}"
                                  min="0" step="1"
                                  formControlName="{{input.data_key}}_{{input.operator_name}}">
                      </div>
                      <div *ngIf="input.data_typ=='BOOLEAN' || input.data_typ=='BOOLEAN_NULL'" class="checkboxtag-container">
                          <div class="checkboxtag-wrapper">
                              <input type="checkbox" class="checkboxtag" formControlName="{{input.data_key}}_0" id="{{input.data_key}}_0" value="0">
                              <label for="{{input.data_key}}_0" class="checkboxtag-label">Alle</label>
                          </div>
                          <div class="checkboxtag-wrapper">
                              <input type="checkbox" class="checkboxtag" formControlName="{{input.data_key}}_1" id="{{input.data_key}}_1" value="1">
                              <label for="{{input.data_key}}_1" class="checkboxtag-label">Ja</label>
                          </div>
                          <div class="checkboxtag-wrapper">
                              <input type="checkbox" class="checkboxtag" formControlName="{{input.data_key}}_2" id="{{input.data_key}}_2" value="2">
                              <label for="{{input.data_key}}_2" class="checkboxtag-label">Nein</label>
                          </div>
                      </div>
                      <div *ngIf="input.data_typ=='ENUM_TAG'" class="checkboxtag-container">
                          <div class="checkboxtag-wrapper">
                              <input type="checkbox" class="checkboxtag" formControlName="{{input.data_key}}_0" id="{{input.data_key}}_0">
                              <label class="checkboxtag-label" for="{{input.data_key}}_0">Alle</label>
                          </div>
                          <div *ngFor="let enumName of input.enum; let i = index" class="checkboxtag-wrapper">
                              <input type="checkbox" class="checkboxtag" formControlName="{{input.data_key}}_{{i+1}}" id="{{input.data_key}}_{{i+1}}">
                              <label class="checkboxtag-label" for="{{input.data_key}}_{{i+1}}">{{enumName.name}}</label>
                          </div>
                      </div>
                      <app-dropdown-objects-search *ngIf="input.data_typ=='ENUM_STRING'"
                        [placeholder]="input.placeholder"
                        [enumvalue]="customFilterForm.controls[input.data_key].value"
                        [formcontrol]="customFilterForm.controls[input.data_key]"
                        [values]="getFilterEnumValueNames(input.data_key)"
                        [submitted]="true"
                        [stringPossible]="true"
                        (submitValueEmitter)="setValueFromDropdown(input.data_key, $event)"
                        (submitValueStringEmitter)="setValueFromDropdown(input.data_key, $event)"
                      ></app-dropdown-objects-search>
                    </div>
                </div>
        </div>
    </div>
</form>