<app-collapsible [isCollapsible]="!editMode" [isContentVisible]="false">
  <h3 collapsible-header class="overview_data_box_lbl_title">Service</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record">
        <label class="overview_record_lbl">Nächste Inspektion</label>
        <div class="overview_record_value">{{vehicle.maintenance.nextInspection | timestampFormat}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Letzter Zahnriemenwechsel</label>
        <div class="overview_record_value">{{vehicle.maintenance.lastBeltService | timestampFormat}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Letzter Service</label>
        <div class="overview_record_value">{{vehicle.maintenance.lastService | timestampFormat}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Nächster Service</label>
        <div class="overview_record_value">{{vehicle.naechstes_service | timestampFormat}}</div>
      </div>
      <div class="overview_record">
        <label class="overview_record_lbl">Serviceheft vorhanden</label>
        <div class="overview_record_value">{{vehicle.maintenance.serviceHistory == true ? 'Ja' : (vehicle.maintenance.serviceHistory == false ? 'Nein' : '---')}}</div>
      </div>
      <div class="overview_record" *ngIf="authService.ACCESS_ALL()">
        <label class="overview_record_lbl">{{changeValueName('planed_repair_date')}}</label>
        <div class="overview_record_value">{{vehicle.planed_repair_date | timestampFormat}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="form" class="overview_data_form">
      <div class="input_wrapper">
        <label class="overview_record_lbl">Nächste Inspektion</label>
        <input #inputDate1 type="date" formControlName="nextInspection" [class.date-input--has-value]="inputDate1.value != null" [class.input_invalid]="submitted && f.nextInspection.errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Letzter Zahnriemenwechsel</label>
        <input #inputDate2 type="date" formControlName="lastBeltService" [class.date-input--has-value]="inputDate2.value != null" [class.input_invalid]="submitted && f.lastBeltService.errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Letzter Service</label>
        <input #inputDate3 type="date" formControlName="lastService" [class.date-input--has-value]="inputDate3.value != null" [class.input_invalid]="submitted && f.lastService.errors">
      </div>
      <div class="input_wrapper">
        <label class="overview_record_lbl">Nächster Service</label>
        <input #inputDate4 type="date" formControlName="naechstes_service" [class.date-input--has-value]="inputDate4.value != null" [class.input_invalid]="submitted && f.naechstes_service.errors">
      </div>
      <app-dropdown-simple
        [title]="'Serviceheft vorhanden'"
        [formcontrol]="f.serviceHistory"
        (submitValueEmitter)="f.serviceHistory.setValue($event)"
      ></app-dropdown-simple>
      <div class="input_wrapper" *ngIf="authService.ACCESS_ALL()">
        <div class="overview_record_lbl">{{changeValueName('planed_repair_date')}}</div>
        <input #inputDate6 type="date" formControlName="planed_repair_date" [class.date-input--has-value]="inputDate6.value != null" [class.input_invalid]="submitted && f.planed_repair_date.errors">
      </div>
    </form>
  </div>
</app-collapsible>
