<app-collapsible [isCollapsible]="!editMode && !authService.isSinhuber()" [isContentVisible]="false">
  <h3 collapsible-header class="overview_data_box_lbl_title">Kundenfahrzeug</h3>
  <div collapsible-content>
    <div *ngIf="!editMode">
      <div class="overview_record">
        <div class="overview_record_lbl">Kunden-ID</div>
        <div class="overview_record_value">{{vehicle.customer_id}}</div>
      </div>
      <!-- <div class="overview_record">
        <div class="overview_record_lbl">Auslieferungsdatum</div>
        <div class="overview_record_value">{{vehicle.guarantee == true ? 'Ja' : (vehicle.guarantee == false ? 'Nein' : '---')}}</div>
      </div> -->
      <div class="overview_record">
        <div class="overview_record_lbl">Kennzeichen</div>
        <div class="overview_record_value">{{vehicle.license_plate ? vehicle.license_plate : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="form">
      <!-- <div class="overview_record">
        <div class="overview_record_lbl">Auslieferungsdatum</div>
        <div class="input_date" appCustomDateFormat3>
          <input #dateInput2 class="inputfield" [ngClass]="(submitted && (form.controls.handing_over_date.errors?.validateDateInputLong?.valid == false)) ? 'inputfield_invalid' : ''" [ngStyle]="{'width': '76%'}" placeholder="TT.MM.JJJJ" type="text" maxlength="10" [min]="minDate" [matDatepicker]="picker1" [formControl]="form.controls.handing_over_date" formControlName="handing_over_date">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1
          ></mat-datepicker>
        </div>
      </div> -->
      <div class="input_wrapper">
        <label class="overview_record_lbl">Kennzeichen</label>
        <input type="text" class="overview_input" placeholder="Kennzeichen" formControlName="license_plate">
      </div>
    </form>
  </div>
</app-collapsible>
