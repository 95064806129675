import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LedgerEntry } from "../LedgerEntry";
import { LedgerEntrySubject, LedgerEntryTyp } from "src/app/view/accounting/dialog-kassabuch-create/dialog-kassabuch-create.component";
import { DateHelper } from "src/app/utils/DateHelper";
import { formatDate } from "@angular/common";

export class LedgerEntryForm {

  public static getForm(formBuilder: FormBuilder, ledgerEntry?: LedgerEntry): FormGroup {
    let today = formatDate(new Date(),'yyyy-MM-dd','de');
    let form = formBuilder.group({
      ledger_entry_id: [ledgerEntry ? ledgerEntry.ledger_entry_id : null],
      location_id: [ledgerEntry ? ledgerEntry.location_id : null],
      ledger_entry_status: [ledgerEntry ? ledgerEntry.ledger_entry_status : null],
      date: [ledgerEntry ? DateHelper.getConvertedDateForInputFull(ledgerEntry.date) : today, [Validators.required]],
      subject: [ledgerEntry ? ledgerEntry.subject : LedgerEntrySubject.INDIVIDUELL, [Validators.required]],
      individuell_subject: [null, Validators.required],
      amount: [ledgerEntry ? ledgerEntry.amount : null, [Validators.required]],
      typ: [LedgerEntryTyp.EINZAHLUNG, [Validators.required]],
    });
    if(form.get('amount').value  < 0){
      form.get('typ').setValue(LedgerEntryTyp.AUSZAHLUNG);
      form.get('amount').setValue(form.get('amount').value * -1)
    }
    if(form.get('subject').value == LedgerEntrySubject.INDIVIDUELL) form.get('individuell_subject').addValidators(Validators.required);
    else form.get('individuell_subject').removeValidators(Validators.required);
    form.get('subject').valueChanges.subscribe((change) => {
      if(change == LedgerEntrySubject.INDIVIDUELL){
        form.get('individuell_subject').addValidators(Validators.required);
      } else {
        form.get('individuell_subject').removeValidators(Validators.required);
      }
      form.get('individuell_subject').updateValueAndValidity();
    })
    return form;
  }

  public static getObject(form: FormGroup): LedgerEntry {
    let subject = form.get('subject').value == LedgerEntrySubject.INDIVIDUELL ? form.get('individuell_subject').value : form.get('subject').value;
    let amount = form.get('typ').value == 'Einzahlung' ? form.get('amount').value : (form.get('amount').value * -1)
    let date = new Date(DateHelper.convertDateStringToTimestamp(form.get('date').value));
    date.setHours(22,0,0,0);
    let obj = new LedgerEntry(
      form.get('ledger_entry_id').value,
      form.get('location_id').value,
      date.getTime(),
      subject,
      amount,
      form.get('ledger_entry_status').value,
      null
    );
    return obj;
  }
}
