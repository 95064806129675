import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { Location } from 'src/app/buisness-object/location/Location';
import { PageHandler } from 'src/app/helpers/PagerHandler';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { LocationService } from 'src/app/service/location/location.service';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { AuthGuard } from 'src/app/helpers/AuthGuard';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Subscription, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { SettingsService } from 'src/app/service/settings/settings.service';
import { Setting } from 'src/app/buisness-object/settings/Setting';
import { SettingFormHelper } from 'src/app/buisness-object/settings/SettingFormHelper';
import { ErrorHelper } from 'src/app/error/ErrorHelper';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
  public surface = 1;
  public employees: Employee[] = [];
  public employeesFiltered: Employee[] = [];
  public locations: Location[] = [];
  public locationsFiltered: Location[] = [];
  public settings: Setting[];
  public selectedEmployee: Employee;
  public form: UntypedFormGroup;
  public submitted = false;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public pageHandlerLoc: PageHandler;
  public pageInfoLoc: string;
  public sliceToLoc: number;
  public sliceFromLoc: number;
  public authGuard = new AuthGuard();
  public roles = [];
  public formSubscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public isLoaded = false;
  public formSettings: FormGroup;
  public settingsChanged = false;

  constructor(
    private eService: EmployeeService,
    private locService: LocationService,
    private formBuilder: UntypedFormBuilder,
    private dService: DialogService,
    private authService: AuthenticationService,
    private settingService: SettingsService,
  ) { }

  ngOnInit(): void {
    this.roles = this.authService.getRoles();
    this.loadData();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.formSubscription) this.formSubscription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }


  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_employee') this.deleteEmployee(value.submit_value);
        if(value.typ == 'settings_verwerfen') this.settingsVerwerfen();
      }
    });
  }

  loadData() {
    forkJoin({
      locations: this.locService.getLocations(true),
      employees: this.eService.getEmployeesServerside(),
      settings: this.settingService.getSettings(),
    }).subscribe((result) => {
      if(result){
        this.locations = result.locations;
        this.locationsFiltered = this.locations;
        this.employees = result.employees;
        this.settings = result.settings;
        this.defineRole();
        this.addLocationToEmployees();
        this.setPagesEmployees();
        this.setPagesLocations();
        this.isLoaded = true;
      }
    });
  }

  defineRole() {
    for(let e of this.employees){
      e.role = this.authService.setRole(e.roles);
    }
  }

  addLocationToEmployees() {
    for(let employee of this.employees) {
      for(let location of this.locations) {
        if(employee.locationId == location.locationId){
          employee.location = location;
        }
      }
    }
    this.employeesFiltered = this.employees;
  }

  setPagesEmployees() {
    this.pageHandler = new PageHandler(this.employeesFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  setPagesLocations() {
    this.pageHandlerLoc = new PageHandler(this.locationsFiltered.length);
    this.sliceFromLoc = this.pageHandlerLoc.sliceFrom;
    this.sliceToLoc = this.pageHandlerLoc.sliceTo;
    this.pageInfoLoc = this.pageHandlerLoc.getLabel();
  }

  createFormGroup(employee?: Employee) {
    this.form = this.formBuilder.group({
      employee_id: [employee ? employee.id : 0, Validators.required],
      firstName: [employee ? employee.firstName : null, Validators.required],
      lastName: [employee ? employee.lastName : null, Validators.required],
      email: [employee ? employee.email : null, [Validators.required, Validators.email]],
      location: [employee ? employee.location : null, Validators.required],
      mandant_id: [Number(localStorage.getItem('mandant_id')), Validators.required],
      username: [employee ? employee.username : null, Validators.required],
      password: [null, [Validators.required, Validators.pattern('^.{8,}$')]],
      password_new: [null, [Validators.pattern('^.{8,}$')]],
      create_new_password: [false],
      active: [employee ? employee.active : true, Validators.required],
      role: [employee ? employee.role : null, Validators.required]
    })
    if(this.form.controls.role.value == 1){
      this.form.controls.role.setValue(2);
    }

    this.formSubscription = this.form.valueChanges.subscribe(change => {
      this.formSubscription.unsubscribe();
    })
  }

  changeSurface(surface: number) {
    switch (surface) {
      case 1: this.selectedEmployee = null; break;
      case 2:  break;
      case 3:  break;
      case 4:  break;
      case 5:  break;
      case 6:  break;
      case 7:
            this.formSettings = SettingFormHelper.getForm(this.formBuilder, 'preisblatt', this.settings);
            this.formSettings.valueChanges.subscribe(() => { this.settingsChanged = true;});
            break;
      default: break;
    }
    this.surface = surface;
  }

  openDetails(employee: Employee) {
    this.createFormGroup(employee);
    this.selectedEmployee = employee;
    this.surface = 3;
  }

  openEdit(employee?: Employee) {
    if(employee) this.selectedEmployee = employee;
    this.createFormGroup(this.selectedEmployee);
    if(this.selectedEmployee){
      this.form.controls['password'].setValidators(null);
      this.form.controls['password'].setErrors(null);
    }
    this.surface = 5;
  }

  updateListLocal(employee: Employee) {
    for(let i = 0; i < this.employees.length; i++){
      if(this.employees[i].id == employee.id){
        this.employees[i] = employee;
      }
    }
  }

  pageHandling(next: boolean, num: number) {
    if(num == 1){
      this.pageHandler.action(next);
      this.sliceFrom = this.pageHandler.sliceFrom;
      this.sliceTo = this.pageHandler.sliceTo;
      this.pageInfo = this.pageHandler.getLabel();
    } else if(num == 2){
      this.pageHandlerLoc.action(next);
      this.sliceFromLoc = this.pageHandlerLoc.sliceFrom;
      this.sliceToLoc = this.pageHandlerLoc.sliceTo;
      this.pageInfoLoc = this.pageHandlerLoc.getLabel();
    }
  }

  openCreate() {
    this.selectedEmployee = null;
    this.createFormGroup();
    this.surface = 5;
  }

  cancelCreate() {
    this.createFormGroup();
    this.submitted = false;
    this.surface = 1;
  }

  createEmployee() {
    this.submitted = true;
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return;
    }
    if(!this.selectedEmployee && !this.form.controls.password.value){
      return;
    }
    if(this.form.controls['create_new_password'].value == true && (this.form.controls['password_new'].value == null || this.form.controls['password_new'].value.length < 8)){
      return;
    }
    let employee = new Employee(
      this.form.controls.employee_id.value,
      this.form.controls.username.value,
      this.form.controls.firstName.value,
      this.form.controls.lastName.value,
      this.form.controls.email.value,
      this.form.controls.location.value.locationId,
      this.form.controls.mandant_id.value,
      this.form.controls.active.value,
      this.form.controls.password.value
    );
    employee.role = this.form.controls.role.value;
    employee.roles = this.authService.getAuthenticationRoles(this.form.controls.role.value);
    employee.location = this.form.controls.location.value;
    if(this.selectedEmployee){
      this.eService.updateEmployee(employee).subscribe((result) => {
        if(result){
          if(this.form.controls['create_new_password'].value == true){
            this.eService.updateEmployeePassword(employee.id, this.form.controls['password_new'].value).subscribe((done) => {
              if(done){
                result.role = employee.role;
                result.roles = employee.roles;
                result.location = employee.location;
                let index = this.employees.findIndex(e => e.id == result.id);
                if(index > -1) this.employees[index] = result;
                this.selectedEmployee = result;
                this.surface = 3;
              }
            });
          } else {
            result.role = employee.role;
            result.roles = employee.roles;
            result.location = employee.location;
            let index = this.employees.findIndex(e => e.id == result.id);
            if(index > -1) this.employees[index] = result;
            this.selectedEmployee = result;
            this.surface = 3;
          }
        }
      })
    } else {
      this.eService.createEmployee(employee).subscribe((result) => {
        if(result){
          result.role = employee.role;
          result.roles = employee.roles;
          result.location = employee.location;
          this.selectedEmployee = result;
          this.employees.push(result);
          this.setPagesEmployees();
          this.surface = 3;
        }
      })
    }
    this.submitted = false;
  }

  searchAction(searchValue: string) {
    if(this.surface == 1){
      this.employeesFiltered = this.employees.filter((employee) => {
        if(searchValue && searchValue.length > 0){
          this.employeesFiltered = [];
          return employee.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
                employee.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
                employee.username.toLowerCase().includes(searchValue.toLowerCase());
        } else {
          return this.employees;
        }
      });
      this.setPagesEmployees();
    } else if(this.surface == 4){
      this.locationsFiltered = this.locations.filter((location) => {
        if(searchValue && searchValue.length > 0){
          this.locationsFiltered = [];
          return location.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                location.postCode.toLowerCase().includes(searchValue.toLowerCase()) ||
                location.city.toLowerCase().includes(searchValue.toLowerCase()) ||
                location.street.toLowerCase().includes(searchValue.toLowerCase());
        } else {
          return this.locations;
        }
      });
      this.setPagesLocations();
    }
  }

  settingsVerwerfenAction() {
    this.dService.openQuery(
      {
        title: 'Verwerfen',
        message: 'Sind Sie sicher, dass Sie die Einstellung verwerfen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Ja',
        typ: 'settings_verwerfen',
      }
    );
  }

  settingsVerwerfen() {
    this.formSettings = SettingFormHelper.getForm(this.formBuilder, 'preisblatt', this.settings);
    this.formSettings.valueChanges.subscribe(() => {this.settingsChanged = true;});
    this.settingsChanged = false;
    this.submitted = false;
  }

  settingsSave() {
    this.submitted = true;
    if(this.formSettings.invalid) return;
    let newSettings = SettingFormHelper.createObject(this.formSettings, this.settings);
    this.settingService.updateSettings(newSettings).subscribe((result) => {
      if(result){
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Einstellungen aktualisiert.',
          success: true
        });
        this.submitted = false;
      }
    })
  }

  deleteAction(employee: Employee) {
    // if(employee){
    //   this.dService.openQuery(
    //     {
    //       title: 'Mitarbeiter wirklich löschen?',
    //       message: 'Sind sie sicher, dass Sie den Mitarbeiter ' + employee.firstName + ' ' + employee.lastName +' unwiderruflich löschen möchten?',
    //       btn_cancel_txt: 'Abbrechen',
    //       btn_submit_txt: 'Löschen',
    //       typ: 'delete_employee',
    //       submit_value: employee.id,
    //     }
    //   );
    // }
  }
  deleteEmployee(employee_id: number) {
    // this.eService.deleteEmployee(employee_id).subscribe((success) => {
    //   if(success){
    //     let index = this.employees.findIndex(e => e.id == employee_id);
    //     if(index > -1) this.employees.splice(index, 1);
    //     this.dService.showNotification({
    //       title: 'Erfolgreich',
    //       message: 'Mitarbeiter entfernt.',
    //       success: true
    //     });
    //   }
    // })
  }
}


// updateEmployee() {
//   this.submitted = true;
//   if(!this.form.valid){
//     this.dService.showNotification({
//       title: 'Fehlgeschlagen',
//       message: 'Fehlende Daten.',
//       success: false
//     });
//     return;
//   }
//   if(this.selectedEmployee.roles.length == 0){
//     this.dService.showNotification({
//       title: 'Fehlgeschlagen',
//       message: 'Es muss eine Rolle zugewiesen werden.',
//       success: false
//     });
//     return;
//   }
//   let employee = new Employee(
//     this.selectedEmployee.id,
//     this.f.username.value,
//     this.f.firstName.value,
//     this.f.lastName.value,
//     this.selectedEmployee.email,
//     this.f.locationId.value,
//     this.selectedEmployee.mandantId,
//     this.f.active.value
//   );
//   employee.roles = this.f.roles.value;
//   this.eService.updateEmployee(employee).subscribe((updatedEmployee) => {
//     if(updatedEmployee){
//       this.locations.forEach((loc) => {
//         if(updatedEmployee.locationId == loc.locationId){
//           updatedEmployee.location = loc;
//         }
//       });
//       if(updatedEmployee.id == Number(localStorage.getItem('user_id')) && updatedEmployee.locationId != Number(localStorage.getItem('location_id'))){
//         localStorage.setItem('location_id',String(updatedEmployee.locationId));
//       }
//       this.updateListLocal(updatedEmployee);
//       this.selectedEmployee = updatedEmployee;
//       this.dService.showNotification({
//         title: 'Erfolgreich',
//         message: 'Mitarbeiter aktualisiert.',
//         success: true
//       });
//       this.submitted = false;
//       this.surface = 3;
//     }
//   })
// }
