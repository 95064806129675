import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bill, BillStatus, getBillStatusLabel, getBillStatusLabelStyle } from 'src/app/buisness-object/document/Bill';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { BaseComponent } from '../../BaseComponent';
import { EnumService } from 'src/app/service/enum/enum.service';
import { VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';

export enum BillDetailsTab {
  Details = 1,
  Tickets = 2,
}

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrl: './bill-details.component.css'
})
export class BillDetailsComponent extends BaseComponent implements OnInit {
  @Input() obj: Bill;
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  public tabView = BillDetailsTab;
  public showTab: BillDetailsTab = BillDetailsTab.Details;
  public shwoActions = false;
  public paymentConditionValue
  public downPaymentConditionValue;
  public finanzierungsartenValue;
  public finanzierungsgesellschaftValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public total_condition;
  public registrationDocumentValue;
  public billStatus;
  public billStatusStyle;

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.obj.payment_method);
    this.billStatus = getBillStatusLabel(this.obj.status);
    this.billStatusStyle = getBillStatusLabelStyle(this.obj.status);
  }

  selectionBack() {
    this.selectionBackEmitter.emit();
  }

  getConditionColor(value: string) {
    return VehicleStatus.getConditionColor(value);
  }
}
