import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Attachment, AttachmentHelper } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrl: './files.component.css'
})
export class FilesComponent implements OnInit, OnChanges {
  @Input() obj: any;
  @Output() successDeleteEmitter = new EventEmitter<any>();
  public attachments: Attachment[] = [];

  constructor(
    private attachmentService: AttachmentService,
  ){}

  ngOnInit(): void {
    if(this.obj){
      let typ =  AttachmentHelper.getAttachmentTyp(this.obj);
      let typID =  AttachmentHelper.getAttachmentTypID(this.obj);

      this.attachmentService.getAttachmentsByTypId(typ, typID).subscribe((result) => {
        if(result){
          this.attachments = result;
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes[this.obj]){
      // console.log(changes[this.obj]?.currentValue.vertrag_status)
      // console.log("change")
    }
  }

  successCreate(attachment: Attachment) {
    this.attachments.push(attachment);
  }

  successDelete(attachment: Attachment) {
    let index = this.attachments.findIndex((a) => a.attachment_id == attachment.attachment_id);
    if(index > -1){
      this.attachments.splice(index,1);
    }
  }
}
