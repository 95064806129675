<div class="data_container" [ngStyle]="{'margin-bottom': '40vh'}">
    <div class="lbl_bold_light_color">Tickets</div>
    <div class="header">
        <div class="lbl_regular_light_color">{{threads.length == 0 ? 'Keine offenen Tickets' : 'Offene Tickets : ' + threads.length}}</div>
        <div class="btn_submit" (click)="createTicket()">Ticket erstellen</div>
    </div>
    <div class="ticket_container">
        <div *ngFor="let thread of threads" class="ticket_message_container">
            <div class="user_initials_message">{{thread.employee.getInitials()}}</div>
            <div>
                <div class="lbl_regular_light_color msg_credentials">{{thread.employee.firstName}} {{thread.employee.lastName}}</div>
                <div class="message">
                    <div class="message_header">
                        <div class="lbl_regular_light_color msg_credentials">{{thread.createdDate | date: 'dd.MM.YYYY, HH:mm'}}</div>
                        <div class="state_container" [ngClass]="thread.resolved == 0 ? 'state_open' : 'state_done'">{{thread.resolved == 0 ? 'Offen' : 'Erledigt'}}</div>
                    </div>
                    <div  [innerHTML]="thread.messages[0].getMessageText(employees)"></div>
                    <div class="action_container">
                        <div *ngIf="thread.creator == userId" class="action"(click)="resolveEmitter.emit(thread)">
                            <img class="icon" src="../../../../../assets/lock-blue.svg">
                            <div class="action_lbl">Auflösen</div>
                        </div>
                        <div class="action" (click)="openTicket(thread)">
                            <img class="icon" [ngStyle]="{'width': '12px'}" src="../../../../../assets/details-eye-blue.svg">
                            <div class="action_lbl">Zum Ticket</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="thread.resolved == 0" class="footer">
        <div class="input_message_container">
            <textarea #textArea class="message_box" placeholder="Schreiben Sie Ihr Anliegen" (input)="getPosition($event, textArea.value)"></textarea>
            <div class="btn_submit" (click)="submit()"><img class="icon_send" src="../../../../assets/send.svg"></div>
        </div>
    </div> -->
</div>
