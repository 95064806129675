<app-collapsible [isCollapsible]="!editMode && !authService.isSinhuber()" [isContentVisible]="false">
  <h3 collapsible-header class="overview_data_box_lbl_title">Saisonale</h3>
  <div collapsible-content>
    <div *ngIf="!editMode" class="overview_data_box_vehicle">
      <div class="overview_record" *ngIf="showValue('winter_diesel')">
        <div class="overview_record_lbl">Winterdiesel</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.winter_diesel)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('winter_tyres')">
        <div class="overview_record_lbl">Winterreifen</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.winter_tyres)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('winter_tyres_description')">
        <div class="overview_record_lbl">Winterreifen-Info</div>
        <div class="overview_record_value">{{vehicle.winter_tyres_description ? vehicle.winter_tyres_description : '---'}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('summer_tyres')">
        <div class="overview_record_lbl">Sommerreifen</div>
        <div class="overview_record_value">{{getBooleanStringValue(vehicle.summer_tyres)}}</div>
      </div>
      <div class="overview_record" *ngIf="showValue('summer_tyres_description')">
        <div class="overview_record_lbl">Sommerreifen-Info</div>
        <div class="overview_record_value">{{vehicle.summer_tyres_description ? vehicle.summer_tyres_description : '---'}}</div>
      </div>
    </div>
    <form *ngIf="editMode" [formGroup]="form" class="overview_data_form">
      <div *ngIf="showValue('winter_diesel')">
      <app-dropdown-simple
        [title]="'Winterdiesel'"
        [formcontrol]="f.winter_diesel"
        [isOptional]="true"
        (submitValueEmitter)="f.winter_diesel.setValue($event)"
      ></app-dropdown-simple>
      </div>
      <div *ngIf="showValue('winter_tyres')">
      <app-dropdown-simple
        [title]="'Winterreifen'"
        [formcontrol]="f.winter_tyres"
        [isOptional]="true"
        (submitValueEmitter)="f.winter_tyres.setValue($event)"
      ></app-dropdown-simple>
      </div>
      <div class="input_wrapper" *ngIf="showValue('winter_tyres_description')">
        <label class="overview_record_lbl">Winterreifen-Info</label>
        <input type="text" class="overview_input" placeholder="Winterreifen-Info" formControlName="winter_tyres_description">
      </div>
      <div *ngIf="showValue('summer_tyres')">
        <app-dropdown-simple
          [title]="'Sommerreifen'"
          [formcontrol]="f.summer_tyres"
          [isOptional]="true"
          (submitValueEmitter)="f.summer_tyres.setValue($event)"
        ></app-dropdown-simple>
      </div>
      <div class="input_wrapper" *ngIf="showValue('summer_tyres_description')">
        <label class="overview_record_lbl">Sommerreifen-Info</label>
        <input type="text" class="overview_input" placeholder="Sommerreifen-Info" formControlName="summer_tyres_description">
      </div>
    </form>
  </div>
</app-collapsible>


    <!-- <div class="overview_record">
      <div class="overview_record_lbl">Winterdiesel</div>
      <div #dropdown_1 class="dropdown_wrapper_overview">
          <div class="dropdown_header_data_overview" (click)="shwoDropdown_1 = !shwoDropdown_1" appDropdown [show]="shwoDropdown_1" (changedValueEmitter)="shwoDropdown_1 = $event">
              <div class="dropdown_header_data_lbl_overview" [ngClass]="f.winter_diesel.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{f.winter_diesel.value == true ? 'Ja' : (f.winter_diesel.value == null ? 'Winterdiesel' : 'Nein')}}</div>
              <img class="dropdown_header_icon_overview" [ngClass]="shwoDropdown_1 ? 'dropdown_header_icon_active_overview' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="shwoDropdown_1 ? 'rotated' : 'default'">
          </div>
          <div class="dropdown_data_body_overview" *ngIf="shwoDropdown_1">
              <div class="dropdown_data_body_element_overview" (click)="f.winter_diesel.setValue(true); shwoDropdown_1 = false">Ja</div>
              <div class="dropdown_data_body_element_overview" (click)="f.winter_diesel.setValue(false); shwoDropdown_1 = false">Nein</div>
          </div>
      </div>
    </div>
    <div class="overview_record">
      <div class="overview_record_lbl">Winterreifen</div>
      <div #dropdown_1 class="dropdown_wrapper_overview">
          <div class="dropdown_header_data_overview" (click)="shwoDropdown_2 = !shwoDropdown_2" appDropdown [show]="shwoDropdown_2" (changedValueEmitter)="shwoDropdown_2 = $event">
              <div class="dropdown_header_data_lbl_overview" [ngClass]="f.winter_tyres.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{f.winter_tyres.value == true ? 'Ja' : (f.winter_tyres.value == null ? 'Winterreifen' : 'Nein')}}</div>
              <img class="dropdown_header_icon_overview" [ngClass]="shwoDropdown_2 ? 'dropdown_header_icon_active_overview' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="shwoDropdown_2 ? 'rotated' : 'default'">
          </div>
          <div class="dropdown_data_body_overview" *ngIf="shwoDropdown_2">
              <div class="dropdown_data_body_element_overview" (click)="f.winter_tyres.setValue(true); shwoDropdown_2 = false">Ja</div>
              <div class="dropdown_data_body_element_overview" (click)="f.winter_tyres.setValue(false); shwoDropdown_2 = false">Nein</div>
          </div>
      </div>
    </div> -->

    <!-- <div class="overview_record">
      <div class="overview_record_lbl">Sommerreifen</div>
      <div #dropdown_1 class="dropdown_wrapper_overview">
          <div class="dropdown_header_data_overview" (click)="shwoDropdown_3 = !shwoDropdown_3" appDropdown [show]="shwoDropdown_3" (changedValueEmitter)="shwoDropdown_3 = $event">
              <div class="dropdown_header_data_lbl_overview" [ngClass]="f.summer_tyres.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{f.summer_tyres.value == true ? 'Ja' : (f.summer_tyres.value == null ? 'Sommerreifen' : 'Nein')}}</div>
              <img class="dropdown_header_icon_overview" [ngClass]="shwoDropdown_3 ? 'dropdown_header_icon_active_overview' : ''" src="../../../../assets/arrow-dark.svg" [@rotate]="shwoDropdown_3 ? 'rotated' : 'default'">
          </div>
          <div class="dropdown_data_body_overview" *ngIf="shwoDropdown_3">
              <div class="dropdown_data_body_element_overview" (click)="f.summer_tyres.setValue(true); shwoDropdown_3 = false">Ja</div>
              <div class="dropdown_data_body_element_overview" (click)="f.summer_tyres.setValue(false); shwoDropdown_3 = false">Nein</div>
          </div>
      </div>
    </div> -->
