import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { EM_MULTIPLIER } from 'src/app/buisness-object/menu/SubMenu';
import { SubMenuColumn } from 'src/app/buisness-object/menu/SubMenuColumn';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { NumberFormatPipe } from 'src/app/helpers/NumberFormatPipe.pipe';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { ImageHandler } from 'src/app/utils/ImageHandler';
import { BaseComponent } from '../BaseComponent';
import { Kaufvertrag } from 'src/app/buisness-object/document/Kaufvertrag';
import { LedgerEntry } from 'src/app/buisness-object/document/LedgerEntry';
import { Bill, getBillStatusLabel } from 'src/app/buisness-object/document/Bill';
import { EnumService } from 'src/app/service/enum/enum.service';
import { Tire } from 'src/app/buisness-object/tires/Tire';
import { TireService } from 'src/app/service/tire/tire.service';
import { PaymentStatus } from '../dokumente/dokumente.component';

@Component({
  selector: 'app-sub-menu-column-data',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './sub-menu-column-data.component.html',
  styleUrl: './sub-menu-column-data.component.css'
})
export class SubMenuColumnDataComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() subMenuColumn: SubMenuColumn;
  @Input() object: any;
  @Input() leftWidth: number;
  public typ = "label";
  public data;
  public columnId;
  public tagValueToCreate;
  public tagEnumToCreate;
  public tagRangeToCreate;
  public tagStringToCreate;
  public tagBooleanToCreate;
  public showImageLoading = false;
  public objectInstance = 'vehicle';

  @Output() calculatePricesheetEmitter = new EventEmitter<any>();

  constructor(
    public authService: AuthenticationService,
    private eService: EnumService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private tireService: TireService
  ) {
    super(authService, eService);
  }

  ngOnInit(): void {
    if(this.object instanceof Vehicle) this.objectInstance = 'vehicle';
    else if(this.object instanceof Tire) this.objectInstance = 'tire';
    this.columnId = 'column-data-' + this.getObjectId() + '_' + this.subMenuColumn.sub_menu_column_id;
    this.data = this.getMenuData();
    if(this.typ == 'label') this.handleLabelWidth();
    if(this.object instanceof Vehicle && !this.object.isReady){
      this.calculatePricesheetEmitter.emit(this.object);
    }
  }

  ngAfterViewInit(): void {
    if(this.tagEnumToCreate) this.createTagEnum();
    if(this.tagRangeToCreate) this.createTagRange();
    if(this.tagValueToCreate) this.createTagValue();
    if(this.tagStringToCreate) this.createTagString();
    if(this.tagBooleanToCreate) this.createTagBoolean();
    if(this.subMenuColumn.is_fixed) this.setFixed();
  }

  getObjectId() {
    if(this.object instanceof Kaufvertrag){
      return this.object.purchase_contract_id;
    } else if(this.object instanceof LedgerEntry){
      return this.object.ledger_entry_id;
    } else if(this.object instanceof Bill){
      return this.object.bill_id;
    } else {
      return this.object.id;
    }
  }

  handleLabelWidth() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = getComputedStyle(document.body).fontSize;
    let labelWidthEm = this.pxToEm(context.measureText(this.data).width)*1.5;
    let columnWidthEm = this.subMenuColumn.width*EM_MULTIPLIER;
    if(this.data != null && labelWidthEm > columnWidthEm){
      while(labelWidthEm > columnWidthEm){
        if(this.data != undefined && this.data != null){
          this.data = this.data.slice(0, this.data?.length - 4)+'...';
          labelWidthEm = this.pxToEm(context.measureText(this.data).width)*1.5;
        }
      }
    }
  }

  pxToEm(px: number){
    const baseFontSize = 16;
    return px/baseFontSize;
  }

  getMenuData() {
    const getNestedValue = (object, keys) => {
      if(keys.length === 0) {
        return object;
      }
      let key = keys.shift();
      if(object[key] === undefined || object[key] === null) {
        return null;
      }
      if (typeof object[key] === 'function') {
        return getNestedValue(object[key](), keys);
      }
      return getNestedValue(object[key], keys);
    };
    if(this.subMenuColumn.data_key.includes('.')) {
      let keys = this.subMenuColumn.data_key.split('.');
      let value = getNestedValue(this.object, keys);
      return value != undefined && value != null ? this.getLabel(value.toString()) : '---';
    }
    return this.getLabel(this.object[this.subMenuColumn.data_key]);
  }

  // getMenuData() {
  //   if(this.subMenuColumn.data_key.includes('.') && !this.subMenuColumn.calculated){
  //     let keys = this.subMenuColumn.data_key.split('.');
  //     let value = this.object;
  //     for(let i = 0; i < keys.length; i++){
  //       value = value[keys[i]];
  //       if(value == undefined || value == null){
  //         break;
  //       }
  //     }
  //     return value != undefined && value != null ? this.getLabel(value.toString()) : '---';
  //   }
  //   return this.getLabel(this.object[this.subMenuColumn.data_key]);
  // }

  getLabel(value: string) {
    this.isTagToCreate();
    if(this.subMenuColumn.calculated === 1){
      return this.getCalcluatedValue();
    }
    if(this.subMenuColumn.data_typ === 'STRING'){
      return value && value.length > 0 ? value : '---';
    }
    if(this.subMenuColumn.data_typ === 'DATE'){
      if(value == null || value == '') return '---';
      return this.getDateValue(value);
    }
    if(this.subMenuColumn.data_typ === 'NUMBER'){
      if(value == null || value == '') return '---';
      let num = this.getNumberValue(value);
      return num;
    }
    if(this.subMenuColumn.data_typ === 'BOOLEAN'){
      return this.getBooleanValue(value);
    }
    if(this.subMenuColumn.data_typ === 'ENUM'){
      if(this.subMenuColumn.data_object == 'bill' && this.subMenuColumn.data_key == 'status') return getBillStatusLabel(this.object[this.subMenuColumn.data_key]);
      return this.getEnumValue(this.subMenuColumn.data_key, this.object[this.subMenuColumn.data_key]);
    }
    return (value != null && value != '') ? value : '---';
  }

  isTagToCreate() {
    if(this.subMenuColumn.column_tag != null){
      if(this.subMenuColumn.column_tag.tag_data_typ === 'RANGE'){
        this.tagRangeToCreate = true;
        return;
      }
      if(this.subMenuColumn.column_tag.tag_data_typ === 'ENUM'){
        this.tagEnumToCreate = true
        return;
      }
      if(this.subMenuColumn.column_tag.tag_data_typ === 'BOOLEAN'){
        this.tagBooleanToCreate = true
        return;
      }
      if(this.subMenuColumn.column_tag.tag_data_typ === 'STRING'){
        this.tagStringToCreate = true
        return;
      }

      //wird das benutzt ?
      if(this.subMenuColumn.column_tag.tag_data_typ === 'NUMBER'){
        this.tagValueToCreate = true
        return;
      }
    }
  }

  getCalcluatedValue() {
    //special functions
    if(this.subMenuColumn.data_key == "getCustomerName"){
      if(this.object.customer != null){
        return this.object.customer.getFullName();
      } else {
        let customers = this.customerService.customers$.getValue();
        if(customers == null){
          this.customerService.getCustomer().subscribe((customers) => {
            return this.object.getCustomerName(customers);
          })
        } else {
          return this.object.getCustomerName(customers);
        }
      }
    }
    //functions
    if(typeof this.object[this.subMenuColumn.data_key] === 'function'){
      let value = this.object[this.subMenuColumn.data_key]();
      return this.getDataTypValue(value);
    } else if(this.subMenuColumn.data_key.includes('.')){
      //Ween tieferes Object und funktion
      let deepObjKey = this.subMenuColumn.data_key.split('.')[0];
      let deepFunction = this.subMenuColumn.data_key.split('.')[1];
      let deepObj = this.object[deepObjKey];
      if(deepObj && typeof deepObj[deepFunction] == 'function'){
        let value = deepObj[deepFunction]();
        return this.getDataTypValue(value);
      }
    } else {
      //Wenn keine funktion aber spezieller Datensatz
      if(this.subMenuColumn.data_key === "getUstVor") {
        let value = this.object[this.subMenuColumn.data_key]();
        if(value == undefined) return '---';
        return this.getNumberValue(value);
      }
      if(this.subMenuColumn.data_key === 'thumbnail'){
        this.typ = 'thumbnail';
        if(this.object[this.subMenuColumn.data_key] == null && (this.object.pictures[0]?.id || this.object.pictures[0]?.picture_id)){
          this.showImageLoading = true;
          if(this.object instanceof Vehicle){
            ImageHandler.getThumbnailVehicle(this.object.pictures[0]?.id, this.vehicleService).then((base64) => {
              if(base64){
                this.object.thumbnail = base64;
                this.data = base64;
                this.showImageLoading = false;
                return base64;
              }
            });
          } else if(this.object instanceof Tire){
            ImageHandler.getThumbnailTire(this.object.pictures[0]?.picture_id, this.tireService).then((base64) => {
              if(base64){
                this.object.thumbnail = base64;
                this.data = base64;
                this.showImageLoading = false;
                return base64;
              }
            });
          }
        } else if(this.object[this.subMenuColumn.data_key] != null) {
          return this.object[this.subMenuColumn.data_key];
        }
        return null;
      }
      if(this.subMenuColumn.data_key === 'make_and_model'){
        return this.object.brand.makeName + ' ' + this.object.model.name;
      }
      if(this.subMenuColumn.data_key === 'make_and_version'){
        return this.object.brand.makeName + ' ' + this.object.model.version;
      }
      if(this.subMenuColumn.data_key === 'model_and_version'){
        let vehicle;
        if(this.object instanceof Vehicle){
          vehicle = this.object
        } else {
          vehicle = this.object.vehicle;
        }
        if(vehicle){
          if(vehicle.model.version.startsWith(vehicle.model.name)) {
            return vehicle.model.version;
          }
          return vehicle.model.name + ' ' + vehicle.model.version;
        }
        return '---';
      }
      if(this.subMenuColumn.data_key === "getOnlineSeit") {
          let value = this.object[this.subMenuColumn.data_key]();
          if(value == undefined) return '---';
          return value + (this.subMenuColumn.suffix ? this.subMenuColumn.suffix : "");
      }
      if(this.subMenuColumn.data_key === 'priceSheet.steuersatz'){
        switch(this.object.priceSheet?.steuersatz){
          case 1: return '20%';
          case 2: return '0%';
          case 3: return '19%';
          default: return '---';
        }
      }
      if(this.subMenuColumn.data_key == 'payment_status'){
        this.tagStringToCreate = true;
        switch (this.object.payment_status) {
          case 0: return PaymentStatus.NichtBezahlt; break;
          case 1: return PaymentStatus.AnzahlungBezahlt; break;
          case 2: return PaymentStatus.Bezahlt; break;
          default: return '---'; break;
        }
      }
      return (this.object[this.subMenuColumn.data_key] != null && this.object[this.subMenuColumn.data_key] != '') ? this.object[this.subMenuColumn.data_key] : '---';
    }
  }

  getDataTypValue(value): any {
    if(value != null){
      switch (this.subMenuColumn.data_typ) {
        case 'STRING':
          return value;
        case 'NUMBER':
          return this.getNumberValue(value);
        case 'DATE':
          return this.getDateValue(value);
        case 'BOOLEAN':
          return this.getBooleanValue(value);
        default: return value;
      }
    } else {
      return '---';
    }
  }

  createTagRange() {
    for(let i = 0; i < this.subMenuColumn.column_tag.possible_ranges.length; i++){
      let value = undefined;
      value = this.object[this.subMenuColumn.data_key];
      if(this.subMenuColumn.calculated == 1 && this.subMenuColumn.data_key == "getOnlineSeit") {
        value = this.object["getOnlineSeit"]();
      } else if(this.subMenuColumn.calculated == 1 && this.subMenuColumn.data_key == "getLastPriceChange") {
        value = this.object["getLastPriceChange"]();
      }
      let range = this.subMenuColumn.column_tag.possible_ranges[i];
      if(range.smaller_than != null && value <= range.smaller_than){
        this.setTagStyle(this.subMenuColumn.column_tag.possible_ranges[i].color);
        break;
      }
      if((range.smaller_than != null && range.bigger_than != null) &&
        (value > range.smaller_than && value < range.bigger_than)){
        this.setTagStyle(this.subMenuColumn.column_tag.possible_ranges[i].color);
        break;
      }
      if(range.smaller_than == null && range.bigger_than != null && value >= range.bigger_than){
        this.setTagStyle(this.subMenuColumn.column_tag.possible_ranges[i].color);
        break;
      }
    }
  }

  createTagValue() {
    let value = this.object[this.subMenuColumn.data_key];
    if(value > 0 && value < this.subMenuColumn.column_tag.possible_values.length){
      this.setTagStyle(this.subMenuColumn.column_tag.possible_values[value-1].color);
    }
  }

  createTagString() {
    if(this.subMenuColumn.column_tag?.possible_values){
      for(let value of this.subMenuColumn.column_tag.possible_values){
        if(value.value == this.data){
          this.setTagStyle(value.color);
          break;
        }
      }
    }
  }

  createTagBoolean() {
    if(this.subMenuColumn.column_tag?.possible_values){
      for(let value of this.subMenuColumn.column_tag.possible_values){
        if(value.value == this.object[this.subMenuColumn.data_key]){
          this.setTagStyle(value.color);
          break;
        }
      }
    }
  }

  createTagEnum() {
    for(let i = 0; i < this.subMenuColumn.column_tag.possible_values?.length; i++){
      let value = this.subMenuColumn.column_tag.possible_values[i].value;
      if(value == this.object[this.subMenuColumn.data_key] || value == this.data){
        this.setTagStyle(this.subMenuColumn.column_tag.possible_values[i].color);
        break;
      }
    }
  }

  getNumberValue(value: any): any {
    let num;
    if(isNaN(value)){
      if(value == 'NaN'){
        return '---'
      } else {
        value = Number(value);
      }
    }
    switch(this.subMenuColumn.format){
      case '0.00':
        let truncatedValue = Math.floor(parseFloat(value) * 100) / 100;
        num = new NumberFormatPipe().transform(truncatedValue.toFixed(2));
        break;
      case '0.000': num = new NumberFormatPipe().transform(parseFloat(value).toFixed(3)); break;
      default: num = new NumberFormatPipe().transform(parseFloat(value).toFixed()); break;
    }
    return (this.subMenuColumn.prefix ? this.subMenuColumn.prefix : "") + num + (this.subMenuColumn.suffix ? this.subMenuColumn.suffix : "");
  }

  getDateValue(value : any): any {
    try {
      return formatDate(value, this.subMenuColumn.format, 'de');
    } catch (error) {
      console.log("Error date format");
      return '---';
    }
  }

  getBooleanValue(value : any): any  {
    return (value && value != "0") ? 'Ja' : 'Nein';
  }

  setFixed() {
    let column = document.getElementById(this.columnId) as HTMLElement;
    if(column != null && column.parentElement != null){
      column.parentElement.setAttribute('style', 'left: '+this.leftWidth+'em;'+' height: 100%');
    }
  }

  setTagStyle(color: string) {
    let column = document.getElementById(this.columnId) as HTMLElement;
    if(column != null){
      let label = column.firstChild as HTMLElement;
      if(label != null){
        label.style.padding = '2px 6px';
        label.style.borderRadius = '4px';
        label.style.color = color;
        label.style.background = color.trim()+'50';
      }
    }
  }
}
