
<div id="overview_container">
  <form class="data_container" [formGroup]="form">
      <div class="lbl_bold_light_color">Neues Kundenfahrzeug</div>
      <div id="customer_create_car_body">
        <div class="body_column">
          <label class="inner_title">Model</label>
          <div #dropdownOne class="dropdown_wrapper" >
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.brand.value ? '1' : '0'}">Marke</div>
            <div class="dropdown_header_data" [ngClass]="submitted && form.controls.brand.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownOne = !showDropdownOne">
                <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.brand.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.brand.value ? form.controls.brand.value.makeName : 'Marke *'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownOne ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownOne" (mouseover)="onMouseEnterDropdown()">
                <div *ngFor="let brand of brands" [id]="'dropdown_value_'+brand.id" class="dropdown_data_body_element" (click)="setBrand(brand); showDropdownOne = false">{{brand.makeName}}</div>
            </div>
          </div>
          <div #dropdownTwo class="dropdown_wrapper" >
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.model.value ? '1' : '0'}">Modelgruppe</div>
            <div class="dropdown_header_data"  [ngStyle]="{'background-color':form.controls.brand?.value ? '' : 'var(--color-gray-lighter)'}" [ngClass]="submitted && form.controls.model.errors ? 'dropdown_header_data_invalid' : (!form.controls.brand?.value ? 'dropdown_header_data_disabled' : '')" (click)="form.controls.brand?.value ? showDropdownTwo = !showDropdownTwo : ''">
                <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.model.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.model.value ? form.controls.model.value.name : 'Modelgruppe *'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownTwo ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownTwo" (mouseover)="highlighter.onMouseEnterDropdown()">
                <div *ngFor="let model of models" [id]="'dropdown_value_'+model.id" class="dropdown_data_body_element" (click)="form.controls.model.setValue(model); showDropdownTwo = false">{{model.name}}</div>
            </div>
          </div>
          <div>
              <div class="lbl_inputfield_container">
                <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.version.value ? '1' : '0'}">Ausführung *</div>
                <div class="lbl_regular_small">{{counterVersion}} Zeichen übrig</div>
              </div>
              <input type="text" class="inputfield" [ngClass]="submitted && form.controls.version.errors ? 'inputfield_invalid' : ''"  maxlength="80" placeholder="Ausführung *" formControlName="version" (ngModel)="form.controls.version.value" (input)="setVersionTitle()">
          </div>
          <div #dropdownNine class="dropdown_wrapper" style="margin-top: 15px; margin-bottom: 10px;">
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.unfall.value != null ? '1' : '0'}">Unfallfahrzeug</div>
            <div class="dropdown_header_data" [ngClass]="submitted && form.controls.unfall.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownNine = !showDropdownNine">
                <div class="dropdown_header_data_lbl" [ngClass]="form.controls.unfall.value == null ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.unfall.value ? 'Nein' : (form.controls.unfall.value == null ? 'Unfallfahrzeug *' : 'Ja')}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownNine ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownNine">
                <div class="dropdown_data_body_element" (click)="form.controls.unfall.setValue(false); showDropdownNine = false">Ja</div>
                <div class="dropdown_data_body_element" (click)="form.controls.unfall.setValue(true); showDropdownNine = false">Nein</div>
            </div>
          </div>
          <label class="inner_title" style="margin-top: 20px">Antrieb</label>
          <div #dropdownThree class="dropdown_wrapper" >
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.drive.value ? '1' : '0'}">Antriebsart</div>
            <div class="dropdown_header_data" [ngClass]="submitted && form.controls.drive.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownThree = !showDropdownThree">
                <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.drive.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.drive.value ? form.controls.drive.value.name : 'Antriebsart *'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownThree ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownThree" (mouseover)="highlighter.onMouseEnterDropdown()">
                <div *ngFor="let drive of drives" [id]="'dropdown_value_'+drive.id" class="dropdown_data_body_element" (click)="form.controls.drive.setValue(drive); showDropdownThree = false">{{drive.name}}</div>
            </div>
          </div>
          <div #dropdownFour class="dropdown_wrapper" >
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.transmission.value ? '1' : '0'}">Getriebeart *</div>
              <div class="dropdown_header_data" [ngClass]="submitted && form.controls.transmission.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownFour = !showDropdownFour">
                  <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.transmission.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.transmission.value ? form.controls.transmission.value.name : 'Getriebeart *'}}</div>
                  <img class="dropdown_header_icon" [ngClass]="showDropdownFour ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div class="dropdown_data_body" *ngIf="showDropdownFour" (mouseover)="highlighter.onMouseEnterDropdown()">
                  <div *ngFor="let trans of transmissions" [id]="'dropdown_value_'+trans.id" class="dropdown_data_body_element" (click)="form.controls.transmission.setValue(trans); showDropdownFour = false">{{trans.name}}</div>
              </div>
          </div>
          <div #dropdownFive class="dropdown_wrapper" >
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.fuel.value ? '1' : '0'}">Kraftstoff</div>
              <div class="dropdown_header_data" [ngClass]="submitted && form.controls.fuel.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownFive = !showDropdownFive">
                  <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.fuel.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.fuel.value ? form.controls.fuel.value.name : 'Kraftstoff *'}}</div>
                  <img class="dropdown_header_icon" [ngClass]="showDropdownFive ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div class="dropdown_data_body" *ngIf="showDropdownFive" (mouseover)="highlighter.onMouseEnterDropdown()">
                  <div *ngFor="let fuel of fuels" [id]="'dropdown_value_'+fuel.id" class="dropdown_data_body_element" (click)="form.controls.fuel.setValue(fuel); showDropdownFive = false">{{fuel.name}}</div>
              </div>
          </div>
        </div>
        <div class="body_column">
          <div class="inner_title">Merkmale</div>
          <div #dropdownEight class="dropdown_wrapper" >
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.bodyColor.value ? '1' : '0'}">Farbe</div>
            <div class="dropdown_header_data" [ngClass]="submitted && form.controls.bodyColor.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownEight = !showDropdownEight">
                <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.bodyColor.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.bodyColor.value ? form.controls.bodyColor.value.name : 'Farbe *'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownEight ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownEight" (mouseover)="highlighter.onMouseEnterDropdown()">
                <div *ngFor="let color of bodyColors" [id]="'dropdown_value_'+color.id" class="dropdown_data_body_element" (click)="form.controls.bodyColor.setValue(color); showDropdownEight = false">{{color.name}}</div>
            </div>
          </div>
          <div #dropdownSix class="dropdown_wrapper" >
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.bodyTyp.value ? '1' : '0'}">KFZ-Typ</div>
            <div class="dropdown_header_data" [ngClass]="submitted && form.controls.bodyTyp.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownSix = !showDropdownSix">
                <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.bodyTyp.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.bodyTyp.value ? form.controls.bodyTyp.value.name : 'KFZ-Typ *'}}</div>
                <img class="dropdown_header_icon" [ngClass]="showDropdownSix ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_data_body" *ngIf="showDropdownSix" (mouseover)="highlighter.onMouseEnterDropdown()">
                <div *ngFor="let typ of bodyTyps" [id]="'dropdown_value_'+typ.id" class="dropdown_data_body_element" (click)="form.controls.bodyTyp.setValue(typ); showDropdownSix = false">{{typ.name}}</div>
            </div>
          </div>
          <div #dropdownSeven class="dropdown_wrapper" >
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.category.value ? '1' : '0'}">KFZ-Art</div>
              <div class="dropdown_header_data" [ngClass]="submitted && form.controls.category.errors ? 'dropdown_header_data_invalid' : ''" (click)="showDropdownSeven = !showDropdownSeven">
                  <div class="dropdown_header_data_lbl" [ngClass]="!form.controls.category.value ? 'dropdown_header_lbl_placeholder' : ''">{{form.controls.category.value ? form.controls.category.value.name : 'KFZ-Art *'}}</div>
                  <img class="dropdown_header_icon" [ngClass]="showDropdownSeven ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div class="dropdown_data_body" *ngIf="showDropdownSeven" (mouseover)="highlighter.onMouseEnterDropdown()">
                  <div *ngFor="let cat of categories" [id]="'dropdown_value_'+cat.id" class="dropdown_data_body_element" (click)="setCategory(cat); showDropdownSeven = false">{{cat.name}}</div>
              </div>
          </div>
          <div *ngIf="form.controls.category.value && form.controls.category.value.id != 1">
            <!-- <div class="date_picker">
              <div class="lbl_regular" [ngStyle]="{'opacity': form.controls.firstRegistrationDate.value ? '1' : '0'}">Erstzulassung TT.MM.JJJJ *</div>
              <div appCustomDateFormat3>
                  <input #dateInput2 class="inputfield input_datepicker" [ngClass]="(submitted && (form.controls.firstRegistrationDate.errors?.validateDateInputLong?.valid == false || form.controls.firstRegistrationDate.value == null)) ? 'inputfield_invalid' : ''" placeholder="Erstzulassung TT.MM.JJJJ *" type="text" maxlength="10" [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" [formControl]="form.controls.firstRegistrationDate" formControlName="firstRegistrationDate">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1
                  ></mat-datepicker>
              </div>
            </div> -->
          </div>
          <div class="input_container">
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.numOfDoors.value ? '1' : '0'}">Anzahl der Türen *</div>
              <input type="number" class="inputfield" placeholder="Anzahl der Türen *" pattern="\d+" step="1" min="1" formControlName="numOfDoors" (ngModel)="form.controls.numOfDoors.value" [ngClass]="submitted && form.controls.numOfDoors.errors ? 'inputfield_invalid' : ''">
          </div>
          <div class="input_container">
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.numOfSeats.value ? '1' : '0'}">Anzahl der Sitze *</div>
              <input type="number" class="inputfield" placeholder="Anzahl der Sitze *" pattern="\d+" step="1" min="1" formControlName="numOfSeats" (ngModel)="form.controls.numOfSeats.value" [ngClass]="submitted && form.controls.numOfSeats.errors ? 'inputfield_invalid' : ''">
          </div>
          <div class="input_container">
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.mileageKm.value ? '1' : '0'}">Kilometerstand (km)*</div>
            <input type="number" class="inputfield" pattern="\d+" step="1" min="1" placeholder="Kilometerstand (km)*" formControlName="mileageKm" (ngModel)="form.controls.mileageKm.value" [ngClass]="submitted && form.controls.mileageKm.errors ? 'inputfield_invalid' : ''" >
          </div>
          <div class="input_container">
            <div class="power_header">
                <div *ngIf="form.controls.power.value" class="lbl_regular_small">Leistung</div>
                <div class="slider_grid">
                    <div class="switch_lbl">
                        <div *ngIf="form.controls.powerTyp.value == 1" style="display: flex;"><div [ngStyle]="{'color': '#2C2C2C'}">PS</div>&nbsp;/ KW</div>
                        <div *ngIf="form.controls.powerTyp.value == 2" style="display: flex;">PS /&nbsp;<div [ngStyle]="{'color': '#2C2C2C'}">KW</div></div>
                    </div>
                    <label class="switch" [ngStyle]="{'cursor': 'unset'}">
                        <input type="checkbox" [checked]="form.controls.powerTyp.value == 2" (click)="changePowervalue()">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <input type="number" class="inputfield" placeholder="Leistung *" pattern="\d+" step="1" min="1" formControlName="power" (ngModel)="form.controls.power.value" [ngClass]="submitted && form.controls.power.errors ? 'inputfield_invalid' : ''">
        </div>
        </div>
        <div class="body_column">
          <div id="existing_box">
            <label class="lbl_regular">Stattdessen ein Fahrzeug aus meiner Datenbank wählen:</label>
            <div #dropdownTen class="dropdown_input_wrapper">
              <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.vehicle.value ? '1' : '0'}">Fahrzeug</div>
              <div class="dropdown_input_header" [ngClass]="submitted && form.controls.vehicle.invalid ? 'dropdown_input_header_invalid' : ''" (click)="showDropdownTen = !showDropdownTen">
                  <input #input_vehicle class="dropdown_input" type="text" placeholder="Fahrzeug wählen *" (input)="filtervehicles(input_vehicle.value);">
                  <img class="dropdown_input_header_icon" [ngClass]="showDropdownTen ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div class="dropdown_input_body" *ngIf="showDropdownTen && vehiclesFiltered.length != 0">
                  <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdownTen = false">
                    <!-- <div class="img_container_dropdown">
                      <img *ngIf="vehicle.thumbnail" class="img_dropdown" [src]="vehicle.thumbnail ? vehicle.thumbnail : ''">
                      <div *ngIf="!vehicle.thumbnail" class="img_empty_dropdown">Kein Bild</div>
                    </div> -->
                    <div>
                      {{authService.getLocationID() == 13 && vehicle.externalId != null ? "("+vehicle.externalId+")" : ""}}
                      {{vehicle.brand.makeName}}
                      {{vehicle.model.name}}
                      {{vehicle.model.version}}
                    </div>
                  </div>
              </div>
            </div>
            <div *ngIf="form.controls.vehicle.value">
              <div class="img_container_overview">
                <img *ngIf="form.controls.vehicle.value.thumbnail" class="img_dropdown" [src]="form.controls.vehicle.value.thumbnail ? form.controls.vehicle.value.thumbnail : ''">
                <div *ngIf="!form.controls.vehicle.value.thumbnail" class="img_empty_dropdown">Kein Bild</div>
              </div>
              <div class="overview_record">
                <div class="overview_record_lbl">ID-Nummer</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.id}}</div>
              </div>
              <div class="overview_record">
                <div class="overview_record_lbl">Marke</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.brand.makeName}}</div>
              </div>
              <div class="overview_record">
                  <div class="overview_record_lbl">Model</div>
                  <div class="overview_record_value">{{form.controls.vehicle.value.model.name}}</div>
              </div>
              <div class="overview_record">
                  <div class="overview_record_lbl" style="align-self: flex-start;">Version</div>
                  <div class="overview_record_value" style="max-width: 270px; text-align: end;">{{form.controls.vehicle.value.model.version}}</div>
              </div>
              <div class="overview_record">
                  <div class="overview_record_lbl">KFZ-Typ</div>
                  <div class="overview_record_value">{{form.controls.vehicle.value.bodyTyp.name}}</div>
              </div>
              <div class="overview_record">
                  <div class="overview_record_lbl">FAHRZEUGART</div>
                  <div class="overview_record_value">{{form.controls.vehicle.value.category.name}}</div>
              </div>
              <div class="overview_record">
                  <div class="overview_record_lbl">KILOMETERSTAND</div>
                  <div class="overview_record_value">{{form.controls.vehicle.value.mileageKm | numberFormat}} km</div>
              </div>
            </div>
          </div>
          <div id="route_box">
            <label class="lbl_regular">Oder zur Fahrzeugansicht für ein detailiertes anlegen:</label>
            <button id="btn_route_box" class="btn_submit" (click)="goToCreateVehicleAction()">Zum Detailierten Anlegeprozess</button>
          </div>
        </div>
      </div>
  </form>
</div>



<!--
<div id="overview_container">
  <form class="data_container" [formGroup]="form">
      <div class="lbl_bold_light_color">Neues Kundenfahrzeug</div>
      <div id="customer_create_car_body">
        <div id="existing_box">
          <label class="lbl_regular">Ein Fahrzeug aus meiner Datenbank wählen:</label>
          <div #dropdownTen class="dropdown_input_wrapper">
            <div class="lbl_regular_small" [ngStyle]="{'opacity': form.controls.vehicle.value ? '1' : '0'}">Fahrzeug</div>
            <div class="dropdown_input_header" [ngClass]="submitted && form.controls.vehicle.invalid ? 'dropdown_input_header_invalid' : ''" (click)="showDropdownTen = !showDropdownTen">
                <input #input_vehicle class="dropdown_input" type="text" placeholder="Fahrzeug wählen *" (input)="filtervehicles(input_vehicle.value);">
                <img class="dropdown_input_header_icon" [ngClass]="showDropdownTen ? 'dropdown_input_header_icon_active' : ''" src="../../../../assets/arrow-dark.svg">
            </div>
            <div class="dropdown_input_body" *ngIf="showDropdownTen && vehiclesFiltered.length != 0">
                <div *ngFor="let vehicle of vehiclesFiltered" class="dropdown_input_body_element" (click)="setVehicle(vehicle); showDropdownTen = false">
                  <div class="img_container_dropdown">
                    <img *ngIf="vehicle.thumbnail" class="img_dropdown" [src]="vehicle.thumbnail ? vehicle.thumbnail : ''">
                    <div *ngIf="!vehicle.thumbnail" class="img_empty_dropdown">Kein Bild</div>
                  </div>
                  <div>
                    {{vehicle.brand.makeName}}
                    {{vehicle.model.name}}
                    {{vehicle.model.version}}
                  </div>
                </div>
            </div>
          </div>
          <div *ngIf="form.controls.vehicle.value">
            <div class="img_container_overview">
              <img *ngIf="form.controls.vehicle.value.thumbnail" class="img_dropdown" [src]="form.controls.vehicle.value.thumbnail ? form.controls.vehicle.value.thumbnail : ''">
              <div *ngIf="!form.controls.vehicle.value.thumbnail" class="img_empty_dropdown">Kein Bild</div>
            </div>
            <div class="overview_record">
              <div class="overview_record_lbl">ID-Nummer</div>
              <div class="overview_record_value">{{form.controls.vehicle.value.id}}</div>
            </div>
            <div class="overview_record">
              <div class="overview_record_lbl">Marke</div>
              <div class="overview_record_value">{{form.controls.vehicle.value.brand.makeName}}</div>
            </div>
            <div class="overview_record">
                <div class="overview_record_lbl">Model</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.model.name}}</div>
            </div>
            <div class="overview_record">
                <div class="overview_record_lbl" style="align-self: flex-start;">Version</div>
                <div class="overview_record_value" style="max-width: 270px; text-align: end;">{{form.controls.vehicle.value.model.version}}</div>
            </div>
            <div class="overview_record">
                <div class="overview_record_lbl">KFZ-Typ</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.bodyTyp.name}}</div>
            </div>
            <div class="overview_record">
                <div class="overview_record_lbl">FAHRZEUGART</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.category.name}}</div>
            </div>
            <div class="overview_record">
                <div class="overview_record_lbl">KILOMETERSTAND</div>
                <div class="overview_record_value">{{form.controls.vehicle.value.mileageKm | numberFormat}} km</div>
            </div>
          </div>
        </div>
      </div>
  </form>
</div> -->
