<div id="page-menu-bar" class="header-base-style" *ngIf="surface == 1">
  <button class="button_type_1" (click)="showCreateEmitter.emit(2)">Neu</button>

  <div id="main-menu-wrapper" *ngIf="!showMainMenuAsDropdown">
    <button *ngFor="let mainMenu of mainMenus"
      [class.main-menu-btn-selected]="mainMenu.getActive()"
      (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive())">
      {{mainMenu.name}}
    </button>
  </div>

  <button id="main-menu-wrapper-dropdown" class="button_type_2" *ngIf="showMainMenuAsDropdown"
    (click)="shwoMainMenuDropdown = !shwoMainMenuDropdown"
    appDropdown
    [show]="shwoMainMenuDropdown"
    (changedValueEmitter)="shwoMainMenuDropdown = $event"
    style="max-width: 210px;">
    {{initialMenuName}}
    <img src="./assets/dropdown-arrow-blue.svg">
    <div *ngIf="shwoMainMenuDropdown">
      <button *ngFor="let mainMenu of mainMenus"
        (click)="mainMenu.getActive() ? null : mainMenu.setActive(!mainMenu.getActive()); shwoMainMenuDropdown = false; initialMenuName = mainMenu.name">
        {{mainMenu.name}}
      </button>
    </div>
  </button>
  <div></div>
</div>

<div class="topbar_inner" *ngIf="surface == 2">
  <div class="btn_cancel_container" (click)="cancelCreateEmitter.emit()" *ngIf="!editMode">
      <img class="icon_action" src="./assets/cancle.svg">
      <button type="button" id="btn-cancel">Abbrechen</button>
  </div>
  <input *ngIf="currentPageCreate != 1" [disabled]="true" class="input_title" placeholder="Marke, Modell, Ausführung" [(ngModel)]="vehicleTitle">
  <button *ngIf="currentPageCreate >= 4 && !vinFlag" class="btn_submit" (click)="saveAsDraftEmitter.emit()">Entwurf speichern</button>
</div>

<div class="topbar_inner" *ngIf="(surface == 3 || surface == 4 || surface == 5)">
  <div class="btn_cancel_container" (click)="closeDetailsEmitter.emit(1)" *ngIf="!editMode && (surfacePriceSheet != 1 && surfacePriceSheet != 2)">
      <img class="icon_action" src="./assets/back.svg">
      <button id="btn-cancel">Zurück</button>
  </div>
  <div class="btn_cancel_container" (click)="cancelEdit()" *ngIf="editMode && (surfacePriceSheet != 1 && surfacePriceSheet != 2)">
    <img class="icon_action" src="./assets/cancle.svg">
    <button id="btn-cancel">Abbrechen</button>
  </div>
  <div class="btn_cancel_container" (click)="selectionPriceSheetCancel.emit()" *ngIf="surface == 5 && (surfacePriceSheet == 1 || surfacePriceSheet == 2)">
    <img class="icon_action" src="./assets/cancle.svg">
    <button id="btn-cancel">Abbrechen</button>
  </div>

  <div class="vehicle_title_container">
    <div *ngIf="authService.isSinhuber()">
      <input class="input_title" [disabled]="true" placeholder="Marke, Modell, Ausführung" [(ngModel)]="vehicleTitle">
      <input class="input_sub_title" [disabled]="true" [(ngModel)]="vehicleSubTitle">
    </div>
    <div *ngIf="!authService.isSinhuber()" id="vehicle-title-wrapper">
      <label class="">{{selectedVehicle.externalId ? selectedVehicle.externalId : selectedVehicle.id}}</label>
      <div>
        <label>{{vehicleTitle}}</label>
        <label>{{vehicleSubTitle}}</label>
      </div>
    </div>
  </div>
  <div *ngIf="editMode" class="btn_submit" (click)="updateVehicle()">Speichern</div>
  <div *ngIf="!editMode" class="btn_container">
     <button class="btn_white" *ngIf="editMode && (surface == 3 || surface == 4)" (click)="editVehicle(false)">Abbrechen</button>
     <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2)  && showEdit" (click)="selectionEditVehicle.emit(true)">Fahrzeug bearbeiten</button>
     <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2)  && !this.authService.MECHANIKER() && showEditDescription" (click)="selectionEditDescrtiption.emit(1)">Beschreibung bearbeiten</button>
     <button class="button_type_2 button_blue" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2)" (click)="shwoActions = !shwoActions" appDropdown [show]="shwoActions" (changedValueEmitter)="shwoActions = $event">
      Erstellen
      <img src="./assets/arrow-light.svg">
      <div *ngIf="shwoActions && (selectedDetailsTab == 1 || selectedDetailsTab == 2)">
        <button
          *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && authService.isHoedl()"
          (click)="selectionPrintPreisblatt.emit()">
            Preisblatt
        </button>
        <button
          *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && (authService.isHoedl() || authService.isSinhuber())"
          (click)="selectionEtikettenDruckEmitter.emit()">
            Etikett
        </button>
        <button
          *ngIf="false"
          (click)="printPdfEmitter.emit(selectedVehicle)">
            PDF-Druck
        </button>
        <button
          *ngIf="(authService.ACCESS_ALL() || authService.ACCESS_FOUR()) && surface != 5"
          (click)="goToCreateDocument(2);">
            Angebot
        </button>
        <button
          *ngIf="(authService.ACCESS_ALL() || authService.ACCESS_FOUR()) && surface != 5"
          (click)="goToCreateDocument(4)">
            Vertrag
        </button>
      </div>
    </button>

     <!-- <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && showEdit" (click)="selectionEditVehicle.emit(true)">Fahrzeug bearbeiten</button> -->
     <!-- <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && !this.authService.MECHANIKER() && showEditDescription" (click)="selectionEditDescrtiption.emit(1)">Beschreibung bearbeiten</button> -->
     <!-- <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && authService.isHoedl()" (click)="selectionPrintPreisblatt.emit()">Preisblatt downloaden</button> -->
     <!-- <button class="btn_white" *ngIf="!editMode && (selectedDetailsTab == 1 || selectedDetailsTab == 2) && (authService.isHoedl() || authService.isSinhuber())" (click)="selectionEtikettenDruckEmitter.emit()">Etiketten-Druck</button> -->
     <div *ngIf="authService.ACCESS_ALL() || authService.ACCESS_FIVE()" style="display: flex; column-gap: 15px;">
      <button class="btn_white" *ngIf="surface == 5 && surfacePriceSheet == 3" (click)="selectionKalkulation.emit(1)">{{!authService.isHoedl() ? 'Vorkalkulation' : 'Planrechnung bearbeiten'}}</button>
      <button class="btn_white" *ngIf="surface == 5 && surfacePriceSheet == 3 && !authService.isHoedl()" (click)="selectionKalkulation.emit(2)" [disabled]="false">{{!authService.isHoedl() ? 'Nachkalkulation' : 'Istrechnung bearbeiten'}}</button>
      <!-- <button class="btn_white" *ngIf="surface == 5 && (surfacePriceSheet == 1 || surfacePriceSheet == 2)" (click)="selectionPriceSheetCancel.emit()">Abbrechen</button> -->
      <button class="btn_submit" *ngIf="surface == 5 && (surfacePriceSheet == 1 || surfacePriceSheet == 2)" (click)="selectionPriceSheetSave.emit(surfacePriceSheet)">Speichern</button>
     </div>
      <!-- <div *ngIf="(authService.ACCESS_ALL() || authService.ACCESS_FOUR()) && surface != 5" #dropdown_1 class="dropdown_container">
        <div class="dropdown_header" (click)="showDropdown_2 = !showDropdown_2"  appDropdown [show]="showDropdown_2" (changedValueEmitter)="showDropdown_2 = $event">
            <div class="dropdown_header_lbl" [ngStyle]="{'padding-right': showDropdown_2 ? '37px' : '0px'}">Erstellen</div>
            <img class="dropdown_header_icon" [ngClass]="showDropdown_2 ? 'dropdown_header_icon_active' : ''" src="../../../../assets/arrow-light.svg" [class.drop-arrow-rotated]="showDropdown_2">
        </div>
        <div class="dropdown_element_container" style="width: -webkit-fill-available;" *ngIf="showDropdown_2">
          <div class="dropdown_element" (click)="goToCreateDocument(2); showDropdown_2 = false">Angebot</div>
          <div class="dropdown_element" (click)="goToCreateDocument(4); showDropdown_2 = false">Vertrag</div>
        </div>
      </div> -->
  </div>
</div>
