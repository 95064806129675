import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent, countries } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Customer, CustomerTyp } from 'src/app/buisness-object/customer/Customer';
import { KaufvertragForm } from 'src/app/buisness-object/document/form/KaufvertragForm';
import { Kaufvertrag, VehicleStatus } from 'src/app/buisness-object/document/Kaufvertrag';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { forkJoin, Subscription } from 'rxjs';
import { VehicleService } from 'src/app/service/vehicle/vehicle.service';
import { formatDate } from '@angular/common';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { DocumentService } from 'src/app/service/document/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Zusatzprodukt } from 'src/app/buisness-object/zusatzprodukt/Zusatzprodukt';
import { EnumService } from 'src/app/service/enum/enum.service';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';

@Component({
  selector: 'app-dokumente-kaufvertrag-erstellen',
  templateUrl: './dokumente-kaufvertrag-erstellen.component.html',
  styleUrl: './dokumente-kaufvertrag-erstellen.component.css'
})
export class DokumenteKaufvertragErstellenComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() document: Kaufvertrag;
  @Input() zusatzprodukte: Zusatzprodukt[];
  @Output() selectionCancelEmitter = new EventEmitter<undefined>();
  @Output() createSuccessEmitter = new EventEmitter<Kaufvertrag>();
  @Output() updateSuccessEmitter = new EventEmitter<Kaufvertrag>();
  public form: FormGroup;
  public submitted = false;
  public customerValues = [];
  public vehicleValues = [];
  public customers: Customer[] = [];
  public vehicles: Vehicle[] = [];
  public showCreateChoise = true;
  public countries = countries;
  public registrationDocumentValues = [];
  public registrationDocumentValue;
  public tradeInVehicleRegistrationDocumentValue;
  public tradeInVehicleRegistrationDocumentValues = [];
  public paymentConditionValues = [];
  public paymentConditionValue;
  public downPaymentConditionValue;
  public finanzierungsartenValues = [];
  public finanzierungsartenValue;
  public finanzierungsartenAnzahlungValues = [];
  public finanzierungsartenAnzahlungValue;
  public finanzierungsgesellschaftValues = [];
  public finanzierungsgesellschaftValue;
  public salutationValues = [];
  public salutationValue;
  public titleValues = [];
  public titleValue;
  public mechanical_conditionValue;
  public body_conditionValue;
  public paint_conditionValue;
  public interior_conditionValue;
  public electronic_conditionValue;
  public conditionValues = [];
  public brandValues = [];
  public modelValues = [];
  public brands: VehicleBrand[] = [];
  public models: VehicleModel[] = [];
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];
  public today = formatDate(new Date(),'yyyy-MM-dd','de');
  public customerChanged = false;
  public formValuesChanged = false;
  public dialogQuerySubsription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.showCreateChoise = this.document ? false : true;
    this.getData();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionCancelEmitter.emit();
      }
    });
  }

  setZahlungsmethode($event) {
    this.form.controls['payment_condition'].setValue($event);
    if(this.getEnumValue('payment_condition', this.form.controls['payment_condition'].value) == "Überweisung") {
      let finanzierungsart = this.enumService.getEnumKey('finanzierungsart', "Überweisung");
      if(finanzierungsart) {
        this.form.controls['finanzierungsart'].setValue(finanzierungsart);
      }
    }
    this.getEnumDataOnChange();
  }

  getData() {
    forkJoin({
      customers: this.customerService.getCustomer(),
      vehicles: this.vehicleService.getVehicle(),
      brands: this.vehicleService.getBrands()
    }).subscribe((result) => {
      if(result){
        this.customers = result.customers;
        this.vehicles = result.vehicles;
        this.brands = result.brands;
        this.initView();
      }
    });
  }

  initView() {
    let defaultValue = this.authService.isHoedl() ? 3 : 2;
    this.form = KaufvertragForm.getKaufVertragForm(this.formBuilder, this.zusatzprodukte, defaultValue, this.document);
    if(this.form.get('customer').value == null) this.checkRouteParaCustomer();
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.setUpCustomerChangeListener();
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    this.setDropdownValues();
    this.handleTradeInVehicle();

    this.registrationDocumentValues = this.getEnumValues('registration_document');
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.form.controls['registration_document'].value);
    this.tradeInVehicleRegistrationDocumentValue = this.getEnumValue('registration_document', this.form.controls['trade_in_vehicle_registration_document'].value);
    this.paymentConditionValues = this.getEnumValues('payment_condition');
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.form.controls['payment_condition'].value);
    this.downPaymentConditionValue = this.getEnumValue('payment_condition', this.form.controls['down_payment_condition'].value);
    this.conditionValues = this.getEnumValues('mechanical_condition');

    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["mechanical_condition"].value);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["body_condition"].value);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["paint_condition"].value);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["interior_condition"].value);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["electronic_condition"].value);
    this.finanzierungsartenValues = this.getEnumValues('finanzierungsart');
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.form.controls['finanzierungsart'].value);
    this.finanzierungsartenAnzahlungValues = this.getEnumValues('finanzierungsart_anzahlung');
    this.finanzierungsartenAnzahlungValue = this.getEnumValue('finanzierungsart_anzahlung', this.form.controls['finanzierungsart_anzahlung'].value);
    this.finanzierungsgesellschaftValues = this.getEnumValues('finanzierungsgesellschaft');
    this.finanzierungsgesellschaftValue = this.getEnumValue('finanzierungsgesellschaft', this.form.controls['finanzierungsgesellschaft'].value);
    this.salutationValues = this.getEnumValues('salutation');
    this.titleValues = this.getEnumValues('title');
    this.form.controls["mechanical_condition"].valueChanges.subscribe((changes) => {this.getOverallCondition(); });
    this.form.controls["body_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["paint_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["interior_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.form.controls["electronic_condition"].valueChanges.subscribe((changes) => { this.getOverallCondition(); });
    this.getOverallCondition();
    if(this.form.get('customer').value != null){
      this.submitCustomer();
    }
  }

  checkRouteParaVehicle() {
    let params = this.activateRoute.snapshot.queryParams;
    const vehicleId = Number(params['vehicleId']);
    if(vehicleId){
      this.setValueFromDropdown('vehicle', vehicleId);
    }
  }

  checkRouteParaCustomer() {
    let params = this.activateRoute.snapshot.queryParams;
    const customerId = Number(params['customerId']);
    if(customerId){
      this.setValueFromDropdown('customer', customerId);
    }
  }

  setUpCustomerChangeListener() {
    if(this.document){
      this.form.get('customer_form').value.valueChanges.subscribe((value) => {
        this.customerChanged = true;
      })
    }
  }

  handleTradeInVehicle() {
    if(this.form['trade_in_vehicle_brand']?.value){
      let index = this.brands.findIndex(b => b.id == this.form['trade_in_vehicle_brand'].value.id);
      if(index > -1) {
        this.models = this.brands[index].models;
        if(this.form['trade_in_vehicle_brand'].value != null){
          this.modelValues = [];
          for(let v of this.models){
            this.modelValues.push({
              id: v.id,
              name: v.name
            });
          }
        }
      }
    }
  }

  setDropdownValues() {
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    if(this.form['trade_in_vehicle_brand']?.value != null){
      this.modelValues = [];
      for(let v of this.form['trade_in_vehicle_brand'].value.models){
        this.modelValues.push({
          id: v.id,
          name: v.name
        })
      }
    }
  }

  get formCustomer() {
    return this.form?.controls['customer_form'].value;
  }

  submitCustomer() {
    this.form.get('customer_form').setValue(CustomerForm.getCustomerForm(this.formBuilder, this.form.get('customer').value));
    if(this.form.get('customer').value == null) this.form.get('create_customer').setValue(true);
    else this.form.get('create_customer').setValue(false);
    // if(this.form.get('create_customer').value == true){
    //   // this.formCustomer.get('typ').valueChanges.subscribe((change) => {
    //   //   if(change){
    //   //     if(change == CustomerTyp.COMPANY){
    //   //       this.formCustomer.get('companyName').addValidators(Validators.required);
    //   //     } else {
    //   //       this.formCustomer.get('companyName').setValidators(null);
    //   //     }
    //   //     this.formCustomer.get('companyName').updateValueAndValidity({ emitEvent: false });
    //   //   }
    //   // })
    // }
    this.salutationValue = this.getEnumValue('salutation', this.formCustomer.controls['salutation'].value);
    if(this.form.get('vehicle').value == null) this.checkRouteParaVehicle();
    this.form.get('customer_form').value.valueChanges.subscribe((value) => {
      this.customerChanged = true;
    })
    this.showCreateChoise = false;
    this.customerChanged = false;
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    } else if(value === 'vehicle'){
      let index = this.vehicles.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['vehicle'].setValue(this.vehicles[index]);
        if(this.vehicles[index].price != null) this.form.controls['price'].setValue(this.vehicles[index].price);
      }
    } else if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
      else this.setBrand(null);
    } else if(value === 'model'){
      let index = this.form.controls['trade_in_vehicle_brand'].value.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.form.controls['trade_in_vehicle_brand'].value.models[index]);
    }
  }


  setBrand(brand: VehicleBrand) {
    this.form.controls['trade_in_vehicle_brand'].setValue(brand);
    this.form.controls['trade_in_vehicle_model'].setValue(null);
    if(this.form.controls['trade_in_vehicle_brand'].value != null){
      this.modelValues = [];
      for(let v of this.form.controls['trade_in_vehicle_brand'].value.models){
        this.modelValues.push({
          id: v.id,
          name: v.name
        })
      }
    }
  }

  setModel(model: VehicleModel) {
    this.form.controls['trade_in_vehicle_model'].setValue(model);
  }

  getEnumDataOnChange() {
    this.finanzierungsartenValue = this.getEnumValue('finanzierungsart', this.form.controls['finanzierungsart'].value);
    this.registrationDocumentValue = this.getEnumValue('registration_document', this.form.controls['registration_document'].value);
    this.tradeInVehicleRegistrationDocumentValue = this.getEnumValue('registration_document', this.form.controls['trade_in_vehicle_registration_document'].value);
    this.paymentConditionValue = this.getEnumValue('payment_condition', this.form.controls['payment_condition'].value);
    this.mechanical_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["mechanical_condition"].value);
    this.body_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["body_condition"].value);
    this.paint_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["paint_condition"].value);
    this.interior_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["interior_condition"].value);
    this.electronic_conditionValue = this.getEnumValue('mechanical_condition', this.form.controls["electronic_condition"].value);
    this.getOverallCondition();
  }

  getOverallCondition() {
    let conditions: number[] = [
      this.form.controls.mechanical_condition.value,
      this.form.controls.body_condition.value,
      this.form.controls.paint_condition.value,
      this.form.controls.interior_condition.value,
      this.form.controls.electronic_condition.value
    ];
    let min = Math.min(...conditions);
    let max = Math.max(...conditions);
    if(min != max) {
      this.form.controls.total_condition.setValue('Klasse ' + min + '-' + max);
    } else {
      this.form.controls.total_condition.setValue('Klasse ' + min);
    }
  }

  getColor(): string {
    return VehicleStatus.getConditionColor(this.form.controls.total_condition.value);
  }

  openInfoCondition() {
    this.dialogService.openContractCarCondition(undefined);
  }

  selectionCancel() {
    if(this.formValuesChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  addCustomer() {

  }

  removeAdditionalService(selektiertesZusatzprodukt) {
    let id = selektiertesZusatzprodukt.controls["zusatzprodukt_id"].value;
    let selektierteProdukte = this.form.controls["selektierte_zusatzprodukte"].value;
    for(let i = 0; i < selektierteProdukte.length; i++){
      if(selektierteProdukte[i].controls["zusatzprodukt_id"].value == id){
        selektierteProdukte.splice(i,1);
        break;
      }
    }
    this.form.controls["selektierte_zusatzprodukte"].setValue(selektierteProdukte);
  }

  selectionCreate() {
    this.submitted = true;
    if(!this.validForm()) return;
    let contract = KaufvertragForm.createObject(this.form);
    if(this.form.get('create_customer').value == true && this.document == null){
      this.customerService.createCustomer(contract.customer).subscribe((resultNewCustomer) => {
        if(resultNewCustomer){
          contract.customer = resultNewCustomer;
          contract.customer_id = resultNewCustomer.id;
          this.createContract(contract);
        }
      })
    } else {
      if(this.customerChanged){
        this.customerService.updateCustomer(contract.customer).subscribe((resultCustomer) => {
          if(resultCustomer){
            contract.customer = resultCustomer;
            this.createContract(contract);
          }
        })
      } else {
        this.createContract(contract);
      }
    }
  }

  selectionSave() {
    this.submitted = true;
    if(!this.validForm()) return;
    let contract = KaufvertragForm.createObject(this.form);
    if(this.customerChanged){
      this.customerService.updateCustomer(contract.customer).subscribe((resultCustomer) => {
        if(resultCustomer){
          contract.customer = resultCustomer;
          this.udpateContract(contract);
        }
      })
    } else {
      this.udpateContract(contract);
    }
  }

  validForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return false;
    }
    if(this.form.controls.selektierte_zusatzprodukte) {
      for(let selektiertesZusatzprodukt of this.form.controls.selektierte_zusatzprodukte.value){
        if(selektiertesZusatzprodukt.invalid){
          ErrorHelper.showFormError(selektiertesZusatzprodukt);
          return false;
        }
      }
    }
    if(this.formCustomer.invalid){
      ErrorHelper.showFormError(this.formCustomer);
      return false;
    }
    return true;
  }

  udpateContract(contract: Kaufvertrag) {
    this.documentService.updateKaufvertrag(contract, this.zusatzprodukte).subscribe((result) => {
      if(result){
        result.vehicle = contract.vehicle;
        result.customer = contract.customer;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kaufvertrag wurde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    })
  }

  createContract(contract: Kaufvertrag) {
    this.documentService.createKaufvertrag(contract, this.zusatzprodukte).subscribe((result) => {
      if(result){
        result.vehicle = contract.vehicle;
        result.customer = contract.customer;
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Kaufvertrag wurde erstellt.',
          success: true
        });
        this.createSuccessEmitter.emit(result);
      }
    })
  }

  selectionSign() {

  }

  selectionSignWithApp() {

  }
}
