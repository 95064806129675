<div id="dialog_window">
    <div id="dialog_container">
      <div id="dialog_close_icon" (click)="selectionCloseEmitter.emit()">&#10005;</div>
        <h1>Rücknahme des Mietfahrzeugs</h1>
        <div id="dialog_conainter_body" class="body">
            <label>
                Bitte kontrollieren Sie die folgenden Parameter, bevor Sie das Fahrzeug vom Kunden übernehmen. 
                Laden Sie zusätzliche Bilder des Fahrzeugs, sowie das unterschriebene Rücknahmeprotokoll hoch.
            </label>

            <form [formGroup]="form">
            <div class="form-wrapper">
            <h3>Rücknahme-Parameter</h3>
            
              <div class="input_wrapper">
                <label class="overview_record_lbl">Kilometerstand</label>
                <input type="text" placeholder="---" formControlName="return_mileage">
              </div>
              <div class="input_wrapper">
                <label class="overview_record_lbl">Schäden</label>
                <input type="text" placeholder="---" formControlName="return_damage">
              </div>
              <div class="input_wrapper">
                <label class="overview_record_lbl">Tankinhalt</label>
                <input type="text" placeholder="---" formControlName="return_fuel_content">
              </div>
            </div>
            </form>

            <h3>Bilder</h3>
            <app-files
              [obj]="obj">
            </app-files>
        </div>

        <div class="dialog_btn_container">
          <button class="btn_label">Speichern</button>
          <button class="btn_submit_dark">Rücknahme</button>
        </div>
    </div>
</div>