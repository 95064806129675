<div id="list-title" [ngClass]="isTitle ? 'top' : 'bottom'">
  <div id="list-header-row-1">
    <div></div>
    <div id="pagination-wrapper">
      <div class="pagination-arrow" (click)="sliceFrom != 0 ? pageEmitter.emit(false) : null">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8.678 15.637">
          <path class="svg-path" d="M150.859,15.637a1,1,0,0,1-.715-.3l-6.678-6.819a1,1,0,0,1,0-1.4L150.144.3a1,1,0,0,1,1.43,1.4l-5.993,6.12,5.993,6.118a1,1,0,0,1-.715,1.7" transform="translate(-143.182 0)" fill="#ABABAB"/>
        </svg>
      </div>
      <label>{{pageInfo}}</label>
      <div class="pagination-arrow" (click)="sliceTo != amount ? pageEmitter.emit(true) : null">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8.678 15.637" style="transform: rotate(180deg);">
          <path class="svg-path" d="M150.859,15.637a1,1,0,0,1-.715-.3l-6.678-6.819a1,1,0,0,1,0-1.4L150.144.3a1,1,0,0,1,1.43,1.4l-5.993,6.12,5.993,6.118a1,1,0,0,1-.715,1.7" transform="translate(-143.182 0)" fill="#ABABAB"/>
        </svg>
      </div>
      <!-- <img class="icon_arrow_right list_arrow_active" src="./assets/arrow.svg" (click)="sliceTo != amount ? pageEmitter.emit(true): null"> -->
    </div>
  </div>
  <div id="list-header-row-2">
    <div id="list-header-submenus">
      <div *ngFor="let mainMenu of mainMenus" [ngStyle]="{'display': mainMenu.getActive() ? 'block':'none'}">
        <div *ngIf="mainMenu.getActive()" id="sub-menu-wrapper"   [class.sub-menu-btn-sinhuber]="authService.isSinhuber()">
          <div *ngFor="let subMenu of mainMenu.sub_menus">
            <button *ngIf="!subMenu.is_hidden"
              class="sub-menu-btn"
              [class.sub-menu-btn-active]="subMenu.getActive()"
              (click)="subMenu.getActive() ? null : subMenu.setActive(!subMenu.getActive())">
              {{subMenu.name}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="list-header-row-2-deep">
      <div class="list-title-right-wrapper">
        <div class="dropdown_component_wrapper" *ngIf="showSpecialFilter && authService.isSinhuber()">
          <div class="overview_record_lbl">AL/RE-Datum</div>
          <div class="dropdown_button">
              <div class="dropdown_button_header" (click)="showDrop = !showDrop" appDropdown [show]="showDrop" (changedValueEmitter)="showDrop = $event">
                  <div class="dropdown_header_data_lbl_overview" [ngClass]="selectedMonthFilter == null ? 'dropdown_header_lbl_placeholder_overview' : ''">{{selectedMonthFilter != null ? (selectedMonthFilter | date: 'MMM yyyy') : 'Datum'}}</div>
                  <img class="dropdown_button_header_img" [ngClass]="showDrop ? 'dropdown_button_header_img_active' : ''" src="../../../../assets/arrow-dark.svg">
              </div>
              <div #dropdownBox class="dropdown_button_box" *ngIf="showDrop">
                  <label *ngFor="let value of specialFilterValues; let i = index" (click)="selectionMonth(value); show = false">{{value | date: 'MMM yyyy'}}</label>
              </div>
          </div>
        </div>
      </div>
      <div class="sort_filter_search_wrapper">
        <div class="dropdown_wrapper_header_filter">
          <div id="csv_btn" class="filter_btn_wrapper" (click)="downloadCsv()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.524" height="20.389" viewBox="0 0 20.524 20.389">
            <path class="filter_icon_path" d="M285.123,537.047a10.262,10.262,0,0,0-.556,20.312.721.721,0,0,0,.839-.712V546.241a.074.074,0,0,0-.126-.053l-2.336,2.31a.464.464,0,0,1-.656-.007l-.6-.607a.464.464,0,0,1,.007-.656l4.272-4.206a.465.465,0,0,1,.649,0l4.272,4.206a.464.464,0,0,1,.007.656l-.6.607a.464.464,0,0,1-.655.007l-2.336-2.31a.075.075,0,0,0-.127.053v10.4a.721.721,0,0,0,.839.712,10.263,10.263,0,0,0-2.9-20.312Z" transform="translate(296.554 557.368) rotate(180)" fill="#2c2c2c"/>
          </svg>
          </div>
        </div>
        <div class="dropdown_wrapper_header_filter" *ngIf="customFilterVisible">
          <div id="filter_btn" class="filter_btn_wrapper" [ngClass]="{'filter_btn_wrapper_active': activeFilter.length > 0}" (click)="showCustomFilter = !showCustomFilter; showSort = false;">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rechteck_928" data-name="Rechteck 928" width="19" height="19" fill="#2c2c2c"/>
                </clipPath>
              </defs>
              <g id="Gruppe_1668" data-name="Gruppe 1668" clip-path="url(#clip-path)">
                <path class="filter_icon_path" data-name="Pfad 1070" d="M12.852,16.065a.8.8,0,0,1-.482-.161l-3.213-2.41a.8.8,0,0,1-.321-.643V9.972L4.113,5.25A3.076,3.076,0,0,1,6.288,0H16.2a3.076,3.076,0,0,1,2.174,5.25L13.655,9.972v5.29a.8.8,0,0,1-.8.8ZM8.193,14.78a2.418,2.418,0,0,1-.964-1.928V10.637L2.978,6.386A4.634,4.634,0,0,1,1.656,3.577,3.06,3.06,0,0,0,.9,8.463l4.722,4.722v2.88a.8.8,0,0,0,.321.643l3.213,2.41a.8.8,0,0,0,1.285-.643V16.467Z" fill="#2c2c2c"/>
              </g>
            </svg>
          </div>
          <app-custom-filter
            [showCustomFilter]="showCustomFilter"
            [activeSubMenu$]="activeSubMenu$"
            [employees]="employees"
            [activeFilter]="activeFilter"
            (customFilterEmitter)="customFilterEmitter.emit($event); showCustomFilter = !showCustomFilter"
            (closeCustomFilterEmitter)="showCustomFilter = !showCustomFilter"
          ></app-custom-filter>
          <app-filter appDropdown *ngIf="showFilter"
            [show]="showFilter"
            [excludingElementIds]="['filter_btn','datepicker_filter']"
            (changedValueEmitter)="showFilter = $event"
            [showFilter]="showFilter"
            [form]="filterForm"
            [objects]="objects"
            [sortObject]="sortObject"
            (filterDetailEmitter)="filterDetailEmitter.emit(); showFilter = false"
            (resetFilterEmitter)="resetFilterEmitter.emit(); showFilter = false"
          ></app-filter>
        </div>
        <app-custom-outside-filter
          *ngIf="outsideFilterVisible"
          [activeSubMenu$]="activeSubMenu$"
          [employees]="employees"
          [activeFilter]="activeFilter"
          (customFilterOutsideEmitter)="customFilterOutsideEmitter.emit($event);"
        ></app-custom-outside-filter>
        <div class="ab-search-list-input-wrapper">
          <input id="ab-search-list-input" class="input_search" type="text" [placeholder]="searchPlaceholder">
          <img id="ab-search-list-input-reset" src="./assets/delete-gray.svg">
        </div>
        <!-- <div class="input_dropdown_container">
          <input #search id="input-search-tickets" type="text" placeholder="Tickets durchsuchen" (input)="applyFilter(search.value, 400)">
          <img src="./assets/delete-gray.svg" (click)="resetFilter()">
        </div> -->
      </div>
    </div>
  </div>
    <!-- <div class="list_header_cars" [ngClass]="isTitle ? 'border_top' : 'border_bottom'">

      <div *ngFor="let mainMenu of mainMenus" [ngStyle]="{'display': mainMenu.getActive() ? 'block':'none'}">
        <div *ngIf="mainMenu.getActive()" id="sub-menu-wrapper"   [class.sub-menu-btn-sinhuber]="authService.isSinhuber()">
          <div *ngFor="let subMenu of mainMenu.sub_menus">
            <button *ngIf="!subMenu.is_hidden"
              class="sub-menu-btn"
              [class.sub-menu-btn-active]="subMenu.getActive()"
              (click)="subMenu.getActive() ? null : subMenu.setActive(!subMenu.getActive())">
              {{subMenu.name}}
            </button>
          </div>
        </div>
      </div>

    </div> -->
</div>
