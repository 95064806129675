import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoadingService } from "./loading.service";

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor{
    activeRequest = 0;

    constructor(private loadingScreenService: LoadingService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const skipLoading = request.headers.has('skip-loading-animation');

      if(this.activeRequest === 0){
          if(this.activeRequest === 0){
            let skipGetVehiclePictures = request.url.includes(environment.api + '/back/vehicle/picture/') && request.method == "GET";
            let skipGetVehicleThumbnail = request.url.includes(environment.api + '/back/vehicle/thumbnail') && request.method == "GET";
            let skipCheckSignature = request.url.includes(environment.api + '/back/signature') && request.method == "GET";
            let skipTireThumbnail = request.url.includes(environment.api + '/back/tire/thumbnail/') && request.method == "GET";
            //let skipGetSingleVehicle = request.url.includes(environment.api + '/back/vehicle/') && !isNaN(Number(request.url[request.url.length-1])) && request.method == "GET";
            let skipGetSingleVehicle = request.url == environment.api + '/back/vehicle' && !isNaN(Number(request.url[request.url.length-1])) && request.method == "GET";
              if(!skipLoading && !skipGetVehiclePictures && !skipGetSingleVehicle && !skipGetVehicleThumbnail && !skipCheckSignature && !skipTireThumbnail) {
                this.loadingScreenService.startLoading();
              }
          }
      }
      this.activeRequest++;
      return next.handle(request).pipe(
          finalize(() => {
              this.activeRequest--;
              if(this.activeRequest === 0){
                this.loadingScreenService.stopLoading();
              }
          })
      )
    }
}
