import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.css']
})
export class CustomerFilterComponent implements OnInit,OnChanges {
  @Input() showFilter: boolean;
  @Input() filter: any;
  @Output() filterDetailEmitter = new EventEmitter<undefined>();
  @Output() resetFilterEmitter = new EventEmitter<undefined>();
  public tempFilter: any

  constructor(
  ) { }

  ngOnInit(): void {
    this.copyFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['showFilter']){
      this.copyFilter();
    }
  }

  copyFilter() {
    this.tempFilter = {
      typ: this.filter.typ,
      is_set: this.filter.is_set,
    }
  }

  applyFilter() {
    this.filter.typ = this.tempFilter.typ;
    this.filter.is_set = this.tempFilter.is_set;
  }

  submitFilter() {
    this.applyFilter();
    this.filterDetailEmitter.emit();
  }
  resetFilter() {
    this.resetFilterEmitter.emit();
  }
}
