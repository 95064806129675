import { Component, ElementRef, Input, LOCALE_ID, OnDestroy, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Salutation } from 'src/app/buisness-object/customer/Salutation';
import { Title } from 'src/app/buisness-object/customer/Title';
import { DropdownIconArrowAnimation } from 'src/app/helpers/Animations';
import { DatePipe, FormatWidth, formatDate, getLocaleDateFormat } from '@angular/common';
import * as moment from 'moment';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { BaseComponent, countries } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { DateHelper } from 'src/app/utils/DateHelper';



@Component({
  selector: 'app-create-customer-container',
  templateUrl: './create-customer-container.component.html',
  styleUrls: ['./create-customer-container.component.css'],
  animations: [DropdownIconArrowAnimation]
})

export class CreateCustomerContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  public showDropdown1 = false;
  public selectedSalutation: Salutation;
  public showDropdown2 = false;
  public selectedTitle: Title;

  public selectedCustomerDate: FormControl;
  public showDropdown3 = false;
  public showDropdown4 = false;
  public showDropdown5 = false;
  public isInvalidDate = false;

  public dropSaluSelected = false;
  public dropTitleSelected = false;
  public dropDateSelected = false;
  public dropTypeSelected = false;
  public dropStatusSelected = false;
  public datepipe: DatePipe;
  public selectedSalu: Salutation;
  public saluOptions: string[] = [];
  public listener: () => void;
  @ViewChild('dropdown1') dropdown1: ElementRef;
  @ViewChild('dropdown2') dropdown2: ElementRef;
  @ViewChild('dropdown3') dropdown3: ElementRef;
  @ViewChild('dropdown4') dropdown4: ElementRef;
  @ViewChild('dropdown5') dropdown5: ElementRef;
  @ViewChild('input_employee') input_employee: ElementRef;
  @Input() submitted: boolean;
  @Input() isCompany: boolean;
  @Input() customerFormGroup: FormGroup;
  @Input() isCompanyFormGroup: FormGroup;
  @Input() selectedCustomer: Customer;
  @Input() titles: Title[];
  @Input() salutations: Salutation[];
  @Input() employees: Employee[];
  public employeesFiltered: Employee[] = [];

  public salutationValues = [];
  public titleValues = [];
  public employeeValues = [];
  public typValues = ["Firma","Privat"];
  public statusValues = ["Kunde","Interessent"];
  public maxDate;
  public countries = countries;

  constructor(
    private renderer: Renderer2,
    public authService: AuthenticationService
    ) {

    }

  ngOnInit(): void {
    this.employeesFiltered = this.employees;
    this.setStartValues();

    this.setDropdownValues();
    let now = new Date();
    let date = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());
    this.maxDate = formatDate(date,'yyyy-MM-dd','de');
  }

  ngAfterViewInit(): void {
    this.setCloseListener();
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  setStartValues() {
    if(this.selectedCustomer && this.selectedCustomer.employee){
      //this.input_employee.nativeElement.value = this.selectedCustomer.employee.firstName + ' ' + this.selectedCustomer.employee.lastName;
    } else {
      for(let employee of this.employees){
        if(employee.id == Number(localStorage.getItem('employee_id'))){
          this.setEmployee(employee);
        }
      }
    }
    this.f.typ.setValue(this.isCompany ? 1 : 2);
    this.f.status.setValue(1);
    if(this.selectedCustomer){
      this.fillFormGroup();
      this.selectedSalutation = this.getSalutation(this.selectedCustomer.contactPerson.salutation);
      this.isCompany = this.selectedCustomer.typ == 1 ? true : false ;
    }
  }

  setDropdownValues() {
    for(let v of this.salutations){
      this.salutationValues.push({
        id: v.id,
        name: v.salu
      })
    }
    for(let v of this.titles){
      this.titleValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.employees){
      this.employeeValues.push({
        id: v.id,
        name: v.firstName + " " + v.lastName
      })
    }
  }
  setValueFromDropdown(value: string, id: number) {
    if(value === 'salutation'){
      let index = this.salutationValues.findIndex(v => v.id == id);
      if(index > -1) this.setSalutation(this.salutations[index]);
    } else if(value === 'title'){
      let index = this.titles.findIndex(v => v.id == id);
      if(index > -1) this.setTitle(this.titles[index]);
    } else if(value === 'employee'){
      let index = this.employees.findIndex(v => v.id == id);
      if(index > -1) this.setEmployee(this.employees[index]);
    }
  }

  // setDropdownValues(){
  //   this.salutations.forEach(salu => {this.saluOptions.push(salu.salu)});
  // }

  setCloseListener() {
    this.listener = this.renderer.listen('window','click', (e:Event) => {
      if(this.showDropdown1 && !this.dropdown1?.nativeElement.contains(e.target)) this.showDropdown1 = false;
      if(this.showDropdown2 && !this.dropdown2?.nativeElement.contains(e.target)) this.showDropdown2 = false;
      if(this.showDropdown3 && !this.dropdown3?.nativeElement.contains(e.target)) this.showDropdown3 = false;
      if(this.showDropdown4 && !this.dropdown4?.nativeElement.contains(e.target)) this.showDropdown4 = false;
      if(this.showDropdown5 && !this.dropdown5?.nativeElement.contains(e.target)) this.showDropdown5 = false;
    });
  }

  fillFormGroup() {
    this.f.firstName.setValue(this.selectedCustomer.contactPerson.firstName);
    this.f.lastName.setValue(this.selectedCustomer.contactPerson.lastName);
    this.f.title.setValue(this.selectedCustomer.contactPerson.title);
    if(this.selectedCustomer.contactPerson.title){
      for(let title of this.titles){
        if(this.f.title.value == title.name){
          this.selectedTitle = title;
          this.f.title.setValue(title);
        }
      }
    } else {
      this.selectedTitle = this.titles[0];
      this.f.title.setValue(this.titles[0]);
    }

    this.f.salutation.setValue(this.selectedCustomer.contactPerson.salutation);
    // this.f.birthdate.setValue(formatDate(new Date(this.selectedCustomer.contactPerson.birthdate),'yyyy-MM-dd', 'de'));
    this.f.birthdate.setValue(DateHelper.getConvertedDateForInputFull(this.selectedCustomer.contactPerson.birthdate));
    //this.f.birthdate.setValue(moment(new Date(this.selectedCustomer.contactPerson.birthdate)));
    this.f.email.setValue(this.selectedCustomer.contactPerson.email);
    this.f.phone.setValue(this.selectedCustomer.contactPerson.phone);
    this.f.street.setValue(this.selectedCustomer.address.street);
    this.f.postalCode.setValue(this.selectedCustomer.address.postalCode);
    this.f.city.setValue(this.selectedCustomer.address.city);
    this.f.dataprivacyAccepted.setValue(this.selectedCustomer.dataprivacyAccepted ? true : false);
    this.f.advertismentAccepted.setValue(this.selectedCustomer.advertismentAccepted);
    this.f.typ.setValue(this.selectedCustomer.typ);
    this.f.status.setValue(this.selectedCustomer.status);
    this.f2.atuNum.setValue(this.selectedCustomer.atuNum);
    this.f2.companyName.setValue(this.selectedCustomer.companyName);
    this.f.employee.setValue(this.selectedCustomer.employee ? this.selectedCustomer.employee : null);
    this.f.country.setValue(this.selectedCustomer.address.country ? this.selectedCustomer.address.country : this.f.country.value);
    this.f.is_pep.setValue(this.selectedCustomer.contactPerson.is_pep ? true : false);
  }

  get f() {
    return this.customerFormGroup.controls;
  }

  get f2() {
    return this.isCompanyFormGroup.controls;
  }

  getSalutation(id: number): Salutation {
    for(let salu of this.salutations){
      if(id == salu.id){
        this.f.salutation.setValue(salu);
        return salu;
      }
    }
    this.f.salutation.setValue(this.salutations[3])
    return this.salutations[3];
  }

  setSalutation(salu: Salutation) {
    this.selectedSalutation = salu;
    this.f.salutation.setValue(salu);
  }

  setTitle(title: Title) {
    this.selectedTitle = title;
    this.f.title.setValue(title);
  }

  setTyp(typ: number) {
    this.f.typ.setValue(typ);
    if(typ == 1){
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
  }

  setStatus(status: number) {
    this.f.status.setValue(status);
  }

  setPrivacy() {
    this.f.dataprivacyAccepted.setValue(!this.f.dataprivacyAccepted.value);
  }

  setAdvertising() {
    this.f.advertismentAccepted.setValue(!this.f.advertismentAccepted.value);
  }

  setEmployee(employee: Employee) {
    this.customerFormGroup.controls.employee.setValue(employee);
    // if(employee){
    //   this.input_employee.nativeElement.value = employee.firstName + ' ' + employee.lastName;
    // }
  }

  filterEmployees(value: string) {
    if(value && value.length > 0){
      this.setEmployee(null);
      this.employeesFiltered = [];
      this.employeesFiltered = this.employees.filter((employee) => {
        return employee.firstName.toLowerCase().includes(value.toLowerCase()) ||
               employee.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (employee.firstName.toLowerCase() + ' ' + employee.lastName.toLowerCase()).includes(value.toLowerCase());
      })
    } else {
      this.employeesFiltered = this.employees;
    }
  }
}
