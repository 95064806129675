<div class="drop-zone"
  appDnd (fileDroppedEmitter)="onFileDropped($event)"
  (click)="selectFileDropZone.click()">
  <img src="./assets/upload.svg">
  <label>Klicken Sie oder ziehen Sie Dateien hier her, um sie hochzuladen</label>
  <input #selectFileDropZone
    type="file"
    accept="image/*, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    (change)="uploadFile($event)"
    multiple=""
    style="display: none;"
    capture="environment">
</div>
