import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { Tire, TireDetail } from 'src/app/buisness-object/tires/Tire';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { TransactionService } from 'src/app/service/transaction/transaction.service';
import { TransactionHelper } from 'src/app/helpers/TransactionHelper';
import { Transaction } from 'src/app/buisness-object/transaction/Transaction';


@Component({
  selector: 'app-kunde-details',
  templateUrl: './kunde-details.component.html',
  styleUrl: './kunde-details.component.css'
})
export class KundeDetailsComponent extends BaseComponent implements OnInit {

  @Input() obj: Customer;
  @Output() selectionCloseEmitter = new EventEmitter<any>();
  @Output() selectionEditEmitter = new EventEmitter<Customer>();

  public shwoActions = false;
  public transactions: Transaction[] = [];
  public TransactionHelper = TransactionHelper;
  public salutation;
  public title;
  public typName;
  public statusName;

  constructor(
    private authService: AuthenticationService,
    private eService: EnumService,
    private transactionSerive: TransactionService,
  ){
    super(authService, eService);
  }

  ngOnInit(): void {

    this.salutation = this.eService.getEnumValue('salutation', this.obj.contactPerson.salutation);
    this.typName = this.obj.typ == 1 ? 'Firma' : 'Privat';
    this.statusName = this.obj.status == 1 ? 'Kunde' : 'Interessent';
  }

}
