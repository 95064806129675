<div class="dropdown_filter_container" [ngClass]="showSort ? 'dropdown_filter_container_open' : ''">
  <div class="lbl_list_title">SORTIEREN</div>
  <div class="sort_wrapper">
    <div class="radio_container">
      <div class="radio_container" (click)="tempSortObject.sort_after_value = 1;">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="tempSortObject.sort_after_value == 1 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Alphabetisch</div>
      </div>
      <img *ngIf="tempSortObject.sort_after_value == 1"  class="sort_icon" [src]="!tempSortObject.sort_asc ? '../../../../assets/sortup.svg' : '../../../../assets/sortdown.svg'" (click)="tempSortObject.sort_asc = !tempSortObject.sort_asc">
    </div>
    <div class="radio_container">
      <div class="radio_container" (click)="tempSortObject.sort_after_value = 2;">
        <div class="radio_icon"><div class="radio_icon_inner" [ngClass]="tempSortObject.sort_after_value == 2 ? 'radio_icon_inner_active' : ''"></div></div>
        <div class="lbl_info">Bearbeitet</div>
      </div>
      <img *ngIf="tempSortObject.sort_after_value == 2" class="sort_icon" [src]="!tempSortObject.sort_asc ? '../../../../assets/sortup.svg' : '../../../../assets/sortdown.svg'" (click)="tempSortObject.sort_asc = !tempSortObject.sort_asc">
    </div>
  </div>
  <div class="footer" [ngStyle]="{'justify-content': !tempSortObject.sorting_is_set ? 'flex-end' : 'space-between'}">
    <div *ngIf="tempSortObject.sorting_is_set" class="btn_menu_information" (click)="resetSortEmitter.emit();">Zurücksetzen</div>
    <div class="btn_submit_dark" (click)="submitSorting()">Sortieren</div>
  </div>
</div>
