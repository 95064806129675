import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Drive } from 'src/app/buisness-object/drives/Drive';
import { DriveForm } from 'src/app/buisness-object/drives/form/DriveForm';
import { BaseComponent } from '../../BaseComponent';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Employee } from 'src/app/buisness-object/employee/Employee';
import { DateHelper } from 'src/app/utils/DateHelper';
import { ErrorHelper } from 'src/app/error/ErrorHelper';
import { DrivesService } from 'src/app/service/drives/drives.service';
import { EnumService } from 'src/app/service/enum/enum.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Observable, Subscription } from 'rxjs';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { catchError, map } from 'rxjs/operators';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { VehicleDrive } from 'src/app/buisness-object/vehicle/basic/VehicleDrive';
import { VehicleBrand } from 'src/app/buisness-object/vehicle/basic/VehicleBrand';
import { VehicleFuel } from 'src/app/buisness-object/vehicle/basic/VehicleFuel';
import { VehicleModel } from 'src/app/buisness-object/vehicle/basic/VehicleModel';
import { VehicleCategory } from 'src/app/buisness-object/vehicle/basic/VehicleCategory';
import { VehicleBodyTyp } from 'src/app/buisness-object/vehicle/basic/VehicleBodyTyp';
import { VehicleBodyColor } from 'src/app/buisness-object/vehicle/basic/VehicleBodyColor';
import { VehicleTransmission } from 'src/app/buisness-object/vehicle/basic/VehicleTransmission';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-test-drive-create',
  templateUrl: './test-drive-create.component.html',
  styleUrl: './test-drive-create.component.css'
})
export class TestDriveCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() object: Drive;
  @Input() typ: string;
  @Input() vehicles: Vehicle[];
  @Input() employees: Employee[];
  @Input() drives: Drive[];
  @Input() customers: Customer[];

  public form: FormGroup;
  public vehicleValues = [];
  public platesValues = [];
  public selectedPlateValue = [];
  public selectedVehicle: Vehicle;
  public customerValues = [];
  public employeeValues = [];
  public submitted = false;
  public formValuesChanged = false;
  public formValuesChangedCustomer = false;
  public dialogQuerySubsription: Subscription;
  public brands: VehicleBrand[];
  public vehiclDrives: VehicleDrive[];
  public fuels: VehicleFuel[];
  public transmissions: VehicleTransmission[];
  public bodyColors: VehicleBodyColor[];
  public bodyTyps: VehicleBodyTyp[];
  public categories: VehicleCategory[];

  public brandValues = [];
  public modelValues = [];
  public bodytypValues = [];
  public categorieValues = [];
  public fuelsValues = [];
  public drivesValues = [];
  public transmissionsValues = [];
  public bodyColorValues = [];
  public models: VehicleModel[] = [];
  public maxDate;
  public minDate;

  @Output() selectionCancelEmitter = new EventEmitter<undefined>();
  @Output() createSuccessEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private eService: EnumService,
    private driveService: DrivesService,
    private dialogService: DialogService,
    private customerService: CustomerService
  ){
    super(authService, eService);
  }

  ngOnInit(): void {
    this.setup();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  get formCustomer() {
    return this.form.get('customer_form').value;
  }

  get formVehicle() {
    return this.form.get('vehicle_form').value;
  }

  setup() {
    this.form = DriveForm.getFormGroup(this.formBuilder);
    if(this.form.get('drive_typ').value == null){
      this.form.get('drive_typ').setValue(this.typ == 'customer' ? 1 : 2);
      DriveForm.updateTypValidity(this.form);
    }
    this.form.get('customer').valueChanges.subscribe((change) => {
      if(change){
        const customerForm = CustomerForm.getCustomerForm(this.formBuilder, this.form.get('customer').value)
        this.form.get('customer_form').setValue(customerForm);
        if(this.formCustomer){
          this.formCustomer.get('authorization_number').setValidators(Validators.required);
          this.formCustomer.get('authorization_authority').setValidators(Validators.required);
          this.formCustomer.get('authorization_date').setValidators(Validators.required);
          this.formCustomer.get('authorization_groups').setValidators(Validators.required);
        }
        this.form.get('customer_form').value.valueChanges.subscribe((change) => {
          if(change) {
            this.formValuesChangedCustomer = true;
          }
        });
      }
    });
    this.employeeValues = this.getDropdownValuesEmployee(this.employees);
    this.platesValues = this.getEnumValues('license_plate_id');
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
    this.vehicleValues = this.getDropdownValuesVehicles(this.vehicles);
    /*this.vehicleValues.unshift({
      id: 0,
      name: 'Neues Fahrzeug anlegen'
    });*/
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
    this.form.controls['start_date'].setValue(DateHelper.getConvertedDateForInputFull(new Date().getTime()));
    this.form.controls['start_time'].setValue(DateHelper.getConvertedTimeForInputFull(new Date().getTime()));
    setTimeout(() => {
      this.form.valueChanges.subscribe((changes) => {
        if(changes){
          this.formValuesChanged = true;
        }
      })
    }, 200);
    this.setDialogSubscription();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_edit') this.selectionCancelEmitter.emit();
      }
    });
  }

  selectionCancel() {
    if(this.formValuesChanged){
      this.dialogService.openQuery(
        {
          title: 'Bearbeitung abbrechen',
          message: 'Sind sie sicher, dass Sie die Bearbeitung abbrechen möchten? Alle Änderungen gehen dadurch verloren.',
          btn_cancel_txt: 'Weiter bearbeiten',
          btn_submit_txt: 'Beenden',
          typ: 'cancel_edit',
          submit_value: null,
        }
      );
    } else {
      this.selectionCancelEmitter.emit();
    }
  }

  getDropdownValuesEmployee(employees: Employee[]): any[] {
    let values = [];
    for(let value of employees){
      values.push({
        id: value.id,
        name: value.firstName + ' ' + value.lastName
      })
    }
    return values;
  }

  setPlate(value) {
    this.form.controls['license_plate_id'].setValue(value);
    this.selectedPlateValue = this.getEnumValue('license_plate_id', this.form.controls['license_plate_id'].value);
  }

  setValueFromDropdown(value: string, id: number) {
    if(value === 'vehicle'){
      if(id == 0){
        this.form.get('vehicle_to_create').setValue(true);
      } else {
        let index = this.vehicles.findIndex(obj => obj.id == id);
        if(index > -1){
          this.form.controls.vehicle.setValue(this.vehicles[index]);
        }
      }
    } else if(value === 'customer'){
      let index = this.customers.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['customer'].setValue(this.customers[index]);
      }
    }
    else if(value === 'employee'){
      let index = this.employees.findIndex(obj => obj.id == id);
      if(index > -1){
        this.form.controls['employee'].setValue(this.employees[index]);
      }
    } else if(value === 'brand'){
      let index = this.brands.findIndex(v => v.id == id);
      if(index > -1) this.setBrand(this.brands[index]);
    } else if(value === 'model'){
      let index = this.models.findIndex(v => v.id == id);
      if(index > -1) this.setModel(this.models[index]);
    } else if(value === 'bodytyp'){
      let index = this.bodyTyps.findIndex(v => v.id == id);
      if(index > -1) this.formVehicle.controls.bodyTyp.setValue(this.bodyTyps[index]);
    } else if(value === 'category'){
      let index = this.categories.findIndex(v => v.id == id);
      if(index > -1){
        this.formVehicle.controls.category.setValue(this.categories[index]);
        this.formVehicle.controls.firstRegistrationDate.setValue(null);
        this.calcRegistrationDate();
      }
    } else if(value === 'fuel'){
      let index = this.fuels.findIndex(v => v.id == id);
      if(index > -1) this.formVehicle.controls.fuel.setValue(this.fuels[index]);
    } else if(value === 'drive'){
      let index = this.vehiclDrives.findIndex(v => v.id == id);
      if(index > -1) this.formVehicle.controls.drive.setValue(this.drives[index]);
    } else if(value === 'transmission'){
      let index = this.transmissions.findIndex(v => v.id == id);
      if(index > -1) this.formVehicle.controls.transmission.setValue(this.transmissions[index]);
    } else if(value === 'bodyColor'){
      let index = this.bodyColors.findIndex(v => v.id == id);
      if(index > -1) this.formVehicle.controls.bodyColor.setValue(this.bodyColors[index]);
    }
  }

  setDropdownValues() {
    for(let v of this.fuels){
      this.fuelsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.vehiclDrives){
      this.drivesValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.transmissions){
      this.transmissionsValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.brands){
      this.brandValues.push({
        id: v.id,
        name: v.makeName
      })
    }
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyTyps){
      this.bodytypValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.categories){
      this.categorieValues.push({
        id: v.id,
        name: v.name
      })
    }
    for(let v of this.bodyColors){
      this.bodyColorValues.push({
        id: v.id,
        name: v.name
      })
    }
  }

  setBrand(brand: VehicleBrand) {
    this.formVehicle.brand.setValue(brand);
    this.models = brand.models;
    this.modelValues = [];
    for(let v of this.models){
      this.modelValues.push({
        id: v.id,
        name: v.name
      })
    }
    this.formVehicle.model.setValue(null);
  }

  setModel(model: VehicleModel) {
    this.formVehicle.model.setValue(model);
  }

  isInvalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.formCustomer && this.formCustomer.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
  }

  async selectionCreate() {
    this.submitted = true;
    if(this.isInvalidForm()) return;
    let drive = DriveForm.getObject(this.form);
    let customer;
    if(this.formValuesChangedCustomer){
      customer = CustomerForm.createObject(this.formCustomer);
      await this.updateCustomer(customer).toPromise();
    }
    let createDrive = await this.createDrive(drive).toPromise();
    createDrive.status_signiert = 0;
    createDrive.customer_id = drive.customer?.id;
    createDrive.customer = customer??drive.customer;
    createDrive.employee_id = drive.employee?.id;
    createDrive.employee = drive.employee;
    createDrive.vehicle_id = drive.vehicle.id;
    createDrive.vehicle = drive.vehicle;
    this.createSuccessEmitter.emit(createDrive);
  }

  createDrive(drive: Drive): Observable<any> {
    return this.driveService.createDrive(drive).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }), catchError((error) => {
        return null;
      })
    );
  }

  updateCustomer(customer: Customer): Observable<any> {
    return this.customerService.updateCustomer(customer).pipe(
      map((result) => {
        if(result){
          return result;
        }
      }), catchError((error) => {
        return null;
      })
    );
  }

  setCategory(cat: VehicleCategory) {
    this.formVehicle.category.setValue(cat);
    this.calcRegistrationDate()
  }

  calcRegistrationDate() {
    let date = new Date();
    if(this.formVehicle.category.value.id == 1){
      this.formVehicle.firstRegistrationDate.setValue(null);
    } else if(this.formVehicle.category.value.id == 6){
      date.setMonth(date.getMonth() - 12);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else if(this.formVehicle.category.value.id == 3) {
      date.setMonth(date.getMonth() - 24);
      this.maxDate = formatDate(date,'yyyy-MM-dd','de');
    } else {
      date = new Date(0);
      this.minDate = formatDate(date,'yyyy-MM-dd','de');
    }
  }
}
